import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Code: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.659 3.064a.978.978 0 00-.533.466C13.027 3.736 9 19.824 9 20.013c0 .514.479.987 1 .987.352 0 .73-.229.874-.53C10.973 20.264 15 4.176 15 3.987c0-.663-.706-1.149-1.341-.923zM5.64 7.068c-.154.053-.488.369-2.311 2.187-1.516 1.511-2.156 2.176-2.217 2.301a1.017 1.017 0 00-.052.77c.065.201.198.342 2.195 2.345 1.511 1.516 2.176 2.156 2.301 2.217a.99.99 0 001.144-.186c.279-.279.364-.655.235-1.045-.059-.177-.244-.376-1.789-1.927L3.422 12l1.724-1.73c1.545-1.551 1.73-1.75 1.789-1.927.129-.389.044-.766-.234-1.044a1.005 1.005 0 00-1.061-.231zm12.003.001C17.291 7.193 17 7.614 17 8c0 .08.029.234.065.343.059.177.244.376 1.789 1.927L20.578 12l-1.724 1.73c-1.545 1.551-1.73 1.75-1.789 1.927-.129.39-.044.766.235 1.045a.99.99 0 001.144.186c.125-.061.79-.701 2.301-2.217 1.997-2.003 2.13-2.144 2.195-2.345a1.018 1.018 0 00-.052-.77c-.061-.125-.701-.79-2.217-2.301-1.924-1.919-2.15-2.131-2.328-2.19a1.032 1.032 0 00-.7.004z"
      fill="currentColor"
    />
  </Icon>
);

Code.displayName = 'CodeIcon';
