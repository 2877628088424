import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CalendarCheck: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M17 2a1 1 0 1 0-2 0v1H9V2a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h3.5a1 1 0 1 0 0-2H5a1 1 0 0 1-1-1v-9h4a1 1 0 1 0 0-2H4V6a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V5h6v1a1 1 0 1 0 2 0V5h2a1 1 0 0 1 1 1v1.5a1 1 0 1 0 2 0V6a3 3 0 0 0-3-3h-2V2Z"
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
    <path
      d="M17.975 14.045c-.115.04-.331.243-1.432 1.34l-1.297 1.292-.548-.542c-.464-.458-.57-.55-.696-.591a.736.736 0 0 0-.782.175.735.735 0 0 0-.175.783c.043.13.152.252.892.995.578.582.884.869.975.913.184.089.382.102.578.04.15-.05.256-.15 1.758-1.647 1.137-1.133 1.617-1.632 1.663-1.726.297-.613-.285-1.255-.936-1.032Z"
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
    <path
      d="M16 11a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm-7 5a7 7 0 1 1 14 0 7 7 0 0 1-14 0Z"
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

CalendarCheck.displayName = 'CalendarCheckIcon';
