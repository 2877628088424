import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CurrencyDollar: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.3.869 2.577 1.358 4.2 1.607.562.087 2.033.099 2.58.022 2.016-.283 3.753-1.046 5.24-2.3 4.113-3.47 4.719-9.628 1.362-13.848-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.992 7.992 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.69-9.3 7.491 7.491 0 011.46-2.073c1.694-1.742 4.143-2.639 6.517-2.386zm-1.133 2.023c-.348.123-.595.441-.652.841l-.027.187-.353.094c-.75.199-1.347.53-1.827 1.013-.55.552-.78 1.084-.78 1.801 0 .717.23 1.249.78 1.801.48.483 1.077.814 1.827 1.013l.353.094.01.969.01.969-.09-.028c-.216-.067-.491-.237-.71-.437-.29-.267-.431-.342-.7-.369-.444-.046-.893.235-1.034.648-.15.44-.052.762.352 1.163.473.467 1.14.829 1.882 1.017l.28.071.027.185c.096.662.705 1.052 1.318.843.363-.124.61-.435.668-.844l.027-.187.353-.094c.75-.199 1.347-.53 1.827-1.013.549-.552.779-1.084.779-1.801 0-.322-.02-.485-.088-.7-.314-.996-1.281-1.797-2.574-2.133l-.297-.077-.011-.968-.01-.968.09.028c.217.067.492.237.71.437.29.267.432.342.7.369.445.046.894-.235 1.035-.648.146-.43.05-.762-.331-1.145-.537-.538-1.07-.81-2.153-1.099-.027-.007-.05-.065-.05-.128 0-.211-.128-.498-.3-.669a.998.998 0 00-1.041-.235zm-.66 3.939v.842l-.09-.027a2.042 2.042 0 01-.708-.441c-.265-.288-.263-.468.005-.759.14-.152.647-.458.759-.458.019 0 .035.379.035.843zm2.41 3.327c.128.067.303.199.39.293.263.286.263.468 0 .754a2.042 2.042 0 01-.71.441l-.09.027v-1.69l.09.027a2.7 2.7 0 01.32.148z"
      fill="currentColor"
    />
  </Icon>
);

CurrencyDollar.displayName = 'CurrencyDollarIcon';
