import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DocumentText: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62 2.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.327-.084 15.031 0 15.357.281 1.099 1.043 1.878 2.184 2.238.177.055.818.064 5.56.075 3.614.008 5.45-.001 5.633-.03a3.034 3.034 0 002.306-1.8c.233-.551.218-.175.231-5.755.008-3.423-.002-5.211-.029-5.36a2.74 2.74 0 00-.183-.525c-.138-.291-.226-.384-3.07-3.228-2.846-2.846-2.936-2.932-3.228-3.069a2.665 2.665 0 00-.515-.182c-.204-.039-6.155-.053-6.345-.016zm8.67 4.685L18 9.42v4.863c0 5.466.02 5.099-.299 5.418-.32.32.082.299-5.7.299-5.785 0-5.382.021-5.703-.3-.324-.324-.3.328-.288-7.782L6.02 4.7l.111-.189c.125-.213.318-.375.539-.454.12-.043.729-.054 3.03-.055L12.58 4l2.71 2.71zm-6.647 4.359C8.291 11.193 8 11.614 8 12c0 .242.119.521.299.701.311.312.157.299 3.701.299s3.39.013 3.701-.299a.984.984 0 000-1.402c-.312-.312-.154-.299-3.718-.296-2.757.003-3.186.011-3.34.066zm0 4C8.291 15.193 8 15.614 8 16c0 .242.119.521.299.701.311.312.157.299 3.701.299s3.39.013 3.701-.299a.984.984 0 000-1.402c-.312-.312-.154-.299-3.718-.296-2.757.003-3.186.011-3.34.066z"
      fill="currentColor"
    />
  </Icon>
);

DocumentText.displayName = 'DocumentTextIcon';
