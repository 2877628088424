import { FC } from 'react';
import { BoxProps, Flex, Text } from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';
import { SortState } from 'types';

const SortMenuOptionStylesByState = {
  [SortState.NONE]: {
    icon: {
      name: null,
    },
    container: {
      backgroundColor: 'main.white',
    },
    text: {
      color: 'blackAlpha.700',
    },
  },
  [SortState.ASC]: {
    icon: {
      name: ICON_NAMES.arrowup,
    },
    container: {
      backgroundColor: 'green.50',
    },
    text: {
      color: 'green.500',
    },
  },
  [SortState.DESC]: {
    icon: {
      name: ICON_NAMES.arrowdown,
    },
    container: {
      backgroundColor: 'green.50',
    },
    text: {
      color: 'green.500',
    },
  },
};

interface SortMenuOptionProps {
  label?: string;
  state?: SortState;
  containerProps?: BoxProps;
  isFirst?: boolean;
  isLast?: boolean;
}

export const SortMenuOption: FC<SortMenuOptionProps> = ({
  label,
  state,
  containerProps,
  isFirst,
  isLast,
}) => {
  const styles = SortMenuOptionStylesByState[state];
  return (
    <Flex
      alignSelf="flex-start"
      py={1}
      px={3}
      cursor="pointer"
      userSelect="none"
      alignItems="center"
      borderTopWidth="1px"
      borderBottomWidth="1px"
      borderStyle="solid"
      borderColor="main.medium"
      borderWidth="1px"
      borderRadius="md"
      _focus={{ borderColor: 'blackAlpha.200' }}
      shadow="sm"
      borderTopColor={
        state === SortState.NONE || isFirst ? 'transparent' : 'green.300'
      }
      borderBottomColor={
        state === SortState.NONE || isLast ? 'transparent' : 'green.300'
      }
      borderTopRadius={isFirst ? 'md' : 'initial'}
      borderBottomRadius={isLast ? 'md' : 'initial'}
      {...styles?.container}
      {...containerProps}
    >
      <Text ml={1} fontWeight="medium" flexGrow={1} {...styles?.text}>
        {label}
      </Text>
      <Icon
        name={styles?.icon?.name}
        color={styles?.icon?.name ? 'green.500' : 'transparent'}
        h={4}
        w={4}
      />
    </Flex>
  );
};
