import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserAccess: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.5 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm-2.036 8.464A5 5 0 0 1 5 14h7a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 1.464-3.536Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.286 6.3c0-1.82 1.514-3.3 3.375-3.3 1.86 0 3.375 1.48 3.375 3.3v1.253c.604.232.964.73.964 1.497v3.3c0 .911-.848 1.65-1.895 1.65h-5.21C15.848 14 15 13.261 15 12.35v-3.3c0-.725.538-1.342 1.286-1.563V6.3Zm1.928 1.1h2.893V6.3c0-.78-.649-1.414-1.446-1.414-.798 0-1.447.634-1.447 1.414v1.1Zm3.654 1.65h-4.736c-.131 0-.237.093-.237.206v2.888c0 .114.106.206.237.206h4.736c.131 0 .237-.092.237-.206V9.256c0-.113-.106-.206-.237-.206Z"
      clipRule="evenodd"
    />
  </Icon>
);

UserAccess.displayName = 'UserAccessIcon';
