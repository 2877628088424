import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Mail: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 4.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.326-.084 11.032 0 11.357.281 1.096 1.043 1.877 2.184 2.238.179.056.983.064 7.56.075 4.998.008 7.448-.001 7.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.327.083-11.033 0-11.357-.307-1.197-1.264-2.09-2.444-2.28-.246-.04-14.661-.057-14.86-.018zm14.72 2.041c.253.087.507.341.594.594.039.114.066.32.066.501v.306l-3.79 2.525c-4.147 2.764-3.989 2.672-4.42 2.574-.175-.04-.851-.475-4.004-2.576L3.991 7.461l.016-.382c.013-.328.031-.408.125-.569.124-.212.317-.374.538-.453.123-.044 1.434-.054 7.313-.055 6.565-.002 7.179.004 7.357.064zM7.28 12.054c1.748 1.166 3.305 2.179 3.46 2.251.436.207.737.271 1.26.271s.824-.064 1.26-.27c.154-.073 1.729-1.099 3.5-2.279l3.22-2.147.01 3.629c.012 4.091.026 3.877-.288 4.191-.325.325.323.3-7.702.3-8.023 0-7.377.025-7.701-.299-.314-.314-.299-.099-.299-4.19 0-2.898.01-3.63.05-3.606.027.017 1.481.984 3.23 2.149z"
      fill="currentColor"
    />
  </Icon>
);

Mail.displayName = 'MailIcon';
