import { FC } from 'react';
import { Flex, Spinner, Text, useBreakpointValue } from '@chakra-ui/react';
import { BlockText } from './BlockText';
import { BlockTextType } from './types';

interface BlockEmbedProps {
  embedly: Partial<{
    src: string;
  }>;
  caption?: Array<BlockTextType>;
}

export const BlockEmbed: FC<BlockEmbedProps> = ({ embedly, caption }) => {
  const { src } = embedly || {};

  const height = useBreakpointValue({
    base: '240px',
    sm: '320px',
    md: '480px',
    lg: '600px',
  });

  return (
    <Flex
      maxWidth="2xl"
      direction="column"
      width="100%"
      align="center"
      mt={6}
      mb={4}
      mx="auto"
      position="relative"
      style={{ aspectRatio: '16/9' }}
      sx={{
        '.embed': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      }}
    >
      {!src && <Spinner color="blackAlpha.200" />}
      {src && height && (
        <iframe src={src} width="100%" height="100%" className="embed" />
      )}
      {caption && (
        <Text
          color="text.secondary"
          mt={2}
          mb={4}
          fontSize="md"
          alignSelf="center"
        >
          <BlockText text={caption} />
        </Text>
      )}
    </Flex>
  );
};
