import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DownloadCloud: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M7.900 2.062 C 4.323 2.496,1.340 5.041,0.343 8.509 C 0.134 9.237,0.035 9.883,0.011 10.684 C -0.026 11.892,0.117 12.848,0.502 13.965 C 0.943 15.244,2.028 16.929,2.575 17.184 C 3.199 17.474,3.907 17.081,3.988 16.398 C 4.024 16.091,3.939 15.865,3.654 15.515 C 3.092 14.825,2.636 14.016,2.362 13.224 C 2.062 12.359,1.935 11.184,2.038 10.237 C 2.424 6.701,5.416 4.006,8.961 4.001 C 10.123 3.999,11.122 4.238,12.159 4.765 C 13.866 5.634,15.060 7.044,15.661 8.902 C 15.871 9.550,15.984 9.726,16.297 9.892 C 16.448 9.972,16.539 9.980,17.520 10.003 C 18.321 10.022,18.653 10.045,18.880 10.098 C 19.642 10.277,20.425 10.728,20.928 11.276 C 22.242 12.708,22.350 14.869,21.185 16.410 C 20.946 16.726,20.748 16.925,20.381 17.218 C 20.090 17.450,19.985 17.587,19.919 17.822 C 19.743 18.452,20.222 19.078,20.880 19.078 C 21.264 19.078,21.655 18.833,22.280 18.199 C 23.088 17.380,23.621 16.377,23.889 15.170 C 24.006 14.642,24.016 13.443,23.908 12.906 C 23.530 11.039,22.385 9.514,20.724 8.664 C 19.903 8.244,19.134 8.050,18.109 8.007 L 17.478 7.980 17.304 7.553 C 16.070 4.523,13.221 2.400,9.912 2.042 C 9.396 1.986,8.446 1.996,7.900 2.062 M11.664 11.063 C 11.436 11.146,11.257 11.297,11.131 11.511 L 11.020 11.700 11.009 15.138 L 10.998 18.575 9.769 17.353 C 8.685 16.275,8.517 16.123,8.343 16.066 C 7.954 15.936,7.577 16.020,7.298 16.300 C 6.996 16.602,6.923 17.052,7.112 17.444 C 7.173 17.569,7.813 18.234,9.329 19.745 C 11.253 21.664,11.479 21.876,11.657 21.935 C 11.766 21.971,11.920 22.000,12.000 22.000 C 12.080 22.000,12.234 21.971,12.343 21.935 C 12.521 21.876,12.747 21.664,14.671 19.745 C 16.187 18.234,16.827 17.569,16.888 17.444 C 17.077 17.052,17.004 16.602,16.702 16.300 C 16.423 16.020,16.046 15.936,15.657 16.066 C 15.483 16.123,15.315 16.276,14.230 17.354 L 13.000 18.577 13.000 15.216 C 13.000 11.430,13.014 11.612,12.701 11.299 C 12.429 11.027,12.018 10.933,11.664 11.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

DownloadCloud.displayName = 'DownloadCloudIcon';
