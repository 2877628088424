import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  Flex,
  Input,
  InputProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { CalendarDate } from '@uselessdev/datepicker';
import { format, isValid } from 'date-fns';
import { DatePicker, DatePickerProps } from './DatePicker';

export interface DatePickerInputProps {
  onChange: (date: string) => void;
  datePickerProps?: DatePickerProps;
  inputProps?: InputProps;
  className?: string;
  defaultValue?: string;
  dateFormat?: string;
  dateMatch?: (value: string) => boolean;
  hasTodayButton?: boolean;
  shouldReset?: boolean;
}

export const DatePickerInput: FC<DatePickerInputProps> = ({
  onChange,
  datePickerProps,
  inputProps,
  className,
  defaultValue = '',
  dateFormat = 'MM/dd/yyyy',
  dateMatch = (value: string) => value.match(/(\d{2})\/(\d{2})\/(\d{4})/),
  hasTodayButton = false,
  shouldReset = false,
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [date, setDate] = useState<CalendarDate>();
  const [value, setValue] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const calendarRef = useRef(null);

  const handleSelectDate = (date: CalendarDate) => {
    setDate(date);
    setValue(() => (isValid(date) ? format(date, dateFormat) : ''));
    onClose();
  };

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
    if (dateMatch(target.value)) {
      onClose();
    }
  };

  const handleSelectToday = () => {
    handleSelectDate(new Date());
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  const handleReset = () => {
    setValue(defaultValue);
    setHasLoaded(true);
  };

  useEffect(() => {
    if (dateMatch(value)) {
      const date = new Date(value);
      return setDate(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!defaultValue || hasLoaded) return;
    handleReset();
  }, [defaultValue, hasLoaded]);

  useEffect(() => {
    if (shouldReset) handleReset();
  }, [shouldReset]);

  useEffect(() => {
    if (!date) return;
    onChange(format(new Date(date), dateFormat));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <Popover
      placement="auto"
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      isLazy
    >
      <PopoverTrigger>
        <Flex cursor="pointer" onClick={onOpen} ref={initialRef}>
          <Input
            placeholder="date"
            value={value}
            onChange={handleInputChange}
            cursor="pointer"
            className={className}
            autoComplete="off"
            {...inputProps}
          />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        p={0}
        w="min-content"
        border="none"
        outline="none"
        shadow="none"
        _focus={{ boxShadow: 'none' }}
        ref={calendarRef}
      >
        <PopoverBody
          p={0}
          borderColor="main.medium"
          borderWidth="1px"
          shadow="sm"
          borderRadius="md"
        >
          <DatePicker
            value={{ start: date }}
            onSelectDate={handleSelectDate}
            singleDateSelection
            {...datePickerProps}
          />
          {hasTodayButton && (
            <Flex width="100%" px={4} mb={3}>
              <Button
                onClick={handleSelectToday}
                width="100%"
                variant="transparent"
              >
                {'Today'}
              </Button>
            </Flex>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
