import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const QuestionMarkCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.439.34-1.308 1.21-1.649 1.651-1.146 1.479-1.814 3.078-2.079 4.975-.065.465-.065 1.975 0 2.44.223 1.596.73 2.976 1.558 4.24 1.419 2.169 3.642 3.721 6.13 4.281.832.187 1.288.236 2.22.237.894.001 1.252-.032 2.02-.183 2.571-.508 4.947-2.129 6.385-4.355.806-1.247 1.282-2.525 1.521-4.08.087-.562.099-2.033.022-2.58-.27-1.921-.96-3.558-2.106-5-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.993 7.993 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.689-9.3 7.491 7.491 0 011.46-2.073c1.693-1.742 4.142-2.639 6.516-2.386zm-1.638 2.017c-1.748.251-3.189 1.189-3.795 2.471-.146.308-.158.602-.036.871.176.388.467.576.897.578.445.003.677-.152.964-.643.224-.383.588-.7 1.076-.938.596-.29.982-.373 1.74-.373.762 0 1.144.083 1.753.379.333.163.469.257.704.489.402.399.522.653.522 1.108 0 .455-.12.709-.522 1.108-.368.364-.899.635-1.557.796-.608.148-.987.349-1.315.697-.647.686-.773 1.613-.286 2.1.589.589 1.549.257 1.693-.586l.03-.175.48-.126c1.827-.482 3.115-1.664 3.419-3.137.432-2.091-1.133-4.017-3.701-4.556-.524-.11-1.525-.141-2.066-.063zm.489 10.008c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

QuestionMarkCircle.displayName = 'QuestionMarkCircleIcon';
