import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AcademicCap: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7 3.049c-.066.023-2.181 1.186-4.7 2.584-3.239 1.797-4.622 2.585-4.725 2.69a.99.99 0 000 1.354c.091.094.529.361 1.185.722.572.315 1.063.594 1.092.62.042.039.029.128-.07.479a13.297 13.297 0 00-.338 5.418c.088.601.233.848.613 1.044.053.028.382.092.73.143.349.052.831.141 1.073.199l.44.105v.869c0 1.011.031 1.157.299 1.425.479.479 1.291.35 1.587-.251.08-.161.097-.27.114-.722l.02-.531.503.294c.574.335 1.043.665 1.587 1.117.439.366.576.432.89.432.296 0 .462-.072.77-.333 1.742-1.473 3.578-2.301 5.79-2.608.56-.078.668-.11.876-.261.229-.166.347-.423.42-.922a13.297 13.297 0 00-.338-5.418c-.099-.351-.112-.44-.07-.479.029-.026.52-.305 1.092-.62.656-.361 1.094-.628 1.185-.722a.99.99 0 000-1.354c-.103-.105-1.486-.893-4.725-2.69-2.519-1.398-4.641-2.562-4.716-2.587a1.019 1.019 0 00-.584.003zm3.792 4.031c1.884 1.045 3.425 1.909 3.425 1.92 0 .022-6.823 3.813-6.896 3.831-.034.009-1.231-.628-1.289-.686-.007-.007.406-.243.918-.526 1.017-.563 1.182-.694 1.291-1.022a.99.99 0 00-1.052-1.304c-.181.018-.424.14-1.7.85-.819.456-1.509.829-1.533.829-.051.001-3.573-1.943-3.573-1.972 0-.04 6.859-3.834 6.92-3.827.035.004 1.605.862 3.489 1.907zM6.99 14.35l.011 1.993-.47-.094a4.535 4.535 0 01-.49-.114c-.045-.046-.018-2.09.033-2.455.057-.404.209-1.194.285-1.475l.045-.169.288.16.288.16.01 1.994zm10.725-1.83a11.17 11.17 0 01.259 3.249l-.026.39-.144.024c-1.923.326-4.084 1.249-5.531 2.362-.125.096-.246.175-.27.175-.023 0-.184-.105-.357-.233-.641-.476-1.752-1.115-2.436-1.401L9 16.998v-1.761c0-.969.013-1.754.03-1.744.395.237 2.554 1.413 2.665 1.452a.906.906 0 00.655-.02c.104-.041 1.324-.706 2.71-1.478 1.68-.935 2.528-1.386 2.545-1.355.014.027.064.219.11.428z"
      fill="currentColor"
    />
  </Icon>
);

AcademicCap.displayName = 'AcademicCapIcon';
