import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Watch: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M8.128 0.882 C 7.857 1.018,7.710 1.169,7.584 1.440 C 7.504 1.611,7.499 1.697,7.487 3.106 L 7.475 4.592 7.327 4.619 C 7.061 4.667,6.524 4.910,6.187 5.134 C 5.215 5.781,4.646 6.733,4.521 7.920 C 4.468 8.425,4.468 15.575,4.521 16.080 C 4.619 17.016,4.970 17.763,5.610 18.399 C 6.080 18.866,6.727 19.244,7.267 19.365 L 7.475 19.412 7.487 20.896 C 7.499 22.316,7.504 22.388,7.586 22.566 C 7.690 22.790,7.925 23.034,8.141 23.141 C 8.296 23.218,8.401 23.220,12.000 23.220 C 15.599 23.220,15.704 23.218,15.859 23.141 C 16.075 23.034,16.310 22.790,16.414 22.566 C 16.496 22.388,16.501 22.316,16.513 20.896 L 16.525 19.413 16.725 19.366 C 17.286 19.237,17.899 18.880,18.383 18.400 C 19.034 17.756,19.380 17.023,19.479 16.080 C 19.532 15.575,19.532 8.425,19.479 7.920 C 19.354 6.734,18.786 5.784,17.811 5.133 C 17.476 4.910,16.940 4.668,16.673 4.619 L 16.525 4.592 16.513 3.106 C 16.501 1.799,16.492 1.602,16.431 1.466 C 16.310 1.200,16.129 1.011,15.872 0.882 L 15.627 0.760 12.000 0.760 L 8.373 0.760 8.128 0.882 M15.000 3.380 L 15.000 4.520 12.000 4.520 L 9.000 4.520 9.000 3.380 L 9.000 2.240 12.000 2.240 L 15.000 2.240 15.000 3.380 M16.443 6.109 C 17.120 6.340,17.681 6.909,17.891 7.578 L 17.980 7.860 17.980 12.000 L 17.980 16.140 17.891 16.422 C 17.681 17.092,17.128 17.652,16.448 17.888 L 16.180 17.980 12.000 17.980 L 7.820 17.980 7.552 17.888 C 6.884 17.657,6.335 17.107,6.109 16.443 L 6.020 16.180 6.009 12.133 C 5.999 8.637,6.006 8.046,6.059 7.793 C 6.199 7.115,6.639 6.546,7.260 6.238 C 7.740 6.000,7.638 6.005,12.100 6.013 L 16.180 6.020 16.443 6.109 M15.000 20.640 L 15.000 21.760 12.000 21.760 L 9.000 21.760 9.000 20.640 L 9.000 19.520 12.000 19.520 L 15.000 19.520 15.000 20.640 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Watch.displayName = 'WatchIcon';
