import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MapPin: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.107 0.042 C 9.769 0.182,8.706 0.486,7.540 1.064 C 4.763 2.439,2.777 5.049,2.185 8.103 C 1.749 10.356,2.090 12.555,3.240 14.900 C 4.628 17.729,7.315 20.754,10.687 23.283 C 11.529 23.915,11.652 23.980,12.000 23.980 C 12.348 23.980,12.471 23.915,13.313 23.283 C 15.894 21.347,18.157 19.038,19.638 16.828 C 21.203 14.493,22.001 12.189,22.000 10.006 C 21.999 6.220,19.842 2.738,16.460 1.064 C 15.455 0.566,14.548 0.279,13.469 0.116 C 12.857 0.024,11.641 -0.014,11.107 0.042 M13.218 2.099 C 16.676 2.634,19.359 5.329,19.909 8.820 C 20.009 9.458,19.999 10.661,19.887 11.280 C 19.457 13.670,17.993 16.152,15.503 18.712 C 14.581 19.660,13.328 20.771,12.377 21.486 L 12.000 21.770 11.623 21.486 C 10.258 20.460,8.564 18.873,7.457 17.582 C 5.555 15.363,4.452 13.263,4.094 11.178 C 4.000 10.626,3.998 9.416,4.092 8.820 C 4.541 5.950,6.419 3.604,9.120 2.540 C 9.602 2.350,10.429 2.140,10.920 2.084 C 11.129 2.060,11.354 2.032,11.420 2.023 C 11.659 1.989,12.829 2.039,13.218 2.099 M11.433 6.040 C 10.843 6.134,10.221 6.380,9.731 6.712 C 8.955 7.240,8.394 8.037,8.130 8.989 C 8.034 9.337,8.022 9.449,8.022 10.000 C 8.022 10.553,8.034 10.663,8.132 11.017 C 8.475 12.256,9.357 13.252,10.520 13.715 C 12.334 14.437,14.462 13.710,15.444 12.033 C 16.503 10.224,15.985 7.878,14.269 6.712 C 13.917 6.473,13.343 6.214,12.958 6.120 C 12.572 6.026,11.783 5.984,11.433 6.040 M12.383 8.043 C 13.185 8.190,13.839 8.872,13.967 9.695 C 14.153 10.894,13.209 12.000,12.000 12.000 C 11.032 12.000,10.183 11.268,10.033 10.305 C 9.933 9.663,10.132 9.061,10.596 8.596 C 11.085 8.108,11.698 7.918,12.383 8.043 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

MapPin.displayName = 'MapPinIcon';
