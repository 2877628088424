import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Scissors: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.31 3.061c-1.876.355-3.202 1.867-3.297 3.759a3.93 3.93 0 001.146 2.985 4.001 4.001 0 004.607.779l.267-.132.773.774.773.774-.773.774-.773.774-.267-.132a4.02 4.02 0 00-3.808.148 4.033 4.033 0 00-1.863 2.576c-.117.5-.1 1.357.037 1.872a4.032 4.032 0 003.008 2.893c.498.117 1.357.1 1.868-.036a4.02 4.02 0 002.876-2.929c.218-.879.101-1.921-.303-2.71l-.129-.25 4.709-4.72c4.323-4.333 4.715-4.736 4.774-4.917.128-.39.044-.766-.234-1.044s-.654-.362-1.044-.234c-.18.059-.479.347-3.427 3.29L12 10.579l-.774-.773-.774-.773.132-.267a3.987 3.987 0 00-.235-3.937 3.943 3.943 0 00-2.344-1.696c-.491-.127-1.239-.159-1.695-.072zm1.098 1.983C8.31 5.232 9 6.08 9 7c0 1.08-.92 2-2 2-.92 0-1.768-.69-1.956-1.592-.291-1.397.967-2.655 2.364-2.364zm6.355 8.145c-.53.187-.786.849-.531 1.375.062.126.815.903 2.657 2.741 2.334 2.328 2.589 2.571 2.768 2.63.39.129.766.044 1.045-.235a.988.988 0 00.186-1.144c-.062-.126-.815-.903-2.657-2.741-2.334-2.328-2.589-2.571-2.768-2.63a1.032 1.032 0 00-.7.004zm-6.355 1.855C8.31 15.232 9 16.08 9 17c0 1.08-.92 2-2 2s-2-.92-2-2c0-.898.657-1.731 1.534-1.943.263-.064.604-.069.874-.013z"
      fill="currentColor"
    />
  </Icon>
);

Scissors.displayName = 'ScissorsIcon';
