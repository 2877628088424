import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Heptagon: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.1496 20.1694L37.8366 47.7632C36.2386 48.5335 34.8346 49.6539 33.729 51.0412C32.6234 52.4284 31.8446 54.047 31.4503 55.7766L17.2962 117.793C16.9014 119.522 16.9012 121.318 17.2957 123.047C17.6902 124.776 18.4692 126.394 19.5748 127.78L59.2358 177.51C60.3421 178.897 61.7466 180.016 63.345 180.786C64.9434 181.555 66.6946 181.954 68.4686 181.954H132.077C133.851 181.954 135.602 181.555 137.201 180.786C138.799 180.016 140.204 178.897 141.31 177.51L180.968 127.777C182.073 126.39 182.852 124.772 183.247 123.043C183.641 121.314 183.641 119.519 183.246 117.79L169.092 55.7731C168.698 54.0435 167.919 52.425 166.813 51.0377C165.708 49.6504 164.304 48.53 162.706 47.7597L105.393 20.1694C103.795 19.3997 102.045 19 100.271 19C98.4978 19 96.7473 19.3997 95.1496 20.1694V20.1694Z"
      fill="currentColor"
    />
  </Icon>
);

Heptagon.displayName = 'HeptagonShape';
