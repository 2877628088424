import { FC } from 'react';
import { BoxProps, Flex, Text } from '@chakra-ui/react';
import { limitToMaxDecimalPlaces } from 'helpers';
import {
  apiTerms,
  billingExtrasObject,
  PlanExtrasTiming,
  PlanExtrasTypes,
} from 'types';
import { CostLabel, CostLabelProps } from './CostLabel';

interface BillingTermsCardProps extends apiTerms, billingExtrasObject {
  topLabel?: string; // Your Plan
  detailsLabel?: string; // Additional Details
  pricingLabel?: string; // Pricing
  billing_processing?: number;
  containerProps?: BoxProps;
}

export const BillingTermsCard: FC<BillingTermsCardProps> = ({
  topLabel = 'Your Plan',
  detailsLabel = 'Additional Details',
  planLabel,
  planDetails,
  billing_rate,
  billing_processing,
  payment_type,
  extras,
  pricingLabel,
  containerProps,
}) => {
  const getMainCostLabelProps = () => {
    const amount = parseFloat(billing_rate).toFixed(2);
    const amountAsString = amount.toString();
    const integerDigits = amountAsString.split('.')[0];
    const fractionalDigits = amountAsString.split('.')[1];
    const showDigits = fractionalDigits !== '00';
    return {
      text: integerDigits,
      trailingText: showDigits ? `.${fractionalDigits}` : '/hour',
      trailingProps: showDigits
        ? { fontSize: 'sm', fontWeight: 'medium' }
        : { fontSize: 'sm' },
      secondLineText: showDigits && '/hour',
      secondLineProps: {
        mt: -1,
        width: '100%',
        textAlign: 'center',
        color: 'text.disabled',
        fontSize: 'xs',
      },
    };
  };

  return (
    <Flex
      flexDirection="column"
      backgroundColor="white"
      borderBottomRightRadius="md"
      borderBottomLeftRadius="md"
      borderTopWidth="3px"
      borderTopColor="pink.400"
      minWidth={{ md: 'xs' }}
      maxWidth={{ base: 'sm', md: 'initial' }}
      width={{ base: '100%', md: 'initial' }}
      shadow="md"
      mt={{ base: 0, md: 5 }}
      mb={{ base: 6, md: 0 }}
      ml={{ base: 0, md: 8 }}
      mr={{ lg: 5 }}
      px={3}
      pt={4}
      pb={6}
      alignSelf="flex-start"
      {...containerProps}
    >
      <Text color="text.disabled" fontSize="xs">
        {topLabel}
      </Text>
      <Flex mb={4}>
        <Flex flexDirection="column" width="100%">
          <Text fontSize="2xl" fontWeight="semi">
            {planLabel}
          </Text>
          <Flex
            flexDirection="column"
            color="text.hint"
            fontSize="sm"
            lineHeight="shorter"
          >
            <Text>{`${payment_type} model`}</Text>
            {planDetails.map(detail => (
              <Text key={detail}>{detail}</Text>
            ))}
          </Flex>
        </Flex>
        {!!billing_rate && (
          <Flex flexDirection="column" alignSelf="center" flexShrink={0}>
            {pricingLabel && (
              <Text
                color="text.disabled"
                fontSize="xs"
                lineHeight={0}
                mb={2}
                width="100%"
                textAlign="center"
              >
                {pricingLabel}
              </Text>
            )}
            <CostLabel
              leadingText="$"
              leadingProps={{ fontSize: 'sm' }}
              textProps={{ fontSize: '2xl', lineHeight: 'none' }}
              {...(getMainCostLabelProps() as CostLabelProps)}
            />
          </Flex>
        )}
      </Flex>
      {(extras?.length > 0 || billing_processing) && (
        <>
          <Text color="text.disabled" fontSize="xs">
            {detailsLabel}
          </Text>
          <Flex flexDirection="column" mt={2}>
            {billing_processing && (
              <Flex lineHeight="none" mb={1}>
                <Text fontSize="sm" fontWeight="medium" width="100%">
                  {'Payment Processing'}
                </Text>
                <CostLabel
                  text={billing_processing.toString()}
                  trailingText="%"
                />
              </Flex>
            )}
            {extras.map(
              item =>
                item?.title && (
                  <Flex key={item?.title} lineHeight="none" mb={1}>
                    <Text fontSize="sm" fontWeight="medium" width="100%">
                      {item?.title}
                    </Text>
                    {item?.amount && (
                      <CostLabel
                        leadingText={
                          item?.type === PlanExtrasTypes.CURRENCY && '$'
                        }
                        text={
                          item?.amount
                            ? limitToMaxDecimalPlaces(
                                Number(item.amount),
                                2,
                              ).toString()
                            : '0'
                        }
                        trailingText={
                          item?.type === PlanExtrasTypes.PERCENT
                            ? '%'
                            : item?.timing === PlanExtrasTiming.DAILY
                              ? '/day'
                              : item?.timing === PlanExtrasTiming.MONTHLY
                                ? '/mo'
                                : item?.timing === PlanExtrasTiming.WEEKLY
                                  ? '/week'
                                  : item?.timing === PlanExtrasTiming.QUARTERLY
                                    ? '/quarter'
                                    : ''
                        }
                      />
                    )}
                  </Flex>
                ),
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
