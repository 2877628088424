import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CloudOff: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M0.650 0.066 C 0.414 0.150,0.148 0.420,0.066 0.659 C -0.017 0.905,-0.018 1.093,0.065 1.343 C 0.124 1.519,0.304 1.714,1.775 3.191 L 3.420 4.842 3.280 4.951 C 1.734 6.154,0.635 7.880,0.117 9.920 L 0.000 10.380 0.000 11.972 C 0.000 13.512,0.003 13.579,0.097 13.992 C 0.479 15.682,1.303 17.158,2.557 18.400 C 4.023 19.851,5.947 20.753,8.020 20.961 C 8.324 20.991,10.054 21.001,13.600 20.992 C 18.375 20.980,18.764 20.974,19.076 20.907 L 19.412 20.835 20.936 22.352 C 22.295 23.705,22.481 23.876,22.657 23.935 C 23.047 24.064,23.423 23.979,23.702 23.700 C 24.005 23.397,24.078 22.947,23.887 22.556 C 23.824 22.426,20.871 19.451,12.671 11.255 C 2.370 0.959,1.525 0.125,1.343 0.065 C 1.103 -0.014,0.876 -0.014,0.650 0.066 M9.403 3.047 C 8.809 3.217,8.517 3.927,8.817 4.470 C 8.937 4.687,9.111 4.848,9.307 4.923 C 9.369 4.947,9.651 5.000,9.933 5.042 C 11.529 5.277,12.794 5.910,13.922 7.038 C 14.828 7.943,15.353 8.836,15.741 10.134 C 15.860 10.530,16.018 10.745,16.297 10.891 C 16.443 10.967,16.553 10.979,17.360 11.004 C 18.684 11.046,19.197 11.136,19.796 11.430 C 21.130 12.085,22.000 13.495,22.000 15.000 C 22.000 15.444,21.925 15.869,21.757 16.371 C 21.671 16.630,21.600 16.895,21.600 16.959 C 21.600 17.281,21.872 17.697,22.186 17.854 C 22.622 18.074,23.206 17.898,23.455 17.472 C 23.611 17.205,23.831 16.500,23.920 15.982 C 24.246 14.075,23.612 12.122,22.218 10.740 C 21.780 10.306,21.345 9.988,20.792 9.700 C 20.021 9.297,19.289 9.112,18.169 9.034 L 17.483 8.986 17.356 8.663 C 16.529 6.574,14.926 4.852,12.900 3.875 C 12.034 3.457,11.114 3.178,10.219 3.061 C 9.698 2.992,9.598 2.991,9.403 3.047 M11.220 12.641 L 17.580 19.002 12.780 18.989 L 7.980 18.977 7.497 18.869 C 6.020 18.542,4.815 17.874,3.814 16.831 C 2.390 15.345,1.688 13.263,1.922 11.222 C 2.106 9.611,2.870 8.057,4.011 6.974 C 4.295 6.704,4.798 6.284,4.840 6.281 C 4.851 6.281,7.722 9.142,11.220 12.641 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

CloudOff.displayName = 'CloudOffIcon';
