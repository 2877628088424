import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CheckCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.44.34-1.308 1.21-1.65 1.651-1.145 1.479-1.813 3.078-2.078 4.975-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.3.869 2.577 1.358 4.2 1.607.562.087 2.033.099 2.58.022 2.016-.283 3.753-1.046 5.24-2.3 4.113-3.47 4.719-9.628 1.362-13.848-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.992 7.992 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.69-9.3 7.491 7.491 0 011.46-2.073c1.694-1.742 4.143-2.639 6.517-2.386zm1.848 5.027c-.153.053-.442.323-1.91 1.785L11 12.577l-.73-.723c-.618-.611-.76-.732-.927-.788-.388-.13-.765-.046-1.044.233-.28.278-.363.655-.233 1.044.057.173.203.336 1.189 1.328.77.775 1.179 1.158 1.3 1.216.246.119.51.137.77.053.202-.065.343-.198 2.346-2.195 1.516-1.511 2.156-2.176 2.217-2.301.396-.818-.38-1.674-1.248-1.376z"
      fill="currentColor"
    />
  </Icon>
);

CheckCircle.displayName = 'CheckCircleIcon';
