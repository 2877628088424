import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Leaf: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M2.325 2.278 C 1.940 2.374,1.683 2.730,1.574 3.321 C 1.498 3.732,1.488 5.115,1.556 5.900 C 1.800 8.697,2.448 11.557,3.382 13.947 C 4.576 17.005,6.120 19.040,8.224 20.330 C 9.225 20.943,10.409 21.386,11.640 21.605 C 12.224 21.710,13.494 21.751,14.063 21.684 C 16.107 21.443,17.727 20.579,18.763 19.176 C 18.858 19.046,18.938 18.936,18.940 18.932 C 18.941 18.928,19.121 18.969,19.338 19.025 C 19.838 19.151,20.501 19.289,21.223 19.416 C 21.837 19.523,21.968 19.516,22.222 19.359 C 22.372 19.266,22.559 18.945,22.559 18.780 C 22.560 18.518,22.379 18.213,22.154 18.097 C 22.089 18.063,21.799 17.995,21.508 17.944 C 20.782 17.816,19.739 17.597,19.677 17.558 C 19.636 17.533,19.648 17.452,19.734 17.173 C 20.003 16.296,20.115 15.326,20.045 14.488 C 19.812 11.703,18.221 9.203,15.580 7.471 C 14.491 6.756,13.380 6.293,11.913 5.942 C 11.362 5.810,10.946 5.731,9.160 5.421 C 6.620 4.979,5.233 4.394,3.840 3.178 C 3.631 2.996,3.370 2.753,3.260 2.640 C 2.947 2.317,2.643 2.199,2.325 2.278 M3.840 5.177 C 4.071 5.340,4.467 5.581,4.720 5.713 C 5.783 6.264,6.558 6.484,8.900 6.900 C 9.472 7.001,10.102 7.120,10.300 7.164 C 11.579 7.447,12.908 8.012,14.222 8.830 C 16.239 10.085,17.550 11.567,18.101 13.214 C 18.469 14.316,18.473 15.548,18.110 16.680 C 18.027 16.942,18.003 16.979,17.926 16.970 C 17.877 16.965,17.515 16.843,17.120 16.700 C 14.093 15.601,11.624 14.065,8.960 11.622 C 8.751 11.431,8.513 11.239,8.432 11.197 C 7.982 10.964,7.419 11.282,7.369 11.798 C 7.337 12.134,7.431 12.264,8.250 13.009 C 11.347 15.827,13.658 17.305,16.628 18.371 L 16.996 18.503 16.830 18.697 C 16.446 19.147,15.765 19.580,15.160 19.760 C 14.417 19.980,13.073 20.064,12.180 19.945 C 10.427 19.711,8.993 18.996,7.720 17.720 C 6.289 16.286,5.084 14.173,4.218 11.580 C 3.478 9.365,3.165 7.477,3.161 5.205 L 3.160 4.670 3.290 4.775 C 3.361 4.834,3.609 5.014,3.840 5.177 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Leaf.displayName = 'LeafIcon';
