import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MenuAlt4: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.643 7.069C3.291 7.193 3 7.614 3 8c0 .242.119.521.299.701C3.624 9.026 2.856 9 12 9s8.376.026 8.701-.299a.984.984 0 000-1.402c-.325-.326.446-.299-8.718-.296-7.286.003-8.182.01-8.34.066zm0 8C3.291 15.193 3 15.614 3 16c0 .242.119.521.299.701.325.325-.443.299 8.701.299s8.376.026 8.701-.299a.984.984 0 000-1.402c-.325-.326.446-.299-8.718-.296-7.286.003-8.182.01-8.34.066z"
      fill="currentColor"
    />
  </Icon>
);

MenuAlt4.displayName = 'MenuAlt4Icon';
