import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MailOpen: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.46 2.457a2.794 2.794 0 00-.818.282c-.116.059-1.861 1.207-3.877 2.551-2.57 1.714-3.733 2.511-3.892 2.67a2.962 2.962 0 00-.812 1.545c-.057.28-.062.858-.051 5.175.011 4.318.02 4.882.076 5.06.361 1.13 1.05 1.82 2.174 2.175.179.056.983.064 7.56.075 4.998.008 7.448-.001 7.633-.03a3.034 3.034 0 002.306-1.8c.232-.55.217-.202.231-5.48.011-4.332.006-4.895-.051-5.178a2.962 2.962 0 00-.796-1.523c-.187-.19-1.094-.814-3.923-2.699-2.024-1.349-3.806-2.513-3.96-2.585a3.568 3.568 0 00-.52-.195 3.518 3.518 0 00-1.28-.043zm.824 1.996c.186.056 7.19 4.696 7.426 4.92.072.067.129.142.129.165-.001.023-1.67 1.153-3.71 2.511-4.058 2.701-3.909 2.615-4.339 2.517-.174-.04-.84-.468-3.919-2.517-2.04-1.358-3.709-2.488-3.71-2.511 0-.023.058-.098.129-.165.213-.203 7.236-4.863 7.41-4.917a.892.892 0 01.584-.003zM6.01 13.208c1.05.7 1.91 1.281 1.91 1.292 0 .015-3.66 2.469-3.87 2.595-.04.024-.05-.506-.05-2.595 0-2.089.01-2.619.05-2.595.027.017.91.603 1.96 1.303zm13.981 2.589l-.011 1.323-1.95-1.3c-1.073-.715-1.95-1.309-1.95-1.32 0-.011.877-.605 1.95-1.32l1.95-1.3.011 1.297c.005.713.005 1.893 0 2.62zm-9.853.161c.72.474 1.156.618 1.862.618.697 0 1.187-.162 1.856-.614.167-.112.329-.214.36-.226.059-.022 5.664 3.69 5.664 3.752 0 .019-.078.112-.173.207-.331.331.33.305-7.707.305-8.043 0-7.375.027-7.71-.308-.097-.096-.17-.193-.163-.214.015-.044 5.554-3.751 5.611-3.755.021-.002.201.104.4.235z"
      fill="currentColor"
    />
  </Icon>
);

MailOpen.displayName = 'MailOpenIcon';
