import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserOverbook: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M8.5 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm-2.036 8.464A5 5 0 0 1 5 14h7a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 1.464-3.536ZM17.604 7.02c-.408.08-.533.114-.75.202-.88.358-1.549 1.137-1.794 2.093-.08.315-.08 3.043 0 3.357.281 1.098 1.061 1.896 2.184 2.236.26.079 2.747.117 3.182.05a3.052 3.052 0 0 0 2.482-2.283c.08-.316.081-3.044 0-3.357-.307-1.197-1.264-2.09-2.444-2.28-.24-.039-2.676-.054-2.86-.018Zm2.72 2.042c.253.087.507.34.594.594.055.163.066.377.066 1.34 0 1.316-.018 1.42-.299 1.7-.28.281-.385.3-1.7.3-1.318 0-1.42-.019-1.703-.3-.288-.288-.304-.387-.29-1.787l.012-1.213.111-.19c.061-.102.173-.235.25-.293.264-.202.352-.213 1.602-.215.981-.002 1.193.008 1.357.064Z"
    />
  </Icon>
);

UserOverbook.displayName = 'UserOverbookIcon';
