import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const HomeAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.648 1.072 C 11.547 1.112,9.584 2.612,6.858 4.734 C 2.942 7.780,2.223 8.355,2.130 8.514 L 2.020 8.700 2.009 14.480 C 1.997 20.830,1.987 20.547,2.238 21.154 C 2.392 21.528,2.582 21.809,2.886 22.114 C 3.321 22.548,3.815 22.814,4.421 22.939 C 4.860 23.030,19.151 23.030,19.580 22.940 C 20.601 22.724,21.390 22.067,21.784 21.102 C 22.010 20.549,22.003 20.760,21.991 14.499 L 21.980 8.738 21.878 8.539 C 21.784 8.356,21.414 8.058,17.138 4.731 C 13.902 2.213,12.446 1.104,12.323 1.061 C 12.096 0.983,11.865 0.987,11.648 1.072 M16.020 6.395 L 20.000 9.491 20.000 14.818 C 20.000 20.804,20.022 20.381,19.701 20.701 C 19.409 20.994,19.362 21.000,17.573 21.000 L 16.000 21.000 16.000 16.427 C 16.000 11.284,16.019 11.617,15.700 11.298 C 15.387 10.985,15.548 10.997,11.936 11.009 L 8.738 11.020 8.539 11.122 C 8.316 11.237,8.104 11.488,8.042 11.711 C 8.014 11.810,8.000 13.405,8.000 16.430 L 8.000 21.000 6.427 21.000 C 4.638 21.000,4.591 20.994,4.299 20.701 C 3.978 20.381,4.000 20.804,4.000 14.818 L 4.000 9.491 7.990 6.386 C 10.184 4.679,11.993 3.286,12.010 3.291 C 12.026 3.296,13.831 4.693,16.020 6.395 M14.000 17.000 L 14.000 21.000 12.000 21.000 L 10.000 21.000 10.000 17.000 L 10.000 13.000 12.000 13.000 L 14.000 13.000 14.000 17.000 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

HomeAlt.displayName = 'HomeAltIcon';
