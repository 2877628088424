import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Laptop: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M3.300 3.800 C 2.507 3.960,1.843 4.573,1.588 5.380 L 1.500 5.660 1.500 11.640 L 1.500 17.620 1.613 17.939 C 1.675 18.115,1.796 18.364,1.883 18.494 C 1.969 18.623,2.040 18.736,2.040 18.745 C 2.040 18.753,1.713 18.760,1.313 18.760 C 0.653 18.760,0.569 18.768,0.410 18.845 C 0.150 18.971,0.020 19.189,0.020 19.500 C 0.020 19.811,0.150 20.029,0.410 20.155 L 0.585 20.240 12.000 20.240 L 23.415 20.240 23.590 20.155 C 23.850 20.029,23.980 19.811,23.980 19.500 C 23.980 19.189,23.850 18.971,23.590 18.845 C 23.431 18.768,23.347 18.760,22.687 18.760 C 22.287 18.760,21.960 18.753,21.960 18.745 C 21.960 18.736,22.031 18.623,22.117 18.494 C 22.204 18.364,22.325 18.115,22.387 17.939 L 22.500 17.620 22.500 11.640 L 22.500 5.660 22.412 5.380 C 22.191 4.681,21.692 4.151,21.001 3.882 L 20.740 3.780 12.100 3.774 C 7.348 3.771,3.388 3.783,3.300 3.800 M20.580 5.326 C 20.743 5.416,20.864 5.552,20.938 5.729 C 20.993 5.861,21.000 6.527,21.000 11.620 C 21.000 16.713,20.993 17.379,20.938 17.511 C 20.856 17.708,20.734 17.835,20.540 17.924 C 20.386 17.995,20.063 17.998,12.000 17.998 C 3.937 17.998,3.614 17.995,3.460 17.924 C 3.266 17.835,3.144 17.708,3.062 17.511 C 3.007 17.379,3.000 16.713,3.001 11.611 C 3.002 6.118,3.005 5.853,3.075 5.698 C 3.160 5.512,3.358 5.331,3.513 5.296 C 3.572 5.284,3.638 5.267,3.660 5.259 C 3.682 5.252,7.471 5.249,12.080 5.253 C 19.795 5.259,20.470 5.265,20.580 5.326 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Laptop.displayName = 'LaptopIcon';
