import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Exclamation: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.38 2.066a3.014 3.014 0 00-1.888 1.3c-.259.401-7.054 12.191-7.159 12.418-.656 1.428-.012 3.211 1.407 3.896.677.327-.074.3 8.26.3h7.42l.318-.092A3.036 3.036 0 0021.6 18.36c.379-.752.421-1.7.11-2.47-.096-.238-6.952-12.151-7.24-12.582-.664-.99-1.914-1.492-3.09-1.242zm1.117 2.07c.099.058.224.164.277.235.118.159 6.969 12.026 7.071 12.249.204.446-.008 1.027-.465 1.274l-.16.086H4.78l-.16-.086c-.498-.269-.675-.836-.423-1.354.065-.132 1.672-2.929 3.571-6.217 2.656-4.594 3.488-6.003 3.598-6.093a1.017 1.017 0 011.131-.094zm-.854 3.933a1.066 1.066 0 00-.577.591c-.098.288-.098 2.392 0 2.68.126.369.538.66.934.66s.808-.291.934-.66c.098-.288.098-2.392 0-2.68-.173-.508-.762-.778-1.291-.591zm0 5.997c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

Exclamation.displayName = 'ExclamationIcon';
