import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ColorSwatch: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 2.025c-.408.08-.533.114-.75.202a3.062 3.062 0 00-1.627 1.606c-.236.573-.223.142-.223 7.247 0 6.174.004 6.559.073 6.88.222 1.037.648 1.825 1.385 2.562.722.722 1.47 1.133 2.522 1.383.28.067.72.073 6.74.085 4.346.008 6.528-.001 6.712-.029 1.197-.185 2.166-1.079 2.472-2.281.082-.322.082-5.038 0-5.357-.314-1.227-1.294-2.116-2.522-2.29l-.311-.044.192-.253c.52-.687.72-1.608.536-2.476-.073-.346-.308-.842-.55-1.16-.101-.132-.882-.936-1.736-1.786-1.625-1.618-1.831-1.791-2.373-2.005-.348-.137-.657-.189-1.121-.189-.661 0-1.248.198-1.778.6l-.253.191-.027-.226c-.159-1.347-1.184-2.43-2.501-2.642-.244-.04-4.668-.056-4.86-.018zm4.72 2.041c.115.039.263.135.361.233.323.322.299-.21.299 6.77 0 5.507-.007 6.249-.063 6.523a3.01 3.01 0 01-2.345 2.345c-1.616.331-3.205-.73-3.53-2.357-.057-.287-.063-.974-.053-6.6L4.02 4.7l.111-.189c.125-.213.318-.375.539-.454.118-.042.611-.054 2.313-.055 1.911-.002 2.185.006 2.357.064zm5.131 2.116c.203.084 3.219 3.093 3.33 3.321.107.22.105.614-.004.857-.062.138-.742.841-2.939 3.04L12 16.26V7.735l.733-.726c.496-.491.791-.753.91-.807.217-.098.617-.108.828-.02zm4.869 7.884c.253.087.507.341.594.594.058.171.066.448.066 2.34 0 2.427.003 2.399-.299 2.701-.316.316-.05.299-4.598.299H11.06l3-3 3-3h1.043c.866 0 1.076.011 1.237.066zm-12.697 2C6.288 16.197 6 16.615 6 17c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

ColorSwatch.displayName = 'ColorSwatchIcon';
