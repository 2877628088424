import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Database: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.76 2.044c-2.79.219-5.19 1.093-6.512 2.372-.457.442-.721.793-.925 1.23-.328.701-.303.176-.303 6.354 0 6.202-.027 5.665.319 6.38.843 1.743 3.288 3.052 6.501 3.477 3.566.473 7.321-.265 9.427-1.852.377-.285.937-.859 1.147-1.177.196-.298.399-.737.492-1.068.07-.247.074-.542.074-5.76 0-6.202.027-5.665-.319-6.38-.845-1.748-3.299-3.061-6.501-3.478a17.959 17.959 0 00-3.4-.098zm2.92 2.052c2.567.32 4.558 1.225 5.16 2.344.124.231.14.293.14.56 0 .267-.016.329-.14.56-.534.993-2.28 1.875-4.424 2.236-.895.151-1.333.183-2.456.181-1.184-.003-1.713-.05-2.679-.238-2.757-.538-4.609-1.883-4.222-3.067.397-1.218 2.731-2.333 5.421-2.589l.62-.059c.335-.034 2.14.017 2.58.072zm-7.62 6.702c3.601 1.728 9.39 1.558 12.572-.368l.373-.226-.013 1.048-.012 1.048-.14.26c-.617 1.145-2.671 2.056-5.34 2.368-.643.075-2.503.063-3.164-.02-2.577-.324-4.575-1.231-5.176-2.348l-.14-.26-.012-1.048-.013-1.047.333.201c.182.11.512.287.732.392zm0 5c3.601 1.728 9.39 1.558 12.572-.368l.373-.226-.013 1.048-.012 1.048-.14.26c-.617 1.145-2.671 2.056-5.34 2.368-.643.075-2.503.063-3.164-.02-2.577-.324-4.575-1.231-5.176-2.348l-.14-.26-.012-1.048-.013-1.047.333.201c.182.11.512.287.732.392z"
      fill="currentColor"
    />
  </Icon>
);

Database.displayName = 'DatabaseIcon';
