import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LetterR: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m13.896 18-2.224-4.368H10.52V18H8.296V6.656h4.432c1.088 0 1.963.33 2.624.992.661.661.992 1.493.992 2.496 0 .79-.219 1.472-.656 2.048-.427.565-1.013.955-1.76 1.168L16.36 18h-2.464Zm-3.376-6.272h1.792c.555 0 .987-.144 1.296-.432.32-.288.48-.667.48-1.136 0-.48-.16-.864-.48-1.152-.31-.288-.741-.432-1.296-.432H10.52v3.152Z"
      fill="currentColor"
    />
  </Icon>
);

LetterR.displayName = 'LetterRIcon';
