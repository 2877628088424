import { FC, useEffect, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import {
  Calendar,
  CalendarControls,
  CalendarDate,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarValues,
  CalendarWeek,
} from '@uselessdev/datepicker';
import { addMonths, startOfMonth } from 'date-fns';
import { Icon } from 'icons';

export type { CalendarValues, CalendarDate };

interface CalNavButtonProps {
  onClick: () => void;
  handleClick?: () => void;
  isDisabled?: boolean;
  direction: 'next' | 'previous';
}

const CalNavButton: FC<CalNavButtonProps> = ({
  onClick,
  handleClick,
  isDisabled,
  direction,
}) => {
  const icon = direction === 'next' ? 'chevronright' : 'chevronleft';
  return (
    <IconButton
      variant="transparent"
      aria-label={direction}
      size="sm"
      icon={<Icon name={icon} />}
      onClick={() => {
        handleClick();
        onClick();
      }}
      isDisabled={isDisabled}
    />
  );
};

export interface DatePickerProps {
  disablePastDates?: boolean;
  disableFutureDates?: boolean;
  disableBefore?: Date; // 1st of month
  disableAfter?: Date; // 1st of month
  onSelectDate: (value: CalendarValues) => void;
  value: CalendarValues;
  singleDateSelection?: boolean;
  allowSelectSameDay?: boolean;
  customDayComponent?: JSX.Element;
  highlightToday?: boolean;
  onMonthChange?: (Date) => void;
}

export const DatePicker: FC<DatePickerProps> = ({
  disablePastDates = false,
  disableFutureDates = false,
  disableBefore,
  disableAfter,
  onSelectDate,
  value,
  singleDateSelection = false,
  allowSelectSameDay = false,
  highlightToday = true,
  customDayComponent,
  onMonthChange = () => null,
}) => {
  const handleSelectDate = dates => onSelectDate(dates);
  const [activeMonth, setActiveMonth] = useState(
    startOfMonth(new Date(value?.start)),
  );
  const [disabledDates, setDisabledDates] = useState({
    past: false,
    future: false,
  });

  const handleUpdateActiveMonth = (increment: number) => {
    setActiveMonth(addMonths(startOfMonth(new Date(activeMonth)), increment));
  };
  const handleNextPage = () => {
    handleUpdateActiveMonth(1);
  };
  const handlePrevPage = () => {
    handleUpdateActiveMonth(-1);
  };

  useEffect(() => {
    if (!activeMonth) return;
    onMonthChange(activeMonth);
  }, [activeMonth]);

  useEffect(() => {
    if (!value?.start) return;
    setActiveMonth(startOfMonth(new Date(value?.start)));
  }, [value?.start]);

  useEffect(() => {
    if (
      !disablePastDates &&
      !disableFutureDates &&
      !disableBefore &&
      !disableAfter
    )
      return;
    const prev = date => date >= activeMonth;
    const future = date => activeMonth >= date;
    setDisabledDates({
      past: disablePastDates ? disablePastDates : prev(disableBefore),
      future: disableFutureDates ? disableFutureDates : future(disableAfter),
    });
  }, [
    disablePastDates,
    disableFutureDates,
    disableBefore,
    disableAfter,
    activeMonth,
  ]);

  return (
    <Calendar
      value={value}
      onSelectDate={handleSelectDate}
      allowOutsideDays
      weekStartsOn={0}
      highlightToday={highlightToday}
      singleDateSelection={singleDateSelection}
      allowSelectSameDay={allowSelectSameDay}
      disablePastDates={disablePastDates}
      disableFutureDates={disableFutureDates}
    >
      <CalendarControls>
        <CalendarPrevButton
          as={props => (
            <CalNavButton
              {...props}
              handleClick={handlePrevPage}
              isDisabled={disabledDates.past}
              direction="previous"
            />
          )}
        />
        <CalendarNextButton
          as={props => (
            <CalNavButton
              {...props}
              handleClick={handleNextPage}
              isDisabled={disabledDates.future}
              direction="next"
            />
          )}
        />
      </CalendarControls>
      <CalendarMonths>
        <CalendarMonth>
          <CalendarMonthName />
          <CalendarWeek />
          <CalendarDays>
            {customDayComponent && customDayComponent}
          </CalendarDays>
        </CalendarMonth>
      </CalendarMonths>
    </Calendar>
  );
};
