import { Block, BlockText } from 'helpers/src/notion/notionTypes';

export enum ElementSizes {
  LG = 'lg',
  MD = 'md',
}

export type BlockTextType = BlockText;

export type BlockType = Partial<Block>;
