import { FC } from 'react';
import ReactPlayer from 'react-player';
import { Flex, Text } from '@chakra-ui/react';
import { BlockText } from './BlockText';
import { videoControlsConfig } from './elements.config';
import { BlockTextType } from './types';

interface BlockVideoProps {
  external?: {
    url?: string;
  };
  caption?: Array<BlockTextType>;
}

export const BlockVideo: FC<BlockVideoProps> = ({ external, caption }) => {
  return (
    <Flex
      maxWidth="2xl"
      direction="column"
      width="100%"
      align="center"
      mt={6}
      mb={4}
      mx="auto"
      position="relative"
      style={{ aspectRatio: '16/9' }}
      sx={{
        '.react-player': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      }}
    >
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore */}
      <ReactPlayer
        url={external?.url}
        config={videoControlsConfig}
        controls={false}
        width="100%"
        height="100%"
        className="react-player"
      />
      {caption && (
        <Text
          color="text.secondary"
          mt={2}
          mb={4}
          fontSize="md"
          alignSelf="center"
        >
          <BlockText text={caption} />
        </Text>
      )}
    </Flex>
  );
};
