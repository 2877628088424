import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Oval: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.97 148.971C184.869 113.072 191.997 61.9979 164.892 34.8928C137.787 7.78768 86.7123 14.916 50.8139 50.8144C14.9155 86.7128 7.78713 137.787 34.8922 164.892C61.9974 191.997 113.072 184.869 148.97 148.971Z"
      fill="currentColor"
    />
  </Icon>
);

Oval.displayName = 'OvalShape';
