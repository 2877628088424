import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ReceiptRefund: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62 2.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.247.602-.227-.147-.24 8.983-.01 7.659-.006 8.354.055 8.536.088.267.339.514.613.605.403.134.433.123 2.47-.893L8 20.121l1.862.929 1.862.93h.552l1.862-.93L16 20.121l1.852.925c2.037 1.016 2.067 1.027 2.47.893.274-.091.525-.338.613-.605.061-.182.065-.877.055-8.536-.013-9.088.005-8.375-.227-8.953-.367-.916-1.287-1.641-2.283-1.802-.246-.04-12.661-.057-12.86-.018zm12.72 2.041c.115.039.263.135.361.233.324.324.299-.335.299 7.808 0 3.989-.008 7.253-.019 7.253a78.3 78.3 0 01-1.363-.67l-1.344-.67h-.55l-1.862.93-1.862.929-1.862-.929-1.862-.93h-.55l-1.344.67c-.74.368-1.353.67-1.364.67-.01 0-.014-3.298-.008-7.33l.01-7.33.111-.189c.125-.213.318-.375.539-.454.123-.044 1.269-.054 6.313-.055 5.634-.002 6.179.004 6.357.064zm-7.7 2.002c-.152.053-.431.312-1.811 1.687-1.145 1.14-1.657 1.677-1.717 1.801-.118.245-.136.51-.052.77.065.199.181.326 1.695 1.845 1.704 1.711 1.791 1.784 2.167 1.82.555.053 1.078-.428 1.078-.991 0-.364-.088-.496-.854-1.27l-.721-.73h.929c1.023 0 1.344.042 1.807.235.692.29 1.314.912 1.604 1.604.181.435.235.799.235 1.592 0 .841.04 1.011.299 1.27a.984.984 0 001.403-.001c.282-.282.311-.432.286-1.494-.014-.633-.041-.996-.087-1.206-.226-1.014-.651-1.791-1.38-2.522-.725-.727-1.493-1.148-2.521-1.38-.229-.051-.57-.072-1.434-.087l-1.133-.019.717-.726c.761-.77.85-.902.85-1.266 0-.686-.694-1.161-1.36-.932z"
      fill="currentColor"
    />
  </Icon>
);

ReceiptRefund.displayName = 'ReceiptRefundIcon';
