import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Scale: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.643 2.069a1.066 1.066 0 00-.577.59c-.052.153-.066.349-.066.905v.71l-2.499.832-2.499.833-1.415-.47c-.854-.283-1.478-.47-1.574-.47-.754 0-1.234.795-.887 1.47.168.325.319.406 1.482.792.578.192 1.065.36 1.081.375.017.015-.583 1.862-1.332 4.105-1.252 3.754-1.36 4.097-1.347 4.304.031.495.335.809 1.229 1.27.528.274.997.44 1.601.57.606.13 1.714.13 2.32 0a5.984 5.984 0 001.599-.568c.904-.468 1.194-.763 1.228-1.25.017-.238-.031-.392-1.344-4.326-.749-2.243-1.349-4.09-1.332-4.105.016-.014.83-.292 1.809-.617s1.802-.6 1.83-.61c.04-.014.05 1.36.05 6.787V20H9.927c-.894 0-1.105.01-1.267.066-.369.126-.66.538-.66.934 0 .242.119.52.299.7.311.313.157.3 3.701.3s3.39.013 3.701-.3a.984.984 0 000-1.401c-.278-.278-.393-.3-1.628-.3H13v-6.803c0-5.427.01-6.801.05-6.786l1.83.609c.979.325 1.793.603 1.809.617.017.015-.583 1.862-1.332 4.105-1.252 3.754-1.36 4.097-1.347 4.304.031.495.335.809 1.229 1.27.528.274.997.44 1.601.57.606.13 1.714.13 2.32 0a5.984 5.984 0 001.599-.568c.904-.468 1.194-.763 1.228-1.25.017-.238-.031-.392-1.344-4.326-.749-2.243-1.349-4.09-1.332-4.105.016-.014.503-.183 1.081-.375 1.163-.386 1.314-.467 1.482-.792a1.002 1.002 0 00-.887-1.47c-.096 0-.72.187-1.574.47l-1.415.47-2.499-.833L13 4.274v-.71c0-.836-.04-1.007-.299-1.265a.998.998 0 00-1.058-.23zm-4.747 10.8l.892 2.675c.019.05-.532.268-.928.364-.39.095-1.335.094-1.72-.001-.408-.101-.947-.312-.928-.363l.892-2.674c.482-1.447.885-2.63.896-2.63.011 0 .414 1.183.896 2.63zm12 0l.892 2.675c.019.05-.532.268-.928.364-.39.095-1.335.094-1.72-.001-.408-.101-.947-.312-.928-.363l.892-2.674c.482-1.447.885-2.63.896-2.63.011 0 .414 1.183.896 2.63z"
      fill="currentColor"
    />
  </Icon>
);

Scale.displayName = 'ScaleIcon';
