import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { BoxProps, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { PushNotificationScreenOption, SelectOption } from 'types';
import { Select, SelectProps } from 'ui';
import { ConnectForm } from './ConnectForm';
import { FieldLabel, FieldLabelProps } from './FieldLabel';

interface ConnectedSelectProps {
  containerProps?: BoxProps;
  labelProps?: FieldLabelProps;
  id: string;
  className?: string;
  options?: Array<SelectOption> | Array<PushNotificationScreenOption>;
  placeholder?: string;
  type?: string;
  selectProps?: SelectProps;
}

export const ConnectedSelect: FC<ConnectedSelectProps> = ({
  containerProps,
  labelProps,
  id,
  className,
  options,
  placeholder = 'Select',
  selectProps,
}) => (
  <ConnectForm>
    {({ ...formMethods }: UseFormReturn) => (
      <FormControl
        {...containerProps}
        isInvalid={!!formMethods.formState.errors[id]}
      >
        {labelProps?.text && <FieldLabel {...labelProps} />}
        <Controller
          control={formMethods?.control}
          name={id}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              value={value}
              onChange={updateOptions => {
                onChange(updateOptions);
              }}
              className={className}
              onBlur={onBlur}
              placeholder={placeholder}
              options={options || []}
              singleValueStyle="tag"
              name={id}
              {...selectProps}
            />
          )}
        />
        {formMethods.formState.errors[id] ? (
          <FormErrorMessage ml={2}>
            {formMethods.formState.errors[id].message?.toString()}
          </FormErrorMessage>
        ) : null}
      </FormControl>
    )}
  </ConnectForm>
);
