import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ExclamationOctagon: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.688 4 4 8.688v6.624L8.688 20h6.624L20 15.312V8.688L15.312 4H8.688Zm7.452-2H7.86L2 7.86v8.28L7.86 22h8.28L22 16.14V7.86L16.14 2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3 7.298c.092-.092.246-.195.343-.23a.998.998 0 0 1 1.058.23c.302.303.299.275.299 2.702s.003 2.399-.299 2.7a.984.984 0 0 1-1.402 0C10.997 12.4 11 12.428 11 10c0-2.428-.003-2.4.3-2.702ZM11 16c0-.385.288-.803.643-.934.23-.084.607-.064.818.045.712.366.715 1.41.004 1.776C11.814 17.222 11 16.729 11 16Z"
      fill="currentColor"
    />
  </Icon>
);

ExclamationOctagon.displayName = 'ExclamationOctagonIcon';
