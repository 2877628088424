import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PaperClipAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M14.925 0.464 C 14.204 0.585,13.376 0.949,12.760 1.416 C 12.539 1.584,10.701 3.397,7.498 6.607 C 2.119 11.998,2.298 11.803,1.785 12.800 C 1.219 13.899,0.960 15.070,1.013 16.280 C 1.093 18.074,1.799 19.683,3.058 20.942 C 5.217 23.101,8.485 23.612,11.200 22.215 C 12.196 21.702,12.006 21.877,17.353 16.542 C 20.306 13.595,22.278 11.599,22.326 11.505 C 22.580 11.015,22.377 10.416,21.868 10.152 C 21.632 10.031,21.245 10.032,21.000 10.157 C 20.872 10.222,19.429 11.636,15.960 15.098 C 12.045 19.005,11.039 19.988,10.788 20.153 C 9.501 20.999,7.941 21.219,6.497 20.757 C 3.939 19.939,2.488 17.278,3.183 14.677 C 3.298 14.248,3.590 13.608,3.865 13.187 C 4.116 12.802,13.723 3.167,14.120 2.902 C 14.784 2.459,15.602 2.301,16.392 2.464 C 17.432 2.679,18.297 3.451,18.627 4.460 C 18.764 4.880,18.799 5.592,18.704 6.040 C 18.627 6.405,18.395 6.897,18.148 7.220 C 18.047 7.352,15.853 9.568,13.272 12.144 C 9.988 15.423,8.532 16.849,8.422 16.900 C 7.783 17.190,7.074 16.778,7.011 16.080 C 6.966 15.590,6.661 15.929,11.542 11.040 C 15.845 6.729,15.955 6.615,16.017 6.403 C 16.095 6.134,16.095 6.066,16.021 5.816 C 15.946 5.563,15.734 5.314,15.502 5.205 C 15.262 5.092,14.887 5.091,14.640 5.203 C 14.500 5.266,13.497 6.246,10.127 9.612 C 7.745 11.992,5.713 14.048,5.613 14.180 C 5.386 14.479,5.234 14.777,5.114 15.161 C 5.033 15.418,5.020 15.539,5.023 16.020 C 5.026 16.521,5.037 16.613,5.135 16.900 C 5.464 17.858,6.124 18.518,7.082 18.845 C 7.426 18.963,7.505 18.975,7.960 18.977 C 8.551 18.980,8.902 18.896,9.418 18.629 C 9.730 18.468,9.889 18.315,14.489 13.744 C 17.101 11.149,19.335 8.916,19.452 8.783 C 20.338 7.777,20.770 6.670,20.772 5.400 C 20.773 4.614,20.631 3.968,20.299 3.249 C 19.638 1.819,18.176 0.717,16.599 0.462 C 16.139 0.387,15.379 0.388,14.925 0.464 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

PaperClipAlt.displayName = 'PaperClipAltIcon';
