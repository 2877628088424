import { ChangeEvent, FC } from 'react';
import { BoxProps, Flex, TextProps } from '@chakra-ui/react';
import { CheckboxTile, TileOptions } from './CheckboxTile';

interface CheckboxOption {
  name?: string;
  value?: string;
  icon?: string;
}

interface CheckboxTilesProps {
  options: Array<CheckboxOption>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  activeOptions: Array<CheckboxOption>;
  tileOptions?: TileOptions;
  disableTileOnActive?: boolean;
  testId?: string;
  containerProps?: BoxProps;
  tileContainerProps?: BoxProps;
  tileTextProps?: TextProps;
}

export const CheckboxTiles: FC<CheckboxTilesProps> = ({
  testId,
  options,
  activeOptions,
  onChange,
  containerProps,
  tileOptions,
  tileContainerProps,
  tileTextProps,
  disableTileOnActive = false,
}) => (
  <Flex flexDirection="column">
    <Flex
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      {...containerProps}
    >
      {options.map(option => {
        const isOptionChecked = activeOptions
          ?.map(opt => opt?.value)
          .includes(option?.value);
        const checkbox = (
          <CheckboxTile
            key={option?.value}
            onChange={onChange}
            isChecked={isOptionChecked}
            name={option?.name}
            iconName={option?.icon}
            value={option?.value}
            testId={`${testId ? `${testId}-${option?.name.toLowerCase()}-checkbox` : null}`}
            isDisabled={disableTileOnActive && isOptionChecked}
            tileContainerProps={tileContainerProps}
            tileTextProps={tileTextProps}
            {...tileOptions}
          />
        );
        return checkbox;
      })}
    </Flex>
  </Flex>
);
