import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DotsHorizontal: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.534 10.057C3.657 10.269 3 11.102 3 12c0 1.08.92 2 2 2 .523 0 1.017-.21 1.404-.596.796-.797.796-2.011 0-2.808a1.969 1.969 0 00-1.87-.539zm7 0C10.657 10.269 10 11.102 10 12c0 1.08.92 2 2 2s2-.92 2-2c0-1.096-.934-2.013-2.032-1.997a2.342 2.342 0 00-.434.054zm7 0C17.657 10.269 17 11.102 17 12c0 1.08.92 2 2 2s2-.92 2-2c0-1.096-.934-2.013-2.032-1.997a2.342 2.342 0 00-.434.054z"
      fill="currentColor"
    />
  </Icon>
);

DotsHorizontal.displayName = 'DotsHorizontalIcon';
