import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChartBar: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.62 2.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.198.482-.218.608-.239 1.496-.018.764-.027.85-.085.832-.385-.114-.525-.123-1.926-.123-1.556 0-1.64.008-2.137.21-.699.284-1.336.91-1.613 1.585-.198.482-.218.608-.239 1.496-.018.764-.027.85-.085.832-.385-.114-.525-.123-1.926-.123-1.117 0-1.511.013-1.677.056a3.068 3.068 0 00-2.247 2.244c-.083.323-.083 7.034 0 7.357.281 1.098 1.061 1.896 2.184 2.235.26.079 2.747.118 3.182.05.515-.079 1.079-.326 1.437-.627l.116-.098.209.158c.366.278.862.488 1.324.563.329.054 2.618.053 2.948 0 .491-.08 1.054-.33 1.403-.623l.116-.098.209.158c.366.278.862.488 1.324.563.329.054 2.618.053 2.948 0 1.179-.191 2.146-1.092 2.448-2.278.083-.328.084-15.032 0-15.357-.307-1.197-1.264-2.09-2.444-2.28-.241-.039-2.676-.054-2.86-.018zm2.72 2.041c.115.039.263.135.361.233.324.324.299-.322.299 7.701s.025 7.377-.299 7.701c-.28.281-.385.299-1.701.299-1.317 0-1.42-.018-1.702-.3-.324-.324-.3.328-.288-7.782l.01-7.218.111-.189c.061-.103.173-.236.25-.294.264-.202.352-.213 1.602-.215.981-.002 1.193.008 1.357.064zm-6 4c.115.039.263.135.361.233.32.32.299-.082.299 5.701 0 5.783.021 5.381-.299 5.701-.28.281-.385.299-1.701.299-1.317 0-1.42-.018-1.702-.3-.321-.321-.3.088-.289-5.782L10.02 8.7l.111-.189c.061-.103.173-.236.25-.294.264-.202.352-.213 1.602-.215.981-.002 1.193.008 1.357.064zm-6 4c.115.039.263.135.361.233.312.311.299.157.299 3.701s.013 3.39-.299 3.701C7.421 19.982 7.316 20 6 20c-1.317 0-1.42-.018-1.702-.3-.314-.314-.301-.151-.289-3.783L4.02 12.7l.111-.189c.061-.103.173-.236.25-.294.264-.202.352-.213 1.602-.215.981-.002 1.193.008 1.357.064z"
      fill="currentColor"
    />
  </Icon>
);

ChartBar.displayName = 'ChartBarIcon';
