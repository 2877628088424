import { FC } from 'react';
import { BoxProps, Flex, FormLabel, Text } from '@chakra-ui/react';

export interface FieldLabelProps {
  text: string;
  subtext?: string;
  containerProps?: BoxProps;
}

export const FieldLabel: FC<FieldLabelProps> = ({
  text,
  subtext,
  containerProps,
}) => {
  return (
    <Flex align="baseline" pl={2} mb={1} {...containerProps}>
      <FormLabel fontWeight="medium" color="text.secondary" mb={0}>
        {text}
      </FormLabel>
      {subtext && (
        <Text fontSize="xs" color="text.hint" mb={1}>
          {subtext}
        </Text>
      )}
    </Flex>
  );
};
