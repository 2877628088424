import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CalendarPlus: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M7.840 4.500 L 7.840 5.320 7.030 5.321 C 6.585 5.321,6.094 5.340,5.940 5.362 C 5.195 5.468,4.637 6.027,4.520 6.783 C 4.491 6.973,4.481 8.939,4.489 13.020 L 4.500 18.980 4.595 19.220 C 4.779 19.680,5.062 19.982,5.507 20.191 L 5.740 20.300 12.000 20.300 L 18.260 20.300 18.493 20.191 C 18.938 19.982,19.221 19.680,19.405 19.220 L 19.500 18.980 19.511 13.020 C 19.519 9.048,19.509 6.970,19.481 6.790 C 19.420 6.396,19.279 6.113,19.011 5.846 C 18.566 5.401,18.258 5.322,16.970 5.321 L 16.160 5.320 16.160 4.500 L 16.160 3.680 15.320 3.680 L 14.480 3.680 14.480 4.500 L 14.480 5.320 12.000 5.320 L 9.520 5.320 9.520 4.500 L 9.520 3.680 8.680 3.680 L 7.840 3.680 7.840 4.500 M17.840 13.680 L 17.840 18.680 12.000 18.680 L 6.160 18.680 6.160 13.680 L 6.160 8.680 12.000 8.680 L 17.840 8.680 17.840 13.680 M12.960 12.380 L 11.180 14.160 10.220 13.200 L 9.259 12.240 8.670 12.830 L 8.080 13.421 9.620 14.960 L 11.160 16.500 13.540 14.120 L 15.920 11.741 15.350 11.170 C 15.037 10.857,14.772 10.600,14.760 10.600 C 14.749 10.600,13.939 11.401,12.960 12.380 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

CalendarPlus.displayName = 'CalendarPlusIcon';
