import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Pause: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.439.34-1.308 1.21-1.649 1.651-1.146 1.479-1.814 3.078-2.079 4.975-.065.465-.065 1.975 0 2.44.223 1.596.73 2.976 1.558 4.24 1.419 2.169 3.642 3.721 6.13 4.281.832.187 1.288.236 2.22.237.894.001 1.252-.032 2.02-.183 2.571-.508 4.947-2.129 6.385-4.355.806-1.247 1.282-2.525 1.521-4.08.087-.562.099-2.033.022-2.58-.27-1.921-.96-3.558-2.106-5-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.993 7.993 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.689-9.3 7.491 7.491 0 011.46-2.073c1.693-1.742 4.142-2.639 6.516-2.386zM9.643 8.069a1.118 1.118 0 00-.343.229C8.987 8.611 9 8.455 9 12c0 3.544-.013 3.39.299 3.701.18.18.459.299.701.299.242 0 .521-.119.701-.299.312-.311.299-.157.299-3.701s.013-3.39-.299-3.701a.998.998 0 00-1.058-.23zm4 0a1.118 1.118 0 00-.343.229c-.313.313-.3.157-.3 3.702 0 3.544-.013 3.39.299 3.701.18.18.459.299.701.299.242 0 .521-.119.701-.299.312-.311.299-.157.299-3.701s.013-3.39-.299-3.701a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

Pause.displayName = 'PauseIcon';
