import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Table: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 5.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.325-.084 9.033 0 9.357.241.939.927 1.749 1.769 2.086.576.232-.039.214 7.975.227 4.97.008 7.448-.001 7.632-.029 1.196-.184 2.166-1.079 2.472-2.281.083-.326.083-9.034 0-9.357-.307-1.197-1.264-2.09-2.444-2.28-.246-.04-14.661-.057-14.86-.018zm14.72 2.041c.253.087.507.341.594.594.049.145.066.339.066.767V9H3.993l.014-.65c.013-.627.017-.657.124-.839.125-.213.318-.375.539-.454.123-.044 1.434-.054 7.313-.055 6.565-.002 7.179.004 7.357.064zM11 12v1H4v-2h7v1zm9 0v1h-7v-2h7v1zm-9 4v1H7.927c-3.463 0-3.317.012-3.628-.299-.247-.247-.299-.442-.299-1.128V15h7v1zm9-.427c0 .686-.052.881-.299 1.128-.311.311-.165.299-3.628.299H13v-2h7v.573z"
      fill="currentColor"
    />
  </Icon>
);

Table.displayName = 'TableIcon';
