import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const User: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.056c-1.663.279-3.001 1.257-3.737 2.729-.359.719-.502 1.35-.502 2.215 0 .446.02.715.072.96.22 1.035.648 1.826 1.385 2.561.744.741 1.529 1.166 2.562 1.386.471.1 1.449.1 1.92 0 .969-.206 1.772-.623 2.464-1.277a4.811 4.811 0 001.059-1.45c.794-1.62.664-3.448-.355-5-.248-.377-.955-1.077-1.348-1.335a5.128 5.128 0 00-1.8-.746c-.426-.09-1.31-.111-1.72-.043zm1.332 2.007c1.621.333 2.675 1.918 2.345 3.529-.333 1.621-1.92 2.676-3.529 2.344a3.009 3.009 0 01-2.345-2.344c-.427-2.085 1.444-3.956 3.529-3.529zm-1.552 8.998a7.823 7.823 0 00-2.54.756c-2.507 1.229-4.161 3.6-4.459 6.391-.091.861-.032 1.203.258 1.493.324.324-.322.299 7.701.299s7.377.025 7.701-.299c.293-.293.344-.589.256-1.484-.188-1.911-1.05-3.667-2.461-5.018a7.993 7.993 0 00-6.456-2.138zm2.124 2.055c1.77.357 3.315 1.511 4.133 3.085.311.599.442.966.6 1.689l.025.11H6.078l.025-.11c.158-.723.289-1.09.6-1.689a5.948 5.948 0 012.543-2.52c.675-.35 1.257-.526 2.174-.657.281-.041 1.374.017 1.744.092z"
      fill="currentColor"
    />
  </Icon>
);

User.displayName = 'UserIcon';
