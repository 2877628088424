import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Apple: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M18.6858 9.1362C18.5814 9.2172 16.7382 10.2558 16.7382 12.5652C16.7382 15.2364 19.0836 16.1814 19.1538 16.2048C19.143 16.2624 18.7812 17.499 17.9172 18.759C17.1468 19.8678 16.3422 20.9748 15.1182 20.9748C13.8942 20.9748 13.5792 20.2638 12.1662 20.2638C10.7892 20.2638 10.2996 20.9982 9.18 20.9982C8.0604 20.9982 7.2792 19.9722 6.381 18.7122C5.3406 17.2326 4.5 14.934 4.5 12.7524C4.5 9.2532 6.7752 7.3974 9.0144 7.3974C10.2042 7.3974 11.196 8.1786 11.943 8.1786C12.654 8.1786 13.7628 7.3506 15.1164 7.3506C15.6294 7.3506 17.4726 7.3974 18.6858 9.1362ZM14.4738 5.8692C15.0336 5.205 15.4296 4.2834 15.4296 3.3618C15.4296 3.234 15.4188 3.1044 15.3954 3C14.4846 3.0342 13.401 3.6066 12.7476 4.3644C12.2346 4.9476 11.7558 5.8692 11.7558 6.8034C11.7558 6.9438 11.7792 7.0842 11.79 7.1292C11.8476 7.14 11.9412 7.1526 12.0348 7.1526C12.852 7.1526 13.8798 6.6054 14.4738 5.8692Z"
      fill="currentColor"
    />
  </Icon>
);

Apple.displayName = 'AppleIcon';
