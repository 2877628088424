import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Ban: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.06 2.042c-1.67.174-3.228.729-4.582 1.633C4.023 5.315 2.5 7.771 2.073 10.78c-.067.467-.067 1.973 0 2.44.165 1.165.475 2.2.946 3.16C5.035 20.489 9.483 22.683 14 21.794c2.146-.422 4.201-1.64 5.628-3.334 2.328-2.765 2.987-6.495 1.752-9.924-.346-.961-1.046-2.159-1.753-2.998-1.566-1.859-3.778-3.078-6.247-3.442-.495-.073-1.836-.104-2.32-.054zm1.72 1.999c1.604.178 3.049.771 4.261 1.748.348.281 1.016.963 1.284 1.312a8.028 8.028 0 01.787 8.551c-.21.41-.733 1.228-.784 1.228-.037 0-11.208-11.172-11.208-11.208 0-.063.949-.65 1.41-.873 1.308-.632 2.874-.911 4.25-.758zm-1.49 8.669c3.074 3.074 5.59 5.602 5.59 5.618 0 .015-.185.151-.41.301-3.748 2.503-8.738 1.471-11.206-2.318a8.926 8.926 0 01-.464-.84 7.974 7.974 0 01.003-6.951c.225-.464.805-1.4.869-1.4.015 0 2.543 2.515 5.618 5.59z"
      fill="currentColor"
    />
  </Icon>
);

Ban.displayName = 'BanIcon';
