import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const School: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.820 2.277 C 11.656 2.318,1.129 8.338,0.985 8.473 C 0.688 8.750,0.683 9.236,0.975 9.525 C 1.034 9.583,1.846 10.067,2.781 10.600 L 4.480 11.570 4.480 14.407 C 4.480 16.351,4.494 17.300,4.524 17.421 C 4.549 17.517,4.625 17.660,4.694 17.737 C 4.784 17.837,5.812 18.427,8.300 19.808 C 11.710 21.699,11.784 21.738,12.000 21.738 C 12.216 21.738,12.290 21.699,15.700 19.808 C 18.188 18.427,19.216 17.837,19.306 17.737 C 19.375 17.660,19.451 17.517,19.476 17.421 C 19.506 17.300,19.520 16.351,19.520 14.407 L 19.520 11.570 20.615 10.945 C 21.218 10.601,21.722 10.320,21.735 10.320 C 21.749 10.320,21.760 11.916,21.760 13.867 C 21.760 17.401,21.760 17.415,21.845 17.590 C 21.971 17.850,22.189 17.980,22.500 17.980 C 22.811 17.980,23.029 17.850,23.155 17.590 C 23.240 17.415,23.240 17.415,23.240 13.126 C 23.240 8.423,23.254 8.685,22.995 8.459 C 22.920 8.394,20.474 6.978,17.559 5.314 C 13.716 3.120,12.216 2.283,12.100 2.267 C 12.012 2.256,11.886 2.260,11.820 2.277 M16.488 6.430 C 18.946 7.833,20.958 8.989,20.958 9.000 C 20.960 9.036,12.066 14.100,12.000 14.100 C 11.934 14.100,3.040 9.036,3.042 9.000 C 3.043 8.979,11.964 3.880,12.000 3.880 C 12.010 3.880,14.030 5.027,16.488 6.430 M8.680 13.969 L 11.220 15.423 11.230 17.573 L 11.241 19.723 10.968 19.572 C 10.818 19.488,9.639 18.835,8.349 18.120 L 6.003 16.820 6.001 14.629 C 6.000 12.553,6.004 12.439,6.070 12.477 C 6.109 12.498,7.283 13.170,8.680 13.969 M17.999 14.630 L 17.997 16.820 15.651 18.120 C 14.361 18.835,13.182 19.488,13.032 19.572 L 12.759 19.723 12.770 17.573 L 12.780 15.423 15.380 13.934 C 16.810 13.115,17.985 12.444,17.990 12.442 C 17.995 12.441,17.999 13.425,17.999 14.630 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

School.displayName = 'SchoolIcon';
