import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Annotation: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 3.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.325-.084 9.033 0 9.357.283 1.105 1.043 1.882 2.184 2.234.162.05.5.065 1.76.078l1.56.016 1.94 1.932c1.746 1.739 1.96 1.939 2.137 1.998a.987.987 0 00.686 0c.177-.059.391-.258 2.138-2L16.422 17h1.399c.769 0 1.513-.019 1.653-.042 1.186-.192 2.147-1.086 2.45-2.278.083-.326.083-9.034 0-9.357-.307-1.197-1.264-2.09-2.444-2.28-.246-.04-14.661-.057-14.86-.018zm14.72 2.041c.115.039.263.135.361.233.317.317.299.038.299 4.701 0 4.663.018 4.384-.299 4.701-.294.294-.33.299-2.201.299-1.415 0-1.673.009-1.843.065-.177.059-.376.244-1.927 1.789L12 18.578l-1.73-1.724c-1.551-1.545-1.75-1.73-1.927-1.789C8.173 15.009 7.915 15 6.5 15c-1.871 0-1.907-.005-2.202-.3-.318-.318-.301-.031-.289-4.782L4.02 5.7l.111-.189c.125-.213.318-.375.539-.454.123-.044 1.434-.054 7.313-.055 6.565-.002 7.179.004 7.357.064zM6.643 7.069C6.291 7.193 6 7.614 6 8c0 .242.119.521.299.701.32.32-.082.299 5.701.299 5.783 0 5.381.021 5.701-.299a.984.984 0 000-1.402c-.32-.32.085-.299-5.718-.296-4.569.003-5.183.01-5.34.066zm0 4C6.291 11.193 6 11.614 6 12c0 .242.119.521.299.701.302.302.274.299 2.701.299s2.399.003 2.701-.299a.984.984 0 000-1.402c-.303-.303-.271-.299-2.718-.296-1.851.003-2.188.012-2.34.066z"
      fill="currentColor"
    />
  </Icon>
);

Annotation.displayName = 'AnnotationIcon';
