import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DeviceTablet: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62 2.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.327-.084 15.031 0 15.357.281 1.096 1.045 1.879 2.184 2.238.177.055.818.064 5.56.075 3.614.008 5.449-.001 5.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.328.084-15.032 0-15.357-.307-1.197-1.264-2.09-2.444-2.28-.246-.04-10.662-.057-10.86-.018zm10.72 2.041c.115.039.263.135.361.233.324.324.299-.322.299 7.701s.025 7.377-.299 7.701c-.32.32.082.299-5.701.299-5.784 0-5.381.021-5.702-.3-.324-.324-.3.328-.288-7.782L6.02 4.7l.111-.189c.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.703-.002 5.18.004 5.357.064zm-5.697 13c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

DeviceTablet.displayName = 'DeviceTabletIcon';
