import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Printer: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.62 2.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.226.55-.223.518-.24 2.41l-.015 1.766-.768.02c-.505.014-.835.041-.967.08-1.125.333-1.901 1.125-2.184 2.229-.082.321-.082 5.037 0 5.357.282 1.102 1.063 1.9 2.184 2.232.132.039.462.066.965.08l.766.02.02.766c.014.503.041.833.08.965.325 1.097 1.052 1.826 2.169 2.174.173.054.654.064 3.56.076 2.233.009 3.452-.001 3.633-.029 1.173-.182 2.11-1.029 2.457-2.221.038-.129.066-.472.079-.97l.02-.77h.606c.668-.001.99-.04 1.359-.168a3.069 3.069 0 001.95-2.152c.082-.322.082-5.038 0-5.357-.307-1.197-1.264-2.09-2.444-2.28A7.543 7.543 0 0018.613 8h-.608l-.015-1.77c-.017-1.881-.015-1.857-.227-2.385-.367-.916-1.287-1.641-2.283-1.802-.245-.04-6.665-.056-6.86-.018zm6.72 2.041c.253.087.507.341.594.594.057.168.066.408.066 1.767V8H7.997l.011-1.65.012-1.65.111-.189c.125-.213.318-.375.539-.454.12-.043.775-.054 3.313-.055 2.842-.002 3.182.005 3.357.064zm4 6c.253.087.507.341.594.594.058.171.066.448.066 2.34 0 2.427.003 2.399-.299 2.701-.247.247-.442.299-1.123.299h-.569l-.02-.77c-.014-.507-.041-.838-.08-.97-.357-1.205-1.276-2.038-2.45-2.22-.189-.029-1.363-.039-3.639-.03-3.625.015-3.417.003-3.975.227-.672.269-1.322.912-1.595 1.578-.193.469-.217.616-.239 1.415l-.02.77h-.569c-.681 0-.876-.052-1.124-.3-.306-.306-.302-.27-.289-2.784L4.02 10.7l.111-.189c.125-.213.318-.375.539-.454.123-.044 1.434-.054 7.313-.055 6.565-.002 7.179.004 7.357.064zm-4 4c.253.087.507.341.594.594.058.171.066.448.066 2.34 0 2.427.003 2.399-.299 2.701-.311.312-.157.299-3.701.299-3.546 0-3.389.013-3.702-.3-.306-.306-.302-.27-.289-2.784L8.02 14.7l.111-.189c.125-.213.318-.375.539-.454.12-.043.775-.054 3.313-.055 2.842-.002 3.182.005 3.357.064z"
      fill="currentColor"
    />
  </Icon>
);

Printer.displayName = 'PrinterIcon';
