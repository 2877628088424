import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Prism: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.534 0.799 C 11.112 0.889,10.563 1.217,10.304 1.533 C 10.064 1.825,1.114 14.966,0.996 15.200 C 0.494 16.188,0.820 17.452,1.731 18.051 C 2.041 18.255,10.980 23.012,11.260 23.123 C 11.466 23.204,11.571 23.217,12.000 23.218 C 12.483 23.219,12.511 23.215,12.829 23.080 C 13.010 23.003,15.215 21.842,17.729 20.500 C 22.274 18.074,22.302 18.058,22.580 17.780 C 23.259 17.102,23.435 16.092,23.022 15.236 C 22.917 15.019,13.954 1.847,13.708 1.548 C 13.502 1.299,13.130 1.038,12.781 0.901 C 12.557 0.812,12.424 0.788,12.101 0.777 C 11.881 0.770,11.625 0.779,11.534 0.799 M11.230 16.775 L 11.220 21.437 6.900 19.136 C 3.909 17.543,2.541 16.794,2.452 16.701 C 2.291 16.531,2.223 16.291,2.271 16.065 C 2.298 15.938,3.408 14.282,6.763 9.358 L 11.220 2.820 11.230 7.467 C 11.236 10.023,11.236 14.211,11.230 16.775 M17.271 9.404 C 20.143 13.621,21.689 15.925,21.718 16.032 C 21.775 16.246,21.720 16.488,21.573 16.668 C 21.468 16.797,19.713 17.749,13.250 21.184 L 12.760 21.444 12.760 12.134 C 12.760 4.343,12.769 2.832,12.814 2.881 C 12.843 2.914,14.849 5.849,17.271 9.404 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Prism.displayName = 'PrismIcon';
