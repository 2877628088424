import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const FilterX: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.093 2.58A1 1 0 0 1 2 2h11.013a1 1 0 1 1 0 2H4.155l6.609 7.814a1 1 0 0 1 .236.646v5.922l2 1V12.46a1 1 0 0 1 .24-.649l.9-1.055a1 1 0 0 1 1.52 1.298l-.66.775V21a1 1 0 0 1-1.447.894l-4-2A1 1 0 0 1 9 19v-6.174l-7.764-9.18a1 1 0 0 1-.143-1.067Zm15.2-.287a1 1 0 0 1 1.414 0L19.5 4.086l1.793-1.793a1 1 0 1 1 1.414 1.414L20.914 5.5l1.793 1.793a1 1 0 0 1-1.414 1.414L19.5 6.914l-1.793 1.793a1 1 0 1 1-1.414-1.414L18.086 5.5l-1.793-1.793a1 1 0 0 1 0-1.414Z"
      fill="currentColor"
    />
  </Icon>
);

FilterX.displayName = 'FilterXIcon';
