import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SwitchHorizontal: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.643 2.069C15.291 2.193 15 2.614 15 3c0 .08.03.234.066.343.057.174.21.342 1.288 1.427L17.577 6h-4.861c-5.465 0-5.098-.02-5.417.299a.984.984 0 000 1.402c.319.319-.048.299 5.417.299h4.861l-1.223 1.23c-1.078 1.085-1.231 1.253-1.288 1.427-.13.389-.046.766.234 1.045a.99.99 0 001.144.186c.125-.061.79-.701 2.301-2.217 1.997-2.003 2.13-2.144 2.195-2.345a1.018 1.018 0 00-.052-.77c-.061-.125-.701-.79-2.217-2.301-1.924-1.919-2.15-2.131-2.328-2.19a1.032 1.032 0 00-.7.004zM7.64 12.068c-.154.053-.488.369-2.311 2.187-1.516 1.511-2.156 2.176-2.217 2.301a1.018 1.018 0 00-.052.77c.065.201.198.342 2.195 2.345 1.511 1.516 2.176 2.156 2.301 2.217a.99.99 0 001.144-.186c.28-.279.364-.656.234-1.045-.057-.174-.21-.342-1.288-1.427L6.423 18h4.861c5.465 0 5.098.02 5.417-.299a.984.984 0 000-1.402c-.319-.319.048-.299-5.417-.299H6.423l1.223-1.23c1.078-1.085 1.231-1.253 1.288-1.427.13-.389.045-.766-.233-1.044a1.005 1.005 0 00-1.061-.231z"
      fill="currentColor"
    />
  </Icon>
);

SwitchHorizontal.displayName = 'SwitchHorizontalIcon';
