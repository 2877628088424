import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Home: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.64 2.068c-.157.053-.776.656-4.811 4.687-3.371 3.367-4.654 4.673-4.716 4.801-.26.535 0 1.192.547 1.378.405.138.742.051 1.103-.283l.237-.22v3.883c0 2.68.014 3.948.044 4.094.157.75.798 1.391 1.548 1.548.299.062 12.517.062 12.816 0a2.062 2.062 0 001.548-1.548c.03-.146.044-1.414.044-4.094v-3.883l.237.22c.146.135.311.245.427.284a.993.993 0 001.223-1.379c-.062-.128-1.345-1.434-4.716-4.801-4.252-4.247-4.647-4.631-4.828-4.69a1.06 1.06 0 00-.703.003zm3.37 5.362L18 10.42V20h-3v-2.098c0-1.377-.015-2.171-.044-2.31a2.062 2.062 0 00-1.548-1.548C13.281 14.018 12.719 14 12 14s-1.281.018-1.408.044a2.062 2.062 0 00-1.548 1.548c-.029.139-.044.933-.044 2.31V20H6v-9.58l2.99-2.99A321.978 321.978 0 0112 4.44c.011 0 1.365 1.345 3.01 2.99zM13 18v2h-2v-4h2v2z"
      fill="currentColor"
    />
  </Icon>
);

Home.displayName = 'HomeIcon';
