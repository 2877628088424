import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Filter: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.534 2.057a2.063 2.063 0 00-1.49 1.535c-.056.272-.06 3.075-.004 3.37.023.118.098.341.168.496.122.273.232.388 3.459 3.623L9 14.422v3.362c0 3.787-.014 3.604.3 3.918a.99.99 0 001.144.186c.125-.061.79-.701 2.301-2.217 1.919-1.924 2.131-2.15 2.19-2.328.055-.167.065-.408.065-1.56v-1.362l3.315-3.32c3.202-3.207 3.32-3.331 3.46-3.621.08-.165.163-.397.185-.515.056-.3.052-3.103-.004-3.373a2.062 2.062 0 00-1.548-1.548c-.324-.067-16.594-.055-16.874.013zM20 5.289v1.289l-3.333 3.341c-3.227 3.235-3.337 3.35-3.459 3.623-.07.155-.145.378-.168.496-.022.117-.04.737-.04 1.378v1.165l-1 .999-1 .999v-2.164c0-1.291-.016-2.25-.04-2.377a2.792 2.792 0 00-.168-.496c-.122-.273-.232-.388-3.459-3.623L4 6.578V4h16v1.289z"
      fill="currentColor"
    />
  </Icon>
);

Filter.displayName = 'FilterIcon';
