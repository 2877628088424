import { FC } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { MotionBox, MotionBoxProps } from './MotionBox';

export const Loading: FC<MotionBoxProps> = props => {
  return (
    <MotionBox
      height="100%"
      flexGrow={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      {...props}
    >
      <Box
        width="100%"
        height="100%"
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner width="2rem" height="2rem" color="navy.200" />
      </Box>
    </MotionBox>
  );
};
