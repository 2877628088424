import { FC, ReactNode } from 'react';
import Uploady from '@rpldy/uploady';
import { getCookieIfPossible } from 'helpers/src/api';
import { USER_TOKEN } from 'types/src/config';
import { API_UPLOAD_FILE } from 'types/src/routes';

interface UploadFileProviderProps {
  children?: ReactNode;
  accept?: string;
  multiple?: boolean;
}

export const UploadFileProvider: FC<UploadFileProviderProps> = ({
  children,
  accept = null,
  multiple = true,
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Uploady
      destination={{
        url: API_UPLOAD_FILE,
        method: 'PUT',
        headers: {
          'X-Authorization': `Token ${getCookieIfPossible(USER_TOKEN)}`,
        },
        params: {
          bucket: 'storage',
        },
      }}
      accept={accept}
      multiple={multiple}
      customInput
      autoUpload
    >
      <>{children}</>
    </Uploady>
  );
};
