import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  BoxProps,
  FormControl,
  FormErrorMessage,
  InputGroup,
} from '@chakra-ui/react';
import { AutoTextarea, AutoTextareaProps } from 'ui';
import { ConnectForm } from './ConnectForm';
import { FieldLabel, FieldLabelProps } from './FieldLabel';

interface ConnectedAutoTextareaProps extends AutoTextareaProps {
  containerProps?: BoxProps;
  labelProps?: FieldLabelProps;
  id: string;
  placeholder?: string;
  type?: string;
}

export const ConnectedAutoTextarea: FC<ConnectedAutoTextareaProps> = ({
  containerProps,
  labelProps,
  id,
  placeholder = 'Enter',
  ...props
}) => (
  <ConnectForm>
    {({ ...formMethods }: UseFormReturn) => (
      <FormControl
        {...containerProps}
        isInvalid={!!formMethods.formState?.errors[id]}
      >
        {labelProps?.text && <FieldLabel {...labelProps} />}
        <InputGroup maxWidth="100%">
          {formMethods?.register && (
            <AutoTextarea id={id} placeholder={placeholder} {...props} />
          )}
        </InputGroup>
        {formMethods.formState?.errors[id] ? (
          <FormErrorMessage ml={2}>
            {`${formMethods.formState.errors[id].message}`}
          </FormErrorMessage>
        ) : null}
      </FormControl>
    )}
  </ConnectForm>
);
