import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const FileMinus: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M9.620 0.999 L 5.460 1.021 5.139 1.121 C 4.164 1.429,3.388 2.217,3.112 3.178 L 3.020 3.500 3.020 12.000 L 3.020 20.500 3.112 20.822 C 3.391 21.796,4.204 22.609,5.178 22.888 L 5.500 22.980 12.000 22.980 L 18.500 22.980 18.822 22.888 C 19.783 22.612,20.572 21.835,20.878 20.861 L 20.979 20.540 21.000 14.160 L 21.020 7.780 20.908 7.531 C 20.836 7.373,20.749 7.250,20.668 7.195 C 20.482 7.069,14.976 1.571,14.837 1.373 C 14.675 1.142,14.286 0.956,13.990 0.969 C 13.874 0.974,11.908 0.988,9.620 0.999 M13.009 5.690 L 13.020 8.380 13.131 8.569 C 13.203 8.691,13.309 8.797,13.431 8.869 L 13.620 8.980 16.310 8.991 L 19.000 9.003 18.998 14.591 C 18.997 19.156,18.987 20.208,18.943 20.330 C 18.864 20.551,18.702 20.744,18.489 20.869 L 18.300 20.980 12.000 20.980 L 5.700 20.980 5.508 20.867 C 5.291 20.739,5.099 20.498,5.040 20.278 C 5.013 20.180,5.003 17.333,5.010 11.914 L 5.020 3.700 5.131 3.511 C 5.256 3.298,5.449 3.136,5.670 3.057 C 5.791 3.014,6.515 3.003,9.409 3.002 L 12.997 3.000 13.009 5.690 M16.300 5.720 L 17.580 7.000 16.290 7.000 L 15.000 7.000 15.000 5.720 C 15.000 5.016,15.005 4.440,15.010 4.440 C 15.016 4.440,15.596 5.016,16.300 5.720 M8.619 14.056 C 8.378 14.131,8.248 14.232,8.128 14.436 C 8.031 14.601,8.020 14.659,8.020 15.000 C 8.020 15.343,8.031 15.398,8.131 15.569 C 8.203 15.691,8.309 15.797,8.431 15.869 L 8.620 15.980 12.003 15.980 L 15.386 15.980 15.581 15.860 C 15.828 15.707,15.961 15.463,15.989 15.109 C 16.017 14.765,15.923 14.445,15.739 14.262 C 15.472 13.994,15.549 14.000,11.983 14.003 C 9.525 14.005,8.743 14.017,8.619 14.056 "
      stroke="none"
      fillRule="evenodd"
      fill="currentColor"
    ></path>
  </Icon>
);

FileMinus.displayName = 'FileMinusIcon';
