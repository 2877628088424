import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ShoppingBag: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M10.813 2.158 C 9.381 2.525,8.237 3.427,7.569 4.714 C 7.160 5.501,7.048 6.002,7.012 7.206 L 6.988 7.993 5.864 8.007 C 4.807 8.021,4.731 8.027,4.580 8.107 C 4.396 8.206,4.196 8.407,4.109 8.580 C 4.022 8.754,3.911 9.928,3.422 15.840 C 3.049 20.358,3.031 20.603,3.046 21.011 C 3.059 21.344,3.071 21.396,3.177 21.543 C 3.392 21.841,3.559 21.928,3.996 21.967 C 4.209 21.987,7.955 21.997,12.322 21.991 C 19.867 21.981,20.267 21.976,20.404 21.908 C 20.593 21.814,20.787 21.622,20.883 21.434 C 21.019 21.168,21.018 21.156,20.558 15.600 C 20.069 9.686,19.979 8.754,19.879 8.560 C 19.792 8.391,19.591 8.196,19.400 8.095 C 19.276 8.030,19.134 8.020,18.136 8.007 L 17.012 7.993 16.989 7.166 C 16.963 6.267,16.911 5.929,16.714 5.367 C 16.215 3.944,15.056 2.785,13.633 2.286 C 13.082 2.093,12.716 2.040,11.946 2.042 C 11.325 2.043,11.218 2.055,10.813 2.158 M12.800 4.121 C 13.311 4.265,13.694 4.494,14.100 4.900 C 14.369 5.169,14.502 5.341,14.627 5.580 C 14.902 6.107,14.963 6.386,14.989 7.250 L 15.011 8.000 12.000 8.000 L 8.989 8.000 9.011 7.250 C 9.029 6.656,9.051 6.439,9.118 6.207 C 9.436 5.099,10.333 4.276,11.460 4.056 C 11.826 3.985,12.415 4.014,12.800 4.121 M7.008 10.630 C 7.026 11.338,7.059 11.458,7.298 11.690 C 7.518 11.904,7.699 11.978,8.000 11.978 C 8.301 11.978,8.482 11.904,8.702 11.690 C 8.941 11.458,8.974 11.338,8.992 10.630 L 9.008 10.000 12.000 10.000 L 14.992 10.000 15.008 10.630 C 15.026 11.338,15.059 11.458,15.298 11.690 C 15.518 11.904,15.699 11.978,16.000 11.978 C 16.301 11.978,16.482 11.904,16.702 11.690 C 16.941 11.458,16.974 11.338,16.992 10.630 L 17.008 10.000 17.544 10.000 C 17.839 10.000,18.080 10.011,18.080 10.025 C 18.080 10.127,18.885 19.733,18.904 19.850 L 18.927 20.000 12.000 20.000 L 5.073 20.000 5.097 19.850 C 5.114 19.736,5.920 10.120,5.920 10.021 C 5.920 10.009,6.161 10.000,6.456 10.000 L 6.992 10.000 7.008 10.630 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

ShoppingBag.displayName = 'ShoppingBagIcon';
