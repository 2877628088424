import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChartBarSquare: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62 3.025c-.408.08-.533.114-.75.202-.88.358-1.549 1.137-1.794 2.093-.083.326-.084 13.031 0 13.357.281 1.096 1.044 1.878 2.184 2.238.178.056.9.064 6.56.075 4.306.008 6.448-.001 6.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.327.084-13.033 0-13.357-.307-1.197-1.264-2.09-2.444-2.28-.246-.04-12.66-.057-12.86-.018zm12.72 2.041c.115.039.263.135.361.233.322.322.3-.202.3 6.701s.022 6.379-.3 6.701c-.322.322.202.299-6.7.299-6.906 0-6.38.024-6.703-.3-.323-.323-.3.208-.288-6.782L5.02 5.7l.111-.189c.125-.213.318-.375.539-.454.123-.044 1.269-.054 6.313-.055 5.634-.002 6.18.004 6.357.064zm-2.697 2.003a1.118 1.118 0 00-.343.229c-.318.318-.3.038-.3 4.702 0 4.663-.018 4.384.3 4.701a.984.984 0 001.401 0c.317-.317.3-.038.3-4.701 0-4.663.017-4.384-.3-4.701a.998.998 0 00-1.058-.23zm-4 3a1.118 1.118 0 00-.343.229c-.309.309-.3.215-.3 3.202 0 2.986-.009 2.894.3 3.201a.984.984 0 001.401 0c.308-.307.3-.215.3-3.201s.008-2.894-.3-3.201a.998.998 0 00-1.058-.23zm-4 3a1.066 1.066 0 00-.577.591c-.098.288-.098 2.392 0 2.68.126.369.538.66.934.66s.808-.291.934-.66c.098-.288.098-2.392 0-2.68-.173-.508-.762-.778-1.29-.591z"
      fill="currentColor"
    />
  </Icon>
);

ChartBarSquare.displayName = 'ChartBarSquareIcon';
