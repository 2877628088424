import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillFolder: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M20.059 3.622l-1.56-.473-.02-.008-3.71-1.124-.017-.005A.385.385 0 0014.661 2H9.338a.393.393 0 00-.109.017L5.522 3.14 5.5 3.15l-1.559.473A2.719 2.719 0 002 6.239v15.37c0 .216.175.391.39.391h19.22a.39.39 0 00.39-.39V6.238c0-1.214-.78-2.265-1.941-2.617zm-5.22-.766l2.943.892-3.087 5.386-2.207-2.207 1.638-2.837.713-1.234zm-.86-.075l-.99 1.715L12 6.21l-1.98-3.429h3.96zm-4.818.075l2.35 4.071-2.206 2.207-3.087-5.386 2.943-.892zm-3.49 18.363h-2.89v-5.782h2.89v5.782zm3.126 0H6.453v-5.782h2.344v5.782zm1.875 0H9.578v-6.172a.39.39 0 00-.39-.39H2.78V6.238c0-.867.557-1.618 1.387-1.87L5.45 3.98l3.43 5.986a.39.39 0 00.615.082l1.176-1.176v12.347zm6.875 0h-2.344v-5.782h2.344v5.782zm3.672 0h-2.89v-5.782h2.89v5.782zm0-8.633h-4.727v-1.719h4.727v1.719zm0-2.5h-5.117a.39.39 0 00-.391.39v2.5c0 .216.175.391.39.391h5.118v1.29h-6.407a.39.39 0 00-.39.39v6.172h-1.094V11.79a.39.39 0 00-.781 0v9.429h-1.094V8.09L12 7.544l2.504 2.504a.39.39 0 00.615-.082l3.43-5.986 1.283.39a1.942 1.942 0 011.387 1.869v3.847z"
      fill="currentColor"
    />
    <path
      d="M7.901 19.81a.393.393 0 00-.552 0 .393.393 0 000 .552.394.394 0 00.552 0 .393.393 0 000-.552zM16.651 19.81a.393.393 0 00-.552 0 .394.394 0 000 .552.393.393 0 00.667-.276.393.393 0 00-.115-.276zM13.214 9.77a.393.393 0 00-.553 0 .394.394 0 000 .553.393.393 0 00.667-.276.393.393 0 00-.114-.276z"
      fill="currentColor"
    />
  </Icon>
);

SkillFolder.displayName = 'SkillFolderIcon';
