import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Users: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.26 2.056c-1.663.279-3.001 1.257-3.737 2.729-.359.719-.502 1.35-.502 2.215 0 1.074.252 1.907.851 2.82.235.357.945 1.066 1.308 1.306 1.265.836 2.705 1.075 4.15.69a6.211 6.211 0 001.463-.67l.213-.145.227.154c1.167.792 2.68 1.037 4.093.662.479-.127 1.254-.506 1.66-.811a5.08 5.08 0 001.902-2.976c.13-.583.13-1.477 0-2.06a5.061 5.061 0 00-1.899-2.973c-.408-.308-1.182-.687-1.663-.814-1.412-.374-2.924-.132-4.083.655l-.235.16-.271-.179a5.125 5.125 0 00-1.757-.72c-.426-.09-1.31-.111-1.72-.043zm1.332 2.007a2.964 2.964 0 011.523.822 2.975 2.975 0 010 4.23 2.975 2.975 0 01-4.23 0 2.985 2.985 0 01.498-4.634 2.965 2.965 0 012.209-.418zm6.173.036a3.067 3.067 0 012.159 2.224c.072.282.072 1.072 0 1.354-.277 1.089-1.121 1.953-2.175 2.226-.511.132-1.177.113-1.658-.047-.266-.088-.696-.302-.716-.356-.01-.024.063-.215.162-.423.621-1.312.605-2.963-.041-4.251-.077-.153-.132-.299-.122-.325.041-.106.733-.39 1.129-.461.269-.049.988-.016 1.262.059zm-7.551 8.942c-2.437.308-4.475 1.761-5.516 3.932-.515 1.075-.697 1.964-.698 3.41 0 .894.037 1.056.299 1.318.326.326-.563.299 9.701.299 10.267 0 9.375.028 9.702-.3.285-.285.311-.426.287-1.555-.032-1.483-.224-2.295-.812-3.422a6.503 6.503 0 00-1.237-1.663 6.27 6.27 0 00-1.8-1.3c-1.14-.568-2.202-.8-3.42-.747a6.819 6.819 0 00-2.387.519l-.327.138-.433-.173c-.807-.322-1.482-.455-2.413-.477a8.929 8.929 0 00-.946.021zm1.766 2.058a4.822 4.822 0 012.542 1.379c.885.885 1.368 1.933 1.463 3.178l.027.344H3.988l.027-.344a4.94 4.94 0 011.353-3.077c.789-.834 1.726-1.327 2.892-1.523.41-.068 1.294-.047 1.72.043zm6 0a5.065 5.065 0 013.023 1.912c.285.377.68 1.166.797 1.589.096.348.199.984.2 1.23V20h-4v-.19a6.75 6.75 0 00-.042-.55 7.01 7.01 0 00-1.63-3.79l-.322-.383.287-.041c.397-.056 1.302-.028 1.687.053z"
      fill="currentColor"
    />
  </Icon>
);

Users.displayName = 'UsersIcon';
