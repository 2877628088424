import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LetterN: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.976 18h-2.32L9.504 9.952V18H7.296V6.656h2.752l4.72 7.504V6.656h2.208V18Z"
      fill="currentColor"
    />
  </Icon>
);

LetterN.displayName = 'LetterNIcon';
