import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AlertCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.069 1.039 C 8.822 1.258,6.868 2.041,5.101 3.432 C 4.640 3.795,3.606 4.863,3.231 5.363 C 2.075 6.906,1.379 8.590,1.093 10.540 C 0.995 11.212,0.995 12.788,1.093 13.460 C 1.379 15.409,2.074 17.091,3.230 18.637 C 3.685 19.244,4.756 20.315,5.363 20.770 C 6.909 21.926,8.591 22.621,10.540 22.907 C 11.212 23.005,12.788 23.005,13.460 22.907 C 15.409 22.621,17.091 21.926,18.637 20.770 C 19.244 20.315,20.315 19.244,20.770 18.637 C 21.926 17.091,22.621 15.409,22.907 13.460 C 22.959 13.104,22.977 12.727,22.977 12.000 C 22.977 11.273,22.959 10.896,22.907 10.540 C 22.621 8.590,21.925 6.906,20.769 5.363 C 20.394 4.863,19.360 3.795,18.899 3.432 C 17.302 2.175,15.548 1.412,13.580 1.120 C 12.946 1.026,11.638 0.984,11.069 1.039 M13.320 3.101 C 14.269 3.244,15.066 3.494,15.944 3.923 C 18.627 5.233,20.448 7.726,20.906 10.720 C 21.003 11.354,21.003 12.646,20.906 13.280 C 20.506 15.891,19.079 18.117,16.880 19.560 C 16.071 20.090,14.874 20.589,13.908 20.797 C 9.860 21.669,5.722 19.656,3.920 15.940 C 3.280 14.621,3.023 13.493,3.023 12.000 C 3.023 10.507,3.280 9.379,3.920 8.060 C 4.794 6.256,6.247 4.803,8.060 3.918 C 9.091 3.415,10.026 3.160,11.340 3.024 C 11.662 2.991,12.911 3.039,13.320 3.101 M11.664 7.063 C 11.436 7.146,11.257 7.297,11.131 7.511 L 11.020 7.700 11.009 9.911 C 11.001 11.377,11.012 12.175,11.040 12.279 C 11.101 12.506,11.311 12.760,11.531 12.874 C 11.932 13.080,12.390 13.012,12.700 12.702 C 13.003 12.399,13.000 12.429,13.000 10.000 C 13.000 7.573,13.003 7.601,12.701 7.299 C 12.429 7.027,12.018 6.933,11.664 7.063 M11.664 15.060 C 11.433 15.148,11.256 15.299,11.131 15.511 C 11.036 15.672,11.020 15.744,11.020 16.000 C 11.020 16.256,11.036 16.328,11.131 16.489 C 11.335 16.836,11.709 17.029,12.099 16.989 C 13.109 16.884,13.337 15.564,12.427 15.093 C 12.254 15.004,11.856 14.987,11.664 15.060 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

AlertCircle.displayName = 'AlertCircleIcon';
