import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChatAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 3.025c-.408.08-.533.114-.75.202-.88.358-1.549 1.137-1.794 2.093-.083.325-.084 9.033 0 9.357.283 1.105 1.04 1.88 2.184 2.235.164.051.515.065 1.97.078l1.77.015v2.07c0 2.344-.002 2.325.299 2.626.278.278.654.363 1.044.234.18-.059.425-.293 2.638-2.5L14.421 17h2.4c1.456 0 2.5-.017 2.653-.042 1.186-.192 2.147-1.086 2.45-2.278.083-.326.083-9.034 0-9.357-.307-1.197-1.264-2.09-2.444-2.28-.246-.04-14.66-.057-14.86-.018zm14.72 2.041c.115.039.263.135.361.233.317.317.3.038.3 4.701 0 4.663.017 4.384-.3 4.701-.307.308-.215.299-3.2.299-2.34 0-2.67.008-2.844.065-.177.059-.376.244-1.927 1.789L10 18.578v-1.362c0-1.555-.012-1.63-.299-1.917C9.4 14.997 9.427 15 7 15c-2.429 0-2.399.003-2.702-.3-.318-.318-.3-.031-.289-4.782L4.02 5.7l.111-.189c.125-.213.318-.375.539-.454.123-.044 1.434-.054 7.313-.055 6.565-.002 7.18.004 7.357.064zm-11.697 4C7.288 9.197 7 9.615 7 10c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm4 0c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.21-.109-.588-.129-.818-.045zm4 0c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.21-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

ChatAlt.displayName = 'ChatAltIcon';
