import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Star: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.7467 21L125.316 72.8099L182.493 81.1194L141.12 121.448L150.888 178.393L99.7467 151.506L48.6057 178.393L58.3733 121.448L16.9999 81.1194L74.1771 72.8099L99.7467 21Z"
      fill="currentColor"
    />
  </Icon>
);

Star.displayName = 'StarShape';
