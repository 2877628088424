import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CurrencyBangladeshi: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.44.34-1.308 1.21-1.65 1.651-1.145 1.479-1.813 3.078-2.078 4.975-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.3.869 2.577 1.358 4.2 1.607.562.087 2.033.099 2.58.022 2.016-.283 3.753-1.046 5.24-2.3 4.113-3.47 4.719-9.628 1.362-13.848-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.992 7.992 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.69-9.3 7.491 7.491 0 011.46-2.073c1.694-1.742 4.143-2.639 6.517-2.386zm-4.15 2.028C8.292 6.193 8 6.614 8 7c0 .527.473 1 1 1 .242 0 .52.119.7.299.248.247.3.442.3 1.128V10h-.573c-.686 0-.881.052-1.128.299a.984.984 0 000 1.402c.247.247.442.299 1.126.299h.57l.015 1.77c.013 1.455.027 1.806.078 1.97.245.787.662 1.364 1.272 1.758.473.305.892.447 1.44.487 1.42.104 2.69-.812 3.11-2.245.04-.134.066-.473.079-1.026.024-.975-.01-1.136-.287-1.414a.984.984 0 00-1.403-.001c-.254.254-.3.435-.3 1.201s-.044.947-.298 1.201a.984.984 0 01-1.402 0c-.293-.292-.3-.339-.3-2.128V12h1.574c1.789 0 1.836-.006 2.128-.299.18-.18.299-.459.299-.701 0-.242-.12-.521-.3-.701-.291-.292-.338-.299-2.122-.299h-1.57l-.02-.77c-.013-.507-.04-.838-.08-.97-.233-.788-.684-1.4-1.332-1.803-.615-.384-1.462-.554-1.933-.388z"
      fill="currentColor"
    />
  </Icon>
);

CurrencyBangladeshi.displayName = 'CurrencyBangladeshiIcon';
