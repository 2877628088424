import { useQuery } from '@tanstack/react-query';
import { getObjectKey, noNulls, Queries } from 'helpers';
import {
  DataKeys,
  paging,
  PagingInfo,
  PagingParams,
  ReflexResponse,
} from 'types';

export type FetchFunction<QueryVariables> = (
  variables: QueryVariables,
  query: string,
  contextOverride?: string,
  returnKey?: string | string[],
) => Promise<ReflexResponse>;

interface useDataQueryProps<QueryVariables extends paging> {
  canFetch?: boolean;
  query?: string;
  queryKey?: string;
  queryVariables?: QueryVariables;
  fetchFunction?: FetchFunction<QueryVariables>;
  dataKey?: DataKeys;
  metaKey?: string;
  pagingKey?: string;
  contextOverride?: string;
  returnKey?: string | string[];
  orderBy?: string;
  search?: string;
  pagingParams?: PagingParams;
}

const defaultProps = {
  canFetch: false,
  contextOverride: null,
  orderBy: '',
  search: '',
};

export const useDataQuery = <ObjectType, QueryVariables extends paging>(
  props: useDataQueryProps<QueryVariables> = defaultProps,
) => {
  const {
    canFetch = defaultProps.canFetch,
    fetchFunction,
    query,
    queryKey,
    dataKey,
    pagingKey,
    pagingParams,
    contextOverride = defaultProps.contextOverride,
    queryVariables,
    returnKey,
    metaKey,
  } = props;
  const search = queryVariables?.search
    ? queryVariables?.search
    : defaultProps.search;
  const orderBy = queryVariables?.orderBy
    ? queryVariables?.orderBy
    : defaultProps.orderBy;

  const _pagingKey = pagingKey ? pagingKey : `${dataKey}_paging`;
  const _metaKey = metaKey ? metaKey : `${dataKey}_meta`;
  const shouldRunQuery =
    canFetch && !!fetchFunction && !!query && !!dataKey && !!_pagingKey;

  const { data, status } = useQuery<ReflexResponse>(
    [
      dataKey,
      queryKey,
      ...Object.entries(noNulls(queryVariables)).map(
        ([key, value]) => `${key}-${value}`,
      ),
      ...Object.values(pagingParams || {}),
      ['orderBy', orderBy],
      ['search', search],
      getObjectKey(Queries, query) || 'unknown-query',
    ].filter(x => !!x), // FUTURE: reckon we can flatten this more...
    () =>
      fetchFunction(
        {
          ...queryVariables,
          ...pagingParams,
          orderBy,
          search,
        },
        query,
        contextOverride,
        returnKey,
      ),
    { enabled: shouldRunQuery },
  );

  return {
    status,
    [dataKey]: data?.[dataKey] ? data[dataKey] : ([] as Array<ObjectType>),
    meta: data?.body?.data?.[_metaKey]
      ? data?.body?.data?.[_metaKey]
      : ({} as unknown), // FUTURE: allow generic to type meta data format
    first: data?.[dataKey] ? data[dataKey]?.[0] : ([] as ObjectType),
    paging: data?.[_pagingKey] ? data[_pagingKey] : ({} as PagingInfo),
  };
};
