import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const HeartFill: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M6.980 4.055 C 6.276 4.173,5.651 4.412,5.111 4.771 C 3.337 5.952,2.574 8.137,3.240 10.128 C 3.418 10.659,3.604 11.005,3.996 11.534 C 4.075 11.641,5.766 13.328,7.752 15.282 C 11.123 18.598,11.380 18.842,11.592 18.922 C 11.872 19.027,12.226 19.012,12.492 18.884 C 12.738 18.765,19.704 11.919,20.067 11.440 C 21.312 9.796,21.306 7.533,20.052 5.863 C 18.665 4.015,16.142 3.478,13.980 4.571 C 13.448 4.840,13.079 5.116,12.510 5.674 L 12.000 6.174 11.490 5.674 C 11.210 5.399,10.845 5.079,10.680 4.963 C 10.106 4.560,9.351 4.234,8.679 4.100 C 8.247 4.014,7.369 3.991,6.980 4.055 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

HeartFill.displayName = 'HeartFillIcon';
