import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';
import { isEqual } from 'lodash';
import { SortState } from 'types';
import { PulseDot, SortMenuOption } from 'ui';

interface SortControlProps<SortOptions> {
  sort: Partial<{ [value in keyof SortOptions]: SortState }>;
  defaultSort?: Partial<{ [value in keyof SortOptions]: SortState }>;
  updateSort: (key, value: string) => void;
  options: SortOptions;
  forceSort?: boolean;
  showDot?: boolean;
}

export const SortControl = <SortOptions,>({
  sort,
  defaultSort,
  updateSort,
  options,
  forceSort = true,
  showDot = true,
}: SortControlProps<SortOptions>) => {
  const activeSortItems = (() =>
    Object.entries(sort).reduce((acc, [key, value]) => {
      if ([SortState.ASC, SortState.DESC].includes(value as SortState)) {
        acc.push(key);
      }
      return acc;
    }, []))();

  const handleUpdate = key => {
    switch (sort[key]) {
      case SortState.ASC: {
        updateSort(key, SortState.DESC);
        break;
      }
      case SortState.DESC: {
        if (!forceSort) {
          updateSort(key, SortState.NONE);
        } else {
          updateSort(key, SortState.ASC);
        }
        break;
      }
      case SortState.NONE: {
        if (!forceSort) {
          updateSort(key, SortState.ASC);
        }
        break;
      }
      default: {
        updateSort(key, SortState.ASC);
        break;
      }
    }
  };

  const updateSortItem = key => {
    // FUTURE: wire to multiple sort options
    if (activeSortItems.includes(key)) {
      handleUpdate(key);
    } else {
      activeSortItems.map(item => {
        updateSort(item, SortState.NONE);
      });
      handleUpdate(key);
    }
  };

  const isDefaultSort = defaultSort ? isEqual(sort, defaultSort) : true;

  return (
    <Popover closeOnBlur={true} placement="bottom-end">
      <PopoverTrigger>
        <Box position="relative">
          <Button
            leftIcon={<Icon name={ICON_NAMES.arrowsupdown} h={5} w={5} />}
            size="sm"
            variant="transparent"
            sx={{ span: { mr: 1 } }}
          >
            {'Sort'}
          </Button>
          {!isDefaultSort && showDot && (
            <PulseDot
              size={2}
              containerProps={{ top: 0, left: 0, position: 'absolute' }}
            />
          )}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          borderColor="main.medium"
          maxWidth={32}
          userSelect="none"
          _focus={{ borderColor: 'blackAlpha.300' }}
        >
          <PopoverBody p={0}>
            {Object.keys(sort).map((key, index) => (
              <SortMenuOption
                key={key}
                label={options[key]}
                state={sort[key] ? sort[key] : SortState.NONE}
                isFirst={index === 0}
                isLast={index === Object.keys(sort)?.length - 1}
                containerProps={{
                  onClick: () => updateSortItem(key),
                }}
              />
            ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
