import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ReorderFour: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M4.179 4.576 C 4.009 4.650,3.809 4.892,3.777 5.062 C 3.710 5.422,3.870 5.770,4.170 5.915 L 4.345 6.000 12.000 6.000 L 19.655 6.000 19.830 5.915 C 20.097 5.786,20.220 5.572,20.220 5.237 C 20.220 4.996,20.207 4.951,20.097 4.814 C 20.030 4.729,19.913 4.629,19.837 4.590 C 19.708 4.524,19.253 4.520,12.000 4.522 C 5.727 4.523,4.278 4.533,4.179 4.576 M4.167 9.086 C 3.848 9.243,3.682 9.662,3.798 10.013 C 3.868 10.226,4.111 10.424,4.366 10.477 C 4.519 10.509,6.724 10.518,12.120 10.511 C 19.570 10.500,19.662 10.499,19.817 10.420 C 19.904 10.376,20.030 10.271,20.097 10.186 C 20.207 10.049,20.220 10.004,20.220 9.763 C 20.220 9.428,20.097 9.214,19.830 9.085 L 19.655 9.000 11.997 9.000 L 4.340 9.000 4.167 9.086 M4.179 13.576 C 4.009 13.650,3.809 13.892,3.777 14.062 C 3.710 14.422,3.870 14.770,4.170 14.915 L 4.345 15.000 12.000 15.000 L 19.655 15.000 19.830 14.915 C 20.097 14.786,20.220 14.572,20.220 14.237 C 20.220 13.996,20.207 13.951,20.097 13.814 C 20.030 13.729,19.913 13.629,19.837 13.590 C 19.708 13.524,19.253 13.520,12.000 13.522 C 5.727 13.523,4.278 13.533,4.179 13.576 M4.167 18.086 C 3.848 18.243,3.682 18.662,3.798 19.013 C 3.868 19.226,4.111 19.424,4.366 19.477 C 4.519 19.509,6.724 19.518,12.120 19.511 C 19.570 19.500,19.662 19.499,19.817 19.420 C 19.904 19.376,20.030 19.271,20.097 19.186 C 20.207 19.049,20.220 19.004,20.220 18.763 C 20.220 18.428,20.097 18.214,19.830 18.085 L 19.655 18.000 11.997 18.000 L 4.340 18.000 4.167 18.086 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

ReorderFour.displayName = 'ReorderFourIcon';
