import { FC } from 'react';
import {
  BoxProps,
  Flex,
  IconProps,
  Text,
  TextProps,
  Tooltip,
} from '@chakra-ui/react';
import { Icon } from 'icons';
import { role } from 'types';

interface ShiftRolesProps {
  roles: Array<role>;
  hasLabel?: boolean;
  hasShortHelper?: boolean;
  hasIconOnly?: boolean;
  iconProps?: IconProps;
  textProps?: TextProps;
  containerProps?: BoxProps;
}

export const ShiftRoles: FC<ShiftRolesProps> = ({
  roles,
  hasLabel = true,
  hasShortHelper = false,
  hasIconOnly = false,
  iconProps,
  textProps,
  containerProps,
}) => {
  const allRoleNames = (): string => {
    let str = roles[0].name;
    for (let i = 1; i < roles.length; i++) {
      str += `, ${roles[i].name}`;
    }
    return str;
  };
  if (!roles || roles.length === 0) return <></>;
  return (
    <Flex
      display="flex"
      flexDirection="row"
      alignItems="center"
      {...containerProps}
    >
      {hasLabel && !hasIconOnly && (
        <>
          <Icon name={roles[0]?.icon} mr={2} {...iconProps} />
          <Text {...textProps}>{roles[0]?.name}</Text>
        </>
      )}
      {hasIconOnly && (
        <Tooltip
          label={allRoleNames()}
          placement="top"
          backgroundColor="blackAlpha.900"
          gutter={1}
        >
          <Flex alignItems="center" cursor="pointer">
            <Icon
              color="text.secondary"
              name={roles[0]?.icon}
              mr={2}
              {...iconProps}
            />
            {roles.length > 1 ? (
              <Text fontSize="xs" ml={1} color="text.disabled">
                {`(+${roles.length - 1} ${hasShortHelper ? '' : 'more'})`}
              </Text>
            ) : null}
          </Flex>
        </Tooltip>
      )}
      {!hasIconOnly && roles.length > 1 ? (
        <Tooltip
          label={allRoleNames()}
          placement="top"
          backgroundColor="blackAlpha.900"
          gutter={1}
        >
          <Text fontSize="xs" ml={1} cursor="pointer" color="text.disabled">
            {`(+${roles.length - 1} ${hasShortHelper ? '' : 'more'})`}
          </Text>
        </Tooltip>
      ) : null}
    </Flex>
  );
};
