import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserRemove: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.26 2.056c-1.663.279-3.001 1.257-3.737 2.729-.359.719-.502 1.35-.502 2.215 0 .446.02.715.072.96.22 1.034.647 1.824 1.385 2.562.738.738 1.528 1.165 2.562 1.385.471.1 1.449.1 1.92 0a4.992 4.992 0 002.469-1.283c.448-.423.77-.865 1.054-1.444.794-1.62.664-3.448-.355-5-.248-.377-.955-1.077-1.348-1.335a5.128 5.128 0 00-1.8-.746c-.426-.09-1.31-.111-1.72-.043zm1.332 2.007c1.621.333 2.675 1.918 2.345 3.529-.333 1.621-1.92 2.676-3.529 2.344a3.008 3.008 0 01-2.345-2.344c-.427-2.085 1.444-3.956 3.529-3.529zm5.051 7.006c-.352.124-.643.545-.643.931 0 .242.119.521.299.701.311.312.157.299 3.701.299s3.39.013 3.701-.299a.984.984 0 000-1.402c-.312-.312-.154-.299-3.718-.296-2.757.003-3.186.011-3.34.066zM8.1 13.062c-2.376.321-4.375 1.768-5.402 3.911-.515 1.075-.697 1.964-.698 3.41 0 .894.037 1.056.299 1.318.322.322-.202.299 6.701.299s6.379.023 6.701-.299c.262-.262.299-.424.299-1.318-.001-1.448-.183-2.334-.7-3.414a6.822 6.822 0 00-3.16-3.209c-1.304-.651-2.657-.885-4.04-.698zm1.88 2.037c1.016.213 1.806.642 2.542 1.378.885.887 1.368 1.934 1.463 3.179l.027.344H3.988l.027-.344a4.94 4.94 0 011.353-3.077c.789-.834 1.726-1.327 2.892-1.523.41-.068 1.294-.047 1.72.043z"
      fill="currentColor"
    />
  </Icon>
);

UserRemove.displayName = 'UserRemoveIcon';
