import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowDown: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.643 2.069a1.118 1.118 0 00-.343.229c-.326.326-.3-.409-.3 8.418v7.863l-2.73-2.725c-2.482-2.477-2.748-2.73-2.927-2.789-.39-.129-.766-.044-1.044.234s-.362.654-.234 1.044c.06.18.374.507 3.69 3.828 2.625 2.629 3.674 3.655 3.801 3.716.244.119.51.137.77.053.203-.066.392-.247 3.845-3.695 2.63-2.624 3.655-3.674 3.716-3.801a.986.986 0 00-.185-1.144c-.279-.279-.655-.364-1.045-.235-.179.059-.445.312-2.927 2.789L13 18.579v-7.863c0-8.826.026-8.092-.299-8.417a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

ArrowDown.displayName = 'ArrowDownIcon';
