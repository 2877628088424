// This is a temp solution until react-number-format
// can support react-18
// https://github.com/s-yadav/react-number-format/issues/630
import { FC } from 'react';
import NumberFormat from 'react-number-format';

export const NumberFormatWrapper: FC<any> = props => {
  // eslint-disable-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <NumberFormat {...props} />;
};
