import { FC, useEffect, useState } from 'react';
import { Flex, Switch, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { notificationDateObject } from 'types';
import { CalendarDate, CalendarValues, DatePicker } from 'ui';

export interface AvailabilityDateSelectProps {
  activeDate: notificationDateObject;
  handleUpdateDate: (date: notificationDateObject) => void;
}

export const AvailabilityDateSelect: FC<AvailabilityDateSelectProps> = ({
  activeDate,
  handleUpdateDate,
}) => {
  const [hasMounted, setHasMounted] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [singleDateSelection, setSingleDateSelection] = useState(true);
  const [customDates, setCustomDates] = useState({
    start: activeDate?.start ? new Date(activeDate.start) : null,
    end: activeDate?.end ? new Date(activeDate.end) : null,
  } as CalendarValues);

  // type update from calendar as any, because could be single date
  // or range, depending if singleDateSelection is set
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdate = (dates: any) => {
    if (singleDateSelection) {
      const update = dates as CalendarDate;
      setCustomDates({ start: update, end: update });
    } else {
      const update = {
        start: dates.start || customDates.start,
        end: dates.end || customDates.end,
      } as CalendarValues;
      setCustomDates(update);
    }
    setShouldUpdate(true);
  };

  const clearDates = () => {
    setCustomDates({ start: null, end: null });
    setShouldUpdate(true);
  };

  const handleSwitchUpdate = () => {
    setSingleDateSelection(!singleDateSelection);
    clearDates();
  };

  useEffect(() => {
    if (!activeDate || !shouldUpdate) return;
    setShouldUpdate(false);
    handleUpdateDate({
      ...activeDate,
      ...customDates,
    });
  }, [shouldUpdate, activeDate, customDates, handleUpdateDate]);

  useEffect(() => {
    if (hasMounted) return;
    const { start, end } = activeDate;
    if (!start || !end) return;
    setHasMounted(true);
    if (start.toString() === end.toString()) return;
    setSingleDateSelection(false);
  }, [hasMounted, activeDate]);

  return (
    <Flex flexDirection="column" alignItems="center" pt={4}>
      <Flex
        borderStyle="solid"
        borderTopWidth="1px"
        borderBottomWidth="1px"
        borderColor="main.light"
        width="100%"
        py={2}
      >
        <Flex
          width="100%"
          alignSelf="flex-start"
          alignItems="center"
          flexGrow={1}
        >
          {singleDateSelection && (
            <Flex>
              <Text fontWeight="medium" mr={2}>
                {'Date:'}
              </Text>
              <Text color={customDates?.start ? 'text.primary' : 'text.hint'}>
                {customDates?.start
                  ? format(customDates?.start, 'M/dd/yy')
                  : 'Select'}
              </Text>
            </Flex>
          )}
          {!singleDateSelection && (
            <Flex flexDirection="column">
              <Flex>
                <Text fontWeight="medium" mr={2}>
                  {'Start:'}
                </Text>
                <Text color={customDates?.start ? 'text.primary' : 'text.hint'}>
                  {customDates?.start
                    ? format(customDates?.start, 'M/dd/yy')
                    : 'Select'}
                </Text>
              </Flex>
              <Flex>
                <Text fontWeight="medium" mr={2}>
                  {'End:'}
                </Text>
                <Text color={customDates?.end ? 'text.primary' : 'text.hint'}>
                  {customDates?.end
                    ? format(customDates?.end, 'M/dd/yy')
                    : 'Select'}
                </Text>
              </Flex>
            </Flex>
          )}
          <Flex flexGrow={1}></Flex>
        </Flex>
        <Flex
          alignSelf={singleDateSelection ? 'flex-start' : 'center'}
          alignItems="center"
          flexShrink={0}
          userSelect="none"
        >
          <Text mr={2} fontSize="xs" color="text.hint">
            {'Range'}
          </Text>
          <Switch
            onChange={handleSwitchUpdate}
            isChecked={singleDateSelection}
            colorScheme="green"
            size="sm"
          />
          <Text ml={2} fontSize="xs" color="text.hint">
            {'Single'}
          </Text>
        </Flex>
      </Flex>
      {customDates && (
        <DatePicker
          onSelectDate={dates => onUpdate(dates)}
          value={customDates}
          disableBefore={new Date()}
          singleDateSelection={singleDateSelection}
          allowSelectSameDay={true}
        />
      )}
    </Flex>
  );
};
