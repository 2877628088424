import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ShieldExclamation: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.591 2.038a4.014 4.014 0 00-.5.368A11.008 11.008 0 015.48 4.901a16.82 16.82 0 01-1.44.093l-.98.026-.193.114c-.269.158-.401.384-.522.9-.206.872-.296 1.577-.33 2.566a12.99 12.99 0 002.85 8.54c.45.564 1.43 1.543 1.995 1.993a13.097 13.097 0 004.44 2.327c.624.19.78.189 1.44-.013a12.489 12.489 0 003.46-1.63c2.939-1.95 5.004-5.101 5.595-8.537.15-.873.184-1.291.184-2.28 0-1.197-.082-1.944-.323-2.966-.122-.516-.254-.742-.523-.9l-.193-.114-.96-.025a17.627 17.627 0 01-1.3-.072c-2.246-.309-4.087-1.116-5.798-2.539-.201-.167-.43-.331-.509-.364-.2-.084-.597-.075-.782.018zm.755 2.468c1.92 1.42 4.338 2.296 6.798 2.462l.688.047.073.522c.099.712.1 2.194.002 2.903a11.141 11.141 0 01-2.293 5.401c-.353.447-1.386 1.476-1.814 1.806a11.168 11.168 0 01-3.232 1.757c-.564.196-.602.194-1.308-.064-1.617-.589-2.959-1.464-4.181-2.725-1.633-1.686-2.656-3.803-2.986-6.175-.099-.715-.098-2.196.002-2.903l.073-.522.688-.047A12.795 12.795 0 009.86 5.6c.623-.315 1.372-.774 1.82-1.116.143-.109.283-.199.31-.201.028-.002.188.098.356.223zm-.703 3.563a1.066 1.066 0 00-.577.591c-.098.288-.098 2.392 0 2.68.126.369.538.66.934.66s.808-.291.934-.66c.098-.288.098-2.392 0-2.68-.173-.508-.762-.778-1.291-.591zm0 5.997c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

ShieldExclamation.displayName = 'ShieldExclamationIcon';
