import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const VideoFill: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M3.576 5.280 C 2.326 5.416,1.270 6.307,0.875 7.560 L 0.780 7.860 0.780 12.000 L 0.780 16.140 0.875 16.440 C 1.269 17.690,2.261 18.539,3.540 18.719 C 3.767 18.751,5.197 18.761,8.460 18.752 L 13.060 18.740 13.412 18.629 C 14.545 18.274,15.275 17.545,15.629 16.412 L 15.740 16.060 15.751 12.165 C 15.764 7.870,15.763 7.862,15.517 7.247 C 15.311 6.730,14.897 6.197,14.440 5.858 C 14.209 5.686,13.690 5.440,13.365 5.348 C 13.062 5.263,13.037 5.262,8.460 5.256 C 5.930 5.252,3.732 5.263,3.576 5.280 M21.280 6.060 C 21.097 6.121,17.185 8.847,16.928 9.093 C 16.752 9.261,16.568 9.643,16.520 9.940 C 16.469 10.252,16.469 13.748,16.520 14.060 C 16.576 14.402,16.768 14.768,16.992 14.961 C 17.366 15.280,21.065 17.855,21.258 17.929 C 21.511 18.027,21.949 18.031,22.220 17.938 C 22.615 17.803,22.932 17.515,23.116 17.122 L 23.220 16.900 23.220 12.000 L 23.220 7.100 23.114 6.873 C 22.971 6.569,22.733 6.319,22.430 6.157 C 22.208 6.038,22.140 6.022,21.820 6.012 C 21.581 6.005,21.399 6.021,21.280 6.060 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

VideoFill.displayName = 'VideoFillIcon';
