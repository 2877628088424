import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserAdd: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.26 2.056c-1.663.279-3.001 1.257-3.737 2.729-.359.719-.502 1.35-.502 2.215 0 .446.02.715.072.96.22 1.034.647 1.824 1.385 2.562.738.738 1.528 1.165 2.562 1.385.471.1 1.449.1 1.92 0a4.992 4.992 0 002.469-1.283c.448-.423.77-.865 1.054-1.444.794-1.62.664-3.448-.355-5-.248-.377-.955-1.077-1.348-1.335a5.128 5.128 0 00-1.8-.746c-.426-.09-1.31-.111-1.72-.043zm1.332 2.007c1.621.333 2.675 1.918 2.345 3.529-.333 1.621-1.92 2.676-3.529 2.344a3.008 3.008 0 01-2.345-2.344c-.427-2.085 1.444-3.956 3.529-3.529zm8.051 4.006a1.066 1.066 0 00-.577.591c-.055.162-.066.373-.066 1.267V11h-1.073c-1.235 0-1.35.021-1.628.299a.984.984 0 000 1.402c.278.278.393.299 1.628.299H17v1.073c0 .894.011 1.105.066 1.267.126.369.538.66.934.66s.808-.291.934-.66c.055-.162.066-.373.066-1.267V13h1.073c1.235 0 1.35-.021 1.628-.299a.984.984 0 000-1.402c-.278-.278-.393-.299-1.628-.299H19V9.927c0-1.235-.021-1.35-.299-1.628a.998.998 0 00-1.058-.23zM8.1 13.062c-2.376.321-4.375 1.768-5.402 3.911-.515 1.075-.697 1.964-.698 3.41 0 .894.037 1.056.299 1.318.322.322-.202.299 6.701.299s6.379.023 6.701-.299c.262-.262.299-.424.299-1.318-.001-1.448-.183-2.334-.7-3.414a6.822 6.822 0 00-3.16-3.209c-1.304-.651-2.657-.885-4.04-.698zm1.88 2.037a4.822 4.822 0 012.542 1.379c.885.885 1.368 1.933 1.463 3.178l.027.344H3.988l.027-.344a4.95 4.95 0 011.332-3.055c.802-.854 1.732-1.347 2.913-1.545.41-.068 1.294-.047 1.72.043z"
      fill="currentColor"
    />
  </Icon>
);

UserAdd.displayName = 'UserAddIcon';
