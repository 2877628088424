import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ThumbUp: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.46 2.058a1.715 1.715 0 00-.686.329A1.909 1.909 0 0010 3.924c0 .275-.106.761-.224 1.031-.055.124-.67 1.083-1.368 2.132L7.14 8.993 5.8 9.01c-1.053.014-1.383.031-1.54.079-1.14.35-1.901 1.127-2.184 2.231-.083.323-.083 7.034 0 7.357.282 1.102 1.054 1.892 2.184 2.233.151.046.514.067 1.48.087l1.28.026 1.86.479 1.86.478h2.5c2.831 0 2.72.011 3.388-.324.421-.21.801-.534 1.069-.911.096-.135 1.011-1.919 2.033-3.965 1.398-2.8 1.876-3.794 1.934-4.02.101-.4.102-1.151.001-1.52a3.064 3.064 0 00-1.604-1.94c-.585-.279-.532-.274-2.909-.29l-2.148-.014-.014-2.268c-.016-2.427-.009-2.341-.227-2.883-.291-.726-1.009-1.409-1.753-1.667-.508-.176-1.14-.225-1.55-.12zm.88 2.008c.253.087.507.341.594.594.058.171.066.443.066 2.267V9h-.573c-.686 0-.881.052-1.128.299a.984.984 0 000 1.402c.314.314.11.299 4.098.301 2.855.001 3.572.012 3.693.055.221.079.414.241.539.454.095.162.111.232.11.489l-.002.3-1.812 3.62c-1.137 2.27-1.86 3.67-1.939 3.754a1.388 1.388 0 01-.286.22c-.158.085-.192.086-2.36.098l-2.2.011-1.57-.391L8 19.221v-7.92l1.722-2.585c1.802-2.705 1.936-2.934 2.118-3.624.045-.172.103-.488.128-.702.052-.439.047-.434.372-.324zM6 15v4h-.573c-.687 0-.881-.051-1.129-.3-.314-.314-.301-.151-.289-3.783L4.02 11.7l.111-.189c.125-.213.318-.375.539-.454.098-.035.353-.054.74-.055L6 11v4z"
      fill="currentColor"
    />
  </Icon>
);

ThumbUp.displayName = 'ThumbUpIcon';
