import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LocationMarker: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3 2.025c-1.279.134-2.25.405-3.28.913a8.913 8.913 0 00-4.1 4.122c-.427.88-.662 1.632-.826 2.64-.1.614-.1 1.986 0 2.6.27 1.661.882 3.061 1.898 4.34.326.411 4.661 4.789 5.088 5.139.597.488 1.137.68 1.92.679.774 0 1.303-.184 1.89-.655.428-.344 4.766-4.72 5.119-5.163a9.095 9.095 0 001.711-3.436c.199-.791.258-1.295.258-2.204 0-1.491-.273-2.677-.916-3.98a9.014 9.014 0 00-6.722-4.921c-.39-.059-1.719-.108-2.04-.074zm1.44 2.017c.887.101 1.574.307 2.4.718 2.043 1.018 3.412 2.905 3.786 5.22.028.176.051.635.051 1.02s-.023.844-.051 1.02c-.215 1.329-.739 2.487-1.574 3.48-.167.198-1.309 1.363-2.538 2.588-2.412 2.406-2.393 2.389-2.816 2.391-.423.002-.412.012-3.02-2.608-2.66-2.671-2.73-2.752-3.218-3.731-.516-1.035-.759-2.043-.759-3.14s.243-2.104.759-3.14c1.083-2.175 3.085-3.54 5.62-3.834a8.91 8.91 0 011.36.016zm-1.43 3.021a3.918 3.918 0 00-2.131 1.116 3.903 3.903 0 00-1.165 3.001c.096 1.833 1.332 3.307 3.126 3.725.464.109 1.319.102 1.78-.014a4.03 4.03 0 002.971-2.971c.116-.461.123-1.316.014-1.78-.49-2.102-2.51-3.454-4.595-3.077zm1.098 1.981C13.31 9.232 14 10.08 14 11c0 1.08-.92 2-2 2-.92 0-1.768-.69-1.956-1.592a1.95 1.95 0 01.552-1.811 1.956 1.956 0 011.812-.553z"
      fill="currentColor"
    />
  </Icon>
);

LocationMarker.displayName = 'LocationMarkerIcon';
