import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Reader: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M6.095 1.579 C 5.088 1.800,4.250 2.561,3.892 3.580 L 3.780 3.900 3.780 12.000 L 3.780 20.100 3.892 20.420 C 4.060 20.897,4.290 21.258,4.657 21.620 C 5.182 22.137,5.722 22.394,6.471 22.479 C 6.942 22.533,17.058 22.533,17.529 22.479 C 18.278 22.394,18.818 22.137,19.343 21.620 C 19.710 21.258,19.941 20.896,20.108 20.420 L 20.220 20.100 20.220 12.000 L 20.220 3.900 20.108 3.580 C 19.789 2.671,19.069 1.952,18.171 1.646 L 17.860 1.540 12.100 1.533 C 7.405 1.527,6.295 1.535,6.095 1.579 M17.849 3.127 C 18.311 3.344,18.640 3.772,18.721 4.262 C 18.773 4.585,18.773 19.415,18.721 19.738 C 18.640 20.228,18.311 20.656,17.849 20.873 L 17.620 20.980 12.000 20.980 L 6.380 20.980 6.151 20.873 C 5.689 20.656,5.360 20.228,5.279 19.738 C 5.227 19.418,5.227 4.585,5.279 4.265 C 5.374 3.687,5.802 3.205,6.357 3.054 C 6.499 3.016,7.774 3.006,12.080 3.012 L 17.620 3.020 17.849 3.127 M8.094 5.278 C 7.436 5.437,7.303 6.307,7.886 6.640 L 8.060 6.740 12.000 6.740 L 15.940 6.740 16.120 6.634 C 16.606 6.348,16.606 5.652,16.120 5.366 L 15.940 5.260 12.080 5.254 C 9.957 5.250,8.163 5.261,8.094 5.278 M7.961 9.063 C 7.778 9.143,7.688 9.228,7.596 9.408 C 7.505 9.585,7.496 9.918,7.576 10.072 C 7.652 10.217,7.835 10.385,7.973 10.437 C 8.049 10.466,9.332 10.480,12.004 10.480 C 15.242 10.480,15.945 10.470,16.051 10.425 C 16.207 10.358,16.380 10.181,16.437 10.027 C 16.538 9.762,16.451 9.371,16.253 9.192 C 16.035 8.994,16.156 9.000,11.991 9.002 C 8.617 9.003,8.082 9.011,7.961 9.063 M7.940 12.826 C 7.652 12.978,7.520 13.189,7.520 13.496 C 7.520 13.814,7.645 14.019,7.928 14.164 C 8.069 14.236,8.190 14.240,10.116 14.240 C 11.459 14.240,12.193 14.225,12.267 14.197 C 12.540 14.095,12.731 13.807,12.731 13.500 C 12.731 13.193,12.540 12.905,12.267 12.803 C 12.193 12.774,11.461 12.761,10.107 12.762 C 8.320 12.763,8.045 12.771,7.940 12.826 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Reader.displayName = 'ReaderIcon';
