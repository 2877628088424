import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Beaker: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.643 3.069C7.291 3.193 7 3.614 7 4c0 .325.11.516.568.985l.434.444-.011 2.516c-.01 2.357-.016 2.524-.085 2.655-.041.077-1.276 1.346-2.746 2.82-2.76 2.767-2.886 2.907-3.113 3.447-.14.334-.206.686-.206 1.105-.001.506.083.88.299 1.328.306.633.745 1.08 1.36 1.382.659.324-.097.298 8.52.298h7.68l.3-.094c1.316-.411 2.16-1.546 2.16-2.906 0-.627-.163-1.15-.531-1.7-.066-.099-1.322-1.386-2.791-2.86-1.468-1.474-2.703-2.743-2.744-2.82-.069-.131-.075-.297-.085-2.655l-.011-2.516.434-.444c.342-.35.448-.487.501-.642a.98.98 0 00-.232-1.044c-.317-.317-.035-.299-4.718-.296-3.663.003-4.184.011-4.34.066zM14 7.722c0 2.974.005 3.047.236 3.601.18.43.388.703 1.062 1.389l.64.653-.319-.033a11.004 11.004 0 00-.919-.025c-1.087.012-1.971.235-3.136.79-.967.462-1.504.596-2.404.599-.517.001-.663-.015-1.353-.152l-.772-.155 1.155-1.164c1.174-1.185 1.376-1.428 1.574-1.902.231-.554.236-.627.236-3.601V5h4v2.722zm2.899 7.878c.791.158 1.501.32 1.579.36.207.107 1.545 1.469 1.62 1.649.19.455-.028 1.042-.478 1.285l-.16.086-7.34.012c-5.223.009-7.399 0-7.545-.031-.563-.121-.91-.854-.655-1.382.048-.097.315-.396.652-.729.529-.521.59-.568.81-.633l.238-.069 1.26.247c1.54.303 2.05.357 2.798.291 1.004-.087 1.705-.286 2.751-.782.77-.365 1.163-.485 1.951-.6.066-.01.337-.011.601-.004.4.012.725.063 1.918.3z"
      fill="currentColor"
    />
  </Icon>
);

Beaker.displayName = 'BeakerIcon';
