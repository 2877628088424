import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowDownCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.223 1.596.73 2.976 1.558 4.24 1.419 2.169 3.642 3.721 6.13 4.281.832.187 1.288.236 2.22.237.894.001 1.252-.032 2.02-.183 2.571-.508 4.947-2.129 6.385-4.355.806-1.247 1.282-2.525 1.521-4.08.087-.562.099-2.033.022-2.58-.27-1.921-.96-3.558-2.106-5-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.992 7.992 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.689-9.3 7.491 7.491 0 011.46-2.073c1.693-1.742 4.142-2.639 6.516-2.386zm-1.149 3.028a1.118 1.118 0 00-.343.229c-.311.31-.3.19-.3 3.417v2.86l-.73-.721C9.496 12.088 9.364 12 9 12c-.563 0-1.044.523-.991 1.078.036.376.109.463 1.82 2.167 1.459 1.453 1.652 1.631 1.828 1.69a.987.987 0 00.686 0c.176-.059.369-.237 1.828-1.69 1.711-1.704 1.784-1.791 1.82-2.167C16.044 12.523 15.563 12 15 12c-.364 0-.496.088-1.27.854l-.73.721v-2.86c0-3.226.01-3.107-.299-3.416a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

ArrowDownCircle.displayName = 'ArrowDownCircleIcon';
