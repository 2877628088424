import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ZoomOutAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M10.340 2.024 C 9.026 2.160,8.091 2.415,7.060 2.918 C 4.287 4.272,2.420 6.913,2.059 9.992 C 1.620 13.745,3.632 17.403,7.060 19.083 C 9.547 20.302,12.453 20.302,14.940 19.083 C 15.468 18.825,16.037 18.479,16.438 18.173 L 16.615 18.037 18.538 19.953 C 20.268 21.678,20.480 21.876,20.657 21.935 C 21.046 22.064,21.423 21.979,21.701 21.701 C 21.979 21.423,22.064 21.046,21.935 20.657 C 21.876 20.480,21.678 20.268,19.953 18.538 L 18.037 16.615 18.173 16.438 C 18.479 16.037,18.825 15.468,19.083 14.940 C 20.302 12.453,20.302 9.547,19.083 7.060 C 18.190 5.237,16.762 3.810,14.944 2.923 C 14.066 2.494,13.269 2.244,12.320 2.101 C 11.911 2.039,10.662 1.991,10.340 2.024 M11.686 4.042 C 13.504 4.226,15.200 5.113,16.334 6.472 C 17.537 7.916,18.125 9.741,17.967 11.550 C 17.882 12.525,17.691 13.226,17.282 14.060 C 16.234 16.199,14.244 17.632,11.894 17.940 C 9.177 18.296,6.480 17.006,5.039 14.660 C 3.538 12.217,3.688 9.083,5.416 6.787 C 6.877 4.846,9.272 3.797,11.686 4.042 M7.664 10.063 C 7.436 10.146,7.257 10.297,7.131 10.511 C 7.036 10.672,7.020 10.744,7.020 11.000 C 7.020 11.256,7.036 11.328,7.131 11.489 C 7.192 11.592,7.301 11.723,7.374 11.778 C 7.672 12.006,7.578 12.000,10.998 12.000 C 14.545 12.000,14.390 12.013,14.701 11.701 C 15.092 11.310,15.092 10.690,14.701 10.299 C 14.389 9.987,14.547 10.000,10.983 10.003 C 8.342 10.005,7.794 10.015,7.664 10.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

ZoomOutAlt.displayName = 'ZoomOutAltIcon';
