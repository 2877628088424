import { FC } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { format, isSameYear } from 'date-fns';
import { notificationDateObject } from 'types';
import { TextAndIcon } from 'ui';

interface AvailabilityDatesListProps {
  dates: Array<notificationDateObject>;
  handleRemoveDate: (date: notificationDateObject) => void;
  handleSetActiveDate: (date: notificationDateObject) => void;
  borderColor?: string;
}

export const AvailabilityDatesList: FC<AvailabilityDatesListProps> = ({
  dates,
  handleRemoveDate,
  handleSetActiveDate,
  borderColor = 'main.light',
}) => {
  const filteredDates = dates.filter(
    date =>
      date.start.toString() !== 'Invalid Date' &&
      date.end.toString() !== 'Invalid Date',
  );
  const getDateLabel = (date: notificationDateObject) => {
    if (!date.end || date.end.toString() === date.start.toString())
      return format(date.start, 'M/dd/yyyy');
    if (isSameYear(date.start, date.end)) {
      return `${format(date.start, 'M/dd')}–${format(date.end, 'M/dd/yyyy')}`;
    }
    return `${format(date.start, 'M/dd/yyyy')}–${format(date.end, 'M/dd/yyyy')}`;
  };

  return (
    <Flex flexDirection="column" width="100%">
      {filteredDates.length > 0 &&
        filteredDates.map((d, index) => (
          <Flex
            key={d.id}
            alignItems="center"
            borderBottomWidth={index !== dates.length - 1 ? 1 : 0}
            borderBottomStyle="solid"
            borderBottomColor={borderColor}
          >
            <TextAndIcon
              text={getDateLabel(d)}
              textProps={{ fontSize: 'lg', color: 'text.secondary' }}
              icon="calendar"
              iconProps={{ mr: 2, color: 'text.secondary' }}
              containerProps={{ flexGrow: 1 }}
            />
            <Flex pt={1}>
              <Button
                variant="transparent"
                onClick={() =>
                  handleSetActiveDate(
                    filteredDates.filter(dt => dt.id === d.id)[0],
                  )
                }
              >
                {'edit'}
              </Button>
              <Button
                variant="transparent"
                onClick={() =>
                  handleRemoveDate(
                    filteredDates.filter(dt => dt.id === d.id)[0],
                  )
                }
              >
                {'delete'}
              </Button>
            </Flex>
          </Flex>
        ))}
    </Flex>
  );
};
