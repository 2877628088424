import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ExternalLinkAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M14.664 2.063 C 14.436 2.146,14.257 2.297,14.131 2.511 C 14.036 2.672,14.020 2.744,14.020 3.000 C 14.020 3.256,14.036 3.328,14.131 3.489 C 14.192 3.592,14.301 3.723,14.374 3.778 C 14.661 3.997,14.695 4.000,16.715 4.000 L 18.579 4.000 13.854 8.730 C 9.515 13.074,9.124 13.476,9.065 13.657 C 8.937 14.047,9.021 14.423,9.299 14.701 C 9.577 14.979,9.953 15.063,10.343 14.935 C 10.524 14.876,10.926 14.485,15.270 10.146 L 20.000 5.421 20.000 7.280 C 20.000 8.438,20.016 9.196,20.042 9.289 C 20.103 9.509,20.315 9.762,20.531 9.874 C 20.932 10.080,21.390 10.012,21.700 9.702 C 22.013 9.389,22.000 9.546,22.000 6.000 C 22.000 2.456,22.013 2.610,21.701 2.299 C 21.389 1.987,21.547 2.000,17.983 2.003 C 15.342 2.005,14.794 2.015,14.664 2.063 M4.503 5.042 C 3.702 5.177,2.889 5.720,2.451 6.415 C 2.297 6.659,2.129 7.092,2.061 7.421 C 1.969 7.867,1.969 19.133,2.061 19.579 C 2.186 20.185,2.452 20.679,2.886 21.114 C 3.321 21.548,3.815 21.814,4.421 21.939 C 4.859 22.029,16.152 22.030,16.580 21.940 C 17.789 21.685,18.685 20.789,18.940 19.580 C 18.989 19.347,18.999 18.763,18.999 16.077 C 19.000 12.445,19.013 12.611,18.700 12.298 C 18.390 11.988,17.932 11.920,17.531 12.126 C 17.315 12.238,17.103 12.491,17.042 12.711 C 17.015 12.808,17.000 13.961,17.000 16.003 C 17.000 19.544,17.012 19.390,16.701 19.701 C 16.380 20.022,16.843 20.000,10.500 20.000 C 4.157 20.000,4.620 20.022,4.299 19.701 C 3.978 19.380,4.000 19.843,4.000 13.500 C 4.000 7.157,3.978 7.620,4.299 7.299 C 4.610 6.987,4.456 7.000,8.000 7.000 C 11.544 7.000,11.390 7.013,11.701 6.701 C 12.092 6.310,12.092 5.690,11.701 5.299 C 11.389 4.986,11.555 4.999,7.923 5.005 C 6.150 5.007,4.611 5.024,4.503 5.042 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

ExternalLinkAlt.displayName = 'ExternalLinkAltIcon';
