import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Cash: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 4.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.323-.083 7.034 0 7.357.282 1.102 1.063 1.9 2.184 2.232.132.039.462.066.965.08l.766.02.02.766c.014.503.041.833.08.965.325 1.097 1.048 1.822 2.169 2.175.177.055.818.064 5.56.075 3.614.008 5.449-.001 5.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.324.083-7.036 0-7.357-.307-1.197-1.264-2.09-2.444-2.28A7.516 7.516 0 0018.615 8h-.606l-.02-.77c-.014-.507-.041-.838-.08-.97-.354-1.196-1.268-2.03-2.429-2.217-.246-.04-10.662-.057-10.86-.018zm10.72 2.041c.253.087.507.341.594.594.049.145.066.339.066.766v.571l-3.77.013c-4.075.014-3.823.001-4.385.227-.672.269-1.322.912-1.595 1.578-.226.55-.223.518-.24 2.415L5.995 14h-.57c-.685 0-.879-.052-1.127-.3-.314-.314-.301-.151-.289-3.783L4.02 6.7l.111-.189c.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.703-.002 5.18.004 5.357.064zm4 4c.115.039.263.135.361.233.312.311.299.157.299 3.701s.013 3.39-.299 3.701c-.32.32.082.299-5.701.299-5.784 0-5.381.021-5.702-.3-.314-.314-.301-.151-.289-3.783L8.02 10.7l.111-.189c.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.703-.002 5.18.004 5.357.064zm-5.72.959c-.408.08-.533.114-.75.202-.881.358-1.551 1.139-1.793 2.093-.073.285-.073 1.074-.001 1.357.193.759.661 1.422 1.284 1.822.476.305.893.446 1.44.486 1.441.105 2.761-.869 3.124-2.305.072-.285.072-1.075 0-1.357-.304-1.194-1.264-2.09-2.444-2.28-.252-.041-.697-.05-.86-.018zm.72 2.041c.369.126.66.538.66.934 0 .527-.473 1-1 1a1.03 1.03 0 01-.94-.679.99.99 0 011.28-1.255z"
      fill="currentColor"
    />
  </Icon>
);

Cash.displayName = 'CashIcon';
