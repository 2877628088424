import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Chat: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.029 3.039c-3.208.312-5.959 1.883-7.615 4.35-.334.498-.808 1.465-.991 2.024a8.188 8.188 0 00.456 6.266c.115.228.252.483.305.567l.096.152-.626 1.671c-.345.921-.635 1.754-.645 1.857-.054.538.429 1.074.969 1.074.068 0 1.142-.204 2.386-.453l2.261-.452.178.075c.701.299 1.718.578 2.617.718.746.115 2.261.125 3.02.019 3.015-.421 5.586-1.967 7.145-4.295.337-.505.81-1.471.994-2.032a8.246 8.246 0 000-5.16c-.184-.561-.657-1.527-.994-2.032-1.377-2.056-3.526-3.5-6.119-4.11-.938-.22-2.504-.329-3.437-.239zm1.811 2.003c3.699.338 6.677 2.915 7.1 6.145.63 4.806-4.344 8.704-9.734 7.629a9.314 9.314 0 01-1.779-.556 4.262 4.262 0 00-.551-.2c-.103-.019-.576.06-1.69.283-.851.17-1.558.299-1.57.286-.013-.013.15-.485.362-1.05.211-.565.395-1.102.407-1.193.034-.248-.047-.489-.287-.852-2.105-3.183-1.093-7.181 2.349-9.281 1.56-.952 3.488-1.385 5.393-1.211zm-5.197 6.024C7.288 11.197 7 11.615 7 12c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm4 0c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm4 0c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

Chat.displayName = 'ChatIcon';
