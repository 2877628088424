import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const TrendingUp: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.643 6.069C12.291 6.193 12 6.614 12 7c0 .242.119.521.299.701.309.309.19.299 3.418.299h2.863l-2.79 2.789-2.789 2.79-1.73-1.724c-1.552-1.546-1.751-1.731-1.928-1.79a.987.987 0 00-.686 0c-.18.059-.473.34-3.328 3.19-2.258 2.254-3.155 3.174-3.216 3.301a.986.986 0 00.185 1.144c.279.279.655.364 1.045.235.179-.059.445-.312 2.927-2.789L9 12.422l1.73 1.724c1.551 1.545 1.75 1.73 1.927 1.789a.987.987 0 00.686 0c.18-.059.479-.347 3.427-3.289L20 9.421v2.862c0 3.228-.01 3.109.299 3.418a.984.984 0 001.402 0c.317-.317.299-.038.299-4.701 0-4.663.018-4.384-.299-4.701-.317-.317-.035-.299-4.718-.296-3.663.003-4.184.011-4.34.066z"
      fill="currentColor"
    />
  </Icon>
);

TrendingUp.displayName = 'TrendingUpIcon';
