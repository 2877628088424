import { FC } from 'react';
import {
  BoxProps,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import { pluralize } from 'helpers';
import { ICON_NAMES } from 'icons';
import { location, user } from 'types';
import { TextAndIcon } from 'ui';

interface LocationsInlineProps extends user {
  locations?: location[];
  containerProps?: BoxProps;
}

export const LocationsInline: FC<LocationsInlineProps> = ({
  locations,
  containerProps,
}) => {
  const numberLocations = locations?.length;
  return (
    <Flex {...containerProps}>
      <Popover trigger="hover" gutter={0}>
        <PopoverTrigger>
          <Flex
            cursor={numberLocations > 0 ? 'pointer' : 'default'}
            minWidth="128px"
          >
            <TextAndIcon
              text={
                numberLocations > 0
                  ? `${numberLocations} ${pluralize('Location', numberLocations)}`
                  : 'No Locations'
              }
              textProps={{ ml: 1 }}
              icon={ICON_NAMES.locationmarker}
              containerProps={{ color: 'blackAlpha.600' }}
            />
          </Flex>
        </PopoverTrigger>
        {numberLocations > 0 && (
          <Portal>
            <PopoverContent
              borderColor="blackAlpha.200"
              maxWidth={64}
              maxHeight={80}
              overflowY="hidden"
            >
              <PopoverBody
                p={0}
                sx={{
                  '::-webkit-scrollbar': {
                    display: 'none',
                  },
                  scrollbarGutter: 'stable both-edges',
                  '-ms-overflow-style': 'none',
                  scrollbarWidth: 'none',
                }}
                _hover={{ overflowY: 'scroll' }}
                _active={{ overflowY: 'scroll' }}
                _focus={{ overflowY: 'scroll' }}
              >
                <Flex flexDirection="column" gap={1} pl={2} pr={2} py={3}>
                  {locations.map(location => (
                    <Text key={location?.uuid} color="blackAlpha.600">
                      {`${location?.retailer?.name} - ${location?.name}`}
                    </Text>
                  ))}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        )}
      </Popover>
    </Flex>
  );
};
