import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Tv: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M6.643 1.069 C 6.291 1.193,6.000 1.614,6.000 2.000 C 6.000 2.080,6.029 2.234,6.065 2.343 C 6.124 2.520,6.309 2.719,7.853 4.268 L 9.575 5.997 6.517 6.010 C 3.114 6.025,3.336 6.008,2.704 6.312 C 1.836 6.729,1.263 7.466,1.060 8.425 C 0.970 8.850,0.971 20.145,1.062 20.581 C 1.310 21.785,2.215 22.690,3.419 22.938 C 3.681 22.993,4.700 23.000,12.000 23.000 C 21.073 23.000,20.544 23.013,21.150 22.767 C 21.850 22.482,22.482 21.850,22.767 21.150 C 23.010 20.554,23.000 20.826,23.000 14.500 C 23.000 8.172,23.010 8.447,22.766 7.849 C 22.499 7.193,21.964 6.633,21.296 6.312 C 20.664 6.008,20.886 6.025,17.483 6.010 L 14.425 5.997 16.147 4.268 C 17.691 2.719,17.876 2.520,17.935 2.343 C 18.064 1.954,17.979 1.577,17.701 1.299 C 17.423 1.021,17.047 0.936,16.657 1.065 C 16.479 1.124,16.247 1.343,14.230 3.354 L 12.000 5.578 9.770 3.354 C 7.753 1.343,7.521 1.124,7.343 1.065 C 7.101 0.985,6.876 0.986,6.643 1.069 M20.340 8.066 C 20.455 8.105,20.603 8.201,20.701 8.299 C 21.022 8.620,21.000 8.157,21.000 14.500 C 21.000 20.843,21.022 20.380,20.701 20.701 C 20.376 21.026,21.144 21.000,12.000 21.000 C 2.856 21.000,3.624 21.026,3.299 20.701 C 2.978 20.380,3.000 20.843,3.000 14.500 C 3.000 8.156,2.978 8.620,3.300 8.298 C 3.617 7.981,2.858 8.007,11.983 8.003 C 19.481 8.000,20.162 8.005,20.340 8.066 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Tv.displayName = 'TvIcon';
