import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Cloud: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.196 4.004a7.944 7.944 0 00-.724.153 5.97 5.97 0 00-4.473 5.768v.495l-.237.114c-.651.312-1.498 1.05-1.89 1.646-.597.906-.851 1.748-.851 2.82 0 .446.02.715.072.96.22 1.035.648 1.826 1.385 2.561.743.741 1.526 1.164 2.562 1.386.319.068.661.073 5.52.073h5.18l.42-.092c2.455-.539 4.203-2.294 4.728-4.745.124-.583.125-1.71.001-2.286-.541-2.502-2.385-4.311-4.857-4.763-.253-.046-.365-.084-.381-.13a7.769 7.769 0 00-.478-1.024 6.03 6.03 0 00-4.313-2.919 7.65 7.65 0 00-1.664-.017zm1.775 2.07c.712.19 1.325.54 1.831 1.045.587.585.862 1.099 1.139 2.133.072.269.268.51.516.637.123.062.282.091.643.114.791.052 1.356.216 1.94.565.934.558 1.562 1.408 1.845 2.497.075.292.092.459.092.935 0 .476-.017.643-.092.935-.389 1.494-1.422 2.531-2.945 2.955l-.32.089-4.94.012c-4.419.011-4.974.005-5.26-.053-1.629-.329-2.688-1.914-2.357-3.53.239-1.165 1.068-2.011 2.298-2.345.381-.103.681-.432.742-.811.014-.087-.006-.339-.044-.568-.09-.538-.065-1.284.06-1.744a5.15 5.15 0 01.566-1.247c.211-.307.733-.828 1.026-1.023a3.917 3.917 0 011.489-.634c.459-.101 1.317-.083 1.771.038z"
      fill="currentColor"
    />
  </Icon>
);

Cloud.displayName = 'CloudIcon';
