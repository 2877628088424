import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const StoreFrontAltEdit: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.166.999a.808.808 0 0 1 1.141 0l2.456 2.456a.808.808 0 0 1 0 1.142l-4.92 4.929a.808.808 0 0 1-.571.237h-2.465A.808.808 0 0 1 15 8.955V6.491c0-.215.086-.42.238-.572L20.166 1Zm-3.551 5.827v1.322h1.322l4.113-4.121-1.314-1.315-4.121 4.114Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.199 4H14v2h-1l.761 3.804v.083l.011.043c.006.018.008.025.008.07 0 1.103.897 2 2 2a2.003 2.003 0 0 0 1.731-1H19l.001 10.001c0 1.103-.897 2-2 2h-14c-1.103 0-2-.897-2-2v-8.382a3.97 3.97 0 0 1-1-2.618c0-.182.05-.36.143-.515l2.709-4.514a2.008 2.008 0 0 1 1.714-.971L11 4h2.199ZM8.82 6.001h2.361l.813 4.065a1.999 1.999 0 0 1-1.993 1.935 1.999 1.999 0 0 1-1.993-1.935l.812-4.065Zm-4.253 0h2.214l-.76 3.804.02.004c-.004.019-.01.037-.015.055a.495.495 0 0 0-.025.137c0 1.103-.897 2-2 2a2.003 2.003 0 0 1-1.984-1.749l2.55-4.251Zm3.434 15v-3h4v3h-4Zm6 0v-3c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v3h-3v-7.142c.321.083.652.142 1 .142a3.99 3.99 0 0 0 3-1.357 3.99 3.99 0 0 0 3 1.357 3.99 3.99 0 0 0 3-1.357 3.991 3.991 0 0 0 3 1.357c.348 0 .679-.059 1-.142v7.142h-3Z"
      clipRule="evenodd"
    />
  </Icon>
);

StoreFrontAltEdit.displayName = 'StoreFrontAltEditIcon';
