import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SocialTwitter: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M15.263 4.044 C 14.576 4.145,13.833 4.466,13.295 4.897 C 12.588 5.464,12.120 6.182,11.874 7.080 C 11.773 7.448,11.730 8.331,11.794 8.738 C 11.818 8.891,11.830 9.023,11.822 9.031 C 11.796 9.058,10.933 8.960,10.447 8.876 C 8.558 8.550,6.699 7.718,5.213 6.536 C 4.661 6.096,3.958 5.417,3.575 4.953 C 3.490 4.849,3.409 4.764,3.396 4.763 C 3.358 4.759,3.098 5.348,3.007 5.645 C 2.659 6.779,2.859 8.079,3.539 9.099 C 3.738 9.398,4.265 9.952,4.540 10.151 C 4.686 10.257,4.646 10.260,4.193 10.181 C 3.767 10.107,3.366 9.984,3.066 9.836 C 2.937 9.772,2.823 9.720,2.811 9.720 C 2.780 9.720,2.840 10.305,2.901 10.601 C 2.981 10.988,3.183 11.517,3.369 11.827 C 3.926 12.755,4.872 13.476,5.853 13.723 C 5.976 13.754,6.028 13.783,5.996 13.802 C 5.878 13.876,5.238 13.930,4.754 13.909 L 4.247 13.886 4.365 14.173 C 4.901 15.476,6.177 16.472,7.533 16.646 C 8.098 16.719,8.074 16.703,7.863 16.864 C 7.380 17.233,6.535 17.687,5.870 17.936 C 4.875 18.307,3.992 18.451,2.819 18.432 C 2.393 18.426,2.057 18.433,2.074 18.450 C 2.156 18.532,3.057 19.017,3.491 19.212 C 4.914 19.853,6.369 20.184,7.960 20.228 C 10.546 20.300,12.864 19.648,14.860 18.288 C 15.498 17.853,15.830 17.574,16.462 16.942 C 17.112 16.292,17.430 15.906,17.914 15.179 C 19.193 13.256,19.875 11.126,19.948 8.829 L 19.972 8.038 20.396 7.685 C 20.629 7.490,20.937 7.207,21.080 7.055 C 21.466 6.645,22.013 5.960,21.954 5.960 C 21.945 5.960,21.803 6.012,21.637 6.076 C 21.073 6.293,19.759 6.612,19.695 6.548 C 19.686 6.539,19.774 6.469,19.889 6.392 C 20.509 5.981,21.061 5.277,21.360 4.521 L 21.438 4.323 20.969 4.557 C 20.711 4.686,20.327 4.856,20.115 4.935 C 19.727 5.079,19.009 5.280,18.884 5.280 C 18.847 5.280,18.745 5.207,18.658 5.118 C 18.461 4.916,18.022 4.611,17.680 4.439 C 16.954 4.074,16.061 3.928,15.263 4.044 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

SocialTwitter.displayName = 'SocialTwitterIcon';
