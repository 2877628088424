import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Gcal: FC<IconProps> = props => (
  <Icon {...props}>
    <path d="M17.5 6.5H6.5V17.5H17.5V6.5Z" fill="white" />
    <path
      d="M12.84 10.46L13.344 11.18L14.136 10.604V14.78H15V9.308H14.28L12.84 10.46Z"
      fill="#1E88E5"
    />
    <path
      d="M11.4715 11.8725C11.784 11.5855 11.978 11.1875 11.978 10.748C11.978 9.8745 11.2115 9.164 10.2695 9.164C9.4685 9.164 8.7835 9.6685 8.6045 10.3905L9.433 10.601C9.5155 10.269 9.867 10.028 10.2695 10.028C10.7405 10.028 11.124 10.351 11.124 10.748C11.124 11.145 10.7405 11.468 10.2695 11.468H9.771V12.332H10.2695C10.81 12.332 11.266 12.7075 11.266 13.152C11.266 13.604 10.833 13.972 10.3005 13.972C9.8195 13.972 9.4085 13.667 9.3435 13.263L8.5 13.401C8.631 14.219 9.405 14.836 10.3 14.836C11.3035 14.836 12.12 14.0805 12.12 13.152C12.12 12.6405 11.868 12.1815 11.4715 11.8725Z"
      fill="#1E88E5"
    />
    <path d="M17 21H7L6.5 19L7 17H17L17.5 19L17 21Z" fill="#FBC02D" />
    <path d="M19 17.5L21 17V7L19 6.5L17 7V17L19 17.5Z" fill="#4CAF50" />
    <path
      d="M17 7L17.5 5L17 3H4.5C3.6715 3 3 3.6715 3 4.5V17L5 17.5L7 17V7H17Z"
      fill="#1E88E5"
    />
    <path d="M17 17V21L21 17H17Z" fill="#E53935" />
    <path d="M19.5 3H17V7H21V4.5C21 3.6715 20.3285 3 19.5 3Z" fill="#1565C0" />
    <path d="M4.5 21H7V17H3V19.5C3 20.3285 3.6715 21 4.5 21Z" fill="#1565C0" />
  </Icon>
);

Gcal.displayName = 'GcalIcon';
