import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Trash: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.534 2.057a2.063 2.063 0 00-1.49 1.535C8.018 3.717 8 4.255 8 4.902V6H5.927c-2.346 0-2.327-.002-2.628.299a.984.984 0 000 1.402c.17.171.458.299.668.299.088 0 .113.019.113.083 0 .046.18 2.615.401 5.71.312 4.376.418 5.696.478 5.936.253 1.019 1.043 1.838 2.082 2.158l.299.092 4.518.011c3.824.01 4.566.003 4.827-.046a2.93 2.93 0 001.929-1.277c.235-.356.393-.722.462-1.067.035-.176.844-11.215.844-11.517 0-.064.025-.083.113-.083.21 0 .498-.128.668-.299a.984.984 0 000-1.402C20.4 5.998 20.419 6 18.073 6H16V4.902c0-.647-.018-1.185-.044-1.31a2.062 2.062 0 00-1.548-1.548c-.313-.065-4.598-.054-4.874.013zM14 5v1h-4V4h4v1zm3.92 3.05c0 .028-.18 2.556-.399 5.618-.279 3.907-.415 5.608-.455 5.703-.079.191-.29.419-.482.521l-.164.088H7.58l-.164-.088a1.198 1.198 0 01-.482-.521c-.04-.095-.176-1.796-.455-5.703-.219-3.062-.399-5.59-.399-5.618C6.08 8.01 7.291 8 12 8s5.92.01 5.92.05zm-8.277 2.019a1.118 1.118 0 00-.343.229c-.313.313-.3.157-.3 3.702 0 3.544-.013 3.39.299 3.701.18.18.459.299.701.299.242 0 .521-.119.701-.299.312-.311.299-.157.299-3.701s.013-3.39-.299-3.701a.998.998 0 00-1.058-.23zm4 0a1.118 1.118 0 00-.343.229c-.313.313-.3.157-.3 3.702 0 3.544-.013 3.39.299 3.701.18.18.459.299.701.299.242 0 .521-.119.701-.299.312-.311.299-.157.299-3.701s.013-3.39-.299-3.701a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

Trash.displayName = 'TrashIcon';
