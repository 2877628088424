import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Wifi: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.08 4.023c-.077.009-.362.035-.634.059-3.423.296-6.899 1.878-9.43 4.291-.536.511-.638.694-.606 1.094a.98.98 0 001.432.812c.085-.043.346-.259.581-.48A13.869 13.869 0 019.52 6.225c.885-.161 1.398-.203 2.48-.203.578 0 1.219.023 1.48.052 3.089.351 5.875 1.632 8.096 3.72.24.226.506.446.59.489a.94.94 0 00.718.063.984.984 0 00.622-1.366c-.155-.332-1.269-1.337-2.266-2.043-2.236-1.584-4.822-2.554-7.62-2.858-.405-.044-2.282-.085-2.54-.056zm-.06 5.021c-2.489.232-4.864 1.31-6.593 2.992-.172.167-.346.367-.386.445-.175.337-.126.791.118 1.085.166.201.484.354.734.354.347 0 .486-.07.936-.471.822-.733 1.391-1.123 2.231-1.53 1.32-.639 2.45-.897 3.94-.897 1.488 0 2.638.262 3.94.896.843.41 1.411.8 2.231 1.531.45.401.589.471.936.471.705 0 1.179-.801.853-1.441-.106-.207-.796-.853-1.367-1.279-2.176-1.626-4.909-2.404-7.573-2.156zm.3 4.78l-.48.082a6.953 6.953 0 00-1.733.571c-.837.426-1.679 1.09-1.877 1.48a.986.986 0 00.964 1.433c.298-.027.426-.096.767-.409a4.488 4.488 0 014.488-.94 4.247 4.247 0 011.59.94c.341.313.469.382.767.409.758.069 1.303-.717.979-1.413-.181-.387-1.066-1.088-1.885-1.493-.944-.466-1.675-.642-2.76-.664a10.295 10.295 0 00-.82.004zm.323 5.242c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

Wifi.displayName = 'WifiIcon';
