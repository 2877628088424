import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Parallelogram: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.004 146.049H33.7436C21.6155 146.049 13.5168 133.543 18.4868 122.478L45.234 62.8774C46.5541 59.9353 48.6966 57.4376 51.4035 55.6852C54.1104 53.9327 57.2662 53.0002 60.4908 53H165.752C177.88 53 185.975 65.5061 181.008 76.5715L154.261 136.172C152.941 139.114 150.799 141.612 148.092 143.364C145.385 145.116 142.229 146.049 139.004 146.049V146.049Z"
      fill="currentColor"
    />
  </Icon>
);

Parallelogram.displayName = 'ParallelogramShape';
