import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Okay: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.156 84.5412C172.548 74.8729 171.461 65.1471 169.023 56.3289C165.051 41.9466 153.917 37.8807 139.931 38.0026C112.232 38.243 80.7501 46.2721 54.5342 55.3829C32.8127 62.9294 23.2141 78.6722 26.6982 101.667C28.4507 113.196 32.358 124.28 34.8091 135.679C39.2652 156.397 48.1636 163.631 69.5889 160.435C86.5118 157.908 103.263 154.339 119.746 149.747C133.236 145.991 149.811 142.631 161.446 134.592C171.327 127.77 174.494 115.893 174.031 104.44C173.773 98.0594 173.582 91.3142 173.156 84.5412Z"
      fill="currentColor"
    />
  </Icon>
);

Okay.displayName = 'OkayShape';
