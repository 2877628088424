import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CurencyYen: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.223 1.596.73 2.976 1.558 4.24 1.419 2.169 3.642 3.721 6.13 4.281.832.187 1.288.236 2.22.237.894.001 1.252-.032 2.02-.183 2.57-.508 4.947-2.129 6.385-4.355.806-1.247 1.282-2.525 1.52-4.08.088-.562.1-2.033.023-2.58-.27-1.921-.96-3.558-2.106-5-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.992 7.992 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.69-9.3 7.491 7.491 0 011.46-2.073c1.694-1.742 4.143-2.639 6.517-2.386zm-4.15 3.028c-.473.167-.756.761-.587 1.236.03.085.399.726.818 1.425L9.637 11h-.392c-.49 0-.72.073-.946.299a.984.984 0 000 1.402c.274.274.402.299 1.545.299h.989l.084.135c.068.11.083.199.083.5V14H9.927c-1.235 0-1.35.021-1.628.299a.984.984 0 000 1.402c.278.278.393.299 1.628.299H11v.573c0 .686.052.881.299 1.128a.984.984 0 001.402 0c.247-.247.299-.442.299-1.128V16h1.073c1.235 0 1.35-.021 1.628-.299a.984.984 0 000-1.402c-.278-.278-.393-.299-1.628-.299H13v-.365c0-.301.015-.39.083-.5l.084-.135h.989c.816 0 1.024-.012 1.184-.066.369-.126.66-.538.66-.934 0-.242-.12-.521-.3-.701-.225-.226-.456-.299-.945-.299h-.392l.764-1.27c.419-.699.788-1.34.818-1.425C16.163 7.691 15.66 7 14.998 7c-.191 0-.457.095-.624.222-.09.068-.478.678-1.234 1.939C12.532 10.172 12.02 11 12 11c-.02 0-.532-.828-1.14-1.839C10.104 7.9 9.715 7.29 9.626 7.222a1.036 1.036 0 00-.983-.153z"
      fill="currentColor"
    />
  </Icon>
);

CurencyYen.displayName = 'CurencyYenIcon';
