import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  BoxProps,
  Flex,
  FormControl,
  FormErrorMessage,
  Switch,
  SwitchProps,
} from '@chakra-ui/react';
import { SelectOption } from 'types';
import { ConnectForm } from './ConnectForm';
import { FieldLabel, FieldLabelProps } from './FieldLabel';

interface ConnectedSwitchProps {
  containerProps?: BoxProps;
  wrapperProps?: BoxProps;
  labelProps?: FieldLabelProps;
  id: string;
  options?: Array<SelectOption>;
  placeholder?: string;
  type?: string;
  switchProps?: SwitchProps;
}

export const ConnectedSwitch: FC<ConnectedSwitchProps> = ({
  containerProps,
  wrapperProps,
  labelProps,
  id,
  switchProps,
}) => (
  <ConnectForm>
    {({ ...formMethods }: UseFormReturn) => (
      <FormControl
        {...containerProps}
        isInvalid={!!formMethods.formState.errors[id]}
      >
        <Flex flexDirection="column" {...wrapperProps}>
          {labelProps?.text && <FieldLabel {...labelProps} />}
          <Controller
            control={formMethods?.control}
            name={id}
            render={({ field: { onChange, onBlur, value } }) => (
              <Switch
                id={id}
                onChange={onChange}
                onBlur={onBlur}
                isChecked={value}
                {...switchProps}
              />
            )}
          />
        </Flex>
        {formMethods.formState.errors[id] ? (
          <FormErrorMessage ml={2}>
            {formMethods.formState.errors[id].message?.toString()}
          </FormErrorMessage>
        ) : null}
      </FormControl>
    )}
  </ConnectForm>
);
