import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillStylist: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M14.344 14.734a.39.39 0 100 .782.39.39 0 000-.782zM9.656 14.734a.39.39 0 100 .782.39.39 0 000-.782z"
      fill="currentColor"
    />
    <path
      d="M8.368 12.376c-.03.04-.06.085-.089.13-.449.677-.952 1.612-1.31 2.343a31.847 31.847 0 00-2.38 6.671.39.39 0 00.38.48H19.03a.39.39 0 00.307-.148c.142-.18.06-.37.059-.393a30.935 30.935 0 00-2.038-5.943c-.85-1.824-1.611-2.916-1.718-3.108a.43.43 0 01-.014-.224.389.389 0 00.045-.164c.075-.206.22-.45.383-.728.393-.664.917-1.551 1.009-2.81.965-.03 1.793-.72 1.94-1.625a1.955 1.955 0 00-1.926-2.279h-1.953a1.173 1.173 0 01-1.172-1.172V2.391a.39.39 0 00-.39-.391h-3.126a.39.39 0 00-.39.39v1.016c0 .646-.526 1.172-1.172 1.172h-1.87c-.994 0-1.859.7-2.01 1.628a1.955 1.955 0 001.927 2.278h.014c.093 1.253.616 2.14 1.007 2.805.165.279.31.525.385.732a.389.389 0 00.044.163.442.442 0 01-.004.192zm10.167 8.843H5.465a31.073 31.073 0 012.204-6.025c.673-1.37 1.23-2.22 1.339-2.37a.39.39 0 00.08-.134c.036-.098.059-.197.069-.3h.788l-.266 1.136a.39.39 0 00.76.178l.308-1.313h.862v2.734a.39.39 0 00.782 0v-2.734h.862l.308 1.313a.39.39 0 10.76-.178l-.266-1.135h.789c.01.106.034.21.074.312.131.336 2.28 3.168 3.617 8.516zM18.234 6.73c-.086.529-.576.934-1.156.97v-.388c0-.647-.526-1.172-1.172-1.172h-.39v-.782h1.562c.702 0 1.277.623 1.156 1.372zm-7.406-3.325v-.625h2.344v.625c0 .943.672 1.733 1.562 1.914v.897c-1.155.221-2.16.911-2.734 1.787-.57-.87-1.572-1.564-2.734-1.787V5.32a1.956 1.956 0 001.562-1.914zM5.766 6.332c.09-.555.623-.973 1.24-.973h1.478v.782h-.39c-.646 0-1.172.525-1.172 1.171v.391a1.172 1.172 0 01-1.156-1.371zm1.937 1.762v-.781c0-.216.175-.391.39-.391h.391c1.372 0 2.724.883 3.148 2.083a.39.39 0 00.736 0c.426-1.204 1.78-2.083 3.148-2.083h.39c.216 0 .39.175.39.39v.782c0 1.253-.506 2.112-.913 2.801a6.793 6.793 0 00-.387.714H9.004c-.1-.227-.236-.46-.388-.717-.406-.69-.913-1.55-.913-2.798z"
      fill="currentColor"
    />
  </Icon>
);

SkillStylist.displayName = 'SkillStylistIcon';
