import { FC, useCallback, useRef, useState } from 'react';
import { BoxProps, Button, Flex } from '@chakra-ui/react';
import {
  useBatchFinishListener,
  useBatchStartListener,
  useFileInput,
  useUploady,
} from '@rpldy/uploady';
import { Icon, ICON_NAMES } from 'icons';

export interface UploadedFile {
  name: string;
  type: string;
  url: string;
}

interface UploadButtonProps extends BoxProps {
  name?: string;
  children: string;
  onUpload: (files: Array<UploadedFile>) => void;
  colorScheme?: string;
  variant?: string;
  size?: string;
  containerProps?: BoxProps;
  icon?: ICON_NAMES;
}

export const UploadButton: FC<UploadButtonProps> = ({
  name = 'files',
  children,
  onUpload,
  colorScheme = 'navy',
  variant = 'outline',
  size = 'sm',
  containerProps,
  icon = ICON_NAMES.upload,
}) => {
  const { showFileUpload } = useUploady();
  const inputRef = useRef();
  useFileInput(inputRef);
  const [loading, setLoading] = useState(false);

  const handleUploadButtonClick = useCallback(() => {
    showFileUpload();
  }, [showFileUpload]);

  useBatchStartListener(() => {
    setLoading(true);
  });

  useBatchFinishListener(batch => {
    // Package up the useful parts of the new files and return them
    const files = batch.items?.map(item => {
      const file = {
        name: item.file.name,
        type: item.file.type,
        // Append the file name as a query param as a way to store it with the url
        url: `${item.uploadResponse?.data}?name=${item.file.name}`,
      };
      return file;
    });
    onUpload(files);
    setLoading(false);
  });

  return (
    <Flex {...containerProps}>
      <Button
        size={size}
        colorScheme={colorScheme}
        variant={variant}
        w="max-content"
        leftIcon={<Icon name={icon} h={4} w={4} />}
        onClick={handleUploadButtonClick}
        isLoading={loading}
      >
        {children}
      </Button>
      <input
        id={name}
        name={name}
        type="file"
        style={{ display: 'none' }}
        multiple
        ref={inputRef}
      />
    </Flex>
  );
};
