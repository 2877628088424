import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Rewind: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.68 6.027a3.376 3.376 0 00-.386.117c-.254.09-.597.335-3.06 2.179-1.526 1.144-2.867 2.17-2.98 2.28A1.924 1.924 0 002.681 12c0 .518.172.965.512 1.332.103.11 1.453 1.15 2.999 2.31 2.404 1.804 2.853 2.125 3.089 2.213.541.2 1.1.177 1.599-.068.326-.16.742-.565.89-.867.207-.424.23-.601.23-1.808V14l2.502 1.876c2.127 1.595 2.544 1.892 2.779 1.979.77.285 1.543.119 2.12-.455.281-.28.44-.557.538-.938.092-.36.092-8.564 0-8.924a1.932 1.932 0 00-.538-.939c-.572-.573-1.349-.74-2.12-.454-.235.087-.652.384-2.779 1.979L12 10V8.888c0-1.208-.023-1.385-.231-1.808-.15-.307-.566-.712-.889-.868a2.093 2.093 0 00-1.2-.185zM10 12v4l-2.658-1.99c-1.462-1.094-2.658-2-2.658-2.01 0-.016 5.271-3.987 5.306-3.997C9.995 8 10 9.8 10 12zm8 0v4l-2.658-1.99c-1.462-1.094-2.658-2-2.658-2.01 0-.016 5.271-3.987 5.306-3.997C17.995 8 18 9.8 18 12z"
      fill="currentColor"
    />
  </Icon>
);

Rewind.displayName = 'RewindIcon';
