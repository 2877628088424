import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const RLogo: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M2.791 21h8.544l.146-1.053c-1.55-.176-2.224-.732-2.048-1.902l.38-3.277c.117-1.112.586-1.61 1.697-1.61 1.405 0 1.932.703 2.488 2.75C15.021 19.538 15.724 21 19.059 21h1.785l.176-1.112c-1.405 0-1.668-1.96-2.078-3.423-.585-2.546-1.404-3.277-2.984-3.804-.176-.059-.146-.351.058-.38 2.956-.205 4.975-2.37 4.975-5.15 0-2.78-2.575-4.301-6.35-4.301H5.015l-.117 1.053c1.258.176 1.872.732 1.726 1.902l-1.492 12.26c-.146 1.17-.907 1.726-2.224 1.902L2.791 21Zm8.924-9.334c-1.112 0-1.492-.497-1.316-1.668l.526-4.067c.146-1.053.527-1.755 1.99-1.755h1.112c1.93 0 2.575 1.463 2.575 3.101 0 2.458-1.522 4.39-3.921 4.39h-.966Z"
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

RLogo.displayName = 'RLogoIcon';
