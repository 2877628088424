import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DirectionsDiamond: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M14.975 3.02a3.492 3.492 0 0 0-4.939 0l-7.013 7.012a3.492 3.492 0 0 0 0 4.94l7.013 7.012a3.492 3.492 0 0 0 4.94 0l7.012-7.013a3.492 3.492 0 0 0 0-4.939L14.975 3.02Zm-3.878 1.06a1.992 1.992 0 0 1 2.817 0l7.013 7.013c.779.778.779 2.04 0 2.818l-7.013 7.013a1.993 1.993 0 0 1-2.817 0L4.084 13.91a1.992 1.992 0 0 1 0-2.818l7.013-7.013ZM9.108 14.475v.33c0 .365-.27.66-.605.66-.334 0-.605-.295-.605-.66v-.33c0-.875.319-1.714.886-2.333.567-.62 1.337-.967 2.14-.967h4.81L14.345 9.66a.703.703 0 0 1 0-.933.57.57 0 0 1 .856 0l2.42 2.64a.703.703 0 0 1 0 .934l-2.42 2.64a.57.57 0 0 1-.856 0 .703.703 0 0 1 0-.933l1.387-1.514h-4.81c-.481 0-.943.209-1.283.58-.34.372-.532.875-.532 1.4Z"
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

DirectionsDiamond.displayName = 'DirectionsDiamondIcon';
