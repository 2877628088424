import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Truck: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.534 4.057a2.063 2.063 0 00-1.49 1.535c-.062.298-.062 10.518 0 10.816C2.232 17.307 3.08 18 3.994 18c.184 0 .192.005.242.15.088.252.385.697.631.945a2.99 2.99 0 003.142.724c.742-.26 1.45-.922 1.734-1.627l.076-.187 1.3-.013c1.264-.012 1.308-.016 1.541-.108.297-.12.378-.12.68-.006.133.05.376.1.544.11l.304.023.05.144c.086.247.385.694.63.94a2.99 2.99 0 003.141.724c.743-.26 1.45-.924 1.735-1.63.076-.188.077-.19.264-.19.913 0 1.76-.693 1.948-1.591.058-.278.061-5.067.003-5.373a2.665 2.665 0 00-.182-.515c-.135-.288-.22-.38-2.069-2.228-1.848-1.848-1.94-1.934-2.228-2.069a2.665 2.665 0 00-.515-.182c-.12-.023-.826-.041-1.58-.041h-1.367l-.048-.302a2.03 2.03 0 00-1.562-1.654c-.32-.067-8.595-.055-8.874.013zM12 11v5H9.816l-.052-.15c-.092-.261-.389-.702-.65-.964-1.353-1.352-3.572-1.115-4.63.495a2.551 2.551 0 00-.24.45c-.045.135-.076.169-.15.169H4V6h8v5zm6.29-1.29L20 11.42V16h-.092c-.07 0-.104-.035-.144-.15-.092-.261-.389-.702-.65-.964-1.353-1.352-3.572-1.115-4.63.495a2.551 2.551 0 00-.24.45c-.045.135-.076.169-.15.169H14V8h2.58l1.71 1.71zM7.34 16.066c.37.126.66.538.66.934 0 .527-.473 1-1 1a1.03 1.03 0 01-.94-.68.99.99 0 011.28-1.254zm10 0c.37.126.66.538.66.934 0 .527-.473 1-1 1a1.03 1.03 0 01-.94-.68.99.99 0 011.28-1.254z"
      fill="currentColor"
    />
  </Icon>
);

Truck.displayName = 'TruckIcon';
