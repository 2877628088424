import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Library: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.68 2.053c-.62.241-9.211 4.098-9.32 4.184-.178.142-.327.43-.351.68-.066.693.645 1.258 1.296 1.028.085-.03 2.066-.903 4.401-1.94 2.336-1.037 4.271-1.885 4.3-1.885.03 0 2.004.867 4.389 1.926 3.184 1.415 4.385 1.932 4.525 1.945.595.057 1.128-.478 1.071-1.074a1.092 1.092 0 00-.351-.681c-.077-.062-2.21-1.033-4.74-2.158-4.087-1.818-4.627-2.048-4.84-2.063a.992.992 0 00-.38.038zM2.643 9.069C2.291 9.193 2 9.614 2 10c0 .396.291.808.66.934.107.036.227.066.267.066.07 0 .073.173.073 4.5S2.997 20 2.927 20c-.173 0-.471.142-.628.299a.984.984 0 000 1.402c.326.326-.563.299 9.701.299 10.264 0 9.375.027 9.701-.299a.984.984 0 000-1.402c-.157-.157-.455-.299-.628-.299-.07 0-.073-.173-.073-4.5s.003-4.5.073-4.5c.173 0 .471-.142.628-.299a.984.984 0 000-1.402c-.326-.327.567-.299-9.718-.296-8.192.003-9.182.01-9.34.066zM19 15.5V20H5v-9h14v4.5zM7.643 13.069a1.066 1.066 0 00-.577.591c-.057.168-.066.413-.066 1.84 0 1.871.005 1.907.299 2.201.18.18.459.299.701.299.242 0 .521-.119.701-.299.294-.294.299-.33.299-2.201s-.005-1.907-.299-2.201a.998.998 0 00-1.058-.23zm4 0a1.066 1.066 0 00-.577.591c-.057.168-.066.413-.066 1.84 0 1.871.005 1.907.299 2.201.18.18.459.299.701.299.242 0 .521-.119.701-.299.294-.294.299-.33.299-2.201s-.005-1.907-.299-2.201a.998.998 0 00-1.058-.23zm4 0a1.066 1.066 0 00-.577.591c-.057.168-.066.413-.066 1.84 0 1.871.005 1.907.299 2.201.18.18.459.299.701.299.242 0 .521-.119.701-.299.294-.294.299-.33.299-2.201s-.005-1.907-.299-2.201a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

Library.displayName = 'LibraryIcon';
