import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Pencil: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.946 2.04a3.988 3.988 0 00-1.426.57c-.263.176-14.307 14.217-14.413 14.41-.086.158-.087.191-.098 2.155-.014 2.284-.016 2.265.3 2.572.305.296.319.298 2.536.284 1.903-.01 1.938-.012 2.095-.098.197-.108 14.276-14.192 14.457-14.463.191-.286.391-.732.489-1.09.125-.457.125-1.303 0-1.76a3.502 3.502 0 00-2.434-2.483c-.389-.114-1.145-.163-1.506-.098zm1.186 2.111c.318.15.568.401.724.73.11.23.124.3.124.62 0 .535-.116.754-.727 1.38l-.473.483-1.072-1.075-1.072-1.074.479-.465c.647-.629.915-.76 1.477-.73.215.01.356.045.54.131zm-7.402 10.26L6.1 20.04H4v-2.18l5.61-5.61 5.61-5.61 1.07 1.07 1.07 1.07-5.63 5.63z"
      fill="currentColor"
    />
  </Icon>
);

Pencil.displayName = 'PencilIcon';
