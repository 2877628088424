import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CreditCard: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.31 4.063a3.918 3.918 0 00-2.13 1.116 3.87 3.87 0 00-1.121 2.173c-.053.31-.06.943-.049 4.812.012 4.425.013 4.458.1 4.776.414 1.532 1.518 2.612 3.03 2.965.306.072.616.075 6.86.075 6.235 0 6.555-.003 6.86-.074a4.051 4.051 0 003.046-3.046c.07-.302.074-.575.074-4.86 0-4.291-.004-4.558-.075-4.86-.377-1.615-1.62-2.796-3.246-3.079-.502-.088-12.864-.085-13.349.002zm13.098 1.981c.75.157 1.391.798 1.548 1.548.024.117.044.481.044.81V9H4v-.598c0-.329.02-.693.044-.81a2.063 2.063 0 011.49-1.535c.28-.068 12.551-.08 12.874-.013zM20 13.598c0 1.742-.015 2.668-.044 2.81a2.062 2.062 0 01-1.548 1.548c-.299.062-12.517.062-12.816 0a2.062 2.062 0 01-1.548-1.548C4.015 16.266 4 15.34 4 13.598V11h16v2.598zm-13.357.471C6.291 14.193 6 14.614 6 15c0 .242.119.521.3.701.253.254.434.299 1.2.299s.947-.045 1.201-.299a.984.984 0 000-1.402c-.256-.257-.434-.3-1.218-.296-.492.002-.709.019-.84.066zm5 0c-.352.124-.643.545-.643.931 0 .242.12.521.3.701.253.254.434.299 1.2.299s.947-.045 1.201-.299a.984.984 0 000-1.402c-.256-.257-.434-.3-1.218-.296-.492.002-.709.019-.84.066z"
      fill="currentColor"
    />
  </Icon>
);

CreditCard.displayName = 'CreditCardIcon';
