import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const File: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M9.120 1.000 L 5.460 1.021 5.139 1.122 C 4.165 1.428,3.388 2.217,3.112 3.178 L 3.020 3.500 3.020 12.000 L 3.020 20.500 3.112 20.822 C 3.391 21.796,4.204 22.609,5.178 22.888 L 5.500 22.980 12.000 22.980 L 18.500 22.980 18.822 22.888 C 19.783 22.612,20.572 21.835,20.878 20.861 L 20.979 20.540 20.999 14.660 L 21.020 8.780 20.907 8.530 C 20.825 8.348,20.749 8.248,20.627 8.163 C 20.429 8.024,13.931 1.518,13.805 1.332 C 13.676 1.143,13.260 0.957,12.990 0.969 C 12.874 0.974,11.133 0.988,9.120 1.000 M12.009 6.190 L 12.020 9.380 12.131 9.569 C 12.203 9.691,12.309 9.797,12.431 9.869 L 12.620 9.980 15.810 9.991 L 19.000 10.002 18.998 15.091 C 18.997 19.238,18.987 20.208,18.943 20.330 C 18.864 20.551,18.702 20.744,18.489 20.869 L 18.300 20.980 12.000 20.980 L 5.700 20.980 5.508 20.867 C 5.291 20.739,5.099 20.498,5.040 20.278 C 5.013 20.180,5.003 17.333,5.010 11.914 L 5.020 3.700 5.131 3.511 C 5.256 3.298,5.449 3.136,5.670 3.057 C 5.790 3.014,6.433 3.003,8.909 3.002 L 11.998 3.000 12.009 6.190 M15.800 6.220 L 17.580 8.000 15.790 8.000 L 14.000 8.000 14.000 6.220 C 14.000 5.241,14.005 4.440,14.010 4.440 C 14.016 4.440,14.821 5.241,15.800 6.220 "
      stroke="none"
      fillRule="evenodd"
      fill="currentColor"
    ></path>
  </Icon>
);

File.displayName = 'FileIcon';
