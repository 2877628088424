import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const WalkAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M13.280 2.401 C 13.007 2.458,12.562 2.694,12.357 2.890 C 11.949 3.281,11.720 3.798,11.720 4.329 C 11.720 6.115,13.840 7.007,15.098 5.749 C 15.368 5.479,15.513 5.234,15.621 4.863 C 15.715 4.539,15.687 3.949,15.562 3.627 C 15.369 3.128,14.904 2.679,14.374 2.481 C 14.107 2.381,13.565 2.342,13.280 2.401 M9.532 7.856 C 8.322 8.159,7.647 8.347,7.493 8.424 C 7.366 8.488,7.152 8.648,7.017 8.780 C 6.781 9.010,6.733 9.097,5.809 10.942 L 4.847 12.864 5.733 13.308 L 6.620 13.752 7.520 11.966 L 8.420 10.181 9.500 9.912 C 10.094 9.764,10.590 9.646,10.602 9.651 C 10.613 9.656,10.220 11.522,9.728 13.799 C 8.944 17.423,8.822 17.945,8.747 17.999 C 8.699 18.032,7.886 18.594,6.940 19.246 C 5.965 19.919,5.222 20.457,5.224 20.489 C 5.231 20.572,6.282 22.086,6.323 22.072 C 6.343 22.065,7.205 21.477,8.240 20.766 C 9.526 19.880,10.179 19.407,10.304 19.267 C 10.662 18.869,10.668 18.848,11.225 16.290 C 11.268 16.094,11.303 16.009,11.334 16.028 C 11.367 16.048,12.978 17.249,13.791 17.860 C 13.798 17.866,14.720 22.431,14.720 22.463 C 14.720 22.468,15.786 22.257,16.464 22.119 L 16.668 22.077 16.181 19.648 C 15.652 17.013,15.627 16.928,15.289 16.552 C 15.207 16.461,14.627 16.002,14.000 15.531 C 13.373 15.060,12.854 14.658,12.847 14.638 C 12.833 14.600,13.427 10.955,13.464 10.842 C 13.475 10.809,13.751 11.187,14.121 11.742 C 14.794 12.754,14.984 12.981,15.327 13.189 C 15.510 13.299,18.205 14.240,18.338 14.240 C 18.362 14.240,18.514 13.840,18.676 13.350 C 18.839 12.861,18.979 12.439,18.988 12.414 C 18.999 12.385,18.512 12.204,17.669 11.923 L 16.332 11.477 15.428 10.124 C 14.799 9.181,14.466 8.719,14.332 8.603 C 14.069 8.376,12.342 7.510,12.023 7.447 C 11.575 7.358,11.417 7.384,9.532 7.856 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

WalkAlt.displayName = 'WalkAltIcon';
