import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  BoxProps,
  FormControl,
  FormErrorMessage,
  InputGroup,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { ConnectForm } from './ConnectForm';
import { FieldLabel, FieldLabelProps } from './FieldLabel';

interface ConnectedTextareaProps {
  containerProps?: BoxProps;
  labelProps?: FieldLabelProps;
  id: string;
  placeholder?: string;
  textareaProps?: TextareaProps;
}

export const ConnectedTextarea: FC<ConnectedTextareaProps> = ({
  containerProps,
  labelProps,
  id,
  placeholder = 'Enter',
  textareaProps,
}) => (
  <ConnectForm>
    {({ ...formMethods }: UseFormReturn) => (
      <FormControl
        {...containerProps}
        isInvalid={!!formMethods.formState.errors[id]}
      >
        {labelProps?.text && <FieldLabel {...labelProps} />}
        <InputGroup maxWidth="100%">
          {formMethods?.register && (
            <Textarea
              placeholder={placeholder}
              type="text"
              {...formMethods?.register(id)}
              id={id}
              width="100%"
              {...textareaProps}
            />
          )}
        </InputGroup>
        {formMethods.formState.errors[id] ? (
          <FormErrorMessage ml={2}>
            {formMethods.formState.errors[id].message?.toString()}
          </FormErrorMessage>
        ) : null}
      </FormControl>
    )}
  </ConnectForm>
);
