import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Calendar: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.643 2.069a1.118 1.118 0 00-.343.229c-.248.248-.3.443-.3 1.126v.569l-1.27.018c-.987.013-1.315.031-1.47.078-1.138.348-1.901 1.127-2.184 2.231-.083.326-.084 13.031 0 13.357.281 1.096 1.043 1.877 2.184 2.238.179.056.983.064 7.56.075 4.998.008 7.448-.001 7.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.327.084-13.033 0-13.357-.307-1.197-1.264-2.09-2.444-2.28C19.337 4.02 18.721 4 18.11 4H17v-.573c0-.686-.052-.881-.299-1.128a.984.984 0 00-1.402 0c-.247.247-.299.442-.299 1.128V4H9v-.573c0-.686-.052-.881-.299-1.128a.998.998 0 00-1.058-.23zM7 6.573c0 .686.052.881.299 1.128a.984.984 0 001.402 0C8.948 7.454 9 7.259 9 6.573V6h6v.573c0 .686.052.881.299 1.128a.984.984 0 001.402 0c.247-.247.299-.442.299-1.128V6h1.073c1.235 0 1.35.021 1.628.299.322.322.299-.202.299 6.701s.023 6.379-.299 6.701c-.324.324.322.299-7.701.299-8.025 0-7.377.025-7.702-.3-.323-.323-.3.208-.288-6.782L4.02 6.7l.111-.189c.061-.103.173-.236.25-.294.262-.2.357-.213 1.529-.215L7 6v.573zm-.357 3.496C6.291 10.193 6 10.614 6 11c0 .242.119.521.299.701.32.32-.082.299 5.701.299 5.783 0 5.381.021 5.701-.299a.984.984 0 000-1.402c-.32-.32.085-.299-5.718-.296-4.569.003-5.183.01-5.34.066z"
      fill="currentColor"
    />
  </Icon>
);

Calendar.displayName = 'CalendarIcon';
