import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillCommunication: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M12 6.922a.39.39 0 100 .781.39.39 0 000-.781zM5.516 13.953a.39.39 0 100-.781.39.39 0 000 .781z"
      fill="currentColor"
    />
    <path
      d="M15.125 3.016c-3.517 0-6.403 2.424-6.626 5.478C5.149 8.662 2 11.077 2 14.344c0 1.365.54 2.687 1.523 3.735a2.376 2.376 0 01-.627 2.239.39.39 0 00.276.666 4.309 4.309 0 002.985-1.206c.777.265 1.791.425 2.718.425 3.516 0 6.402-2.424 6.626-5.477.818-.037 1.669-.188 2.342-.417a4.31 4.31 0 002.985 1.207.39.39 0 00.276-.667 2.375 2.375 0 01-.627-2.238C21.46 11.563 22 10.24 22 8.875c0-3.394-3.392-5.86-6.875-5.86zm-6.25 16.406c-.922 0-1.973-.181-2.676-.461a.39.39 0 00-.433.1 3.524 3.524 0 01-1.753 1.04 3.159 3.159 0 00.238-2.325.39.39 0 00-.095-.166c-.887-.912-1.375-2.073-1.375-3.266 0-2.753 2.79-5.078 6.094-5.078 3.12 0 5.86 2.169 5.86 5.078 0 2.8-2.63 5.078-5.86 5.078zm10.97-7.28a.392.392 0 00-.096.165 3.16 3.16 0 00.238 2.325 3.523 3.523 0 01-1.753-1.04.39.39 0 00-.433-.1c-.61.243-1.48.41-2.301.451-.114-1.477-.851-2.865-2.11-3.896h5.094a.39.39 0 000-.781H12.19a7.286 7.286 0 00-2.907-.77c.224-2.624 2.76-4.7 5.843-4.7 3.303 0 6.094 2.326 6.094 5.079 0 1.194-.488 2.354-1.375 3.267z"
      fill="currentColor"
    />
    <path
      d="M12 13.172H7.078a.39.39 0 100 .781H12a.39.39 0 100-.781zM12 15.516H5.516a.39.39 0 100 .78H12a.39.39 0 100-.78zM18.484 6.922h-4.922a.39.39 0 100 .781h4.922a.39.39 0 000-.781z"
      fill="currentColor"
    />
  </Icon>
);

SkillCommunication.displayName = 'SkillCommunicationIcon';
