import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Menu6: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M2.664 5.063 C 2.436 5.146,2.257 5.297,2.131 5.511 C 2.036 5.672,2.020 5.744,2.020 6.000 C 2.020 6.256,2.036 6.328,2.131 6.489 C 2.260 6.709,2.437 6.854,2.678 6.939 C 2.830 6.993,3.916 7.000,11.998 7.000 C 22.265 7.000,21.375 7.027,21.701 6.701 C 22.092 6.310,22.092 5.690,21.701 5.299 C 21.375 4.972,22.267 5.000,11.983 5.003 C 4.135 5.005,2.798 5.014,2.664 5.063 M2.664 11.063 C 2.436 11.146,2.257 11.297,2.131 11.511 C 2.036 11.672,2.020 11.744,2.020 12.000 C 2.020 12.256,2.036 12.328,2.131 12.489 C 2.260 12.709,2.437 12.854,2.678 12.939 C 2.830 12.993,3.916 13.000,11.998 13.000 C 22.265 13.000,21.375 13.027,21.701 12.701 C 22.092 12.310,22.092 11.690,21.701 11.299 C 21.375 10.972,22.267 11.000,11.983 11.003 C 4.135 11.005,2.798 11.014,2.664 11.063 M2.664 17.063 C 2.436 17.146,2.257 17.297,2.131 17.511 C 2.036 17.672,2.020 17.744,2.020 18.000 C 2.020 18.256,2.036 18.328,2.131 18.489 C 2.260 18.709,2.437 18.854,2.678 18.939 C 2.830 18.993,3.916 19.000,11.998 19.000 C 22.265 19.000,21.375 19.027,21.701 18.701 C 22.092 18.310,22.092 17.690,21.701 17.299 C 21.375 16.972,22.267 17.000,11.983 17.003 C 4.135 17.005,2.798 17.014,2.664 17.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Menu6.displayName = 'Menu6Icon';
