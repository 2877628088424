import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const VideoCamera: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 5.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.325-.084 9.033 0 9.357.281 1.096 1.047 1.881 2.184 2.237.175.055.735.065 4.56.076 2.923.009 4.45-.001 4.633-.03 1.172-.181 2.128-1.046 2.454-2.22.031-.11.067-.403.08-.65.014-.247.031-.45.039-.45s.715.35 1.571.778c.856.429 1.665.816 1.799.86.302.102.852.111 1.167.018a2.027 2.027 0 001.375-1.46c.091-.383.091-7.289 0-7.672a2.027 2.027 0 00-1.375-1.46c-.315-.093-.865-.084-1.167.018-.134.044-.943.431-1.799.86-.856.428-1.563.778-1.571.778-.008 0-.025-.202-.039-.45-.034-.639-.165-1.058-.482-1.545a3.033 3.033 0 00-2.025-1.322c-.245-.04-8.663-.057-8.86-.018zm8.72 2.041c.115.039.263.135.361.233.317.317.299.038.299 4.701 0 4.663.018 4.384-.299 4.701-.317.317-.038.299-4.701.299-4.665 0-4.384.018-4.702-.3-.318-.318-.301-.031-.289-4.782L4.02 7.7l.111-.189c.125-.213.318-.375.539-.454.122-.043.94-.054 4.313-.055 3.773-.002 4.181.004 4.357.064zM20 12c0 1.848-.009 3.36-.02 3.36-.011 0-.911-.445-2-.99L16 13.38v-2.76l1.97-.988c1.083-.543 1.983-.989 2-.99.017-.001.03 1.51.03 3.358z"
      fill="currentColor"
    />
  </Icon>
);

VideoCamera.displayName = 'VideoCameraIcon';
