import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DatabaseAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M10.520 1.043 C 8.780 1.137,7.081 1.406,5.756 1.799 C 3.967 2.328,2.733 3.103,2.269 3.989 C 1.980 4.540,2.000 3.949,2.000 12.000 C 2.000 20.051,1.980 19.460,2.269 20.011 C 3.041 21.483,5.732 22.542,9.620 22.902 C 10.561 22.990,12.504 23.020,13.480 22.963 C 16.604 22.779,19.099 22.158,20.580 21.195 C 21.022 20.907,21.543 20.374,21.727 20.020 C 22.020 19.457,22.000 20.060,22.000 12.000 C 22.000 3.949,22.020 4.540,21.731 3.989 C 20.992 2.579,18.369 1.511,14.740 1.142 C 13.426 1.008,11.853 0.971,10.520 1.043 M13.360 3.041 C 15.835 3.180,17.803 3.606,19.136 4.290 C 19.529 4.492,20.000 4.875,20.000 4.994 C 20.000 5.094,19.639 5.425,19.330 5.609 C 17.909 6.455,15.038 6.999,12.000 6.999 C 8.930 6.999,5.990 6.433,4.616 5.578 C 4.333 5.402,4.000 5.086,4.000 4.993 C 4.000 4.966,4.104 4.843,4.230 4.721 C 4.600 4.361,5.384 3.989,6.380 3.700 C 8.258 3.154,10.920 2.903,13.360 3.041 M4.940 7.931 C 6.871 8.662,9.618 9.045,12.520 8.987 C 13.765 8.963,14.247 8.930,15.320 8.796 C 17.003 8.586,18.814 8.113,19.770 7.634 L 20.000 7.519 20.000 9.779 L 20.000 12.040 19.851 12.204 C 19.074 13.065,16.521 13.772,13.520 13.959 C 9.529 14.206,5.230 13.402,4.149 12.204 L 4.000 12.040 4.000 9.779 L 4.000 7.519 4.230 7.634 C 4.356 7.698,4.676 7.831,4.940 7.931 M4.485 14.755 C 5.850 15.340,7.699 15.742,9.880 15.927 C 10.675 15.995,13.383 15.995,14.120 15.928 C 16.413 15.718,18.146 15.342,19.515 14.755 C 19.765 14.648,19.977 14.560,19.985 14.560 C 19.993 14.560,20.000 15.568,20.000 16.800 L 20.000 19.040 19.851 19.204 C 19.074 20.065,16.521 20.772,13.520 20.959 C 9.529 21.206,5.230 20.402,4.149 19.204 L 4.000 19.040 4.000 16.800 C 4.000 15.568,4.007 14.560,4.015 14.560 C 4.023 14.560,4.235 14.648,4.485 14.755 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

DatabaseAlt.displayName = 'DatabaseAltIcon';
