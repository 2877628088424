import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Selector: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.64 4.068c-.154.053-.488.369-2.311 2.187-1.516 1.511-2.156 2.176-2.217 2.301A.99.99 0 007.298 9.7c.279.279.655.364 1.045.235.177-.059.376-.244 1.927-1.789L12 6.422l1.73 1.724c1.551 1.545 1.75 1.73 1.927 1.789.39.129.766.044 1.045-.235a.99.99 0 00.186-1.144c-.061-.125-.701-.79-2.217-2.301-1.924-1.919-2.15-2.131-2.328-2.19a1.053 1.053 0 00-.703.003zM7.643 14.069C7.291 14.193 7 14.614 7 15c0 .08.029.234.065.343.059.178.271.404 2.19 2.328 1.511 1.516 2.176 2.156 2.301 2.217.244.118.509.136.77.052.201-.065.342-.198 2.345-2.195 1.516-1.511 2.156-2.176 2.217-2.301a.99.99 0 00-.186-1.144c-.279-.279-.655-.364-1.045-.235-.177.059-.376.244-1.927 1.789L12 17.578l-1.73-1.724c-1.551-1.545-1.75-1.73-1.927-1.789a1.032 1.032 0 00-.7.004z"
      fill="currentColor"
    />
  </Icon>
);

Selector.displayName = 'SelectorIcon';
