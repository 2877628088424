import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const RibbonAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M7.005 6.766a8 8 0 0 1 4.674-1.749 3.003 3.003 0 0 1 .635 0 8 8 0 0 1 4.68 1.74c-.058-1.314-.595-2.187-1.377-2.766C14.748 3.347 13.476 3 11.98 3c-1.435 0-2.7.344-3.578.992-.792.585-1.339 1.463-1.397 2.774Zm9.647 2.448a6 6 0 0 0-1.658-1.407c.004.064.006.129.006.193 0 .824-.407 1.54-.812 2.108-.29.405-.66.841-1.016 1.261-.14.164-.277.325-.406.482l-2.63 3.297-3.214 4.149 1.865 1.353 2.377-3.202.003-.004 2.595-3.533.001-.002c.182-.25.36-.49.532-.722 1.053-1.423 1.907-2.577 2.357-3.973Zm-2.055 6.939-1.382 1.882 1.959 2.62 1.894-1.358-2.471-3.144Zm-2.624 3.56-2.17 2.923a1 1 0 0 1-1.39.213l-3.5-2.54a1 1 0 0 1-.204-1.421l3.394-4.381-.176-.245C6.489 12.264 5.578 10.998 5.212 9.3a1.01 1.01 0 0 1-.018-.117C5.054 8.524 5 7.807 5 7c0-2.058.85-3.61 2.215-4.617C8.54 1.406 10.265 1 11.98 1c1.764 0 3.503.403 4.828 1.384C18.174 3.395 19 4.95 19 7c0 .81-.1 1.564-.281 2.258-.506 2.016-1.688 3.605-2.834 5.146l-.058.077 3.46 4.401a1 1 0 0 1-.204 1.43l-3.54 2.54a1 1 0 0 1-1.384-.213l-2.186-2.925ZM7.28 9.314c.307 1.005.912 1.88 2.113 3.559l1.308-1.64a18.78 18.78 0 0 1-.893-1.126C9.406 9.539 9 8.824 9 8c0-.062.002-.124.006-.186a6 6 0 0 0-1.726 1.5Zm4.56-2.3A1 1 0 0 0 11 8c0 .176.094.46.44.95.165.233.35.463.556.712.21-.25.397-.482.564-.715.347-.487.44-.771.44-.947a1 1 0 0 0-.842-.988 5.99 5.99 0 0 0-.318 0Z"
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

RibbonAlt.displayName = 'RibbonAltIcon';
