import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const FolderOpen: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 4.025c-.408.08-.533.114-.75.202-.88.358-1.549 1.137-1.794 2.093-.083.326-.084 11.032 0 11.357.241.942.924 1.748 1.769 2.086.576.232-.039.214 7.975.227 4.97.008 7.448-.001 7.632-.029 1.196-.184 2.166-1.079 2.472-2.281.082-.323.083-6.037 0-6.357-.307-1.197-1.264-2.09-2.444-2.28A7.516 7.516 0 0018.615 9h-.605l-.027-.273a3.019 3.019 0 00-2.507-2.685C15.326 6.018 14.442 6 13.322 6h-1.898l-.942-.934c-.816-.81-.968-.943-1.139-1-.174-.058-.455-.065-2.42-.061a87.455 87.455 0 00-2.303.02zm4.898 2.909c.816.81.968.943 1.14 1C10.83 7.992 11.114 8 13 8c2.427 0 2.4-.003 2.701.299.157.157.3.455.3.627 0 .068-.182.072-3.77.084-4.076.014-3.824.001-4.386.227-.672.269-1.322.912-1.595 1.578-.235.571-.224.411-.248 3.545l-.022 2.9-.094.19c-.296.602-1.108.73-1.588.25-.32-.321-.3.088-.289-5.782L4.02 6.7l.111-.189c.125-.213.318-.375.539-.454.117-.042.564-.054 2.028-.055L8.576 6l.942.934zm9.822 4.132c.253.087.507.341.594.594.06.173.066.482.066 2.84 0 2.986.01 2.894-.299 3.201-.32.321.143.299-6.208.299-3.109 0-5.653-.005-5.653-.01.001-.005.031-.109.068-.23.06-.194.07-.564.09-3.14l.022-2.92.111-.189c.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.703-.002 5.18.004 5.357.064z"
      fill="currentColor"
    />
  </Icon>
);

FolderOpen.displayName = 'FolderOpenIcon';
