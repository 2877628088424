import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Reply: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64 3.068c-.156.053-.603.483-3.311 3.187-2.258 2.253-3.155 3.174-3.216 3.3a1.013 1.013 0 00-.053.77c.066.204.23.376 3.195 3.346 2.253 2.258 3.174 3.155 3.301 3.216a.986.986 0 001.144-.185c.279-.28.364-.655.235-1.045-.059-.178-.278-.41-2.289-2.427L5.422 11h3.979c2.465 0 4.116.016 4.339.042a6.976 6.976 0 013.76 1.605c1.383 1.156 2.249 2.782 2.458 4.613.023.198.041.928.042 1.623 0 1.445.015 1.534.299 1.818a.984.984 0 001.403-.001c.301-.301.309-.36.287-2.113-.016-1.312-.03-1.594-.102-2.007a8.867 8.867 0 00-.824-2.56 9.015 9.015 0 00-7.243-4.98C13.559 9.017 11.757 9 9.401 9H5.422l2.224-2.23c2.011-2.017 2.23-2.249 2.289-2.427A1.004 1.004 0 008.64 3.068z"
      fill="currentColor"
    />
  </Icon>
);

Reply.displayName = 'ReplyIcon';
