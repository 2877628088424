import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Circle: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.7467 182.493C145.447 182.493 182.493 145.447 182.493 99.7467C182.493 54.047 145.447 17 99.7467 17C54.047 17 17 54.047 17 99.7467C17 145.447 54.047 182.493 99.7467 182.493Z"
      fill="currentColor"
    />
  </Icon>
);

Circle.displayName = 'CircleShape';
