import { Flex, Text } from '@chakra-ui/react';
import { BlockText } from './BlockText';

export const BlockCallout = (props: any): JSX.Element => {
  return (
    <Flex
      className="callout"
      backgroundColor={`${props?.color?.replace('_background', '')}.50`}
      borderColor={`${props?.color?.replace('_background', '')}.100`}
      borderWidth="2px"
      borderStyle="solid"
      borderRadius="md"
      pt={2}
      pb={5}
      mx="auto"
      my={4}
      px={4}
      color={`${props?.color?.replace('_background', '')}.800`}
    >
      {props?.icon?.type === 'emoji' && (
        <Flex pt={2} mr={3}>
          <Text fontSize="3xl">{props?.icon?.emoji}</Text>
        </Flex>
      )}
      <Flex>
        <Text color="inherit" mt={2} fontSize="xl" lineHeight="short">
          <BlockText text={props?.rich_text} />
        </Text>
      </Flex>
    </Flex>
  );
};
