import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Blob: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M179.662 104.445C174.758 96.716 163.578 94.359 159.641 86.0948C156.04 78.5413 160.08 69.8241 162.323 61.762C165.633 49.8761 164.414 35.9154 155.925 26.9578C145.762 16.2286 128.854 16.0927 116.013 21.5366C110.324 23.9493 105.103 27.3289 100.23 31.1074C95.4868 34.7848 91.3111 39.1573 86.1895 42.2878C79.3033 46.4983 70.4154 47.0104 62.5832 46.0593C54.1831 45.0384 45.4555 44.2388 37.4369 46.9303C27.4515 50.2924 19.5688 59.2691 17.5341 69.6046C15.4994 79.9401 19.3859 91.2372 27.354 98.1305C30.1987 100.592 33.5243 102.538 35.9544 105.409C41.6195 112.102 41.069 122.033 38.9072 130.531C36.7453 139.028 33.2577 147.498 34.0817 156.227C35.1078 167.084 43.3354 176.756 53.7302 180.055C64.1249 183.354 76.1101 180.403 84.1932 173.087C90.3216 167.538 94.9938 159.367 103.059 157.583C108.604 156.363 114.238 158.581 119.745 159.959C133.681 163.443 149.089 161.325 161.158 153.529C173.227 145.733 181.603 132.205 182.432 117.861C182.698 113.227 182.144 108.365 179.662 104.445Z"
      fill="currentColor"
    />
  </Icon>
);

Blob.displayName = 'BlobShape';
