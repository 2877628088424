import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Meh: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.8288 157.205C131.461 169.087 175.034 114.392 175.034 114.392C175.034 114.392 183.345 101.959 182.307 92.5344C182.285 92.3307 180.917 66.9136 157.524 42.4072C154.365 39.6446 138.955 16.6074 110.579 29.9912C103.346 33.4025 98.6556 40.5431 95.0804 47.5735C91.5053 54.604 88.4768 62.1047 82.9078 67.8374C76.1861 74.7561 66.6591 78.1773 58.858 83.9093C52.5295 88.5591 47.4538 94.6392 42.867 100.932C35.7278 110.726 29.378 122.07 30.2363 133.657C31.0946 145.245 42.097 156.011 53.7978 152.857C70.9069 148.256 84.5074 151.451 99.8288 157.205Z"
      fill="currentColor"
    />
  </Icon>
);

Meh.displayName = 'MehShape';
