import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Upload: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.64 3.068c-.154.053-.488.369-2.311 2.187-1.516 1.511-2.156 2.176-2.217 2.301A.99.99 0 007.298 8.7c.279.28.656.364 1.045.234.174-.057.342-.21 1.427-1.288L11 6.423v4.861c0 5.465-.02 5.098.299 5.417a.984.984 0 001.402 0c.319-.319.299.048.299-5.417V6.423l1.23 1.223c1.085 1.078 1.253 1.231 1.427 1.288.389.13.766.046 1.045-.234a.99.99 0 00.186-1.144c-.061-.125-.701-.79-2.217-2.301-1.924-1.919-2.15-2.131-2.328-2.19a1.053 1.053 0 00-.703.003zM3.643 15.069c-.244.086-.496.349-.582.608-.103.309-.069 1.796.052 2.263.39 1.511 1.514 2.612 3.027 2.965.304.071.592.075 5.86.075 5.26 0 5.556-.004 5.86-.074a4.047 4.047 0 003.027-2.966c.067-.258.086-.488.102-1.187.023-1.018-.008-1.174-.287-1.453a.984.984 0 00-1.403-.001c-.256.256-.299.433-.299 1.226 0 .369-.02.766-.044.883a2.062 2.062 0 01-1.548 1.548c-.298.062-10.518.062-10.816 0a2.062 2.062 0 01-1.548-1.548C5.02 17.291 5 16.894 5 16.525c0-.793-.043-.97-.299-1.226a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

Upload.displayName = 'UploadIcon';
