import { Text } from '@chakra-ui/react';
import { BlockCallout } from './BlockCallout';
import { BlockColumn } from './BlockColumn';
import { BlockColumns } from './BlockColumns';
import { BlockDivider } from './BlockDivider';
import { BlockEmbed } from './BlockEmbed';
import { BlockHeading } from './BlockHeading';
import { BlockImage } from './BlockImage';
import { BlockListItem } from './BlockListItem';
import { BlockParagraph } from './BlockParagraph';
import { BlockVideo } from './BlockVideo';
import { BlockType, ElementSizes } from './types';

export const Block = ({
  block,
  env = 'dev',
  size = ElementSizes.LG,
  columnSize = 1,
  columnIndex = 0,
  embeds = false,
}: {
  block: BlockType;
  env: string;
  size?: ElementSizes;
  columnSize?: number;
  columnIndex?: number;
  embeds?: boolean;
}): JSX.Element => {
  const { type, id } = block;
  const blockValue = block[type];

  switch (type) {
    case 'paragraph':
      return <BlockParagraph {...blockValue} size={size} id={id} />;
    case 'heading_1':
      return <BlockHeading {...blockValue} size={size} id={id} level="h1" />;
    case 'heading_2':
      return <BlockHeading {...blockValue} size={size} id={id} level="h2" />;
    case 'heading_3':
      return <BlockHeading {...blockValue} size={size} id={id} level="h3" />;
    case 'bulleted_list_item':
    case 'numbered_list_item':
      return <BlockListItem {...blockValue} size={size} id={id} />;
    case 'image':
      return <BlockImage {...blockValue} id={id} env={env} />;
    case 'callout':
      return <BlockCallout {...blockValue} />;
    case 'embed':
      if (!embeds) return null;
      return <BlockEmbed {...blockValue} />;
    case 'video':
      return <BlockVideo {...blockValue} />;
    case 'column':
      return (
        <BlockColumn
          blocks={blockValue.children}
          env={env}
          size={size}
          columnSize={columnSize}
          columnIndex={columnIndex}
        />
      );
    case 'column_list':
      return (
        <BlockColumns columns={blockValue.children} env={env} size={size} />
      );
    case 'divider':
      return <BlockDivider size={size} />;
    default:
      return (
        <Text>{`❌ Unsupported block (${
          type === 'unsupported' ? 'unsupported by Notion API' : type
        })`}</Text>
      );
  }
};
