import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Key: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.28 2.043A7.034 7.034 0 008.787 5.79a7.636 7.636 0 00-.714 2.17c-.07.396-.059 1.797.016 2.18l.056.28-2.905 2.92c-2.8 2.814-2.91 2.93-3.032 3.202-.07.155-.145.378-.168.496-.056.295-.052 3.098.004 3.37.157.75.798 1.391 1.548 1.548.135.028.818.044 1.883.044 1.898 0 1.932-.004 2.226-.299.247-.247.299-.442.299-1.128V20h.573c.686 0 .881-.052 1.128-.299.247-.247.299-.442.299-1.128V18h.573c.873 0 .78.053 1.987-1.138l1.02-1.006.28.051c.45.082 1.348.109 1.84.056 2.459-.266 4.546-1.733 5.603-3.939a6.97 6.97 0 00-1.361-7.966 7.019 7.019 0 00-5.662-2.015zM15.953 4.1a5.044 5.044 0 013.781 3.312c.192.573.245.919.245 1.588 0 1.073-.254 1.916-.85 2.817-.242.365-.95 1.075-1.309 1.311-1.238.816-2.666 1.065-4.067.71-.394-.1-.683-.099-.913.005-.13.059-.469.37-1.221 1.119L10.578 16h-.862c-1.002 0-1.149.031-1.417.299-.247.247-.299.442-.299 1.128V18h-.573c-.686 0-.881.052-1.128.299-.247.247-.299.442-.299 1.128V20H4v-2.579l3.038-3.041c2.344-2.345 3.057-3.081 3.119-3.22.098-.217.106-.533.022-.84a4.979 4.979 0 01.298-3.424 5.027 5.027 0 013.763-2.833c.458-.073 1.227-.057 1.713.037zm-1.31 1.969C14.291 6.193 14 6.614 14 7c0 .527.473 1 1 1s1 .473 1 1 .473 1 1 1c.407 0 .811-.292.94-.68.056-.168.065-.282.045-.537a2.997 2.997 0 00-1.409-2.326c-.615-.384-1.462-.554-1.933-.388z"
      fill="currentColor"
    />
  </Icon>
);

Key.displayName = 'KeyIcon';
