import { FC, isValidElement, ReactNode, useEffect, useState } from 'react';
import {
  Box,
  BoxProps,
  CloseButton,
  Flex,
  Heading,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  useBreakpointValue,
} from '@chakra-ui/react';

export interface ModalProps extends ChakraModalProps {
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  hasCloseButton?: boolean;
  containerProps?: BoxProps;
  closeButtonContainerProps?: BoxProps;
  displayFullScreenMobile?: boolean;
}

export const Modal: FC<ModalProps> = ({
  header,
  body,
  footer,
  hasCloseButton = true,
  isOpen,
  onClose,
  children,
  containerProps,
  closeButtonContainerProps,
  displayFullScreenMobile = true,
  ...props
}) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [shouldDisplay, setShouldDisplay] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
        setShouldAnimate(isOpen);
      }, 200);
    } else {
      setShouldAnimate(isOpen);
    }
  }, [isOpen, isMobile]);

  useEffect(() => {
    if (isMobile) {
      setShouldDisplay(isOpen);
    } else if (!isOpen) {
      setTimeout(() => {
        setShouldDisplay(false);
      }, 1000);
    } else {
      setShouldDisplay(true);
    }
  }, [isOpen, isMobile]);

  return (
    <>
      {isMobile && displayFullScreenMobile ? (
        <Box
          display={!shouldDisplay ? 'none' : 'inherit'}
          backgroundColor="white"
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={1000}
          opacity={shouldAnimate ? 1 : 0}
          transition={isMobile ? 'none' : 'all 250ms ease-in-out'}
        >
          <Flex
            direction="column"
            justifyContent="space-between"
            height="100%"
            width="100%"
            overflowY="auto"
          >
            <Box position="relative" {...containerProps}>
              {!!header && (
                <>
                  {isValidElement(header) ? (
                    header
                  ) : (
                    <>
                      {hasCloseButton ? (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          py={2}
                          pl={4}
                          pr={2}
                          pt={2}
                        >
                          <Heading
                            fontFamily="body"
                            size="md"
                            fontWeight="bold"
                            pt={2}
                          >
                            {header}
                          </Heading>
                          <CloseButton onClick={onClose} />
                        </Flex>
                      ) : (
                        <Heading fontFamily="body" fontWeight="bold" m={6}>
                          {header}
                        </Heading>
                      )}
                    </>
                  )}
                </>
              )}

              {!header && hasCloseButton && (
                <Box
                  position="absolute"
                  top="0"
                  right="0"
                  zIndex={10}
                  {...closeButtonContainerProps}
                >
                  <CloseButton onClick={onClose} />
                </Box>
              )}

              {children || body}
            </Box>
            {!!footer && (
              <Flex m={6} justifyContent="flex-end">
                {footer}
              </Flex>
            )}
          </Flex>
        </Box>
      ) : (
        <ChakraModal isOpen={isOpen} onClose={onClose} {...props}>
          <ModalOverlay />
          <ModalContent>
            {!!header && (
              <>
                {isValidElement(header) ? (
                  header
                ) : (
                  <ModalHeader pt={3} pb={1} mt={2}>
                    {header}
                  </ModalHeader>
                )}
                {hasCloseButton && <ModalCloseButton mt={1} />}
              </>
            )}

            {!header && hasCloseButton && (
              <Box
                position="absolute"
                top="0"
                right="16px"
                zIndex={10}
                {...closeButtonContainerProps}
              >
                <CloseButton onClick={onClose} />
              </Box>
            )}

            <ModalBody {...containerProps}>{children || body}</ModalBody>
            {!!footer && <ModalFooter mb={4}>{footer}</ModalFooter>}
          </ModalContent>
        </ChakraModal>
      )}
    </>
  );
};
