import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CheckAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.294 6.12c-.307.107-.887.649-3.828 3.58l-3.47 3.457-1.463-1.45c-1.242-1.225-1.526-1.467-1.861-1.58-.778-.26-1.534-.092-2.094.468-.56.557-.728 1.313-.467 2.093.114.347.407.674 2.384 2.663 1.546 1.554 2.365 2.322 2.607 2.439a2.038 2.038 0 0 0 1.546.106c.403-.13.686-.397 4.703-4.402 3.04-3.03 4.323-4.363 4.445-4.614.794-1.64-.762-3.357-2.502-2.76Z"
      fill="currentColor"
    />
  </Icon>
);

CheckAlt.displayName = 'CheckAltIcon';
