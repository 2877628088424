import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Star: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.504 1.301c-.566.155-1.083.58-1.299 1.067-.062.139-.452 1.301-.867 2.582l-.754 2.33H6.062c-2.739 0-2.855.009-3.295.225-.46.225-.868.724-1.023 1.252-.09.306-.083.814.015 1.132.087.278.321.668.506.837.063.058 1.024.766 2.135 1.573 1.111.806 2.025 1.488 2.031 1.516.007.027-.343 1.138-.776 2.467L4.867 18.7l-.003.38c-.005.721.304 1.291.914 1.688.37.24.707.326 1.187.303.368-.018.421-.031.726-.185.202-.102 1.09-.72 2.307-1.606a103.07 103.07 0 012.004-1.44c.015 0 .934.66 2.042 1.466 1.258.914 2.127 1.518 2.31 1.606.258.123.341.143.686.159.474.023.813-.064 1.182-.303.61-.397.919-.967.914-1.688l-.003-.38-.788-2.417c-.434-1.33-.783-2.44-.777-2.468.007-.028.849-.658 1.872-1.399 2.333-1.691 2.294-1.66 2.502-1.943.248-.337.344-.617.368-1.08.017-.316.005-.434-.063-.661-.157-.516-.588-1.036-1.025-1.236-.452-.207-.564-.215-3.284-.215l-2.522-.001-.754-2.33c-.415-1.282-.803-2.44-.862-2.572-.139-.314-.525-.727-.829-.888-.446-.238-1.021-.312-1.467-.189zm.783 2.81c1.143 3.547 1.313 4.04 1.478 4.287.203.305.569.605.913.75l.262.112 2.692.02 2.692.02-2.092 1.52c-1.82 1.323-2.115 1.552-2.277 1.767-.323.428-.473.986-.395 1.474.023.144.385 1.316.803 2.604.419 1.289.754 2.351.745 2.361-.01.009-.907-.628-1.993-1.417s-2.056-1.482-2.155-1.539c-.275-.158-.576-.23-.96-.23-.385 0-.686.072-.96.231-.099.057-1.068.75-2.154 1.539-1.086.789-1.983 1.427-1.993 1.417-.01-.01.325-1.073.744-2.362.418-1.288.78-2.46.803-2.604.078-.487-.071-1.046-.394-1.474-.162-.215-.454-.442-2.277-1.767L3.678 9.3l2.691-.02 2.691-.02.265-.112a2.18 2.18 0 00.901-.738c.174-.255.319-.675 1.487-4.3.147-.454.276-.827.287-.827.011 0 .14.373.287.828z"
      fill="currentColor"
    />
  </Icon>
);

Star.displayName = 'StarIcon';
