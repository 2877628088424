import { FC } from 'react';
import { Box, Button, Flex, FlexProps, Text } from '@chakra-ui/react';
import { Icon } from 'icons';
import { MotionBox } from 'ui';

interface FormSuccessProps {
  text: string;
  testId?: string;
  subtext?: string;
  buttonText?: string;
  buttonAction?: () => void;
  containerProps?: FlexProps;
}

export const FormSuccess: FC<FormSuccessProps> = ({
  text,
  testId,
  subtext,
  buttonText,
  buttonAction,
  containerProps,
}) => {
  return (
    <MotionBox
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="view-state-success"
    >
      <Flex direction="column" py={8} maxWidth="480px" {...containerProps}>
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.3 }}
          width="100%"
          px={3}
          mb={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box backgroundColor="green.100" borderRadius="full">
            <Icon name="checkcircle" color="main.green" w={12} h={12} />
          </Box>
          <Text fontSize="xl" fontWeight="semi" mb={2} data-testid={testId}>
            {text}
          </Text>
        </MotionBox>
        {subtext && (
          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.6 }}
            width="100%"
            px={3}
            mb={6}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Text color="text.hint" fontSize="md" maxWidth="40ch">
              {subtext}
            </Text>
          </MotionBox>
        )}
        {buttonText && buttonAction && (
          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1.2 }}
            width="100%"
            display="flex"
            flexDirection="column"
            alignSelf="center"
            mt={3}
          >
            <Button alignSelf="center" onClick={buttonAction}>
              {buttonText}
            </Button>
          </MotionBox>
        )}
      </Flex>
    </MotionBox>
  );
};
