import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { elementPropsBySize } from './elements.config';
import { ElementSizes } from './types';

interface BlockDividerProps {
  size: ElementSizes;
}

export const BlockDivider: FC<BlockDividerProps> = ({ size }) => {
  return (
    <Flex
      h={1}
      w="full"
      borderBottomWidth="1px"
      {...elementPropsBySize[size].divider}
    />
  );
};
