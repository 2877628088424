import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PencilAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.648 2.001c-.625.086-1.15.335-1.668.793-.198.174-2.261 2.223-4.584 4.552-2.858 2.865-4.248 4.286-4.3 4.394-.07.148-.077.283-.087 1.776-.013 1.846-.008 1.887.29 2.185.298.298.339.303 2.185.29 1.492-.01 1.628-.017 1.776-.087.108-.052 1.524-1.437 4.394-4.3 2.329-2.323 4.378-4.386 4.553-4.584.556-.628.805-1.268.802-2.06a2.965 2.965 0 00-1.641-2.662c-.493-.25-1.188-.37-1.72-.297zm.775 2.058c.498.207.742.861.5 1.341-.065.129-1.277 1.368-4.297 4.39L11.42 14H10v-1.42l4.21-4.206c2.316-2.314 4.273-4.238 4.35-4.277.283-.143.58-.156.863-.038zM5.62 4.025c-.408.08-.533.114-.75.202-.879.357-1.549 1.14-1.794 2.096-.083.323-.083 12.031 0 12.354.281 1.096 1.044 1.878 2.184 2.238.178.056.859.064 6.06.075 3.96.008 5.949-.001 6.133-.03a3.034 3.034 0 002.306-1.8c.225-.533.216-.4.231-3.454.015-3.208.025-3.093-.288-3.406a.984.984 0 00-1.403-.001c-.308.307-.299.215-.299 3.201s.009 2.894-.299 3.201c-.321.321.142.299-6.201.299s-5.88.022-6.201-.299c-.321-.321-.299.144-.297-6.218.001-4.626.011-5.69.055-5.813.079-.221.241-.414.454-.539L5.7 6.02 8.48 6l2.78-.02.19-.094c.601-.296.73-1.108.251-1.587-.309-.309-.205-.3-3.278-.294-1.498.002-2.759.011-2.803.02z"
      fill="currentColor"
    />
  </Icon>
);

PencilAlt.displayName = 'PencilAltIcon';
