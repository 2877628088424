import { FC } from 'react';
import { MotionBox, TextAndIcon } from 'ui';

export const FormError: FC = () => {
  return (
    <MotionBox
      initial={{ opacity: 0, marginTop: -12 }}
      animate={{ opacity: 1, marginTop: 12 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      backgroundColor="pink.100"
      py={1.5}
      borderRadius="md"
    >
      <TextAndIcon
        icon="emojisad"
        iconProps={{
          color: 'main.pink',
        }}
        textProps={{
          ml: 2,
          color: 'main.pink',
          fontWeight: 'semi',
        }}
        text="Something Went Wrong"
      />
    </MotionBox>
  );
};
