import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Photograph: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62 3.025c-.408.08-.533.114-.75.202-.88.358-1.549 1.137-1.794 2.093-.083.326-.084 13.031 0 13.357.281 1.096 1.044 1.878 2.184 2.238.178.056.9.064 6.56.075 4.306.008 6.448-.001 6.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.327.084-13.033 0-13.357-.307-1.197-1.264-2.09-2.444-2.28-.246-.04-12.66-.057-12.86-.018zm12.72 2.041c.115.039.263.135.361.233.31.309.3.191.3 3.408 0 1.569-.01 2.853-.02 2.853s-.106-.066-.21-.146c-.77-.592-1.95-.74-2.89-.361-.44.178-.689.36-1.29.944l-.594.577-1.048-1.041c-.577-.572-1.136-1.099-1.242-1.171a3.066 3.066 0 00-2.826-.309c-.528.214-.655.322-2.292 1.946l-1.591 1.578.011-3.938L5.02 5.7l.111-.189c.125-.213.318-.375.539-.454.123-.044 1.269-.054 6.313-.055 5.634-.002 6.18.004 6.357.064zm-4.697 2C13.288 7.197 13 7.615 13 8c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.21-.109-.588-.129-.818-.045zM10.4 11.923c.137.062.756.655 2.62 2.514 2.212 2.205 2.458 2.439 2.637 2.498.39.129.766.044 1.044-.234.28-.279.363-.656.233-1.044-.056-.167-.177-.309-.79-.929l-.724-.733.54-.529c.582-.571.677-.627 1.065-.625.346.001.5.107 1.265.87l.71.708v1.863c0 2.113 0 2.121-.299 2.419-.322.322.202.299-6.7.299-6.904 0-6.38.023-6.702-.299-.268-.268-.299-.415-.299-1.419v-.864l2.25-2.243c1.721-1.716 2.283-2.253 2.39-2.285.235-.07.562-.056.76.033z"
      fill="currentColor"
    />
  </Icon>
);

Photograph.displayName = 'PhotographIcon';
