import { FC } from 'react';
import {
  BoxProps,
  FormControl,
  Input,
  InputGroup,
  InputProps,
} from '@chakra-ui/react';
import { FieldLabel, FieldLabelProps } from './FieldLabel';

interface UnconnectedInputProps {
  containerProps?: BoxProps;
  labelProps?: FieldLabelProps;
  id: string;
  placeholder?: string;
  type?: string;
  inputProps?: InputProps;
}

export const UnconnectedInput: FC<UnconnectedInputProps> = ({
  containerProps,
  labelProps,
  id,
  placeholder = 'Enter',
  type = 'text',
  inputProps,
}) => (
  <FormControl {...containerProps}>
    {labelProps?.text && <FieldLabel {...labelProps} />}
    <InputGroup maxWidth="100%">
      <Input placeholder={placeholder} type={type} id={id} {...inputProps} />
    </InputGroup>
  </FormControl>
);
