import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ThumbDown: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.154 2.06a3.085 3.085 0 00-1.851 1.194c-.253.356-3.888 7.662-3.973 7.986-.095.362-.093 1.167.004 1.52a3.094 3.094 0 002.126 2.144c.213.063.484.072 2.39.086l2.15.014v2.14c0 2.34.009 2.456.237 3.01a3.037 3.037 0 001.596 1.603c.422.174.671.22 1.167.222.556 0 .856-.087 1.214-.355.501-.377.727-.83.789-1.584.035-.436.129-.828.264-1.098.033-.067.63-.981 1.327-2.031L16.86 15h1.18c.649-.001 1.294-.02 1.434-.043 1.186-.192 2.147-1.086 2.45-2.278.083-.324.083-7.036 0-7.357-.307-1.197-1.264-2.09-2.448-2.281-.141-.023-.729-.042-1.307-.042h-1.05l-1.93-.49-1.929-.49-2.42-.007c-1.893-.006-2.478.004-2.686.047zm6.276 2.328l1.57.39v7.92l-1.72 2.582c-1.796 2.697-1.943 2.947-2.119 3.62a6.493 6.493 0 00-.13.714l-.047.394-.14-.023c-.355-.057-.711-.366-.802-.696-.026-.094-.042-.916-.042-2.22V15h.573c.686 0 .881-.051 1.128-.298a.984.984 0 000-1.402c-.314-.314-.11-.3-4.098-.301-2.855-.001-3.572-.012-3.693-.055a1.038 1.038 0 01-.539-.454c-.095-.162-.111-.232-.11-.49l.002-.3 1.824-3.64c1.338-2.667 1.861-3.673 1.959-3.767.074-.07.215-.164.314-.21.174-.077.256-.08 2.34-.082l2.16-.002 1.57.39zm4.91.678c.115.039.263.135.361.233.312.31.299.157.299 3.7 0 3.545.013 3.39-.299 3.702-.247.247-.442.299-1.128.299H18V5h.573c.428 0 .622.017.767.066z"
      fill="currentColor"
    />
  </Icon>
);

ThumbDown.displayName = 'ThumbDownIcon';
