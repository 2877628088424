import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronLeft: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.64 4.068c-.156.053-.66.541-3.811 3.687-2.629 2.624-3.655 3.674-3.716 3.801a1.013 1.013 0 00-.053.77c.066.203.247.392 3.695 3.845 2.624 2.629 3.674 3.655 3.801 3.716a.986.986 0 001.144-.185c.279-.279.364-.655.235-1.045-.059-.18-.347-.479-3.289-3.427L9.421 12l3.225-3.23c2.942-2.948 3.23-3.247 3.289-3.427.128-.39.044-.766-.234-1.044a1.006 1.006 0 00-1.061-.231z"
      fill="currentColor"
    />
  </Icon>
);

ChevronLeft.displayName = 'ChevronLeftIcon';
