import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Variable: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6 3.037c-.36.11-.499.265-.797.888-.886 1.851-1.463 3.93-1.71 6.155-.085.774-.097 2.846-.02 3.62.192 1.932.609 3.672 1.285 5.36.291.728.666 1.499.794 1.634a1.03 1.03 0 001.023.254.96.96 0 00.568-.478.777.777 0 00.115-.47c-.001-.235-.025-.311-.251-.789-.816-1.73-1.301-3.491-1.533-5.571-.068-.611-.068-2.678 0-3.28.237-2.107.712-3.83 1.533-5.571.226-.478.25-.554.251-.789a.973.973 0 00-.662-.942 1.084 1.084 0 00-.596-.021zm14.043.029c-.388.143-.674.598-.634 1.011.011.115.108.381.249.685 2.067 4.44 2.148 9.549.222 13.961l-.383.877a.963.963 0 00.203 1.102c.412.413 1.133.377 1.476-.073.227-.298.87-1.831 1.2-2.863.597-1.863.878-3.701.879-5.746.001-2.068-.28-3.917-.879-5.786-.33-1.032-.973-2.565-1.2-2.863-.238-.311-.743-.448-1.133-.305zm-10 5.003a1.118 1.118 0 00-.343.229c-.48.48-.352 1.292.25 1.588.173.086.257.096.946.114l.756.02.314 1.1c.173.605.306 1.122.296 1.149-.01.027-.497.606-1.081 1.286C8.588 14.946 8.58 14.953 8.04 15c-.52.046-.839.266-.979.675a.994.994 0 00.62 1.265c.468.156 1.338-.017 1.958-.388.33-.197.664-.528 1.383-1.367.357-.417.518-.578.539-.54.016.03.096.287.176.57.081.284.194.608.251.72.151.298.577.706.892.854.382.18.599.211 1.475.211.924 0 1.082-.035 1.346-.299.479-.479.35-1.291-.251-1.587-.173-.086-.257-.096-.946-.114l-.756-.02-.314-1.1c-.173-.605-.306-1.121-.297-1.146.01-.026.493-.602 1.075-1.28.74-.865 1.107-1.262 1.224-1.328.117-.065.269-.102.501-.124.547-.049.86-.261 1.002-.677a.994.994 0 00-.619-1.265c-.472-.157-1.335.014-1.959.388-.329.197-.664.528-1.381 1.365-.279.326-.516.584-.527.573a7.666 7.666 0 01-.189-.598c-.103-.352-.223-.67-.307-.808a2.124 2.124 0 00-1.309-.936c-.334-.07-1.788-.052-2.005.025z"
      fill="currentColor"
    />
  </Icon>
);

Variable.displayName = 'VariableIcon';
