import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Block } from './Notion';
import { ElementSizes } from './types';

interface BlockColumnsProps {
  env: string;
  size: ElementSizes;
  columns?: any;
}

export const BlockColumns: FC<BlockColumnsProps> = ({ columns, env, size }) => {
  return (
    <Flex
      flexWrap="wrap"
      align="center"
      justify="space-evenly"
      width="100%"
      overflowX="hidden"
    >
      {columns?.map((child, index) => (
        <Block
          key={index}
          block={child}
          env={env}
          size={size}
          columnSize={columns.length}
          columnIndex={index}
        />
      ))}
    </Flex>
  );
};
