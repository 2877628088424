import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SortAscending: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.643 3.069C2.291 3.193 2 3.614 2 4c0 .242.119.521.299.701C2.622 5.024 2.037 5 9.5 5c7.463 0 6.878.024 7.201-.299a.984.984 0 000-1.402c-.323-.324.265-.299-7.218-.296-5.928.003-6.682.01-6.84.066zm0 4C2.291 7.193 2 7.614 2 8c0 .242.119.521.299.701C2.617 9.02 2.277 9 7.5 9s4.883.02 5.201-.299a.984.984 0 000-1.402c-.319-.319.025-.299-5.218-.296-4.116.003-4.684.011-4.84.066zm13.997-.001c-.154.053-.488.369-2.311 2.187-1.516 1.511-2.156 2.176-2.217 2.301a.99.99 0 00.186 1.144c.279.28.656.364 1.045.234.174-.057.342-.21 1.427-1.288L16 10.423v4.861c0 5.465-.02 5.098.299 5.417a.984.984 0 001.402 0c.319-.319.299.048.299-5.417v-4.861l1.23 1.223c1.085 1.078 1.253 1.231 1.427 1.288.389.13.766.046 1.045-.234a.99.99 0 00.186-1.144c-.061-.125-.701-.79-2.217-2.301-1.924-1.919-2.15-2.131-2.328-2.19a1.053 1.053 0 00-.703.003zM2.643 11.069C2.291 11.193 2 11.614 2 12c0 .242.119.521.299.701.311.312.157.299 3.701.299s3.39.013 3.701-.299a.984.984 0 000-1.402c-.312-.312-.154-.299-3.718-.296-2.757.003-3.186.011-3.34.066z"
      fill="currentColor"
    />
  </Icon>
);

SortAscending.displayName = 'SortAscendingIcon';
