import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Music: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M14.764 3.020 C 9.766 3.858,8.676 4.052,8.524 4.130 C 8.314 4.238,8.102 4.493,8.042 4.711 C 8.014 4.810,8.000 6.486,8.000 9.690 C 8.000 12.347,7.986 14.520,7.969 14.520 C 7.952 14.520,7.902 14.496,7.859 14.467 C 7.710 14.366,7.180 14.168,6.860 14.094 C 6.390 13.986,5.541 13.995,5.062 14.114 C 4.659 14.213,4.087 14.471,3.731 14.712 C 2.955 15.240,2.394 16.037,2.130 16.989 C 2.034 17.337,2.022 17.449,2.022 18.000 C 2.022 18.553,2.034 18.663,2.132 19.017 C 2.475 20.256,3.357 21.252,4.520 21.715 C 5.836 22.239,7.386 22.010,8.481 21.130 C 9.246 20.516,9.721 19.735,9.917 18.774 C 9.999 18.373,10.000 18.279,10.000 12.104 C 10.000 8.659,10.009 5.840,10.021 5.840 C 10.032 5.840,12.255 5.471,14.960 5.020 C 17.665 4.569,19.906 4.200,19.939 4.200 C 19.993 4.200,20.000 4.670,20.000 8.360 C 20.000 10.648,19.986 12.520,19.969 12.520 C 19.952 12.520,19.902 12.496,19.859 12.467 C 19.710 12.366,19.180 12.168,18.860 12.094 C 18.390 11.986,17.541 11.995,17.062 12.114 C 16.659 12.213,16.087 12.471,15.731 12.712 C 14.955 13.240,14.394 14.037,14.130 14.989 C 14.034 15.337,14.022 15.449,14.022 16.000 C 14.022 16.553,14.034 16.663,14.132 17.017 C 14.475 18.256,15.357 19.252,16.520 19.715 C 17.836 20.239,19.386 20.010,20.481 19.130 C 21.246 18.516,21.721 17.735,21.917 16.774 C 21.999 16.372,22.000 16.285,22.000 9.614 C 22.000 5.085,21.986 2.811,21.958 2.711 C 21.853 2.332,21.461 2.033,21.045 2.014 C 20.896 2.008,18.786 2.345,14.764 3.020 M18.383 14.043 C 19.185 14.190,19.839 14.872,19.967 15.695 C 20.153 16.894,19.209 18.000,18.000 18.000 C 17.032 18.000,16.183 17.268,16.033 16.305 C 15.933 15.663,16.132 15.061,16.596 14.596 C 17.085 14.108,17.698 13.918,18.383 14.043 M6.383 16.043 C 7.185 16.190,7.839 16.872,7.967 17.695 C 8.153 18.894,7.209 20.000,6.000 20.000 C 5.032 20.000,4.183 19.268,4.033 18.305 C 3.933 17.663,4.132 17.061,4.596 16.596 C 5.085 16.108,5.698 15.918,6.383 16.043 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Music.displayName = 'MusicIcon';
