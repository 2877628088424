import { FC, useState } from 'react';
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  IconButton,
  IconButtonProps,
  Text,
} from '@chakra-ui/react';
import { ChevronDown, ChevronUp } from 'icons';

interface CollapsibleSectionWithHeaderProps {
  buttonProps?: IconButtonProps;
  children?: JSX.Element | JSX.Element[];
  collapsed?: boolean;
  containerProps?: BoxProps;
  containerIsCollapsedProps?: BoxProps;
  containerIsExpandedProps?: BoxProps;
  contentProps?: BoxProps;
  header: string | JSX.Element;
  headerProps?: FlexProps;
  isLocked?: boolean;
  onToggle?: () => void;
}

export const CollapsibleSectionWithTitle: FC<
  CollapsibleSectionWithHeaderProps
> = ({
  buttonProps,
  children,
  collapsed,
  containerProps,
  containerIsCollapsedProps,
  containerIsExpandedProps,
  contentProps,
  header,
  headerProps,
  isLocked = false,
  onToggle,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed || false);

  const handleToggle = e => {
    e.stopPropagation();
    if (!isLocked) setIsCollapsed(!isCollapsed);
    if (onToggle) onToggle();
  };

  return (
    <Box
      border="1px solid"
      borderColor="main.light"
      borderRadius="md"
      overflow="hidden"
      {...containerProps}
      {...(isCollapsed ? containerIsCollapsedProps : {})}
      {...(!isCollapsed ? containerIsExpandedProps : {})}
    >
      <Flex
        alignItems="center"
        backgroundColor="bg.light"
        color="text.primary"
        cursor="pointer"
        justifyContent="space-between"
        onClick={handleToggle}
        userSelect="none"
        {...headerProps}
      >
        {typeof header === 'string' ? <Text>{header}</Text> : { ...header }}
        <Box>
          <IconButton
            aria-label="expand section"
            colorScheme="gray"
            icon={isLocked || isCollapsed ? <ChevronDown /> : <ChevronUp />}
            margin={1}
            maxHeight={6}
            maxWidth={6}
            minWidth="unset"
            padding={0}
            width={6}
            variant="ghost"
            {...buttonProps}
          />
        </Box>
      </Flex>
      {!isLocked && !isCollapsed && <Box {...contentProps}>{children}</Box>}
    </Box>
  );
};
