import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillDependable: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M7.078 16.688a.39.39 0 100 .781.39.39 0 000-.782z"
      fill="currentColor"
    />
    <path
      d="M21.61 4.188H2.39a.39.39 0 00-.39.39v14.844c0 .216.175.39.39.39h19.22a.39.39 0 00.39-.39V4.578a.39.39 0 00-.39-.39zM5.877 14.344a2.737 2.737 0 00-3.097-3.097V8.066a2.737 2.737 0 003.097-3.1h12.244a2.74 2.74 0 003.097 3.1v3.181a2.737 2.737 0 00-3.097 3.097H5.878zm-3.097 0v-2.305a1.955 1.955 0 012.305 2.305H2.78zm16.133 0a1.955 1.955 0 012.305-2.305v2.305h-2.305zm-4.96.781h7.265v1.563h-7.266v-1.563zm7.265-7.852a1.955 1.955 0 01-2.305-2.305h2.305v2.305zM5.086 4.97A1.955 1.955 0 012.78 7.274V4.968h2.305zm-2.305 12.5h2.735a.39.39 0 000-.782H2.78v-1.562h7.266v1.563H8.64a.39.39 0 100 .78h1.406v1.563H2.78V17.47zm8.047-2.344h2.344v3.906h-2.344v-3.906zm10.39 3.906h-7.265V17.47h7.266v1.562z"
      fill="currentColor"
    />
    <path
      d="M15.906 9.656A3.91 3.91 0 0012 5.75a3.906 3.906 0 103.906 3.906zm-7.031 0A3.128 3.128 0 0112 6.531a3.128 3.128 0 013.125 3.125c0 .706-.238 1.383-.666 1.929a2.748 2.748 0 00-1.296-1.277c.248-.277.4-.642.4-1.042 0-.862-.702-1.563-1.563-1.563s-1.563.701-1.563 1.563c0 .4.152.765.4 1.042a2.749 2.749 0 00-1.296 1.277 3.123 3.123 0 01-.666-1.929zm2.344-.39a.782.782 0 111.564.001.782.782 0 01-1.564-.001zm-1.074 2.904A1.958 1.958 0 0112 10.828c.85 0 1.596.553 1.855 1.342a3.122 3.122 0 01-3.71 0zM17.703 10.047h1.563a.39.39 0 100-.781h-1.563a.39.39 0 000 .78zM6.297 9.266H4.734a.39.39 0 100 .78h1.563a.39.39 0 100-.78z"
      fill="currentColor"
    />
  </Icon>
);

SkillDependable.displayName = 'SkillDependableIcon';
