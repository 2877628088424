import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserMinus: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M7.864 2.043 C 5.952 2.308,4.393 3.580,3.774 5.380 C 3.264 6.863,3.471 8.457,4.344 9.770 C 4.961 10.696,5.860 11.381,6.925 11.738 C 9.553 12.617,12.386 11.185,13.252 8.540 C 13.712 7.133,13.506 5.532,12.706 4.304 C 11.968 3.172,10.839 2.392,9.540 2.115 C 9.116 2.025,8.258 1.988,7.864 2.043 M9.319 4.121 C 10.141 4.353,10.798 4.908,11.185 5.700 C 11.397 6.135,11.479 6.495,11.479 7.000 C 11.479 7.505,11.397 7.865,11.185 8.300 C 10.800 9.087,10.173 9.621,9.320 9.886 C 9.071 9.963,8.932 9.979,8.500 9.979 C 8.068 9.979,7.929 9.963,7.680 9.886 C 6.678 9.574,5.928 8.829,5.620 7.838 C 5.521 7.518,5.496 6.735,5.574 6.370 C 5.830 5.166,6.777 4.269,8.033 4.038 C 8.327 3.984,8.984 4.027,9.319 4.121 M16.664 10.063 C 16.436 10.146,16.257 10.297,16.131 10.511 C 16.036 10.672,16.020 10.744,16.020 11.000 C 16.020 11.256,16.036 11.328,16.131 11.489 C 16.192 11.592,16.301 11.723,16.374 11.778 C 16.672 12.006,16.578 12.000,19.998 12.000 C 23.545 12.000,23.390 12.013,23.701 11.701 C 24.092 11.310,24.092 10.690,23.701 10.299 C 23.389 9.987,23.547 10.000,19.983 10.003 C 17.342 10.005,16.794 10.015,16.664 10.063 M4.340 14.046 C 3.354 14.172,2.310 14.671,1.574 15.371 C 0.848 16.061,0.392 16.844,0.134 17.848 C 0.030 18.248,0.027 18.302,0.010 19.683 C -0.008 21.243,0.001 21.330,0.211 21.606 C 0.649 22.179,1.570 22.093,1.886 21.450 C 1.977 21.265,1.981 21.222,2.004 19.860 C 2.023 18.790,2.043 18.413,2.088 18.260 C 2.371 17.317,3.003 16.593,3.860 16.233 C 4.383 16.013,4.230 16.020,8.500 16.020 C 12.896 16.020,12.635 16.005,13.260 16.298 C 14.054 16.670,14.593 17.296,14.882 18.180 C 14.968 18.444,14.975 18.543,14.997 19.880 L 15.020 21.300 15.131 21.489 C 15.470 22.065,16.234 22.168,16.702 21.700 C 16.999 21.403,17.009 21.332,16.990 19.689 C 16.973 18.301,16.970 18.248,16.866 17.848 C 16.608 16.844,16.152 16.061,15.426 15.371 C 14.669 14.652,13.644 14.169,12.601 14.042 C 12.182 13.990,4.747 13.994,4.340 14.046 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

UserMinus.displayName = 'UserMinusIcon';
