import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MinusCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.44.34-1.308 1.21-1.65 1.651-1.145 1.479-1.813 3.078-2.078 4.975-.065.465-.065 1.975 0 2.44.223 1.596.73 2.976 1.558 4.24 1.419 2.169 3.642 3.721 6.13 4.281.832.187 1.288.236 2.22.237.894.001 1.252-.032 2.02-.183 2.57-.508 4.947-2.129 6.385-4.355.806-1.247 1.282-2.525 1.52-4.08.088-.562.1-2.033.023-2.58-.27-1.921-.96-3.558-2.106-5-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.993 7.993 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.69-9.3 7.491 7.491 0 011.46-2.073c1.694-1.742 4.143-2.639 6.517-2.386zm-4.15 7.028c-.35.124-.642.545-.642.931 0 .242.119.521.299.701.31.312.157.299 3.7.299 3.545 0 3.39.013 3.702-.299a.984.984 0 000-1.402c-.312-.312-.154-.299-3.718-.296-2.757.003-3.186.011-3.34.066z"
      fill="currentColor"
    />
  </Icon>
);

MinusCircle.displayName = 'MinusCircleIcon';
