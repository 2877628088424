import { cookie, getStore, REFLEX_STORE } from 'helpers';
import { useMobileApp } from 'hooks';
import { USER_TOKEN } from 'types';

export const getCookieDomain = domain => {
  const cleanedDomain = domain.startsWith('.') ? domain.substring(1) : domain;
  const domainParts = cleanedDomain.split('.');
  if (domainParts.length > 2) {
    domainParts.shift();
  }
  return domainParts.join('.');
};

interface useAuthTokenReturn {
  setAuthToken: (token: string) => void;
  clearAuthToken: () => void;
  token: string;
}

export const useAuthToken = (): useAuthTokenReturn => {
  const { token: mobileToken } = useMobileApp();

  const clearAuthToken = () => {
    cookie.destroy(USER_TOKEN);
    const currentStore = getStore(REFLEX_STORE);
    const { user, ...rest } = currentStore || {}; // eslint-disable-line unused-imports/no-unused-vars
    window.localStorage.setItem(
      REFLEX_STORE,
      JSON.stringify({ user: {}, ...rest }),
    );
  };

  const setAuthToken = (token: string) => {
    clearAuthToken();
    const cookieObj = {};
    cookieObj[USER_TOKEN] =
      `${token}; path=/; domain=${getCookieDomain(document.domain)}`;
    // eslint-disable-next-line
    let now = new Date();
    const expireTime = now.getTime() + 60 * 24 * 60 * 60 * 1000;
    now.setTime(expireTime);
    cookie.set(cookieObj, now.toUTCString());
  };

  const getToken = () => {
    const cookieToken = cookie.get(USER_TOKEN);

    if (mobileToken && mobileToken !== cookieToken) {
      setAuthToken(mobileToken);
      if (typeof window !== 'undefined') window.location.reload();
      return mobileToken;
    }

    return cookieToken;
  };

  return {
    setAuthToken,
    clearAuthToken,
    token: getToken(),
  };
};
