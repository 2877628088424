import { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { BlockSpacer } from './BlockSpacer';
import { BlockText } from './BlockText';
import { elementPropsBySize } from './elements.config';
import { BlockType, ElementSizes } from './types';

interface BlockParagraphProps extends BlockType {
  size: ElementSizes;
  id: string;
}

export const BlockParagraph: FC<BlockParagraphProps> = ({
  size,
  id,
  rich_text,
}) => {
  if (rich_text.length === 0)
    return <BlockSpacer {...elementPropsBySize[size].spacer} />;
  return (
    <Text color="text.secondary" {...elementPropsBySize[size].paragraph}>
      <BlockText text={rich_text} id={id} />
    </Text>
  );
};
