import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const FolderOpenAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M4.500 3.028 C 3.660 3.139,2.868 3.735,2.501 4.531 C 2.264 5.044,2.240 5.261,2.240 6.908 L 2.240 8.380 1.942 8.533 C 1.249 8.888,0.760 9.687,0.760 10.462 C 0.760 10.582,0.913 12.241,1.101 14.150 C 1.288 16.058,1.468 17.909,1.501 18.263 C 1.591 19.243,1.778 19.705,2.297 20.223 C 2.617 20.543,2.961 20.750,3.400 20.887 L 3.700 20.980 12.020 20.980 L 20.340 20.980 20.665 20.870 C 21.279 20.662,21.838 20.205,22.136 19.667 C 22.356 19.272,22.435 18.966,22.499 18.263 C 22.532 17.909,22.712 16.058,22.899 14.150 C 23.087 12.241,23.240 10.582,23.240 10.462 C 23.240 10.143,23.171 9.859,23.010 9.520 C 22.828 9.135,22.378 8.675,22.014 8.501 L 21.769 8.384 21.750 7.522 C 21.734 6.771,21.721 6.624,21.642 6.380 C 21.377 5.551,20.771 4.937,19.900 4.614 C 19.712 4.544,19.465 4.539,15.640 4.520 C 11.615 4.500,11.578 4.499,11.398 4.416 C 11.297 4.369,10.884 4.110,10.480 3.840 C 10.075 3.570,9.634 3.297,9.499 3.234 C 9.032 3.015,8.833 2.999,6.626 3.006 C 5.523 3.009,4.566 3.019,4.500 3.028 M8.864 4.596 C 8.954 4.637,9.350 4.886,9.745 5.150 C 10.534 5.677,10.658 5.747,11.040 5.885 L 11.300 5.979 15.360 5.999 C 19.848 6.022,19.538 6.003,19.854 6.280 C 19.936 6.352,20.052 6.494,20.112 6.595 C 20.217 6.775,20.220 6.799,20.233 7.510 L 20.246 8.240 12.003 8.240 L 3.760 8.240 3.760 6.806 L 3.760 5.373 3.885 5.122 C 4.015 4.862,4.226 4.671,4.493 4.573 C 4.584 4.539,5.202 4.525,6.660 4.524 C 8.565 4.522,8.711 4.527,8.864 4.596 M21.292 9.814 C 21.462 9.885,21.665 10.121,21.720 10.309 C 21.756 10.433,21.702 11.087,21.422 13.931 C 21.234 15.840,21.053 17.681,21.020 18.022 C 20.951 18.742,20.878 18.958,20.623 19.193 C 20.531 19.278,20.366 19.382,20.258 19.424 C 20.066 19.498,19.831 19.500,12.000 19.500 C 4.169 19.500,3.934 19.498,3.742 19.424 C 3.349 19.272,3.061 18.885,3.016 18.448 C 3.006 18.344,2.826 16.499,2.617 14.346 C 2.300 11.086,2.244 10.410,2.282 10.293 C 2.341 10.115,2.543 9.884,2.699 9.816 C 2.881 9.736,21.102 9.735,21.292 9.814 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

FolderOpenAlt.displayName = 'FolderOpenAltIcon';
