import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useFormContext, UseFormRegister } from 'react-hook-form';
import { Textarea, TextareaProps } from '@chakra-ui/react';

export interface AutoTextareaProps extends TextareaProps {
  value?: string | number | readonly string[];
  autoResize?: boolean;
  id: string;
  register?: UseFormRegister<any>;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

export const AutoTextarea = ({
  value,
  autoResize = true,
  id,
  register,
  onChange,
  onBlur,
  ...props
}: AutoTextareaProps) => {
  const formContext = useFormContext();
  const _register = register
    ? register
    : formContext?.register
      ? formContext?.register
      : null;
  const {
    onChange: _onChange = undefined,
    onBlur: _onBlur = undefined,
    name = undefined,
    ref = undefined,
  } = _register ? _register(id) : {};
  const [_value, setValue] = useState(value ?? '');
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (!autoResize) return;
    const textArea = textAreaRef.current;
    textArea.style.height = 'auto';
    textArea.style.height = `${textArea.scrollHeight}px`;
  }, [autoResize, _value]);

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Textarea
      id={id}
      ref={el => {
        textAreaRef.current = el;
        ref && ref(el);
      }}
      onChange={e => {
        setValue(e.target.value);
        onChange && onChange(e);
        _onChange && _onChange(e);
      }}
      onBlur={e => {
        onBlur && onBlur(e);
        _onBlur && _onBlur(e);
      }}
      name={name}
      value={_value}
      backgroundColor="white"
      resize="none"
      {...props}
    />
  );
};
