import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CloudLightning: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M8.320 0.025 C 5.471 0.286,3.027 1.727,1.475 4.060 C 1.166 4.524,0.683 5.503,0.499 6.040 C 0.119 7.147,-0.022 8.075,0.013 9.240 C 0.056 10.660,0.317 11.716,0.938 12.980 C 1.657 14.446,2.668 15.590,4.079 16.536 C 4.745 16.982,5.046 17.071,5.442 16.939 C 5.957 16.768,6.240 16.190,6.059 15.678 C 5.964 15.408,5.826 15.261,5.459 15.040 C 4.895 14.699,4.484 14.374,4.020 13.904 C 2.345 12.208,1.661 9.836,2.166 7.484 C 2.346 6.647,2.785 5.663,3.311 4.920 C 3.656 4.433,4.433 3.656,4.920 3.311 C 5.664 2.785,6.581 2.376,7.450 2.183 C 9.302 1.769,11.239 2.113,12.791 3.130 C 14.193 4.049,15.142 5.301,15.670 6.930 C 15.871 7.549,15.990 7.731,16.297 7.890 C 16.439 7.963,16.563 7.978,17.260 8.006 C 18.817 8.067,19.516 8.228,20.231 8.692 C 21.011 9.198,21.617 10.049,21.870 10.993 C 21.960 11.331,21.974 11.459,21.974 12.000 C 21.974 12.542,21.960 12.669,21.870 13.008 C 21.574 14.111,20.826 15.045,19.832 15.552 C 19.543 15.699,19.276 15.795,18.759 15.937 C 18.043 16.132,17.768 16.996,18.248 17.543 C 18.613 17.958,19.003 17.989,19.934 17.677 C 21.673 17.095,23.047 15.727,23.672 13.955 C 23.985 13.070,24.081 11.961,23.925 11.045 C 23.707 9.772,23.137 8.661,22.239 7.761 C 21.256 6.777,19.982 6.212,18.439 6.078 C 18.164 6.055,17.834 6.023,17.706 6.008 L 17.471 5.980 17.380 5.740 C 16.786 4.164,15.621 2.678,14.170 1.646 C 13.058 0.856,11.685 0.308,10.300 0.102 C 9.871 0.038,8.681 -0.008,8.320 0.025 M12.694 10.057 C 12.602 10.086,12.470 10.150,12.400 10.200 C 12.251 10.306,8.144 16.443,8.056 16.690 C 7.897 17.143,8.115 17.672,8.550 17.886 L 8.740 17.980 10.930 18.000 L 13.120 18.020 11.604 20.294 C 10.626 21.761,10.071 22.631,10.039 22.745 C 9.802 23.591,10.758 24.314,11.508 23.857 C 11.684 23.750,11.870 23.485,13.787 20.620 C 14.935 18.904,15.902 17.420,15.937 17.323 C 16.103 16.855,15.893 16.332,15.450 16.114 L 15.260 16.020 13.070 16.000 L 10.880 15.980 12.384 13.725 C 13.211 12.484,13.913 11.398,13.944 11.310 C 14.210 10.553,13.472 9.814,12.694 10.057 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

CloudLightning.displayName = 'CloudLightningIcon';
