import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Backspace: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.22 4.066a3.262 3.262 0 00-1.043.434c-.348.225-6.949 6.819-7.068 7.06a1.095 1.095 0 000 .88c.119.241 6.72 6.835 7.068 7.06.317.205.696.36 1.071.437.27.055.848.063 4.64.063 2.803 0 4.422-.015 4.588-.042 1.179-.191 2.146-1.092 2.448-2.278.083-.327.083-11.033 0-11.357-.307-1.197-1.264-2.09-2.444-2.28-.171-.028-1.753-.041-4.62-.039-3.707.002-4.402.011-4.64.062zm9.12 2c.115.039.263.135.361.233.32.32.299-.082.299 5.701 0 5.784.021 5.381-.3 5.702-.318.318-.022.301-4.862.289l-4.298-.011-.16-.087c-.091-.05-1.409-1.338-3.06-2.99L4.42 12l2.9-2.903c1.595-1.596 2.954-2.931 3.02-2.966.066-.035.165-.077.22-.094.055-.016 2.009-.032 4.343-.034 3.85-.003 4.261.003 4.437.063zm-7.697 3.003c-.352.124-.643.545-.643.931 0 .364.088.496.854 1.27l.721.73-.721.73c-.766.774-.854.906-.854 1.27 0 .527.473 1 1 1 .364 0 .496-.088 1.27-.854l.73-.721.73.721c.774.766.906.854 1.27.854.527 0 1-.473 1-1 0-.364-.088-.496-.854-1.27l-.721-.73.721-.73c.766-.774.854-.906.854-1.27 0-.527-.473-1-1-1-.364 0-.496.088-1.27.854l-.73.721-.73-.721c-.618-.611-.76-.732-.927-.788a1.026 1.026 0 00-.7.003z"
      fill="currentColor"
    />
  </Icon>
);

Backspace.displayName = 'BackspaceIcon';
