import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LightBulb: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.643 2.069a1.066 1.066 0 00-.577.59c-.051.15-.066.345-.066.84 0 .767.045.948.299 1.202a.984.984 0 001.402 0c.254-.254.299-.435.299-1.201s-.045-.947-.299-1.201a.998.998 0 00-1.058-.23zM5.36 4.675c-.464.152-.698.475-.699.965-.001.254.015.328.106.48.144.243.93 1.008 1.151 1.12.486.246 1.126.003 1.346-.511a1.108 1.108 0 00-.024-.811C7.13 5.7 6.365 4.91 6.128 4.772c-.184-.11-.58-.16-.768-.097zm12.675.018c-.213.08-.333.177-.78.633-.48.49-.585.672-.585 1.012 0 .574.414.988.991.99.359.003.557-.117 1.084-.654.547-.558.593-.64.593-1.034 0-.324-.058-.484-.253-.694-.235-.254-.729-.373-1.05-.253zM11.28 6.04c-2.026.279-3.715 1.466-4.618 3.247-.467.919-.639 1.65-.639 2.713 0 1.064.172 1.795.639 2.713.329.648.625 1.04 1.341 1.787.859.894.947 1.096.999 2.28.042.955.135 1.305.493 1.855a2.99 2.99 0 002.305 1.35c1.42.104 2.708-.826 3.108-2.245.032-.113.071-.538.09-.98.051-1.165.137-1.361.994-2.256.705-.735 1.015-1.144 1.322-1.742.275-.535.445-1.01.571-1.602.075-.35.09-.544.09-1.16 0-.616-.015-.811-.09-1.16-.391-1.832-1.51-3.318-3.126-4.156-.857-.445-1.613-.637-2.599-.663a7.55 7.55 0 00-.88.019zm1.66 2.075c.455.114.848.286 1.251.549 1.67 1.088 2.274 3.269 1.407 5.076-.209.436-.45.753-1.018 1.34-.309.319-.622.656-.694.75l-.133.17h-3.506l-.133-.17a16.037 16.037 0 00-.694-.75c-.568-.587-.809-.904-1.018-1.34a4.007 4.007 0 012.908-5.68c.416-.078 1.2-.052 1.63.055zM2.643 11.069c-.352.124-.643.545-.643.93 0 .243.119.522.299.702.254.254.435.299 1.201.299s.947-.045 1.201-.3a.984.984 0 000-1.401c-.256-.257-.434-.3-1.218-.296-.492.002-.709.019-.84.066zm17 0c-.352.124-.643.545-.643.93 0 .243.119.522.299.702.254.254.435.299 1.201.299s.947-.045 1.201-.3c.18-.18.299-.458.299-.7 0-.396-.291-.808-.66-.934-.268-.091-1.436-.09-1.697.003zM13 18.629c-.018.557-.031.653-.114.82-.28.57-1.035.722-1.535.31-.269-.222-.328-.414-.351-1.13l-.02-.63h2.04l-.02.63z"
      fill="currentColor"
    />
  </Icon>
);

LightBulb.displayName = 'LightBulbIcon';
