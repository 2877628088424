import { FC } from 'react';
import { default as MarkdownRenderer, MarkdownToJSX } from 'markdown-to-jsx';
import { MarkdownConfig } from './Markdown.config';
import styles from '../Content.module.css';

interface MarkdownProps {
  content: string;
  overrides?: MarkdownToJSX.Overrides;
  includeSpacing?: boolean;
}

export const Markdown: FC<MarkdownProps> = ({
  content,
  overrides,
  includeSpacing,
}) => {
  return (
    <MarkdownRenderer
      options={{
        overrides: {
          ...MarkdownConfig(includeSpacing),
          ...overrides,
        },
        wrapper: 'div',
      }}
      className={styles.container}
    >
      {content}
    </MarkdownRenderer>
  );
};
