import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LockClosed: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.056c-1.89.317-3.409 1.589-3.994 3.345-.232.694-.237.756-.255 2.744l-.017 1.846-.767.02c-.505.014-.835.041-.967.08-1.125.333-1.901 1.125-2.184 2.229-.083.323-.083 7.034 0 7.357.281 1.096 1.044 1.878 2.184 2.238.178.056.9.064 6.56.075 4.306.008 6.448-.001 6.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.324.083-7.036 0-7.357-.307-1.197-1.264-2.09-2.444-2.28a7.543 7.543 0 00-.867-.043h-.607l-.017-1.85c-.014-1.573-.027-1.895-.086-2.15-.239-1.033-.655-1.792-1.382-2.523a4.798 4.798 0 00-2.541-1.378c-.426-.09-1.31-.111-1.72-.043zm1.332 2.007a3.01 3.01 0 012.345 2.345c.051.252.063.611.063 1.95V10H9V8.358c0-1.339.012-1.698.063-1.95.333-1.621 1.918-2.675 3.529-2.345zm5.748 8.003c.115.039.263.135.361.233.312.311.299.157.299 3.701s.013 3.39-.299 3.701c-.322.322.202.299-6.701.299-6.905 0-6.379.024-6.702-.3-.314-.314-.301-.151-.289-3.783L5.02 12.7l.111-.189c.125-.213.318-.375.539-.454.123-.044 1.269-.054 6.313-.055 5.634-.002 6.179.004 6.357.064zm-6.697 2.003a1.066 1.066 0 00-.577.591c-.098.288-.098 2.392 0 2.68.126.369.538.66.934.66s.808-.291.934-.66c.098-.288.098-2.392 0-2.68-.173-.508-.762-.778-1.291-.591z"
      fill="currentColor"
    />
  </Icon>
);

LockClosed.displayName = 'LockClosedIcon';
