import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Translate: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.643 2.069a1.118 1.118 0 00-.343.229c-.248.248-.3.442-.3 1.129V4H5.427c-2.904 0-2.821-.008-3.128.299a.984.984 0 000 1.402c.317.317.015.299 4.889.299h4.334l-.026.11c-.365 1.588-1.227 3.583-2.186 5.06-.153.237-.293.43-.311.43-.039 0-.387-.526-.688-1.04a24.292 24.292 0 01-.896-1.752c-.282-.61-.503-.784-.995-.786-.416-.003-.716.18-.91.556-.144.277-.117.604.086 1.06.467 1.044 1.216 2.378 1.807 3.214.152.216.277.415.277.442 0 .074-1.279 1.332-1.76 1.732a17.39 17.39 0 01-3.118 2.078c-.448.232-.652.424-.745.704a.99.99 0 00.955 1.31c.21.002.284-.025.8-.291a18.88 18.88 0 002.968-1.923c.589-.466.93-.771 1.609-1.44l.609-.6.259.25c.309.297.459.366.792.365.297-.001.513-.088.704-.283.222-.225.266-.341.266-.696 0-.388-.035-.46-.417-.87-.155-.165-.282-.323-.282-.348 0-.026.123-.221.273-.435a18.75 18.75 0 002.244-4.302c.218-.587.603-1.875.685-2.295L13.57 6h.788c.921 0 1.079-.035 1.343-.3a.984.984 0 000-1.401c-.307-.307-.224-.3-3.128-.3H10v-.572c0-.686-.052-.881-.299-1.128a.998.998 0 00-1.058-.23zm7 8c-.38.134-.308.005-3.058 5.514-2.133 4.273-2.558 5.153-2.575 5.323-.042.44.241.888.65 1.028a.949.949 0 00.676 0c.353-.12.44-.244 1.139-1.644L13.12 19h5.76l.645 1.29c.699 1.4.786 1.525 1.139 1.645a.949.949 0 00.676-.001c.409-.14.692-.587.65-1.028-.017-.17-.442-1.05-2.575-5.323-2.075-4.158-2.586-5.15-2.718-5.285a.992.992 0 00-1.054-.23zm1.307 5.07l.93 1.86h-3.76l.93-1.86c.511-1.022.939-1.86.95-1.86.011 0 .439.838.95 1.86z"
      fill="currentColor"
    />
  </Icon>
);

Translate.displayName = 'TranslateIcon';
