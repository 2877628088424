import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Hexagon: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.064 135.578V63.7292C174.064 61.657 173.519 59.6214 172.483 57.8268C171.447 56.0322 169.957 54.5417 168.162 53.5052L105.93 17.5809C104.135 16.5452 102.1 16 100.028 16C97.9559 16 95.9204 16.5452 94.1258 17.5809L31.902 53.5052C30.1077 54.5417 28.6178 56.0322 27.5818 57.8268C26.5458 59.6214 26.0003 61.657 26 63.7292V135.578C26.0003 137.65 26.5458 139.686 27.5818 141.48C28.6178 143.275 30.1077 144.765 31.902 145.802L94.1258 181.726C95.9204 182.762 97.9559 183.307 100.028 183.307C102.1 183.307 104.135 182.762 105.93 181.726L168.154 145.802C169.95 144.766 171.441 143.276 172.479 141.482C173.516 139.687 174.063 137.651 174.064 135.578V135.578Z"
      fill="currentColor"
    />
  </Icon>
);

Hexagon.displayName = 'HexagonShape';
