import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowLeftCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.439.34-1.308 1.21-1.649 1.651-1.146 1.479-1.814 3.078-2.079 4.975-.065.465-.065 1.975 0 2.44.223 1.596.73 2.976 1.558 4.24 1.419 2.169 3.642 3.721 6.13 4.281.832.187 1.288.236 2.22.237.894.001 1.252-.032 2.02-.183 2.571-.508 4.947-2.129 6.385-4.355.806-1.247 1.282-2.525 1.521-4.08.087-.562.099-2.033.022-2.58-.27-1.921-.96-3.558-2.106-5-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.992 7.992 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.689-9.3 7.491 7.491 0 011.46-2.073c1.693-1.742 4.142-2.639 6.516-2.386zM10.64 8.068c-.152.053-.431.312-1.811 1.687-1.145 1.14-1.657 1.677-1.717 1.801-.118.245-.136.51-.052.77.065.199.181.326 1.695 1.845 1.704 1.711 1.791 1.784 2.167 1.82.555.053 1.078-.428 1.078-.991 0-.364-.088-.496-.854-1.27l-.721-.73h2.86c3.226 0 3.107.01 3.416-.299a.984.984 0 000-1.402c-.309-.309-.19-.299-3.416-.299h-2.86l.721-.73C11.912 9.496 12 9.364 12 9c0-.686-.694-1.161-1.36-.932z"
      fill="currentColor"
    />
  </Icon>
);

ArrowLeftCircle.displayName = 'ArrowLeftCircleIcon';
