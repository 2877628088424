import { Box, Tag } from '@chakra-ui/react';
import { selectColors } from '../select.config';

export const OptionTag = ({
  data,
  children,
  isSelected,
  isFocused,
  innerProps,
  ...props
}) => (
  <Box
    py={1.5}
    px={2}
    cursor="pointer"
    backgroundColor={
      isSelected
        ? selectColors.backgroundSelected
        : isFocused
          ? selectColors.backgroundHover
          : null
    }
    _hover={{ backgroundColor: !isSelected && selectColors.backgroundHover }}
    _focus={{ backgroundColor: !isSelected && selectColors.backgroundHover }}
    _active={{
      backgroundColor: !isSelected && selectColors.backgroundActive,
      color: !isSelected && selectColors.textActive,
    }}
    {...innerProps}
  >
    <Tag
      {...props.sx}
      backgroundColor={!data?.colorScheme && selectColors.tagDefault}
      colorScheme={data?.colorScheme}
      borderRadius="md"
      px={3}
    >
      {children}
    </Tag>
  </Box>
);
