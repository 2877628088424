import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Tablet: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M5.503 1.042 C 4.487 1.214,3.556 1.976,3.202 2.925 C 2.992 3.487,3.001 3.068,3.001 11.992 C 3.000 21.076,2.986 20.543,3.234 21.151 C 3.521 21.857,4.143 22.479,4.849 22.766 C 5.449 23.011,5.123 23.000,12.000 23.000 C 18.876 23.000,18.551 23.011,19.150 22.767 C 19.850 22.482,20.482 21.850,20.767 21.150 C 21.013 20.544,21.000 21.073,21.000 12.000 C 21.000 2.925,21.014 3.457,20.766 2.849 C 20.499 2.193,19.964 1.633,19.296 1.312 C 18.639 0.996,19.291 1.023,12.120 1.015 C 8.589 1.012,5.611 1.024,5.503 1.042 M18.340 3.066 C 18.593 3.153,18.847 3.407,18.934 3.660 C 18.995 3.838,19.000 4.523,19.000 12.000 C 19.000 21.144,19.026 20.376,18.701 20.701 C 18.379 21.023,18.903 21.000,12.000 21.000 C 5.097 21.000,5.621 21.023,5.299 20.701 C 4.974 20.376,5.000 21.144,5.000 12.000 C 5.000 2.855,4.973 3.624,5.300 3.298 C 5.614 2.984,5.091 3.007,11.983 3.003 C 17.620 3.000,18.162 3.006,18.340 3.066 M11.664 17.060 C 11.433 17.148,11.256 17.299,11.131 17.511 C 11.036 17.672,11.020 17.744,11.020 18.000 C 11.020 18.256,11.036 18.328,11.131 18.489 C 11.335 18.836,11.709 19.029,12.099 18.989 C 13.109 18.884,13.337 17.564,12.427 17.093 C 12.254 17.004,11.856 16.987,11.664 17.060 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Tablet.displayName = 'TabletIcon';
