import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillHustler: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M21.886 12.72L18.494 9.33a2.735 2.735 0 00-.256-3.566 2.713 2.713 0 00-1.931-.8 2.71 2.71 0 00-1.636.543L11.28 2.114a.391.391 0 00-.63.113l-5.527 12.01-2.16 2.162A3.26 3.26 0 002 18.719c0 .876.341 1.7.961 2.32a3.26 3.26 0 002.32.961c.877 0 1.7-.341 2.32-.961l1.443-1.443 2.29 2.29a.39.39 0 00.552 0l1.426-1.427a.39.39 0 000-.553l-1.823-1.823 10.284-4.731a.39.39 0 00.113-.631zm-5.58-6.976c.521 0 1.01.203 1.38.571.665.666.747 1.697.247 2.453l-2.701-2.702c.316-.21.687-.322 1.075-.322zM8.114 19.421L6.67 17.977a.39.39 0 00-.552.553l1.444 1.444-.513.513c-.472.472-1.1.732-1.768.732-.668 0-1.295-.26-1.768-.732a2.484 2.484 0 01-.732-1.768c0-.668.26-1.296.732-1.768l1.935-1.934 3.535 3.535-.869.87zm4.37.762l-.874.874-2.014-2.013.167-.166.97-.447 1.75 1.752zm-2.864-2.1L5.917 14.38l4.342-9.437 1.785 1.785a.39.39 0 00.552 0 .39.39 0 000-.552l-1.99-1.99.518-1.123 9.813 9.813L9.62 18.083z"
      fill="currentColor"
    />
    <path
      d="M13.457 14.572l-1.475-5.299a.39.39 0 10-.753.21l1.475 5.298a.39.39 0 00.753-.21zM15.963 10.778a1.387 1.387 0 00-.987-.409c-.374 0-.724.146-.988.41a1.387 1.387 0 00-.409.987c0 .373.145.723.41.987.263.264.613.409.986.409s.724-.145.988-.41c.264-.263.409-.614.409-.986 0-.373-.145-.724-.41-.988zM15.41 12.2a.61.61 0 01-.87 0 .616.616 0 11.87 0zM10.738 10.522a1.387 1.387 0 00-.988-.409c-.373 0-.723.146-.987.41a1.387 1.387 0 00-.41.987c0 .373.146.723.41.987s.614.409.987.409.724-.145.988-.409c.263-.264.409-.614.409-.987s-.146-.724-.41-.988zm-.553 1.423a.611.611 0 01-.87 0 .61.61 0 010-.87.611.611 0 01.87 0 .61.61 0 01.18.435.61.61 0 01-.18.435zM21.885 2.115a.39.39 0 00-.552 0l-2.11 2.11a.39.39 0 10.553.552l2.11-2.11a.39.39 0 000-.552zM21.609 7.303h-1.68a.39.39 0 000 .782h1.68a.39.39 0 000-.782zM16.31 2a.39.39 0 00-.39.391v1.68a.39.39 0 00.78 0v-1.68a.39.39 0 00-.39-.39zM5.636 16.944a.391.391 0 10-.553.554.391.391 0 00.553-.554zM13.63 7.21a.394.394 0 00-.553 0 .393.393 0 00.276.667.393.393 0 00.391-.39.393.393 0 00-.114-.277z"
      fill="currentColor"
    />
  </Icon>
);

SkillHustler.displayName = 'SkillHustlerIcon';
