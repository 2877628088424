import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SaveAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M4.503 2.042 C 3.852 2.151,3.167 2.541,2.722 3.057 C 2.495 3.321,2.371 3.522,2.235 3.851 C 1.988 4.452,2.000 4.020,2.000 12.000 C 2.000 19.991,1.987 19.546,2.238 20.156 C 2.392 20.528,2.582 20.809,2.886 21.114 C 3.321 21.548,3.815 21.814,4.421 21.939 C 4.860 22.030,19.151 22.030,19.580 21.940 C 20.607 21.723,21.408 21.050,21.793 20.080 C 22.005 19.547,21.999 19.741,21.999 13.577 C 22.000 8.407,21.994 7.835,21.935 7.657 C 21.876 7.478,21.632 7.221,19.205 4.795 C 16.779 2.368,16.522 2.124,16.343 2.065 C 16.165 2.006,15.600 2.000,10.423 2.005 C 7.275 2.007,4.611 2.024,4.503 2.042 M6.009 6.150 L 6.020 8.300 6.131 8.489 C 6.192 8.592,6.301 8.723,6.374 8.778 C 6.677 9.009,6.484 9.000,10.998 9.000 C 15.663 9.000,15.384 9.018,15.701 8.701 C 16.092 8.310,16.092 7.690,15.701 7.299 C 15.387 6.985,15.595 7.000,11.573 7.000 L 8.000 7.000 8.000 5.500 L 8.000 4.000 11.790 4.000 L 15.580 4.000 17.790 6.210 L 20.000 8.420 20.000 13.783 C 20.000 19.809,20.022 19.380,19.701 19.701 C 19.454 19.948,19.259 20.000,18.573 20.000 L 18.000 20.000 18.000 16.427 C 18.000 12.405,18.015 12.613,17.701 12.299 C 17.381 11.979,17.783 12.000,11.998 12.000 C 6.390 12.000,6.680 11.989,6.374 12.222 C 6.301 12.277,6.192 12.408,6.131 12.511 L 6.020 12.700 6.009 16.350 L 5.998 20.000 5.426 20.000 C 4.741 20.000,4.546 19.948,4.299 19.701 C 3.975 19.377,4.000 20.023,4.000 12.000 C 4.000 3.975,3.975 4.623,4.300 4.298 C 4.546 4.052,4.715 4.007,5.409 4.003 L 5.997 4.000 6.009 6.150 M16.000 17.000 L 16.000 20.000 12.000 20.000 L 8.000 20.000 8.000 17.000 L 8.000 14.000 12.000 14.000 L 16.000 14.000 16.000 17.000 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

SaveAlt.displayName = 'SaveAltIcon';
