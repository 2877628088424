import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CircleDonut: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.44.34-1.308 1.21-1.65 1.651-1.145 1.479-1.813 3.078-2.078 4.975-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.3.869 2.577 1.358 4.2 1.607.562.087 2.033.099 2.58.022 2.016-.283 3.753-1.046 5.24-2.3 4.113-3.47 4.719-9.628 1.362-13.848-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
      fill="currentColor"
    />
  </Icon>
);

CircleDonut.displayName = 'CircleDonutIcon';
