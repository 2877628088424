import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const BarChartAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M17.664 3.063 C 17.436 3.146,17.257 3.297,17.131 3.511 L 17.020 3.700 17.010 11.914 C 17.003 17.490,17.013 20.178,17.041 20.283 C 17.101 20.506,17.312 20.761,17.531 20.874 C 17.932 21.080,18.390 21.012,18.700 20.702 C 19.027 20.376,19.000 21.146,19.000 12.000 C 19.000 2.856,19.026 3.624,18.701 3.299 C 18.429 3.027,18.018 2.933,17.664 3.063 M11.664 9.063 C 11.436 9.146,11.257 9.297,11.131 9.511 L 11.020 9.700 11.009 14.914 C 11.002 18.436,11.012 20.177,11.041 20.282 C 11.101 20.506,11.312 20.761,11.531 20.874 C 11.932 21.080,12.390 21.012,12.700 20.702 C 13.021 20.381,13.000 20.784,13.000 15.000 C 13.000 9.217,13.021 9.619,12.701 9.299 C 12.429 9.027,12.018 8.933,11.664 9.063 M5.664 15.063 C 5.436 15.146,5.257 15.297,5.131 15.511 L 5.020 15.700 5.009 17.911 C 5.001 19.377,5.012 20.175,5.040 20.279 C 5.101 20.506,5.311 20.760,5.531 20.874 C 5.932 21.080,6.390 21.012,6.700 20.702 C 7.003 20.399,7.000 20.429,7.000 18.000 C 7.000 15.573,7.003 15.601,6.701 15.299 C 6.429 15.027,6.018 14.933,5.664 15.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

BarChartAlt.displayName = 'BarChartAltIcon';
