import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DeviceMobile: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.62 2.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.327-.084 15.031 0 15.357.281 1.096 1.047 1.881 2.184 2.237.175.055.735.065 4.56.076 2.923.009 4.45-.001 4.633-.03 1.191-.184 2.167-1.084 2.471-2.28.083-.328.084-15.032 0-15.357-.307-1.197-1.264-2.09-2.444-2.28-.245-.04-8.663-.057-8.86-.018zm8.72 2.041c.115.039.263.135.361.233.324.324.299-.322.299 7.701s.025 7.377-.299 7.701c-.317.317-.038.299-4.701.299-4.665 0-4.384.018-4.702-.3-.324-.324-.3.328-.288-7.782L7.02 4.7l.111-.189c.125-.213.318-.375.539-.454.122-.043.94-.054 4.313-.055 3.773-.002 4.181.004 4.357.064zm-4.697 13c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

DeviceMobile.displayName = 'DeviceMobileIcon';
