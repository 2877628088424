import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ClipboardCopy: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.62 2.025c-.408.08-.533.114-.75.202a3.24 3.24 0 00-.986.657c-.254.254-.562.708-.639.942l-.055.165-.865.02c-.601.014-.926.038-1.065.079-1.127.337-1.901 1.128-2.184 2.233-.083.323-.083 13.031 0 13.354.281 1.096 1.045 1.879 2.184 2.238.177.055.818.064 5.56.075 3.614.008 5.449-.001 5.633-.03 1.171-.18 2.108-1.026 2.457-2.22.04-.134.066-.473.079-1.026.024-.975-.009-1.136-.287-1.414a.984.984 0 00-1.403-.001c-.254.254-.299.435-.299 1.201s-.045.947-.299 1.201c-.32.32.082.299-5.701.299-5.783 0-5.381.021-5.701-.299-.323-.322-.299.204-.297-6.718.001-5.044.011-6.19.055-6.313.079-.221.241-.414.454-.539.185-.109.203-.111.93-.124l.741-.013.053.153c.089.255.385.699.632.948.368.371.847.652 1.393.817.26.079 2.747.118 3.182.05 1.013-.157 1.938-.869 2.302-1.772L14.82 6h.663c.784 0 .963.044 1.218.299.294.294.299.33.299 2.201s.005 1.907.299 2.201a.984.984 0 001.403-.001c.304-.303.305-.318.288-2.408-.017-1.949-.014-1.917-.227-2.447-.292-.728-1-1.401-1.751-1.663-.413-.145-.714-.181-1.494-.182h-.702l-.052-.15c-.092-.261-.389-.702-.65-.964a3.013 3.013 0 00-1.634-.843c-.241-.039-2.676-.054-2.86-.018zm2.72 2.041c.369.126.66.538.66.934s-.291.808-.66.934c-.288.098-2.392.098-2.68 0-.716-.244-.881-1.258-.279-1.717.264-.202.352-.213 1.602-.215.981-.002 1.193.008 1.357.064zm.3 6.002c-.152.053-.431.312-1.811 1.687-1.145 1.14-1.657 1.677-1.717 1.801-.118.245-.136.51-.052.77.065.199.181.326 1.695 1.845 1.704 1.711 1.791 1.784 2.167 1.82.555.053 1.078-.428 1.078-.991 0-.364-.088-.496-.854-1.27l-.721-.73h3.86c4.344 0 4.101.016 4.416-.299a.984.984 0 000-1.402c-.315-.315-.072-.299-4.416-.299h-3.86l.721-.73c.766-.774.854-.906.854-1.27 0-.686-.694-1.161-1.36-.932z"
      fill="currentColor"
    />
  </Icon>
);

ClipboardCopy.displayName = 'ClipboardCopyIcon';
