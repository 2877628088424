import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ZoomInAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M10.340 2.024 C 9.026 2.160,8.091 2.415,7.060 2.918 C 4.287 4.272,2.420 6.913,2.059 9.992 C 1.620 13.745,3.632 17.403,7.060 19.083 C 9.547 20.302,12.453 20.302,14.940 19.083 C 15.468 18.825,16.037 18.479,16.438 18.173 L 16.615 18.037 18.538 19.953 C 20.268 21.678,20.480 21.876,20.657 21.935 C 21.046 22.064,21.423 21.979,21.701 21.701 C 21.979 21.423,22.064 21.046,21.935 20.657 C 21.876 20.480,21.678 20.268,19.953 18.538 L 18.037 16.615 18.173 16.438 C 18.479 16.037,18.825 15.468,19.083 14.940 C 20.302 12.453,20.302 9.547,19.083 7.060 C 18.190 5.237,16.762 3.810,14.944 2.923 C 14.066 2.494,13.269 2.244,12.320 2.101 C 11.911 2.039,10.662 1.991,10.340 2.024 M11.686 4.042 C 13.504 4.226,15.200 5.113,16.334 6.472 C 17.537 7.916,18.125 9.741,17.967 11.550 C 17.882 12.525,17.691 13.226,17.282 14.060 C 16.234 16.199,14.244 17.632,11.894 17.940 C 9.177 18.296,6.480 17.006,5.039 14.660 C 3.538 12.217,3.688 9.083,5.416 6.787 C 6.877 4.846,9.272 3.797,11.686 4.042 M10.664 7.063 C 10.436 7.146,10.257 7.297,10.131 7.511 L 10.020 7.700 10.008 8.850 L 9.995 10.000 8.923 10.000 C 7.764 10.000,7.642 10.018,7.374 10.222 C 7.301 10.277,7.192 10.408,7.131 10.511 C 7.036 10.672,7.020 10.744,7.020 11.000 C 7.020 11.256,7.036 11.328,7.131 11.489 C 7.192 11.592,7.301 11.723,7.374 11.778 C 7.642 11.983,7.764 12.000,8.925 12.000 L 10.000 12.000 10.000 13.070 C 10.000 13.684,10.018 14.204,10.042 14.289 C 10.103 14.509,10.315 14.762,10.531 14.874 C 10.932 15.080,11.390 15.012,11.700 14.702 C 11.979 14.423,12.000 14.309,12.000 13.073 L 12.000 12.000 13.073 12.000 C 14.308 12.000,14.423 11.979,14.701 11.701 C 15.092 11.310,15.092 10.690,14.701 10.299 C 14.423 10.021,14.308 10.000,13.073 10.000 L 12.000 10.000 12.000 8.927 C 12.000 7.692,11.979 7.577,11.701 7.299 C 11.429 7.027,11.018 6.933,10.664 7.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

ZoomInAlt.displayName = 'ZoomInAltIcon';
