import { FC } from 'react';
import { IconProps } from '@chakra-ui/icon';
import { Blerb } from './shapes/Blerb';
import { Blob } from './shapes/Blob';
import { Circle } from './shapes/Circle';
import { Heptagon } from './shapes/Heptagon';
import { Hexagon } from './shapes/Hexagon';
import { Lake } from './shapes/Lake';
import { Meh } from './shapes/Meh';
import { Octagon } from './shapes/Octagon';
import { Okay } from './shapes/Okay';
import { Oval } from './shapes/Oval';
import { Parallelogram } from './shapes/Parallelogram';
import { Pentagon } from './shapes/Pentagon';
import { Rectangle } from './shapes/Rectangle';
import { Scramble } from './shapes/Scramble';
import { Star } from './shapes/Star';
import { Thingy } from './shapes/Thingy';
import { Weird } from './shapes/Weird';

export {
  Blerb,
  Blob,
  Circle,
  Heptagon,
  Hexagon,
  Lake,
  Meh,
  Octagon,
  Okay,
  Oval,
  Parallelogram,
  Pentagon,
  Rectangle,
  Scramble,
  Star,
  Thingy,
  Weird,
};

export const SHAPE_BY_NAME = {
  blerb: Blerb,
  blob: Blob,
  circle: Circle,
  heptagon: Heptagon,
  hexagon: Hexagon,
  lake: Lake,
  meh: Meh,
  octagon: Octagon,
  okay: Okay,
  oval: Oval,
  Parallelogram: Parallelogram,
  pentagon: Pentagon,
  rectangle: Rectangle,
  scramble: Scramble,
  star: Star,
  thingy: Thingy,
  weird: Weird,
};

export interface Props extends IconProps {
  name?: string;
}

export const Shape: FC<Props> = ({ name = 'blerb', ...rest }) => {
  const ShapeComponent =
    SHAPE_BY_NAME[name.toLowerCase()] || SHAPE_BY_NAME['blerb'];
  return <ShapeComponent {...rest} />;
};

Shape.displayName = 'ReflexShapeIllustration';
