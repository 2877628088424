import { FC } from 'react';
import { IconProps } from '@chakra-ui/icon';
import AcademicCap from './src/AcademicCap';
import Activity from './src/Activity';
import Adjustments from './src/Adjustments';
import Airplane from './src/Airplane';
import AlertCircle from './src/AlertCircle';
import Annotation from './src/Annotation';
import Apple from './src/Apple';
import Archive from './src/Archive';
import ArchiveaAlt from './src/ArchiveaAlt';
import ArrowDown from './src/ArrowDown';
import ArrowDownCircle from './src/ArrowDownCircle';
import ArrowDownNarrow from './src/ArrowDownNarrow';
import ArrowLeft from './src/ArrowLeft';
import ArrowLeftCircle from './src/ArrowLeftCircle';
import ArrowLeftNarrow from './src/ArrowLeftNarrow';
import ArrowRight from './src/ArrowRight';
import ArrowRightCircle from './src/ArrowRightCircle';
import ArrowRightNarrow from './src/ArrowRightNarrow';
import ArrowsExpand from './src/ArrowsExpand';
import ArrowsUpDown from './src/ArrowsUpDown';
import ArrowUp from './src/ArrowUp';
import ArrowUpCircle from './src/ArrowUpCircle';
import ArrowUpNarrow from './src/ArrowUpNarrow';
import AtSign from './src/AtSign';
import AtSymbol from './src/AtSymbol';
import Award from './src/Award';
import Backspace from './src/Backspace';
import BadgeCheck from './src/BadgeCheck';
import Bag from './src/Bag';
import BagHandle from './src/BagHandle';
import Ban from './src/Ban';
import BarChart from './src/BarChart';
import BarChartAlt from './src/BarChartAlt';
import Baseball from './src/Baseball';
import Basketball from './src/Basketball';
import Beaker from './src/Beaker';
import Bell from './src/Bell';
import Bicycle from './src/Bicycle';
import BookDollar from './src/BookDollar';
import Bookmark from './src/Bookmark';
import BookmarkAlt from './src/BookmarkAlt';
import BookOpen from './src/BookOpen';
import Briefcase from './src/Briefcase';
import Brush from './src/Brush';
import Building from './src/Building';
import Cake from './src/Cake';
import Calculator from './src/Calculator';
import Calendar from './src/Calendar';
import CalendarAlt from './src/CalendarAlt';
import CalendarCheck from './src/CalendarCheck';
import CalendarCheckAlt from './src/CalendarCheckAlt';
import CalendarClock from './src/CalendarClock';
import CalendarDollar from './src/CalendarDollar';
import CalendarEdit from './src/CalendarEdit';
import CalendarPlus from './src/CalendarPlus';
import Camera from './src/Camera';
import Cash from './src/Cash';
import ChartBar from './src/ChartBar';
import ChartBarSquare from './src/ChartBarSquare';
import ChartPie from './src/ChartPie';
import Chat from './src/Chat';
import ChatAlt from './src/ChatAlt';
import ChatAlt2 from './src/ChatAlt2';
import ChatGroup from './src/ChatGroup';
import Check from './src/Check';
import CheckAlt from './src/CheckAlt';
import CheckCircle from './src/CheckCircle';
import ChevronDoubleDown from './src/ChevronDoubleDown';
import ChevronDoubleLeft from './src/ChevronDoubleLeft';
import ChevronDoubleRight from './src/ChevronDoubleRight';
import ChevronDoubleUp from './src/ChevronDoubleUp';
import ChevronDown from './src/ChevronDown';
import ChevronLeft from './src/ChevronLeft';
import ChevronRight from './src/ChevronRight';
import ChevronUp from './src/ChevronUp';
import Chip from './src/Chip';
import CircleDonut from './src/CircleDonut';
import CircleFilledWithOffset from './src/CircleFilledWithOffset';
import CircleOutline from './src/CircleOutline';
import Clipboard from './src/Clipboard';
import ClipboardCheck from './src/ClipboardCheck';
import ClipboardCopy from './src/ClipboardCopy';
import ClipboardList from './src/ClipboardList';
import Clock from './src/Clock';
import ClockAlt from './src/ClockAlt';
import Cloud from './src/Cloud';
import CloudDownload from './src/CloudDownload';
import CloudDrizzle from './src/CloudDrizzle';
import CloudLightning from './src/CloudLightning';
import CloudOff from './src/CloudOff';
import CloudRain from './src/CloudRain';
import CloudSnow from './src/CloudSnow';
import CloudUpload from './src/CloudUpload';
import Code from './src/Code';
import Coffee from './src/Coffee';
import Cog from './src/Cog';
import Collection from './src/Collection';
import ColorPalette from './src/ColorPalette';
import ColorSwatch from './src/ColorSwatch';
import Command from './src/Command';
import CreditCard from './src/CreditCard';
import CreditCardAlt from './src/CreditCardAlt';
import Crop from './src/Crop';
import Crosshair from './src/Crosshair';
import Cube from './src/Cube';
import CubeTransparent from './src/CubeTransparent';
import CurencyYen from './src/CurencyYen';
import CurrencyBangladeshi from './src/CurrencyBangladeshi';
import CurrencyDollar from './src/CurrencyDollar';
import CurrencyEuro from './src/CurrencyEuro';
import CurrencyPound from './src/CurrencyPound';
import CurrencyRupee from './src/CurrencyRupee';
import CursorClick from './src/CursorClick';
import Database from './src/Database';
import DatabaseAlt from './src/DatabaseAlt';
import DeviceDesktop from './src/DeviceDesktop';
import DeviceMobile from './src/DeviceMobile';
import DeviceTablet from './src/DeviceTablet';
import Diamond from './src/Diamond';
import DirectionsDiamond from './src/DirectionsDiamond';
import Divide from './src/Divide';
import Document from './src/Document';
import DocumentAdd from './src/DocumentAdd';
import DocumentDownload from './src/DocumentDownload';
import DocumentDuplicate from './src/DocumentDuplicate';
import DocumentRemove from './src/DocumentRemove';
import DocumentReport from './src/DocumentReport';
import DocumentSearch from './src/DocumentSearch';
import DocumentText from './src/DocumentText';
import DollarSign from './src/DollarSign';
import DotsHorizontal from './src/DotsHorizontal';
import DotsHorizontalCircle from './src/DotsHorizontalCircle';
import DotsVertical from './src/DotsVertical';
import Download from './src/Download';
import DownloadAlt from './src/DownloadAlt';
import DownloadCloud from './src/DownloadCloud';
import Droplet from './src/Droplet';
import Duplicate from './src/Duplicate';
import Edit from './src/Edit';
import Edit2 from './src/Edit2';
import Edit3 from './src/Edit3';
import EmojiHappy from './src/EmojiHappy';
import EmojiMeh from './src/EmojiMeh';
import EmojiSad from './src/EmojiSad';
import Exclamation from './src/Exclamation';
import ExclamationCircle from './src/ExclamationCircle';
import ExclamationOctagon from './src/ExclamationOctagon';
import ExternalLink from './src/ExternalLink';
import ExternalLinkAlt from './src/ExternalLinkAlt';
import Eye from './src/Eye';
import EyeOff from './src/EyeOff';
import FastFood from './src/FastFood';
import FastForward from './src/FastForward';
import File from './src/File';
import FileMinus from './src/FileMinus';
import FilePlus from './src/FilePlus';
import FileText from './src/FileText';
import Film from './src/Film';
import Filter from './src/Filter';
import FilterX from './src/FilterX';
import FingerPrint from './src/FingerPrint';
import FingerPrintAlt from './src/FingerPrintAlt';
import Fire from './src/Fire';
import Fish from './src/Fish';
import Flag from './src/Flag';
import Flame from './src/Flame';
import Flower from './src/Flower';
import Folder from './src/Folder';
import FolderAdd from './src/FolderAdd';
import FolderAlt from './src/FolderAlt';
import FolderDownload from './src/FolderDownload';
import FolderMinus from './src/FolderMinus';
import FolderOpen from './src/FolderOpen';
import FolderOpenAlt from './src/FolderOpenAlt';
import FolderPlus from './src/FolderPlus';
import FolderRemove from './src/FolderRemove';
import Football from './src/Football';
import Gcal from './src/Gcal';
import Gift from './src/Gift';
import Globe from './src/Globe';
import GlobeAlt from './src/GlobeAlt';
import Hand from './src/Hand';
import Hash from './src/Hash';
import Hashtag from './src/Hashtag';
import Heart from './src/Heart';
import HeartFill from './src/HeartFill';
import Help from './src/Help';
import HelpCircle from './src/HelpCircle';
import Home from './src/Home';
import HomeAlt from './src/HomeAlt';
import HourGlass from './src/HourGlass';
import Identification from './src/Identification';
import Image from './src/Image';
import Images from './src/Images';
import Inbox from './src/Inbox';
import InboxIn from './src/InboxIn';
import InformationCircle from './src/InformationCircle';
import Key from './src/Key';
import KeyAlt from './src/KeyAlt';
import Laptop from './src/Laptop';
import Layers from './src/Layers';
import Leaf from './src/Leaf';
import LetterG from './src/LetterG';
import LetterN from './src/LetterN';
import LetterR from './src/LetterR';
import Library from './src/Library';
import LifeBuoy from './src/LifeBuoy';
import LightBulb from './src/LightBulb';
import LightningBolt from './src/LightningBolt';
import Link from './src/Link';
import Link2 from './src/Link2';
import Link3 from './src/Link3';
import Loader from './src/Loader';
import LocationMarker from './src/LocationMarker';
import LockClosed from './src/LockClosed';
import LockOpen from './src/LockOpen';
import Login from './src/Login';
import Logout from './src/Logout';
import Mail from './src/Mail';
import MailAlt from './src/MailAlt';
import MailOpen from './src/MailOpen';
import Man from './src/Man';
import Map from './src/Map';
import MapAlt from './src/MapAlt';
import MapPin from './src/MapPin';
import Megaphone from './src/Megaphone';
import Menu from './src/Menu';
import Menu2 from './src/Menu2';
import Menu3 from './src/Menu3';
import Menu4 from './src/Menu4';
import Menu5 from './src/Menu5';
import Menu6 from './src/Menu6';
import MenuAlt1 from './src/MenuAlt1';
import MenuAlt2 from './src/MenuAlt2';
import MenuAlt3 from './src/MenuAlt3';
import MenuAlt4 from './src/MenuAlt4';
import MessageCircle from './src/MessageCircle';
import Microphone from './src/Microphone';
import Minus from './src/Minus';
import MinusCircle from './src/MinusCircle';
import MinusSmall from './src/MinusSmall';
import Monitor from './src/Monitor';
import Moon from './src/Moon';
import Moon2 from './src/Moon2';
import Moon3 from './src/Moon3';
import MousePointer from './src/MousePointer';
import Music from './src/Music';
import MusicNote from './src/MusicNote';
import Navigation from './src/Navigation';
import NavigationAlt from './src/NavigationAlt';
import Newspaper from './src/Newspaper';
import OfficeBuilding from './src/OfficeBuilding';
import Package from './src/Package';
import PaperAirplane from './src/PaperAirplane';
import PaperClip from './src/PaperClip';
import PaperClipAlt from './src/PaperClipAlt';
import Pause from './src/Pause';
import Pencil from './src/Pencil';
import PencilAlt from './src/PencilAlt';
import PenTool from './src/PenTool';
import Person from './src/Person';
import Phone from './src/Phone';
import PhoneIncoming from './src/PhoneIncoming';
import PhoneMissedCall from './src/PhoneMissedCall';
import PhoneOutgoing from './src/PhoneOutgoing';
import Photograph from './src/Photograph';
import PieChart2 from './src/PieChart2';
import PieChart3 from './src/PieChart3';
import Pizza from './src/Pizza';
import Planet from './src/Planet';
import Play from './src/Play';
import Plus from './src/Plus';
import PlusCircle from './src/PlusCircle';
import PlusSmall from './src/PlusSmall';
import PresentationChartBar from './src/PresentationChartBar';
import PresentationChartLine from './src/PresentationChartLine';
import Print from './src/Print';
import Printer from './src/Printer';
import Prism from './src/Prism';
import Puzzle from './src/Puzzle';
import Qrcode from './src/Qrcode';
import QuestionMarkCircle from './src/QuestionMarkCircle';
import Quote from './src/Quote';
import Rainey from './src/Rainey';
import Reader from './src/Reader';
import ReceiptRefund from './src/ReceiptRefund';
import ReceiptTax from './src/ReceiptTax';
import Reciept from './src/Reciept';
import Refresh from './src/Refresh';
import RefreshCcw from './src/RefreshCcw';
import RefreshCw from './src/RefreshCw';
import ReorderFour from './src/ReorderFour';
import Repeat from './src/Repeat';
import Reply from './src/Reply';
import Rewind from './src/Rewind';
import Ribbon from './src/Ribbon';
import RibbonAlt from './src/RibbonAlt';
import RLogo from './src/RLogo';
import Rocket from './src/Rocket';
import RotateCcw from './src/RotateCcw';
import RotateCW from './src/RotateCW';
import Rss from './src/Rss';
import Save from './src/Save';
import SaveAlt from './src/SaveAlt';
import SaveAs from './src/SaveAs';
import Scale from './src/Scale';
import School from './src/School';
import Scissors from './src/Scissors';
import Search from './src/Search';
import Search2 from './src/Search2';
import SearchCircle from './src/SearchCircle';
import Selector from './src/Selector';
import Send from './src/Send';
import Server from './src/Server';
import Shapes from './src/Shapes';
import Share from './src/Share';
import ShieldCheck from './src/ShieldCheck';
import ShieldExclamation from './src/ShieldExclamation';
import Shirt from './src/Shirt';
import ShoppingBag from './src/ShoppingBag';
import ShoppingCart from './src/ShoppingCart';
import SkillApproachable from './src/SkillApproachable';
import SkillCommunication from './src/SkillCommunication';
import SkillCustomerFacing from './src/SkillCustomerFacing';
import SkillDependable from './src/SkillDependable';
import SkillFolder from './src/SkillFolder';
import SkillGiftWrapping from './src/SkillGiftWrapping';
import SkillHeavyLifter from './src/SkillHeavyLifter';
import SkillHustler from './src/SkillHustler';
import SkillInteriorDesign from './src/SkillInteriorDesign';
import SkillPatient from './src/SkillPatient';
import SkillPointOfSale from './src/SkillPointOfSale';
import SkillPunctual from './src/SkillPunctual';
import SkillSales from './src/SkillSales';
import SkillStylist from './src/SkillStylist';
import SkillTeamPlayer from './src/SkillTeamPlayer';
import SkillTrustworthy from './src/SkillTrustworthy';
import Skull from './src/Skull';
import Snow from './src/Snow';
import SocialFacebook from './src/SocialFacebook';
import SocialInstagram from './src/SocialInstagram';
import SocialLinkedin from './src/SocialLinkedin';
import SocialTikTok from './src/SocialTikTok';
import SocialTwitter from './src/SocialTwitter';
import SortAscending from './src/SortAscending';
import SortDescending from './src/SortDescending';
import Sparkle from './src/Sparkle';
import SparklePlus from './src/SparklePlus';
import Sparkles from './src/Sparkles';
import Speakerphone from './src/Speakerphone';
import Speedometer from './src/Speedometer';
import Star from './src/Star';
import StarFill from './src/StarFill';
import StarHalf from './src/StarHalf';
import StarHalfStroke from './src/StarHalfStroke';
import StatsChart from './src/StatsChart';
import StatusOffline from './src/StatusOffline';
import StatusOnline from './src/StatusOnline';
import Stop from './src/Stop';
import Storefront from './src/Storefront';
import StoreFrontAlt from './src/StoreFrontAlt';
import StoreFrontAltEdit from './src/StoreFrontAltEdit';
import Subway from './src/Subway';
import Sun from './src/Sun';
import SunAlt from './src/SunAlt';
import Support from './src/Support';
import SwitchHorizontal from './src/SwitchHorizontal';
import SwitchVertical from './src/SwitchVertical';
import Table from './src/Table';
import Tablet from './src/Tablet';
import Tag from './src/Tag';
import TagAlt from './src/TagAlt';
import Template from './src/Template';
import Tennisball from './src/Tennisball';
import Terminal from './src/Terminal';
import Terminal2 from './src/Terminal2';
import Terminal3 from './src/Terminal3';
import Text from './src/Text';
import ThumbDown from './src/ThumbDown';
import ThumbUp from './src/ThumbUp';
import Ticket from './src/Ticket';
import Tool from './src/Tool';
import Train from './src/Train';
import Translate from './src/Translate';
import Trash from './src/Trash';
import Trash2 from './src/Trash2';
import Trash3 from './src/Trash3';
import Trash4 from './src/Trash4';
import TrendingDown from './src/TrendingDown';
import TrendingUp from './src/TrendingUp';
import Truck from './src/Truck';
import TruckAlt from './src/TruckAlt';
import Tv from './src/Tv';
import Umbrella from './src/Umbrella';
import Unlink from './src/Unlink';
import Upload from './src/Upload';
import User from './src/User';
import UserAccess from './src/UserAccess';
import UserAdd from './src/UserAdd';
import UserAdd2 from './src/UserAdd2';
import UserAlt from './src/UserAlt';
import UserCheck from './src/UserCheck';
import UserCircle from './src/UserCircle';
import UserEdit from './src/UserEdit';
import UserGroup from './src/UserGroup';
import UserMinus from './src/UserMinus';
import UserMove from './src/UserMove';
import UserMoveAlt from './src/UserMoveAlt';
import UserMoveAlt2 from './src/UserMoveAlt2';
import UserOverbook from './src/UserOverbook';
import UserRemove from './src/UserRemove';
import UserRemove2 from './src/UserRemove2';
import Users from './src/Users';
import UsersAlt from './src/UsersAlt';
import Variable from './src/Variable';
import Video from './src/Video';
import VideoCamera from './src/VideoCamera';
import VideoFill from './src/VideoFill';
import ViewBoards from './src/ViewBoards';
import ViewGrid from './src/ViewGrid';
import ViewGridAdd from './src/ViewGridAdd';
import ViewList from './src/ViewList';
import VolumeOff from './src/VolumeOff';
import VolumeUp from './src/VolumeUp';
import Walk from './src/Walk';
import WalkAlt from './src/WalkAlt';
import Wallet from './src/Wallet';
import Watch from './src/Watch';
import Water from './src/Water';
import Wifi from './src/Wifi';
import Woman from './src/Woman';
import X from './src/X';
import XCircle from './src/XCircle';
import Zap from './src/Zap';
import ZapOff from './src/ZapOff';
import ZoomIn from './src/ZoomIn';
import ZoomInAlt from './src/ZoomInAlt';
import ZoomOut from './src/ZoomOut';
import ZoomOutAlt from './src/ZoomOutAlt';

export {
  AcademicCap,
  Activity,
  Adjustments,
  Airplane,
  AlertCircle,
  Annotation,
  Apple,
  Archive,
  ArchiveaAlt,
  ArrowDown,
  ArrowDownCircle,
  ArrowDownNarrow,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowLeftNarrow,
  ArrowRight,
  ArrowRightCircle,
  ArrowRightNarrow,
  ArrowsExpand,
  ArrowsUpDown,
  ArrowUp,
  ArrowUpCircle,
  ArrowUpNarrow,
  AtSign,
  AtSymbol,
  Award,
  Backspace,
  BadgeCheck,
  Bag,
  BagHandle,
  Ban,
  BarChart,
  BarChartAlt,
  Baseball,
  Basketball,
  Beaker,
  Bell,
  Bicycle,
  Bookmark,
  BookmarkAlt,
  BookDollar,
  BookOpen,
  Briefcase,
  Brush,
  Building,
  Cake,
  Calculator,
  Calendar,
  CalendarAlt,
  CalendarCheck,
  CalendarCheckAlt,
  CalendarClock,
  CalendarEdit,
  CalendarDollar,
  CalendarPlus,
  Camera,
  Cash,
  ChartBar,
  ChartBarSquare,
  ChartPie,
  Chat,
  ChatAlt,
  ChatAlt2,
  ChatGroup,
  Check,
  CheckAlt,
  CheckCircle,
  ChevronDoubleDown,
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronDoubleUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Chip,
  CircleDonut,
  CircleFilledWithOffset,
  CircleOutline,
  Clipboard,
  ClipboardCheck,
  ClipboardCopy,
  ClipboardList,
  Clock,
  ClockAlt,
  Cloud,
  CloudDownload,
  CloudDrizzle,
  CloudLightning,
  CloudOff,
  CloudRain,
  CloudSnow,
  CloudUpload,
  Code,
  Coffee,
  Cog,
  Collection,
  ColorPalette,
  ColorSwatch,
  Command,
  CreditCard,
  CreditCardAlt,
  Crop,
  Crosshair,
  Cube,
  CubeTransparent,
  CurencyYen,
  CurrencyBangladeshi,
  CurrencyDollar,
  CurrencyEuro,
  CurrencyPound,
  CurrencyRupee,
  CursorClick,
  Database,
  DatabaseAlt,
  DeviceDesktop,
  DeviceMobile,
  DeviceTablet,
  DirectionsDiamond,
  Diamond,
  Divide,
  Document,
  DocumentAdd,
  DocumentDownload,
  DocumentDuplicate,
  DocumentRemove,
  DocumentReport,
  DocumentSearch,
  DocumentText,
  DollarSign,
  DotsHorizontal,
  DotsHorizontalCircle,
  DotsVertical,
  Download,
  DownloadAlt,
  DownloadCloud,
  Droplet,
  Duplicate,
  Edit,
  Edit2,
  Edit3,
  EmojiHappy,
  EmojiMeh,
  EmojiSad,
  Exclamation,
  ExclamationCircle,
  ExclamationOctagon,
  ExternalLink,
  ExternalLinkAlt,
  Eye,
  EyeOff,
  FastFood,
  FastForward,
  File,
  FileMinus,
  FilePlus,
  FileText,
  Film,
  Filter,
  FilterX,
  FingerPrint,
  FingerPrintAlt,
  Fire,
  Fish,
  Flag,
  Flame,
  Flower,
  Folder,
  FolderAdd,
  FolderAlt,
  FolderDownload,
  FolderMinus,
  FolderOpen,
  FolderOpenAlt,
  FolderPlus,
  FolderRemove,
  Football,
  Gcal,
  Gift,
  Globe,
  GlobeAlt,
  Hand,
  Hash,
  Hashtag,
  Heart,
  HeartFill,
  Help,
  HelpCircle,
  Home,
  HomeAlt,
  HourGlass,
  Identification,
  Image,
  Images,
  Inbox,
  InboxIn,
  InformationCircle,
  Key,
  KeyAlt,
  Laptop,
  Layers,
  Leaf,
  LetterG,
  LetterN,
  LetterR,
  Library,
  LifeBuoy,
  LightBulb,
  LightningBolt,
  Link,
  Link2,
  Link3,
  Loader,
  LocationMarker,
  LockClosed,
  LockOpen,
  Login,
  Logout,
  Mail,
  MailAlt,
  MailOpen,
  Man,
  Map,
  MapAlt,
  MapPin,
  Megaphone,
  Menu,
  Menu2,
  Menu3,
  Menu4,
  Menu5,
  Menu6,
  MenuAlt1,
  MenuAlt2,
  MenuAlt3,
  MenuAlt4,
  MessageCircle,
  Microphone,
  Minus,
  MinusCircle,
  MinusSmall,
  Monitor,
  Moon,
  Moon2,
  Moon3,
  MousePointer,
  Music,
  MusicNote,
  Navigation,
  NavigationAlt,
  Newspaper,
  OfficeBuilding,
  Package,
  PaperAirplane,
  PaperClip,
  PaperClipAlt,
  Pause,
  Pencil,
  PencilAlt,
  PenTool,
  Person,
  Phone,
  PhoneIncoming,
  PhoneMissedCall,
  PhoneOutgoing,
  Photograph,
  PieChart2,
  PieChart3,
  Pizza,
  Planet,
  Play,
  Plus,
  PlusCircle,
  PlusSmall,
  PresentationChartBar,
  PresentationChartLine,
  Print,
  Printer,
  Prism,
  Puzzle,
  Qrcode,
  QuestionMarkCircle,
  Quote,
  Rainey,
  Reader,
  ReceiptRefund,
  ReceiptTax,
  Reciept,
  Refresh,
  RefreshCcw,
  RefreshCw,
  ReorderFour,
  Repeat,
  Reply,
  Rewind,
  Ribbon,
  RibbonAlt,
  RLogo,
  Rocket,
  RotateCcw,
  RotateCW,
  Rss,
  Save,
  SaveAlt,
  SaveAs,
  Scale,
  School,
  Scissors,
  Search,
  Search2,
  SearchCircle,
  Selector,
  Send,
  Server,
  Shapes,
  Share,
  ShieldCheck,
  ShieldExclamation,
  Shirt,
  ShoppingBag,
  ShoppingCart,
  SkillApproachable,
  SkillCommunication,
  SkillCustomerFacing,
  SkillDependable,
  SkillFolder,
  SkillGiftWrapping,
  SkillHeavyLifter,
  SkillHustler,
  SkillInteriorDesign,
  SkillPatient,
  SkillPointOfSale,
  SkillPunctual,
  SkillSales,
  SkillStylist,
  SkillTeamPlayer,
  SkillTrustworthy,
  Skull,
  Snow,
  SocialFacebook,
  SocialInstagram,
  SocialLinkedin,
  SocialTikTok,
  SocialTwitter,
  SortAscending,
  SortDescending,
  Sparkle,
  SparklePlus,
  Sparkles,
  Speakerphone,
  Speedometer,
  Star,
  StarFill,
  StarHalf,
  StarHalfStroke,
  StatsChart,
  StatusOffline,
  StatusOnline,
  Stop,
  Storefront,
  StoreFrontAlt,
  StoreFrontAltEdit,
  Subway,
  Sun,
  SunAlt,
  Support,
  SwitchHorizontal,
  SwitchVertical,
  Table,
  Tablet,
  Tag,
  TagAlt,
  Template,
  Tennisball,
  Terminal,
  Terminal2,
  Terminal3,
  Text,
  ThumbDown,
  ThumbUp,
  Ticket,
  Tool,
  Train,
  Translate,
  Trash,
  Trash2,
  Trash3,
  Trash4,
  TrendingDown,
  TrendingUp,
  Truck,
  TruckAlt,
  Tv,
  Umbrella,
  Unlink,
  Upload,
  User,
  UserAccess,
  UserAdd,
  UserAdd2,
  UserAlt,
  UserCheck,
  UserCircle,
  UserEdit,
  UserGroup,
  UserMinus,
  UserMove,
  UserMoveAlt,
  UserMoveAlt2,
  UserOverbook,
  UserRemove,
  UserRemove2,
  Users,
  UsersAlt,
  Variable,
  Video,
  VideoCamera,
  VideoFill,
  ViewBoards,
  ViewGrid,
  ViewGridAdd,
  ViewList,
  VolumeOff,
  VolumeUp,
  Walk,
  WalkAlt,
  Wallet,
  Watch,
  Water,
  Wifi,
  Woman,
  X,
  XCircle,
  Zap,
  ZapOff,
  ZoomIn,
  ZoomInAlt,
  ZoomOut,
  ZoomOutAlt,
};

export const ICON_BY_NAME = {
  academiccap: AcademicCap,
  activity: Activity,
  adjustments: Adjustments,
  airplane: Airplane,
  alertcircle: AlertCircle,
  annotation: Annotation,
  apple: Apple,
  archive: Archive,
  archiveaalt: ArchiveaAlt,
  arrowdown: ArrowDown,
  arrowdowncircle: ArrowDownCircle,
  arrowdownnarrow: ArrowDownNarrow,
  arrowleft: ArrowLeft,
  arrowleftcircle: ArrowLeftCircle,
  arrowleftnarrow: ArrowLeftNarrow,
  arrowright: ArrowRight,
  arrowrightcircle: ArrowRightCircle,
  arrowrightnarrow: ArrowRightNarrow,
  arrowsexpand: ArrowsExpand,
  arrowsupdown: ArrowsUpDown,
  arrowup: ArrowUp,
  arrowupcircle: ArrowUpCircle,
  arrowupnarrow: ArrowUpNarrow,
  atsign: AtSign,
  atsymbol: AtSymbol,
  award: Award,
  backspace: Backspace,
  badgecheck: BadgeCheck,
  bag: Bag,
  baghandle: BagHandle,
  ban: Ban,
  barchart: BarChart,
  barchartalt: BarChartAlt,
  baseball: Baseball,
  basketball: Basketball,
  beaker: Beaker,
  bell: Bell,
  bicycle: Bicycle,
  bookmark: Bookmark,
  bookmarkalt: BookmarkAlt,
  bookdollar: BookDollar,
  bookopen: BookOpen,
  briefcase: Briefcase,
  brush: Brush,
  building: Building,
  cake: Cake,
  calculator: Calculator,
  calendar: Calendar,
  calendaralt: CalendarAlt,
  calendarcheck: CalendarCheck,
  calendarcheckalt: CalendarCheckAlt,
  calendarclock: CalendarClock,
  calendaredit: CalendarEdit,
  calendardollar: CalendarDollar,
  calendarplus: CalendarPlus,
  camera: Camera,
  cash: Cash,
  chartbar: ChartBar,
  chartbarsquare: ChartBarSquare,
  chartpie: ChartPie,
  chat: Chat,
  chatalt: ChatAlt,
  chatalt2: ChatAlt2,
  chatgroup: ChatGroup,
  check: Check,
  checkalt: CheckAlt,
  checkcircle: CheckCircle,
  chevrondoubledown: ChevronDoubleDown,
  chevrondoubleleft: ChevronDoubleLeft,
  chevrondoubleright: ChevronDoubleRight,
  chevrondoubleup: ChevronDoubleUp,
  chevrondown: ChevronDown,
  chevronleft: ChevronLeft,
  chevronright: ChevronRight,
  chevronup: ChevronUp,
  chip: Chip,
  circledonut: CircleDonut,
  circlefilledwithoffset: CircleFilledWithOffset,
  circleoutline: CircleOutline,
  clipboard: Clipboard,
  clipboardcheck: ClipboardCheck,
  clipboardcopy: ClipboardCopy,
  clipboardlist: ClipboardList,
  clock: Clock,
  clockalt: ClockAlt,
  cloud: Cloud,
  clouddownload: CloudDownload,
  clouddrizzle: CloudDrizzle,
  cloudlightning: CloudLightning,
  cloudoff: CloudOff,
  cloudrain: CloudRain,
  cloudsnow: CloudSnow,
  cloudupload: CloudUpload,
  code: Code,
  coffee: Coffee,
  cog: Cog,
  collection: Collection,
  colorpalette: ColorPalette,
  colorswatch: ColorSwatch,
  command: Command,
  creditcard: CreditCard,
  creditcardalt: CreditCardAlt,
  crop: Crop,
  crosshair: Crosshair,
  cube: Cube,
  cubetransparent: CubeTransparent,
  curencyyen: CurencyYen,
  currencybangladeshi: CurrencyBangladeshi,
  currencydollar: CurrencyDollar,
  currencyeuro: CurrencyEuro,
  currencypound: CurrencyPound,
  currencyrupee: CurrencyRupee,
  cursorclick: CursorClick,
  database: Database,
  databasealt: DatabaseAlt,
  devicedesktop: DeviceDesktop,
  devicemobile: DeviceMobile,
  devicetablet: DeviceTablet,
  directionsdiamond: DirectionsDiamond,
  diamond: Diamond,
  divide: Divide,
  document: Document,
  documentadd: DocumentAdd,
  documentdownload: DocumentDownload,
  documentduplicate: DocumentDuplicate,
  documentremove: DocumentRemove,
  documentreport: DocumentReport,
  documentsearch: DocumentSearch,
  documenttext: DocumentText,
  dollarsign: DollarSign,
  dotshorizontal: DotsHorizontal,
  dotshorizontalcircle: DotsHorizontalCircle,
  dotsvertical: DotsVertical,
  download: Download,
  downloadalt: DownloadAlt,
  downloadcloud: DownloadCloud,
  droplet: Droplet,
  duplicate: Duplicate,
  edit: Edit,
  edit2: Edit2,
  edit3: Edit3,
  emojihappy: EmojiHappy,
  emojimeh: EmojiMeh,
  emojisad: EmojiSad,
  exclamation: Exclamation,
  exclamationcircle: ExclamationCircle,
  exclamationoctagon: ExclamationOctagon,
  externallink: ExternalLink,
  externallinkalt: ExternalLinkAlt,
  eye: Eye,
  eyeoff: EyeOff,
  fastfood: FastFood,
  fastforward: FastForward,
  file: File,
  fileminus: FileMinus,
  fileplus: FilePlus,
  filetext: FileText,
  film: Film,
  filter: Filter,
  filterx: FilterX,
  fingerprint: FingerPrint,
  fingerprintalt: FingerPrintAlt,
  fire: Fire,
  fish: Fish,
  flag: Flag,
  flame: Flame,
  flower: Flower,
  folder: Folder,
  folderadd: FolderAdd,
  folderalt: FolderAlt,
  folderdownload: FolderDownload,
  folderminus: FolderMinus,
  folderopen: FolderOpen,
  folderopenalt: FolderOpenAlt,
  folderplus: FolderPlus,
  folderremove: FolderRemove,
  football: Football,
  gcal: Gcal,
  gift: Gift,
  globe: Globe,
  globealt: GlobeAlt,
  hand: Hand,
  hash: Hash,
  hashtag: Hashtag,
  heart: Heart,
  heartfill: HeartFill,
  help: Help,
  helpcircle: HelpCircle,
  home: Home,
  homealt: HomeAlt,
  hourglass: HourGlass,
  identification: Identification,
  image: Image,
  images: Images,
  inbox: Inbox,
  inboxin: InboxIn,
  informationcircle: InformationCircle,
  key: Key,
  keyalt: KeyAlt,
  laptop: Laptop,
  layers: Layers,
  leaf: Leaf,
  letterg: LetterG,
  lettern: LetterN,
  letterr: LetterR,
  library: Library,
  lifebuoy: LifeBuoy,
  lightbulb: LightBulb,
  lightningbolt: LightningBolt,
  link: Link,
  link2: Link2,
  link3: Link3,
  loader: Loader,
  locationmarker: LocationMarker,
  lockclosed: LockClosed,
  lockopen: LockOpen,
  login: Login,
  logout: Logout,
  mail: Mail,
  mailalt: MailAlt,
  mailopen: MailOpen,
  man: Man,
  map: Map,
  mapalt: MapAlt,
  mappin: MapPin,
  megaphone: Megaphone,
  menu: Menu,
  menu2: Menu2,
  menu3: Menu3,
  menu4: Menu4,
  menu5: Menu5,
  menu6: Menu6,
  menualt1: MenuAlt1,
  menualt2: MenuAlt2,
  menualt3: MenuAlt3,
  menualt4: MenuAlt4,
  messagecircle: MessageCircle,
  microphone: Microphone,
  minus: Minus,
  minuscircle: MinusCircle,
  minussmall: MinusSmall,
  monitor: Monitor,
  moon: Moon,
  moon2: Moon2,
  moon3: Moon3,
  mousepointer: MousePointer,
  music: Music,
  musicnote: MusicNote,
  navigation: Navigation,
  navigationalt: NavigationAlt,
  newspaper: Newspaper,
  officebuilding: OfficeBuilding,
  package: Package,
  paperairplane: PaperAirplane,
  paperclip: PaperClip,
  paperclipalt: PaperClipAlt,
  pause: Pause,
  pencil: Pencil,
  pencilalt: PencilAlt,
  pentool: PenTool,
  person: Person,
  phone: Phone,
  phoneincoming: PhoneIncoming,
  phonemissedcall: PhoneMissedCall,
  phoneoutgoing: PhoneOutgoing,
  photograph: Photograph,
  piechart2: PieChart2,
  piechart3: PieChart3,
  pizza: Pizza,
  planet: Planet,
  play: Play,
  plus: Plus,
  pluscircle: PlusCircle,
  plussmall: PlusSmall,
  presentationchartbar: PresentationChartBar,
  presentationchartline: PresentationChartLine,
  print: Print,
  printer: Printer,
  prism: Prism,
  puzzle: Puzzle,
  qrcode: Qrcode,
  questionmarkcircle: QuestionMarkCircle,
  quote: Quote,
  rainey: Rainey,
  reader: Reader,
  receiptrefund: ReceiptRefund,
  receipttax: ReceiptTax,
  reciept: Reciept,
  refresh: Refresh,
  refreshccw: RefreshCcw,
  refreshcw: RefreshCw,
  reorderfour: ReorderFour,
  repeat: Repeat,
  reply: Reply,
  rewind: Rewind,
  ribbon: Ribbon,
  ribbonalt: RibbonAlt,
  rlogo: RLogo,
  rocket: Rocket,
  rotateccw: RotateCcw,
  rotatecw: RotateCW,
  rss: Rss,
  save: Save,
  savealt: SaveAlt,
  saveas: SaveAs,
  scale: Scale,
  school: School,
  scissors: Scissors,
  search: Search,
  search2: Search2,
  searchcircle: SearchCircle,
  selector: Selector,
  send: Send,
  server: Server,
  shapes: Shapes,
  share: Share,
  shieldcheck: ShieldCheck,
  shieldexclamation: ShieldExclamation,
  shirt: Shirt,
  shoppingbag: ShoppingBag,
  shoppingcart: ShoppingCart,
  skillapproachable: SkillApproachable,
  skillcommunication: SkillCommunication,
  skillcustomerfacing: SkillCustomerFacing,
  skilldependable: SkillDependable,
  skillfolder: SkillFolder,
  skillgiftwrapping: SkillGiftWrapping,
  skillheavylifter: SkillHeavyLifter,
  skillhustler: SkillHustler,
  skillinteriordesign: SkillInteriorDesign,
  skillpatient: SkillPatient,
  skillpointofsale: SkillPointOfSale,
  skillpunctual: SkillPunctual,
  skillsales: SkillSales,
  skillstylist: SkillStylist,
  skillteamplayer: SkillTeamPlayer,
  skilltrustworthy: SkillTrustworthy,
  skull: Skull,
  snow: Snow,
  socialfacebook: SocialFacebook,
  socialinstagram: SocialInstagram,
  sociallinkedin: SocialLinkedin,
  socialtiktok: SocialTikTok,
  socialtwitter: SocialTwitter,
  sortascending: SortAscending,
  sortdescending: SortDescending,
  sparkle: Sparkle,
  sparkleplus: SparklePlus,
  sparkles: Sparkles,
  speakerphone: Speakerphone,
  speedometer: Speedometer,
  star: Star,
  starfill: StarFill,
  starhalf: StarHalf,
  starhalfstroke: StarHalfStroke,
  statschart: StatsChart,
  statusoffline: StatusOffline,
  statusonline: StatusOnline,
  stop: Stop,
  storefront: Storefront,
  storefrontalt: StoreFrontAlt,
  storefrontaltedit: StoreFrontAltEdit,
  subway: Subway,
  sun: Sun,
  sunalt: SunAlt,
  support: Support,
  switchhorizontal: SwitchHorizontal,
  switchvertical: SwitchVertical,
  table: Table,
  tablet: Tablet,
  tag: Tag,
  tagalt: TagAlt,
  template: Template,
  tennisball: Tennisball,
  terminal: Terminal,
  terminal2: Terminal2,
  terminal3: Terminal3,
  text: Text,
  thumbdown: ThumbDown,
  thumbup: ThumbUp,
  ticket: Ticket,
  tool: Tool,
  train: Train,
  translate: Translate,
  trash: Trash,
  trash2: Trash2,
  trash3: Trash3,
  trash4: Trash4,
  trendingdown: TrendingDown,
  trendingup: TrendingUp,
  truck: Truck,
  truckalt: TruckAlt,
  tv: Tv,
  umbrella: Umbrella,
  unlink: Unlink,
  upload: Upload,
  user: User,
  useraccess: UserAccess,
  useradd: UserAdd,
  useradd2: UserAdd2,
  useralt: UserAlt,
  usercheck: UserCheck,
  usercircle: UserCircle,
  useredit: UserEdit,
  usergroup: UserGroup,
  userminus: UserMinus,
  usermove: UserMove,
  usermovealt: UserMoveAlt,
  usermovealt2: UserMoveAlt2,
  useroverbook: UserOverbook,
  userremove: UserRemove,
  userremove2: UserRemove2,
  users: Users,
  usersalt: UsersAlt,
  variable: Variable,
  video: Video,
  videocamera: VideoCamera,
  videofill: VideoFill,
  viewboards: ViewBoards,
  viewgrid: ViewGrid,
  viewgridadd: ViewGridAdd,
  viewlist: ViewList,
  volumeoff: VolumeOff,
  volumeup: VolumeUp,
  walk: Walk,
  walkalt: WalkAlt,
  wallet: Wallet,
  watch: Watch,
  water: Water,
  wifi: Wifi,
  woman: Woman,
  x: X,
  xcircle: XCircle,
  zap: Zap,
  zapoff: ZapOff,
  zoomin: ZoomIn,
  zoominalt: ZoomInAlt,
  zoomout: ZoomOut,
  zoomoutalt: ZoomOutAlt,
};

export enum ICON_NAMES {
  academiccap = 'academiccap',
  activity = 'activity',
  adjustments = 'adjustments',
  airplane = 'airplane',
  alertcircle = 'alertcircle',
  annotation = 'annotation',
  apple = 'apple',
  archive = 'archive',
  archiveaalt = 'archiveaalt',
  arrowdown = 'arrowdown',
  arrowdowncircle = 'arrowdowncircle',
  arrowdownnarrow = 'arrowdownnarrow',
  arrowleft = 'arrowleft',
  arrowleftcircle = 'arrowleftcircle',
  arrowleftnarrow = 'arrowleftnarrow',
  arrowright = 'arrowright',
  arrowrightcircle = 'arrowrightcircle',
  arrowrightnarrow = 'arrowrightnarrow',
  arrowsexpand = 'arrowsexpand',
  arrowsupdown = 'arrowsupdown',
  arrowup = 'arrowup',
  arrowupcircle = 'arrowupcircle',
  arrowupnarrow = 'arrowupnarrow',
  atsign = 'atsign',
  atsymbol = 'atsymbol',
  award = 'award',
  backspace = 'backspace',
  badgecheck = 'badgecheck',
  bag = 'bag',
  baghandle = 'baghandle',
  ban = 'ban',
  barchart = 'barchart',
  barchartalt = 'barchartalt',
  baseball = 'baseball',
  basketball = 'basketball',
  beaker = 'beaker',
  bell = 'bell',
  bicycle = 'bicycle',
  bookmark = 'bookmark',
  bookmarkalt = 'bookmarkalt',
  bookdollar = 'bookdollar',
  bookopen = 'bookopen',
  briefcase = 'briefcase',
  brush = 'brush',
  building = 'building',
  cake = 'cake',
  calculator = 'calculator',
  calendar = 'calendar',
  calendaralt = 'calendaralt',
  calendarcheck = 'calendarcheck',
  calendarcheckalt = 'calendarcheckalt',
  calendarclock = 'calendarclock',
  calendaredit = 'calendaredit',
  calendardollar = 'calendardollar',
  calendarplus = 'calendarplus',
  camera = 'camera',
  cash = 'cash',
  chartbar = 'chartbar',
  chartbarsquare = 'chartbarsquare',
  chartpie = 'chartpie',
  chat = 'chat',
  chatalt = 'chatalt',
  chatalt2 = 'chatalt2',
  chatgroup = 'chatgroup',
  check = 'check',
  checkalt = 'checkalt',
  checkcircle = 'checkcircle',
  chevrondoubledown = 'chevrondoubledown',
  chevrondoubleleft = 'chevrondoubleleft',
  chevrondoubleright = 'chevrondoubleright',
  chevrondoubleup = 'chevrondoubleup',
  chevrondown = 'chevrondown',
  chevronleft = 'chevronleft',
  chevronright = 'chevronright',
  chevronup = 'chevronup',
  chip = 'chip',
  circledonut = 'circledonut',
  circlefilledwithoffset = 'circlefilledwithoffset',
  circleoutline = 'circleoutline',
  clipboard = 'clipboard',
  clipboardcheck = 'clipboardcheck',
  clipboardcopy = 'clipboardcopy',
  clipboardlist = 'clipboardlist',
  clock = 'clock',
  clockalt = 'clockalt',
  cloud = 'cloud',
  clouddownload = 'clouddownload',
  clouddrizzle = 'clouddrizzle',
  cloudlightning = 'cloudlightning',
  cloudoff = 'cloudoff',
  cloudrain = 'cloudrain',
  cloudsnow = 'cloudsnow',
  cloudupload = 'cloudupload',
  code = 'code',
  coffee = 'coffee',
  cog = 'cog',
  collection = 'collection',
  colorpalette = 'colorpalette',
  colorswatch = 'colorswatch',
  command = 'command',
  creditcard = 'creditcard',
  creditcardalt = 'creditcardalt',
  crop = 'crop',
  crosshair = 'crosshair',
  cube = 'cube',
  cubetransparent = 'cubetransparent',
  curencyyen = 'curencyyen',
  currencybangladeshi = 'currencybangladeshi',
  currencydollar = 'currencydollar',
  currencyeuro = 'currencyeuro',
  currencypound = 'currencypound',
  currencyrupee = 'currencyrupee',
  cursorclick = 'cursorclick',
  database = 'database',
  databasealt = 'databasealt',
  devicedesktop = 'devicedesktop',
  devicemobile = 'devicemobile',
  devicetablet = 'devicetablet',
  directionsdiamond = 'directionsdiamond',
  diamond = 'diamond',
  divide = 'divide',
  document = 'document',
  documentadd = 'documentadd',
  documentdownload = 'documentdownload',
  documentduplicate = 'documentduplicate',
  documentremove = 'documentremove',
  documentreport = 'documentreport',
  documentsearch = 'documentsearch',
  documenttext = 'documenttext',
  dollarsign = 'dollarsign',
  dotshorizontal = 'dotshorizontal',
  dotshorizontalcircle = 'dotshorizontalcircle',
  dotsvertical = 'dotsvertical',
  download = 'download',
  downloadalt = 'downloadalt',
  downloadcloud = 'downloadcloud',
  droplet = 'droplet',
  duplicate = 'duplicate',
  edit = 'edit',
  edit2 = 'edit2',
  edit3 = 'edit3',
  emojihappy = 'emojihappy',
  emojimeh = 'emojimeh',
  emojisad = 'emojisad',
  exclamation = 'exclamation',
  exclamationcircle = 'exclamationcircle',
  exclamationoctagon = 'exclamationoctagon',
  externallink = 'externallink',
  externallinkalt = 'externallinkalt',
  eye = 'eye',
  eyeoff = 'eyeoff',
  fastfood = 'fastfood',
  fastforward = 'fastforward',
  file = 'file',
  fileminus = 'fileminus',
  fileplus = 'fileplus',
  filetext = 'filetext',
  film = 'film',
  filter = 'filter',
  filterx = 'filterx',
  fingerprint = 'fingerprint',
  fingerprintalt = 'fingerprintalt',
  fire = 'fire',
  fish = 'fish',
  flag = 'flag',
  flame = 'flame',
  flower = 'flower',
  folder = 'folder',
  folderadd = 'folderadd',
  folderalt = 'folderalt',
  folderdownload = 'folderdownload',
  folderminus = 'folderminus',
  folderopen = 'folderopen',
  folderopenalt = 'folderopenalt',
  folderplus = 'folderplus',
  folderremove = 'folderremove',
  football = 'football',
  gcal = 'gcal',
  gift = 'gift',
  globe = 'globe',
  globealt = 'globealt',
  hand = 'hand',
  hash = 'hash',
  hashtag = 'hashtag',
  heart = 'heart',
  heartfill = 'heartfill',
  help = 'help',
  helpcircle = 'helpcircle',
  home = 'home',
  homealt = 'homealt',
  hourglass = 'hourglass',
  identification = 'identification',
  image = 'image',
  images = 'images',
  inbox = 'inbox',
  inboxin = 'inboxin',
  informationcircle = 'informationcircle',
  key = 'key',
  keyalt = 'keyalt',
  laptop = 'laptop',
  layers = 'layers',
  leaf = 'leaf',
  letterg = 'letterg',
  lettern = 'lettern',
  letterr = 'letterr',
  library = 'library',
  lifebuoy = 'lifebuoy',
  lightbulb = 'lightbulb',
  lightningbolt = 'lightningbolt',
  link = 'link',
  link2 = 'link2',
  link3 = 'link3',
  loader = 'loader',
  locationmarker = 'locationmarker',
  lockclosed = 'lockclosed',
  lockopen = 'lockopen',
  login = 'login',
  logout = 'logout',
  mail = 'mail',
  mailalt = 'mailalt',
  mailopen = 'mailopen',
  man = 'man',
  map = 'map',
  mapalt = 'mapalt',
  mappin = 'mappin',
  megaphone = 'megaphone',
  menu = 'menu',
  menu2 = 'menu2',
  menu3 = 'menu3',
  menu4 = 'menu4',
  menu5 = 'menu5',
  menu6 = 'menu6',
  menualt1 = 'menualt1',
  menualt2 = 'menualt2',
  menualt3 = 'menualt3',
  menualt4 = 'menualt4',
  messagecircle = 'messagecircle',
  microphone = 'microphone',
  minus = 'minus',
  minuscircle = 'minuscircle',
  minussmall = 'minussmall',
  monitor = 'monitor',
  moon = 'moon',
  moon2 = 'moon2',
  moon3 = 'moon3',
  mousepointer = 'mousepointer',
  music = 'music',
  musicnote = 'musicnote',
  navigation = 'navigation',
  navigationalt = 'navigationalt',
  newspaper = 'newspaper',
  officebuilding = 'officebuilding',
  package = 'package',
  paperairplane = 'paperairplane',
  paperclip = 'paperclip',
  paperclipalt = 'paperclipalt',
  pause = 'pause',
  pencil = 'pencil',
  pencilalt = 'pencilalt',
  pentool = 'pentool',
  person = 'person',
  phone = 'phone',
  phoneincoming = 'phoneincoming',
  phonemissedcall = 'phonemissedcall',
  phoneoutgoing = 'phoneoutgoing',
  photograph = 'photograph',
  piechart2 = 'piechart2',
  piechart3 = 'piechart3',
  pizza = 'pizza',
  planet = 'planet',
  play = 'play',
  plus = 'plus',
  pluscircle = 'pluscircle',
  plussmall = 'plussmall',
  presentationchartbar = 'presentationchartbar',
  presentationchartline = 'presentationchartline',
  print = 'print',
  printer = 'printer',
  prism = 'prism',
  puzzle = 'puzzle',
  qrcode = 'qrcode',
  questionmarkcircle = 'questionmarkcircle',
  quote = 'quote',
  rainey = 'rainey',
  reader = 'reader',
  receiptrefund = 'receiptrefund',
  receipttax = 'receipttax',
  reciept = 'reciept',
  refresh = 'refresh',
  refreshccw = 'refreshccw',
  refreshcw = 'refreshcw',
  reorderfour = 'reorderfour',
  repeat = 'repeat',
  reply = 'reply',
  rewind = 'rewind',
  ribbon = 'ribbon',
  ribbonalt = 'ribbonalt',
  rlogo = 'rlogo',
  rocket = 'rocket',
  rotateccw = 'rotateccw',
  rotatecw = 'rotatecw',
  rss = 'rss',
  save = 'save',
  savealt = 'savealt',
  saveas = 'saveas',
  scale = 'scale',
  school = 'school',
  scissors = 'scissors',
  search = 'search',
  search2 = 'search2',
  searchcircle = 'searchcircle',
  selector = 'selector',
  send = 'send',
  server = 'server',
  shapes = 'shapes',
  share = 'share',
  shieldcheck = 'shieldcheck',
  shieldexclamation = 'shieldexclamation',
  shirt = 'shirt',
  shoppingbag = 'shoppingbag',
  shoppingcart = 'shoppingcart',
  skillapproachable = 'skillapproachable',
  skillcommunication = 'skillcommunication',
  skillcustomerfacing = 'skillcustomerfacing',
  skilldependable = 'skilldependable',
  skillfolder = 'skillfolder',
  skillgiftwrapping = 'skillgiftwrapping',
  skillheavylifter = 'skillheavylifter',
  skillhustler = 'skillhustler',
  skillinteriordesign = 'skillinteriordesign',
  skillpatient = 'skillpatient',
  skillpointofsale = 'skillpointofsale',
  skillpunctual = 'skillpunctual',
  skillsales = 'skillsales',
  skillstylist = 'skillstylist',
  skillteamplayer = 'skillteamplayer',
  skilltrustworthy = 'skilltrustworthy',
  skull = 'skull',
  snow = 'snow',
  socialfacebook = 'socialfacebook',
  socialinstagram = 'socialinstagram',
  sociallinkedin = 'sociallinkedin',
  socialtiktok = 'socialtiktok',
  socialtwitter = 'socialtwitter',
  sortascending = 'sortascending',
  sortdescending = 'sortdescending',
  sparkle = 'sparkle',
  sparkleplus = 'sparkleplus',
  sparkles = 'sparkles',
  speakerphone = 'speakerphone',
  speedometer = 'speedometer',
  star = 'star',
  starfill = 'starfill',
  starhalf = 'starhalf',
  starhalfstroke = 'starhalfstroke',
  statschart = 'statschart',
  statusoffline = 'statusoffline',
  statusonline = 'statusonline',
  stop = 'stop',
  storefront = 'storefront',
  storefrontalt = 'storefrontalt',
  storefrontaltedit = 'storefrontaltedit',
  subway = 'subway',
  sun = 'sun',
  sunalt = 'sunalt',
  support = 'support',
  switchhorizontal = 'switchhorizontal',
  switchvertical = 'switchvertical',
  table = 'table',
  tablet = 'tablet',
  tag = 'tag',
  tagalt = 'tagalt',
  template = 'template',
  tennisball = 'tennisball',
  terminal = 'terminal',
  terminal2 = 'terminal2',
  terminal3 = 'terminal3',
  text = 'text',
  thumbdown = 'thumbdown',
  thumbup = 'thumbup',
  ticket = 'ticket',
  tool = 'tool',
  train = 'train',
  translate = 'translate',
  trash = 'trash',
  trash2 = 'trash2',
  trash3 = 'trash3',
  trash4 = 'trash4',
  trendingdown = 'trendingdown',
  trendingup = 'trendingup',
  truck = 'truck',
  truckalt = 'truckalt',
  tv = 'tv',
  umbrella = 'umbrella',
  unlink = 'unlink',
  upload = 'upload',
  user = 'user',
  useraccess = 'useraccess',
  useradd = 'useradd',
  useradd2 = 'useradd2',
  useralt = 'useralt',
  usercheck = 'usercheck',
  usercircle = 'usercircle',
  useredit = 'useredit',
  usergroup = 'usergroup',
  userminus = 'userminus',
  usermove = 'usermove',
  usermovealt = 'usermovealt',
  usermovealt2 = 'usermovealt2',
  useroverbook = 'useroverbook',
  userremove = 'userremove',
  userremove2 = 'userremove2',
  users = 'users',
  usersalt = 'usersalt',
  variable = 'variable',
  video = 'video',
  videocamera = 'videocamera',
  videofill = 'videofill',
  viewboards = 'viewboards',
  viewgrid = 'viewgrid',
  viewgridadd = 'viewgridadd',
  viewlist = 'viewlist',
  volumeoff = 'volumeoff',
  volumeup = 'volumeup',
  walk = 'walk',
  walkalt = 'walkalt',
  wallet = 'wallet',
  watch = 'watch',
  water = 'water',
  wifi = 'wifi',
  woman = 'woman',
  x = 'x',
  xcircle = 'xcircle',
  zap = 'zap',
  zapoff = 'zapoff',
  zoomin = 'zoomin',
  zoominalt = 'zoominalt',
  zoomout = 'zoomout',
  zoomoutalt = 'zoomoutalt',
}

export interface Props extends IconProps {
  name?: string;
}

export const Icon: FC<Props> = ({
  name,
  ...rest
}: {
  name: ICON_NAMES | string;
}) => {
  const IconComponent = ICON_BY_NAME[name ? name.toLowerCase() : 'x'];
  return <IconComponent {...rest} />;
};

Icon.displayName = 'ReflexIcon';
