import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Sparkle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.656 2.051 C 11.447 2.133,11.248 2.291,11.166 2.439 C 11.064 2.624,10.607 3.938,9.671 6.740 C 9.252 7.994,8.889 9.041,8.864 9.067 C 8.840 9.093,7.920 9.450,6.820 9.861 C 4.845 10.599,3.743 11.026,3.503 11.147 C 3.296 11.251,3.125 11.444,3.056 11.653 C 2.928 12.036,3.052 12.524,3.330 12.734 C 3.486 12.852,4.337 13.190,6.928 14.159 L 8.876 14.889 9.034 15.354 C 9.120 15.610,9.542 16.864,9.971 18.140 C 10.926 20.981,11.100 21.463,11.229 21.634 C 11.464 21.945,12.021 22.053,12.420 21.865 C 12.687 21.739,12.771 21.638,12.917 21.269 C 13.026 20.993,14.326 17.175,14.876 15.517 L 15.082 14.894 16.991 14.179 C 19.499 13.241,20.553 12.820,20.671 12.710 C 21.036 12.370,21.048 11.661,20.695 11.308 C 20.526 11.139,19.944 10.903,17.040 9.820 C 15.995 9.430,15.119 9.085,15.093 9.052 C 15.067 9.019,14.614 7.703,14.087 6.126 C 13.128 3.256,12.899 2.614,12.751 2.369 C 12.588 2.099,11.979 1.922,11.656 2.051 M12.639 8.140 C 13.268 10.001,13.440 10.437,13.606 10.589 C 13.712 10.686,14.292 10.923,15.992 11.562 C 16.592 11.788,17.069 11.986,17.052 12.003 C 17.034 12.020,16.579 12.199,16.040 12.400 C 14.044 13.146,13.649 13.313,13.538 13.458 C 13.390 13.653,13.230 14.076,12.607 15.920 C 11.939 17.898,11.997 17.744,11.957 17.673 C 11.940 17.642,11.653 16.811,11.321 15.826 C 10.688 13.950,10.580 13.667,10.419 13.455 C 10.316 13.320,9.913 13.150,7.780 12.338 C 7.340 12.171,6.966 12.020,6.948 12.003 C 6.931 11.986,7.273 11.835,7.708 11.668 C 8.144 11.500,8.885 11.215,9.356 11.034 C 9.827 10.853,10.264 10.661,10.328 10.607 C 10.517 10.448,10.652 10.124,11.184 8.552 C 11.462 7.731,11.753 6.873,11.830 6.645 C 11.920 6.379,11.982 6.249,12.001 6.285 C 12.018 6.315,12.305 7.150,12.639 8.140 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Sparkle.displayName = 'SparkleIcon';
