import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Droplet: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.780 1.716 C 11.714 1.733,11.597 1.778,11.520 1.816 C 11.361 1.896,5.557 7.712,5.099 8.251 C 3.698 9.901,2.924 12.153,3.013 14.320 C 3.109 16.652,4.039 18.773,5.679 20.400 C 6.844 21.557,8.286 22.357,9.929 22.760 C 11.337 23.105,12.980 23.077,14.400 22.683 C 17.679 21.773,20.172 19.084,20.836 15.740 C 21.347 13.164,20.678 10.461,19.009 8.360 C 18.589 7.831,12.671 1.912,12.456 1.806 C 12.267 1.712,11.954 1.670,11.780 1.716 M14.684 6.790 C 17.682 9.798,17.810 9.946,18.325 11.021 C 19.778 14.051,18.805 17.758,16.038 19.729 C 15.297 20.257,14.300 20.681,13.320 20.885 C 12.913 20.969,12.758 20.980,11.980 20.978 C 11.225 20.976,11.041 20.963,10.680 20.886 C 8.984 20.523,7.598 19.684,6.561 18.395 C 4.733 16.123,4.502 12.969,5.980 10.459 C 6.411 9.726,6.636 9.479,9.336 6.770 C 10.788 5.313,11.987 4.120,12.000 4.120 C 12.012 4.120,13.220 5.321,14.684 6.790 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Droplet.displayName = 'DropletIcon';
