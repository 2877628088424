import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Fire: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.68 2.052c-.205.077-.242.101-.601.408-1.297 1.106-2.287 2.797-2.74 4.68-.074.308-.147.655-.162.772-.017.127-.045.205-.072.197-.093-.03-.551-.621-.715-.921-.268-.492-.363-.607-.594-.723a1.007 1.007 0 00-.9-.005c-.197.098-.518.42-.956.96-.734.906-1.387 2.241-1.673 3.42-.196.81-.244 1.238-.244 2.16 0 .922.048 1.35.244 2.16.695 2.867 2.885 5.301 5.669 6.301 1.029.369 1.916.518 3.084.516 1.496-.002 2.604-.255 3.92-.895a9 9 0 005.02-8.902 9.023 9.023 0 00-2.674-5.609c-.558-.55-1.137-.994-2.426-1.859-1.73-1.161-2.139-1.468-2.891-2.164-.226-.209-.478-.412-.56-.451-.189-.091-.548-.113-.729-.045zM12.908 5.1c.246.186.975.693 1.62 1.126.644.433 1.343.918 1.552 1.077 1.025.783 1.715 1.612 2.222 2.67 1.196 2.496.832 5.391-.949 7.529a6.699 6.699 0 01-2.213 1.738c-1.132.564-2.215.8-3.42.747-2.193-.098-4.141-1.152-5.43-2.939-.302-.419-.763-1.329-.931-1.838a7.034 7.034 0 01.667-5.855l.184-.305.486.485c.81.81 1.828 1.461 2.287 1.464.411.002.823-.283.951-.659.038-.113.066-.32.066-.497 0-.167.018-.501.04-.743.165-1.819.734-3.314 1.701-4.471l.226-.271.247.201c.135.11.447.354.694.541zm-1.288 4.994c-.418.195-.458.273-1.054 2.066l-.273.82-.776.02c-.711.018-.793.028-.967.114-.406.199-.576.562-.536 1.143a3.984 3.984 0 002.512 3.452c.504.204.866.27 1.474.269.544-.001.806-.039 1.256-.185 1.305-.424 2.334-1.563 2.649-2.933.112-.484.102-1.326-.02-1.8-.407-1.583-1.636-2.721-3.24-3.001-.493-.087-.786-.076-1.025.035zm1.514 2.262a1.984 1.984 0 01.27 3.047 1.903 1.903 0 01-.942.536c-.752.193-1.609-.121-2.071-.758l-.117-.161.494-.02c.429-.017.521-.034.693-.122.336-.173.406-.306.836-1.592l.39-1.164.136.057c.075.031.215.111.311.177z"
      fill="currentColor"
    />
  </Icon>
);

Fire.displayName = 'FireIcon';
