import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Map: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.68 3.055c-.077.03-1.387.678-2.91 1.44L9 5.88 6.923 4.842C5.781 4.27 4.737 3.766 4.604 3.721c-.326-.11-.86-.111-1.21-.002-.657.204-1.23.846-1.354 1.516-.025.137-.04 2.25-.04 5.795 0 6.157-.014 5.805.252 6.305.068.129.231.342.361.472.227.227.358.297 3.172 1.705l2.936 1.468h.556l2.862-1.43 2.862-1.43 2.076 1.039c1.142.571 2.186 1.075 2.319 1.12.302.101.852.11 1.167.017a2.027 2.027 0 001.375-1.46c.091-.386.091-11.286 0-11.672a2.041 2.041 0 00-.551-.971c-.227-.227-.358-.297-3.166-1.702-2.734-1.367-2.946-1.466-3.165-1.477a1.116 1.116 0 00-.376.041zM6.02 6.63L8 7.62v5.37c0 2.953-.009 5.37-.02 5.37-.011 0-.911-.445-2-.99L4 16.38v-5.37c0-2.953.009-5.37.02-5.37.011 0 .911.445 2 .99zM14 11.01v5.37l-1.98.99c-1.089.545-1.989.99-2 .99-.011 0-.02-2.417-.02-5.37V7.62l1.97-.988a112.23 112.23 0 012-.99c.017-.001.03 2.415.03 5.368zm4.02-4.38l1.98.99v5.37c0 2.953-.009 5.37-.02 5.37-.011 0-.911-.445-2-.99L16 16.38v-5.37c0-2.953.009-5.37.02-5.37.011 0 .911.445 2 .99z"
      fill="currentColor"
    />
  </Icon>
);

Map.displayName = 'MapIcon';
