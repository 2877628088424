import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CalendarEdit: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1a1 1 0 0 1 1 1v1h2a3 3 0 0 1 3 3v1.5a1 1 0 1 1-2 0V6a1 1 0 0 0-1-1h-2v1a1 1 0 1 1-2 0V5H9v1a1 1 0 0 1-2 0V5H5a1 1 0 0 0-1 1v3h4a1 1 0 1 1 0 2H4v9a1 1 0 0 0 1 1h3.5a1 1 0 1 1 0 2H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h2V2a1 1 0 0 1 2 0v1h6V2a1 1 0 0 1 1-1Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.69 10.697c.444-.392.894-.605 1.43-.679a2.733 2.733 0 0 1 1.474.254A2.538 2.538 0 0 1 24 12.552c.003.678-.21 1.226-.687 1.764-.15.17-1.906 1.935-3.902 3.925-2.46 2.45-3.673 3.637-3.765 3.681-.127.06-.243.066-1.522.075-1.582.01-1.617.007-1.872-.248-.256-.256-.26-.29-.249-1.871.009-1.279.015-1.394.075-1.521.044-.092 1.235-1.31 3.684-3.762 1.991-1.995 3.759-3.749 3.929-3.898Zm2.522 2.231c.208-.41-.001-.97-.428-1.148a.86.86 0 0 0-.74.033c-.066.033-1.742 1.68-3.727 3.662l-3.608 3.601v1.216h1.217l3.604-3.605c2.588-2.587 3.627-3.648 3.682-3.759Z"
      fill="currentColor"
    />
  </Icon>
);

CalendarEdit.displayName = 'CalendarEditIcon';
