import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Edit: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M19.513 0.918 C 18.987 1.015,18.509 1.222,18.100 1.533 C 17.968 1.633,15.671 3.908,12.994 6.588 C 8.456 11.133,8.125 11.473,8.066 11.660 C 7.929 12.096,7.000 15.887,7.000 16.012 C 7.000 16.521,7.479 17.000,7.988 17.000 C 8.113 17.000,11.904 16.071,12.340 15.934 C 12.527 15.875,12.867 15.544,17.412 11.006 C 20.092 8.329,22.367 6.032,22.467 5.900 C 22.704 5.587,22.898 5.199,23.009 4.812 C 23.136 4.369,23.136 3.631,23.009 3.188 C 22.700 2.115,21.890 1.298,20.839 1.001 C 20.504 0.906,19.813 0.863,19.513 0.918 M20.355 2.946 C 20.618 3.031,20.855 3.235,20.991 3.494 C 21.144 3.788,21.144 4.213,20.992 4.500 C 20.912 4.649,19.695 5.889,16.173 9.405 L 11.460 14.110 10.449 14.360 C 9.892 14.498,9.426 14.600,9.414 14.587 C 9.401 14.574,9.504 14.104,9.644 13.542 L 9.898 12.521 14.659 7.767 C 19.091 3.340,19.434 3.008,19.620 2.949 C 19.881 2.865,20.103 2.865,20.355 2.946 M3.503 3.042 C 2.852 3.151,2.167 3.541,1.722 4.057 C 1.495 4.321,1.371 4.522,1.235 4.851 C 0.988 5.452,1.000 5.020,1.000 13.000 C 1.000 20.991,0.987 20.546,1.238 21.156 C 1.392 21.528,1.582 21.809,1.886 22.114 C 2.321 22.548,2.815 22.814,3.421 22.939 C 3.860 23.030,18.151 23.030,18.580 22.940 C 19.789 22.685,20.685 21.789,20.940 20.580 C 20.989 20.345,20.999 19.704,20.999 16.577 C 21.000 12.386,21.016 12.614,20.700 12.298 C 20.390 11.988,19.932 11.920,19.531 12.126 C 19.315 12.238,19.103 12.491,19.042 12.711 C 19.015 12.809,19.000 14.117,19.000 16.503 C 19.000 20.603,19.015 20.387,18.701 20.701 C 18.377 21.025,19.023 21.000,11.000 21.000 C 2.977 21.000,3.623 21.025,3.299 20.701 C 2.975 20.377,3.000 21.023,3.000 13.000 C 3.000 4.977,2.975 5.623,3.299 5.299 C 3.613 4.985,3.396 5.000,7.500 5.000 C 11.604 5.000,11.387 5.015,11.701 4.701 C 12.092 4.310,12.092 3.690,11.701 3.299 C 11.386 2.984,11.614 2.999,7.423 3.005 C 5.375 3.007,3.611 3.024,3.503 3.042 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Edit.displayName = 'EditIcon';
