import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Thingy: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.535 31.6788C124.643 36.4614 128.22 40.4483 132.746 43.0618C137.272 45.6752 142.513 46.7795 147.709 46.2144C168.974 43.9027 182.86 67.9515 170.223 85.2116C167.136 89.4292 165.471 94.5203 165.471 99.7472C165.471 104.974 167.136 110.065 170.223 114.283V114.283C182.858 131.543 168.972 155.592 147.707 153.28C142.511 152.715 137.27 153.819 132.744 156.433C128.218 159.046 124.641 163.033 122.533 167.816V167.816C113.903 187.387 86.1246 187.387 77.5015 167.816C75.3934 163.033 71.8172 159.047 67.2913 156.433C62.7653 153.82 57.5248 152.715 52.3291 153.28V153.28C31.0641 155.592 17.1783 131.543 29.815 114.283C32.9025 110.065 34.5668 104.974 34.5668 99.7472C34.5668 94.5203 32.9025 89.4292 29.815 85.2116V85.2116C17.18 67.9515 31.0658 43.9027 52.3308 46.2144C57.5268 46.7795 62.7677 45.6752 67.294 43.0618C71.8203 40.4483 75.3968 36.4614 77.505 31.6788V31.6788C86.1333 12.1071 113.905 12.1071 122.535 31.6788Z"
      fill="currentColor"
    />
  </Icon>
);

Thingy.displayName = 'ThingyShape';
