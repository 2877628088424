import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CloudUpload: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.24 1.1c-1.903.262-3.525 1.358-4.457 3.014-.489.867-.72 1.704-.77 2.787l-.025.522-.23.108c-.608.286-1.378.942-1.81 1.542a5.282 5.282 0 00-.873 2.027c-.072.367-.072 1.433 0 1.8.398 2.028 1.937 3.589 3.944 3.999.528.108 1.049.125 1.3.041a.994.994 0 00.62-1.265c-.148-.434-.457-.632-1.057-.679-.477-.037-.819-.122-1.183-.296a3.09 3.09 0 01-1.608-1.94c-.109-.408-.098-1.212.022-1.6.317-1.02 1.14-1.814 2.142-2.064.429-.107.728-.378.829-.752.045-.165.036-.325-.046-.844-.094-.594.049-1.494.335-2.114.282-.609.828-1.261 1.369-1.633 1.951-1.343 4.657-.685 5.79 1.407.14.258.285.647.407 1.092.074.268.271.511.518.637.124.063.283.091.663.116.907.059 1.618.306 2.264.786a4.006 4.006 0 011.375 4.578 4.037 4.037 0 01-2.742 2.495c-.469.124-.556.165-.723.343-.209.222-.272.382-.272.693 0 .209.022.321.086.443.111.209.36.434.553.497.386.128 1.231-.078 2.058-.501 1.64-.839 2.77-2.32 3.169-4.151.075-.345.089-.529.088-1.188-.001-.864-.062-1.265-.298-1.948-.709-2.048-2.536-3.609-4.632-3.957l-.388-.064-.099-.262c-.241-.638-.753-1.399-1.322-1.967C13.921 1.488 12.051.852 10.24 1.1zm1.4 7.968c-.152.053-.431.312-1.811 1.687-1.711 1.704-1.784 1.791-1.82 2.167C7.956 13.477 8.437 14 9 14c.364 0 .496-.088 1.27-.854l.73-.721v4.86c0 5.464-.02 5.097.299 5.416a.984.984 0 001.402 0c.319-.319.299.048.299-5.416v-4.86l.73.721c.774.766.906.854 1.27.854.563 0 1.044-.523.991-1.078-.036-.376-.109-.463-1.82-2.167-1.459-1.453-1.652-1.631-1.828-1.69a1.053 1.053 0 00-.703.003z"
      fill="currentColor"
    />
  </Icon>
);

CloudUpload.displayName = 'CloudUploadIcon';
