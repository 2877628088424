import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Brush: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M19.520 1.518 C 19.050 1.585,18.640 1.754,18.256 2.041 C 17.995 2.236,8.043 13.314,7.951 13.511 C 7.746 13.953,8.002 14.425,8.519 14.561 C 9.262 14.756,9.997 15.463,10.164 16.145 C 10.271 16.582,10.481 16.808,10.831 16.863 C 11.156 16.915,11.283 16.829,12.067 16.025 C 15.462 12.547,21.928 5.796,22.063 5.589 C 22.740 4.558,22.637 3.249,21.809 2.355 C 21.226 1.725,20.343 1.403,19.520 1.518 M20.263 3.067 C 20.536 3.160,20.845 3.475,20.938 3.755 C 21.030 4.032,21.009 4.396,20.885 4.642 C 20.822 4.768,19.326 6.345,16.011 9.782 C 13.381 12.509,11.215 14.740,11.199 14.740 C 11.183 14.740,11.092 14.641,10.997 14.520 C 10.773 14.236,10.396 13.876,10.130 13.692 C 10.014 13.612,9.920 13.535,9.920 13.522 C 9.920 13.484,18.894 3.488,19.102 3.293 C 19.200 3.202,19.357 3.099,19.450 3.066 C 19.679 2.985,20.023 2.986,20.263 3.067 M5.979 15.040 C 5.526 15.106,4.910 15.357,4.560 15.618 C 4.303 15.809,3.915 16.217,3.741 16.480 C 3.412 16.975,3.255 17.456,3.199 18.131 C 3.176 18.414,3.135 18.654,3.096 18.731 C 2.936 19.048,2.661 19.227,2.244 19.284 C 1.786 19.348,1.505 19.636,1.505 20.041 C 1.505 20.638,2.750 21.733,3.935 22.180 C 6.466 23.132,9.013 21.828,9.647 19.254 C 9.781 18.711,9.771 17.908,9.624 17.422 C 9.128 15.781,7.624 14.798,5.979 15.040 M7.219 16.678 C 8.224 17.163,8.528 18.366,7.942 19.540 C 7.843 19.738,7.671 20.003,7.559 20.128 C 7.285 20.437,6.832 20.733,6.429 20.867 C 6.145 20.961,6.035 20.976,5.620 20.977 C 4.962 20.978,4.481 20.844,3.872 20.491 L 3.644 20.359 3.960 20.047 C 4.214 19.797,4.308 19.669,4.437 19.398 C 4.600 19.056,4.638 18.885,4.712 18.156 C 4.788 17.411,5.234 16.841,5.939 16.590 C 6.094 16.535,6.232 16.521,6.540 16.531 C 6.898 16.542,6.969 16.558,7.219 16.678 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Brush.displayName = 'BrushIcon';
