import { SortMap, SortState } from 'types';

interface useListSortProps<T> {
  sort: SortMap<Partial<T>>;
  updateSort: (key: string, value: SortState) => void;
  reverseSort?: boolean;
  // FUTURE: make reverse a list of keys for sort items, then switch off that
}

export const useListSort = <T>(props: useListSortProps<T>) => {
  const { sort, updateSort, reverseSort } = props || {};

  const activeSortItems = (() =>
    Object.entries(sort).reduce((acc, [key, value]) => {
      if ([SortState.ASC, SortState.DESC].includes(value as SortState)) {
        acc.push(key);
      }
      return acc;
    }, []))();

  const handleUpdate = key => {
    if (reverseSort) {
      switch (sort[key]) {
        case SortState.ASC: {
          updateSort(key, SortState.NONE);
          break;
        }
        case SortState.DESC: {
          updateSort(key, SortState.ASC);
          break;
        }
        case SortState.NONE:
        default: {
          updateSort(key, SortState.DESC);
          break;
        }
      }
    } else {
      switch (sort[key]) {
        case SortState.ASC: {
          updateSort(key, SortState.DESC);
          break;
        }
        case SortState.DESC: {
          updateSort(key, SortState.NONE);
          break;
        }
        case SortState.NONE:
        default: {
          updateSort(key, SortState.ASC);
          break;
        }
      }
    }
  };

  const updateSortItem = key => {
    // FUTURE: wire to multiple sort options
    if (activeSortItems.includes(key)) {
      handleUpdate(key);
    } else {
      activeSortItems.map(item => {
        updateSort(item, SortState.NONE);
      });
      handleUpdate(key);
    }
  };

  return {
    activeSortItems,
    handleUpdate,
    updateSortItem,
  };
};
