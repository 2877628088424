import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillPatient: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M12 14.617a.39.39 0 100-.781.39.39 0 000 .781z"
      fill="currentColor"
    />
    <path
      d="M14.136 11.658a6.148 6.148 0 002.17-2.242 6.047 6.047 0 00.772-2.95v-2.19c.455-.16.781-.595.781-1.104V2.39A.39.39 0 0017.47 2H6.53a.39.39 0 00-.39.39v.782c0 .51.326.943.78 1.105v2.19c0 1.028.268 2.048.773 2.949a6.148 6.148 0 002.17 2.242c.116.07.185.198.185.342 0 .144-.07.272-.185.342a6.147 6.147 0 00-2.17 2.242 6.048 6.048 0 00-.772 2.95v2.19a1.174 1.174 0 00-.781 1.104v.781c0 .216.174.391.39.391H17.47a.39.39 0 00.39-.39v-.782c0-.51-.326-.943-.78-1.105v-2.19a6.047 6.047 0 00-.773-2.949 6.147 6.147 0 00-2.17-2.242.394.394 0 01-.185-.342c0-.144.07-.272.185-.342zM6.922 2.78h10.156v.39a.39.39 0 01-.39.392H7.313a.391.391 0 01-.391-.391v-.39zM17.078 21.22H6.922v-.39c0-.216.175-.392.39-.392h9.375a.39.39 0 01.391.391v.39zM13.73 13.01c1.607.976 2.567 2.667 2.567 4.524v2.122H7.703v-2.122c0-1.857.96-3.548 2.567-4.524.35-.213.56-.59.56-1.01 0-.42-.21-.797-.56-1.01-1.607-.976-2.567-2.667-2.567-4.524V4.344h8.594v2.122c0 1.857-.96 3.548-2.567 4.524a1.17 1.17 0 00-.56 1.01c0 .42.21.797.56 1.01z"
      fill="currentColor"
    />
    <path
      d="M12.311 15.534a.39.39 0 00-.625 0c-.146.195-.33.361-.544.494-.448.27-.847.438-1.2.587-.782.33-1.458.615-1.458 1.698v.171c0 .216.175.391.391.391h6.25a.39.39 0 00.39-.39v-.172c0-1.083-.676-1.369-1.46-1.7-.356-.15-.76-.32-1.212-.593a1.943 1.943 0 01-.532-.486zm2.408 2.56H9.281c.06-.361.306-.481.964-.76.36-.15.806-.339 1.302-.638l.004-.002c.162-.1.312-.215.447-.343.133.125.278.236.435.334l.005.002c.501.302.951.493 1.313.645.661.28.908.4.968.762zM15.516 6.469V5.75a.39.39 0 00-.598-.331 5.499 5.499 0 01-5.836 0 .39.39 0 00-.598.331v.719c0 .675.261 1.403.755 2.103a6.376 6.376 0 001.918 1.752c.158.098.313.238.452.406v1.934a.39.39 0 10.782 0V10.73c.142-.17.302-.312.467-.415 1.59-.958 2.658-2.504 2.658-3.846zm-3.063 3.178l-.004.003a2.64 2.64 0 00-.448.352 2.524 2.524 0 00-.434-.343c-.002 0-.003-.002-.005-.003C10.19 8.83 9.267 7.548 9.267 6.47V6.41a6.28 6.28 0 005.468 0v.058c0 1.062-.938 2.37-2.281 3.178z"
      fill="currentColor"
    />
  </Icon>
);

SkillPatient.displayName = 'SkillPatientIcon';
