import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MusicNote: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.714 3.538c-4.196 1.052-6.151 1.559-6.251 1.62-.08.049-.189.148-.243.219-.231.303-.22.008-.22 5.74v5.268l-.33-.11c-1.436-.476-3.067-.312-4.261.43-.385.239-.861.713-1.06 1.055-.239.41-.328.747-.328 1.24 0 .717.23 1.249.779 1.801 1.874 1.886 5.849 1.461 6.942-.741.264-.531.257-.393.257-5.06L10 10.78l4.95-1.237 5.01-1.252c.053-.013.059.285.049 2.539l-.01 2.554-.344-.115c-1.409-.471-3.059-.302-4.246.436-.385.239-.861.713-1.06 1.055-.239.41-.328.747-.328 1.24 0 .717.23 1.249.779 1.801 1.874 1.885 5.849 1.461 6.942-.741.27-.545.257-.139.257-7.523.001-7.505.025-6.915-.298-7.238a1.027 1.027 0 00-.718-.295c-.09.002-2.911.692-6.269 1.534zm5.295 1.712l-.012.971-4.948 1.236c-2.722.679-4.971 1.244-4.999 1.254-.039.014-.05-.194-.05-.957V6.78l4.97-1.246a1006.12 1006.12 0 015.01-1.25c.026-.003.036.337.029.966zm-1.189 9.848c.375.099.789.319.973.519.269.292.271.472.006.76-.197.213-.524.391-.94.51-.457.13-1.261.13-1.718 0-.416-.119-.743-.297-.94-.51-.263-.286-.263-.468 0-.754.251-.272.706-.481 1.272-.585.269-.049 1.066-.014 1.347.06zm-12 3c.375.099.789.319.973.519.269.292.271.472.006.76-.197.213-.524.391-.94.51-.457.13-1.261.13-1.718 0-.416-.119-.743-.297-.94-.51-.263-.286-.263-.468 0-.754.251-.272.706-.481 1.272-.585.269-.049 1.066-.014 1.347.06z"
      fill="currentColor"
    />
  </Icon>
);

MusicNote.displayName = 'MusicNoteIcon';
