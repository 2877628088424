import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Check: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.64 6.068c-.157.053-.787.667-4.91 4.786l-4.729 4.725-1.731-1.724c-1.551-1.546-1.75-1.731-1.927-1.79-.39-.129-.766-.044-1.045.235a.99.99 0 00-.186 1.144c.061.125.701.79 2.217 2.301 1.924 1.919 2.15 2.131 2.328 2.19a.987.987 0 00.686 0c.181-.059.611-.478 5.328-5.19 3.743-3.738 5.154-5.173 5.216-5.301.397-.817-.378-1.673-1.247-1.376z"
      fill="currentColor"
    />
  </Icon>
);

Check.displayName = 'CheckIcon';
