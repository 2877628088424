import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MenuAlt1: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.643 5.069C3.291 5.193 3 5.614 3 6c0 .242.119.521.299.701C3.624 7.026 2.856 7 12 7s8.376.026 8.701-.299a.984.984 0 000-1.402c-.325-.326.446-.299-8.718-.296-7.286.003-8.182.01-8.34.066zm0 6C3.291 11.193 3 11.614 3 12c0 .242.119.521.299.701.317.317.038.299 4.701.299 4.663 0 4.384.018 4.701-.299a.984.984 0 000-1.402c-.317-.317-.035-.299-4.718-.296-3.663.003-4.184.011-4.34.066zm0 6C3.291 17.193 3 17.614 3 18c0 .242.119.521.299.701.325.325-.443.299 8.701.299s8.376.026 8.701-.299a.984.984 0 000-1.402c-.325-.326.446-.299-8.718-.296-7.286.003-8.182.01-8.34.066z"
      fill="currentColor"
    />
  </Icon>
);

MenuAlt1.displayName = 'MenuAlt1Icon';
