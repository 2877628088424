import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const InboxIn: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.643 2.069a1.118 1.118 0 00-.343.229c-.311.31-.3.19-.3 3.417v2.86l-.73-.721C9.496 7.088 9.364 7 9 7c-.563 0-1.044.523-.991 1.078.036.376.109.463 1.82 2.167 1.459 1.453 1.652 1.63 1.828 1.69a.987.987 0 00.686 0c.176-.06.369-.237 1.828-1.69 1.711-1.704 1.784-1.791 1.82-2.167C16.044 7.523 15.563 7 15 7c-.364 0-.496.088-1.27.854l-.73.72v-2.86c0-3.225.01-3.106-.299-3.415a.998.998 0 00-1.058-.23zm-6.023.956c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.326-.084 13.03 0 13.357.281 1.096 1.044 1.878 2.184 2.238.178.056.9.064 6.56.075 4.306.008 6.448-.001 6.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.327.084-13.033 0-13.357-.307-1.197-1.264-2.09-2.448-2.281-.141-.023-.788-.042-1.439-.042-1.357 0-1.456.017-1.738.299a.984.984 0 000 1.402c.28.28.385.299 1.701.299s1.421.018 1.701.299c.311.31.299.165.299 3.628V12h-.875c-.981 0-1.179.026-1.583.208-.263.119-.373.218-1.624 1.46L13.576 15h-3.152l-1.342-1.332C7.349 11.947 7.45 12 5.875 12H5l.002-3.09c.001-2.477.012-3.12.055-3.24.079-.221.241-.414.454-.54l.189-.11L6.98 5c1.241-.02 1.286-.023 1.47-.114.601-.296.73-1.108.251-1.587-.284-.284-.376-.3-1.778-.294-.673.002-1.259.01-1.303.02zm2.279 12.29c1.112 1.108 1.36 1.336 1.571 1.44.464.233.573.244 2.515.244 1.007 0 1.857-.016 1.98-.04.118-.021.35-.104.515-.184.278-.135.396-.242 1.621-1.46L17.423 14H19v2.073c0 2.346.002 2.327-.299 2.628-.322.322.202.299-6.701.299s-6.379.023-6.701-.3C4.998 18.4 5 18.42 5 16.074V14h1.577l1.322 1.316z"
      fill="currentColor"
    />
  </Icon>
);

InboxIn.displayName = 'InboxInIcon';
