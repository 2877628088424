import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Login: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.31 3.063a3.918 3.918 0 00-2.131 1.116 3.87 3.87 0 00-1.12 2.172c-.053.31-.059 1.05-.049 5.812.012 5.456.012 5.457.099 5.777.416 1.533 1.519 2.612 3.031 2.965.301.071.557.075 4.36.075 3.797 0 4.059-.004 4.36-.074a4.047 4.047 0 003.027-2.966c.067-.258.086-.488.102-1.187.023-1.018-.008-1.174-.287-1.453a.984.984 0 00-1.403-.001c-.256.256-.299.433-.299 1.226 0 .369-.02.766-.044.883a2.062 2.062 0 01-1.548 1.548c-.296.062-7.52.062-7.816 0a2.062 2.062 0 01-1.548-1.548c-.062-.298-.062-10.518 0-10.816a2.062 2.062 0 011.548-1.548c.296-.062 7.52-.062 7.816 0 .75.157 1.391.798 1.548 1.548.024.117.044.514.044.883 0 .793.043.97.299 1.226a.984.984 0 001.403-.001c.279-.279.31-.435.287-1.453-.016-.7-.035-.929-.102-1.187-.41-1.586-1.631-2.72-3.228-2.999-.497-.087-7.869-.085-8.349.002zm5.33 4.005c-.154.053-.488.369-2.311 2.187-1.516 1.511-2.156 2.176-2.217 2.301a1.018 1.018 0 00-.052.77c.065.201.198.342 2.195 2.345 1.511 1.516 2.176 2.156 2.301 2.217a.99.99 0 001.144-.186c.28-.279.364-.656.234-1.045-.057-.174-.21-.342-1.288-1.427L9.423 13h5.861c6.585 0 6.095.023 6.417-.299a.984.984 0 000-1.402c-.322-.322.168-.299-6.417-.299H9.423l1.223-1.23c1.078-1.085 1.231-1.253 1.288-1.427.13-.389.045-.766-.233-1.044a1.005 1.005 0 00-1.061-.231z"
      fill="currentColor"
    />
  </Icon>
);

Login.displayName = 'LoginIcon';
