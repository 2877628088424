import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillCustomerFacing: FC<IconProps> = props => (
  <Icon {...props}>
    <g clipPath="url(#clip0)" fill="currentColor">
      <path d="M13.979 8.992a.391.391 0 100-.782.391.391 0 000 .782zM10.065 8.992a.391.391 0 100-.782.391.391 0 000 .782zM10.975 11.677c.329.155.68.233 1.046.233.358 0 .703-.075 1.026-.223a.39.39 0 10-.327-.71c-.22.1-.455.152-.699.152-.249 0-.489-.053-.712-.159a.392.392 0 00-.334.707z" />
      <path d="M20.653 17.811c0-2.148-2.45-3.832-4.552-3.912l-1.072-.961a5.093 5.093 0 002.098-4.102V6.258c0-2.37-1.937-4.297-4.319-4.297h-1.565A4.287 4.287 0 006.95 6.258v2.578c0 1.62.782 3.146 2.07 4.095l-1.08.968c-2.096.08-4.552 1.76-4.552 3.912v3.76c0 .215.176.39.392.39h16.48a.391.391 0 00.392-.39v-3.76zm-9.499 1.572h1.732l.41 1.797h-2.564l.422-1.797zm1.766-.781h-1.79l-.3-.934 1.192-.595 1.217.608-.32.92zm-.899-2.703l-1.36-2.171a5.104 5.104 0 002.718.003l-1.358 2.168zm.56.58l1.88-3 1.013.908-1.29 2.892-1.602-.8zM11.244 2.742h1.565c1.95 0 3.537 1.577 3.537 3.516v.323l-.735-1.057a.391.391 0 00-.602-.05c-.795.812-1.716 1.174-2.986 1.174-1.27 0-2.191-.362-2.988-1.174a.392.392 0 00-.603.053l-.697 1.02v-.29a3.516 3.516 0 013.509-3.515zm-3.51 6.094v-.903l1.088-1.59c.87.74 1.9 1.087 3.2 1.087 1.303 0 2.333-.348 3.204-1.089l1.12 1.61v.885c0 2.34-1.916 4.297-4.32 4.297-2.395 0-4.291-1.952-4.291-4.297zm1.849 4.642l1.88 3.001-1.604.8-1.29-2.892 1.014-.909zm-5.41 4.333c0-1.609 1.96-2.998 3.673-3.125l1.464 3.284a.391.391 0 00.533.19l.279-.139.317.987-.51 2.172H4.172V17.81zM19.87 21.18H14.1l-.495-2.166.34-.98.256.127a.392.392 0 00.532-.19l1.465-3.285c1.712.127 3.672 1.516 3.672 3.125v3.369z" />
      <path d="M17.11 19.383h-1.566a.391.391 0 100 .781h1.566a.391.391 0 100-.781zM18.654 19.383a.391.391 0 110 .782.391.391 0 010-.782z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="currentColor"
          transform="translate(2 2)"
          d="M0 0h20v20H0z"
        />
      </clipPath>
    </defs>
  </Icon>
);

SkillCustomerFacing.displayName = 'SkillCustomerFacingIcon';
