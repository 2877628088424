import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Terminal: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 3.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.326-.084 13.031 0 13.357.281 1.096 1.043 1.877 2.184 2.238.179.056.983.064 7.56.075 4.998.008 7.448-.001 7.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.327.084-13.033 0-13.357-.307-1.197-1.264-2.09-2.444-2.28-.246-.04-14.661-.057-14.86-.018zm14.72 2.041c.115.039.263.135.361.233.322.322.299-.202.299 6.701s.023 6.379-.299 6.701c-.324.324.322.299-7.701.299-8.025 0-7.377.025-7.702-.3-.323-.323-.3.208-.288-6.782L4.02 5.7l.111-.189c.125-.213.318-.375.539-.454.123-.044 1.434-.054 7.313-.055 6.565-.002 7.179.004 7.357.064zM7.643 8.069C7.291 8.193 7 8.614 7 9c0 .08.03.234.066.343.057.174.21.342 1.288 1.427L9.577 12l-1.223 1.23c-1.078 1.085-1.231 1.253-1.288 1.427-.13.389-.046.766.234 1.045a.99.99 0 001.144.186c.124-.06.661-.572 1.801-1.717 1.514-1.519 1.63-1.646 1.695-1.845.084-.26.066-.525-.052-.77-.06-.124-.572-.66-1.717-1.801-1.459-1.453-1.652-1.631-1.828-1.69a1.032 1.032 0 00-.7.004zm5 6c-.352.124-.643.545-.643.931 0 .242.119.521.299.701.294.294.33.299 2.201.299s1.907-.005 2.201-.299a.984.984 0 000-1.402c-.295-.295-.328-.299-2.218-.296-1.398.003-1.691.013-1.84.066z"
      fill="currentColor"
    />
  </Icon>
);

Terminal.displayName = 'TerminalIcon';
