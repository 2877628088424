import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Send: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M21.720 1.039 C 21.665 1.056,17.111 2.648,11.600 4.577 C 5.079 6.859,1.524 8.122,1.421 8.193 C 0.927 8.533,0.866 9.270,1.295 9.696 C 1.441 9.841,1.957 10.081,5.820 11.795 C 8.218 12.859,10.200 13.750,10.225 13.775 C 10.250 13.800,11.141 15.782,12.205 18.180 C 14.346 23.004,14.236 22.793,14.678 22.939 C 15.068 23.069,15.521 22.941,15.771 22.632 C 15.866 22.515,16.627 20.382,19.426 12.392 C 21.415 6.716,22.972 2.211,22.986 2.095 C 23.066 1.419,22.365 0.834,21.720 1.039 M14.477 8.103 L 10.774 11.806 7.768 10.473 C 6.115 9.740,4.762 9.130,4.761 9.117 C 4.760 9.099,18.077 4.413,18.160 4.403 C 18.171 4.401,16.514 6.066,14.477 8.103 M19.425 6.330 C 19.330 6.600,18.274 9.615,17.079 13.031 C 15.884 16.447,14.892 19.228,14.875 19.211 C 14.858 19.194,14.249 17.840,13.521 16.202 L 12.197 13.223 15.888 9.532 C 17.919 7.501,19.584 5.840,19.589 5.840 C 19.594 5.840,19.520 6.060,19.425 6.330 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Send.displayName = 'SendIcon';
