import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Play: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.44.34-1.308 1.21-1.65 1.651-1.145 1.479-1.813 3.078-2.078 4.975-.065.465-.065 1.975 0 2.44.223 1.596.73 2.976 1.558 4.24 1.419 2.169 3.642 3.721 6.13 4.281.832.187 1.288.236 2.22.237.894.001 1.252-.032 2.02-.183 2.57-.508 4.947-2.129 6.385-4.355.806-1.247 1.282-2.525 1.52-4.08.088-.562.1-2.033.023-2.58-.27-1.921-.96-3.558-2.106-5-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.993 7.993 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.69-9.3 7.491 7.491 0 011.46-2.073c1.694-1.742 4.143-2.639 6.517-2.386zm-2.308 3.9c-.51.142-1.034.565-1.264 1.02-.212.417-.22.51-.22 3.027 0 1.569.015 2.397.045 2.54.16.76.794 1.398 1.543 1.554.32.066.82.029 1.11-.082.314-.121 3.75-2.411 3.95-2.632a2.13 2.13 0 00.433-.708c.133-.344.134-.975 0-1.32a2.232 2.232 0 00-.524-.798c-.28-.253-3.608-2.445-3.86-2.543-.305-.118-.896-.146-1.213-.058zm2.206 3.053c.808.538 1.47.991 1.47 1.006 0 .02-2.18 1.49-3.05 2.058l-.11.071V9.871l.11.071 1.58 1.052z"
      fill="currentColor"
    />
  </Icon>
);

Play.displayName = 'PlayIcon';
