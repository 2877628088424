import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DocumentDownload: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62 2.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.327-.084 15.031 0 15.357.281 1.099 1.043 1.878 2.184 2.238.177.055.818.064 5.56.075 3.614.008 5.449-.001 5.633-.03a3.034 3.034 0 002.306-1.8c.233-.551.218-.175.231-5.755.008-3.423-.002-5.211-.029-5.36a2.74 2.74 0 00-.183-.525c-.138-.291-.226-.384-3.07-3.228-2.846-2.846-2.936-2.932-3.228-3.069a2.665 2.665 0 00-.515-.182c-.204-.039-6.155-.053-6.345-.016zm8.67 4.685L18 9.42v4.863c0 5.466.02 5.099-.299 5.418-.32.32.082.299-5.701.299-5.784 0-5.381.021-5.702-.3-.324-.324-.3.328-.288-7.782L6.02 4.7l.111-.189c.125-.213.318-.375.539-.454.12-.043.729-.054 3.03-.055L12.58 4l2.71 2.71zm-3.647 2.359a1.066 1.066 0 00-.577.591c-.058.17-.066.428-.066 2.055v1.86l-.73-.721c-.618-.611-.76-.732-.927-.788-.388-.13-.765-.046-1.044.233-.278.278-.363.655-.234 1.044.059.176.237.369 1.69 1.828 1.141 1.145 1.677 1.657 1.801 1.717.245.118.51.136.77.052.199-.065.326-.181 1.845-1.695 1.711-1.704 1.784-1.791 1.82-2.167C16.044 12.523 15.563 12 15 12c-.364 0-.496.088-1.27.854l-.73.721v-1.86c0-2.11-.001-2.118-.299-2.416a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

DocumentDownload.displayName = 'DocumentDownloadIcon';
