import { FC } from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/react';

export interface CostLabelProps {
  leadingText?: string;
  leadingProps?: TextProps;
  text: string;
  textProps?: TextProps;
  trailingText?: string;
  trailingProps?: TextProps;
  secondLineText?: string;
  secondLineProps?: TextProps;
}

export const CostLabel: FC<CostLabelProps> = ({
  leadingText,
  leadingProps,
  text,
  textProps,
  trailingText,
  trailingProps,
  secondLineText,
  secondLineProps,
}) => {
  return (
    <Flex flexDirection="column" alignSelf="flex-start" alignItems="center">
      <Flex alignSelf="flex-start" alignItems="center">
        {leadingText && (
          <Text fontSize="2xs" {...leadingProps}>
            {leadingText}
          </Text>
        )}
        <Text fontSize="md" fontWeight="medium" mr={0.5} {...textProps}>
          {text}
        </Text>
        {trailingText && (
          <Text fontSize="xs" pt={0.5} {...trailingProps}>
            {trailingText}
          </Text>
        )}
      </Flex>
      {secondLineText && (
        <Text fontSize="xs" pt={0.5} {...secondLineProps}>
          {secondLineText}
        </Text>
      )}
    </Flex>
  );
};
