import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PieChart2: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.643 1.066 C 11.546 1.102,11.391 1.207,11.299 1.299 C 10.977 1.621,10.997 1.210,11.009 7.082 L 11.020 12.300 11.131 12.489 C 11.192 12.592,11.301 12.723,11.374 12.778 C 11.680 13.011,11.390 13.000,16.998 13.000 C 22.783 13.000,22.381 13.021,22.701 12.701 C 22.997 12.406,23.051 12.057,22.959 11.060 C 22.756 8.853,21.969 6.893,20.568 5.100 C 20.223 4.659,19.142 3.611,18.670 3.260 C 16.770 1.847,14.689 1.102,12.418 1.021 C 11.926 1.004,11.789 1.012,11.643 1.066 M7.560 1.935 C 6.332 2.449,4.981 3.412,3.959 4.500 C 1.979 6.608,0.917 9.433,1.012 12.339 C 1.173 17.241,4.532 21.435,9.263 22.642 C 10.297 22.906,10.869 22.975,12.000 22.975 C 13.084 22.974,13.657 22.909,14.581 22.683 C 17.258 22.029,19.542 20.445,21.101 18.163 C 21.724 17.251,22.198 16.269,22.199 15.889 C 22.201 15.352,21.771 14.909,21.243 14.906 C 20.726 14.902,20.488 15.094,20.157 15.780 C 19.644 16.844,19.166 17.519,18.343 18.345 C 17.510 19.180,16.727 19.729,15.720 20.183 C 13.603 21.138,11.349 21.251,9.126 20.512 C 7.273 19.897,5.608 18.626,4.536 17.010 C 2.698 14.238,2.504 10.741,4.026 7.840 C 4.933 6.111,6.233 4.846,8.069 3.906 C 8.721 3.571,8.832 3.474,8.948 3.135 C 9.025 2.910,8.999 2.593,8.887 2.375 C 8.644 1.903,8.082 1.717,7.560 1.935 M13.735 3.178 C 16.084 3.654,18.044 4.940,19.409 6.900 C 19.898 7.602,20.387 8.621,20.619 9.421 C 20.745 9.853,20.920 10.701,20.920 10.875 L 20.920 11.000 16.960 11.000 L 13.000 11.000 13.000 7.040 L 13.000 3.080 13.125 3.080 C 13.194 3.080,13.468 3.124,13.735 3.178 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

PieChart2.displayName = 'PieChart2Icon';
