import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Eye: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.02 4.041A11.134 11.134 0 005.2 6.354c-.441.339-1.512 1.41-1.847 1.846a11.687 11.687 0 00-1.412 2.349c-.233.532-.501 1.308-.501 1.451 0 .143.268.919.5 1.451.989 2.256 2.844 4.211 5.074 5.347 1.155.589 2.379.962 3.706 1.129.613.077 2.137.065 2.74-.022a11.077 11.077 0 005.177-2.135c.525-.393 1.572-1.415 1.977-1.929a11.254 11.254 0 001.768-3.208c.189-.541.189-.725 0-1.266A11.214 11.214 0 0020.647 8.2c-.388-.505-1.473-1.569-2.01-1.97A10.987 10.987 0 0013.6 4.118c-.621-.094-2.004-.135-2.58-.077zm1.82 2.001c3.334.305 6.256 2.491 7.523 5.628l.133.33-.133.33c-1.43 3.541-4.921 5.81-8.703 5.656a8.714 8.714 0 01-3.6-.904 9.04 9.04 0 01-4.423-4.752L3.504 12l.133-.33A9.035 9.035 0 018.06 6.918a8.927 8.927 0 014.78-.876zm-1.53 2.021a3.917 3.917 0 00-2.131 1.116 3.903 3.903 0 00-1.165 3.001c.096 1.833 1.332 3.307 3.126 3.725.464.109 1.319.102 1.78-.014a4.03 4.03 0 002.97-2.971c.117-.461.124-1.316.015-1.78-.49-2.102-2.51-3.454-4.595-3.077zm1.098 1.981C13.31 10.232 14 11.08 14 12c0 1.08-.92 2-2 2s-2-.92-2-2c0-.898.657-1.731 1.534-1.943.263-.064.604-.069.874-.013z"
      fill="currentColor"
    />
  </Icon>
);

Eye.displayName = 'EyeIcon';
