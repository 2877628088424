import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowLeftNarrow: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.64 7.068c-.154.053-.488.369-2.311 2.187-1.516 1.511-2.156 2.176-2.217 2.301a1.018 1.018 0 00-.052.77c.065.201.198.342 2.195 2.345 1.511 1.516 2.176 2.156 2.301 2.217a.99.99 0 001.144-.186c.28-.279.364-.656.234-1.045-.057-.174-.21-.342-1.288-1.427L5.423 13h7.861c8.825 0 8.092.026 8.417-.299a.984.984 0 000-1.402c-.325-.325.408-.299-8.417-.299H5.423l1.223-1.23c1.078-1.085 1.231-1.253 1.288-1.427.13-.389.045-.766-.233-1.044a1.005 1.005 0 00-1.061-.231z"
      fill="currentColor"
    />
  </Icon>
);

ArrowLeftNarrow.displayName = 'ArrowLeftNarrowIcon';
