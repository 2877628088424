import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Inbox: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62 3.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.326-.084 13.031 0 13.357.281 1.099 1.042 1.877 2.184 2.238.178.056.9.064 6.56.075 4.306.008 6.448-.001 6.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.327.084-13.033 0-13.357-.307-1.197-1.264-2.09-2.444-2.28-.246-.04-12.661-.057-12.86-.018zm12.72 2.041c.115.039.263.135.361.233.311.311.299.165.299 3.628V12h-.875c-.981 0-1.179.026-1.583.208-.263.119-.373.218-1.624 1.46L13.576 15h-3.152l-1.342-1.332C7.349 11.947 7.45 12 5.874 12h-.876l.011-3.15.011-3.15.111-.189c.125-.213.318-.375.539-.454.123-.044 1.269-.054 6.313-.055 5.634-.002 6.179.004 6.357.064zM7.899 15.316c1.112 1.107 1.36 1.335 1.571 1.44.464.232.573.243 2.515.243 1.007.001 1.857-.016 1.98-.039.118-.022.35-.105.515-.185.278-.135.396-.242 1.621-1.46L17.423 14H19v2.073c0 2.346.002 2.327-.299 2.628-.322.322.202.299-6.701.299s-6.379.023-6.701-.299C4.998 18.4 5 18.419 5 16.073V14h1.577l1.322 1.316z"
      fill="currentColor"
    />
  </Icon>
);

Inbox.displayName = 'InboxIcon';
