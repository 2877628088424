import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronDoubleDown: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.643 4.069c-.53.187-.786.849-.53 1.375.061.127 1.087 1.176 3.716 3.801 3.321 3.316 3.648 3.631 3.828 3.69a.987.987 0 00.686 0c.18-.059.507-.374 3.828-3.69 2.63-2.625 3.655-3.674 3.716-3.801a.986.986 0 00-.185-1.144c-.279-.279-.655-.364-1.045-.235-.18.059-.479.347-3.427 3.289L12 10.579 8.77 7.354c-2.948-2.942-3.247-3.23-3.427-3.289a1.038 1.038 0 00-.7.004zm0 8c-.53.187-.786.849-.53 1.375.061.127 1.087 1.176 3.716 3.801 3.321 3.316 3.648 3.631 3.828 3.69a.987.987 0 00.686 0c.18-.059.507-.374 3.828-3.69 2.63-2.625 3.655-3.674 3.716-3.801a.986.986 0 00-.185-1.144c-.279-.279-.655-.364-1.045-.235-.18.059-.479.347-3.427 3.289L12 18.579l-3.23-3.225c-2.948-2.942-3.247-3.23-3.427-3.289a1.038 1.038 0 00-.7.004z"
      fill="currentColor"
    />
  </Icon>
);

ChevronDoubleDown.displayName = 'ChevronDoubleDownIcon';
