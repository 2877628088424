import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserMove: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M8.5 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm-2.036 8.464A5 5 0 0 1 5 14h7a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 1.464-3.536Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M18.648 7.408c.273-.273.777-.365 1.113-.203.147.07.424.334 2.055 1.961 2.127 2.123 2.066 2.05 2.066 2.485.001.435.06.363-2.052 2.475-2.111 2.111-2.04 2.053-2.475 2.052a.984.984 0 0 1-.991-.992c0-.405.045-.467 1.144-1.566l.963-.963-3.15-.005c-2.848-.004-3.166-.013-3.334-.097-.367-.185-.573-.51-.573-.904 0-.393.206-.72.573-.904.168-.084.486-.093 3.335-.097l3.15-.005-.964-.963c-.822-.822-.976-.99-1.053-1.147-.172-.35-.087-.847.193-1.127Z"
    />
  </Icon>
);

UserMove.displayName = 'UserMoveIcon';
