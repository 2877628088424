import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Terminal2: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M3.095 1.579 C 2.089 1.799,1.250 2.561,0.892 3.580 L 0.780 3.900 0.780 12.000 L 0.780 20.100 0.892 20.420 C 1.060 20.897,1.290 21.258,1.657 21.620 C 2.182 22.137,2.722 22.394,3.471 22.479 C 3.947 22.534,20.053 22.534,20.529 22.479 C 21.278 22.394,21.818 22.137,22.343 21.620 C 22.710 21.258,22.941 20.896,23.108 20.420 L 23.220 20.100 23.220 12.000 L 23.220 3.900 23.108 3.580 C 22.789 2.671,22.069 1.952,21.171 1.646 L 20.860 1.540 12.100 1.533 C 4.904 1.527,3.296 1.535,3.095 1.579 M20.849 3.127 C 21.311 3.344,21.640 3.772,21.721 4.262 C 21.773 4.585,21.773 19.415,21.721 19.738 C 21.640 20.228,21.311 20.656,20.849 20.873 L 20.620 20.980 12.000 20.980 L 3.380 20.980 3.151 20.873 C 2.689 20.656,2.360 20.228,2.279 19.738 C 2.227 19.418,2.227 4.585,2.279 4.265 C 2.374 3.687,2.803 3.205,3.357 3.054 C 3.500 3.016,5.397 3.007,12.080 3.012 L 20.620 3.020 20.849 3.127 M5.163 5.588 C 5.087 5.628,4.971 5.728,4.905 5.811 C 4.805 5.936,4.781 6.008,4.769 6.211 C 4.756 6.431,4.768 6.480,4.867 6.629 C 4.942 6.741,5.496 7.210,6.514 8.023 C 7.358 8.696,8.033 9.261,8.014 9.278 C 7.995 9.295,7.303 9.850,6.475 10.511 C 5.648 11.172,4.929 11.771,4.878 11.843 C 4.660 12.149,4.763 12.655,5.087 12.870 C 5.295 13.007,5.605 13.034,5.800 12.932 C 5.954 12.851,9.686 9.870,9.827 9.716 C 10.014 9.512,10.050 9.214,9.922 8.933 C 9.858 8.791,9.562 8.538,7.953 7.252 C 6.912 6.419,5.998 5.694,5.922 5.640 C 5.737 5.510,5.361 5.484,5.163 5.588 M9.595 11.635 C 9.096 11.951,9.154 12.678,9.698 12.925 C 9.847 12.992,9.994 12.998,11.500 12.998 C 13.006 12.998,13.153 12.992,13.302 12.925 C 13.846 12.678,13.904 11.951,13.405 11.635 L 13.224 11.520 11.500 11.520 L 9.776 11.520 9.595 11.635 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Terminal2.displayName = 'Terminal2Icon';
