import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserMoveAlt2: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M8.5 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm-2.036 8.464A5 5 0 0 1 5 14h7a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 1.464-3.536Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M22 11.994c0 .386-.291.807-.643.931-.153.054-.536.063-2.84.066-3.005.003-2.91.012-3.218-.296-.308-.307-.299-.215-.299-3.201s-.009-2.894.299-3.201a.984.984 0 0 1 1.402 0c.287.287.299.362.299 1.917v1.362l2.23-2.224c2.017-2.011 2.249-2.23 2.427-2.289.39-.129.766-.044 1.044.234s.363.654.234 1.044c-.059.178-.278.41-2.289 2.427l-2.224 2.23h1.362c1.163 0 1.39.01 1.556.066.369.126.66.538.66.934Z"
    />
  </Icon>
);

UserMoveAlt2.displayName = 'UserMoveAlt2Icon';
