import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Umbrella: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.002 1.045 C 8.105 1.283,5.369 2.585,3.328 4.699 C 1.669 6.417,0.572 8.560,0.144 10.920 C -0.062 12.053,-0.034 12.368,0.298 12.700 C 0.619 13.021,0.224 13.000,5.927 13.000 L 11.000 13.000 11.000 16.184 C 11.000 19.225,11.004 19.385,11.081 19.765 C 11.413 21.392,12.608 22.587,14.235 22.919 C 16.383 23.357,18.474 21.949,18.919 19.765 C 19.070 19.025,19.007 18.605,18.701 18.299 C 18.239 17.837,17.469 17.935,17.139 18.499 C 17.060 18.633,17.026 18.767,16.996 19.061 C 16.895 20.049,16.356 20.709,15.462 20.939 C 14.490 21.188,13.515 20.657,13.116 19.660 L 13.020 19.420 13.009 16.210 L 12.997 13.000 18.072 13.000 C 23.776 13.000,23.381 13.021,23.702 12.700 C 24.035 12.368,24.062 12.052,23.856 10.920 C 23.413 8.490,22.288 6.330,20.561 4.596 C 19.919 3.951,19.455 3.559,18.800 3.110 C 16.547 1.562,13.746 0.820,11.002 1.045 M13.240 3.080 C 15.510 3.374,17.552 4.386,19.149 6.005 C 19.687 6.551,19.943 6.865,20.363 7.494 C 20.981 8.423,21.463 9.501,21.724 10.540 C 21.768 10.716,21.813 10.892,21.825 10.930 C 21.844 10.997,21.358 11.000,12.000 11.000 C 2.642 11.000,2.156 10.997,2.175 10.930 C 2.187 10.892,2.208 10.806,2.223 10.740 C 2.509 9.437,3.279 7.862,4.178 6.740 C 4.510 6.324,5.238 5.600,5.677 5.246 C 7.160 4.054,8.995 3.278,10.800 3.081 C 11.009 3.059,11.243 3.033,11.320 3.024 C 11.563 2.995,12.886 3.034,13.240 3.080 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Umbrella.displayName = 'UmbrellaIcon';
