import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Edit3: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M17.513 1.918 C 16.987 2.015,16.509 2.222,16.100 2.533 C 15.968 2.633,12.995 5.583,9.494 9.088 C 3.539 15.050,3.125 15.473,3.066 15.660 C 2.928 16.097,2.000 19.887,2.000 20.012 C 2.000 20.521,2.479 21.000,2.988 21.000 C 3.113 21.000,6.903 20.072,7.340 19.934 C 7.527 19.875,7.950 19.461,13.912 13.506 C 17.417 10.005,20.367 7.032,20.467 6.900 C 20.704 6.587,20.898 6.199,21.009 5.812 C 21.136 5.369,21.136 4.631,21.009 4.188 C 20.700 3.115,19.890 2.298,18.839 2.001 C 18.504 1.906,17.813 1.863,17.513 1.918 M18.365 3.949 C 18.518 3.997,18.639 4.076,18.782 4.219 C 19.135 4.571,19.213 5.064,18.986 5.505 C 18.910 5.653,17.383 7.201,12.673 11.905 L 6.460 18.110 5.449 18.360 C 4.892 18.498,4.426 18.600,4.414 18.587 C 4.401 18.574,4.504 18.104,4.644 17.542 L 4.898 16.521 11.159 10.267 C 17.006 4.426,17.433 4.008,17.620 3.948 C 17.884 3.865,18.103 3.865,18.365 3.949 M11.664 19.063 C 11.436 19.146,11.257 19.297,11.131 19.511 C 11.036 19.672,11.020 19.744,11.020 20.000 C 11.020 20.256,11.036 20.328,11.131 20.489 C 11.192 20.592,11.301 20.723,11.374 20.778 C 11.679 21.010,11.437 21.000,16.498 21.000 C 21.723 21.000,21.383 21.020,21.701 20.701 C 22.092 20.310,22.092 19.690,21.701 19.299 C 21.382 18.980,21.726 19.000,16.483 19.003 C 12.541 19.005,11.796 19.014,11.664 19.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Edit3.displayName = 'Edit3Icon';
