import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PresentationChartLine: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.643 3.07C2.291 3.192 2 3.613 2 4c0 .396.291.808.66.934.107.036.227.066.267.066.07 0 .073.21.073 5.598 0 3.932.013 5.661.044 5.81.157.75.798 1.391 1.548 1.548.141.03 1.011.044 2.599.044h2.386l-1.223 1.23c-1.078 1.085-1.231 1.253-1.288 1.427-.13.39-.045.766.233 1.044s.655.363 1.044.234c.177-.059.376-.244 1.927-1.789L12 18.422l1.73 1.724c1.551 1.545 1.75 1.73 1.927 1.79.389.128.766.043 1.044-.235.278-.278.363-.655.233-1.044-.057-.174-.21-.342-1.288-1.427L14.423 18h2.386c1.588 0 2.458-.015 2.599-.044a2.062 2.062 0 001.548-1.548c.031-.149.044-1.878.044-5.81C21 5.21 21.003 5 21.073 5c.173 0 .471-.142.628-.299a.984.984 0 000-1.402c-.326-.327.567-.299-9.718-.296-8.192.003-9.182.01-9.34.066zM19 10.5V16H5V5h14v5.5zm-2.36-3.432c-.153.053-.442.323-1.91 1.786L13 10.577l-1.23-1.223c-1.131-1.125-1.247-1.229-1.444-1.294a1.018 1.018 0 00-.77.052c-.124.06-.66.572-1.801 1.717-1.453 1.46-1.631 1.652-1.69 1.828-.129.39-.044.766.234 1.044s.655.363 1.044.233c.174-.057.342-.21 1.427-1.288L10 10.423l1.23 1.223c1.131 1.125 1.247 1.23 1.444 1.294.26.084.525.066.77-.052.125-.06.79-.7 2.301-2.217 1.919-1.924 2.131-2.15 2.19-2.328.129-.39.044-.766-.234-1.044a1.005 1.005 0 00-1.061-.23z"
      fill="currentColor"
    />
  </Icon>
);

PresentationChartLine.displayName = 'PresentationChartLineIcon';
