import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.340 2.046 C 10.849 2.108,10.317 2.273,9.820 2.517 C 8.374 3.226,7.390 4.510,7.075 6.100 C 7.002 6.468,7.002 7.532,7.075 7.900 C 7.482 9.953,9.047 11.518,11.100 11.925 C 11.468 11.998,12.532 11.998,12.900 11.925 C 14.723 11.564,16.191 10.273,16.753 8.539 C 16.940 7.961,16.980 7.680,16.978 6.960 C 16.976 6.381,16.963 6.247,16.869 5.869 C 16.678 5.104,16.374 4.473,15.926 3.912 C 14.822 2.527,13.090 1.823,11.340 2.046 M12.756 4.097 C 13.093 4.189,13.595 4.447,13.873 4.671 C 14.554 5.219,15.000 6.140,15.000 7.000 C 15.000 7.802,14.587 8.711,13.980 9.246 C 12.866 10.226,11.193 10.249,10.080 9.297 C 9.624 8.907,9.214 8.247,9.075 7.680 C 9.006 7.398,9.005 6.605,9.074 6.320 C 9.154 5.989,9.489 5.329,9.702 5.080 C 10.159 4.549,10.828 4.162,11.503 4.040 C 11.770 3.991,12.491 4.024,12.756 4.097 M7.340 14.046 C 6.354 14.172,5.310 14.671,4.574 15.371 C 3.848 16.061,3.392 16.844,3.134 17.848 C 3.030 18.248,3.027 18.302,3.010 19.683 C 2.992 21.243,3.001 21.330,3.211 21.606 C 3.649 22.179,4.570 22.093,4.886 21.450 C 4.977 21.265,4.981 21.222,5.004 19.860 C 5.023 18.790,5.043 18.413,5.088 18.260 C 5.371 17.317,6.003 16.593,6.860 16.233 C 7.386 16.012,7.188 16.020,12.000 16.020 C 16.949 16.020,16.632 16.003,17.260 16.298 C 18.054 16.670,18.593 17.296,18.882 18.180 C 18.968 18.444,18.975 18.543,18.997 19.880 L 19.020 21.300 19.131 21.489 C 19.470 22.065,20.234 22.168,20.702 21.700 C 20.999 21.403,21.009 21.332,20.990 19.689 C 20.973 18.301,20.970 18.248,20.866 17.848 C 20.608 16.844,20.152 16.061,19.426 15.371 C 18.669 14.652,17.644 14.169,16.601 14.042 C 16.181 13.990,7.747 13.994,7.340 14.046 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

UserAlt.displayName = 'UserAltIcon';
