import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Search2: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M9.247 2.043 C 6.459 2.312,3.969 4.059,2.773 6.585 C 2.175 7.847,1.885 9.514,2.041 10.801 C 2.383 13.627,4.010 15.956,6.492 17.172 C 9.079 18.439,12.110 18.223,14.500 16.602 L 14.900 16.331 17.520 18.937 C 18.961 20.370,20.242 21.628,20.366 21.733 C 20.747 22.053,21.182 22.082,21.558 21.811 C 21.898 21.567,22.052 21.176,21.964 20.782 C 21.917 20.574,21.877 20.523,21.221 19.831 C 20.840 19.429,19.583 18.157,18.428 17.004 L 16.328 14.908 16.628 14.464 C 18.261 12.046,18.435 8.861,17.074 6.285 C 15.563 3.425,12.444 1.734,9.247 2.043 M10.360 4.040 C 11.300 4.104,11.862 4.251,12.650 4.640 C 13.210 4.917,13.681 5.246,14.127 5.674 C 14.950 6.462,15.503 7.407,15.826 8.580 C 15.932 8.965,15.936 9.017,15.938 9.960 C 15.940 11.026,15.927 11.125,15.696 11.840 C 15.238 13.259,14.204 14.515,12.902 15.237 C 12.436 15.495,11.785 15.740,11.222 15.870 C 10.831 15.960,10.692 15.971,10.020 15.971 C 9.156 15.970,8.756 15.907,8.037 15.657 C 7.145 15.347,6.478 14.923,5.777 14.223 C 5.057 13.502,4.582 12.728,4.284 11.790 C 4.093 11.189,4.040 10.798,4.040 10.000 C 4.040 9.179,4.097 8.779,4.302 8.165 C 4.954 6.215,6.510 4.736,8.453 4.219 C 8.834 4.117,9.436 4.011,9.660 4.004 C 9.726 4.003,10.041 4.019,10.360 4.040 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Search2.displayName = 'Search2Icon';
