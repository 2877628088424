import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Divide: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.503 3.040 C 10.828 3.162,10.156 3.550,9.703 4.080 C 8.987 4.917,8.806 6.100,9.234 7.151 C 9.521 7.857,10.143 8.479,10.849 8.766 C 11.989 9.230,13.251 8.978,14.115 8.115 C 15.238 6.992,15.295 5.212,14.246 4.020 C 13.897 3.624,13.276 3.239,12.756 3.097 C 12.491 3.024,11.770 2.991,11.503 3.040 M12.340 5.066 C 12.709 5.192,13.000 5.604,13.000 6.000 C 13.000 6.527,12.527 7.000,12.000 7.000 C 11.758 7.000,11.479 6.881,11.299 6.701 C 10.907 6.309,10.908 5.690,11.300 5.298 C 11.577 5.020,11.957 4.936,12.340 5.066 M4.664 11.063 C 4.436 11.146,4.257 11.297,4.131 11.511 C 4.036 11.672,4.020 11.744,4.020 12.000 C 4.020 12.256,4.036 12.328,4.131 12.489 C 4.192 12.592,4.301 12.723,4.374 12.778 C 4.683 13.014,4.201 13.000,11.998 13.000 C 20.023 13.000,19.377 13.025,19.701 12.701 C 20.092 12.310,20.092 11.690,19.701 11.299 C 19.377 10.975,20.026 11.000,11.983 11.003 C 5.871 11.005,4.797 11.014,4.664 11.063 M11.503 15.040 C 11.202 15.094,10.930 15.192,10.596 15.365 C 9.231 16.073,8.644 17.703,9.234 19.151 C 9.521 19.857,10.143 20.479,10.849 20.766 C 11.989 21.230,13.251 20.978,14.115 20.115 C 15.238 18.992,15.295 17.212,14.246 16.020 C 13.897 15.624,13.276 15.239,12.756 15.097 C 12.491 15.024,11.770 14.991,11.503 15.040 M12.340 17.066 C 12.709 17.192,13.000 17.604,13.000 18.000 C 13.000 18.527,12.527 19.000,12.000 19.000 C 11.758 19.000,11.479 18.881,11.299 18.701 C 10.907 18.309,10.908 17.690,11.300 17.298 C 11.577 17.020,11.957 16.936,12.340 17.066 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Divide.displayName = 'DivideIcon';
