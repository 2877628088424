import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const InformationCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.223 1.596.73 2.976 1.558 4.24 1.419 2.169 3.642 3.721 6.13 4.281.832.187 1.288.236 2.22.237.894.001 1.252-.032 2.02-.183 2.571-.508 4.947-2.129 6.385-4.355.806-1.247 1.282-2.525 1.521-4.08.087-.562.099-2.033.022-2.58-.27-1.921-.96-3.558-2.106-5-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.993 7.993 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.689-9.3 7.491 7.491 0 011.46-2.073c1.693-1.742 4.142-2.639 6.516-2.386zm-1.149 3.025C11.288 7.197 11 7.615 11 8c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm-1 4.003c-.352.124-.643.545-.643.931 0 .396.291.808.66.934.107.036.227.066.267.066.069 0 .073.078.073 1.573 0 1.789.006 1.836.299 2.128.254.254.435.299 1.201.299s.947-.045 1.201-.299a.984.984 0 000-1.402c-.157-.157-.455-.299-.628-.299-.069 0-.073-.078-.073-1.573 0-1.789-.006-1.836-.299-2.128-.256-.257-.434-.3-1.218-.296-.492.002-.709.019-.84.066z"
      fill="currentColor"
    />
  </Icon>
);

InformationCircle.displayName = 'InformationCircleIcon';
