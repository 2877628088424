import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Bell: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.62 2.025c-.408.08-.533.114-.75.202a3.033 3.033 0 00-1.802 2.168c-.053.264-.054.265-.271.38-1.569.831-2.707 2.109-3.342 3.753a8.84 8.84 0 00-.379 1.392c-.032.169-.057 1.01-.072 2.4-.022 1.992-.029 2.15-.099 2.28-.042.077-.457.527-.924 1-.727.737-.858.888-.915 1.057-.13.388-.046.765.233 1.044.301.301.283.299 2.622.299h2.067l.026.3c.15 1.775 1.384 3.199 3.126 3.605.464.109 1.319.102 1.78-.014.475-.12.864-.29 1.271-.555a4.018 4.018 0 001.794-3.026l.027-.31h2.067c2.339 0 2.321.002 2.622-.299.279-.279.363-.656.233-1.044-.057-.169-.188-.32-.915-1.057-.467-.473-.882-.923-.924-1-.07-.13-.077-.288-.099-2.28-.024-2.259-.028-2.307-.236-3.12-.461-1.804-1.735-3.427-3.407-4.34l-.367-.2-.052-.259a3.027 3.027 0 00-2.454-2.358c-.252-.041-.697-.05-.86-.018zm.705 2.036c.246.084.45.262.56.489.072.148.098.288.117.63.039.695.192.905.858 1.182.728.303 1.222.64 1.751 1.196.657.689 1.068 1.468 1.292 2.442.058.255.073.632.096 2.5.02 1.554.042 2.247.077 2.36.114.373.253.696.384.894.077.117.14.22.14.229 0 .01-2.52.017-5.6.017-3.08 0-5.6-.008-5.6-.018 0-.01.055-.1.123-.2.13-.193.288-.551.389-.882.049-.163.066-.605.089-2.4.023-1.753.041-2.258.091-2.483.367-1.683 1.466-2.997 3.061-3.66.658-.274.805-.483.847-1.202.024-.405.041-.49.134-.649a.988.988 0 011.191-.445zm1.66 14.162c-.1.846-.75 1.56-1.577 1.733a2.01 2.01 0 01-2.393-1.733L9.989 18h4.022l-.026.223z"
      fill="currentColor"
    />
  </Icon>
);

Bell.displayName = 'BellIcon';
