import { FC } from 'react';
import { BoxProps, Flex, Spinner, Text } from '@chakra-ui/react';
import { Icon } from 'icons';
import { Shape } from 'illustrations';

interface TileButton {
  text: string;
  icon?: string;
  shape?: string;
  shapeColor?: string;
  shapeTransform?: string;
  shapeOpacity?: string;
  backgroundColor?: string;
  iconColor?: string;
  textColor?: string;
  textSize?: string;
  isProcessing?: boolean;
  size?: 'md' | 'sm';
  containerProps?: BoxProps;
  onClick?: () => void;
}

const stylesBySize = {
  md: {
    container: {
      minWidth: { base: 36, md: 44 },
      maxWidth: { base: 36, md: 44 },
    },
    wrapper: {
      pt: 4,
      pb: 6,
      px: 3,
    },
    iconAndShape: {
      h: { base: 16, md: 20 },
      w: { base: 16, md: 20 },
    },
    shape: {
      h: { base: 16, md: 20 },
      w: { base: 16, md: 20 },
    },
    icon: {
      h: { base: 10, md: 12 },
      w: { base: 10, md: 12 },
    },
  },
  sm: {
    container: {
      minWidth: { base: 16, md: 20 },
      maxWidth: { base: 16, md: 20 },
    },
    wrapper: {
      pt: 4,
      pb: 2,
      px: 4,
    },
    iconAndShape: {
      h: { base: 6, md: 8 },
      w: { base: 10, md: 12 },
    },
    shape: {
      h: { base: 10, md: 12 },
      w: { base: 10, md: 12 },
    },
    icon: {
      h: { base: 5, md: 8 },
      w: { base: 5, md: 8 },
    },
  },
};

export const TileButton: FC<TileButton> = ({
  text,
  textColor,
  textSize,
  icon,
  iconColor,
  shape,
  shapeColor,
  shapeTransform,
  shapeOpacity,
  backgroundColor,
  size = 'md',
  containerProps,
  isProcessing,
  onClick,
}) => {
  return (
    <Flex
      direction="column"
      bg={backgroundColor ? backgroundColor : 'main.white'}
      shadow="sm"
      borderRadius="lg"
      aria-roledescription="button"
      position="relative"
      cursor="pointer"
      transition="all 300ms ease-in-out"
      _after={{
        content: "' '",
        position: 'absolute',
        zIndex: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        shadow: 'lg',
        transition: 'opacity 300ms ease-in-out',
        borderRadius: 'lg',
      }}
      _hover={{ _after: { opacity: 1 } }}
      onClick={onClick}
      {...containerProps}
      {...stylesBySize[size].container}
    >
      <Flex
        width="100%"
        height="100%"
        position="relative"
        zIndex={2}
        bg={backgroundColor ? backgroundColor : 'main.white'}
        direction="column"
        borderRadius="lg"
        {...stylesBySize[size].wrapper}
      >
        <Flex
          width="100%"
          height="100%"
          direction="column"
          alignItems="center"
          position="relative"
          alignSelf="center"
          justify="center"
          {...stylesBySize[size].iconAndShape}
        >
          {isProcessing && (
            <Spinner
              alignSelf="center"
              color={iconColor ? iconColor : 'main.concrete'}
              w={5}
              h={5}
            />
          )}
          {!isProcessing && (
            <>
              <Shape
                position="absolute"
                zIndex={1}
                top={0}
                bottom={0}
                left={0}
                right={0}
                name={shape ? shape : 'blob'}
                color={shapeColor ? shapeColor : 'navy.50'}
                transform={shapeTransform}
                opacity={shapeOpacity}
                alignSelf="center"
                {...stylesBySize[size].shape}
              />
              <Icon
                name={icon ? icon : 'calendar'}
                color={iconColor ? iconColor : 'main.concrete'}
                position="relative"
                zIndex={2}
                {...stylesBySize[size].icon}
              />
            </>
          )}
        </Flex>
        {!isProcessing && (
          <Flex
            width="100%"
            height="100%"
            direction="column"
            position="relative"
            zIndex={2}
          >
            <Text
              color={textColor ? textColor : 'main.navy'}
              fontSize={textSize ? textSize : { base: 'md', md: 'lg' }}
              fontWeight="semi"
              align="center"
            >
              {text}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
