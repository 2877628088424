import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillApproachable: FC<IconProps> = props => (
  <Icon {...props}>
    <g clipPath="url(#clip0)" fill="currentColor">
      <path d="M10.999 15.061a.391.391 0 10-.555-.553.391.391 0 00.555.553z" />
      <path d="M6.54 21.177a.39.39 0 00.552 0l1.2-1.2c.39-.39.578-.925.524-1.465l.43-.415c.342-.33.79-.51 1.264-.51h5.143a3.496 3.496 0 002.48-1.027l3.499-4.202a1.565 1.565 0 00-.186-2.203 1.567 1.567 0 00-2.193.175l-.377.391V3.094a.39.39 0 00-.391-.39H8.445a.39.39 0 00-.39.39v8.752c-1.513.317-2.713 1.256-3.359 2.629a1.804 1.804 0 00-1.375.53l-1.206 1.2a.391.391 0 000 .553l4.425 4.42zM18.094 3.484v8.045l-1.13 1.169a1.57 1.57 0 00-1.214-.58h-4.529a.216.216 0 01-.08-.017 5.048 5.048 0 00-1.914-.374c-.13 0-.261.005-.39.013V3.484h9.257zM8.501 12.56c.79-.114 1.58-.047 2.347.266.122.049.248.074.373.074h4.53a.782.782 0 010 1.563h-3.4a.39.39 0 000 .78h3.4a1.564 1.564 0 001.548-1.767c.082-.083 2.443-2.525 2.524-2.61l.018-.02a.784.784 0 011.101-.092c.33.278.371.772.091 1.103l-3.488 4.188a2.718 2.718 0 01-1.892.761H10.51a2.59 2.59 0 00-1.806.73l-.173.166a1.818 1.818 0 00-.24-.296l-2.399-2.4a1.821 1.821 0 00-.43-.318c.596-1.177 1.662-1.927 3.04-2.127zm-4.628 3a1.036 1.036 0 011.467 0l2.4 2.399c.402.401.409 1.058 0 1.467l-.925.924-3.871-3.867.929-.924z" />
    </g>
    <path
      d="M12.6 9.935c.223.188.453.383.698.595a.389.389 0 00.511 0c.245-.212.475-.407.698-.594 1.243-1.05 2.141-1.808 2.141-2.921 0-1.042-.765-1.859-1.742-1.859-.658 0-1.086.353-1.353.711-.266-.358-.695-.71-1.352-.71-.977 0-1.742.816-1.742 1.858 0 1.113.898 1.871 2.141 2.92zm-.399-3.998c.713 0 .966.851.976.888a.39.39 0 00.375.283h.002a.392.392 0 00.376-.283c.01-.037.263-.888.975-.888.549 0 .962.464.962 1.078 0 .75-.702 1.342-1.864 2.324l-.45.38c-.154-.132-.303-.257-.449-.38-1.162-.982-1.864-1.574-1.864-2.324 0-.614.413-1.077.961-1.077z"
      fill="currentColor"
    />
    <defs>
      <clipPath id="clip0">
        <path fill="transparent" transform="translate(2 2)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </Icon>
);

SkillApproachable.displayName = 'SkillApproachableIcon';
