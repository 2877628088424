import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Cube: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.68 2.055c-.077.03-1.967.968-4.2 2.084-3.266 1.633-4.088 2.06-4.205 2.182C2.983 6.627 3 6.266 3 12c0 5.783-.021 5.384.298 5.697.134.132.96.559 4.295 2.223l4.129 2.06h.556l4.129-2.06c3.335-1.664 4.161-2.091 4.295-2.223.319-.313.298.086.298-5.697s.021-5.384-.298-5.697c-.134-.132-.959-.559-4.289-2.22-3.864-1.928-4.138-2.058-4.358-2.069a1.113 1.113 0 00-.375.041zm3.18 3.495c1.573.786 2.86 1.439 2.86 1.45 0 .011-1.287.664-2.86 1.45L12 9.88 9.14 8.45C7.567 7.664 6.28 7.011 6.28 7c0-.015 5.656-2.866 5.71-2.878.005-.001 1.297.641 2.87 1.428zm-6.84 4.58L11 11.62v3.87c0 2.128-.009 3.87-.02 3.87-.011 0-1.361-.67-3-1.49L5 16.38v-3.87c0-2.128.009-3.87.02-3.87.011 0 1.361.67 3 1.49zM19 12.51v3.87l-2.98 1.49a304.58 304.58 0 01-3 1.49c-.011 0-.02-1.742-.02-3.87v-3.87l2.97-1.488a251.344 251.344 0 013-1.49c.017-.001.03 1.74.03 3.868z"
      fill="currentColor"
    />
  </Icon>
);

Cube.displayName = 'CubeIcon';
