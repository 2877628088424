import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Menu3: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M4.456 5.038 C 3.553 5.062,3.596 5.052,3.326 5.298 C 3.044 5.556,2.933 5.973,3.058 6.301 C 3.096 6.401,3.197 6.570,3.282 6.678 C 3.418 6.850,3.465 6.880,3.668 6.927 C 4.011 7.006,19.989 7.005,20.332 6.927 C 20.542 6.879,20.581 6.852,20.741 6.648 C 21.071 6.228,21.076 5.793,20.756 5.370 C 20.667 5.252,20.546 5.137,20.487 5.114 C 20.428 5.092,20.047 5.057,19.640 5.038 C 18.855 5.001,5.887 5.001,4.456 5.038 M4.456 11.038 C 3.553 11.062,3.596 11.052,3.326 11.298 C 3.044 11.556,2.933 11.973,3.058 12.301 C 3.096 12.401,3.197 12.570,3.282 12.678 C 3.418 12.850,3.465 12.880,3.668 12.927 C 4.011 13.006,19.989 13.005,20.332 12.927 C 20.542 12.879,20.581 12.852,20.741 12.648 C 21.071 12.228,21.076 11.793,20.756 11.370 C 20.667 11.252,20.546 11.137,20.487 11.114 C 20.428 11.092,20.047 11.057,19.640 11.038 C 18.855 11.001,5.887 11.001,4.456 11.038 M4.056 17.035 C 3.763 17.052,3.586 17.080,3.536 17.118 C 3.194 17.378,3.045 17.611,3.011 17.940 C 2.971 18.330,3.283 18.818,3.641 18.926 C 3.905 19.006,11.095 19.006,11.360 18.926 C 11.559 18.866,11.788 18.648,11.906 18.406 C 12.003 18.208,12.002 17.790,11.904 17.590 C 11.799 17.374,11.549 17.133,11.373 17.076 C 11.257 17.038,10.399 17.024,7.860 17.019 C 6.012 17.014,4.300 17.022,4.056 17.035 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Menu3.displayName = 'Menu3Icon';
