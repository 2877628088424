import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CalendarDollar: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 3a1 1 0 1 0-2 0v1H9V3a1 1 0 0 0-2 0v1H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h7a1 1 0 1 0 0-2H6a1 1 0 0 1-1-1v-7h11.5a1 1 0 1 0 0-2H5V7a1 1 0 0 1 1-1h1v1a1 1 0 0 0 2 0V6h6v1a1 1 0 1 0 2 0V6h1a1 1 0 0 1 1 1v3a1 1 0 1 0 2 0V7a3 3 0 0 0-3-3h-1V3Zm3 11a1 1 0 1 0-2 0v.05a2.5 2.5 0 0 0 .5 4.95h1a.5.5 0 0 1 0 1H17a1 1 0 1 0 0 2h1a1 1 0 1 0 2 0v-.05a2.5 2.5 0 0 0-.5-4.95h-1a.5.5 0 0 1 0-1H21a1 1 0 1 0 0-2h-1Z"
      fill="currentColor"
    />
  </Icon>
);

CalendarDollar.displayName = 'CalendarDollarIcon';
