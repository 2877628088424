import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Pentagon: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.8865 22.2538L20.8673 74.5793C18.849 76.0449 17.347 78.1123 16.5768 80.4846C15.8066 82.857 15.8077 85.4123 16.5801 87.784L44.0886 172.447C44.8588 174.818 46.3598 176.884 48.3763 178.349C50.3928 179.815 52.8215 180.604 55.3143 180.605H144.332C146.827 180.607 149.259 179.819 151.278 178.353C153.297 176.887 154.799 174.82 155.57 172.447L183.07 87.784C183.841 85.4133 183.841 82.8594 183.071 80.4885C182.301 78.1176 180.8 76.0515 178.783 74.5863L106.764 22.2538C104.747 20.7889 102.318 20 99.8251 20C97.3323 20 94.9035 20.7889 92.8865 22.2538V22.2538Z"
      fill="currentColor"
    />
  </Icon>
);

Pentagon.displayName = 'PentagonShape';
