import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowsExpand: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.643 3.069a1.118 1.118 0 00-.343.229C2.997 3.601 3 3.572 3 6c0 2.427-.003 2.399.299 2.701a.984.984 0 001.402 0C4.969 8.433 5 8.286 5 7.284v-.862l1.73 1.724c1.551 1.545 1.75 1.73 1.927 1.789.389.129.766.044 1.044-.234s.363-.655.234-1.044c-.059-.177-.244-.376-1.789-1.927L6.422 5h.862c.697 0 .899-.013 1.056-.066C8.709 4.808 9 4.396 9 4c0-.242-.119-.521-.299-.701-.303-.303-.271-.299-2.718-.296-1.851.003-2.188.012-2.34.066zm12 0C15.291 3.193 15 3.614 15 4c0 .396.291.808.66.934.157.053.359.066 1.056.066h.862l-1.724 1.73c-1.545 1.551-1.73 1.75-1.789 1.927-.129.389-.044.766.234 1.044s.655.363 1.044.234c.177-.059.376-.244 1.927-1.789L19 6.422v.862c0 1.002.031 1.149.299 1.417a.984.984 0 001.402 0C21.003 8.399 21 8.427 21 6s.003-2.399-.299-2.701c-.303-.303-.271-.299-2.718-.296-1.851.003-2.188.012-2.34.066zM8.64 14.068c-.153.053-.442.323-1.91 1.786L5 17.578v-.862c0-1.002-.031-1.149-.299-1.417a.984.984 0 00-1.402 0C2.997 15.601 3 15.573 3 18s-.003 2.399.299 2.701c.302.302.274.299 2.701.299s2.399.003 2.701-.299a.984.984 0 000-1.402C8.433 19.031 8.286 19 7.284 19h-.862l1.724-1.73c1.545-1.551 1.73-1.75 1.789-1.927.129-.389.044-.766-.234-1.044a1.005 1.005 0 00-1.061-.231zm6.003.001c-.352.124-.643.545-.643.931 0 .08.029.234.065.343.059.177.244.376 1.789 1.927L17.578 19h-.862c-1.002 0-1.149.031-1.417.299a.984.984 0 000 1.402c.302.302.274.299 2.701.299s2.399.003 2.701-.299c.302-.302.299-.274.299-2.701s.003-2.399-.299-2.701a.984.984 0 00-1.402 0c-.268.268-.299.415-.299 1.417v.862l-1.73-1.724c-1.551-1.545-1.75-1.73-1.927-1.789a1.032 1.032 0 00-.7.004z"
      fill="currentColor"
    />
  </Icon>
);

ArrowsExpand.displayName = 'ArrowsExpandIcon';
