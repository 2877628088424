import { FC } from 'react';
import {
  ButtonProps,
  Flex,
  FlexProps,
  IconButton,
  IconProps,
  Select,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { getEnumKeyByValue } from 'helpers';
import { Icon, ICON_NAMES } from 'icons';
import { PageSizes } from 'types';

export interface PaginationControlsProps {
  total?: number;
  pageSize?: number;
  currentPage?: number;
  containerProps?: FlexProps;
  handleNext?: () => void;
  handlePrevious?: () => void;
  handleChangePageSize?: (PageSizes) => void;
  allowPageSizeChange?: boolean;
  pagingContainerProps?: FlexProps;
  pagingLabelProps?: TextProps;
  isLoading?: boolean;
  hideWhenLoading?: boolean;
  loadingText?: string;
  emptyText?: string;
  hideText?: boolean;
  iconButtonProps?: ButtonProps;
  iconProps?: IconProps;
}

export const PaginationControls: FC<PaginationControlsProps> = ({
  total,
  pageSize,
  currentPage,
  containerProps,
  handleNext = () => null,
  handlePrevious = () => null,
  handleChangePageSize = () => null,
  allowPageSizeChange = true,
  pagingContainerProps,
  pagingLabelProps,
  isLoading = false,
  hideWhenLoading = false,
  loadingText = 'Loading...',
  emptyText = 'No Results Found',
  hideText = false,
  iconButtonProps,
  iconProps,
}) => {
  const start = currentPage * pageSize + 1;
  const end =
    (currentPage + 1) * pageSize < total ? (currentPage + 1) * pageSize : total;
  const isEmpty = !end;

  const labelString = isLoading
    ? loadingText
    : isEmpty
      ? emptyText
      : `Showing ${start} – ${end} of ${total.toLocaleString('en-US')}`;

  if (hideWhenLoading && isLoading) return null;
  return (
    <Flex
      mt={4}
      mb={8}
      px={8}
      align="center"
      width="100%"
      fontFamily="mono"
      fontSize="sm"
      color="text.hint"
      flexWrap={{ base: 'wrap', md: 'nowrap' }}
      {...containerProps}
    >
      {allowPageSizeChange ? (
        <Flex align="center" ml={{ base: 0, md: 3 }} order={{ base: 2, md: 1 }}>
          <Text mr={2} flexShrink={0}>
            {'Page Size'}
          </Text>
          <Select
            size="sm"
            data-testid="page-size"
            maxWidth="72px"
            onChange={e =>
              handleChangePageSize(
                PageSizes[getEnumKeyByValue(PageSizes, e.target.value)],
              )
            }
            defaultValue={pageSize}
          >
            {Object.values(PageSizes).map(value => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              );
            })}
          </Select>
        </Flex>
      ) : null}
      <Flex
        flexGrow={1}
        align="center"
        justify={{ base: 'flex-start', md: 'flex-end' }}
        order={{ base: 1, md: 2 }}
        {...pagingContainerProps}
      >
        {!hideText && (
          <Text mr={2} ml={{ base: 0, md: 8 }} {...pagingLabelProps}>
            {labelString}
          </Text>
        )}
        {!isEmpty && (
          <>
            <IconButton
              variant="transparent"
              aria-label="previous"
              size="sm"
              icon={<Icon name={ICON_NAMES.chevronleft} {...iconProps} />}
              onClick={handlePrevious}
              isDisabled={start === 1}
              {...iconButtonProps}
            />
            <IconButton
              variant="transparent"
              aria-label="next"
              size="sm"
              icon={<Icon name={ICON_NAMES.chevronright} {...iconProps} />}
              onClick={handleNext}
              isDisabled={end === total}
              {...iconButtonProps}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
