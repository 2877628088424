import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PhoneOutgoing: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 2.025c-.408.08-.533.114-.75.202-.864.351-1.493 1.069-1.78 2.033-.082.272-.113 1.943-.053 2.796a15.989 15.989 0 003.507 8.984c.555.694 1.725 1.864 2.416 2.415 2.62 2.092 5.615 3.262 8.98 3.507.849.062 2.525.031 2.8-.052 1.106-.333 1.832-1.058 2.173-2.17.051-.165.064-.546.077-2.18.011-1.389.002-2.052-.03-2.22a2.105 2.105 0 00-.89-1.303c-.182-.118-.754-.325-2.661-.961-2.376-.794-2.437-.812-2.817-.828-.477-.021-.82.07-1.186.314-.376.251-.533.467-.969 1.33l-.401.792-.148-.09a10.304 10.304 0 01-3.483-3.482l-.089-.148.792-.401c.863-.436 1.079-.593 1.33-.969.244-.366.335-.709.314-1.186-.016-.38-.034-.441-.828-2.817-.636-1.907-.843-2.479-.961-2.661a2.104 2.104 0 00-1.275-.886c-.199-.041-3.867-.059-4.068-.019zm11.023.044C15.291 2.193 15 2.614 15 3c0 .396.291.808.66.934.166.056.393.066 1.556.066h1.362l-2.224 2.23c-2.011 2.017-2.23 2.249-2.289 2.427-.129.39-.044.766.234 1.044s.654.363 1.044.234c.178-.059.41-.278 2.427-2.289L20 5.422v1.362c0 1.555.012 1.63.299 1.917a.984.984 0 001.402 0c.308-.307.299-.215.299-3.201s.009-2.894-.299-3.201c-.308-.308-.213-.299-3.218-.296-2.304.003-2.687.012-2.84.066zM9.021 6.243c.411 1.233.741 2.248.733 2.255-.008.007-.558.285-1.223.618-1.1.551-1.221.622-1.346.786-.16.209-.245.515-.208.746.069.431.879 1.854 1.533 2.692a12.21 12.21 0 003.684 3.157c.554.31.96.495 1.158.526.231.037.537-.048.746-.208.164-.125.235-.246.786-1.346.333-.665.611-1.215.618-1.223.007-.008 1.022.322 2.255.733l2.243.748v1.709c0 1.943-.004 1.97-.3 2.266-.293.293-.408.312-1.736.285-.629-.013-1.297-.042-1.484-.066-3.377-.42-6.192-1.799-8.501-4.166a12.867 12.867 0 01-1.637-2.015 13.605 13.605 0 01-2.117-5.26c-.164-.895-.205-1.423-.205-2.64V4.7l.111-.189c.061-.103.173-.236.25-.294.275-.21.308-.213 2.166-.215L8.273 4l.748 2.243z"
      fill="currentColor"
    />
  </Icon>
);

PhoneOutgoing.displayName = 'PhoneOutgoingIcon';
