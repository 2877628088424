import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { NumberFormatProps } from 'react-number-format';
import {
  BoxProps,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputProps,
} from '@chakra-ui/react';
import { NumberFormatWrapper } from '../NumberFormatWrapper';
import { ConnectForm } from './ConnectForm';
import { FieldLabel, FieldLabelProps } from './FieldLabel';

export enum NumberInputSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

interface ConnectedInputProps {
  containerProps?: BoxProps;
  labelProps?: FieldLabelProps;
  labelExtras?: React.ReactNode;
  showLabel?: boolean;
  id: string;
  placeholder?: string;
  type?: string;
  inputProps?: InputProps;
  inputFormat?: string | ((inputValue: string) => string);
  inputMask?: string;
  inputPrefix?: string;
  numberFormatProps?: NumberFormatProps;
  numberInputSize?: NumberInputSize;
}

const numberInputStyles = {
  [NumberInputSize.sm]: {
    h: 8,
    fontSize: 'sm',
  },
  [NumberInputSize.md]: {
    h: 10,
    fontSize: 'md',
  },
  [NumberInputSize.lg]: {
    h: 12,
    fontSize: 'lg',
  },
};

export const ConnectedInput: FC<ConnectedInputProps> = ({
  containerProps,
  labelProps,
  labelExtras,
  showLabel = true,
  id,
  placeholder = 'Enter',
  type = 'text',
  inputProps,
  inputFormat,
  numberFormatProps,
  numberInputSize = NumberInputSize.md,
}) => (
  <ConnectForm>
    {({ ...formMethods }: UseFormReturn) => (
      <FormControl
        {...containerProps}
        isInvalid={!!formMethods.formState.errors[id]}
      >
        {showLabel && (
          <Flex>
            {labelProps?.text && <FieldLabel {...labelProps} />}
            {labelExtras && labelExtras}
          </Flex>
        )}
        <InputGroup maxWidth="100%">
          {formMethods?.register && (
            <>
              {inputFormat || numberFormatProps ? (
                <Controller
                  render={({ field: { ...rest } }) => (
                    <NumberFormatWrapper
                      format={inputFormat}
                      placeholder={inputProps?.placeholder}
                      borderRadius="md"
                      fontSize={numberInputStyles[numberInputSize].fontSize}
                      h={numberInputStyles[numberInputSize].h}
                      px={4}
                      _placeholder={{ color: 'blackAlpha.500' }}
                      {...numberFormatProps}
                      {...rest}
                      customInput={Input}
                    />
                  )}
                  control={formMethods.control}
                  name={id}
                />
              ) : (
                <Input
                  placeholder={placeholder}
                  type={type}
                  {...formMethods?.register(id)}
                  id={id}
                  {...inputProps}
                />
              )}
            </>
          )}
        </InputGroup>
        {formMethods.formState?.errors[id] ? (
          <FormErrorMessage ml={2}>
            {`${formMethods.formState.errors[id].message}`}
          </FormErrorMessage>
        ) : null}
      </FormControl>
    )}
  </ConnectForm>
);
