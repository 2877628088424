import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Flag: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 2.025l-.29.058c-.88.178-1.753.913-2.093 1.762-.232.578-.214-.135-.227 8.953-.013 9.366-.038 8.576.288 8.902.48.48 1.292.352 1.588-.25l.094-.19.02-2.28.02-2.28.111-.189c.125-.213.318-.375.539-.454.12-.043.772-.054 3.291-.055L11.102 16l.399.403c.219.222.471.442.559.49l.16.087 4.458.011c5.021.012 4.703.03 5.023-.29a.994.994 0 00.289-.794c-.016-.168-.273-.71-1.445-3.056L19.121 10l1.424-2.851c1.172-2.346 1.429-2.888 1.445-3.056a.994.994 0 00-.288-.793c-.318-.318-.04-.3-4.68-.3h-4.124l-.399-.403c-.219-.222-.471-.442-.559-.49l-.16-.087-3.54-.006c-1.947-.003-3.576.002-3.62.011zM11 6.573c0 2.904-.008 2.821.299 3.128a.984.984 0 001.402 0c.301-.301.299-.282.299-2.628V5h3.18c1.749 0 3.18.009 3.18.019 0 .011-.527 1.074-1.17 2.362l-1.17 2.342v.554l1.17 2.342c.644 1.288 1.17 2.351 1.17 2.362 0 .01-1.454.019-3.231.019h-3.231l-.399-.403c-.219-.222-.471-.442-.559-.49l-.16-.087H8.16c-3.597 0-3.682.003-4.091.125-.067.02-.07-.204-.06-4.712L4.02 4.7l.111-.189c.125-.213.318-.375.539-.454.12-.043.763-.054 3.24-.055L11 4v2.573z"
      fill="currentColor"
    />
  </Icon>
);

Flag.displayName = 'FlagIcon';
