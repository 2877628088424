import { FC } from 'react';
import { IconProps } from '@chakra-ui/icon';
import { Flex, FlexProps, Text, TextProps } from '@chakra-ui/react';
import { Icon } from 'icons';

export interface TextAndIconProps extends FlexProps {
  text: React.ReactNode;
  icon: string;
  textClassName?: string;
  containerProps?: FlexProps;
  textProps?: TextProps;
  iconProps?: IconProps;
  onClick?: () => void;
}

export const TextAndIcon: FC<TextAndIconProps> = ({
  text,
  icon,
  textClassName,
  containerProps,
  textProps,
  iconProps,
  onClick = () => undefined,
}) => {
  return (
    <Flex direction="row" align="center" {...containerProps} onClick={onClick}>
      <Icon name={icon} {...iconProps} />
      <Text className={textClassName} {...textProps}>
        {text}
      </Text>
    </Flex>
  );
};
