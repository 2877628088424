import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Monitor: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M3.503 2.042 C 2.488 2.214,1.556 2.976,1.202 3.925 C 0.996 4.477,1.001 4.315,1.001 9.992 C 1.000 15.780,0.991 15.556,1.234 16.151 C 1.521 16.857,2.143 17.479,2.849 17.766 C 3.429 18.002,3.381 18.000,7.358 18.000 L 11.000 18.000 11.000 19.000 L 11.000 20.000 9.425 20.000 C 7.721 20.000,7.656 20.007,7.374 20.222 C 7.301 20.277,7.192 20.408,7.131 20.511 C 7.036 20.672,7.020 20.744,7.020 21.000 C 7.020 21.256,7.036 21.328,7.131 21.489 C 7.192 21.592,7.301 21.723,7.374 21.778 C 7.677 22.009,7.484 22.000,11.998 22.000 C 16.663 22.000,16.384 22.018,16.701 21.701 C 17.092 21.310,17.092 20.690,16.701 20.299 C 16.409 20.006,16.362 20.000,14.573 20.000 L 13.000 20.000 13.000 19.000 L 13.000 18.000 16.642 18.000 C 20.618 18.000,20.571 18.002,21.150 17.767 C 21.850 17.482,22.482 16.850,22.767 16.150 C 23.008 15.556,23.000 15.777,23.000 10.000 C 23.000 4.221,23.008 4.444,22.766 3.849 C 22.499 3.193,21.964 2.633,21.296 2.312 C 20.634 1.993,21.532 2.022,12.120 2.015 C 7.489 2.012,3.611 2.024,3.503 2.042 M20.340 4.066 C 20.455 4.105,20.603 4.201,20.701 4.299 C 21.021 4.619,21.000 4.217,21.000 10.000 C 21.000 15.783,21.021 15.381,20.701 15.701 C 20.376 16.026,21.144 16.000,12.000 16.000 C 2.856 16.000,3.624 16.026,3.299 15.701 C 2.979 15.381,3.000 15.783,3.000 10.000 C 3.000 4.216,2.979 4.619,3.300 4.298 C 3.617 3.981,2.858 4.007,11.983 4.003 C 19.481 4.000,20.162 4.005,20.340 4.066 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Monitor.displayName = 'MonitorIcon';
