import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const StatusOffline: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.643 2.069c-.531.187-.786.849-.53 1.375.063.129 2.503 2.593 9.216 9.301 8.441 8.436 9.146 9.13 9.328 9.19.39.128.766.044 1.044-.234.279-.278.363-.655.233-1.044-.057-.172-.199-.331-1.127-1.266l-1.061-1.068.182-.232c1.486-1.893 2.256-4.528 2.031-6.951a10.064 10.064 0 00-2.711-6.027c-.352-.373-.505-.451-.888-.452-.258-.001-.327.015-.492.112a1 1 0 00-.474 1.077c.051.228.146.363.661.938.848.949 1.535 2.344 1.781 3.62.353 1.828.104 3.63-.724 5.244-.211.412-.733 1.228-.785 1.228-.037 0-1.407-1.372-1.407-1.41 0-.015.061-.123.135-.239a6.46 6.46 0 00.85-2.171c.101-.538.101-1.606.002-2.12a6.224 6.224 0 00-1.477-2.985c-.309-.345-.55-.475-.879-.475-.577 0-1.005.418-1.004.98.001.315.071.466.37.802.477.535.781 1.088.953 1.736.086.32.103.476.106.962.005.661-.057 1.001-.282 1.56-.236.585-.147.606-.986-.232l-.731-.73.002-.509c.001-.441-.011-.54-.092-.738-.246-.61-.677-1.02-1.277-1.215-.3-.098-.367-.106-.74-.084l-.41.023L7.5 6.083c-3.627-3.62-3.977-3.959-4.157-4.018a1.038 1.038 0 00-.7.004zm.517 6.11c-.227.074-.46.265-.559.459-.115.225-.306.899-.418 1.473a10.054 10.054 0 00.945 6.501c.516 1.004 1.598 2.396 2.052 2.64.122.066.231.086.46.087.258.001.327-.015.492-.112.45-.265.624-.827.4-1.299-.036-.075-.206-.289-.377-.474A7.479 7.479 0 014.817 15.5c-.889-1.808-1.06-3.932-.469-5.82.127-.405.14-.753.034-.96-.236-.464-.753-.693-1.222-.541zm3.457 3.636a.98.98 0 00-.524.624c-.059.202-.059.25 0 .58a6.098 6.098 0 001.426 2.968c.37.43.596.549.995.527.219-.013.315-.041.471-.136.222-.138.43-.444.47-.695a1.007 1.007 0 00-.189-.722l-.392-.482a3.867 3.867 0 01-.738-1.488 68.762 68.762 0 00-.143-.581c-.055-.214-.317-.517-.531-.615-.235-.106-.591-.098-.845.02z"
      fill="currentColor"
    />
  </Icon>
);

StatusOffline.displayName = 'StatusOfflineIcon';
