import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Sparkles: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.643 2.069a1.118 1.118 0 00-.343.229c-.248.248-.3.442-.3 1.129V4h-.573c-.686 0-.881.052-1.128.299a.984.984 0 000 1.402c.247.247.442.299 1.128.299H4v.573c0 .686.052.88.299 1.128a.984.984 0 001.402 0C5.948 7.454 6 7.259 6 6.573V6h.573c.686 0 .881-.052 1.128-.3a.984.984 0 000-1.401c-.247-.247-.442-.3-1.128-.3H6v-.572c0-.686-.052-.881-.299-1.128a.998.998 0 00-1.058-.23zm8.025-.01a1.028 1.028 0 00-.476.35c-.053.074-.564 1.538-1.192 3.409l-1.1 3.28-2.698 1.01c-1.885.707-2.74 1.045-2.84 1.126-.458.37-.458 1.16 0 1.532.1.08.955.419 2.84 1.125L9.9 14.9l1.1 3.286c.896 2.675 1.123 3.314 1.224 3.439.385.476 1.167.476 1.552 0 .101-.125.329-.765 1.224-3.437l1.1-3.284 2.695-1.01c1.913-.716 2.737-1.043 2.84-1.126.463-.375.463-1.163 0-1.538-.103-.083-.927-.41-2.84-1.127L16.1 9.094 15 5.812c-1.199-3.58-1.164-3.496-1.539-3.69-.23-.117-.575-.145-.793-.062zm1.024 6.198c.688 2.063.759 2.233 1.014 2.412.063.044.798.337 1.634.65.836.313 1.583.596 1.66.628l.14.058-1.66.623c-.913.342-1.711.658-1.774.702-.255.18-.326.35-1.014 2.413-.369 1.109-.681 2.017-.692 2.017-.011 0-.323-.908-.692-2.017-.688-2.063-.758-2.231-1.015-2.413-.062-.045-.847-.357-1.743-.693-.897-.337-1.63-.623-1.63-.637 0-.014.733-.3 1.63-.637.896-.336 1.681-.648 1.743-.693.257-.182.327-.35 1.015-2.413.369-1.11.681-2.017.692-2.017.011 0 .323.908.692 2.017zm-8.049 7.812a1.118 1.118 0 00-.343.229c-.248.248-.3.442-.3 1.129V18h-.573c-.686 0-.881.052-1.128.299a.984.984 0 000 1.402c.247.247.442.299 1.128.299H5v.573c0 .686.052.88.299 1.128a.984.984 0 001.402 0c.247-.247.299-.442.299-1.128V20h.573c.686 0 .881-.052 1.128-.3a.984.984 0 000-1.401c-.247-.247-.442-.3-1.128-.3H7v-.572c0-.686-.052-.881-.299-1.128a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

Sparkles.displayName = 'SparklesIcon';
