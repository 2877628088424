import { FC } from 'react';
import { BoxProps, Flex, Text, TextProps } from '@chakra-ui/react';
import { ICON_NAMES } from 'icons';
import { TextAndIcon } from './TextAndIcon';

interface CalloutProps {
  title: string;
  icon: ICON_NAMES;
  description?: string;
  color: string;
  containerProps?: BoxProps;
  descriptionProps?: TextProps;
  additionalDescription?: JSX.Element;
}

export const Callout: FC<CalloutProps> = ({
  title,
  icon,
  description,
  color,
  containerProps,
  descriptionProps,
  additionalDescription = () => null,
}) => {
  return (
    <Flex
      flexDirection="column"
      backgroundColor={`${color}.100`}
      mb={4}
      pt={2}
      pb={3}
      pl={2}
      pr={4}
      borderRadius="md"
      {...containerProps}
    >
      <TextAndIcon
        text={title}
        icon={icon}
        textProps={{
          color: `${color}.600`,
          fontWeight: 'semi',
        }}
        iconProps={{ color: `${color}.600`, mr: 1 }}
      />
      {description && (
        <Flex flexDirection="column">
          <>
            <Text
              lineHeight="shorter"
              fontSize="sm"
              color={`${color}.600`}
              {...descriptionProps}
            >
              {description}
            </Text>
            {additionalDescription}
          </>
        </Flex>
      )}
    </Flex>
  );
};
