import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserMoveAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M8.5 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm-2.036 8.464A5 5 0 0 1 5 14h7a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 1.464-3.536Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M15.994 5.998c0-.386.29-.807.643-.931.153-.054.536-.063 2.84-.066 3.005-.003 2.91-.012 3.218.296.308.307.299.215.299 3.2 0 2.987.009 2.895-.3 3.202a.984.984 0 0 1-1.401 0c-.287-.287-.3-.362-.3-1.917V8.42l-2.23 2.224c-2.016 2.01-2.248 2.23-2.426 2.289-.39.129-.766.044-1.044-.234s-.363-.654-.234-1.044c.059-.178.278-.41 2.289-2.427l2.224-2.23H18.21c-1.163 0-1.39-.01-1.556-.066-.37-.126-.66-.538-.66-.934Z"
    />
  </Icon>
);

UserMoveAlt.displayName = 'UserMoveAltIcon';
