import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Camera: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.462 3.066a3.008 3.008 0 00-1.482.791c-.15.147-.44.534-.739.983-.785 1.182-.693 1.126-1.901 1.165-.577.018-.949.048-1.08.087-1.119.327-1.901 1.126-2.184 2.231-.083.322-.083 10.032 0 10.354.281 1.099 1.041 1.876 2.184 2.238.179.056.983.064 7.56.075 4.998.008 7.448-.001 7.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.326.083-10.034 0-10.357a3.082 3.082 0 00-1.292-1.829c-.591-.386-.89-.459-1.992-.492-1.184-.035-1.096.019-1.882-1.162-.271-.407-.594-.842-.718-.967a2.957 2.957 0 00-1.531-.81c-.438-.09-4.622-.088-5.047.003zm4.918 2.04c.228.123.311.225.861 1.054.532.8.827 1.13 1.232 1.376.591.359 1.044.464 1.998.464.797 0 .974.043 1.23.299.319.318.299-.022.299 5.201s.02 4.883-.299 5.201c-.324.324.322.299-7.701.299s-7.377.025-7.701-.299c-.319-.319-.299.024-.297-5.218.001-3.791.012-4.691.055-4.813.079-.221.241-.414.454-.539.186-.11.201-.111 1.069-.137.967-.028 1.156-.062 1.645-.296.359-.172.724-.445.949-.711.096-.114.378-.513.625-.887.577-.869.659-.957 1.001-1.063.055-.017 1.072-.028 2.26-.024 2.13.007 2.162.008 2.32.093zm-3.07 3.957a3.918 3.918 0 00-2.131 1.116 3.903 3.903 0 00-1.165 3.001c.096 1.833 1.332 3.307 3.126 3.725.464.109 1.319.102 1.78-.014a4.03 4.03 0 002.971-2.971c.116-.461.123-1.316.014-1.78-.49-2.102-2.51-3.454-4.595-3.077zm1.098 1.981C13.31 11.232 14 12.08 14 13c0 1.08-.92 2-2 2s-2-.92-2-2c0-.898.657-1.731 1.534-1.943.263-.064.604-.069.874-.013z"
      fill="currentColor"
    />
  </Icon>
);

Camera.displayName = 'CameraIcon';
