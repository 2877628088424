import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Image: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M4.503 2.042 C 3.487 2.214,2.556 2.976,2.202 3.925 C 1.993 4.484,2.001 4.151,2.001 11.992 C 2.000 19.977,1.988 19.546,2.234 20.151 C 2.521 20.857,3.143 21.479,3.849 21.766 C 4.453 22.012,4.024 22.000,12.000 22.000 C 19.974 22.000,19.547 22.012,20.150 21.767 C 20.850 21.482,21.482 20.850,21.767 20.150 C 22.012 19.547,22.000 19.974,22.000 12.000 C 22.000 4.024,22.012 4.453,21.766 3.849 C 21.499 3.193,20.964 2.633,20.296 2.312 C 19.636 1.994,20.412 2.023,12.120 2.015 C 8.039 2.012,4.611 2.024,4.503 2.042 M19.340 4.066 C 19.455 4.105,19.603 4.201,19.701 4.299 C 20.016 4.614,20.000 4.371,20.000 8.716 L 20.000 12.578 18.270 10.854 C 16.719 9.309,16.520 9.124,16.343 9.065 C 16.094 8.983,15.907 8.983,15.657 9.065 C 15.476 9.124,15.027 9.562,10.050 14.534 L 4.641 19.939 4.496 19.861 C 4.417 19.818,4.300 19.720,4.237 19.644 C 3.984 19.337,4.000 19.844,4.000 12.000 C 4.000 3.975,3.975 4.623,4.300 4.298 C 4.616 3.982,3.975 4.007,11.983 4.003 C 18.550 4.000,19.162 4.006,19.340 4.066 M7.960 6.062 C 7.542 6.151,7.069 6.414,6.741 6.739 C 5.759 7.714,5.759 9.277,6.742 10.264 C 7.710 11.237,9.288 11.236,10.262 10.262 C 11.237 9.287,11.237 7.703,10.262 6.740 C 9.649 6.135,8.795 5.884,7.960 6.062 M8.724 8.062 C 8.977 8.193,9.068 8.508,8.922 8.748 C 8.722 9.076,8.278 9.076,8.078 8.748 C 7.935 8.513,8.009 8.226,8.252 8.078 C 8.406 7.984,8.563 7.979,8.724 8.062 M18.010 13.430 L 20.000 15.420 20.000 17.283 C 20.000 19.395,19.999 19.403,19.701 19.701 C 19.379 20.023,19.869 20.000,13.283 20.000 L 7.420 20.000 11.700 15.720 C 14.054 13.366,15.989 11.440,16.000 11.440 C 16.011 11.440,16.915 12.335,18.010 13.430 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Image.displayName = 'ImageIcon';
