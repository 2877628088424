import { FC, Fragment } from 'react';
import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { Icon } from 'icons';

export const menuOptionContainerStyles = {
  _disabled: {
    cursor: 'not-allowed',
    backgroundColor: 'blackAlpha.300',
  },
  _hover: {
    backgroundColor: 'blackAlpha.100',
  },
  align: 'center',
  color: 'blackAlpha.700',
  cursor: 'pointer',
  px: 3,
  py: 2,
  width: '100%',
};

export interface MenuOption {
  action?: (any) => void;
  label?: JSX.Element;
  data?: any; // eslint-disable-line
  icon?: JSX.Element;
  component?: JSX.Element;
}

interface RowMenuProps {
  menuOptions: Array<MenuOption>;
  containerProps?: BoxProps;
}

export const RowMenu: FC<RowMenuProps> = ({ menuOptions, containerProps }) => {
  return (
    <Popover closeOnBlur={true} placement="bottom-end" isLazy>
      <PopoverTrigger>
        <Box {...containerProps}>
          <IconButton
            zIndex={1}
            aria-label="more"
            variant="transparent"
            size="sm"
            color="text.disabled"
            icon={<Icon name="dotshorizontal" w={5} h={5} />}
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent borderColor="main.medium">
          <PopoverBody p={0}>
            {menuOptions.map((option, index) => (
              <Fragment key={index}>
                {option?.component ? (
                  option?.component
                ) : (
                  <Flex
                    onClick={() => option.action(option.data)}
                    {...menuOptionContainerStyles}
                  >
                    {option?.icon && <Flex mr={1}>{option?.icon}</Flex>}
                    {option.label}
                  </Flex>
                )}
              </Fragment>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
