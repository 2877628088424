import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  BoxProps,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputProps,
} from '@chakra-ui/react';
import { isNullDate } from 'helpers';
import { DatePickerInput, DatePickerInputProps, DatePickerProps } from 'ui';
import { ConnectForm } from './ConnectForm';
import { FieldLabel, FieldLabelProps } from './FieldLabel';

interface ConnectedDatePickerInputProps extends Partial<DatePickerInputProps> {
  containerProps?: BoxProps;
  labelProps?: FieldLabelProps;
  id: string;
  placeholder?: string;
  inputProps?: InputProps;
  datePickerProps?: DatePickerProps;
}

export const ConnectedDatePickerInput: FC<ConnectedDatePickerInputProps> = ({
  containerProps,
  labelProps,
  id,
  placeholder = 'Enter Date',
  inputProps,
  datePickerProps,
  hasTodayButton = false,
  shouldReset = false,
}) => (
  <ConnectForm>
    {({ ...formMethods }: UseFormReturn) => (
      <FormControl
        {...containerProps}
        isInvalid={!!formMethods.formState.errors[id]}
      >
        {labelProps?.text && <FieldLabel {...labelProps} />}
        <InputGroup maxWidth="100%" isolation="auto">
          {formMethods?.register && (
            <Controller
              render={({ field: { value, onChange, onBlur } }) => {
                if (!value) return <></>;
                return (
                  <DatePickerInput
                    inputProps={{
                      id,
                      placeholder,
                      onBlur: onBlur,
                      ...inputProps,
                    }}
                    className="test-shift-date-input"
                    defaultValue={
                      !value || isNullDate(new Date(value)) ? 'None' : value
                    }
                    onChange={date => onChange(date)}
                    datePickerProps={datePickerProps}
                    hasTodayButton={hasTodayButton}
                    shouldReset={shouldReset}
                  />
                );
              }}
              name={id}
              control={formMethods?.control}
            />
          )}
        </InputGroup>
        {formMethods.formState?.errors[id] ? (
          <FormErrorMessage ml={2}>
            {`${formMethods.formState.errors[id].message}`}
          </FormErrorMessage>
        ) : null}
      </FormControl>
    )}
  </ConnectForm>
);
