import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Rss: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.643 4.066C5.288 4.197 5 4.615 5 5c0 .396.291.808.66.934.117.04.323.066.517.066.769.001 1.855.144 2.733.36 3.898.957 7.119 3.909 8.431 7.726.406 1.179.657 2.607.659 3.737 0 .417.084.663.299.878a.984.984 0 001.403-.001c.268-.268.315-.468.284-1.218-.123-2.993-1.128-5.744-2.969-8.122-.462-.598-1.787-1.923-2.377-2.378-2.419-1.867-5.197-2.876-8.16-2.963-.547-.016-.69-.008-.837.047zm0 6.003C5.291 10.193 5 10.614 5 11c0 .401.295.815.664.931.108.034.395.072.637.084a5.995 5.995 0 015.684 5.684c.028.562.098.786.313 1.001a.984.984 0 001.403.001c.293-.293.344-.589.256-1.484a8.03 8.03 0 00-3.577-5.907 9.022 9.022 0 00-1.88-.905c-1.047-.341-2.394-.5-2.857-.336zm-.109 5.988C4.657 16.269 4 17.102 4 18c0 1.08.92 2 2 2 .523 0 1.017-.21 1.404-.596.796-.797.796-2.011 0-2.808a1.969 1.969 0 00-1.87-.539z"
      fill="currentColor"
    />
  </Icon>
);

Rss.displayName = 'RssIcon';
