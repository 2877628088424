import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Shapes: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M8.280 1.594 C 8.204 1.635,8.094 1.730,8.036 1.804 C 7.978 1.879,6.324 4.817,4.361 8.334 C 1.913 12.718,0.785 14.775,0.771 14.881 C 0.724 15.232,0.894 15.538,1.220 15.685 C 1.371 15.754,1.579 15.758,4.828 15.759 L 8.276 15.760 8.300 15.910 C 8.535 17.370,8.966 18.432,9.761 19.500 C 10.119 19.982,10.451 20.335,10.893 20.706 C 13.205 22.648,16.367 23.041,19.057 21.723 C 19.868 21.325,20.494 20.866,21.145 20.192 C 22.157 19.144,22.792 17.932,23.109 16.440 C 23.206 15.985,23.216 15.858,23.218 15.040 C 23.220 14.072,23.195 13.848,22.996 13.088 C 22.648 11.755,21.978 10.612,20.973 9.637 C 20.530 9.206,20.317 9.034,19.840 8.721 C 19.077 8.220,18.100 7.823,17.178 7.638 C 16.546 7.512,15.382 7.484,14.711 7.580 C 14.195 7.653,13.484 7.836,13.030 8.012 L 12.761 8.116 12.714 8.028 C 12.194 7.054,9.242 1.828,9.166 1.746 C 9.108 1.684,9.011 1.607,8.952 1.576 C 8.797 1.496,8.447 1.505,8.280 1.594 M11.554 9.029 C 13.141 11.872,14.440 14.207,14.440 14.219 C 14.440 14.230,11.820 14.240,8.617 14.240 C 5.414 14.240,2.799 14.225,2.807 14.207 C 2.883 14.017,8.625 3.810,8.643 3.831 C 8.657 3.847,9.967 6.186,11.554 9.029 M16.903 9.116 C 17.714 9.282,18.402 9.568,19.098 10.029 C 19.625 10.377,20.372 11.120,20.715 11.636 C 21.914 13.442,22.074 15.684,21.142 17.616 C 20.592 18.757,19.574 19.790,18.439 20.361 C 15.821 21.677,12.553 20.868,10.880 18.489 C 10.334 17.712,9.956 16.796,9.825 15.930 L 9.799 15.760 12.814 15.760 C 14.985 15.760,15.866 15.747,15.964 15.714 C 16.435 15.554,16.623 15.073,16.391 14.620 C 16.335 14.510,15.666 13.307,14.905 11.946 C 14.143 10.585,13.530 9.455,13.542 9.436 C 13.598 9.347,14.650 9.083,15.180 9.025 C 15.550 8.985,16.509 9.036,16.903 9.116 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Shapes.displayName = 'ShapesIcon';
