import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Ticket: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 4.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.227.553-.223.512-.24 2.477-.014 1.643-.008 1.853.053 2.036.126.379.534.672.937.672.527 0 1 .473 1 1s-.473 1-1 1c-.403 0-.811.293-.937.672-.061.183-.067.393-.053 2.036.013 1.513.027 1.867.078 2.032.35 1.125 1.032 1.808 2.172 2.174.18.058.933.065 7.56.076 4.97.008 7.448-.001 7.632-.029 1.178-.182 2.088-1.003 2.461-2.221.05-.164.064-.534.077-2.032.014-1.643.008-1.853-.053-2.036A1.032 1.032 0 0021 13c-.668 0-1.167-.703-.943-1.33.139-.39.448-.614.917-.667.522-.058.832-.279.968-.69.09-.274.065-3.746-.03-4.053-.378-1.217-1.27-2.029-2.436-2.218-.242-.039-14.659-.056-14.856-.017zM14 6.573c0 .686.052.881.299 1.128.18.18.459.299.7.299.243 0 .522-.119.702-.299.247-.247.299-.442.299-1.128V6h1.573c1.789 0 1.836.006 2.128.299.281.281.299.383.299 1.72v1.165l-.15.052c-.253.089-.698.385-.945.631a3.072 3.072 0 00-.828 1.453c-.073.285-.073 1.074-.001 1.357.236.929.914 1.736 1.734 2.067l.19.076v1.163c0 1.335-.018 1.437-.299 1.718-.292.293-.34.299-2.128.299H16v-.573c0-.686-.052-.881-.3-1.128a.984.984 0 00-1.401 0c-.247.247-.3.442-.3 1.128V18H9.428c-5.141 0-4.81.019-5.128-.299-.281-.281-.3-.383-.3-1.725v-1.17l.116-.025c.189-.041.728-.397.981-.647.385-.381.694-.923.828-1.454.072-.285.072-1.075 0-1.357a3.164 3.164 0 00-.828-1.457c-.254-.251-.792-.606-.983-.648l-.117-.025.012-1.247L4.02 6.7l.111-.189c.125-.213.318-.375.539-.454.122-.043 1.01-.054 4.74-.055L14 6v.573zm.643 3.496a1.066 1.066 0 00-.577.591c-.098.288-.098 2.392 0 2.68.126.369.538.66.934.66s.808-.291.934-.66c.098-.288.098-2.392 0-2.68-.173-.508-.762-.778-1.291-.591z"
      fill="currentColor"
    />
  </Icon>
);

Ticket.displayName = 'TicketIcon';
