import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const StatsChart: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M8.132 0.880 C 7.857 1.018,7.710 1.168,7.584 1.440 L 7.500 1.620 7.500 12.000 L 7.500 22.380 7.586 22.566 C 7.690 22.790,7.925 23.034,8.141 23.141 C 8.284 23.212,8.401 23.221,9.319 23.232 C 10.202 23.242,10.362 23.235,10.517 23.176 C 10.757 23.084,11.032 22.824,11.146 22.580 L 11.239 22.380 11.239 12.000 C 11.238 1.650,11.238 1.619,11.157 1.440 C 11.058 1.221,10.835 0.983,10.620 0.867 C 10.470 0.785,10.398 0.779,9.420 0.768 L 8.380 0.756 8.132 0.880 M18.680 4.603 C 18.421 4.720,18.174 4.978,18.080 5.230 C 18.001 5.440,18.000 5.554,18.000 13.874 C 18.000 23.291,17.975 22.504,18.288 22.859 C 18.595 23.208,18.636 23.218,19.792 23.233 C 20.933 23.247,21.062 23.226,21.352 22.978 C 21.442 22.901,21.566 22.735,21.628 22.609 L 21.740 22.380 21.740 13.860 C 21.740 5.788,21.736 5.332,21.669 5.189 C 21.561 4.959,21.322 4.718,21.100 4.614 C 20.907 4.524,20.865 4.521,19.880 4.521 C 18.941 4.522,18.846 4.528,18.680 4.603 M13.560 9.797 C 13.262 9.888,12.977 10.143,12.843 10.440 C 12.763 10.618,12.762 10.675,12.761 16.500 L 12.761 22.380 12.854 22.580 C 12.968 22.824,13.243 23.084,13.483 23.176 C 13.638 23.235,13.798 23.242,14.681 23.232 C 15.599 23.221,15.716 23.212,15.859 23.141 C 16.075 23.034,16.310 22.790,16.414 22.566 L 16.500 22.380 16.500 16.500 C 16.500 11.004,16.495 10.610,16.431 10.466 C 16.310 10.200,16.129 10.011,15.872 9.882 L 15.627 9.760 14.644 9.763 C 14.103 9.765,13.615 9.781,13.560 9.797 M3.122 14.282 C 2.908 14.326,2.621 14.515,2.469 14.712 C 2.233 15.018,2.237 14.933,2.249 18.823 L 2.260 22.380 2.372 22.609 C 2.434 22.735,2.558 22.901,2.648 22.978 C 2.938 23.226,3.067 23.247,4.208 23.233 C 5.364 23.218,5.405 23.208,5.713 22.858 C 6.017 22.513,6.003 22.730,5.991 18.677 L 5.980 15.060 5.862 14.847 C 5.735 14.618,5.539 14.440,5.294 14.330 C 5.165 14.273,4.991 14.260,4.220 14.254 C 3.714 14.249,3.220 14.262,3.122 14.282 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

StatsChart.displayName = 'StatsChartIcon';
