import { FC } from 'react';
import {
  Box,
  BoxProps,
  CheckboxProps,
  Flex,
  Text,
  TextProps,
  useCheckbox,
} from '@chakra-ui/react';
import { Icon } from 'icons';

export enum TileSizes {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

const sizesStyles = {
  [TileSizes.XS]: {
    container: {
      px: 2,
      py: 0.5,
    },
    label: {
      fontSize: 'xs',
      fontWeight: 'medium',
    },
    icon: {
      w: 4,
      h: 4,
    },
  },
  [TileSizes.SM]: {
    container: {
      px: 2,
      py: 1,
    },
    label: {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    icon: {
      w: 5,
      h: 5,
    },
  },
  [TileSizes.MD]: {
    container: {
      px: 2,
      py: 1,
    },
    label: {
      fontSize: 'lg',
      fontWeight: 'medium',
      px: 2,
    },
    icon: {
      w: 6,
      h: 6,
    },
  },
  [TileSizes.LG]: {
    container: {
      px: 2,
      py: 1,
    },
    label: {
      fontSize: 'xl',
      fontWeight: 'medium',
    },
    icon: {
      w: 6,
      h: 6,
    },
  },
};

export enum TileWidths {
  FULL = 'full',
  INLINE = 'inline',
}

const widthsStyles = {
  [TileWidths.FULL]: {
    width: '100%',
  },
  [TileWidths.INLINE]: {
    width: 'initial',
  },
};

export enum TileCheckPosition {
  LEFT = 'left',
  RIGHT = 'right',
  NONE = 'none',
}

export interface TileOptions {
  readonly width?: TileWidths;
  readonly size?: TileSizes;
  readonly checkPosition?: TileCheckPosition;
}

interface CheckBoxTileProps extends TileOptions {
  readonly iconName?: string;
  readonly testId?: string;
  readonly tileContainerProps?: BoxProps;
  readonly tileTextProps?: TextProps;
}

export const CheckboxTile: FC<CheckBoxTileProps & CheckboxProps> = ({
  value,
  isChecked,
  name,
  testId,
  ...props
}) => {
  const { getInputProps, getCheckboxProps } = useCheckbox({
    value,
    isChecked,
    name,
    ...props,
  });
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const {
    width = TileWidths.INLINE,
    size = TileSizes.SM,
    checkPosition = TileCheckPosition.RIGHT,
  } = props;
  const settings = {
    ...widthsStyles[width],
    ...sizesStyles[size],
    color: 'green',
    checkIcon: 'check',
  };

  return (
    <Flex
      className="container"
      borderRadius="md"
      backgroundColor={isChecked ? `${settings.color}.100` : 'blackAlpha.50'}
      {...settings.container}
      {...props?.tileContainerProps}
    >
      <Box as="label" position="relative" width="100%" className="label">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderStyle="solid"
          borderColor="none"
          bg="transparent"
          color="text.secondary"
          _checked={{
            color: 'text.primary',
            borderColor: 'bg.light',
          }}
          position="relative"
          data-testid={testId}
        >
          <Flex flexDirection="row" alignItems="center">
            <Flex
              flexDirection="row"
              alignItems="center"
              order={checkPosition === TileCheckPosition.LEFT ? 2 : 0}
            >
              <Text
                color={isChecked ? `${settings.color}.500` : 'inherit'}
                letterSpacing={isChecked ? 'normal' : '0.015em'}
                // {...getTextSpacing()}
                {...settings.label}
                {...props?.tileTextProps}
              >
                {name.toString().replace(/^\w/, c => c.toUpperCase())}
              </Text>
            </Flex>
            <Box ml={!isChecked && `-${2 * settings?.container?.px}`}>
              <Icon
                name="check"
                w={settings.icon.w}
                h={settings.icon.h}
                color={isChecked ? 'main.green' : 'transparent'}
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};
