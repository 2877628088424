import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Moon: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.32 2.705c-.633.215-1.864.928-2.56 1.482-1.982 1.579-3.271 3.857-3.666 6.481-.082.542-.094 1.996-.021 2.512.405 2.865 1.752 5.181 3.983 6.849 2.21 1.653 5.039 2.301 7.81 1.789 2.3-.425 4.468-1.728 5.948-3.578.387-.484.85-1.219 1.146-1.82.412-.836.452-.994.343-1.355a.96.96 0 00-.853-.693c-.201-.019-.305.004-.83.18-1.451.487-2.785.576-4.24.283-1.147-.231-2.465-.848-3.377-1.581-.323-.26-.937-.87-1.202-1.194A8.053 8.053 0 019 7c0-.895.136-1.688.448-2.62.176-.525.199-.629.18-.83a.96.96 0 00-.693-.853.868.868 0 00-.615.008zM7.033 6.161c-.053.523-.018 1.718.067 2.279.266 1.751 1.025 3.492 2.103 4.827 1.584 1.961 3.852 3.252 6.397 3.64.492.075 2.006.101 2.45.043.132-.018.23-.015.23.005 0 .065-.921.999-1.234 1.252-1.103.889-2.346 1.441-3.828 1.7-.551.096-1.878.095-2.438-.001a7.936 7.936 0 01-2.28-.729c-2.578-1.262-4.223-3.694-4.468-6.607-.182-2.164.63-4.454 2.148-6.046.326-.342.857-.821.882-.795a3.04 3.04 0 01-.03.432z"
      fill="currentColor"
    />
  </Icon>
);

Moon.displayName = 'MoonIcon';
