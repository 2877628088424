import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const BookmarkAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62 3.025c-.408.08-.533.114-.75.202-.88.358-1.549 1.137-1.794 2.093-.083.326-.084 13.031 0 13.357.281 1.096 1.044 1.878 2.184 2.238.178.056.9.064 6.56.075 4.306.008 6.448-.001 6.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.327.084-13.033 0-13.357-.307-1.197-1.264-2.089-2.448-2.281-.242-.039-12.659-.056-12.856-.017zM7 10.573c0 6.263-.022 5.807.3 6.129a.994.994 0 00.792.288c.164-.016.562-.199 2.056-.944L12 15.121l1.852.925c1.494.745 1.892.928 2.056.944a.994.994 0 00.792-.288c.322-.322.3.134.3-6.129V5h.573c.686 0 .881.052 1.128.299.322.322.3-.202.3 6.701s.022 6.379-.3 6.701c-.322.322.202.299-6.7.299-6.906 0-6.38.024-6.703-.3-.323-.323-.3.208-.288-6.782L5.02 5.7l.111-.189c.125-.213.318-.375.539-.454.098-.035.353-.054.74-.055L7 5v5.573zm8-.893c0 2.574-.008 4.68-.019 4.68-.01 0-.623-.301-1.363-.67l-1.344-.67h-.548l-1.344.67a78.4 78.4 0 01-1.363.67C9.01 14.36 9 12.254 9 9.68V5h6v4.68z"
      fill="currentColor"
    />
  </Icon>
);

BookmarkAlt.displayName = 'BookmarkAltIcon';
