import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Edit2: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M18.579 1.199 C 17.989 1.264,17.394 1.481,16.867 1.825 C 16.643 1.971,15.059 3.531,9.647 8.936 C 5.411 13.166,2.683 15.921,2.633 16.016 C 2.527 16.221,1.000 21.816,1.000 22.002 C 1.000 22.526,1.474 23.000,1.998 23.000 C 2.184 23.000,7.779 21.473,7.984 21.367 C 8.079 21.317,10.837 18.586,15.066 14.353 C 22.484 6.928,22.226 7.200,22.515 6.506 C 22.932 5.505,22.932 4.496,22.516 3.497 C 21.884 1.978,20.238 1.018,18.579 1.199 M19.542 3.262 C 19.804 3.334,20.183 3.581,20.374 3.804 C 20.844 4.353,20.947 5.146,20.635 5.800 C 20.519 6.041,20.027 6.544,13.741 12.835 L 6.971 19.609 5.221 20.087 C 4.259 20.350,3.464 20.557,3.455 20.548 C 3.446 20.539,3.654 19.742,3.917 18.777 L 4.396 17.024 11.168 10.257 C 17.440 3.989,17.959 3.480,18.200 3.364 C 18.614 3.165,19.064 3.131,19.542 3.262 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Edit2.displayName = 'Edit2Icon';
