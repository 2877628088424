import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ExternalLink: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.643 3.069C13.291 3.193 13 3.614 13 4c0 .242.119.521.299.701.298.298.306.299 2.418.299h1.862l-4.225 4.23c-3.874 3.879-4.23 4.246-4.289 4.427-.128.39-.044.766.234 1.044s.654.362 1.044.234c.181-.059.548-.415 4.427-4.289L19 6.421v1.862c0 2.112.001 2.12.299 2.418a.984.984 0 001.402 0C21.013 10.39 21 10.544 21 7s.013-3.39-.299-3.701c-.312-.312-.154-.299-3.718-.296-2.757.003-3.186.011-3.34.066zM5.62 5.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.326-.084 11.032 0 11.357.281 1.096 1.045 1.879 2.184 2.238.177.055.818.064 5.56.075 3.614.008 5.449-.001 5.633-.03a3.034 3.034 0 002.306-1.8c.221-.524.215-.448.231-2.953.016-2.649.021-2.598-.288-2.907a.984.984 0 00-1.403-.001c-.302.302-.299.274-.299 2.701s.003 2.399-.299 2.701c-.32.32.082.299-5.701.299-5.783 0-5.381.021-5.701-.299-.32-.32-.299.084-.297-5.718.001-4.209.011-5.191.055-5.313.079-.221.241-.414.454-.539L5.7 7.02 7.98 7l2.28-.02.19-.094c.601-.296.73-1.108.251-1.587-.304-.304-.263-.3-2.778-.294a87.455 87.455 0 00-2.303.02z"
      fill="currentColor"
    />
  </Icon>
);

ExternalLink.displayName = 'ExternalLinkIcon';
