import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Icon } from 'icons';
import { DaysOfWeek } from 'types';

export enum DayOfWeekState {
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
  NEGATIVE = 'negative',
}

const settings = {
  [DayOfWeekState.POSITIVE]: {
    backgroundColor: 'green.100',
    color: 'green.500',
    icon: 'check',
    iconColor: 'green.500',
  },
  [DayOfWeekState.NEUTRAL]: {
    backgroundColor: 'gray.50',
    color: 'gray.600',
    icon: 'minussmall',
    iconColor: 'gray.200',
  },
  [DayOfWeekState.NEGATIVE]: {
    backgroundColor: 'red.100',
    color: 'red.500',
    icon: 'x',
    iconColor: 'red.500',
  },
};

interface DayOfWeekCardProps {
  state?: DayOfWeekState;
  dow: DaysOfWeek;
  handleUpdateState: (dow: DaysOfWeek) => void;
}

export const DayOfWeekCard: FC<DayOfWeekCardProps> = ({
  state = DayOfWeekState.NEUTRAL,
  dow,
  handleUpdateState,
}) => {
  const getSettings = () => settings[state];
  const onUpdate = () => handleUpdateState(dow);

  return (
    <Flex
      width={16}
      gridColumn="span 2"
      className="card"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="60px"
      backgroundColor={getSettings().backgroundColor}
      color={getSettings().color}
      borderRadius="md"
      cursor="pointer"
      onClick={onUpdate}
    >
      {getSettings().icon && (
        <Icon
          name={getSettings().icon}
          color={getSettings().iconColor}
          h="24px"
          w="24px"
        />
      )}
      <Text fontWeight="semi">{dow.substring(0, 3).toUpperCase()}</Text>
    </Flex>
  );
};
