import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const BookDollar: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h7c1.148 0 2 .852 2 2a1 1 0 1 0 2 0c0-1.148.852-2 2-2a1 1 0 1 0 0-2c-.735 0-1.416.186-2 .515V7c0-1.648 1.352-3 3-3h5v2a1 1 0 1 0 2 0V3a1 1 0 0 0-1-1h-6a5 5 0 0 0-4 2.007A5 5 0 0 0 8 2H2Zm9 5c0-1.648-1.352-3-3-3H3v13h6c.735 0 1.416.186 2 .515V7Zm10 3a1 1 0 1 0-2 0v.05a2.5 2.5 0 0 0 .5 4.95h1a.5.5 0 0 1 0 1H18a1 1 0 1 0 0 2h1a1 1 0 1 0 2 0v-.05a2.5 2.5 0 0 0-.5-4.95h-1a.5.5 0 0 1 0-1H22a1 1 0 1 0 0-2h-1Z"
      fill="currentColor"
    />
  </Icon>
);

BookDollar.displayName = 'BookDollarIcon';
