import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Rectangle: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.689 46H28.804C22.2849 46 17 51.2848 17 57.804V142.697C17 149.216 22.2849 154.501 28.804 154.501H170.689C177.209 154.501 182.493 149.216 182.493 142.697V57.804C182.493 51.2848 177.209 46 170.689 46Z"
      fill="currentColor"
    />
  </Icon>
);

Rectangle.displayName = 'RectangleShape';
