// inspired by: https://github.com/mvasin/react-div-100vh
import { useEffect, useState } from 'react';

const isClient = () =>
  typeof window !== 'undefined' && typeof document !== 'undefined';

function measureHeight(): number | null {
  if (!isClient()) return null;
  return document.documentElement?.clientHeight || window.innerHeight;
}

function useWasRenderedOnClientAtLeastOnce() {
  const [wasRenderedOnClientAtLeastOnce, setWasRenderedOnClientAtLeastOnce] =
    useState(false);

  useEffect(() => {
    if (isClient()) {
      setWasRenderedOnClientAtLeastOnce(true);
    }
  }, []);

  return wasRenderedOnClientAtLeastOnce;
}

export const useFullHeight = (): number | null => {
  const [height, setHeight] = useState<number | null>(measureHeight);

  const wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce();

  useEffect(() => {
    if (!wasRenderedOnClientAtLeastOnce) return;

    function setMeasuredHeight() {
      const measuredHeight = measureHeight();
      setHeight(measuredHeight);
    }

    window.addEventListener('resize', setMeasuredHeight);
    return () => window.removeEventListener('resize', setMeasuredHeight);
  }, [wasRenderedOnClientAtLeastOnce]);
  return wasRenderedOnClientAtLeastOnce ? height : null;
};
