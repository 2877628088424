import { Button } from '@chakra-ui/react';
import { colors } from 'styles/config/colors.config';

interface PillButtonWithIconProps {
  text: string;
  icon: React.ReactElement;
  onClick: () => void;
  isSelected: boolean;
  focusColor?: string;
}

export const PillButtonWithIcon = ({
  text,
  icon,
  onClick,
  isSelected,
  focusColor = colors.green[500],
}: PillButtonWithIconProps) => {
  return (
    <Button
      colorScheme="none"
      leftIcon={icon}
      borderColor={isSelected ? focusColor : 'blackAlpha.300'}
      borderWidth={2}
      backgroundColor={isSelected ? focusColor : 'white'}
      textColor={isSelected ? 'white' : 'blackAlpha.400'}
      variant="solid"
      px={6}
      borderRadius="full"
      onClick={onClick}
      _hover={
        !isSelected && {
          borderColor: focusColor,
          textColor: focusColor,
        }
      }
    >
      {text}
    </Button>
  );
};
