import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DownloadAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.664 2.063 C 11.436 2.146,11.257 2.297,11.131 2.511 L 11.020 2.700 11.009 7.638 L 10.999 12.577 9.269 10.854 C 7.719 9.309,7.520 9.124,7.343 9.065 C 6.953 8.936,6.577 9.021,6.298 9.300 C 5.995 9.602,5.922 10.053,6.113 10.444 C 6.174 10.570,6.942 11.363,8.829 13.245 C 11.219 15.629,11.478 15.876,11.657 15.935 C 11.906 16.017,12.094 16.017,12.343 15.935 C 12.522 15.876,12.781 15.629,15.171 13.245 C 17.058 11.363,17.826 10.570,17.887 10.444 C 18.078 10.053,18.005 9.602,17.702 9.300 C 17.423 9.021,17.047 8.936,16.657 9.065 C 16.480 9.124,16.281 9.309,14.730 10.854 L 13.000 12.578 13.000 7.716 C 13.000 2.251,13.020 2.618,12.701 2.299 C 12.429 2.027,12.018 1.933,11.664 2.063 M2.664 14.063 C 2.436 14.146,2.257 14.297,2.131 14.511 L 2.020 14.700 2.009 16.980 C 1.996 19.487,2.003 19.586,2.238 20.154 C 2.392 20.528,2.582 20.809,2.886 21.114 C 3.321 21.548,3.815 21.814,4.421 21.939 C 4.860 22.030,19.151 22.030,19.580 21.940 C 20.789 21.685,21.685 20.789,21.940 19.580 C 21.987 19.353,21.999 18.882,21.999 17.077 C 22.000 14.562,22.004 14.602,21.700 14.298 C 21.390 13.988,20.932 13.920,20.531 14.126 C 20.315 14.238,20.103 14.491,20.042 14.711 C 20.016 14.805,20.000 15.650,20.000 17.003 C 20.000 19.427,20.003 19.399,19.701 19.701 C 19.377 20.025,20.023 20.000,12.000 20.000 C 3.977 20.000,4.623 20.025,4.299 19.701 C 3.997 19.399,4.000 19.427,4.000 17.000 C 4.000 14.573,4.003 14.601,3.701 14.299 C 3.429 14.027,3.018 13.933,2.664 14.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

DownloadAlt.displayName = 'DownloadAltIcon';
