import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MailAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M3.400 3.066 C 2.484 3.242,1.582 3.993,1.224 4.876 C 0.991 5.453,1.000 5.156,1.000 12.000 C 1.000 18.841,0.991 18.548,1.223 19.121 C 1.516 19.840,2.161 20.486,2.876 20.776 C 3.460 21.012,2.966 21.000,12.000 21.000 C 19.231 21.000,20.321 20.992,20.576 20.940 C 21.766 20.694,22.694 19.766,22.940 18.576 C 23.031 18.135,23.031 5.865,22.940 5.424 C 22.749 4.500,22.150 3.730,21.303 3.319 C 20.592 2.975,21.315 2.999,11.966 3.004 C 4.928 3.007,3.655 3.016,3.400 3.066 M20.259 5.045 C 20.468 5.102,20.722 5.281,20.809 5.430 C 20.875 5.543,20.875 5.552,20.809 5.608 C 20.588 5.797,12.051 11.737,12.000 11.737 C 11.949 11.737,3.412 5.797,3.191 5.608 C 3.125 5.552,3.125 5.543,3.191 5.430 C 3.274 5.288,3.533 5.101,3.723 5.047 C 3.926 4.988,20.049 4.986,20.259 5.045 M11.705 13.951 C 11.912 14.013,12.087 14.013,12.290 13.952 C 12.386 13.924,14.148 12.716,16.715 10.920 L 20.980 7.936 20.990 13.017 C 21.002 18.730,21.020 18.369,20.708 18.690 C 20.379 19.028,21.173 19.000,12.000 19.000 C 2.836 19.000,3.620 19.028,3.296 18.694 C 2.979 18.368,3.000 18.766,3.000 13.035 L 3.000 7.924 7.270 10.913 C 9.941 12.782,11.602 13.920,11.705 13.951 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

MailAlt.displayName = 'MailAltIcon';
