import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PaperAirplane: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.643 2.069c-.388.137-.152-.308-5.058 9.513-4.975 9.959-4.67 9.302-4.524 9.74.124.375.53.676.916.679.068.001 2.127-.444 4.577-.987l4.454-.989 4.446.988c2.445.544 4.501.988 4.569.988.385-.003.792-.304.916-.679.146-.438.451.219-4.524-9.74-3.739-7.484-4.584-9.148-4.718-9.284a.992.992 0 00-1.054-.229zM15.59 12.42c1.964 3.927 3.561 7.14 3.549 7.14-.019 0-5.849-1.298-6.049-1.347l-.09-.022v-3.668c0-4.129.016-3.91-.299-4.224A1.055 1.055 0 0012 10c-.242 0-.521.119-.701.299-.315.314-.299.094-.299 4.227v3.672l-.09.022c-.22.053-6.031 1.34-6.05 1.34-.011 0 1.586-3.213 3.55-7.14 1.963-3.927 3.579-7.14 3.59-7.14.011 0 1.627 3.213 3.59 7.14z"
      fill="currentColor"
    />
  </Icon>
);

PaperAirplane.displayName = 'PaperAirplaneIcon';
