import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const VolumeUp: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.534 3.057c-.532.129-.506.107-3.015 2.608L5.178 8h-.687c-.378 0-.782.02-.899.044a2.062 2.062 0 00-1.548 1.548c-.061.292-.061 4.524 0 4.816.157.75.798 1.391 1.548 1.548.117.024.521.044.899.044h.687l2.341 2.335c2.15 2.145 2.362 2.345 2.601 2.456.302.14.597.209.894.209.902 0 1.756-.699 1.942-1.592.062-.3.062-14.516 0-14.816a1.976 1.976 0 00-.552-.995 1.98 1.98 0 00-1.87-.54zm7.537 1.62a1.013 1.013 0 00-.677 1.173c.051.228.146.363.661.938 1.288 1.441 2.021 3.539 1.932 5.532-.09 1.989-.824 3.721-2.206 5.202a2.351 2.351 0 00-.311.402 1.005 1.005 0 00.398 1.303c.165.097.234.113.492.112.384-.001.536-.079.894-.459 3.424-3.63 3.663-9.243.557-13.114-.434-.542-.803-.92-.988-1.015-.178-.09-.578-.13-.752-.074zM11 12.01v6.97l-2.49-2.49L6.02 14H4v-3.996l.93-.012c.83-.011.947-.02 1.09-.091.103-.051 1.01-.93 2.549-2.47a211.948 211.948 0 012.41-2.391c.012 0 .021 3.136.021 6.97zm4.162-4.467c-.388.168-.616.508-.615.917.001.315.071.466.37.802.477.535.781 1.088.953 1.736.135.504.151 1.367.035 1.862a3.941 3.941 0 01-.822 1.672l-.349.428c-.146.18-.226.489-.189.723.04.251.248.557.47.695.282.173.66.193.956.048.522-.255 1.375-1.501 1.706-2.491.23-.69.297-1.121.299-1.915.002-.792-.047-1.139-.258-1.828-.31-1.012-1.195-2.344-1.735-2.613-.233-.116-.599-.132-.821-.036z"
      fill="currentColor"
    />
  </Icon>
);

VolumeUp.displayName = 'VolumeUpIcon';
