import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Bag: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.094 1.583 C 10.632 1.661,10.078 1.847,9.626 2.077 C 8.209 2.798,7.210 4.099,6.855 5.686 C 6.814 5.869,6.780 6.267,6.764 6.740 L 6.740 7.500 5.040 7.520 L 3.340 7.540 3.102 7.657 C 2.788 7.811,2.543 8.063,2.387 8.392 L 2.260 8.660 2.260 14.140 L 2.260 19.620 2.350 19.932 C 2.726 21.229,3.660 22.125,4.951 22.428 C 5.243 22.496,5.637 22.500,12.040 22.500 L 18.820 22.500 19.160 22.410 C 19.835 22.230,20.364 21.916,20.840 21.413 C 21.266 20.963,21.494 20.551,21.670 19.915 C 21.735 19.677,21.740 19.290,21.740 14.160 L 21.740 8.660 21.613 8.392 C 21.457 8.063,21.212 7.811,20.898 7.657 L 20.660 7.540 18.960 7.520 L 17.260 7.500 17.234 6.720 C 17.205 5.849,17.125 5.429,16.863 4.779 C 16.190 3.111,14.636 1.867,12.867 1.580 C 12.417 1.507,11.538 1.508,11.094 1.583 M12.913 3.118 C 14.279 3.455,15.376 4.599,15.667 5.992 C 15.708 6.187,15.733 6.520,15.736 6.900 L 15.740 7.500 12.000 7.500 L 8.260 7.500 8.264 6.900 C 8.271 5.979,8.430 5.385,8.841 4.745 C 9.417 3.848,10.291 3.261,11.350 3.061 C 11.759 2.983,12.470 3.009,12.913 3.118 M20.240 14.216 C 20.240 19.213,20.237 19.443,20.165 19.675 C 20.123 19.808,20.043 19.996,19.986 20.093 C 19.852 20.323,19.459 20.684,19.222 20.795 C 18.761 21.011,19.113 21.002,11.897 20.991 L 5.220 20.980 4.990 20.887 C 4.507 20.692,4.068 20.252,3.872 19.768 L 3.780 19.540 3.769 14.270 L 3.759 9.000 11.999 9.000 L 20.240 9.000 20.240 14.216 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Bag.displayName = 'BagIcon';
