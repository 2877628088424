import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowUpNarrow: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.64 2.068c-.154.053-.488.369-2.311 2.187-1.516 1.511-2.156 2.176-2.217 2.301A.99.99 0 007.298 7.7c.279.28.656.364 1.045.234.174-.057.342-.21 1.427-1.288L11 5.423v7.861c0 8.825-.026 8.092.299 8.417a.984.984 0 001.402 0c.325-.325.299.408.299-8.417V5.423l1.23 1.223c1.085 1.078 1.253 1.231 1.427 1.288.389.13.766.046 1.045-.234a.99.99 0 00.186-1.144c-.061-.125-.701-.79-2.217-2.301-1.924-1.919-2.15-2.131-2.328-2.19a1.053 1.053 0 00-.703.003z"
      fill="currentColor"
    />
  </Icon>
);

ArrowUpNarrow.displayName = 'ArrowUpNarrowIcon';
