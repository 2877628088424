import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MinusSmall: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.643 11.069C5.291 11.193 5 11.614 5 12c0 .242.119.521.299.701.322.322-.202.299 6.701.299s6.379.023 6.701-.299a.984.984 0 000-1.402c-.323-.323.205-.299-6.718-.296-5.475.003-6.183.01-6.34.066z"
      fill="currentColor"
    />
  </Icon>
);

MinusSmall.displayName = 'MinusSmallIcon';
