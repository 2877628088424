import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Navigation: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.664 1.061 C 11.456 1.140,11.266 1.289,11.167 1.449 C 11.032 1.669,4.030 20.723,4.009 20.926 C 3.941 21.610,4.658 22.173,5.307 21.944 C 5.394 21.914,6.935 21.049,8.732 20.022 L 12.000 18.154 15.268 20.022 C 17.065 21.049,18.606 21.914,18.693 21.944 C 19.342 22.173,20.059 21.610,19.991 20.926 C 19.980 20.816,18.515 16.785,16.429 11.125 C 13.770 3.910,12.857 1.474,12.769 1.365 C 12.526 1.065,12.019 0.926,11.664 1.061 M14.534 11.771 C 15.914 15.518,17.053 18.623,17.066 18.671 C 17.085 18.742,16.605 18.483,14.743 17.419 C 13.165 16.517,12.332 16.063,12.199 16.032 C 12.049 15.998,11.951 15.998,11.801 16.032 C 11.668 16.063,10.835 16.517,9.257 17.419 C 7.395 18.483,6.915 18.742,6.934 18.671 C 6.988 18.465,11.978 4.960,12.000 4.960 C 12.013 4.960,13.154 8.025,14.534 11.771 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Navigation.displayName = 'NavigationIcon';
