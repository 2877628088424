import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Lake: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.5 54.9999C114.259 57.018 98.2888 57.1707 81.0255 50.8031C73.5375 48.0396 65.8031 45.0724 57.8413 45.6327C45.4435 46.5051 35.1816 55.8971 28.3129 66.3462C16.9893 83.5386 12.2691 105.971 19.3273 125.36C26.3856 144.75 46.6694 159.459 66.9785 156.657C77.569 155.192 87.1991 149.449 97.7896 147.921C111.059 146.01 124.898 150.85 137.896 147.488C152.044 143.82 162.06 131.276 169.598 118.668C176.227 107.582 181.888 95.5031 182.868 82.6025C183.847 69.7019 179.399 55.7953 169.055 48.1288C158.71 40.4623 148.816 53.7348 135.5 54.9999Z"
      fill="currentColor"
    />
  </Icon>
);

Lake.displayName = 'LakeShape';
