import { FC, useEffect, useState } from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { Block } from './Notion';
import { BlockType, ElementSizes } from './types';

const columnPaddingByBreakPointAndSize = (vp, size) => {
  switch (size) {
    case 1:
      return {};
    case 2:
      if (vp === 'base') return {};
      return { pr: 2 };
    case 3:
      if (vp === 'base') return {};
      return { pr: 1 };
    case 4:
      if (vp === 'base') return { pr: 1 };
      return { pr: 1 };
    default:
      return { pr: 1 };
  }
};

const columnSizeByBreakPointAndSize = (vp, size) => {
  switch (size) {
    case 1:
      return '100%';
    case 2:
      return '50%';
    case 3:
      if (vp === 'base') return '100%';
      return '33%';
    case 4:
      if (vp === 'base') return '50%';
      return '25%';
    default:
      return '100%';
  }
};

interface BlockColumnProps {
  blocks: Array<BlockType>;
  env: string;
  size: ElementSizes;
  columnSize?: number;
  columnIndex?: number;
}

export const BlockColumn: FC<BlockColumnProps> = ({
  blocks,
  env,
  size,
  columnSize = 1,
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const columnFlex = useBreakpointValue({
    base: columnSizeByBreakPointAndSize('base', columnSize),
    md: columnSizeByBreakPointAndSize('md', columnSize),
  });

  const columnPadding = useBreakpointValue({
    base: columnPaddingByBreakPointAndSize('base', columnSize),
    md: columnPaddingByBreakPointAndSize('md', columnSize),
  });

  useEffect(() => {
    if (hasLoaded) return;
    setHasLoaded(true);
  }, [hasLoaded]);

  if (!hasLoaded) return null;

  return (
    <Flex
      direction="column"
      flex={`${columnFlex ? columnFlex : 1} 1 0`}
      {...columnPadding}
      sx={{
        ':last-of-type': {
          mr: -1 * columnPadding?.pr,
        },
      }}
    >
      {blocks?.map((child, index) => (
        <Block block={child} env={env} size={size} key={index} />
      ))}
    </Flex>
  );
};
