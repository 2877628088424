import { FC } from 'react';
import { BoxProps, FormControl } from '@chakra-ui/react';
import { SelectOption } from 'types';
import { Select, SelectProps } from 'ui';
import { FieldLabel, FieldLabelProps } from './FieldLabel';

interface UnconnectedSelectProps {
  containerProps?: BoxProps;
  labelProps?: FieldLabelProps;
  id: string;
  options?: Array<SelectOption>;
  placeholder?: string;
  type?: string;
  selectProps?: SelectProps;
  value?: Array<SelectOption>;
  onChange?: (value: SelectOption) => void;
}

export const UnconnectedSelect: FC<UnconnectedSelectProps> = ({
  containerProps,
  labelProps,
  id,
  options,
  placeholder = 'Select',
  selectProps,
  value,
  onChange,
}) => (
  <FormControl {...containerProps}>
    {labelProps?.text && <FieldLabel {...labelProps} />}
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      options={options || []}
      singleValueStyle="tag"
      name={id}
      {...selectProps}
    />
  </FormControl>
);
