import { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { BlockLink } from './BlockLink';
import { BlockTextType } from './types';

interface BlockTextProps {
  text: Array<BlockTextType>;
  id?: string;
}

export const BlockText: FC<BlockTextProps> = ({ text, id }) => {
  return (
    <>
      {text?.map((value, index) => {
        const {
          annotations: { bold, code, color, italic, strikethrough, underline },
          text,
        } = value || {};
        return (
          <Text
            key={`${id}-${index}-${text?.content}`}
            fontWeight={bold && 'semi'}
            fontFamily={code && 'mono'}
            fontStyle={italic ? 'italic' : underline ? 'underline' : 'unset'}
            textDecoration={
              text?.link
                ? 'underline'
                : strikethrough
                  ? 'line-through'
                  : undefined
            }
            style={color !== 'default' ? { color } : {}}
            display={
              text?.content?.startsWith('\n') ? 'inline-block' : 'inline'
            }
            lineHeight="short"
            as="span"
          >
            {text?.link ? (
              <BlockLink href={text?.link?.url} label={text.content} />
            ) : (
              text?.content
            )}
          </Text>
        );
      })}
    </>
  );
};
