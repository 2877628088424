import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DataKeys, ReflexResponse } from 'types';

export const useDataMutation = <T>(
  mutationFunction: (update: T) => Promise<ReflexResponse>,
  cacheKeys: Array<DataKeys | string> = [],
) => {
  const queryClient = useQueryClient();

  const invalidateCache = cacheKeys => {
    if (!cacheKeys || !cacheKeys?.length) return;
    cacheKeys.forEach(key => {
      queryClient.invalidateQueries({ queryKey: [key] });
    });
    queryClient.invalidateQueries(cacheKeys);
  };

  const mutate = useMutation((update: T) => mutationFunction(update), {
    retry: 0,
    onSuccess: () => invalidateCache(cacheKeys),
  });

  return {
    invalidateCache,
    mutate,
  };
};
