import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const TrendingDown: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.643 6.069C2.291 6.193 2 6.614 2 7c0 .08.029.234.065.343.059.18.34.473 3.19 3.328 2.254 2.258 3.174 3.155 3.301 3.216.244.119.509.137.77.053.2-.065.329-.185 1.945-1.794l1.73-1.725 2.789 2.79L18.58 16h-2.863c-3.228 0-3.109-.01-3.418.299a.984.984 0 000 1.402c.317.317.038.299 4.701.299 4.663 0 4.384.018 4.701-.299.317-.317.299-.038.299-4.701 0-4.663.018-4.384-.299-4.701a.984.984 0 00-1.402 0c-.309.309-.299.19-.299 3.418v2.862l-3.23-3.225c-2.948-2.942-3.247-3.23-3.427-3.289a.987.987 0 00-.686 0c-.177.059-.376.244-1.927 1.789L9 11.578 6.27 8.854c-2.482-2.477-2.748-2.73-2.927-2.789a1.032 1.032 0 00-.7.004z"
      fill="currentColor"
    />
  </Icon>
);

TrendingDown.displayName = 'TrendingDownIcon';
