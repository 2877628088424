import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ViewBoards: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 4.023l-.29.06c-.881.179-1.734.894-2.087 1.75-.235.569-.223.239-.223 6.167 0 4.535.009 5.497.056 5.677.281 1.098 1.061 1.896 2.184 2.235.26.079 2.747.118 3.182.05.515-.079 1.079-.326 1.437-.627l.116-.098.209.158c.366.278.862.488 1.324.563.329.054 2.618.053 2.948 0 .491-.08 1.054-.33 1.403-.623l.116-.098.209.158c.366.278.862.488 1.324.563.329.054 2.618.053 2.948 0 1.179-.191 2.146-1.092 2.448-2.278.083-.327.083-11.033 0-11.357-.307-1.197-1.264-2.09-2.444-2.28-.334-.054-2.621-.055-2.952-.001a3.06 3.06 0 00-1.318.558l-.21.159-.21-.16a3.522 3.522 0 00-1.01-.502c-.199-.058-.469-.072-1.589-.086-1.371-.018-1.713.004-2.1.133a3.384 3.384 0 00-.881.456L9 4.759l-.21-.16a3.564 3.564 0 00-1.01-.503c-.203-.058-.456-.07-1.66-.079a36.12 36.12 0 00-1.5.006zm2.72 2.043c.115.039.263.135.361.233.32.32.299-.082.299 5.701 0 5.783.021 5.381-.299 5.701-.28.281-.385.299-1.701.299-1.317 0-1.42-.018-1.702-.3-.321-.321-.3.088-.289-5.782L4.02 6.7l.111-.189c.061-.103.173-.236.25-.294.264-.202.352-.213 1.602-.215.981-.002 1.193.008 1.357.064zm6 0c.115.039.263.135.361.233.32.32.299-.082.299 5.701 0 5.783.021 5.381-.299 5.701-.28.281-.385.299-1.701.299-1.317 0-1.42-.018-1.702-.3-.321-.321-.3.088-.289-5.782L10.02 6.7l.111-.189c.061-.103.173-.236.25-.294.264-.202.352-.213 1.602-.215.981-.002 1.193.008 1.357.064zm6 0c.115.039.263.135.361.233.32.32.299-.082.299 5.701 0 5.783.021 5.381-.299 5.701-.28.281-.385.299-1.701.299-1.317 0-1.42-.018-1.702-.3-.321-.321-.3.088-.289-5.782L16.02 6.7l.111-.189c.061-.103.173-.236.25-.294.264-.202.352-.213 1.602-.215.981-.002 1.193.008 1.357.064z"
      fill="currentColor"
    />
  </Icon>
);

ViewBoards.displayName = 'ViewBoardsIcon';
