import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PaperClip: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 2.065a5.197 5.197 0 00-2.766 1.16c-.23.195-6.819 6.951-7.113 7.295-.484.566-1.006 1.506-1.26 2.27a7.054 7.054 0 00-.002 4.42c.168.509.629 1.419.931 1.838 1.833 2.541 5.033 3.571 7.984 2.569.234-.079.651-.26.926-.402.969-.499.943-.475 4.694-4.213 2.371-2.362 3.458-3.472 3.51-3.582.093-.199.1-.605.014-.811a1.113 1.113 0 00-.478-.501c-.217-.116-.657-.12-.867-.007-.082.043-1.684 1.601-3.56 3.461-3.596 3.563-3.646 3.608-4.341 3.94-.721.344-1.327.477-2.172.477-1.071 0-1.917-.255-2.812-.847-.388-.257-1.085-.958-1.343-1.352-1.12-1.711-1.122-3.858-.003-5.553.113-.172.371-.486.572-.699.613-.647 6.436-6.617 6.586-6.751.356-.321.921-.588 1.439-.681.394-.07.597-.069 1.021.005 1.267.221 2.222 1.184 2.443 2.464.132.76-.051 1.555-.511 2.226-.065.094-1.619 1.714-3.455 3.6-2.522 2.59-3.384 3.452-3.527 3.525-.496.255-1.136.014-1.361-.513-.099-.23-.081-.588.042-.823.078-.151.934-1.029 3.49-3.58 2.716-2.711 3.406-3.42 3.483-3.58a1.001 1.001 0 00-.444-1.312c-.215-.114-.653-.12-.88-.012-.184.088-6.67 6.532-7.065 7.019-.474.585-.682 1.164-.683 1.905-.002 1.15.592 2.128 1.617 2.662.447.233.837.325 1.371.325.714 0 1.32-.208 1.846-.635.19-.153 5.228-5.296 6.697-6.835a5.094 5.094 0 001.343-2.566c.102-.523.093-1.391-.022-1.928-.42-1.973-1.932-3.485-3.881-3.882-.402-.082-1.142-.131-1.463-.096z"
      fill="currentColor"
    />
  </Icon>
);

PaperClip.displayName = 'PaperClipIcon';
