import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { getFullLocationName } from 'helpers';
import Link from 'next/link';
import { APP_BUDGETS, location } from 'types';

interface EmptyBudgetWidgetProps {
  locations?: location[];
  selectedLocation?: string;
  canEditLocations?: location[];
}

export const EmptyBudgetWidget: FC<EmptyBudgetWidgetProps> = ({
  locations,
  selectedLocation,
  canEditLocations,
}) => {
  const activeLocation =
    locations?.find(location => location?.uuid === selectedLocation) || {};
  const canEdit = canEditLocations
    ?.map(l => l?.uuid)
    .includes(selectedLocation);
  if (!Object.keys(activeLocation)?.length) return null;
  return (
    <Flex>
      <Text lineHeight="shorter" color="blackAlpha.600" maxWidth="28ch" px={3}>
        {`${getFullLocationName(activeLocation)} does not have have a budget${canEdit ? '' : ' set'} for the current period.`}
        {canEdit && (
          <>
            {' Set one '}
            <Text
              display="inline"
              textDecoration="underline"
              as="span"
              textUnderlineOffset="2px"
              color="main.blue"
            >
              <Link href={APP_BUDGETS}>{'here'}</Link>
            </Text>
            {'.'}
          </>
        )}
      </Text>
    </Flex>
  );
};
