import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const NavigationAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M21.600 1.094 C 21.044 1.327,2.485 10.141,2.371 10.226 C 1.854 10.613,1.904 11.496,2.463 11.842 C 2.560 11.902,3.839 12.239,6.391 12.878 L 10.176 13.824 11.122 17.609 C 11.773 20.209,12.098 21.439,12.160 21.540 C 12.499 22.089,13.362 22.143,13.763 21.640 C 13.873 21.502,22.861 2.565,22.959 2.264 C 23.071 1.923,22.918 1.444,22.626 1.222 C 22.322 0.990,21.957 0.944,21.600 1.094 M19.825 4.250 C 19.794 4.321,18.351 7.368,16.619 11.020 C 14.887 14.672,13.435 17.738,13.392 17.833 C 13.349 17.929,13.304 17.997,13.293 17.986 C 13.281 17.975,12.970 16.757,12.600 15.280 C 12.077 13.192,11.904 12.560,11.823 12.438 C 11.617 12.126,11.610 12.124,8.720 11.400 C 7.243 11.030,6.025 10.718,6.013 10.706 C 6.001 10.694,6.052 10.659,6.126 10.627 C 6.200 10.595,9.320 9.118,13.060 7.345 C 16.800 5.572,19.865 4.121,19.871 4.120 C 19.876 4.120,19.856 4.179,19.825 4.250 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

NavigationAlt.displayName = 'NavigationAltIcon';
