import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Help: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.268 3.047 C 10.778 3.097,10.075 3.283,9.624 3.480 C 9.053 3.731,8.561 4.073,8.077 4.557 C 7.391 5.244,6.931 6.025,6.697 6.900 C 6.503 7.624,6.526 7.973,6.789 8.280 C 7.173 8.728,7.811 8.737,8.196 8.298 C 8.353 8.119,8.390 8.028,8.458 7.641 C 8.662 6.488,9.518 5.489,10.638 5.098 C 11.444 4.816,12.802 4.816,13.594 5.097 C 14.216 5.319,15.032 6.020,15.295 6.560 C 15.702 7.395,15.630 8.482,15.114 9.286 C 14.881 9.650,14.300 10.200,13.684 10.640 C 12.664 11.369,12.433 11.549,12.091 11.883 C 11.189 12.765,10.765 13.679,10.696 14.890 C 10.661 15.506,10.801 15.813,11.207 16.016 C 11.554 16.189,11.991 16.128,12.275 15.868 C 12.432 15.724,12.559 15.407,12.560 15.160 C 12.560 15.039,12.579 14.825,12.602 14.684 C 12.739 13.844,13.147 13.326,14.340 12.482 C 15.227 11.854,15.599 11.552,16.042 11.094 C 16.827 10.285,17.216 9.512,17.381 8.440 C 17.693 6.408,16.719 4.594,14.774 3.590 C 14.318 3.354,14.059 3.258,13.616 3.161 C 12.925 3.011,12.041 2.968,11.268 3.047 M11.349 17.280 C 10.607 17.411,10.058 18.147,10.139 18.902 C 10.193 19.406,10.529 19.882,10.990 20.106 C 11.194 20.205,11.265 20.218,11.620 20.219 C 11.983 20.220,12.042 20.209,12.259 20.102 C 12.565 19.952,12.834 19.683,12.982 19.379 C 13.085 19.167,13.098 19.097,13.099 18.759 C 13.100 18.409,13.090 18.357,12.969 18.112 C 12.668 17.500,12.011 17.163,11.349 17.280 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Help.displayName = 'HelpIcon';
