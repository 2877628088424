import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Microphone: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.31 1.063a3.917 3.917 0 00-2.131 1.116 3.868 3.868 0 00-1.12 2.175c-.053.309-.06.835-.05 3.812.014 3.391.016 3.46.1 3.774.413 1.53 1.519 2.612 3.03 2.965.465.109 1.32.102 1.78-.014a4.042 4.042 0 002.987-3.031c.069-.299.074-.55.074-3.86 0-3.922.008-3.782-.267-4.477-.692-1.747-2.563-2.792-4.403-2.46zm1.098 1.981c.75.157 1.39.798 1.548 1.548.03.144.044 1.229.044 3.408s-.014 3.264-.044 3.408C13.768 12.31 12.92 13 12 13c-.92 0-1.768-.69-1.956-1.592-.061-.295-.061-6.521 0-6.816a2.063 2.063 0 011.49-1.535c.263-.064.604-.069.874-.013zm-7.765 7.025a1.066 1.066 0 00-.577.591c-.11.322-.071 1.073.1 1.94.23 1.173.846 2.488 1.593 3.4 1.189 1.454 2.772 2.43 4.572 2.819.257.056.513.101.568.101h.1V21H9.428c-1.79 0-1.836.006-2.128.299a.984.984 0 000 1.402c.317.317.038.299 4.7.299 4.664 0 4.385.018 4.702-.299a.984.984 0 000-1.402c-.292-.293-.34-.299-2.128-.299H13v-2.08h.09c.049 0 .3-.044.558-.098a7.993 7.993 0 005.53-4.314c.426-.866.683-1.77.78-2.742.088-.87.034-1.176-.257-1.467a.984.984 0 00-1.403.001c-.215.215-.285.439-.313 1.009a5.882 5.882 0 01-.67 2.45c-.852 1.647-2.393 2.786-4.255 3.148-.514.099-1.582.099-2.12-.002-2.757-.515-4.786-2.824-4.925-5.604-.028-.562-.098-.786-.313-1.001a.999.999 0 00-1.06-.231z"
      fill="currentColor"
    />
  </Icon>
);

Microphone.displayName = 'MicrophoneIcon';
