import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Bookmark: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62 2.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.247.602-.227-.147-.24 8.983-.013 9.368-.038 8.576.289 8.903.21.21.503.317.794.289.169-.017.786-.31 3.556-1.695L12 18.621l3.351 1.674c2.77 1.385 3.387 1.678 3.556 1.695a.994.994 0 00.794-.289c.327-.327.302.465.289-8.903-.013-9.088.005-8.375-.227-8.953-.367-.916-1.287-1.641-2.283-1.802-.246-.04-10.662-.057-10.86-.018zm10.72 2.041c.115.039.263.135.361.233.324.324.299-.335.299 7.808 0 3.989-.009 7.253-.02 7.253-.01 0-1.298-.639-2.861-1.42l-2.841-1.42h-.556l-2.841 1.42c-1.563.781-2.851 1.42-2.862 1.42-.011 0-.015-3.298-.009-7.33l.01-7.33.111-.189c.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.703-.002 5.18.004 5.357.064z"
      fill="currentColor"
    />
  </Icon>
);

Bookmark.displayName = 'BookmarkIcon';
