import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Octagon: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.131 17H70.3621C68.8117 16.9999 67.2765 17.3053 65.8442 17.8986C64.4118 18.4919 63.1104 19.3616 62.0142 20.4579L20.4579 62.0142C19.3616 63.1104 18.4919 64.4118 17.8986 65.8442C17.3053 67.2765 16.9999 68.8117 17 70.3621V129.131C16.9999 130.682 17.3053 132.217 17.8986 133.649C18.4919 135.082 19.3616 136.383 20.4579 137.479L62.0142 179.036C63.1104 180.132 64.4118 181.002 65.8442 181.595C67.2765 182.188 68.8117 182.494 70.3621 182.493H129.131C130.682 182.494 132.217 182.188 133.649 181.595C135.082 181.002 136.383 180.132 137.479 179.036L179.036 137.479C180.132 136.383 181.002 135.082 181.595 133.649C182.188 132.217 182.494 130.682 182.493 129.131V70.3621C182.494 68.8117 182.188 67.2765 181.595 65.8442C181.002 64.4118 180.132 63.1104 179.036 62.0142L137.479 20.4579C136.383 19.3616 135.082 18.4919 133.649 17.8986C132.217 17.3053 130.682 16.9999 129.131 17V17Z"
      fill="currentColor"
    />
  </Icon>
);

Octagon.displayName = 'OctagonShape';
