import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CurrencyRupee: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.3.869 2.577 1.358 4.2 1.607.562.087 2.033.099 2.58.022 2.016-.283 3.753-1.046 5.24-2.3 4.113-3.47 4.719-9.628 1.362-13.848-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.992 7.992 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.69-9.3 7.491 7.491 0 011.46-2.073c1.694-1.742 4.143-2.639 6.517-2.386zm-4.15 3.028C8.292 7.193 8 7.614 8 8c0 .242.119.521.299.701.256.256.433.299 1.226.299.75 0 .983.035 1.345.206.266.126.663.465.782.67l.073.124h-1.436c-1.636 0-1.701.01-1.99.299a.984.984 0 000 1.402c.289.289.354.299 1.99.299h1.436l-.073.124c-.12.205-.516.544-.782.67-.362.171-.594.206-1.345.206-.794 0-.97.043-1.227.3a.99.99 0 00-.186 1.144c.06.124.572.66 1.717 1.801 1.459 1.453 1.652 1.631 1.828 1.69.389.129.766.044 1.044-.234.279-.279.363-.656.233-1.044-.057-.169-.183-.315-.882-1.021l-.816-.824.3-.123c1.092-.444 1.939-1.401 2.32-2.619.019-.063.087-.07.656-.07.753 0 .936-.046 1.189-.299.479-.479.35-1.291-.251-1.587-.171-.084-.261-.096-.883-.114l-.693-.02-.077-.23a6.736 6.736 0 00-.308-.72c-.01-.016.36-.03.82-.03.975 0 1.125-.032 1.392-.299a.984.984 0 000-1.402c-.312-.312-.154-.299-3.718-.296-2.757.003-3.186.011-3.34.066z"
      fill="currentColor"
    />
  </Icon>
);

CurrencyRupee.displayName = 'CurrencyRupeeIcon';
