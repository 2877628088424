import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ShoppingCart: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.643 2.069C2.291 2.193 2 2.614 2 3c0 .242.119.521.299.701.255.255.434.299 1.218.299h.663l.867 4.33.866 4.33-1.071 1.08c-.985.993-1.082 1.104-1.215 1.382-.389.811-.226 1.699.427 2.331l.241.233-.072.187c-.16.418-.198.628-.2 1.107-.004.671.126 1.123.475 1.659a2.996 2.996 0 003.511 1.18c.918-.32 1.639-1.124 1.918-2.139.064-.235.064-1.128-.001-1.36l-.071-.25C9.836 18.004 9.957 18 12 18s2.164.004 2.145.07c-.102.351-.121.492-.123.91-.003.671.127 1.123.476 1.659a2.996 2.996 0 003.511 1.18c.92-.321 1.666-1.155 1.915-2.139.072-.285.072-1.075 0-1.357-.304-1.194-1.264-2.089-2.448-2.281-.169-.027-2.269-.041-6.156-.042H5.421l.999-1 .999-1h4.864c5.466 0 5.102.02 5.414-.298.132-.134.559-.959 2.218-4.285 1.707-3.421 2.058-4.154 2.075-4.324a.994.994 0 00-.288-.793c-.326-.326.362-.3-8.021-.3H6.216l-.114-.59c-.149-.768-.201-.911-.401-1.111-.282-.282-.383-.299-1.718-.296-.945.003-1.196.015-1.34.066zM19.36 6.02c0 .011-.67 1.361-1.49 3L16.38 12H7.819l-.591-2.97a301.94 301.94 0 01-.59-3c.002-.033 12.722-.043 12.722-.01zM7.34 18.066c.369.126.66.538.66.934 0 .527-.473 1-1 1a1.03 1.03 0 01-.94-.679.99.99 0 011.28-1.255zm10 0c.369.126.66.538.66.934 0 .527-.473 1-1 1a1.03 1.03 0 01-.94-.679.99.99 0 011.28-1.255z"
      fill="currentColor"
    />
  </Icon>
);

ShoppingCart.displayName = 'ShoppingCartIcon';
