import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ClockAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.069 1.039 C 8.822 1.258,6.868 2.041,5.101 3.432 C 4.640 3.795,3.606 4.863,3.231 5.363 C 2.075 6.906,1.379 8.590,1.093 10.540 C 0.995 11.212,0.995 12.788,1.093 13.460 C 1.379 15.409,2.074 17.091,3.230 18.637 C 3.685 19.244,4.756 20.315,5.363 20.770 C 6.909 21.926,8.591 22.621,10.540 22.907 C 11.212 23.005,12.788 23.005,13.460 22.907 C 15.409 22.621,17.091 21.926,18.637 20.770 C 19.244 20.315,20.315 19.244,20.770 18.637 C 21.926 17.091,22.621 15.409,22.907 13.460 C 22.959 13.104,22.977 12.727,22.977 12.000 C 22.977 11.273,22.959 10.896,22.907 10.540 C 22.621 8.590,21.925 6.906,20.769 5.363 C 20.394 4.863,19.360 3.795,18.899 3.432 C 17.302 2.175,15.548 1.412,13.580 1.120 C 12.946 1.026,11.638 0.984,11.069 1.039 M13.320 3.101 C 14.269 3.244,15.066 3.494,15.944 3.923 C 18.627 5.233,20.448 7.726,20.906 10.720 C 21.003 11.354,21.003 12.646,20.906 13.280 C 20.506 15.891,19.079 18.117,16.880 19.560 C 16.071 20.090,14.874 20.589,13.908 20.797 C 9.860 21.669,5.722 19.656,3.920 15.940 C 3.280 14.621,3.023 13.493,3.023 12.000 C 3.023 10.507,3.280 9.379,3.920 8.060 C 4.794 6.256,6.247 4.803,8.060 3.918 C 9.091 3.415,10.026 3.160,11.340 3.024 C 11.662 2.991,12.911 3.039,13.320 3.101 M11.664 5.063 C 11.436 5.146,11.257 5.297,11.131 5.511 L 11.020 5.700 11.009 8.917 C 10.999 11.798,11.005 12.154,11.063 12.329 C 11.189 12.709,11.240 12.741,13.557 13.899 C 15.907 15.074,15.912 15.076,16.321 14.940 C 17.063 14.693,17.232 13.656,16.603 13.208 C 16.519 13.149,15.674 12.713,14.725 12.240 L 13.000 11.381 13.000 8.617 C 13.000 5.501,13.010 5.608,12.701 5.299 C 12.429 5.027,12.018 4.933,11.664 5.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

ClockAlt.displayName = 'ClockAltIcon';
