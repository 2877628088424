import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SortDescending: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.643 3.069C2.291 3.193 2 3.614 2 4c0 .242.119.521.299.701C2.622 5.024 2.037 5 9.5 5c7.463 0 6.878.024 7.201-.299a.984.984 0 000-1.402c-.323-.324.265-.299-7.218-.296-5.928.003-6.682.01-6.84.066zm0 4C2.291 7.193 2 7.614 2 8c0 .242.119.521.299.701C2.617 9.02 2.277 9 7.5 9s4.883.02 5.201-.299a.984.984 0 000-1.402c-.319-.319.025-.299-5.218-.296-4.116.003-4.684.011-4.84.066zm14 0a1.118 1.118 0 00-.343.229c-.32.32-.3-.048-.3 5.418v4.861l-1.23-1.223c-1.085-1.078-1.253-1.231-1.427-1.288-.389-.13-.766-.045-1.044.233s-.363.654-.234 1.044c.059.178.271.404 2.19 2.328 1.511 1.516 2.176 2.156 2.301 2.217.244.118.509.136.77.052.201-.065.342-.198 2.345-2.195 1.516-1.511 2.156-2.176 2.217-2.301a.99.99 0 00-.186-1.144c-.279-.28-.656-.364-1.045-.234-.174.057-.342.21-1.427 1.288L18 17.577v-4.861c0-5.465.02-5.098-.299-5.417a.998.998 0 00-1.058-.23zm-14 4C2.291 11.193 2 11.614 2 12c0 .242.119.521.299.701.318.319-.022.299 5.201.299s4.883.02 5.201-.299a.984.984 0 000-1.402c-.319-.319.025-.299-5.218-.296-4.116.003-4.684.011-4.84.066z"
      fill="currentColor"
    />
  </Icon>
);

SortDescending.displayName = 'SortDescendingIcon';
