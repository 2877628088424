import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const StarFill: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.504 1.30095C10.938 1.45595 10.421 1.88095 10.205 2.36795C10.143 2.50695 9.75303 3.66895 9.33803 4.94995L8.58403 7.27995L6.06203 7.28095C3.32303 7.28095 3.20703 7.28895 2.76703 7.50495C2.30703 7.72995 1.89903 8.22895 1.74403 8.75695C1.65403 9.06295 1.66103 9.57095 1.75903 9.88895C1.84603 10.167 2.08003 10.557 2.26503 10.726C2.32803 10.784 3.28903 11.492 4.40003 12.299C5.51103 13.105 6.42503 13.787 6.43103 13.815C6.43803 13.842 6.08803 14.953 5.65503 16.282L4.86703 18.7L4.86403 19.08C4.85903 19.801 5.16803 20.371 5.77803 20.768C6.14803 21.008 6.48503 21.094 6.96503 21.071C7.33303 21.053 7.38603 21.04 7.69103 20.886C7.89303 20.784 8.78103 20.166 9.99803 19.28C11.086 18.488 11.988 17.84 12.002 17.84C12.017 17.84 12.936 18.5 14.044 19.306C15.302 20.22 16.171 20.824 16.354 20.912C16.612 21.035 16.695 21.055 17.04 21.071C17.514 21.094 17.853 21.007 18.222 20.768C18.832 20.371 19.141 19.801 19.136 19.08L19.133 18.7L18.345 16.283C17.911 14.953 17.562 13.843 17.568 13.815C17.575 13.787 18.417 13.157 19.44 12.416C21.773 10.725 21.734 10.756 21.942 10.473C22.19 10.136 22.286 9.85595 22.31 9.39395C22.327 9.07695 22.315 8.95895 22.247 8.73195C22.09 8.21595 21.659 7.69595 21.222 7.49595C20.77 7.28895 20.658 7.28095 17.938 7.28095L15.416 7.27995L14.662 4.94995C14.247 3.66795 13.859 2.51095 13.8 2.37795C13.661 2.06395 13.275 1.65095 12.971 1.48995C12.525 1.25195 11.95 1.17795 11.504 1.30095Z"
      fill="currentColor"
    />
  </Icon>
);

StarFill.displayName = 'StarFillIcon';
