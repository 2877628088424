import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const RotateCcw: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.460 2.024 C 9.970 2.138,8.812 2.447,7.580 3.058 C 6.865 3.413,6.325 3.752,5.760 4.201 C 5.573 4.350,4.655 5.192,3.720 6.071 L 2.020 7.671 2.000 5.705 C 1.980 3.745,1.980 3.740,1.886 3.550 C 1.643 3.056,1.036 2.866,0.531 3.126 C 0.312 3.239,0.101 3.494,0.040 3.719 C 0.012 3.824,0.002 4.935,0.009 7.088 L 0.020 10.300 0.131 10.489 C 0.192 10.592,0.301 10.723,0.374 10.778 C 0.672 11.006,0.578 11.000,3.998 11.000 C 7.545 11.000,7.390 11.013,7.701 10.701 C 8.180 10.222,8.051 9.410,7.450 9.114 C 7.261 9.021,7.250 9.020,5.403 9.000 L 3.547 8.980 5.120 7.500 C 6.863 5.861,7.047 5.700,7.610 5.331 C 8.935 4.459,10.380 4.029,11.980 4.031 C 13.342 4.032,14.419 4.287,15.620 4.890 C 18.680 6.428,20.418 9.805,19.902 13.207 C 19.695 14.567,19.131 15.868,18.265 16.980 C 18.024 17.289,17.375 17.946,17.041 18.219 C 16.072 19.010,14.839 19.585,13.520 19.861 C 12.906 19.989,11.769 20.038,11.139 19.964 C 8.723 19.679,6.639 18.403,5.320 16.400 C 5.056 15.998,4.706 15.315,4.489 14.776 C 4.295 14.292,4.031 14.063,3.610 14.014 C 3.208 13.966,2.821 14.181,2.630 14.557 C 2.424 14.964,2.490 15.285,2.997 16.340 C 3.481 17.348,4.012 18.115,4.774 18.907 C 6.325 20.521,8.154 21.485,10.420 21.883 C 11.032 21.991,12.314 22.031,12.965 21.963 C 16.507 21.592,19.505 19.479,21.039 16.271 C 21.506 15.295,21.856 14.011,21.963 12.880 C 22.020 12.282,21.990 11.201,21.900 10.600 C 21.603 8.604,20.746 6.788,19.391 5.280 C 17.864 3.580,15.610 2.401,13.320 2.104 C 12.843 2.042,11.809 1.998,11.460 2.024 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

RotateCcw.displayName = 'RotateCcwIcon';
