import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Menu5: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M4.340 7.040 C 3.922 7.054,3.575 7.069,3.569 7.073 C 3.563 7.077,3.476 7.156,3.376 7.248 C 3.018 7.578,2.913 7.986,3.090 8.365 C 3.255 8.721,3.420 8.873,3.700 8.931 C 4.064 9.005,20.004 9.002,20.329 8.927 C 20.523 8.883,20.583 8.846,20.719 8.687 C 21.092 8.251,21.088 7.713,20.706 7.299 C 20.576 7.158,20.496 7.109,20.340 7.073 C 20.116 7.023,5.702 6.993,4.340 7.040 M4.380 15.040 C 3.940 15.054,3.575 15.069,3.569 15.073 C 3.563 15.077,3.476 15.156,3.376 15.248 C 2.918 15.670,2.885 16.175,3.282 16.678 C 3.418 16.850,3.465 16.880,3.668 16.927 C 4.009 17.005,19.989 17.006,20.329 16.927 C 20.523 16.883,20.583 16.846,20.719 16.687 C 21.092 16.251,21.088 15.714,20.706 15.298 C 20.568 15.147,20.503 15.109,20.320 15.073 C 20.073 15.024,5.778 14.994,4.380 15.040 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Menu5.displayName = 'Menu5Icon';
