import { FC } from 'react';
import { Flex } from '@chakra-ui/react';

// Internal use only

interface DataDebuggerProps {
  data: unknown;
}

export const DataDebugger: FC<DataDebuggerProps> = data => {
  if (process.env.NODE_ENV !== 'development') return null;
  return (
    <Flex
      fontSize="xs"
      width="100%"
      backgroundColor="main.light"
      overflowX="scroll"
      p={2}
    >
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </Flex>
  );
};
