import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LetterG: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.568 18h-1.792l-.144-1.296c-.288.437-.72.805-1.296 1.104-.565.288-1.248.432-2.048.432-1.579 0-2.917-.539-4.016-1.616-1.099-1.077-1.648-2.507-1.648-4.288 0-1.77.565-3.2 1.696-4.288 1.141-1.088 2.512-1.632 4.112-1.632 1.387 0 2.517.336 3.392 1.008.885.672 1.483 1.499 1.792 2.48l-2.112.752c-.17-.587-.517-1.093-1.04-1.52-.512-.437-1.19-.656-2.032-.656-.96 0-1.797.33-2.512.992-.704.661-1.056 1.616-1.056 2.864 0 1.227.341 2.181 1.024 2.864.693.683 1.547 1.024 2.56 1.024.939 0 1.659-.235 2.16-.704.501-.48.795-.981.88-1.504h-3.584v-1.92h5.664V18Z"
      fill="currentColor"
    />
  </Icon>
);

LetterG.displayName = 'LetterGIcon';
