import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Scramble: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.509 83.4915C164.84 76.499 154.998 81.5247 147.718 84.5263C144.159 85.9948 140.384 87.2543 136.544 86.9912C120.579 85.8955 129.663 70.7015 134.726 63.5452C138.37 58.394 140.586 51.9206 139.38 45.7259C138.175 39.5312 132.949 33.9497 126.648 33.6187C118.91 33.2128 112.628 40.113 106.81 44.2451C99.7108 49.2971 86.6508 54.6347 85.7101 41.6199C85.447 37.9773 86.7971 34.441 87.7709 30.9186C88.9729 26.5757 89.6871 21.4454 85.609 18.5083C82.3793 16.1774 77.6323 17.0241 74.0332 17.9003C54.0764 22.7623 23.1274 39.0574 23.0002 63.0138C22.9549 71.4801 29.404 80.2025 37.8633 80.4987C42.3003 80.652 47.1536 78.7009 50.946 81.0108C64.0461 88.9981 42.8664 105.922 37.386 110.848C32.0275 115.662 26.9634 121.708 26.5853 128.901C26.0244 139.406 36.2467 148.078 46.6989 149.283C53.4615 150.06 60.1666 148.447 66.4222 145.973C70.915 144.193 77.5208 138.794 82.449 139.195H82.4629C86.0864 139.494 87.2257 142.238 86.3093 145.625C83.6423 155.462 70.6955 164.177 76.1306 175.281C79.5729 182.312 87.034 183.322 94.0736 182.012C117.607 177.619 141.286 162.289 157.472 144.886C166.231 135.479 173.016 122.856 175.852 110.319C177.606 102.579 177.432 89.7193 171.509 83.4915Z"
      fill="currentColor"
    />
  </Icon>
);

Scramble.displayName = 'ScrambleShape';
