import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronDoubleUp: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.64 3.068c-.156.053-.66.541-3.811 3.687-2.629 2.624-3.655 3.674-3.716 3.801a.986.986 0 00.185 1.144c.279.279.655.364 1.045.235.18-.059.479-.347 3.427-3.289L12 5.421l3.23 3.225c2.948 2.942 3.247 3.23 3.427 3.289.39.129.766.044 1.045-.235a.986.986 0 00.185-1.144c-.061-.127-1.087-1.176-3.716-3.801-3.321-3.316-3.648-3.631-3.828-3.69a1.06 1.06 0 00-.703.003zm0 8c-.156.053-.66.541-3.811 3.687-2.629 2.624-3.655 3.674-3.716 3.801a.986.986 0 00.185 1.144c.279.279.655.364 1.045.235.18-.059.479-.347 3.427-3.289L12 13.421l3.23 3.225c2.948 2.942 3.247 3.23 3.427 3.289.39.129.766.044 1.045-.235a.986.986 0 00.185-1.144c-.061-.127-1.087-1.176-3.716-3.801-3.321-3.316-3.648-3.631-3.828-3.69a1.06 1.06 0 00-.703.003z"
      fill="currentColor"
    />
  </Icon>
);

ChevronDoubleUp.displayName = 'ChevronDoubleUpIcon';
