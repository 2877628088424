import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Hand: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.96 2.062c-.545.117-1.193.543-1.504.988l-.076.109-.2-.062a2.952 2.952 0 00-.54-.083c-.978-.063-1.928.481-2.355 1.348-.274.556-.284.649-.284 2.785L6 9.035l-.35-.022a2.5 2.5 0 00-2.59 1.967c-.072.342-.084 2.641-.018 3.323a8.523 8.523 0 004.078 6.474A8.682 8.682 0 0011.5 22a8.51 8.51 0 008.321-6.8c.162-.79.179-1.197.178-4.137 0-3.144.001-3.122-.281-3.694-.431-.875-1.376-1.416-2.368-1.356l-.35.022-.001-.388c-.001-.51-.081-.873-.284-1.285-.427-.867-1.377-1.411-2.355-1.348-.187.012-.43.049-.54.083l-.2.062-.076-.109a3.771 3.771 0 00-.331-.358c-.571-.559-1.446-.803-2.253-.63zm.764 2c.066.034.155.12.198.19.075.124.078.226.078 3.511 0 3.809-.014 3.625.299 3.938a.984.984 0 001.402 0c.31-.309.299-.189.299-3.43 0-3.173-.011-3.032.252-3.193a.451.451 0 01.496 0c.263.161.252.02.252 3.193 0 3.241-.011 3.121.299 3.43a.984.984 0 001.402 0c.288-.288.299-.361.299-1.93 0-1.519.003-1.541.252-1.693a.451.451 0 01.496 0c.263.16.252.033.251 3.07-.001 3.236-.013 3.398-.346 4.432-.507 1.578-1.846 3.084-3.374 3.793-2.196 1.019-4.649.779-6.649-.652a8.584 8.584 0 01-.732-.66c-1.059-1.057-1.676-2.339-1.858-3.86-.022-.186-.04-.894-.04-1.572 0-1.363.006-1.401.252-1.551a.478.478 0 01.67.174c.073.12.078.21.078 1.511 0 1.578.011 1.65.299 1.938a.984.984 0 001.402 0c.318-.318.299-.011.299-4.93 0-4.83-.016-4.529.252-4.693a.478.478 0 01.67.174c.075.124.078.222.078 3.011 0 3.25-.011 3.129.299 3.438a.984.984 0 001.402 0c.313-.313.299-.13.299-3.93 0-3.725-.013-3.531.252-3.693a.453.453 0 01.472-.016z"
      fill="currentColor"
    />
  </Icon>
);

Hand.displayName = 'HandIcon';
