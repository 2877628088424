import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Share: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.338 2.056a3.75 3.75 0 00-1.112.368 3.965 3.965 0 00-2.093 2.568c-.082.311-.103.492-.108.908-.003.286.009.614.026.729l.032.21-2.524 1.262-2.524 1.262-.28-.259a3.897 3.897 0 00-.982-.681c-1.51-.76-3.386-.454-4.588.748a4.186 4.186 0 00-1.07 1.889c-.132.515-.132 1.365 0 1.88a4.186 4.186 0 001.07 1.889c1.527 1.527 3.985 1.551 5.584.055l.265-.247 2.524 1.262 2.525 1.262-.032.21a6.598 6.598 0 00-.026.729c.005.416.026.597.108.908a3.988 3.988 0 003.687 2.979 3.993 3.993 0 004.047-2.973c.138-.517.155-1.374.038-1.874a4.028 4.028 0 00-2.893-3.009c-.343-.092-.469-.105-1.012-.105-.542 0-.669.014-1.008.104a4.135 4.135 0 00-1.798 1.019l-.226.215-2.525-1.263-2.526-1.262.032-.21c.017-.115.031-.398.031-.629 0-.231-.014-.514-.031-.629l-.032-.21 2.526-1.262 2.525-1.263.226.214c1.523 1.445 3.773 1.533 5.378.209.638-.526 1.138-1.35 1.334-2.199.102-.44.102-1.28 0-1.72a4.02 4.02 0 00-2.966-3.024c-.415-.105-1.215-.134-1.602-.06zm1.07 1.988C19.31 4.232 20 5.08 20 6c0 1.08-.92 2-2 2s-2-.92-2-2c0-.898.657-1.731 1.534-1.943.263-.064.604-.069.874-.013zm-12 6c.75.157 1.391.798 1.548 1.548a1.95 1.95 0 01-.552 1.811c-.798.795-2.014.796-2.807.001-.629-.631-.766-1.498-.369-2.324.141-.292.565-.714.866-.86a2.028 2.028 0 011.314-.176zm12 6C19.31 16.232 20 17.08 20 18c0 1.08-.92 2-2 2s-2-.92-2-2c0-.898.657-1.731 1.534-1.943.263-.064.604-.069.874-.013z"
      fill="currentColor"
    />
  </Icon>
);

Share.displayName = 'ShareIcon';
