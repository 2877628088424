import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Repeat: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M16.650 0.066 C 16.414 0.150,16.148 0.420,16.066 0.659 C 15.983 0.905,15.982 1.093,16.066 1.343 C 16.123 1.517,16.276 1.685,17.354 2.770 L 18.577 4.000 12.657 4.000 C 8.807 4.000,6.619 4.015,6.399 4.042 C 5.356 4.169,4.331 4.652,3.574 5.371 C 2.848 6.061,2.392 6.844,2.134 7.848 C 2.030 8.248,2.027 8.302,2.010 9.683 C 1.992 11.243,2.001 11.330,2.211 11.606 C 2.649 12.179,3.570 12.093,3.886 11.450 C 3.977 11.265,3.981 11.222,4.004 9.860 C 4.023 8.790,4.043 8.413,4.088 8.260 C 4.414 7.170,5.148 6.436,6.260 6.085 C 6.438 6.029,7.135 6.021,12.519 6.010 L 18.579 5.998 17.355 7.229 C 16.276 8.315,16.123 8.483,16.066 8.657 C 15.936 9.046,16.020 9.423,16.300 9.702 C 16.602 10.004,17.052 10.077,17.444 9.888 C 17.569 9.827,18.234 9.187,19.745 7.671 C 21.742 5.668,21.875 5.527,21.940 5.326 C 22.024 5.066,22.006 4.801,21.888 4.556 C 21.827 4.431,21.189 3.769,19.671 2.255 C 17.749 0.339,17.521 0.124,17.343 0.065 C 17.104 -0.014,16.877 -0.014,16.650 0.066 M20.664 12.063 C 20.436 12.146,20.257 12.297,20.131 12.511 L 20.020 12.700 19.997 14.120 C 19.975 15.525,19.973 15.543,19.867 15.860 C 19.497 16.967,18.675 17.713,17.576 17.940 C 17.323 17.992,16.486 18.000,11.353 18.000 L 5.423 18.000 6.646 16.770 C 7.724 15.685,7.877 15.517,7.934 15.343 C 8.064 14.954,7.980 14.577,7.700 14.298 C 7.398 13.996,6.948 13.923,6.556 14.112 C 6.431 14.173,5.766 14.813,4.255 16.329 C 2.258 18.332,2.125 18.473,2.060 18.674 C 1.976 18.935,1.994 19.200,2.112 19.444 C 2.173 19.569,2.813 20.234,4.329 21.745 C 6.253 23.664,6.479 23.876,6.657 23.935 C 7.047 24.064,7.423 23.979,7.701 23.701 C 7.979 23.423,8.064 23.046,7.934 22.657 C 7.877 22.483,7.725 22.315,6.647 21.231 L 5.425 20.002 11.582 19.990 L 17.740 19.978 18.152 19.869 C 20.052 19.366,21.381 18.037,21.866 16.152 C 21.970 15.752,21.973 15.699,21.990 14.311 C 22.005 13.037,21.999 12.859,21.938 12.674 C 21.768 12.162,21.172 11.877,20.664 12.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Repeat.displayName = 'RepeatIcon';
