import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ClipboardList: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.62 2.025c-.408.08-.533.114-.75.202a3.24 3.24 0 00-.986.657c-.254.254-.562.708-.639.942l-.055.165-.865.02c-.601.014-.926.038-1.065.079-1.127.337-1.901 1.128-2.184 2.233-.083.323-.083 13.031 0 13.354.281 1.096 1.045 1.879 2.184 2.238.177.055.818.064 5.56.075 3.614.008 5.449-.001 5.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.327.084-13.033 0-13.357-.307-1.197-1.264-2.089-2.448-2.281A8.77 8.77 0 0016.518 4h-.702l-.052-.15c-.092-.261-.389-.702-.65-.964a3.013 3.013 0 00-1.634-.843c-.241-.039-2.676-.054-2.86-.018zm2.72 2.041c.369.126.66.538.66.934s-.291.808-.66.934c-.288.098-2.392.098-2.68 0-.716-.244-.881-1.258-.279-1.717.264-.202.352-.213 1.602-.215.981-.002 1.193.008 1.357.064zM8.236 6.15c.088.252.385.697.631.945.368.371.847.652 1.393.817.26.079 2.747.118 3.182.05 1.013-.157 1.938-.869 2.302-1.772L15.82 6h.663c.784 0 .963.044 1.218.299.322.322.299-.202.299 6.701s.023 6.379-.299 6.701c-.32.32.082.299-5.701.299-5.784 0-5.381.021-5.702-.3-.323-.323-.3.208-.288-6.782L6.02 6.7l.111-.189c.061-.103.173-.236.25-.294.243-.186.386-.213 1.121-.215L8.184 6l.052.15zm.407 4.916C8.288 11.197 8 11.615 8 12c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm3 .003c-.352.124-.643.545-.643.931 0 .242.119.521.299.701.294.294.33.299 2.201.299s1.907-.005 2.201-.299a.984.984 0 000-1.402c-.295-.295-.328-.299-2.218-.296-1.398.003-1.691.013-1.84.066zm-3 3.997C8.288 15.197 8 15.615 8 16c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm3 .003c-.352.124-.643.545-.643.931 0 .242.119.521.299.701.294.294.33.299 2.201.299s1.907-.005 2.201-.299a.984.984 0 000-1.402c-.295-.295-.328-.299-2.218-.296-1.398.003-1.691.013-1.84.066z"
      fill="currentColor"
    />
  </Icon>
);

ClipboardList.displayName = 'ClipboardListIcon';
