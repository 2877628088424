import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const RotateCW: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.300 2.025 C 9.441 2.180,7.704 2.802,6.234 3.840 C 3.496 5.772,1.893 8.994,2.013 12.320 C 2.066 13.784,2.398 15.108,3.041 16.422 C 4.104 18.594,5.964 20.330,8.221 21.254 C 9.530 21.790,10.912 22.037,12.336 21.988 C 13.768 21.939,15.059 21.622,16.340 21.004 C 17.401 20.493,18.195 19.926,19.062 19.061 C 19.921 18.203,20.523 17.354,21.038 16.271 C 21.509 15.280,21.572 14.956,21.370 14.557 C 21.179 14.181,20.792 13.966,20.390 14.014 C 19.969 14.063,19.705 14.292,19.510 14.776 C 19.042 15.940,18.523 16.752,17.723 17.574 C 15.301 20.061,11.601 20.707,8.500 19.184 C 4.611 17.273,2.950 12.679,4.717 8.716 C 5.493 6.975,6.977 5.491,8.716 4.717 C 10.958 3.719,13.479 3.780,15.640 4.884 C 16.088 5.113,16.642 5.467,16.988 5.746 C 17.125 5.856,17.961 6.629,18.847 7.463 L 20.457 8.980 18.597 9.000 L 16.738 9.020 16.539 9.122 C 15.767 9.520,15.853 10.648,16.678 10.939 C 16.826 10.992,17.284 11.000,19.998 11.000 C 23.545 11.000,23.390 11.013,23.701 10.701 C 24.013 10.390,24.000 10.544,24.000 7.000 C 24.000 3.456,24.013 3.610,23.701 3.299 C 23.234 2.832,22.470 2.935,22.131 3.511 L 22.020 3.700 22.000 5.683 L 21.980 7.667 20.460 6.230 C 18.579 4.453,18.200 4.124,17.521 3.672 C 16.314 2.871,14.909 2.332,13.472 2.118 C 12.956 2.041,11.737 1.989,11.300 2.025 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

RotateCW.displayName = 'RotateCWIcon';
