import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArchiveaAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M0.664 2.063 C 0.436 2.146,0.257 2.297,0.131 2.511 L 0.020 2.700 0.009 5.412 C 0.002 7.221,0.012 8.176,0.040 8.280 C 0.102 8.509,0.312 8.761,0.539 8.878 C 0.723 8.973,0.783 8.981,1.368 8.993 L 1.999 9.005 2.010 15.153 L 2.020 21.300 2.131 21.489 C 2.260 21.709,2.437 21.854,2.678 21.939 C 2.830 21.993,3.916 22.000,11.998 22.000 C 22.265 22.000,21.375 22.027,21.701 21.701 C 22.023 21.379,22.000 21.894,22.000 15.073 L 22.000 9.000 22.573 9.000 C 23.259 9.000,23.454 8.948,23.701 8.701 C 24.009 8.394,24.000 8.486,24.000 5.500 C 24.000 2.514,24.009 2.606,23.701 2.299 C 23.373 1.971,24.509 2.000,11.983 2.003 C 2.399 2.005,0.798 2.014,0.664 2.063 M22.000 5.500 L 22.000 7.000 12.000 7.000 L 2.000 7.000 2.000 5.500 L 2.000 4.000 12.000 4.000 L 22.000 4.000 22.000 5.500 M20.000 14.500 L 20.000 20.000 12.000 20.000 L 4.000 20.000 4.000 14.500 L 4.000 9.000 12.000 9.000 L 20.000 9.000 20.000 14.500 M9.664 11.063 C 9.436 11.146,9.257 11.297,9.131 11.511 C 9.036 11.672,9.020 11.744,9.020 12.000 C 9.020 12.256,9.036 12.328,9.131 12.489 C 9.192 12.592,9.301 12.723,9.374 12.778 C 9.664 13.000,9.670 13.000,11.998 13.000 C 14.428 13.000,14.399 13.003,14.701 12.701 C 15.092 12.310,15.092 11.690,14.701 11.299 C 14.398 10.996,14.430 11.000,11.983 11.003 C 10.210 11.005,9.792 11.016,9.664 11.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

ArchiveaAlt.displayName = 'ArchiveaAltIcon';
