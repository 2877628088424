import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Zap: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M12.655 1.065 C 12.564 1.097,12.426 1.181,12.348 1.252 C 12.119 1.460,2.189 13.405,2.104 13.574 C 1.922 13.941,2.001 14.403,2.298 14.700 C 2.615 15.017,2.352 15.000,6.859 15.000 L 10.865 15.000 10.423 18.535 L 9.982 22.069 10.055 22.299 C 10.241 22.892,10.946 23.175,11.484 22.873 C 11.611 22.802,12.842 21.350,16.734 16.680 C 19.531 13.325,21.853 10.511,21.896 10.426 C 22.079 10.057,21.999 9.597,21.698 9.296 C 21.387 8.985,21.618 9.000,17.120 9.000 L 13.135 9.000 13.577 5.465 L 14.018 1.931 13.945 1.700 C 13.777 1.165,13.189 0.876,12.655 1.065 M11.277 7.708 C 11.005 9.889,10.988 10.074,11.038 10.252 C 11.111 10.513,11.279 10.732,11.508 10.867 L 11.700 10.980 15.275 11.000 L 18.850 11.020 15.658 14.850 C 13.903 16.957,12.459 18.672,12.448 18.662 C 12.438 18.652,12.562 17.583,12.724 16.287 C 13.017 13.932,13.018 13.929,12.950 13.715 C 12.863 13.440,12.690 13.232,12.450 13.114 L 12.260 13.020 8.705 13.000 L 5.150 12.980 8.342 9.150 C 10.097 7.043,11.541 5.328,11.552 5.338 C 11.562 5.348,11.438 6.415,11.277 7.708 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Zap.displayName = 'ZapIcon';
