import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.439.34-1.308 1.21-1.649 1.651-1.146 1.479-1.814 3.078-2.079 4.975-.065.465-.065 1.975 0 2.44.223 1.596.73 2.976 1.558 4.24 1.419 2.169 3.642 3.721 6.13 4.281.832.187 1.288.236 2.22.237.894.001 1.252-.032 2.02-.183 2.571-.508 4.947-2.129 6.385-4.355.806-1.247 1.282-2.525 1.521-4.08.087-.562.099-2.033.022-2.58-.27-1.921-.96-3.558-2.106-5-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215c2.492 2.405 3.152 6.151 1.629 9.244a9.707 9.707 0 01-.419.77l-.162.25-.231-.105a15.436 15.436 0 00-4.851-1.341c-.697-.08-2.522-.068-3.22.021-1.71.218-3.138.622-4.671 1.32l-.231.105-.162-.25c-.266-.408-.664-1.256-.831-1.77a7.963 7.963 0 011.871-8.073c1.693-1.742 4.142-2.639 6.516-2.386zm-1.482 2.02a3.938 3.938 0 00-2.131 1.118 3.903 3.903 0 00-1.165 3.001c.096 1.834 1.331 3.306 3.126 3.725.464.109 1.319.102 1.78-.014a4.03 4.03 0 002.971-2.971c.116-.461.123-1.316.014-1.78-.353-1.515-1.465-2.649-2.965-3.024-.429-.108-1.213-.134-1.63-.055zm1.098 1.983C13.31 8.232 14 9.08 14 10c0 1.08-.92 2-2 2-.92 0-1.768-.69-1.956-1.592a1.95 1.95 0 01.552-1.811 1.956 1.956 0 011.812-.553zm1.189 9.054c.604.079 1.044.162 1.603.303.709.179 1.96.617 1.96.686 0 .041-.467.401-.82.632a9.742 9.742 0 01-.84.464 7.977 7.977 0 01-6.058.394 9.6 9.6 0 01-1.782-.858c-.359-.235-.82-.591-.82-.634 0-.015.13-.079.29-.142a14 14 0 013.774-.902c.545-.049 2.138-.015 2.693.057z"
      fill="currentColor"
    />
  </Icon>
);

UserCircle.displayName = 'UserCircleIcon';
