import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  BoxProps,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputProps,
} from '@chakra-ui/react';
import { NumberFormatWrapper } from '../NumberFormatWrapper';
import { ConnectForm } from './ConnectForm';
import { FieldLabel, FieldLabelProps } from './FieldLabel';

interface ConnectedTimeInputProps {
  containerProps?: BoxProps;
  labelProps?: FieldLabelProps;
  id: string;
  placeholder?: string;
  inputProps?: InputProps;
}

export const ConnectedTimeInput: FC<ConnectedTimeInputProps> = ({
  containerProps,
  labelProps,
  placeholder = '24 Hour',
  id,
}) => {
  const handleAllowedValues = values => {
    const hourValue = values?.formattedValue.split(':')[0];
    const minuteValue = values?.formattedValue.split(':')[1];
    if (parseInt(hourValue, 10) > 23) return false;
    if (parseInt(minuteValue, 10) > 59) return false;
    return true;
  };

  // ONE DAY: update this to handle am / pm

  return (
    <ConnectForm>
      {({ ...formMethods }: UseFormReturn) => (
        <FormControl
          {...containerProps}
          isInvalid={!!formMethods.formState.errors[id]}
        >
          {labelProps?.text && <FieldLabel {...labelProps} />}
          <InputGroup maxWidth="100%">
            <Controller
              render={({ field: { ...rest } }) => (
                <NumberFormatWrapper
                  format="##:##"
                  mask="_"
                  placeholder={placeholder}
                  type="text"
                  id={id}
                  borderRadius="md"
                  fontSize="lg"
                  h={12}
                  px={4}
                  _placeholder={{ color: 'gray.300' }}
                  isAllowed={handleAllowedValues}
                  {...rest}
                  customInput={Input}
                />
              )}
              control={formMethods.control}
              name={id}
            />
          </InputGroup>
          {formMethods.formState.errors[id] ? (
            <FormErrorMessage ml={2}>
              {formMethods.formState.errors[id].message?.toString()}
            </FormErrorMessage>
          ) : null}
        </FormControl>
      )}
    </ConnectForm>
  );
};
