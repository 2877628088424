import { Flex, Image } from '@chakra-ui/react';

export const SingleValueWithImage = ({ data, ...props }) => (
  <Flex
    flexDirection="row"
    alignItems="center"
    borderRadius="full"
    pl={3}
    pr={4}
  >
    <Image
      src={data.image}
      fallbackSrc="/images/placeholder.svg"
      w={8}
      h={8}
      borderRadius="lg"
      alt={`${data.label} logo`}
      mr={2}
    />
    {props.children}
  </Flex>
);
