import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const FolderDownload: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 4.025c-.408.08-.533.114-.75.202-.88.358-1.549 1.137-1.794 2.093-.083.326-.084 11.032 0 11.357.281 1.099 1.041 1.876 2.184 2.238.18.056.983.064 7.56.075 4.998.008 7.448-.001 7.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.326.083-9.034 0-9.357-.307-1.197-1.264-2.089-2.448-2.281-.16-.026-1.343-.042-3.154-.042h-2.898l-.942-.934c-.816-.81-.968-.943-1.139-1-.177-.059-.527-.066-3.42-.061-1.773.002-3.259.011-3.303.02zm6.898 2.909c.816.81.968.943 1.14 1 .175.059.528.066 3.342.066 3.544 0 3.39-.013 3.701.299.317.317.3.038.3 4.701 0 4.663.017 4.384-.3 4.701-.324.324.322.299-7.7.299-8.026 0-7.378.025-7.703-.3-.32-.321-.3.088-.289-5.782L4.02 6.7l.111-.189c.125-.213.318-.375.539-.454.12-.043.729-.054 3.028-.055L10.576 6l.942.934zm.125 2.135a1.066 1.066 0 00-.577.591c-.058.17-.066.428-.066 2.055v1.86l-.73-.721c-.618-.611-.76-.732-.927-.788-.388-.13-.765-.046-1.044.233-.278.278-.363.655-.234 1.044.06.176.237.369 1.69 1.828 1.141 1.145 1.677 1.657 1.801 1.717.245.118.51.136.77.052.2-.065.326-.181 1.845-1.695 1.711-1.704 1.784-1.791 1.82-2.167.053-.555-.428-1.078-.99-1.078-.365 0-.497.088-1.27.854l-.73.721v-1.86c0-2.11-.002-2.118-.3-2.416a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

FolderDownload.displayName = 'FolderDownloadIcon';
