import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MapAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M7.620 1.016 C 7.554 1.045,7.419 1.130,7.320 1.206 C 7.221 1.282,5.629 2.207,3.782 3.262 C 1.934 4.317,0.372 5.223,0.310 5.275 C 0.247 5.327,0.157 5.444,0.108 5.535 L 0.020 5.700 0.020 13.980 C 0.020 21.823,0.024 22.268,0.091 22.411 C 0.322 22.903,0.790 23.139,1.228 22.984 C 1.314 22.954,2.842 22.096,4.623 21.078 C 6.403 20.060,7.905 19.209,7.960 19.188 C 8.048 19.155,8.506 19.374,11.660 20.957 C 13.640 21.951,15.377 22.828,15.520 22.906 C 15.761 23.037,15.801 23.046,16.067 23.033 C 16.329 23.020,16.375 23.004,16.587 22.854 C 16.715 22.763,18.340 21.818,20.199 20.754 C 22.057 19.690,23.628 18.777,23.690 18.725 C 23.753 18.673,23.843 18.556,23.892 18.465 L 23.980 18.300 23.990 10.062 C 24.002 0.914,24.020 1.543,23.739 1.261 C 23.463 0.986,22.929 0.930,22.524 1.135 C 22.423 1.186,20.945 2.025,19.240 3.000 C 17.535 3.975,16.095 4.791,16.040 4.812 C 15.952 4.845,15.494 4.626,12.340 3.043 C 10.360 2.049,8.626 1.174,8.487 1.098 C 8.273 0.982,8.194 0.960,7.987 0.962 C 7.851 0.962,7.686 0.987,7.620 1.016 M12.020 5.130 L 15.000 6.620 15.000 13.490 C 15.000 17.268,14.991 20.360,14.980 20.360 C 14.969 20.360,13.619 19.690,11.980 18.870 L 9.000 17.380 9.000 10.510 C 9.000 6.732,9.009 3.640,9.020 3.640 C 9.031 3.640,10.381 4.310,12.020 5.130 M7.000 10.572 L 7.000 17.423 4.590 18.799 C 3.264 19.555,2.139 20.200,2.090 20.232 L 2.000 20.289 2.000 13.433 L 2.000 6.577 4.490 5.151 C 5.860 4.367,6.985 3.724,6.990 3.722 C 6.995 3.721,7.000 6.803,7.000 10.572 M22.000 10.572 L 22.000 17.423 19.590 18.799 C 18.265 19.555,17.140 20.200,17.090 20.232 L 17.000 20.289 17.000 13.433 L 17.000 6.577 19.490 5.151 C 20.859 4.367,21.984 3.724,21.990 3.722 C 21.996 3.721,22.000 6.803,22.000 10.572 "
      stroke="none"
      fillRule="evenodd"
      fill="currentColor"
    ></path>
  </Icon>
);

MapAlt.displayName = 'MapAltIcon';
