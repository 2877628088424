import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SocialTikTok: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M12.551 9.710 L 12.540 16.420 12.433 16.713 C 12.293 17.096,12.111 17.397,11.840 17.693 C 10.897 18.724,9.267 18.848,8.181 17.971 C 7.536 17.450,7.159 16.664,7.161 15.844 C 7.162 15.068,7.410 14.470,7.959 13.915 C 8.249 13.622,8.357 13.542,8.680 13.387 C 8.889 13.287,9.172 13.184,9.309 13.158 C 9.553 13.111,10.301 13.126,10.590 13.183 L 10.720 13.209 10.720 11.590 L 10.720 9.970 10.595 9.945 C 10.395 9.905,9.474 9.916,9.133 9.963 C 7.780 10.147,6.571 10.765,5.642 11.746 C 5.033 12.390,4.581 13.129,4.325 13.900 C 3.731 15.689,3.984 17.602,5.020 19.160 C 5.222 19.464,5.737 20.033,6.099 20.352 C 6.807 20.976,7.730 21.418,8.800 21.644 C 9.134 21.715,9.355 21.733,9.880 21.734 C 10.642 21.736,11.080 21.670,11.706 21.461 C 12.604 21.160,13.346 20.697,14.041 20.001 C 14.907 19.135,15.405 18.220,15.685 16.980 C 15.775 16.585,15.776 16.534,15.798 12.932 L 15.820 9.284 16.160 9.506 C 17.193 10.180,18.387 10.558,19.850 10.672 L 20.240 10.703 20.240 9.111 L 20.240 7.520 20.085 7.520 C 19.623 7.520,18.670 7.249,18.160 6.973 C 17.809 6.783,17.386 6.461,17.067 6.142 C 16.294 5.369,15.804 4.243,15.801 3.230 L 15.800 3.000 14.181 3.000 L 12.561 3.000 12.551 9.710 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

SocialTikTok.displayName = 'SocialTikTokIcon';
