import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Film: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.534 3.057a2.063 2.063 0 00-1.49 1.535c-.062.3-.062 14.516 0 14.816.157.75.798 1.391 1.548 1.548.3.062 16.516.062 16.816 0a2.062 2.062 0 001.548-1.548c.062-.3.062-14.516 0-14.816a2.062 2.062 0 00-1.548-1.548c-.324-.067-16.594-.055-16.874.013zM6 6v1H4V5h2v1zm10 2v3H8V5h8v3zm4-2v1h-2V5h2v1zM6 10v1H4V9h2v1zm14 0v1h-2V9h2v1zM6 14v1H4v-2h2v1zm10 2v3H8v-6h8v3zm4-2v1h-2v-2h2v1zM6 18v1H4v-2h2v1zm14 0v1h-2v-2h2v1z"
      fill="currentColor"
    />
  </Icon>
);

Film.displayName = 'FilmIcon';
