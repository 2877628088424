import { FC } from 'react';
import { Flex, Img, Text } from '@chakra-ui/react';
import { getAssetExtensionFromUrl } from 'helpers';
import { BlockText } from './BlockText';

export const BlockImage: FC = (props: any) => {
  const imageSource =
    props?.env?.toLowerCase() !== 'production'
      ? props?.file?.url
      : `
    ${process.env.NEXT_PUBLIC_AWS_S3_URL}/${props.id}.${getAssetExtensionFromUrl(props?.file?.url)}
  `.trim();

  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <Img src={imageSource} alt="blog post image" role="img" />
      {props?.caption && (
        <Text
          color="text.secondary"
          mt={2}
          mb={4}
          fontSize="md"
          alignSelf="center"
        >
          <BlockText text={props?.caption} />
        </Text>
      )}
    </Flex>
  );
};
