import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PhoneIncoming: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 2.025c-.408.08-.533.114-.75.202-.864.351-1.493 1.069-1.78 2.033-.082.272-.113 1.943-.053 2.796a15.989 15.989 0 003.507 8.984c.555.694 1.725 1.864 2.416 2.415 2.62 2.092 5.615 3.262 8.98 3.507.849.062 2.525.031 2.8-.052 1.106-.333 1.832-1.058 2.173-2.17.051-.165.064-.546.077-2.18.011-1.389.002-2.052-.03-2.22a2.105 2.105 0 00-.89-1.303c-.182-.118-.754-.325-2.661-.961-2.376-.794-2.437-.812-2.817-.828-.477-.021-.82.07-1.186.314-.376.251-.533.467-.969 1.33l-.401.792-.148-.09a10.304 10.304 0 01-3.483-3.482l-.089-.148.792-.401c.863-.436 1.079-.593 1.33-.969.244-.366.335-.709.314-1.186-.016-.38-.034-.441-.828-2.817-.636-1.907-.843-2.479-.961-2.661a2.104 2.104 0 00-1.275-.886c-.199-.041-3.867-.059-4.068-.019zm16.02.043c-.154.053-.499.38-2.41 2.286L16 6.578V5.216c0-1.555-.012-1.63-.299-1.917a.984.984 0 00-1.402 0C13.991 3.606 14 3.514 14 6.5s-.009 2.894.299 3.201c.307.308.215.299 3.201.299s2.894.009 3.201-.299a.984.984 0 000-1.402C20.414 8.012 20.339 8 18.784 8h-1.362l2.224-2.23c2.011-2.017 2.23-2.249 2.289-2.427a1.004 1.004 0 00-1.295-1.275zM9.021 6.243c.411 1.233.741 2.248.733 2.255-.008.007-.558.285-1.223.618-1.1.551-1.221.622-1.346.786-.16.209-.245.515-.208.746.069.431.879 1.854 1.533 2.692a12.21 12.21 0 003.684 3.157c.554.31.96.495 1.158.526.231.037.537-.048.746-.208.164-.125.235-.246.786-1.346.333-.665.611-1.215.618-1.223.007-.008 1.022.322 2.255.733l2.243.748v1.709c0 1.943-.004 1.97-.3 2.266-.293.293-.408.312-1.736.285-.629-.013-1.297-.042-1.484-.066-3.377-.42-6.192-1.799-8.501-4.166a12.867 12.867 0 01-1.637-2.015 13.605 13.605 0 01-2.117-5.26c-.164-.895-.205-1.423-.205-2.64V4.7l.111-.189c.061-.103.173-.236.25-.294.275-.21.308-.213 2.166-.215L8.273 4l.748 2.243z"
      fill="currentColor"
    />
  </Icon>
);

PhoneIncoming.displayName = 'PhoneIncomingIcon';
