import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const BookOpen: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.76 4.023l-.52.06a9.449 9.449 0 00-2.9.836c-.859.416-1.188.686-1.297 1.061-.035.119-.044 1.862-.035 6.86l.012 6.7.086.16c.178.329.517.538.878.539.247.001.412-.053.676-.221 1.655-1.054 4.249-1.315 6.3-.634.411.137.929.375 1.512.695.434.239.742.223 1.188-.061 1.723-1.098 4.442-1.329 6.53-.555.215.08.561.236.77.348l.596.316a.99.99 0 001.335-.421l.089-.166.012-6.7c.009-4.998 0-6.741-.035-6.86-.109-.376-.438-.645-1.297-1.061-.83-.403-1.74-.675-2.76-.825-.647-.096-2.17-.096-2.792-.001-1.014.154-1.893.412-2.698.789l-.41.192-.41-.193a9.559 9.559 0 00-2.67-.782c-.433-.062-1.851-.111-2.16-.076zM8.457 6.06a7.142 7.142 0 012.233.61l.31.141v5.395c0 2.967-.015 5.394-.033 5.394-.018 0-.167-.046-.33-.102-.946-.326-1.915-.474-3.117-.475-1.19-.002-2.065.125-3.044.44a7.73 7.73 0 01-.45.137C4.012 17.6 4 15.173 4 12.206V6.811l.31-.141c.764-.347 1.528-.544 2.49-.643.368-.038 1.192-.021 1.657.033zm9 0a7.142 7.142 0 012.233.61l.31.141v5.395c0 2.967-.015 5.394-.033 5.394-.018 0-.167-.046-.33-.102-.946-.326-1.915-.474-3.117-.475-1.19-.002-2.065.125-3.044.44a7.73 7.73 0 01-.45.137c-.014 0-.026-2.427-.026-5.394V6.811l.31-.141c.764-.347 1.528-.544 2.49-.643.368-.038 1.192-.021 1.657.033z"
      fill="currentColor"
    />
  </Icon>
);

BookOpen.displayName = 'BookOpenIcon';
