import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const VolumeOff: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.534 3.057c-.532.129-.506.107-3.015 2.608L5.178 8h-.687c-.378 0-.782.02-.899.044a2.062 2.062 0 00-1.548 1.548c-.061.292-.061 4.524 0 4.816.157.75.798 1.391 1.548 1.548.117.024.521.044.899.044h.687l2.341 2.335c2.15 2.145 2.362 2.345 2.601 2.456.302.14.597.209.894.209.902 0 1.756-.699 1.942-1.592.062-.3.062-14.516 0-14.816a1.976 1.976 0 00-.552-.995 1.98 1.98 0 00-1.87-.54zM11 12.01v6.97l-2.49-2.49L6.02 14H4v-3.996l.93-.012c.81-.01.949-.022 1.079-.086.089-.044 1.124-1.052 2.539-2.47 1.315-1.318 2.404-2.396 2.421-2.396.017 0 .031 3.136.031 6.97zm5.643-2.941c-.352.124-.643.545-.643.931 0 .364.088.496.854 1.27l.721.73-.721.73c-.766.774-.854.906-.854 1.27 0 .527.473 1 1 1 .364 0 .496-.088 1.27-.854l.73-.721.73.721c.774.766.906.854 1.27.854.527 0 1-.473 1-1 0-.364-.088-.496-.854-1.27l-.721-.73.721-.73c.766-.774.854-.906.854-1.27 0-.527-.473-1-1-1-.364 0-.496.088-1.27.854l-.73.721-.73-.721c-.618-.611-.76-.732-.927-.788a1.026 1.026 0 00-.7.003z"
      fill="currentColor"
    />
  </Icon>
);

VolumeOff.displayName = 'VolumeOffIcon';
