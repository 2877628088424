import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DotsVertical: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.534 3.057C10.657 3.269 10 4.102 10 5c0 1.08.92 2 2 2s2-.92 2-2c0-1.096-.934-2.013-2.032-1.997a2.342 2.342 0 00-.434.054zm0 7C10.657 10.269 10 11.102 10 12c0 1.08.92 2 2 2s2-.92 2-2c0-1.096-.934-2.013-2.032-1.997a2.342 2.342 0 00-.434.054zm0 7C10.657 17.269 10 18.102 10 19c0 1.08.92 2 2 2 .92 0 1.768-.69 1.956-1.592a1.954 1.954 0 00-.552-1.812 1.969 1.969 0 00-1.87-.539z"
      fill="currentColor"
    />
  </Icon>
);

DotsVertical.displayName = 'DotsVerticalIcon';
