import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DollarSign: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.664 0.063 C 11.436 0.146,11.257 0.297,11.131 0.511 L 11.020 0.700 11.008 2.350 L 10.997 4.000 10.108 4.000 C 9.529 4.000,9.083 4.021,8.828 4.060 C 7.156 4.312,5.777 5.469,5.241 7.072 C 4.450 9.431,5.714 11.970,8.072 12.759 C 8.657 12.955,9.027 13.000,10.065 13.000 L 11.000 13.000 11.000 15.500 L 11.000 18.000 8.425 18.000 C 5.631 18.000,5.668 17.997,5.374 18.222 C 5.301 18.277,5.192 18.408,5.131 18.511 C 5.036 18.672,5.020 18.744,5.020 19.000 C 5.020 19.256,5.036 19.328,5.131 19.489 C 5.192 19.592,5.301 19.723,5.374 19.778 C 5.668 20.003,5.631 20.000,8.425 20.000 L 11.000 20.000 11.000 21.570 C 11.000 22.528,11.017 23.198,11.042 23.289 C 11.103 23.509,11.315 23.762,11.531 23.874 C 11.932 24.080,12.390 24.012,12.700 23.702 C 12.994 23.408,13.000 23.363,13.000 21.573 L 13.000 20.000 13.935 20.000 C 14.973 20.000,15.343 19.955,15.928 19.759 C 16.611 19.531,17.164 19.188,17.676 18.676 C 19.446 16.906,19.437 14.074,17.656 12.305 C 16.981 11.634,16.119 11.202,15.172 11.060 C 14.916 11.021,14.470 11.000,13.890 11.000 L 13.000 11.000 13.000 8.500 L 13.000 6.000 15.073 6.000 C 17.419 6.000,17.400 6.002,17.701 5.701 C 18.092 5.310,18.092 4.690,17.701 4.299 C 17.400 3.998,17.419 4.000,15.073 4.000 L 13.000 4.000 13.000 2.427 C 13.000 0.638,12.994 0.591,12.701 0.299 C 12.429 0.027,12.018 -0.067,11.664 0.063 M11.000 8.500 L 11.000 11.000 10.130 10.999 C 9.109 10.998,8.855 10.958,8.363 10.716 C 7.964 10.519,7.582 10.158,7.349 9.759 C 6.605 8.484,7.116 6.871,8.465 6.233 C 8.866 6.043,9.128 6.007,10.110 6.003 L 11.000 6.000 11.000 8.500 M15.240 13.112 C 16.020 13.370,16.647 14.004,16.890 14.780 C 17.014 15.174,17.014 15.826,16.890 16.220 C 16.689 16.864,16.210 17.433,15.630 17.719 C 15.146 17.957,14.888 17.998,13.870 17.999 L 13.000 18.000 13.000 15.496 L 13.000 12.993 13.990 13.009 C 14.869 13.024,15.009 13.035,15.240 13.112 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

DollarSign.displayName = 'DollarSignIcon';
