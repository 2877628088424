import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowLeft: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.64 4.068c-.156.053-.66.541-3.811 3.687-2.629 2.624-3.655 3.674-3.716 3.801a1.013 1.013 0 00-.053.77c.066.203.247.392 3.695 3.845 2.624 2.629 3.674 3.655 3.801 3.716a.986.986 0 001.144-.185c.279-.279.364-.655.235-1.045-.059-.179-.312-.445-2.789-2.927L5.421 13h7.863c8.826 0 8.092.026 8.417-.299a.984.984 0 000-1.402c-.325-.325.409-.299-8.417-.299H5.421l2.725-2.73c2.477-2.482 2.73-2.748 2.789-2.927.129-.39.044-.766-.234-1.044a1.006 1.006 0 00-1.061-.231z"
      fill="currentColor"
    />
  </Icon>
);

ArrowLeft.displayName = 'ArrowLeftIcon';
