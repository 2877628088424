import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CalendarCheckAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M17 2a1 1 0 1 0-2 0v1H9V2a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h3.5a1 1 0 1 0 0-2H5a1 1 0 0 1-1-1v-9h4a1 1 0 1 0 0-2H4V6a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V5h6v1a1 1 0 1 0 2 0V5h2a1 1 0 0 1 1 1v1.5a1 1 0 1 0 2 0V6a3 3 0 0 0-3-3h-2V2Z"
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
    <path
      d="M19.309 12.564c-.192.067-.555.406-2.396 2.24L14.74 16.97l-.916-.908c-.777-.767-.955-.919-1.165-.989-.487-.163-.96-.058-1.31.293-.35.348-.456.822-.293 1.31.072.217.255.422 1.492 1.667.968.973 1.48 1.454 1.632 1.526.31.15.64.172.968.067.252-.082.43-.249 2.944-2.755 1.902-1.897 2.706-2.732 2.782-2.889.497-1.026-.477-2.1-1.566-1.727Z"
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

CalendarCheckAlt.displayName = 'CalendarCheckAltIcon';
