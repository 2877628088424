import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SocialFacebook: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.000 2.024 C 10.594 2.061,10.117 2.129,9.740 2.204 C 6.842 2.783,4.292 4.755,2.999 7.420 C 2.530 8.386,2.257 9.256,2.096 10.300 C 1.993 10.968,1.992 12.428,2.095 13.080 C 2.228 13.932,2.515 14.918,2.834 15.620 C 4.069 18.343,6.399 20.317,9.254 21.059 C 9.543 21.134,9.865 21.208,9.970 21.222 L 10.160 21.249 10.160 17.865 L 10.160 14.480 8.940 14.480 L 7.720 14.480 7.720 13.080 L 7.720 11.680 8.940 11.680 L 10.160 11.680 10.161 10.670 C 10.163 9.047,10.233 8.446,10.494 7.799 C 10.659 7.391,10.846 7.116,11.200 6.757 C 11.494 6.460,11.599 6.384,11.960 6.206 C 12.558 5.912,12.880 5.838,13.680 5.812 C 14.313 5.792,14.961 5.829,15.670 5.927 L 16.000 5.972 16.000 7.166 L 16.000 8.360 15.287 8.360 C 14.219 8.360,13.901 8.447,13.556 8.832 C 13.241 9.183,13.225 9.261,13.209 10.550 L 13.194 11.680 14.540 11.680 L 15.885 11.680 15.856 11.870 C 15.841 11.975,15.745 12.600,15.643 13.260 L 15.459 14.460 14.329 14.471 L 13.200 14.481 13.200 17.861 L 13.200 21.240 13.282 21.240 C 13.454 21.240,14.281 21.031,14.800 20.857 C 16.891 20.154,18.757 18.666,19.939 16.758 C 20.657 15.600,21.164 14.123,21.323 12.725 C 21.392 12.114,21.351 10.706,21.246 10.100 C 20.705 6.977,18.767 4.383,15.940 2.997 C 14.988 2.531,14.086 2.253,13.020 2.097 C 12.624 2.039,11.348 1.993,11.000 2.024 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

SocialFacebook.displayName = 'SocialFacebookIcon';
