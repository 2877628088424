import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DocumentSearch: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62 2.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.245.596-.227.107-.24 6.482-.013 6.566-.035 6.08.288 6.403a.984.984 0 001.403.001c.321-.321.299.143.301-6.218.001-4.626.011-5.69.055-5.813.079-.221.241-.414.454-.539L6.7 4.02l2.939-.011 2.939-.011 2.711 2.711L18 9.42v4.863c0 5.466.02 5.099-.299 5.418-.314.314-.097.299-4.201.299-4.104 0-3.887-.015-4.201.299-.18.18-.299.459-.299.701 0 .242.119.521.299.701.315.315.093.299 4.238.299 2.339 0 3.776-.016 3.937-.042 1.168-.189 2.056-.997 2.441-2.218.055-.177.064-.799.075-5.335.008-3.423-.002-5.211-.029-5.36a2.74 2.74 0 00-.183-.525c-.138-.291-.226-.384-3.07-3.228-2.846-2.846-2.936-2.932-3.228-3.069a2.665 2.665 0 00-.515-.182c-.204-.039-6.155-.053-6.345-.016zm4.96 8.019a4.15 4.15 0 00-1.84.688c-.313.211-.801.702-1.018 1.023-.793 1.177-.919 2.702-.325 3.945l.153.32-2.21 2.22c-1.996 2.005-2.216 2.239-2.275 2.417-.129.39-.044.766.234 1.044s.654.363 1.044.234c.178-.059.413-.28 2.417-2.271l2.22-2.206.3.15c.165.083.462.199.66.259.335.101.411.109 1.08.109.671 0 .745-.007 1.08-.109 1.385-.422 2.337-1.377 2.768-2.776.101-.326.108-.402.108-1.071 0-.669-.008-.746-.109-1.08-.492-1.63-1.801-2.724-3.467-2.899a5.387 5.387 0 00-.44-.035l-.38.038zm.96 2.043c.302.076.692.314.922.562.372.401.538.826.538 1.374 0 1.511-1.566 2.443-2.939 1.747-.305-.154-.677-.551-.841-.897-.728-1.54.657-3.203 2.32-2.786z"
      fill="currentColor"
    />
  </Icon>
);

DocumentSearch.displayName = 'DocumentSearchIcon';
