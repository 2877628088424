import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CursorClick: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.92 1.275c-.431.14-.717.515-.719.943-.001.202.736 3.053.867 3.355.085.195.361.439.564.5.087.026.253.047.37.047.513 0 .958-.456.958-.982 0-.165-.758-3.058-.867-3.308a1.081 1.081 0 00-.478-.491c-.17-.081-.538-.115-.695-.064zm6.7 1.831c-.181.056-.312.173-1.391 1.247-1.323 1.317-1.39 1.405-1.388 1.825a.907.907 0 00.304.71.9.9 0 00.686.272c.41 0 .505-.073 1.815-1.389 1.118-1.123 1.19-1.203 1.25-1.408.08-.271.08-.335 0-.606a1.017 1.017 0 00-.653-.653c-.262-.077-.362-.077-.623.002zM1.84 6.283a.965.965 0 00-.588.816.97.97 0 00.455.927c.203.119 3.19.933 3.428.933.367.002.738-.234.902-.572.094-.196.109-.51.036-.755-.061-.203-.305-.479-.5-.564-.302-.131-3.153-.868-3.355-.867-.112 0-.276.036-.378.082zm6.803 1.783a1.017 1.017 0 00-.629 1.029c.014.122.85 2.474 2.061 5.799 1.538 4.223 2.066 5.629 2.155 5.739.36.445 1.061.478 1.469.07.151-.151.231-.328.878-1.944.391-.979.729-1.823.749-1.876.035-.092.153.018 2.086 1.944 1.847 1.842 2.067 2.049 2.245 2.108.389.129.766.044 1.044-.234s.363-.655.234-1.044c-.059-.178-.266-.398-2.108-2.245-1.926-1.933-2.036-2.051-1.944-2.086.053-.02.897-.358 1.876-.749 1.616-.647 1.793-.727 1.944-.878.408-.408.375-1.109-.07-1.469-.11-.089-1.517-.618-5.739-2.156C8.886 7.885 9.02 7.928 8.643 8.066zm5.304 3.795a206.95 206.95 0 013.181 1.173c.015.014-.632.289-1.437.611l-1.463.586-.591 1.474c-.563 1.406-.592 1.469-.637 1.355a902.85 902.85 0 01-1.16-3.18c-.613-1.683-1.127-3.095-1.144-3.137-.023-.06-.016-.072.034-.052l3.217 1.17zm-8.187-.938c-.236.106-2.485 2.338-2.598 2.577a.949.949 0 00-.061.728c.09.312.351.577.656.668.271.08.335.08.606 0 .205-.06.285-.132 1.408-1.25 1.29-1.284 1.389-1.41 1.389-1.779 0-.109-.021-.268-.047-.355-.062-.206-.311-.484-.526-.589-.228-.11-.583-.11-.827 0z"
      fill="currentColor"
    />
  </Icon>
);

CursorClick.displayName = 'CursorClickIcon';
