import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const EyeOff: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.643 2.069c-.531.187-.786.849-.53 1.375.063.129 2.503 2.593 9.216 9.301 8.441 8.436 9.146 9.13 9.328 9.19.39.128.766.044 1.044-.234s.363-.655.234-1.044c-.059-.175-.226-.358-1.527-1.664l-1.46-1.468.136-.111c.285-.231 1.057-1.009 1.365-1.374.867-1.029 1.774-2.708 2.055-3.801.071-.279.041-.44-.207-1.108-.918-2.473-2.811-4.615-5.185-5.869a11.235 11.235 0 00-3.672-1.169c-.694-.097-2.23-.096-2.9.001-1.233.179-2.434.55-3.472 1.072l-.319.161-1.605-1.598C3.711 2.301 3.519 2.124 3.343 2.065a1.032 1.032 0 00-.7.004zm10.697 4.03A9.148 9.148 0 0117.9 8.21a9.115 9.115 0 012.463 3.46l.133.33-.133.33c-.525 1.298-1.406 2.547-2.421 3.426-.205.178-.389.324-.407.324-.019 0-.495-.461-1.059-1.024l-1.024-1.023.132-.267a3.999 3.999 0 00-5.35-5.35l-.267.132-.866-.867-.867-.868.413-.164a9.558 9.558 0 012.433-.607c.465-.051 1.772-.018 2.26.057zM3.706 8.025c-.251.077-.486.313-.834.838C2.147 9.957 1.471 11.47 1.471 12c0 .369.419 1.449.893 2.301.545.981 1.043 1.633 1.893 2.481 1.565 1.562 3.422 2.564 5.55 2.993.811.163 1.273.205 2.233.203 1.393-.004 2.181-.133 2.485-.407.537-.486.436-1.297-.202-1.621-.259-.131-.424-.138-1.032-.042-.601.095-1.985.095-2.591-.001a8.911 8.911 0 01-2.64-.825 8.95 8.95 0 01-3.58-3.146 10.223 10.223 0 01-.843-1.606L3.504 12l.134-.33a9.447 9.447 0 01.908-1.7c.416-.616.454-.7.454-1.009a.965.965 0 00-.696-.942c-.242-.072-.347-.071-.598.006zm8.702 2.019c.768.16 1.403.809 1.554 1.588.046.238.032.787-.022.841-.024.024-2.42-2.351-2.42-2.4 0-.071.595-.09.888-.029z"
      fill="currentColor"
    />
  </Icon>
);

EyeOff.displayName = 'EyeOffIcon';
