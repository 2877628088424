import { FC } from 'react';
import styles from './Fireworks.module.css';

export const Fireworks: FC = () => {
  return (
    <div className={styles.fireworks}>
      <div className={styles.before} />
      <div className={styles.after} />
    </div>
  );
};
