import { useEffect, useState } from 'react';
import { createObjectOfNulls } from 'helpers';
import { RouterOptions, useQueryParamsHelper } from 'hooks';
import { QueryParamDrawer, QueryParamModal } from 'types';

interface useViewHelperReturn {
  modalOpen: string;
  modalTwoOpen: string;
  drawerOpen: string;
  handleOpenModal: (
    modal: QueryParamModal,
    routerOption?: RouterOptions,
    additionalParams?: Record<string, string>,
  ) => void;
  handleOpenModal2: (
    modal: QueryParamModal,
    routerOption?: RouterOptions,
  ) => void;
  handleOpenDrawer: (
    drawer: QueryParamDrawer,
    routerOption?: RouterOptions,
  ) => void;
  handleCloseModal: (
    routerOption?: RouterOptions,
    removeParams?: string[],
    refresh?: boolean,
  ) => void;
  handleCloseModalTwo: (routerOption?: RouterOptions) => void;
  handleCloseDrawer: (routerOption?: RouterOptions) => void;
}

export const useViewHelper = (): useViewHelperReturn => {
  const [openModal, setOpenModal] = useState(null);
  const [openModalTwo, setOpenModalTwo] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(null);
  const { pathname, query, updateQueryParams } = useQueryParamsHelper();

  const handleOpenModal = (
    modal: QueryParamModal,
    routerOption?: RouterOptions,
    additionalParams?: Record<string, string>,
  ) => {
    updateQueryParams({ modal, ...additionalParams }, routerOption);
  };

  const handleOpenModal2 = (
    modal: QueryParamModal,
    routerOption?: RouterOptions,
  ) => {
    updateQueryParams({ modal2: modal }, routerOption);
  };

  const handleOpenDrawer = (drawer: string, routerOption?: RouterOptions) => {
    updateQueryParams({ drawer }, routerOption);
  };

  const handleCloseModal = (
    routerOption?: RouterOptions,
    removeParams?: string[],
    refresh?: boolean,
  ) => {
    updateQueryParams(
      {
        modal: null,
        id: null,
        ...createObjectOfNulls(removeParams),
        refresh: refresh ? 'true' : null,
      },
      routerOption,
    );
    setOpenModal(null);
  };

  const handleCloseModalTwo = (routerOption?: RouterOptions) => {
    updateQueryParams({ modal2: null }), routerOption;
    setOpenModalTwo(null);
  };

  const handleCloseDrawer = (routerOption?: RouterOptions) => {
    updateQueryParams({ drawer: null }, routerOption);
    setOpenDrawer(null);
  };

  useEffect(() => {
    if (!pathname || !query) return;
    const { modal, modal2, drawer } = query;
    setOpenModal(modal);
    setOpenModalTwo(modal2);
    setOpenDrawer(drawer);
  }, [pathname, query]);

  return {
    modalOpen: openModal,
    modalTwoOpen: openModalTwo,
    drawerOpen: openDrawer,
    handleOpenModal,
    handleOpenModal2,
    handleOpenDrawer,
    handleCloseModal,
    handleCloseModalTwo,
    handleCloseDrawer,
  };
};
