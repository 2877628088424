import { FC } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import styles from './PulseDotAnimation.module.css';

interface PulseDotProps {
  size?: number;
  color?: string;
  containerProps?: FlexProps;
  isSubtle?: boolean;
}

export const PulseDot: FC<PulseDotProps> = ({
  size = 1.5,
  color = 'pink',
  containerProps,
  isSubtle = false,
}) => {
  const dotColorValue = isSubtle ? '300' : '500';
  const borderColorValue = isSubtle ? '200' : '300';
  return (
    <Flex {...containerProps}>
      <Flex position="relative" h={size * 1.5} w={size * 1.5}>
        <Flex
          className="circle"
          h={size}
          w={size}
          borderRadius="full"
          backgroundColor={`${color}.${dotColorValue}`}
          position="absolute"
          top={0}
          left={0}
          transform="translateX(50%) translateY(50%)"
          mx="auto"
          zIndex={1}
        />
        <Flex
          className={styles.ring}
          borderStyle="solid"
          borderWidth={size >= 2 ? 5 : size * 2}
          borderColor={`${color}.${borderColorValue}`}
          borderRadius="full"
          h={size * 2}
          w={size * 2}
          position="absolute"
        />
      </Flex>
    </Flex>
  );
};
