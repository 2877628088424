import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillPointOfSale: FC<IconProps> = props => (
  <Icon {...props}>
    <g clipPath="url(#clip0)" fill="currentColor">
      <path d="M5.503 5.906a.391.391 0 100-.782.391.391 0 000 .782zM7.46 12.547h3.914c.647 0 1.174-.526 1.174-1.172V6.687a.391.391 0 00-.392-.39H6.677a.391.391 0 00-.391.39v4.688c0 .646.526 1.172 1.174 1.172zm-.392-5.469h4.697v4.297c0 .215-.176.39-.391.39H7.46a.391.391 0 01-.392-.39V7.078z" />
      <path d="M9.372 10.203h.09c.838 0 1.52-.68 1.52-1.518V8.25a.391.391 0 00-.782 0v.435a.738.738 0 01-.738.737h-.09a.738.738 0 01-.738-.737V8.25a.391.391 0 00-.783 0v.435a1.52 1.52 0 001.52 1.518z" />
      <path d="M16.869 9.393C16.869 5.309 13.548 2 9.432 2 5.323 2 1.98 5.316 1.98 9.393c0 4.092 3.343 7.422 7.452 7.422 1.563 0 3.098-.5 4.36-1.414L15 16.607l-.277.273a.39.39 0 00-.003.552l3.97 3.996a1.96 1.96 0 002.979-.249c.55-.762.434-1.868-.27-2.57l-3.909-3.936a.392.392 0 00-.584.033c-.018.019-.072.076-.242.247l-1.211-1.209a7.428 7.428 0 001.416-4.351zm-1.313 6.663l-1.152-1.149c.194-.174.379-.359.553-.552l1.154 1.15-.555.551zM2.763 9.393c0-3.646 2.992-6.612 6.67-6.612 3.668 0 6.653 2.966 6.653 6.612a6.655 6.655 0 01-6.654 6.64c-3.677 0-6.669-2.979-6.669-6.64zm14.453 6.114l3.628 3.652c.437.436.518 1.108.19 1.564a1.176 1.176 0 01-1.79.154l-3.692-3.717c.47-.464 1.202-1.188 1.664-1.653z" />
      <path d="M12.286 14.512c.007-.003.006-.003 0 0zM15.3 9.59c.106-3.393-2.642-6.099-5.949-6.056a5.782 5.782 0 00-2.752.727.39.39 0 10.369.689 4.942 4.942 0 012.397-.635 5.086 5.086 0 015.152 5.25c-.093 2.789-2.396 4.906-5.085 4.906-2.728 0-5.166-2.19-5.082-5.322a5.078 5.078 0 01.604-2.166.39.39 0 10-.69-.37 5.85 5.85 0 00-.348.774c-.079.216-.137.401-.213.724-.828 3.614 1.95 7.141 5.73 7.141.963 0 1.923-.23 2.853-.74A5.859 5.859 0 0015.3 9.591z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="transparent" transform="translate(2 2)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </Icon>
);

SkillPointOfSale.displayName = 'SkillPointOfSaleIcon';
