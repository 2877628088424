import { FC, ReactElement, ReactNode } from 'react';
import { TableInstance } from 'react-table';
import { Box, BoxProps, Flex } from '@chakra-ui/react';

export interface PagingListProps extends Partial<TableInstance> {
  headerRowProps?: BoxProps;
  headerColumnProps?: BoxProps;
  containerProps?: BoxProps;
  emptyComponent?: ReactElement;
  rowProps?: BoxProps;
  isLoading?: boolean;
  loadingComponent?: ReactElement;
}

export const PagingList: FC<PagingListProps> = ({
  containerProps,
  rows,
  rowProps,
  prepareRow,
  emptyComponent,
  isLoading = true,
  loadingComponent,
}) => {
  if (isLoading) {
    if (loadingComponent) return loadingComponent;
    return <>{'Loading...'}</>;
  }

  if (!rows?.length) {
    if (emptyComponent) return emptyComponent;
    return <>{'No data here'}</>;
  }

  /* eslint-disable react/jsx-key */
  /* React Table creates it's own render keys in the generated props.
   * Having an explicitly declared key for the sake of satisfying the linter will break functionality.
   */

  return (
    <Flex flexDirection="column" {...containerProps}>
      {rows?.map(row => {
        prepareRow(row);
        return (
          <Box {...row.getRowProps()} position="relative" {...rowProps}>
            {row.cells.map(cell => {
              return (
                <Box {...cell.getCellProps()} position="relative" zIndex={2}>
                  {cell.render('Cell') as ReactNode}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Flex>
  );
};
