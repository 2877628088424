import { Tag } from '@chakra-ui/react';
import { selectColors } from '../select.config';

export const SingleValueTag = ({ data, children, ...props }) => (
  <Tag
    {...props.sx}
    backgroundColor={!data?.colorScheme && selectColors.tagDefault}
    colorScheme={data?.colorScheme}
    borderRadius="md"
  >
    {children}
  </Tag>
);
