import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowRight: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.643 4.069C13.291 4.193 13 4.614 13 5c0 .08.029.234.065.343.059.179.312.445 2.789 2.927L18.579 11h-7.863c-8.826 0-8.092-.026-8.417.299a.984.984 0 000 1.402c.325.325-.409.299 8.417.299h7.863l-2.725 2.73c-2.477 2.482-2.73 2.748-2.789 2.927-.129.39-.044.766.235 1.045a.986.986 0 001.144.185c.127-.061 1.177-1.087 3.801-3.716 3.448-3.453 3.629-3.642 3.695-3.845a1.013 1.013 0 00-.053-.77c-.061-.127-1.087-1.176-3.716-3.801-3.321-3.316-3.648-3.631-3.828-3.69a1.038 1.038 0 00-.7.004z"
      fill="currentColor"
    />
  </Icon>
);

ArrowRight.displayName = 'ArrowRightIcon';
