import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowsUpDown: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.32 5.249C7.145 3.43 7.479 3.115 7.633 3.062c.238-.082.462-.082.703-.003.178.059.404.27 2.328 2.19 1.516 1.51 2.156 2.176 2.217 2.3a.99.99 0 0 1-.186 1.145c-.28.28-.656.364-1.045.234-.174-.057-.342-.21-1.427-1.288l-1.23-1.223v4.86c0 4.676.014 5.083-.181 5.303-.033.037-.072.069-.118.115a.984.984 0 0 1-1.402 0c-.046-.046-.085-.078-.118-.115-.196-.22-.181-.627-.181-5.302V6.417L5.762 7.64C4.677 8.718 4.509 8.87 4.335 8.928c-.39.13-.766.046-1.045-.234a.99.99 0 0 1-.186-1.144c.06-.125.7-.79 2.217-2.301ZM16.295 7.29c.092-.092.246-.195.343-.229a.998.998 0 0 1 1.058.23c.046.047.085.078.118.115.196.22.18.627.18 5.303v4.86l1.23-1.223c1.086-1.078 1.254-1.23 1.428-1.288.389-.13.766-.045 1.045.235a.99.99 0 0 1 .186 1.143c-.061.125-.701.79-2.217 2.301-2.003 1.997-2.144 2.13-2.345 2.195a1.018 1.018 0 0 1-.77-.052c-.125-.06-.79-.7-2.301-2.216-1.92-1.924-2.131-2.15-2.19-2.328-.13-.39-.044-.767.234-1.044.278-.278.655-.363 1.044-.233.174.056.342.21 1.427 1.287l1.23 1.223v-4.86c0-4.675-.015-5.083.181-5.303.033-.037.073-.07.119-.116Z"
      fill="currentColor"
    />
  </Icon>
);

ArrowsUpDown.displayName = 'ArrowsUpDownIcon';
