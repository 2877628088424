import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Weird: FC<IconProps> = props => (
  <Icon {...props} viewBox="0 0 200 200">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.9964 40.9966C82.3206 49.7068 78.955 60.1991 70.2343 61.8105C65.0082 62.7756 59.9232 59.9639 54.8347 58.41C45.2204 55.4747 33.547 57.6522 27.436 65.636C21.3249 73.6197 23.5181 87.3331 32.7997 91.1969C35.0853 92.1481 37.7401 92.5749 39.4194 94.3953C41.9942 97.1825 40.9141 101.726 39.0083 105.022C37.1025 108.318 34.4407 111.418 34.1916 115.207C33.8711 119.942 37.6339 124.221 42.0691 125.909C46.5043 127.597 51.443 127.215 56.1308 126.475C62.3917 125.485 69.3703 123.243 71.938 117.444C73.7306 113.385 72.8091 108.704 73.1923 104.285C73.5756 99.8653 76.4447 94.8081 80.8747 95.052C84.2316 95.2384 86.5346 98.334 89.1058 100.501C92.0934 103.02 96.1175 104.487 99.9639 103.802C103.81 103.118 107.28 100.001 107.773 96.1251C108.042 94.0155 107.463 91.8885 107.479 89.7615C107.537 82.0965 116.057 76.2764 123.603 77.5968C131.15 78.9173 136.872 85.8279 138.522 93.3117C141.135 105.226 133.819 118.514 122.347 122.665C114.346 125.562 105.423 124.299 97.1488 126.283C95.3284 126.719 93.4191 127.402 92.3321 128.928C89.2452 133.265 95.2622 139.106 93.9661 144.268C92.6996 149.311 86.052 150.017 81.0663 151.496C76.1189 152.957 71.6314 156.307 69.3685 160.945C67.1056 165.582 67.3304 171.491 70.426 175.618C74.1295 180.555 80.8364 182.082 86.998 182.422C97.9536 183.028 110.613 179.821 115.393 169.944C118.119 164.307 118.355 156.621 123.919 153.733C126.934 152.165 130.538 152.659 133.944 152.665C149.312 152.686 163.104 141.46 169.727 127.593C176.351 113.727 176.887 97.7086 175.314 82.4223C173.95 69.1741 169.823 54.3371 157.913 48.3811C148.741 43.7926 137.834 45.9632 127.584 45.6287C124.887 45.5399 121.607 44.8221 120.828 42.237C120.431 40.92 120.849 39.5159 121.011 38.1501C121.861 31.0496 115.834 25.0605 109.801 21.2245C103.34 17.1168 93.67 14.5386 88.9403 20.5608C84.9929 25.5953 85.6723 32.2864 83.9964 40.9966Z"
      fill="currentColor"
    />
  </Icon>
);

Weird.displayName = 'WeirdShape';
