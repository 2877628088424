import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const HourGlass: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M5 1a1 1 0 0 0 0 2h1v3.172a3 3 0 0 0 .879 2.121L10.586 12l-3.707 3.707A3 3 0 0 0 6 17.828V21H5a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2h-1v-3.172a3 3 0 0 0-.879-2.121L13.414 12l3.707-3.707A3 3 0 0 0 18 6.172V3h1a1 1 0 1 0 0-2H5Zm3 2v3.172a1 1 0 0 0 .293.707L12 10.586l3.707-3.707A1 1 0 0 0 16 6.172V3H8Zm8 14.828V21H8v-3.172a1 1 0 0 1 .293-.707L12 13.414l3.707 3.707a1 1 0 0 1 .293.707Z"
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

HourGlass.displayName = 'HourGlassIcon';
