import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MousePointer: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M3.645 2.999 C 3.438 3.050,3.191 3.244,3.081 3.442 C 3.004 3.578,2.982 3.676,2.984 3.860 C 2.986 4.085,3.184 4.576,6.212 11.840 C 9.322 19.303,9.444 19.586,9.613 19.746 C 10.040 20.153,10.736 20.042,10.996 19.526 C 11.037 19.446,11.472 18.196,11.963 16.748 L 12.856 14.117 15.318 16.574 C 17.991 19.241,17.879 19.147,18.303 19.111 C 18.894 19.062,19.283 18.433,19.059 17.889 C 19.016 17.785,18.255 16.997,16.557 15.298 L 14.116 12.856 16.748 11.963 C 18.196 11.472,19.446 11.037,19.526 10.996 C 20.042 10.736,20.153 10.039,19.746 9.614 C 19.586 9.446,19.284 9.316,11.859 6.224 C 7.121 4.250,4.069 2.999,3.959 2.986 C 3.861 2.975,3.719 2.980,3.645 2.999 M11.026 7.823 C 14.015 9.068,16.474 10.100,16.490 10.115 C 16.507 10.131,15.522 10.483,14.302 10.897 C 13.082 11.311,12.021 11.693,11.943 11.745 C 11.866 11.798,11.760 11.917,11.707 12.010 C 11.654 12.104,11.281 13.156,10.878 14.350 C 10.474 15.543,10.131 16.506,10.115 16.490 C 10.089 16.462,5.560 5.612,5.560 5.576 C 5.560 5.567,5.567 5.560,5.576 5.560 C 5.585 5.560,8.037 6.579,11.026 7.823 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

MousePointer.displayName = 'MousePointerIcon';
