import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const RefreshCcw: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.153 2.043 C 9.185 2.206,7.272 2.978,5.702 4.244 C 5.547 4.369,4.655 5.191,3.720 6.071 L 2.020 7.671 2.000 5.705 C 1.980 3.745,1.980 3.740,1.886 3.550 C 1.643 3.056,1.036 2.866,0.531 3.126 C 0.312 3.239,0.101 3.494,0.040 3.719 C 0.012 3.824,0.002 4.935,0.009 7.088 L 0.020 10.300 0.131 10.489 C 0.192 10.592,0.301 10.723,0.374 10.778 C 0.672 11.006,0.578 11.000,3.998 11.000 C 7.545 11.000,7.390 11.013,7.701 10.701 C 8.180 10.222,8.051 9.410,7.450 9.114 C 7.261 9.021,7.250 9.020,5.403 9.000 L 3.547 8.980 5.120 7.500 C 6.869 5.856,7.043 5.704,7.629 5.317 C 8.564 4.699,9.647 4.284,10.820 4.095 C 11.428 3.997,12.582 3.997,13.180 4.094 C 14.903 4.375,16.310 5.068,17.536 6.240 C 18.392 7.058,19.021 8.010,19.511 9.225 C 19.730 9.768,20.010 9.981,20.500 9.978 C 20.930 9.976,21.221 9.788,21.397 9.400 C 21.564 9.030,21.488 8.675,21.040 7.734 C 20.565 6.733,19.983 5.886,19.233 5.100 C 17.962 3.769,16.273 2.775,14.540 2.339 C 13.354 2.040,12.300 1.948,11.153 2.043 M16.664 13.063 C 16.303 13.195,16.051 13.514,16.013 13.888 C 15.970 14.307,16.174 14.690,16.539 14.878 L 16.738 14.980 18.595 15.000 L 20.453 15.020 18.880 16.500 C 16.818 18.440,16.521 18.676,15.540 19.160 C 13.272 20.280,10.623 20.269,8.389 19.132 C 7.621 18.741,7.087 18.360,6.464 17.761 C 5.602 16.932,4.978 15.988,4.490 14.776 C 4.295 14.292,4.031 14.063,3.610 14.014 C 3.208 13.966,2.821 14.181,2.630 14.557 C 2.428 14.955,2.491 15.280,2.960 16.266 C 3.474 17.347,4.070 18.193,4.918 19.043 C 6.526 20.654,8.329 21.548,10.740 21.926 C 11.249 22.007,12.799 21.994,13.380 21.905 C 15.199 21.626,16.899 20.889,18.260 19.788 C 18.436 19.645,19.345 18.809,20.280 17.929 L 21.980 16.329 22.000 18.315 L 22.020 20.300 22.131 20.489 C 22.470 21.065,23.234 21.168,23.701 20.701 C 24.013 20.390,24.000 20.544,24.000 17.000 C 24.000 13.456,24.013 13.610,23.701 13.299 C 23.389 12.987,23.547 13.000,19.983 13.003 C 17.342 13.005,16.794 13.015,16.664 13.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

RefreshCcw.displayName = 'RefreshCcwIcon';
