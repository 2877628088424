import { FC, ReactElement, ReactNode } from 'react';
import { TableInstance } from 'react-table';
import {
  Box,
  BoxProps,
  Flex,
  Table as ChakraTable,
  TableContainer,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react';

interface TableProps extends Partial<TableInstance> {
  headerRowProps?: BoxProps;
  headerColumnProps?: BoxProps;
  hideHeader?: boolean;
  rowProps?: BoxProps;
  rowPropsGenerator?: (row) => Record<string, unknown>;
  rowStyleGenerator?: (row) => Record<string, unknown>;
  containerProps?: BoxProps;
  emptyComponent?: ReactElement;
  testId?: string;
  alternativeColorRows?: boolean;
  isLoading?: boolean;
  loadingComponent?: ReactElement;
  forceDivs?: boolean;
}

export const Table: FC<TableProps> = ({
  headerRowProps,
  headerColumnProps,
  hideHeader = false,
  rowProps,
  rowPropsGenerator,
  rowStyleGenerator,
  containerProps,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  emptyComponent,
  testId = 'table',
  alternativeColorRows = true,
  isLoading = true,
  loadingComponent,
  forceDivs = false,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isLoading) {
    if (loadingComponent) return loadingComponent;
    return <>{'Loading...'}</>;
  }

  if (!rows?.length) {
    if (emptyComponent) return emptyComponent;
    return <>{'No data here'}</>;
  }

  const useDivs = forceDivs || isMobile;

  /* eslint-disable react/jsx-key */
  /* React Table creates it's own render keys in the generated props.
   * Having an explicitly declared key for the sake of satisfying the linter will break functionality.
   */

  return (
    <TableContainer data-testid={testId} width="100%">
      <ChakraTable
        as={useDivs ? 'div' : 'table'}
        sx={{ fontVariantNumeric: 'unset' }}
        width="100%"
        {...getTableProps()}
        {...containerProps}
      >
        {!isMobile && !hideHeader && (
          <Thead as={useDivs ? 'div' : 'tbody'}>
            {headerGroups.map(headerGroup => (
              <Tr
                px={{ base: 2, sm: 3, md: 8 }}
                as={useDivs ? 'div' : 'tr'}
                {...headerGroup.getHeaderGroupProps()}
                {...headerRowProps}
              >
                {headerGroup.headers.map(column => (
                  <Th
                    as={useDivs ? 'div' : 'th'}
                    {...column.getHeaderProps()}
                    pt={0}
                    px={0}
                    pb={2}
                    fontSize="sm"
                    letterSpacing="normal"
                    textTransform="none"
                    fontWeight="semi"
                    borderColor="main.light"
                    {...headerColumnProps}
                  >
                    <Flex width="100%" height="100%">
                      {column.render('Header') as ReactNode}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
        )}
        <Box
          as={useDivs ? 'div' : 'tbody'}
          {...getTableBodyProps()}
          sx={
            alternativeColorRows
              ? {
                  'tr:nth-of-type(odd)': {
                    backgroundColor: 'main.sky',
                  },
                  '& > div:nth-of-type(odd)': {
                    backgroundColor: 'main.sky',
                  },
                  'tr:not(:last-child)': {
                    borderBottomWidth: 1,
                  },
                }
              : null
          }
        >
          {rows.map(row => {
            prepareRow(row);
            return (
              <Box
                as={useDivs ? 'div' : 'tr'}
                {...row.getRowProps()}
                borderStyle="solid"
                borderColor="main.light"
                borderBottomWidth={useDivs ? 1 : 2}
                position="relative"
                zIndex={1}
                display={useDivs && 'flex'}
                alignItems={useDivs && 'flex-start'}
                flexDirection={{ base: 'column', md: 'initial' }}
                sx={{
                  ...(!!rowStyleGenerator && rowStyleGenerator(row)),
                }}
                {...(!!rowPropsGenerator && rowPropsGenerator(row))}
                {...rowProps}
              >
                {row.cells.map(cell => {
                  return (
                    <Box
                      as={useDivs ? 'div' : 'td'}
                      {...cell.getCellProps()}
                      position="relative"
                      zIndex={2}
                    >
                      {cell.render('Cell') as ReactNode}
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </ChakraTable>
    </TableContainer>
  );
};
