import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Calculator: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62 2.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.327-.084 15.031 0 15.357.281 1.096 1.045 1.879 2.184 2.238.177.055.818.064 5.56.075 3.614.008 5.449-.001 5.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.328.084-15.032 0-15.357-.307-1.197-1.264-2.09-2.444-2.28-.246-.04-10.662-.057-10.86-.018zm10.72 2.041c.115.039.263.135.361.233.324.324.299-.322.299 7.701s.025 7.377-.299 7.701c-.32.32.082.299-5.701.299-5.784 0-5.381.021-5.702-.3-.324-.324-.3.328-.288-7.782L6.02 4.7l.111-.189c.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.703-.002 5.18.004 5.357.064zM8.643 6.069C8.291 6.193 8 6.614 8 7c0 .242.119.521.299.701C8.61 8.013 8.456 8 12 8s3.39.013 3.701-.299a.984.984 0 000-1.402c-.312-.312-.154-.299-3.718-.296-2.757.003-3.186.011-3.34.066zm0 3.997C8.288 10.197 8 10.615 8 11c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm3 0c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm3 0c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm-6 3C8.288 13.197 8 13.615 8 14c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm3 0c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm3 .003a1.066 1.066 0 00-.577.591c-.057.168-.066.413-.066 1.84 0 1.871.005 1.907.299 2.201.18.18.459.299.701.299.242 0 .521-.119.701-.299.294-.294.299-.33.299-2.201s-.005-1.907-.299-2.201a.998.998 0 00-1.058-.23zm-6 2.997C8.288 16.197 8 16.615 8 17c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm3 0c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

Calculator.displayName = 'CalculatorIcon';
