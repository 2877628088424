import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const FastForward: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.56 6.056c-.525.116-1.103.562-1.33 1.024C3.992 7.567 4 7.391 4 12s-.008 4.433.23 4.92c.061.124.222.333.369.48.585.581 1.375.743 2.164.444.162-.061.903-.594 2.73-1.964L12 14v1.112c0 1.207.023 1.384.23 1.808.061.124.222.333.369.48.585.581 1.375.743 2.164.444.164-.062.972-.646 3.04-2.198 1.549-1.162 2.901-2.204 3.004-2.314a1.981 1.981 0 000-2.664c-.103-.11-1.455-1.152-3.004-2.314-2.068-1.552-2.876-2.136-3.04-2.198-.789-.299-1.579-.137-2.164.444a2.249 2.249 0 00-.369.48c-.207.424-.23.601-.23 1.808V10L9.493 8.12C7.654 6.741 6.927 6.218 6.764 6.158c-.432-.162-.796-.193-1.204-.102zM11.316 12c0 .011-1.196.916-2.658 2.01L6 16V8l2.658 1.99c1.462 1.094 2.658 1.999 2.658 2.01zm8 0c0 .011-1.196.916-2.658 2.01L14 16V8l2.658 1.99c1.462 1.094 2.658 1.999 2.658 2.01z"
      fill="currentColor"
    />
  </Icon>
);

FastForward.displayName = 'FastForwardIcon';
