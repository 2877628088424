import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Water: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.573 1.952 C 11.198 2.086,11.045 2.241,10.032 3.514 C 6.851 7.509,4.931 11.216,4.557 14.080 C 4.495 14.556,4.496 15.574,4.558 16.100 C 4.772 17.898,5.420 19.306,6.557 20.443 C 7.932 21.818,9.720 22.491,12.000 22.491 C 14.280 22.491,16.068 21.818,17.443 20.443 C 18.579 19.308,19.227 17.897,19.442 16.100 C 19.510 15.529,19.501 14.507,19.424 13.955 C 19.024 11.110,17.076 7.403,13.875 3.398 C 13.054 2.371,12.844 2.148,12.574 2.015 C 12.282 1.872,11.869 1.846,11.573 1.952 M12.477 4.047 C 15.326 7.561,17.299 11.058,17.818 13.518 C 18.087 14.792,18.036 16.094,17.673 17.240 C 17.234 18.622,16.308 19.725,15.057 20.352 C 13.557 21.105,11.307 21.212,9.586 20.613 C 8.829 20.350,8.231 19.987,7.683 19.459 C 6.588 18.403,5.997 16.838,6.001 15.006 C 6.006 12.646,7.577 9.271,10.463 5.416 C 11.068 4.609,11.962 3.484,12.000 3.484 C 12.011 3.484,12.225 3.737,12.477 4.047 M15.840 14.685 C 15.710 14.737,15.529 14.910,15.453 15.055 C 15.420 15.118,15.377 15.342,15.357 15.554 C 15.290 16.257,15.069 16.733,14.582 17.224 C 14.323 17.485,14.192 17.582,13.915 17.716 C 13.547 17.893,13.145 17.998,12.820 18.000 C 12.093 18.006,11.735 18.803,12.242 19.286 C 12.443 19.478,12.673 19.528,13.124 19.481 C 14.174 19.371,14.999 18.966,15.744 18.194 C 16.330 17.589,16.674 16.887,16.823 15.996 C 16.944 15.271,16.842 14.905,16.470 14.725 C 16.284 14.635,16.010 14.617,15.840 14.685 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Water.displayName = 'WaterIcon';
