import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Newspaper: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 3.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.326-.084 13.031 0 13.357.241.939.927 1.749 1.769 2.086.576.232-.039.214 7.975.227 4.97.008 7.448-.001 7.632-.029 1.196-.184 2.166-1.079 2.472-2.281.083-.326.083-10.034 0-10.357-.307-1.197-1.264-2.089-2.448-2.281A7.458 7.458 0 0018.615 6h-.605l-.027-.273a3.019 3.019 0 00-2.507-2.685c-.242-.039-10.66-.056-10.856-.017zm10.705 2.036a.982.982 0 01.561.489l.094.19.021 6.4c.02 5.887.027 6.418.09 6.62l.069.23c0 .005-2.544.01-5.653.01-6.352 0-5.887.022-6.209-.3-.323-.323-.3.208-.288-6.782L4.02 5.7l.111-.189c.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.529-.002 5.185.005 5.342.059zM6.643 7.069a1.118 1.118 0 00-.343.229C5.997 7.601 6 7.572 6 10c0 2.427-.003 2.399.299 2.701.311.312.157.299 3.701.299s3.39.013 3.701-.299c.302-.302.299-.274.299-2.701s.003-2.399-.299-2.701c-.312-.312-.154-.299-3.718-.296-2.757.003-3.186.011-3.34.066zm12.697.997c.115.039.263.135.361.233.319.318.299-.022.299 5.201s.02 4.883-.299 5.201a.984.984 0 01-1.402 0c-.32-.32-.299.073-.299-5.628V8h.573c.428 0 .622.017.767.066zM12 10v1H8V9h4v1zm-5.357 5.069C6.291 15.193 6 15.614 6 16c0 .242.119.521.299.701.311.312.157.299 3.701.299s3.39.013 3.701-.299a.984.984 0 000-1.402c-.312-.312-.154-.299-3.718-.296-2.757.003-3.186.011-3.34.066z"
      fill="currentColor"
    />
  </Icon>
);

Newspaper.displayName = 'NewspaperIcon';
