import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Search: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.04 2.061A8.053 8.053 0 003.281 5.66c-.958 1.467-1.425 3.397-1.24 5.132.297 2.784 1.958 5.167 4.451 6.387a7.969 7.969 0 006.006.418 8.996 8.996 0 002.002-.99l.4-.278 2.78 2.77c2.527 2.519 2.798 2.777 2.977 2.836.39.129.766.044 1.044-.234s.363-.654.234-1.044c-.059-.18-.317-.45-2.837-2.977l-2.771-2.78.278-.398c1.034-1.483 1.534-3.436 1.352-5.285-.188-1.911-1.05-3.667-2.461-5.018A7.988 7.988 0 009.04 2.061zm2.124 2.055a6.012 6.012 0 011.597.568c1.64.848 2.781 2.391 3.146 4.256.108.55.097 1.663-.022 2.22a5.963 5.963 0 01-.569 1.599c-.852 1.645-2.396 2.786-4.256 3.148-.514.099-1.582.099-2.12-.002a6.03 6.03 0 01-4.845-4.845c-.101-.538-.101-1.606-.002-2.12a6.156 6.156 0 01.569-1.653 5.897 5.897 0 012.584-2.606c.675-.35 1.257-.526 2.174-.657.281-.041 1.374.017 1.744.092z"
      fill="currentColor"
    />
  </Icon>
);

Search.displayName = 'SearchIcon';
