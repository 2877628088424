import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Video: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M3.576 5.280 C 2.326 5.416,1.270 6.307,0.875 7.560 L 0.780 7.860 0.780 12.000 L 0.780 16.140 0.875 16.440 C 1.269 17.690,2.261 18.539,3.540 18.719 C 3.767 18.751,5.197 18.761,8.460 18.752 L 13.060 18.740 13.412 18.629 C 14.545 18.274,15.275 17.545,15.629 16.412 L 15.740 16.060 15.751 12.165 C 15.764 7.870,15.763 7.862,15.517 7.247 C 15.311 6.730,14.897 6.197,14.440 5.858 C 14.209 5.686,13.690 5.440,13.365 5.348 C 13.062 5.263,13.037 5.262,8.460 5.256 C 5.930 5.252,3.732 5.263,3.576 5.280 M21.280 6.060 C 21.097 6.121,17.185 8.847,16.928 9.093 C 16.752 9.261,16.568 9.643,16.520 9.940 C 16.469 10.252,16.469 13.748,16.520 14.060 C 16.576 14.402,16.768 14.768,16.992 14.961 C 17.366 15.280,21.065 17.855,21.258 17.929 C 21.511 18.027,21.949 18.031,22.220 17.938 C 22.615 17.803,22.932 17.515,23.116 17.122 L 23.220 16.900 23.220 12.000 L 23.220 7.100 23.114 6.873 C 22.971 6.569,22.733 6.319,22.430 6.157 C 22.208 6.038,22.140 6.022,21.820 6.012 C 21.581 6.005,21.399 6.021,21.280 6.060 M13.043 6.824 C 13.323 6.906,13.495 7.009,13.732 7.236 C 13.963 7.458,14.090 7.666,14.177 7.964 C 14.235 8.164,14.241 8.588,14.231 12.084 L 14.220 15.980 14.093 16.249 C 13.916 16.623,13.654 16.889,13.286 17.071 L 12.984 17.220 8.323 17.231 C 3.731 17.241,3.659 17.240,3.421 17.160 C 2.810 16.954,2.383 16.451,2.279 15.813 C 2.228 15.504,2.228 8.496,2.279 8.187 C 2.383 7.548,2.822 7.032,3.424 6.840 C 3.649 6.768,3.866 6.764,8.243 6.762 C 12.325 6.760,12.849 6.767,13.043 6.824 M21.760 12.003 C 21.760 14.634,21.745 16.481,21.724 16.473 C 21.704 16.466,20.858 15.876,19.844 15.162 L 18.000 13.863 18.000 12.001 L 18.000 10.139 19.850 8.833 C 20.867 8.115,21.713 7.525,21.730 7.523 C 21.747 7.521,21.760 9.537,21.760 12.003 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Video.displayName = 'VideoIcon';
