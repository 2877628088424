import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const OfficeBuilding: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62 2.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.247.601-.227-.09-.24 8.415L3.999 20h-.573c-.685 0-.88.052-1.127.299a.984.984 0 000 1.402c.326.326-.563.299 9.701.299 10.264 0 9.375.027 9.701-.299a.984.984 0 000-1.402c-.247-.247-.442-.299-1.127-.299h-.573l-.011-7.77c-.013-8.465.005-7.808-.227-8.385-.367-.916-1.287-1.641-2.283-1.802-.246-.04-10.662-.057-10.86-.018zm10.72 2.041c.115.039.263.135.361.233.325.325.299-.374.299 8.128V20h-3v-2.098c0-1.377-.015-2.171-.044-2.31a2.062 2.062 0 00-1.548-1.548c-.283-.059-2.533-.059-2.816 0a2.062 2.062 0 00-1.548 1.548c-.029.139-.044.933-.044 2.31V20H5.999l.011-7.65.01-7.65.111-.189c.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.703-.002 5.18.004 5.357.064zM8.643 6.069C8.291 6.193 8 6.614 8 7c0 .396.291.808.66.934.278.094 1.402.094 1.68 0 .369-.126.66-.538.66-.934s-.291-.808-.66-.934c-.268-.091-1.436-.089-1.697.003zm5 0C13.291 6.193 13 6.614 13 7c0 .396.291.808.66.934.278.094 1.402.094 1.68 0 .369-.126.66-.538.66-.934s-.291-.808-.66-.934c-.268-.091-1.436-.089-1.697.003zm-5 4C8.291 10.193 8 10.614 8 11c0 .242.119.521.299.701.254.254.435.299 1.201.299s.947-.045 1.201-.299a.984.984 0 000-1.402c-.256-.257-.434-.3-1.218-.296-.492.002-.709.019-.84.066zm5 0c-.352.124-.643.545-.643.931 0 .242.119.521.299.701.254.254.435.299 1.201.299s.947-.045 1.201-.299a.984.984 0 000-1.402c-.256-.257-.434-.3-1.218-.296-.492.002-.709.019-.84.066zM13 18v2h-2v-4h2v2z"
      fill="currentColor"
    />
  </Icon>
);

OfficeBuilding.displayName = 'OfficeBuildingIcon';
