/* eslint-disable max-len */
import { createIcon } from '@chakra-ui/react';

export const Logo = createIcon({
  displayName: 'Logo',
  viewBox: '0 0 450 152',
  defaultProps: {
    width: 112.5,
    height: 38,
    color: 'text.primary',
  },
  path: (
    <>
      <path
        fill="currentColor"
        d="M.5 117.031h46.332l.686-5.491c-8.923-1.03-12.526-3.775-11.668-10.468l2.574-21.278c.686-6.52 3.432-9.438 10.81-9.438 9.782 0 13.9 4.462 16.989 17.503 4.976 20.249 9.095 29.172 27.627 29.172h8.752l.858-5.663c-7.722 0-9.438-10.296-12.012-21.621-2.917-13.213-7.379-18.705-16.817-21.793-1.03-.344-.858-1.888.343-2.06 17.16-2.402 28.314-14.07 28.314-30.373 0-16.13-14.07-25.053-36.55-25.053H13.542l-.687 5.49c7.207 1.03 10.811 3.776 9.953 10.468l-9.095 74.646c-.858 6.693-5.148 9.438-12.527 10.468L.5 117.031zm49.078-54.397c-6.693 0-8.752-2.917-7.894-9.61l3.26-25.911c.858-6.178 3.26-9.61 11.498-9.61h7.55c12.355 0 16.474 8.409 16.474 18.361 0 15.273-8.58 26.77-23.681 26.77h-7.207zm97.113 55.427c15.101 0 25.397-8.409 30.03-14.071l-3.26-4.29c-4.977 5.148-11.669 7.207-17.847 7.207-9.609 0-15.958-4.977-19.39-12.184-2.574-5.32-1.202-9.266 3.603-10.124l7.722-1.373c23.338-4.118 33.805-11.497 33.805-24.196 0-11.668-8.751-21.278-25.911-21.278-27.799 0-42.9 25.397-42.9 46.332 0 21.107 15.444 33.977 34.148 33.977zm-7.207-38.61c-4.805.686-7.379-1.373-7.379-6.006 0-15.787 7.207-29.515 20.249-29.515 7.036 0 11.497 3.775 11.497 14.414 0 6.52-1.544 17.675-20.935 20.592l-3.432.515z"
      />
      <path
        fill="currentColor"
        d="M182.669 151.351c18.19 0 32.776-10.811 35.35-32.089l8.065-65.208c.687-5.32 2.574-6.52 10.125-6.35l10.982.344.858-7.55h-10.982c-7.722 0-9.267-1.03-8.752-5.492l1.201-11.325c1.373-12.184 2.231-17.847 12.87-17.847 15.273 0 25.054 10.983 21.107 41.528l-7.035 54.568c-1.373 10.64 3.775 16.131 12.355 16.131 3.946 0 10.81-1.201 15.958-6.006l.858-5.148c-5.662 2.917-10.639-.172-9.781-7.207l7.207-57.658C286.831 11.154 267.611 0 247.191 0c-21.622 0-35.178 12.012-37.924 34.492-.686 5.662-5.319 7.035-13.556 8.408l-.686 4.805h8.408c3.089 0 4.29 1.03 3.604 6.006l-8.237 65.894c-1.716 13.213-3.432 25.912-11.669 25.912-9.438 0-1.544-16.131-14.586-16.131-6.521 0-9.266 4.119-9.266 9.095 0 8.58 8.065 12.87 19.39 12.87zm147.855-33.29c15.101 0 25.397-8.409 30.03-14.071l-3.26-4.29c-4.976 5.148-11.669 7.207-17.846 7.207-9.61 0-15.959-4.977-19.391-12.184-2.574-5.32-1.201-9.266 3.603-10.124l7.722-1.373c23.338-4.118 33.806-11.497 33.806-24.196 0-11.668-8.752-21.278-25.912-21.278-27.799 0-42.9 25.397-42.9 46.332 0 21.107 15.444 33.977 34.148 33.977zm-7.207-38.61c-4.805.686-7.379-1.373-7.379-6.006 0-15.787 7.208-29.515 20.249-29.515 7.036 0 11.497 3.775 11.497 14.414 0 6.52-1.544 17.675-20.935 20.592l-3.432.515zm54.128 38.267c8.58 0 13.728-5.663 20.935-18.018l5.32-8.924 4.633 10.468c4.29 9.781 6.692 16.474 15.787 16.474 3.947 0 9.781-1.202 15.616-6.006l.686-5.148c-6.521 3.26-10.467 0-13.728-7.38L414.682 71.9l3.775-6.349c8.924-15.272 12.527-18.533 15.273-18.533 3.603 0 3.775 6.521 8.923 6.521 4.118 0 6.521-3.26 6.521-6.52 0-4.29-3.261-8.58-10.639-8.58-8.58 0-13.729 5.662-20.936 18.017l-5.662 9.438-4.805-10.982c-4.29-9.781-6.693-16.474-15.787-16.474-3.947 0-9.782 1.202-15.616 6.006l-.686 5.148c6.52-3.26 10.467 0 13.727 7.38l12.184 27.627-3.432 6.006c-8.923 15.272-12.527 18.533-15.272 18.533-3.604 0-3.776-5.835-8.924-5.835-4.118 0-6.52 3.261-6.52 6.521 0 4.29 3.26 7.894 10.639 7.894z"
      />
    </>
  ),
});
