import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Chip: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.643 2.069a1.118 1.118 0 00-.343.229c-.248.248-.3.443-.3 1.124v.569l-.77.02c-.786.021-.937.046-1.385.226-.672.269-1.322.912-1.595 1.578-.193.469-.217.616-.239 1.415l-.02.77h-.569c-.681 0-.876.052-1.123.299a.984.984 0 000 1.402c.247.247.442.299 1.128.299H4v4h-.573c-.686 0-.881.052-1.128.299a.984.984 0 000 1.402c.247.247.442.299 1.123.299h.569l.02.77c.014.507.041.838.08.97.325 1.097 1.081 1.853 2.169 2.17.129.038.472.066.97.079l.77.02v.569c0 .681.052.876.299 1.123a.984.984 0 001.402 0c.247-.247.299-.442.299-1.128V20h4v.573c0 .686.052.881.299 1.128a.984.984 0 001.402 0c.247-.247.299-.442.299-1.128V20h.61c.335 0 .725-.019.866-.042 1.16-.188 2.09-1.036 2.434-2.218.038-.129.066-.472.079-.97l.02-.77h.569c.681 0 .876-.052 1.123-.299a.984.984 0 000-1.402c-.247-.247-.442-.299-1.128-.299H20v-4h.573c.686 0 .881-.052 1.128-.299a.984.984 0 000-1.402C21.454 8.052 21.259 8 20.578 8h-.569l-.02-.77c-.021-.786-.046-.937-.226-1.385-.291-.726-1.009-1.409-1.753-1.667-.394-.137-.715-.177-1.4-.178H16v-.573c0-.686-.052-.881-.299-1.128a.984.984 0 00-1.402 0c-.247.247-.299.442-.299 1.128V4h-4v-.573c0-.686-.052-.881-.299-1.128a.998.998 0 00-1.058-.23zm8.697 3.997c.115.039.263.135.361.233.32.32.299-.082.299 5.701 0 5.783.021 5.381-.299 5.701-.32.32.082.299-5.701.299-5.784 0-5.381.021-5.702-.3-.321-.321-.3.088-.289-5.782L6.02 6.7l.111-.189c.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.703-.002 5.18.004 5.357.064zM8.643 8.069a1.118 1.118 0 00-.343.229C7.987 8.611 8 8.455 8 12c0 3.544-.013 3.39.299 3.701.311.312.157.299 3.701.299s3.39.013 3.701-.299c.312-.311.299-.157.299-3.701s.013-3.39-.299-3.701c-.312-.312-.154-.299-3.718-.296-2.757.003-3.186.011-3.34.066zM14 12v2h-4v-4h4v2z"
      fill="currentColor"
    />
  </Icon>
);

Chip.displayName = 'ChipIcon';
