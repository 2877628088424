import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CalendarAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M7.664 1.063 C 7.436 1.146,7.257 1.297,7.131 1.511 C 7.024 1.693,7.020 1.724,7.007 2.347 L 6.993 2.993 5.727 3.011 C 4.322 3.030,4.276 3.037,3.704 3.312 C 2.836 3.729,2.263 4.466,2.060 5.425 C 1.969 5.851,1.971 20.144,2.062 20.581 C 2.310 21.785,3.215 22.690,4.419 22.938 C 4.680 22.993,5.600 23.000,12.000 23.000 C 19.974 23.000,19.547 23.012,20.150 22.767 C 20.850 22.482,21.482 21.850,21.767 21.150 C 22.012 20.547,22.000 20.974,22.000 13.000 C 22.000 5.024,22.012 5.453,21.766 4.849 C 21.499 4.193,20.964 3.633,20.296 3.312 C 19.724 3.037,19.679 3.030,18.270 3.011 L 17.000 2.993 17.000 2.424 C 17.000 1.741,16.948 1.546,16.700 1.298 C 16.281 0.879,15.570 0.924,15.212 1.393 C 15.031 1.630,15.000 1.781,15.000 2.430 L 15.000 3.000 12.000 3.000 L 9.000 3.000 9.000 2.427 C 9.000 1.741,8.948 1.546,8.701 1.299 C 8.429 1.027,8.018 0.933,7.664 1.063 M7.000 5.570 C 7.000 6.219,7.031 6.370,7.212 6.607 C 7.570 7.076,8.281 7.121,8.700 6.702 C 8.949 6.454,9.000 6.260,9.000 5.573 L 9.000 5.000 12.000 5.000 L 15.000 5.000 15.000 5.570 C 15.000 6.219,15.031 6.370,15.212 6.607 C 15.570 7.076,16.281 7.121,16.700 6.702 C 16.949 6.454,17.000 6.260,17.000 5.573 L 17.000 5.000 18.073 5.000 C 19.308 5.000,19.423 5.021,19.701 5.299 C 19.994 5.591,20.000 5.638,20.000 7.427 L 20.000 9.000 12.000 9.000 L 4.000 9.000 4.000 7.427 C 4.000 5.637,4.006 5.592,4.300 5.298 C 4.574 5.024,4.667 5.007,5.910 5.003 L 7.000 5.000 7.000 5.570 M20.000 15.573 C 20.000 20.714,20.019 20.383,19.701 20.701 C 19.377 21.025,20.023 21.000,12.000 21.000 C 3.977 21.000,4.623 21.025,4.299 20.701 C 3.981 20.383,4.000 20.714,4.000 15.573 L 4.000 11.000 12.000 11.000 L 20.000 11.000 20.000 15.573 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

CalendarAlt.displayName = 'CalendarAltIcon';
