import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DocumentDuplicate: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.62 2.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.193.469-.217.616-.239 1.41l-.02.766-.766.02c-.503.014-.833.041-.965.08-1.125.333-1.901 1.125-2.184 2.229-.083.326-.084 11.032 0 11.357.281 1.096 1.047 1.881 2.184 2.237.175.055.735.065 4.56.076 2.923.009 4.45-.001 4.633-.03 1.172-.181 2.11-1.028 2.457-2.22.038-.129.066-.472.08-.97l.02-.77h.605c.668-.001.99-.04 1.36-.168.938-.324 1.615-1.055 1.94-2.092.054-.174.063-.676.075-3.833.008-2.412 0-3.71-.029-3.86a2.73 2.73 0 00-.183-.527c-.137-.29-.224-.382-2.57-2.728-2.347-2.347-2.438-2.433-2.728-2.569a2.665 2.665 0 00-.515-.182c-.203-.039-5.157-.053-5.345-.016zm7.17 4.185L19 8.42v3.363c0 3.787.014 3.605-.299 3.918-.317.317-.038.299-4.7.299-4.666 0-4.385.018-4.703-.3-.32-.321-.3.088-.289-5.782L9.02 4.7l.111-.189c.125-.213.318-.375.539-.454.119-.042.647-.054 2.53-.055L14.58 4l2.21 2.21zm-9.78 5.56c.012 3.31.021 3.794.077 3.97.361 1.137 1.039 1.815 2.173 2.173.172.054.586.065 2.97.077l2.77.014v.571c0 .684-.052.879-.299 1.126-.317.317-.038.299-4.7.299-4.666 0-4.385.018-4.703-.3-.32-.321-.3.088-.289-5.782L5.02 8.7l.111-.189c.125-.213.318-.375.539-.454.098-.035.352-.054.739-.055L6.997 8l.013 3.77z"
      fill="currentColor"
    />
  </Icon>
);

DocumentDuplicate.displayName = 'DocumentDuplicateIcon';
