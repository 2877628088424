import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChartPie: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.58 2.1a10.015 10.015 0 00-7.561 5.52c-.462.941-.775 1.975-.945 3.12-.069.469-.069 1.997 0 2.5.261 1.885.973 3.567 2.126 5.02a13.175 13.175 0 001.54 1.54c3.421 2.714 8.13 2.931 11.76.543a10.002 10.002 0 004.4-6.957c.059-.419.039-.637-.078-.86-.084-.16-.301-.352-.502-.443-.178-.08-.241-.081-4.75-.082L12 12V7.43l-.001-4.57-.094-.2a.995.995 0 00-.805-.592 2.586 2.586 0 00-.52.032zm3.977.53c-.241.123-.45.366-.515.601C14.015 3.327 14 4.4 14 6.263c0 3.25-.011 3.128.299 3.438.31.31.188.299 3.438.299 1.863 0 2.936-.015 3.032-.042.393-.109.711-.535.711-.951 0-.422-.542-1.608-1.159-2.538A9.986 9.986 0 0015.666 2.7c-.566-.221-.783-.235-1.109-.07zm-4.444 10.819c.102.207.27.368.487.467.125.057.63.066 4.646.084l4.507.02-.157.48a8.48 8.48 0 01-1.385 2.54 10.805 10.805 0 01-1.311 1.286A8.16 8.16 0 0111.992 20a8.145 8.145 0 01-5.032-1.789c-.354-.286-1.027-.972-1.286-1.311a8.177 8.177 0 01-1.632-4.094A8.084 8.084 0 015.674 7.1c.26-.339.932-1.025 1.286-1.311A8.48 8.48 0 019.5 4.404l.48-.157.02 4.507.02 4.506.093.189zm6.576-7.93c.701.489 1.703 1.575 2.145 2.322l.094.159H16V5.072l.159.094c.088.052.326.211.53.353z"
      fill="currentColor"
    />
  </Icon>
);

ChartPie.displayName = 'ChartPieIcon';
