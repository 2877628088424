import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Link: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 2.065a5.27 5.27 0 00-2.38.867c-.432.289-1.82 1.666-1.944 1.928a.981.981 0 00.62 1.366.945.945 0 00.695-.051c.071-.036.447-.378.837-.76.937-.919 1.363-1.185 2.111-1.319.394-.07.597-.069 1.021.005 1.267.221 2.222 1.184 2.443 2.464.156.897-.114 1.79-.763 2.526-.541.612-4.192 4.211-4.446 4.381a3.236 3.236 0 01-.95.436c-.31.085-1.114.095-1.424.017a3.182 3.182 0 01-1.36-.736c-.313-.282-.506-.361-.84-.342-.654.037-1.082.632-.902 1.253.123.423.754.967 1.602 1.383 1.618.793 3.444.664 4.997-.353.411-.269 4.973-4.828 5.253-5.25a5.355 5.355 0 00.796-1.909c.102-.523.093-1.391-.022-1.928-.42-1.973-1.932-3.485-3.881-3.882-.402-.082-1.142-.131-1.463-.096zm-6.24 5.991c-.797.132-1.635.482-2.24.937-.189.142-1.278 1.202-2.664 2.594-2.487 2.496-2.567 2.588-2.914 3.313-.359.749-.501 1.462-.468 2.34.039 1.05.351 1.955.955 2.769a4.896 4.896 0 003.831 2.017c.916.035 1.613-.112 2.421-.51.581-.286.98-.608 1.862-1.502.843-.856.877-.907.877-1.321 0-.25-.153-.568-.354-.734-.285-.237-.742-.295-1.057-.134-.071.036-.471.401-.889.81-.799.783-1.058.985-1.51 1.175a3.025 3.025 0 01-3.482-.871 3.041 3.041 0 01-.287-3.362c.207-.373.569-.757 2.744-2.92 1.656-1.646 2.07-2.037 2.325-2.194a3.028 3.028 0 012.588-.289c.367.128.647.297 1.044.632.365.31.512.369.858.347a.975.975 0 00.882-1.258c-.059-.205-.107-.274-.376-.535-.642-.625-1.487-1.064-2.426-1.261-.424-.089-1.309-.111-1.72-.043z"
      fill="currentColor"
    />
  </Icon>
);

Link.displayName = 'LinkIcon';
