import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillHeavyLifter: FC<IconProps> = props => (
  <Icon {...props}>
    <g clipPath="url(#clip0)" fill="currentColor">
      <path d="M6.531 18.094a1.173 1.173 0 000 2.343c.646 0 1.172-.525 1.172-1.171 0-.647-.526-1.172-1.172-1.172zm0 1.562a.39.39 0 110-.782.39.39 0 010 .782z" />
      <path d="M20.828 18.094h-.625V9.656a.39.39 0 00-.39-.39h-1.954V3.953a.39.39 0 00-.39-.39H9.656a.39.39 0 00-.39.39v14.14h-.264a2.718 2.718 0 00-1.299-1.298V3.172C7.703 2.526 7.177 2 6.531 2h-3.36a1.173 1.173 0 000 2.344H5.36v12.45a2.74 2.74 0 00-1.562 2.472A2.738 2.738 0 006.53 22a2.74 2.74 0 002.471-1.563h11.826c.646 0 1.172-.525 1.172-1.171 0-.647-.526-1.172-1.172-1.172zm-1.406 0h-9.375v-8.047h2.734v4.14a.39.39 0 00.625.313l1.328-.996 1.329.996a.39.39 0 00.625-.313v-4.14h2.734v8.047zm-5.86-8.047h2.344v3.36l-.937-.704a.39.39 0 00-.469 0l-.938.703v-3.36zm-.78-5.703h1.562v2.239l-.565-.377a.39.39 0 00-.433 0l-.565.377v-2.24zm-.782 0v2.968a.39.39 0 00.607.326L13.563 7l.955.637a.39.39 0 00.607-.325v-2.97h1.953v4.923h-7.031V4.344H12zm-8.828-.782a.391.391 0 010-.78h3.36c.215 0 .39.174.39.39v13.387a2.745 2.745 0 00-.781 0V3.954a.39.39 0 00-.391-.39H3.172zm3.36 17.657a1.954 1.954 0 110-3.905 1.954 1.954 0 010 3.905zm14.296-1.563H9.238a2.749 2.749 0 000-.781h11.59a.391.391 0 010 .781z" />
      <path d="M18.25 17.313a.39.39 0 00.39-.391v-.781a.39.39 0 10-.78 0v.78c0 .217.174.392.39.392zM18.25 14.188a.39.39 0 110 .781.39.39 0 010-.781zM16.688 15.75a.39.39 0 00-.391.39v.782a.39.39 0 10.781 0v-.781a.39.39 0 00-.39-.391z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="transparent" transform="translate(2 2)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </Icon>
);

SkillHeavyLifter.displayName = 'SkillHeavyLifterIcon';
