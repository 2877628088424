import { FC } from 'react';
import {
  Flex,
  FlexProps,
  Spinner,
  SpinnerProps,
  Text,
  TextProps,
} from '@chakra-ui/react';

interface RealSimpleLoadingProps {
  containerProps?: FlexProps;
  textProps?: TextProps;
  spinnerProps?: SpinnerProps;
}

export const RealSimpleLoading: FC<RealSimpleLoadingProps> = ({
  containerProps,
  textProps,
  spinnerProps,
}) => {
  return (
    <Flex
      px={6}
      gap={2}
      alignItems="center"
      color="blackAlpha.400"
      {...containerProps}
    >
      <Text {...textProps}>{'Loading...'}</Text>
      <Spinner h={3} w={3} mt={1} {...spinnerProps} />
    </Flex>
  );
};
