import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Puzzle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.62 1.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.193.469-.217.616-.239 1.415l-.02.77H8.397c-1.008 0-1.671.016-1.805.044a2.062 2.062 0 00-1.548 1.548C5.016 6.726 5 7.389 5 8.397v1.594l-.77.02c-.507.014-.838.041-.97.08-1.123.333-1.903 1.13-2.184 2.232-.072.282-.072 1.072 0 1.354.281 1.102 1.061 1.899 2.184 2.232.132.039.463.066.97.08l.77.02v1.594c0 1.008.016 1.671.044 1.805.157.75.798 1.391 1.548 1.548.289.06 3.527.06 3.816 0a2.062 2.062 0 001.548-1.548c.024-.117.045-.522.046-.9.001-.469.019-.736.055-.838a.998.998 0 011.643-.372c.257.257.3.433.3 1.227 0 .369.02.766.044.883.157.75.798 1.391 1.548 1.548.289.06 3.527.06 3.816 0a2.062 2.062 0 001.548-1.548c.06-.289.06-3.527 0-3.816a2.062 2.062 0 00-1.548-1.548c-.117-.024-.514-.044-.883-.044-.794 0-.97-.043-1.227-.3a.998.998 0 01.372-1.643c.102-.036.369-.054.838-.055.378-.001.783-.022.9-.046a2.062 2.062 0 001.548-1.548c.06-.289.06-3.527 0-3.816-.119-.57-.563-1.14-1.076-1.381-.422-.199-.555-.211-2.277-.211h-1.594l-.02-.77c-.014-.507-.041-.838-.08-.97-.354-1.196-1.268-2.03-2.429-2.217-.252-.041-.697-.05-.86-.018zm.72 2.041c.253.087.507.341.594.594.051.15.066.346.066.865 0 .369.02.766.044.883.157.75.798 1.391 1.548 1.548.134.028.798.044 1.81.044H19v2.991l-.77.02c-.507.014-.838.041-.97.08-1.123.333-1.903 1.13-2.184 2.232-.072.282-.072 1.072 0 1.354.281 1.102 1.061 1.899 2.184 2.232.132.039.463.066.97.08l.77.02V19h-2.991l-.02-.77c-.026-.956-.114-1.297-.484-1.865a2.988 2.988 0 00-4.14-.87c-.485.316-.91.819-1.115 1.32-.193.469-.217.616-.239 1.415l-.02.77H7v-1.598c0-1.012-.016-1.676-.044-1.81a2.062 2.062 0 00-1.548-1.548C5.291 14.02 4.894 14 4.525 14c-.794 0-.97-.043-1.227-.3a.998.998 0 01.372-1.643c.102-.036.369-.054.838-.055.378-.001.783-.022.9-.046a2.062 2.062 0 001.548-1.548c.028-.134.044-.798.044-1.81V7h1.598c1.012 0 1.676-.016 1.81-.044a2.059 2.059 0 001.481-1.302c.074-.202.088-.352.108-1.094.023-.849.024-.862.134-1.049a.987.987 0 011.209-.445z"
      fill="currentColor"
    />
  </Icon>
);

Puzzle.displayName = 'PuzzleIcon';
