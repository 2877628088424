import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DeviceDesktop: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 2.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.326-.084 11.032 0 11.357.281 1.096 1.056 1.89 2.184 2.236.165.051.547.064 2.205.077l2.004.015-.18.727-.181.728-.487.5c-.391.401-.501.539-.554.697-.131.387-.047.765.232 1.044.317.317.038.299 4.701.299 4.663 0 4.384.018 4.701-.299.279-.279.363-.657.232-1.044-.053-.158-.163-.296-.554-.697l-.487-.5-.181-.73-.181-.73h1.845c1.083 0 1.951-.018 2.101-.042 1.179-.191 2.146-1.092 2.448-2.278.083-.327.083-11.033 0-11.357-.307-1.197-1.264-2.09-2.444-2.28-.246-.04-14.661-.057-14.86-.018zm14.72 2.041c.115.039.263.135.361.233.314.314.299.106.299 4.128V12H3.998l.011-3.65.011-3.65.111-.189c.125-.213.318-.375.539-.454.123-.044 1.434-.054 7.313-.055 6.565-.002 7.179.004 7.357.064zM20 14.573c0 .686-.052.881-.299 1.128-.324.324.322.299-7.701.299s-7.377.025-7.701-.299C4.052 15.454 4 15.259 4 14.573V14h16v.573zm-6.285 4.406c.135.539.245.989.245 1 0 .012-.882.021-1.96.021-1.078 0-1.96-.009-1.96-.021 0-.011.11-.461.245-1L10.53 18h2.94l.245.979z"
      fill="currentColor"
    />
  </Icon>
);

DeviceDesktop.displayName = 'DeviceDesktopIcon';
