import { useState } from 'react';
import { PageSizes } from 'types';

export { PageSizes };

interface useGraphHelpersProps {
  pageSize?: number;
}

const defaultProps = {
  pageSize: PageSizes.twentyFive,
};

export const useGraphHelpers = (props: useGraphHelpersProps = defaultProps) => {
  const { pageSize = defaultProps?.pageSize } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [_pageSize, setPageSize] = useState(pageSize);
  const [searchString, setSearchString] = useState('');
  const [sortString, setSortString] = useState('');

  const returnProps = {
    currentPage,
    setCurrentPage,
    pageSize: _pageSize,
    setPageSize,
    searchString,
    setSearchString,
    sortString,
    setSortString,
  };

  return {
    ...returnProps,
    commonOzPagingProps: {
      // FUTURE: rename to commonPagingProps
      containerProps: { px: 6, mt: 5, mb: 4 },
      pagingLabelProps: { fontSize: 'sm', flexGrow: 1, ml: 0 },
      handleNext: () => setCurrentPage(currentPage + 1),
      handlePrevious: () => setCurrentPage(currentPage - 1),
      handleChangePageSize: currentPageSize => {
        setPageSize(currentPageSize);
        setCurrentPage(0);
      },
      hideWhenLoading: true,
      emptyText: '',
      ...returnProps,
    },
  };
};
