import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DocumentAdd: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62 2.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.327-.084 15.031 0 15.357.281 1.099 1.043 1.878 2.184 2.238.177.055.818.064 5.56.075 3.614.008 5.449-.001 5.633-.03a3.034 3.034 0 002.306-1.8c.233-.551.218-.175.231-5.755.008-3.423-.002-5.211-.029-5.36a2.74 2.74 0 00-.183-.525c-.138-.291-.226-.384-3.07-3.228-2.846-2.846-2.936-2.932-3.228-3.069a2.665 2.665 0 00-.515-.182c-.204-.039-6.155-.053-6.345-.016zm8.67 4.685L18 9.42v4.863c0 5.466.02 5.099-.299 5.418-.32.32.082.299-5.701.299-5.784 0-5.381.021-5.702-.3-.324-.324-.3.328-.288-7.782L6.02 4.7l.111-.189c.125-.213.318-.375.539-.454.12-.043.729-.054 3.03-.055L12.58 4l2.71 2.71zm-3.647 2.359a1.066 1.066 0 00-.577.591c-.055.162-.066.373-.066 1.267V12H9.927c-1.235 0-1.35.021-1.628.299a.984.984 0 000 1.402c.278.278.393.299 1.628.299H11v1.073c0 .894.011 1.105.066 1.267.126.369.538.66.934.66s.808-.291.934-.66c.055-.162.066-.373.066-1.267V14h1.073c1.235 0 1.35-.021 1.628-.299a.984.984 0 000-1.402c-.278-.278-.393-.299-1.628-.299H13v-1.073c0-1.235-.021-1.35-.299-1.628a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

DocumentAdd.displayName = 'DocumentAddIcon';
