import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Collection: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.62 2.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.199.485-.218.607-.239 1.509l-.02.863-.145.051c-.611.213-1.327.92-1.596 1.577-.199.485-.218.607-.239 1.509l-.02.863-.145.051c-.249.087-.695.385-.941.629a3.076 3.076 0 00-.829 1.453c-.083.323-.083 7.034 0 7.357.281 1.099 1.041 1.876 2.184 2.238.179.056.983.064 7.56.075 4.998.008 7.448-.001 7.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.324.083-7.036 0-7.357a3.166 3.166 0 00-.828-1.457c-.25-.247-.791-.605-.977-.646l-.11-.024-.02-.868c-.02-.893-.04-1.019-.226-1.483a3.217 3.217 0 00-.667-.979c-.25-.247-.791-.605-.977-.646l-.11-.024-.02-.868c-.02-.893-.04-1.019-.226-1.483-.367-.916-1.287-1.641-2.283-1.802-.245-.04-6.665-.056-6.86-.018zm6.72 2.041c.253.087.507.341.594.594.049.145.066.339.066.767V6H7.993l.014-.65c.013-.627.017-.657.124-.839.125-.213.318-.375.539-.454.12-.043.775-.054 3.313-.055 2.842-.002 3.182.005 3.357.064zm2 4c.253.087.507.341.594.594.049.145.066.339.066.767V10H5.993l.014-.65c.013-.627.017-.657.124-.839.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.703-.002 5.18.004 5.357.064zm2 4c.115.039.263.135.361.233.312.311.299.157.299 3.701s.013 3.39-.299 3.701c-.324.324.322.299-7.701.299-8.025 0-7.377.025-7.702-.3-.314-.314-.301-.151-.289-3.783L4.02 12.7l.111-.189c.125-.213.318-.375.539-.454.123-.044 1.434-.054 7.313-.055 6.565-.002 7.179.004 7.357.064z"
      fill="currentColor"
    />
  </Icon>
);

Collection.displayName = 'CollectionIcon';
