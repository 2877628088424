import { ElementSizes } from './types';

export const elementPropsBySize = {
  [ElementSizes.LG]: {
    paragraph: {
      mt: 2,
      mb: 4,
      fontSize: 'xl',
    },
    h1: {
      fontSize: '4xl',
      fontWeight: 'semi',
      lineHeight: 'short',
      mb: 2,
    },
    h2: {
      fontSize: '3xl',
      fontWeight: 'semi',
      lineHeight: 'short',
      mt: 12,
      mb: 1,
    },
    h3: {
      fontSize: '2xl',
      fontWeight: 'semi',
      lineHeight: 'short',
      mb: 1,
    },
    ui: {
      mt: -2.5,
      mb: 3,
      pl: 7,
      ml: -1,
    },
    li: {
      fontSize: 'xl',
    },
    spacer: {
      height: 1,
    },
    divider: {
      borderBottomColor: 'blackAlpha.200',
    },
  },
  [ElementSizes.MD]: {
    paragraph: {
      mt: 2,
      mb: 2,
      fontSize: { base: 'md', md: 'lg' },
      lineHeight: 'short',
    },
    h1: {
      fontSize: '3xl',
      fontWeight: 'semi',
      lineHeight: 'short',
      mb: 2,
    },
    h2: {
      fontSize: '2xl',
      fontWeight: 'semi',
      lineHeight: 'short',
      mt: 12,
      mb: 1,
    },
    h3: {
      fontSize: 'xl',
      fontWeight: 'semi',
      lineHeight: 'short',
      mb: 1,
    },
    ui: {
      mt: -1,
      mb: 4,
      pl: 7,
      ml: -1,
    },
    li: {
      fontSize: { base: 'md', md: 'lg' },
      lineHeight: 'shorter',
    },
    spacer: {
      height: 1,
    },
    divider: {
      borderBottomColor: 'blackAlpha.200',
      mt: 3,
      mb: 4,
    },
  },
};

export const videoControlsConfig = {
  wistia: {
    options: {
      controlsVisibleOnLoad: true,
    },
  },
};
