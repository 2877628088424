import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const GlobeAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.28 2.023l-.52.06c-1.731.197-3.485.929-4.96 2.071-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.586 1.06 3.279 1.609 5.2 1.687 3.143.128 6.206-1.283 8.184-3.769 1.121-1.409 1.793-2.977 2.082-4.857.087-.562.099-2.033.022-2.58-.232-1.656-.743-3.017-1.629-4.34-1.609-2.405-4.085-3.943-7.019-4.36-.312-.044-1.756-.085-2-.057zm.923 2.125c.641.579 1.248 2.295 1.554 4.393.101.69.203 1.781.203 2.169V11h-3.92v-.29c.001-.678.169-2.111.364-3.105.259-1.318.684-2.518 1.116-3.145.123-.179.416-.46.48-.46.022 0 .113.066.203.148zm-3.126.771C8.556 6.26 8.144 8.457 8.049 10.4l-.029.58-1.97.01-1.97.011v-.1c0-.186.191-1.003.337-1.444.27-.816.815-1.813 1.342-2.457.645-.789 1.517-1.528 2.34-1.983.273-.151 1.113-.538 1.13-.521a4.79 4.79 0 01-.152.423zm6.673.022a8.025 8.025 0 014.17 5.96v.1l-1.97-.011-1.97-.01-.027-.54c-.097-1.989-.521-4.238-1.051-5.58a15.851 15.851 0 01-.142-.367c0-.027.644.265.99.448zM8.047 13.56c.107 2.18.524 4.274 1.174 5.89.023.058-.034.044-.307-.072-2.436-1.039-4.196-3.166-4.738-5.725a5.087 5.087 0 01-.096-.554v-.1l1.97.011 1.97.01.027.54zm5.913-.27c0 .388-.102 1.479-.203 2.169-.255 1.748-.741 3.301-1.276 4.079-.124.181-.417.462-.481.462s-.357-.281-.48-.46c-.432-.627-.857-1.827-1.116-3.145-.195-.994-.363-2.427-.364-3.105V13h3.92v.29zm5.96-.19a8.025 8.025 0 01-4.17 5.959c-.346.183-.99.475-.99.448 0-.004.064-.169.142-.367.526-1.331.964-3.644 1.042-5.492.012-.301.03-.57.038-.598.012-.039.443-.05 1.977-.05h1.961v.1z"
      fill="currentColor"
    />
  </Icon>
);

GlobeAlt.displayName = 'GlobeAltIcon';
