export const selectColors = {
  backgroundHover: 'main.light',
  backgroundSelected: 'blackAlpha.200',
  backgroundActive: 'main.medium',
  textActive: 'text.secondary',
  textSelected: 'text.secondary',
  tagDefault: 'main.light',
};

export const selectStyleOverrides = {
  container: provided => ({
    ...provided,
    width: '100%',
  }),
  clearIndicator: provided => ({
    ...provided,
    '--close-button-size': '28px',
    fontSize: '10px',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    borderColor: 'main.light',
    maxHeight: '20px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    px: 3,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    display: 'flex',
    pt: state.hasValue && !state.isMulti ? 1 : null,
    pl: state.hasValue ? 2 : null,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? selectColors.textSelected : null,
    backgroundColor: state.isSelected ? selectColors.backgroundSelected : null,
    fontWeight: 'medium',
    _hover: {
      backgroundColor: state.isSelected ? null : selectColors.backgroundHover,
    },
    _active: { backgroundColor: selectColors.backgroundActive },
  }),
  input: provided => ({
    ...provided,
    gridArea: 'inherit', // LATER: fix safari input with better approach
  }),
  menu: provided => ({
    ...provided,
  }),
  menuList: provided => ({
    ...provided,
    minW: 8,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.data.isFixed ? selectColors.backgroundActive : null,
    color: state.data.isFixed ? 'text.primary' : null,
  }),
};
