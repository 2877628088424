import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SearchCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.301.869 2.577 1.358 4.2 1.607.562.087 2.033.099 2.58.022 2.016-.283 3.753-1.046 5.24-2.3 4.113-3.47 4.719-9.628 1.362-13.848-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.993 7.993 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.689-9.3 7.491 7.491 0 011.46-2.073c1.693-1.742 4.142-2.639 6.516-2.386zm-.212 3.003a4.15 4.15 0 00-1.84.688c-.313.211-.801.702-1.018 1.023-.793 1.177-.919 2.701-.325 3.945l.153.32-1.21 1.22c-1.064 1.074-1.217 1.244-1.274 1.417-.13.389-.045.766.233 1.044s.655.363 1.044.233c.173-.057.345-.212 1.417-1.27l1.22-1.206.3.15c.165.083.462.199.66.259.335.101.411.109 1.08.109.671 0 .745-.007 1.08-.109 1.385-.422 2.337-1.377 2.768-2.776.101-.326.108-.402.108-1.071 0-.669-.008-.746-.109-1.08-.492-1.63-1.801-2.724-3.467-2.899a5.387 5.387 0 00-.44-.035l-.38.038zm.96 2.043c.302.076.692.314.922.562.372.401.538.826.538 1.374 0 1.511-1.566 2.443-2.939 1.747-.305-.154-.677-.551-.841-.897-.728-1.54.657-3.203 2.32-2.786z"
      fill="currentColor"
    />
  </Icon>
);

SearchCircle.displayName = 'SearchCircleIcon';
