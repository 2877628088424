import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const EmojiSad: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.439.34-1.308 1.21-1.649 1.651-1.146 1.479-1.814 3.078-2.079 4.975-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.301.869 2.577 1.358 4.2 1.607.562.087 2.033.099 2.58.022 2.016-.283 3.753-1.046 5.24-2.3 4.113-3.47 4.719-9.628 1.362-13.848-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.992 7.992 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.689-9.3 7.491 7.491 0 011.46-2.073c1.693-1.742 4.142-2.639 6.516-2.386zM8.643 9.066C8.288 9.197 8 9.615 8 10c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm6 0c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm-3.383 4.99c-1.051.173-2.126.715-2.767 1.394-.271.287-.364.57-.297.905a.961.961 0 00.924.798c.338.019.523-.058.872-.368.673-.598 1.506-.864 2.362-.756.66.083 1.112.291 1.688.777.365.31.512.369.858.347a.975.975 0 00.882-1.258c-.059-.204-.108-.274-.376-.536-.636-.621-1.486-1.063-2.426-1.26-.424-.089-1.309-.111-1.72-.043z"
      fill="currentColor"
    />
  </Icon>
);

EmojiSad.displayName = 'EmojiSadIcon';
