import { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { BlockText } from './BlockText';
import { elementPropsBySize } from './elements.config';
import { BlockType, ElementSizes } from './types';

interface BlockListItemProps extends BlockType {
  size: ElementSizes;
  id: string;
}

export const BlockListItem: FC<BlockListItemProps> = ({
  size,
  id,
  rich_text,
}) => {
  return (
    <Box as="ul" {...elementPropsBySize[size].ui}>
      <Text as="li" color="text.secondary" {...elementPropsBySize[size].li}>
        <BlockText text={rich_text} id={id} />
      </Text>
    </Box>
  );
};
