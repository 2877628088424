import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UserEdit: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.5 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm-2.036 8.464A5 5 0 0 1 5 14h7a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 1.464-3.536Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.721 4.38a.875.875 0 0 1 1.237.001l2.66 2.66a.875.875 0 0 1 .001 1.238l-5.33 5.34a.875.875 0 0 1-.62.256H15a.875.875 0 0 1-.875-.875v-2.67c0-.232.092-.455.257-.62l5.34-5.33Zm-3.846 6.313v1.432h1.432l4.456-4.464-1.424-1.424-4.464 4.456Z"
      clipRule="evenodd"
    />
  </Icon>
);

UserEdit.displayName = 'UserEditIcon';
