import { FC } from 'react';
import { BoxProps, Flex, Text } from '@chakra-ui/react';
import { titleCase } from 'helpers';

interface ProgressStepProps {
  label: string;
  number: number;
  handleClick: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
  containerProps?: BoxProps;
}

export const ProgressStep: FC<ProgressStepProps> = ({
  label,
  number,
  isActive,
  isDisabled = false,
  handleClick,
  containerProps,
}) => (
  <Flex
    cursor={isDisabled ? 'not-allowed' : 'pointer'}
    onClick={isDisabled ? () => null : handleClick}
    mr={{ base: 2, sm: 0 }}
    mb={{ base: 3, sm: 0 }}
    userSelect="none"
    {...containerProps}
  >
    <Flex
      h={6}
      w={6}
      borderRadius="full"
      backgroundColor={isActive ? 'main.green' : 'blackAlpha.50'}
      alignItems="center"
      justifyContent="center"
    >
      <Text
        color={isActive ? 'main.white' : 'blackAlpha.300'}
        fontWeight="semi"
      >
        {number}
      </Text>
    </Flex>
    <Text
      ml={1.5}
      color={isActive ? 'main.green' : 'blackAlpha.400'}
      fontWeight="medium"
    >
      {titleCase(label.toLowerCase())}
    </Text>
  </Flex>
);
