import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Refresh: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.643 3.069a1.118 1.118 0 00-.343.229c-.309.309-.3.215-.3 3.202 0 2.986-.009 2.894.299 3.2.307.309.215.3 3.201.3s2.894.009 3.201-.3c.18-.18.299-.458.299-.7 0-.396-.291-.808-.66-.934C9.173 8.009 8.941 8 7.71 8c-.892 0-1.432-.014-1.423-.038.007-.021.135-.191.283-.378.916-1.15 2.366-2.055 3.852-2.404 1.924-.451 4.008-.035 5.658 1.132.487.344 1.264 1.12 1.608 1.608a7.35 7.35 0 011.232 3.02c.061.4.111.53.272.708.219.242.417.33.748.33.329 0 .534-.09.739-.323.21-.24.254-.386.23-.775-.047-.776-.375-1.893-.827-2.822A9.03 9.03 0 0013.34 3.1a10.507 10.507 0 00-2.14-.063 9.123 9.123 0 00-6.014 3.077L5 6.329V5.09c0-1.416-.015-1.508-.299-1.792a.998.998 0 00-1.058-.23zm.157 8.966c-.3.098-.555.327-.67.602-.192.46.2 2.102.789 3.303 1.321 2.693 3.799 4.502 6.802 4.966.636.098 1.927.098 2.559 0 2.164-.332 4.122-1.4 5.514-3.004l.206-.238v1.24c0 1.422.015 1.513.299 1.797a.984.984 0 001.402 0c.308-.307.299-.215.299-3.201s.009-2.894-.299-3.201c-.307-.308-.215-.3-3.201-.3s-2.894-.008-3.201.3a.984.984 0 000 1.402c.289.289.354.299 1.988.299.788 0 1.433.01 1.433.023s-.079.126-.176.25c-2.015 2.62-5.621 3.462-8.604 2.008a7.004 7.004 0 01-3.86-5.221 3.415 3.415 0 00-.086-.415c-.057-.178-.289-.431-.488-.532-.175-.09-.547-.13-.706-.078z"
      fill="currentColor"
    />
  </Icon>
);

Refresh.displayName = 'RefreshIcon';
