import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowDownNarrow: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.643 2.069a1.118 1.118 0 00-.343.229c-.326.326-.3-.409-.3 8.418v7.861l-1.23-1.223c-1.085-1.078-1.253-1.231-1.427-1.288-.389-.13-.766-.046-1.045.234a.99.99 0 00-.186 1.144c.061.125.701.79 2.217 2.301 2.003 1.997 2.144 2.13 2.345 2.195.261.084.526.066.77-.052.125-.061.79-.701 2.301-2.217 1.919-1.924 2.131-2.15 2.19-2.328.129-.39.044-.766-.234-1.044s-.655-.363-1.044-.233c-.174.057-.342.21-1.427 1.288L13 18.577v-7.861c0-8.825.026-8.092-.299-8.417a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

ArrowDownNarrow.displayName = 'ArrowDownNarrowIcon';
