import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Logout: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.31 3.063A3.918 3.918 0 003.179 4.18a3.87 3.87 0 00-1.12 2.172c-.053.31-.059 1.05-.049 5.812.012 5.456.012 5.457.099 5.777.416 1.533 1.519 2.612 3.031 2.965.295.07.521.075 2.86.075 2.335 0 2.566-.006 2.86-.074a4.047 4.047 0 003.027-2.966c.067-.258.086-.488.102-1.187.023-1.018-.008-1.174-.287-1.453a.984.984 0 00-1.403-.001c-.256.256-.299.433-.299 1.226 0 .369-.02.766-.044.883a2.062 2.062 0 01-1.548 1.548c-.292.061-4.524.061-4.816 0a2.062 2.062 0 01-1.548-1.548c-.062-.298-.062-10.518 0-10.816a2.062 2.062 0 011.548-1.548c.292-.06 4.524-.06 4.816 0 .75.157 1.391.798 1.548 1.548.024.117.044.514.044.883 0 .793.043.97.299 1.226a.984.984 0 001.403-.001c.279-.279.31-.435.287-1.453-.016-.7-.035-.929-.102-1.187-.41-1.586-1.631-2.72-3.228-2.999-.489-.085-4.875-.083-5.349.002zM16.643 7.07c-.352.123-.643.544-.643.93 0 .08.03.234.066.343.057.174.21.342 1.288 1.427L18.577 11h-5.861c-6.585 0-6.095-.023-6.417.299a.984.984 0 000 1.402c.322.322-.168.299 6.417.299h5.861l-1.223 1.23c-1.078 1.085-1.231 1.253-1.288 1.427-.13.389-.046.766.234 1.045a.99.99 0 001.144.186c.125-.06.79-.701 2.301-2.217 1.997-2.003 2.13-2.144 2.195-2.345a1.018 1.018 0 00-.052-.77c-.061-.125-.701-.79-2.217-2.3-1.924-1.92-2.15-2.132-2.328-2.191a1.032 1.032 0 00-.7.004z"
      fill="currentColor"
    />
  </Icon>
);

Logout.displayName = 'LogoutIcon';
