import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Moon3: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M10.532 2.079 C 7.031 2.625,4.058 4.983,2.760 8.241 C 2.240 9.545,2.012 10.826,2.052 12.220 C 2.077 13.068,2.144 13.581,2.325 14.319 C 2.774 16.141,3.649 17.693,4.978 19.022 C 6.805 20.849,9.130 21.858,11.720 21.947 C 14.487 22.041,17.115 21.004,19.082 19.041 C 20.072 18.053,20.784 16.991,21.294 15.740 C 21.707 14.729,22.056 13.168,21.985 12.652 C 21.918 12.166,21.503 11.803,21.009 11.801 C 20.748 11.800,20.606 11.858,20.188 12.136 C 19.534 12.570,18.821 12.869,18.014 13.047 C 17.443 13.173,16.237 13.173,15.666 13.047 C 13.224 12.509,11.472 10.750,10.955 8.320 C 10.826 7.714,10.826 6.604,10.955 6.000 C 11.123 5.214,11.436 4.458,11.843 3.852 C 12.129 3.427,12.198 3.260,12.199 3.000 C 12.200 2.617,11.987 2.279,11.627 2.093 C 11.443 1.998,11.078 1.993,10.532 2.079 M9.198 4.801 C 8.428 7.256,8.921 9.974,10.514 12.060 C 10.773 12.399,11.446 13.085,11.800 13.371 C 13.872 15.046,16.685 15.590,19.196 14.803 L 19.552 14.692 19.510 14.816 C 19.424 15.077,19.044 15.836,18.820 16.197 C 18.377 16.912,17.582 17.795,16.940 18.286 C 15.533 19.361,13.784 19.961,12.061 19.960 C 10.287 19.958,8.562 19.372,7.140 18.286 C 6.801 18.026,6.115 17.354,5.829 17.000 C 4.848 15.786,4.245 14.309,4.082 12.726 C 3.753 9.505,5.501 6.313,8.403 4.838 C 8.595 4.740,9.262 4.447,9.305 4.441 C 9.308 4.440,9.260 4.602,9.198 4.801 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Moon3.displayName = 'Moon3Icon';
