import { FC } from 'react';
import { BoxProps, Flex, IconProps, Text, TextProps } from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';
import { Shape } from 'illustrations';

interface TextIconShapeCardProps {
  text: string;
  textProps?: TextProps;
  subText?: string;
  subTextProps?: TextProps;
  icon: ICON_NAMES;
  iconProps?: IconProps;
  shape?: string;
  shapeProps?: IconProps;
  onClick: () => void;
  containerProps?: BoxProps;
}

export const TextIconShapeCard: FC<TextIconShapeCardProps> = ({
  text,
  textProps,
  subText,
  subTextProps,
  icon,
  iconProps,
  shape = 'blob',
  shapeProps,
  onClick,
  containerProps,
}) => {
  return (
    <Flex
      alignItems="center"
      backgroundColor="main.sky"
      borderRadius="lg"
      borderWidth={2}
      borderStyle="solid"
      borderColor="blackAlpha.50"
      py={2}
      px={2}
      gap={2}
      cursor="pointer"
      as="button"
      onClick={() => onClick()}
      {...containerProps}
    >
      <Flex w={12} h={12} position="relative" zIndex={2}>
        <Shape
          position="absolute"
          zIndex={1}
          top={0}
          bottom={0}
          left={0}
          right={0}
          name={shape}
          alignSelf="center"
          color="blackAlpha.50"
          width="100%"
          height="100%"
          {...shapeProps}
        />
        <Icon
          name={icon}
          position="relative"
          zIndex={2}
          h={8}
          w={8}
          mt={3}
          ml={3}
          color="blackAlpha.300"
          {...iconProps}
        />
      </Flex>
      <Flex alignItems="baseline" columnGap={2} rowGap={1} flexWrap="wrap">
        <Text
          fontSize={{ base: 'md', sm: 'xl' }}
          fontWeight="medium"
          color="text.secondary"
          lineHeight={1}
          {...textProps}
        >
          {text}
        </Text>
        {subText && (
          <Text
            color="blackAlpha.600"
            lineHeight={1}
            width="100%"
            textAlign="start"
            alignSelf="flex-start"
            {...subTextProps}
          >
            {subText}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
