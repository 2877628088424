import { FC } from 'react';
import {
  Box,
  BoxProps,
  Flex,
  IconProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Shape } from 'illustrations/Shape';

const reduceNumberWidth = (number: number): string => {
  // FUTURE: consider moving the k,m part to separate shape
  // so we can format as different elements (for sizing)
  // and probably want to make this more eloquent...
  if (!number) return '–';
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(1)}m`;
  } else if (number >= 1000) {
    return `${(number / 1000).toFixed(1)}k`;
  }
  return number.toString();
};

interface ReportsTileCardProps {
  containerProps?: BoxProps;
  textWrapperProps?: BoxProps;
  header?: string;
  headerProps?: TextProps;
  number: number;
  numberProps?: TextProps;
  shapeName?: string;
  shapeSize?: number;
  shapeProps?: IconProps;
  subtitle?: string;
  subtitleProps?: TextProps;
  title: string;
  titleProps?: TextProps;
}

export const ReportsTileCard: FC<ReportsTileCardProps> = ({
  containerProps,
  textWrapperProps,
  header,
  headerProps,
  number,
  numberProps,
  shapeName = 'scramble',
  shapeSize = 16,
  shapeProps,
  subtitle,
  subtitleProps,
  title,
  titleProps,
}) => {
  return (
    <Flex
      alignItems="center"
      backgroundColor="main.white"
      borderRadius="lg"
      flexDirection="column"
      h="100%"
      w="100%"
      py={2}
      shadow="sm"
      {...containerProps}
    >
      {header && <Text {...headerProps}>{header}</Text>}
      <Box position="relative" h={shapeSize} w={shapeSize}>
        <Shape
          color="green.100"
          name={shapeName}
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          height="unset"
          width="unset"
          flexShrink="unset"
          transform="rotate(150deg)"
          {...shapeProps}
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            color="main.green"
            fontSize="xl"
            fontWeight="bold"
            {...numberProps}
          >
            {reduceNumberWidth(number)}
          </Text>
        </Box>
      </Box>
      <Flex flexDirection="column" {...textWrapperProps}>
        <Text {...titleProps}>{title}</Text>
        {subtitle && <Text {...subtitleProps}>{subtitle}</Text>}
      </Flex>
    </Flex>
  );
};
