import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Heart: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.785 4.043a5.525 5.525 0 00-3.166 1.576c-.787.786-1.285 1.721-1.526 2.861-.1.475-.1 1.565 0 2.04.096.452.31 1.088.481 1.429.403.799.356.748 4.702 5.104 2.351 2.356 4.14 4.118 4.244 4.18.152.091.226.107.48.107s.328-.016.48-.107c.104-.062 1.893-1.824 4.244-4.18 4.346-4.356 4.299-4.305 4.702-5.104.171-.341.385-.977.481-1.429.1-.475.1-1.565 0-2.04-.241-1.14-.739-2.075-1.526-2.861-.786-.787-1.721-1.285-2.861-1.526-.475-.1-1.565-.1-2.04 0-1.178.25-2.034.715-2.965 1.618L12 6.21l-.515-.499C10.558 4.813 9.689 4.338 8.54 4.1c-.38-.079-1.364-.11-1.755-.056zm1.667 2.094c.768.226.993.385 2.11 1.486.83.818.919.894 1.117.953.119.035.263.064.321.064.058 0 .202-.029.321-.064.198-.059.287-.135 1.117-.953 1.114-1.098 1.333-1.253 2.11-1.49.315-.095.415-.107.952-.107.537 0 .637.012.952.107 1.204.368 2.047 1.211 2.415 2.415.095.315.107.415.107.952 0 .537-.012.637-.107.952a3.6 3.6 0 01-.529 1.088c-.095.132-1.784 1.851-3.755 3.82L12 18.94l-3.583-3.58c-1.971-1.969-3.66-3.688-3.755-3.82a3.6 3.6 0 01-.529-1.088c-.095-.315-.107-.415-.107-.952 0-.537.012-.637.107-.952.408-1.336 1.443-2.26 2.813-2.509.361-.065 1.117-.016 1.506.098z"
      fill="currentColor"
    />
  </Icon>
);

Heart.displayName = 'HeartIcon';
