import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Plus: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.643 5.069a1.118 1.118 0 00-.343.229c-.308.308-.3.224-.3 3.129V11H8.427c-2.904 0-2.821-.008-3.128.299a.984.984 0 000 1.402c.307.307.224.299 3.128.299H11v2.573c0 2.904-.008 2.82.299 3.128a.984.984 0 001.402 0c.307-.307.299-.224.299-3.128V13h2.573c2.904 0 2.821.008 3.128-.3a.984.984 0 000-1.401c-.307-.307-.224-.3-3.128-.3H13V8.428c0-2.904.008-2.821-.299-3.128a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

Plus.displayName = 'PlusIcon';
