import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Terminal3: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M3.643 4.069 C 3.291 4.193,3.000 4.614,3.000 5.000 C 3.000 5.080,3.029 5.234,3.065 5.343 C 3.124 5.522,3.377 5.788,5.854 8.270 L 8.579 11.000 5.854 13.730 C 3.377 16.212,3.124 16.478,3.065 16.657 C 2.936 17.047,3.021 17.423,3.300 17.702 C 3.602 18.005,4.053 18.078,4.444 17.887 C 4.571 17.826,5.492 16.929,7.745 14.671 C 10.710 11.701,10.874 11.529,10.940 11.326 C 11.024 11.065,11.006 10.800,10.887 10.556 C 10.826 10.429,9.929 9.509,7.671 7.255 C 4.816 4.405,4.523 4.124,4.343 4.065 C 4.101 3.985,3.876 3.987,3.643 4.069 M11.664 18.063 C 11.436 18.146,11.257 18.297,11.131 18.511 C 11.036 18.672,11.020 18.744,11.020 19.000 C 11.020 19.256,11.036 19.328,11.131 19.489 C 11.192 19.592,11.301 19.723,11.374 19.778 C 11.677 20.009,11.484 20.000,15.998 20.000 C 20.663 20.000,20.384 20.018,20.701 19.701 C 21.092 19.310,21.092 18.690,20.701 18.299 C 20.384 17.982,20.666 18.000,15.983 18.003 C 12.474 18.005,11.795 18.015,11.664 18.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Terminal3.displayName = 'Terminal3Icon';
