import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CalendarClock: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 2a1 1 0 1 0-2 0v1H9V2a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h3.5a1 1 0 1 0 0-2H5a1 1 0 0 1-1-1v-9h4a1 1 0 1 0 0-2H4V6a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V5h6v1a1 1 0 1 0 2 0V5h2a1 1 0 0 1 1 1v1.5a1 1 0 1 0 2 0V6a3 3 0 0 0-3-3h-2V2Zm-1 11a1 1 0 0 1 1 1v1.82l1.125.9a1 1 0 1 1-1.25 1.56l-1.5-1.2A1 1 0 0 1 15 16.3V14a1 1 0 0 1 1-1Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm5-7a7 7 0 1 0 0 14 7 7 0 0 0 0-14Z"
      fill="currentColor"
    />
  </Icon>
);

CalendarClock.displayName = 'CalendarClockIcon';
