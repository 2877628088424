import { FC } from 'react';
import { BoxProps, Flex, Text, Tooltip } from '@chakra-ui/react';
import { compactNumber, pluralize, roundBudgetValue } from 'helpers';
import { locationBudget } from 'types';
import { Icon, ICON_NAMES } from 'icons';

export enum BudgetLabelSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

const stylesBySize = (options: { wrapParts: boolean }) => ({
  [BudgetLabelSizes.SMALL]: {
    progressLabelFontSize: options?.wrapParts ? 'xl' : 'sm',
    progressDecimalLabelFontSize: 'sm',
    progressDecimalLabelMarginTop: '3px',
    progressDecimalLabelMarginLeft: '-1.5px',
    goalLabelFontSize: options?.wrapParts ? '11px' : 'sm',
    slashLabelFontSize: '2xs',
  },
  [BudgetLabelSizes.MEDIUM]: {
    progressLabelFontSize: '1.1rem',
    progressDecimalLabelFontSize: '1.1rem',
    progressDecimalLabelMarginTop: 0,
    progressDecimalLabelMarginLeft: -0.5,
    goalLabelFontSize: 'sm',
    slashLabelFontSize: 'sm',
  },
  [BudgetLabelSizes.LARGE]: {
    progressLabelFontSize: '2xl',
    progressDecimalLabelFontSize: 'lg',
    progressDecimalLabelMarginTop: 1,
    progressDecimalLabelMarginLeft: -0.5,
    goalLabelFontSize: 'md',
    slashLabelFontSize: 'md',
  },
});

export enum BudgetUnitVariations {
  FULL = 'full',
  ABBREVIATION = 'abbreviation',
  NONE = 'none',
}

export interface BudgetLabelProps extends locationBudget {
  containerProps?: BoxProps;
  wrapperProps?: BoxProps;
  wrapParts?: boolean;
  isLoading?: boolean;
  size?: BudgetLabelSizes;
  unitVariation?: BudgetUnitVariations;
  compactProgress?: boolean;
  compactGoal?: boolean;
  splitProgressDecimal?: boolean;
  splitGoalDecimal?: boolean;
  showGoal?: boolean;
  progressLabelProps?: BoxProps;
  goalLabelProps?: BoxProps;
  includePendingHoursClarifier?: boolean;
}

export const BudgetLabel: FC<BudgetLabelProps> = ({
  containerProps,
  wrapperProps,
  wrapParts,
  isLoading = true,
  size = BudgetLabelSizes.LARGE,
  unitVariation = BudgetUnitVariations.FULL,
  compactProgress = false,
  compactGoal = false,
  splitProgressDecimal = false,
  splitGoalDecimal = false,
  showGoal = true,
  progressLabelProps,
  goalLabelProps,
  includePendingHoursClarifier = false,
  ...budget
}) => {
  const { blendedTotalHours, totalFeeHours } = budget || {};

  const progressLabel = (() => {
    if (isLoading) return 0;
    const blendedProgress = blendedTotalHours + totalFeeHours;
    return roundBudgetValue(blendedProgress, 2);
  })();

  const goalLabel = (() => {
    if (isLoading) return 0;
    const goalNumber = budget?.amountHours || 0;
    return roundBudgetValue(goalNumber, 2);
  })();

  const unitLabel =
    unitVariation === BudgetUnitVariations.FULL ? 'hours' : 'hrs';

  const styles = stylesBySize({ wrapParts })[size];

  const ProgressLabelComponent =
    splitProgressDecimal && !compactProgress ? (
      <Flex lineHeight={1} {...progressLabelProps}>
        <Text fontSize={styles.progressLabelFontSize}>
          {progressLabel?.toString().split('.')[0]}
        </Text>
        {progressLabel?.toString().split('.')[1] && (
          <Text
            fontSize={styles.progressDecimalLabelFontSize}
            mt={styles?.progressDecimalLabelMarginTop}
            ml={styles?.progressDecimalLabelMarginLeft}
          >
            {`.${progressLabel?.toString().split('.')[1]}`}
          </Text>
        )}
      </Flex>
    ) : (
      <Flex
        fontSize={styles.progressLabelFontSize}
        lineHeight={1}
        {...progressLabelProps}
      >
        <Text>
          {compactGoal ? compactNumber(progressLabel) : progressLabel}
        </Text>
      </Flex>
    );

  const GoalLabelComponent =
    splitGoalDecimal && !compactGoal ? (
      <Flex lineHeight={1} {...goalLabelProps}>
        <Text fontSize={styles.slashLabelFontSize}>{'/'}</Text>
        <Text fontSize={styles.goalLabelFontSize}>{`${goalLabel}`}</Text>
        {unitVariation !== BudgetUnitVariations.NONE && (
          <Text
            fontSize={styles.goalLabelFontSize}
            fontFamily="body"
            fontWeight="semi"
            ml={0.5}
          >
            {unitLabel}
          </Text>
        )}
      </Flex>
    ) : (
      <Flex lineHeight={1} {...goalLabelProps}>
        <Text fontSize={styles.slashLabelFontSize}>{'/'}</Text>
        <Text fontSize={styles.goalLabelFontSize}>
          {compactGoal ? compactNumber(goalLabel) : goalLabel}
        </Text>
        {unitVariation !== BudgetUnitVariations.NONE && (
          <Text
            fontSize={styles.goalLabelFontSize}
            fontFamily="body"
            fontWeight="semi"
            ml={1}
          >
            {unitLabel}
          </Text>
        )}
      </Flex>
    );

  return (
    <Flex flexDirection="row" color="blackAlpha.600" {...containerProps}>
      <Flex
        alignItems="flex-end"
        fontFamily="mono"
        fontWeight="bold"
        flexDirection={wrapParts ? 'column' : 'row'}
        position="relative"
        {...wrapperProps}
      >
        {ProgressLabelComponent}
        {showGoal && GoalLabelComponent}
        {includePendingHoursClarifier && budget?.estimatedPendingHours > 0 && (
          <Tooltip
            label={`Includes ${budget?.estimatedPendingHours} pending ${pluralize('hour', budget?.estimatedPendingHours)}`}
            aria-label={`Includes ${budget?.estimatedPendingHours} pending ${pluralize('hour', budget?.estimatedPendingHours)}`}
          >
            <Flex position="relative">
              <Icon
                name={ICON_NAMES.exclamation}
                color="main.orange"
                h={6}
                w={6}
                ml={2}
              />
            </Flex>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};
