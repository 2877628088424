import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const StatusOnline: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.36 4.675c-.218.071-.356.171-.608.438-1.465 1.553-2.366 3.458-2.679 5.667-.067.467-.067 1.973 0 2.44.313 2.209 1.214 4.114 2.679 5.667.352.373.505.451.888.452.258.001.327-.015.492-.112a1 1 0 00.474-1.077c-.051-.228-.146-.363-.661-.938-1.389-1.553-2.136-3.904-1.904-5.992.201-1.813.905-3.369 2.114-4.674.171-.185.341-.399.377-.474a.983.983 0 00-.824-1.416 1.3 1.3 0 00-.348.019zm12.711.002a1.013 1.013 0 00-.677 1.173c.051.228.146.363.661.938 1.288 1.441 2.021 3.539 1.932 5.532-.09 1.989-.824 3.721-2.206 5.202a2.351 2.351 0 00-.311.402 1.005 1.005 0 00.398 1.303c.165.097.234.113.492.112.384-.001.536-.079.894-.459a9.995 9.995 0 001.707-11.3 11.941 11.941 0 00-.563-1c-.518-.795-1.278-1.677-1.575-1.829-.178-.09-.578-.13-.752-.074zM8.049 7.557c-.537.251-1.455 1.618-1.765 2.63-.21.683-.261 1.042-.261 1.813 0 .771.051 1.13.261 1.813.322 1.053 1.272 2.441 1.809 2.647.276.105.643.071.893-.083.221-.137.429-.444.469-.694a1.012 1.012 0 00-.189-.723 79.543 79.543 0 00-.35-.428c-.619-.753-.896-1.536-.896-2.532s.277-1.779.897-2.532l.349-.428c.146-.18.226-.489.189-.723a1.134 1.134 0 00-.471-.695c-.258-.159-.671-.188-.935-.065zm7.038.027a.955.955 0 00-.54.876c.001.312.072.469.354.783.488.543.817 1.154.99 1.837.122.483.122 1.357 0 1.84a4.287 4.287 0 01-.99 1.837c-.282.314-.353.471-.355.784-.003.554.434.98 1.003.978.319-.001.522-.105.818-.417.757-.8 1.274-1.802 1.518-2.942.075-.349.09-.544.09-1.16 0-.82-.066-1.244-.301-1.94-.336-.993-1.218-2.271-1.725-2.501a1.059 1.059 0 00-.862.025zm-3.553 2.473C10.657 10.269 10 11.102 10 12c0 1.08.92 2 2 2s2-.92 2-2c0-1.096-.934-2.013-2.032-1.997a2.342 2.342 0 00-.434.054z"
      fill="currentColor"
    />
  </Icon>
);

StatusOnline.displayName = 'StatusOnlineIcon';
