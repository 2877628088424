import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Support: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.3.869 2.577 1.358 4.2 1.607.562.087 2.033.099 2.58.022 2.016-.283 3.753-1.046 5.24-2.3 4.113-3.47 4.719-9.628 1.362-13.848-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a8.032 8.032 0 012.712.777c.487.239 1.376.791 1.376.855 0 .015-.477.504-1.061 1.087l-1.06 1.06-.13-.076c-.874-.514-1.634-.723-2.63-.723-1 0-1.717.198-2.628.724l-.13.075-1.06-1.06C7.597 6.177 7.12 5.689 7.12 5.675c0-.064.897-.622 1.374-.856 1.338-.655 2.862-.931 4.298-.778zM6.76 8.181l1.06 1.06-.075.13C7.219 10.282 7.02 11 7.02 12s.198 1.718.724 2.629l.075.13-1.06 1.06c-.583.584-1.072 1.061-1.087 1.061-.064 0-.616-.889-.855-1.376a7.938 7.938 0 010-7.008c.239-.487.79-1.376.855-1.376.015 0 .504.477 1.087 1.061zm11.88-.635a8.008 8.008 0 010 8.908c-.156.234-.297.426-.313.426-.015 0-.502-.475-1.082-1.055l-1.055-1.056.265-.534c.79-1.596.72-3.27-.2-4.864l-.075-.13 1.06-1.06c.583-.584 1.072-1.061 1.087-1.061.016 0 .157.192.313.426zm-6.048 1.517a2.964 2.964 0 011.523.822 2.975 2.975 0 010 4.23 2.975 2.975 0 01-4.23 0 2.985 2.985 0 01.498-4.634 2.965 2.965 0 012.209-.418zM9.55 16.352a4.944 4.944 0 003.169.589c.593-.091.937-.202 1.515-.487l.534-.264 1.056 1.055c.58.58 1.055 1.067 1.055 1.082 0 .064-.89.616-1.376.855a7.939 7.939 0 01-7.004.001c-.435-.213-1.216-.689-1.36-.827-.024-.024.348-.423 1.02-1.097.583-.582 1.074-1.059 1.09-1.059.018 0 .153.068.3.152z"
      fill="currentColor"
    />
  </Icon>
);

Support.displayName = 'SupportIcon';
