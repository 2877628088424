import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Duplicate: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62 3.025c-.408.08-.533.114-.75.202-.88.358-1.549 1.137-1.794 2.093-.083.325-.084 9.033 0 9.357.282 1.102 1.063 1.9 2.184 2.232.132.039.462.066.965.08l.766.02.02.766c.014.503.041.833.08.965.325 1.097 1.05 1.823 2.169 2.174.175.055.735.065 4.56.076 2.923.009 4.45-.001 4.633-.03 1.191-.184 2.167-1.084 2.471-2.28.083-.326.083-9.034 0-9.357-.307-1.197-1.264-2.09-2.444-2.28A7.516 7.516 0 0017.615 7h-.606l-.02-.77c-.014-.507-.04-.838-.08-.97-.354-1.196-1.268-2.03-2.429-2.217-.245-.04-8.663-.057-8.86-.018zm8.72 2.041c.253.087.507.341.594.594.05.145.066.339.066.765v.571l-2.77.014c-2.978.015-2.834.006-3.385.227-.672.269-1.322.912-1.595 1.578-.236.574-.225.417-.24 3.415L6.996 15h-.571c-.685 0-.879-.052-1.127-.3-.318-.318-.301-.031-.289-4.782L5.02 5.7l.111-.189c.125-.213.318-.375.539-.454.122-.043.94-.054 4.313-.055 3.773-.002 4.181.004 4.357.064zm4 4c.115.039.263.135.361.233.317.317.3.038.3 4.701 0 4.663.017 4.384-.3 4.701-.317.317-.038.299-4.7.299-4.666 0-4.385.018-4.703-.3-.318-.318-.301-.031-.289-4.782L9.02 9.7l.111-.189c.125-.213.318-.375.539-.454.122-.043.94-.054 4.313-.055 3.773-.002 4.181.004 4.357.064z"
      fill="currentColor"
    />
  </Icon>
);

Duplicate.displayName = 'DuplicateIcon';
