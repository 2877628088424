import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Server: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 3.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.233.567-.224.459-.24 3.001-.014 2.385-.001 2.687.134 3.093.099.298.283.652.456.881l.159.21-.161.21a3.28 3.28 0 00-.454.881c-.135.405-.148.707-.134 3.093.013 2 .024 2.386.078 2.556.352 1.122 1.049 1.82 2.172 2.175.179.056.983.064 7.56.075 4.998.008 7.448-.001 7.633-.03a3.034 3.034 0 002.306-1.8c.221-.524.215-.446.231-2.976.014-2.385.001-2.687-.134-3.093a3.384 3.384 0 00-.456-.881l-.159-.21.156-.206c.17-.225.357-.585.458-.885.136-.402.149-.704.135-3.093-.016-2.524-.008-2.427-.227-2.971-.367-.916-1.287-1.641-2.283-1.802-.246-.04-14.661-.057-14.86-.018zm14.72 2.041c.253.087.507.341.594.594.058.171.066.448.066 2.34 0 2.427.003 2.399-.299 2.701-.324.324.322.299-7.701.299-8.025 0-7.377.025-7.702-.3-.306-.306-.302-.27-.289-2.784L4.02 5.7l.111-.189c.125-.213.318-.375.539-.454.123-.044 1.434-.054 7.313-.055 6.565-.002 7.179.004 7.357.064zm-2.697 2C16.288 7.197 16 7.615 16 8c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045zm2.697 6c.253.087.507.341.594.594.058.171.066.448.066 2.34 0 2.427.003 2.399-.299 2.701-.324.324.322.299-7.701.299-8.025 0-7.377.025-7.702-.3-.306-.306-.302-.27-.289-2.784L4.02 13.7l.111-.189c.125-.213.318-.375.539-.454.123-.044 1.434-.054 7.313-.055 6.565-.002 7.179.004 7.357.064zm-2.697 2c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

Server.displayName = 'ServerIcon';
