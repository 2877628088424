import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Gift: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.936 2.044c-1.257.21-2.376 1.17-2.756 2.365a3.555 3.555 0 00.118 2.517c.025.059-.06.067-.905.085-.666.014-.99.036-1.133.079-1.13.34-1.903 1.129-2.183 2.23-.073.285-.073 1.074-.001 1.357a3.16 3.16 0 00.828 1.456c.248.245.694.543.945.63l.148.052.013 3.362c.012 2.934.022 3.389.077 3.563.359 1.134 1.035 1.81 2.173 2.174.178.057.78.065 5.56.076 3.594.008 5.449-.001 5.632-.029 1.179-.182 2.079-.994 2.462-2.221.054-.173.064-.654.076-3.57.01-2.648.024-3.37.063-3.37.066 0 .474-.213.681-.356.545-.376 1.015-1.073 1.19-1.764.072-.285.072-1.075 0-1.357-.304-1.194-1.264-2.089-2.448-2.281-.141-.023-.793-.042-1.449-.042-1.117 0-1.191-.004-1.172-.07.102-.351.121-.492.123-.91.003-.671-.127-1.123-.476-1.659-.63-.967-1.81-1.501-2.931-1.326a2.873 2.873 0 00-.948.31c-.258.129-.28.134-.315.067-.02-.04-.193-.225-.383-.412a3.48 3.48 0 00-2.989-.956zm1.187 2.103c.325.154.573.401.732.733l.125.26.013.933.012.932-.932-.012c-.926-.013-.935-.014-1.173-.125a1.698 1.698 0 01-.767-.764c-.098-.21-.113-.293-.113-.604 0-.31.015-.393.112-.6.36-.773 1.243-1.106 1.991-.753zm4.217.919c.369.126.66.538.66.934 0 .242-.119.521-.299.701-.248.248-.442.299-1.132.299h-.576l.014-.65c.013-.627.017-.657.124-.839a.987.987 0 011.209-.445zM11 10v1H7.927c-3.464 0-3.317.012-3.629-.3a.998.998 0 01.372-1.643c.12-.043.763-.054 3.24-.055L11 9v1zm8.34-.934c.369.126.66.538.66.934 0 .242-.119.521-.299.701-.311.311-.165.299-3.628.299H13V9h3.073c2.755 0 3.093.007 3.267.066zM11 16.5V20H8.927c-2.346 0-2.327.002-2.628-.299C5.988 19.39 6 19.536 6 16.073V13h5v3.5zm7-.427c0 3.463.012 3.317-.299 3.628-.301.301-.282.299-2.628.299H13v-7h5v3.073z"
      fill="currentColor"
    />
  </Icon>
);

Gift.displayName = 'GiftIcon';
