import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PieChart3: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.060 1.546 C 9.988 1.672,9.099 1.872,8.340 2.158 C 4.419 3.637,1.771 7.228,1.521 11.406 C 1.425 13.009,1.744 14.779,2.391 16.240 C 2.593 16.695,3.033 16.843,3.516 16.619 C 3.635 16.564,3.741 16.527,3.752 16.538 C 3.763 16.549,3.828 16.680,3.896 16.829 C 4.727 18.639,6.133 20.180,7.882 21.195 C 8.961 21.820,10.229 22.248,11.539 22.426 C 12.133 22.508,13.358 22.505,13.978 22.421 C 15.798 22.175,17.488 21.453,18.878 20.326 C 21.323 18.345,22.674 15.281,22.478 12.163 C 22.325 9.739,21.345 7.576,19.622 5.860 C 18.784 5.025,18.060 4.507,17.020 4.000 C 15.815 3.412,14.777 3.135,13.393 3.032 L 12.766 2.985 12.753 2.523 C 12.737 1.964,12.675 1.806,12.405 1.635 C 12.228 1.523,12.213 1.520,11.702 1.526 C 11.415 1.529,11.126 1.538,11.060 1.546 M11.230 7.285 L 11.220 11.529 7.387 13.245 C 5.279 14.188,3.539 14.960,3.522 14.960 C 3.447 14.960,3.176 13.859,3.073 13.140 C 2.997 12.606,3.008 11.282,3.094 10.720 C 3.343 9.088,3.982 7.622,4.993 6.360 C 6.297 4.734,8.229 3.564,10.241 3.184 C 10.800 3.078,10.944 3.056,11.130 3.046 L 11.240 3.040 11.230 7.285 M13.721 4.562 C 16.146 4.862,18.260 6.165,19.618 8.197 C 21.008 10.278,21.365 12.930,20.580 15.346 C 20.408 15.874,19.972 16.773,19.651 17.260 C 17.769 20.113,14.371 21.521,11.056 20.821 C 8.555 20.293,6.472 18.664,5.334 16.345 L 5.116 15.901 5.268 15.835 C 5.352 15.799,6.995 15.065,8.920 14.204 C 12.679 12.522,12.623 12.552,12.717 12.214 C 12.746 12.110,12.760 10.839,12.760 8.290 L 12.760 4.520 13.071 4.520 C 13.242 4.520,13.535 4.539,13.721 4.562 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

PieChart3.displayName = 'PieChart3Icon';
