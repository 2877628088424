import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Tag: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.26 2.059C4.39 2.357 2.851 3.645 2.266 5.4c-.249.747-.246.693-.246 4.02 0 3.265-.004 3.19.21 3.717.234.576.283.629 4.164 4.507 4.07 4.068 3.93 3.942 4.686 4.202.355.122.415.13.92.13.505 0 .565-.008.92-.13.756-.26.616-.134 4.686-4.202 3.91-3.907 3.931-3.93 4.165-4.524.181-.46.206-.6.205-1.14-.001-.481-.01-.549-.131-.9-.259-.757-.129-.613-4.221-4.704-3.197-3.198-3.768-3.751-4.034-3.914a3.055 3.055 0 00-1.11-.419c-.395-.064-5.806-.05-6.22.016zm6.083 2.006c.18.059.507.374 3.828 3.69 2.629 2.625 3.655 3.674 3.716 3.801.119.244.137.509.053.77-.066.203-.247.392-3.695 3.845-2.634 2.639-3.674 3.655-3.805 3.72a1.095 1.095 0 01-.88 0c-.131-.065-1.17-1.081-3.805-3.72-3.316-3.321-3.631-3.648-3.69-3.828C4.008 12.169 4 11.828 4 9.431c0-2.317.009-2.759.063-3.023a3.013 3.013 0 011.768-2.167c.545-.232.579-.234 3.572-.238 2.429-.003 2.765.004 2.94.062zm-5.7 2.001C6.288 6.197 6 6.615 6 7c0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.211-.109-.588-.129-.818-.045z"
      fill="currentColor"
    />
  </Icon>
);

Tag.displayName = 'TagIcon';
