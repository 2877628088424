import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Save: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.643 3.069a1.118 1.118 0 00-.343.229c-.317.317-.3.072-.3 4.417v3.86l-.73-.721C9.496 10.088 9.364 10 9 10c-.563 0-1.044.523-.991 1.078.036.376.109.463 1.82 2.167 1.459 1.453 1.652 1.63 1.828 1.69a.987.987 0 00.686 0c.176-.06.369-.237 1.828-1.69 1.711-1.704 1.784-1.791 1.82-2.167C16.044 10.523 15.563 10 15 10c-.364 0-.496.088-1.27.854l-.73.72v-3.86c0-4.343.016-4.1-.299-4.415a.998.998 0 00-1.058-.23zM4.62 6.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.325-.084 10.032 0 10.357.281 1.096 1.043 1.877 2.184 2.238.179.056.983.064 7.56.075 4.998.008 7.448-.001 7.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.326.083-10.034 0-10.357-.307-1.197-1.264-2.09-2.448-2.281-.148-.024-.968-.042-1.939-.042-1.912 0-1.943.004-2.238.299a.984.984 0 000 1.402c.294.294.33.299 2.201.299s1.907.005 2.201.299c.319.318.299-.022.299 5.2 0 5.224.02 4.884-.299 5.202-.324.324.322.299-7.701.299s-7.377.025-7.701-.3c-.319-.318-.299.025-.297-5.217.001-3.791.012-4.691.055-4.813.079-.221.241-.414.454-.54l.189-.11L6.48 8c1.766-.02 1.782-.021 1.97-.114.601-.296.73-1.108.251-1.587-.296-.296-.321-.3-2.278-.294-.948.002-1.759.01-1.803.02z"
      fill="currentColor"
    />
  </Icon>
);

Save.displayName = 'SaveIcon';
