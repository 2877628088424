import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CurrencyEuro: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.3.869 2.577 1.358 4.2 1.607.562.087 2.033.099 2.58.022 2.016-.283 3.753-1.046 5.24-2.3 4.113-3.47 4.719-9.628 1.362-13.848-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.992 7.992 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.69-9.3 7.491 7.491 0 011.46-2.073c1.694-1.742 4.143-2.639 6.517-2.386zM11.42 6.066c-1.261.27-2.285 1.32-2.918 2.994l-.167.44-.34.021c-.354.021-.46.063-.69.274-.2.185-.283.393-.283.705 0 .21.022.321.087.444.156.295.502.536.77.536h.12v1.04h-.12c-.268 0-.614.241-.77.536-.121.229-.121.659 0 .888.099.188.328.402.51.479.07.029.264.065.43.08l.304.027.125.351c1.109 3.1 3.824 4.071 5.802 2.074.319-.323.735-.92.798-1.145.097-.349.023-.653-.225-.93a.926.926 0 00-.733-.331c-.408-.001-.618.14-.98.655-.23.328-.466.553-.72.688-.21.112-.62.117-.82.012-.368-.194-.723-.595-.983-1.11l-.134-.265.869-.017c.959-.019 1.07-.042 1.322-.284.22-.213.304-.409.304-.728 0-.311-.063-.471-.272-.693-.25-.265-.25-.265-1.526-.287l-1.16-.02-.011-.486-.011-.486 1.11-.016c1.228-.017 1.3-.03 1.566-.284.222-.213.304-.409.304-.728s-.082-.515-.304-.728c-.245-.234-.338-.252-1.313-.252-.484 0-.881-.009-.881-.021 0-.065.297-.594.45-.802.34-.461.679-.674 1.07-.674.42 0 .759.23 1.14.773.26.371.39.499.6.588.206.087.553.086.77-.003.21-.087.499-.4.562-.612.108-.36.046-.607-.272-1.082-.85-1.27-2.135-1.887-3.38-1.621z"
      fill="currentColor"
    />
  </Icon>
);

CurrencyEuro.displayName = 'CurrencyEuroIcon';
