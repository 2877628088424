import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronRight: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.643 4.069C8.291 4.193 8 4.614 8 5c0 .08.029.234.065.343.059.18.347.479 3.289 3.427L14.579 12l-3.225 3.23c-2.942 2.948-3.23 3.247-3.289 3.427-.129.39-.044.766.235 1.045a.986.986 0 001.144.185c.127-.061 1.177-1.087 3.801-3.716 3.448-3.453 3.629-3.642 3.695-3.845a1.013 1.013 0 00-.053-.77c-.061-.127-1.087-1.176-3.716-3.801-3.321-3.316-3.648-3.631-3.828-3.69a1.038 1.038 0 00-.7.004z"
      fill="currentColor"
    />
  </Icon>
);

ChevronRight.displayName = 'ChevronRightIcon';
