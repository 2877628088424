import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Award: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.200 0.044 C 8.467 0.307,6.006 2.031,4.800 4.529 C 3.288 7.659,3.963 11.366,6.485 13.781 C 6.669 13.958,6.891 14.158,6.978 14.226 L 7.137 14.350 6.560 18.694 C 5.922 23.502,5.943 23.218,6.192 23.579 C 6.431 23.924,6.969 24.098,7.331 23.947 C 7.413 23.912,8.487 23.280,9.717 22.542 C 10.947 21.804,11.974 21.200,12.000 21.200 C 12.026 21.200,13.053 21.804,14.282 22.541 C 15.511 23.279,16.592 23.909,16.684 23.941 C 17.036 24.066,17.430 23.973,17.701 23.701 C 17.868 23.534,17.999 23.246,18.001 23.043 C 18.001 22.986,17.746 21.007,17.433 18.645 L 16.864 14.350 17.022 14.226 C 17.109 14.158,17.331 13.958,17.515 13.781 C 20.526 10.898,20.836 6.264,18.237 2.997 C 17.908 2.584,17.252 1.945,16.831 1.629 C 15.206 0.409,13.219 -0.151,11.200 0.044 M13.041 2.097 C 14.371 2.333,15.638 3.046,16.506 4.045 C 17.229 4.879,17.706 5.876,17.910 6.980 C 18.012 7.536,18.000 8.617,17.884 9.160 C 17.487 11.030,16.331 12.539,14.660 13.371 C 14.188 13.606,13.841 13.728,13.258 13.864 C 12.819 13.967,12.716 13.976,12.000 13.975 C 11.350 13.974,11.154 13.959,10.826 13.887 C 8.341 13.339,6.548 11.496,6.090 9.020 C 6.000 8.531,6.000 7.469,6.090 6.980 C 6.394 5.335,7.309 3.928,8.660 3.025 C 9.725 2.313,10.873 1.981,12.140 2.019 C 12.448 2.029,12.853 2.063,13.041 2.097 M9.504 15.596 C 11.152 16.136,12.848 16.136,14.497 15.596 C 14.759 15.510,14.977 15.444,14.981 15.450 C 15.003 15.477,15.721 21.012,15.705 21.029 C 15.694 21.039,14.948 20.605,14.047 20.064 C 12.263 18.993,12.164 18.948,11.796 19.033 C 11.651 19.067,11.121 19.363,9.953 20.064 C 9.052 20.605,8.306 21.039,8.295 21.029 C 8.279 21.013,9.003 15.440,9.021 15.440 C 9.025 15.440,9.242 15.510,9.504 15.596 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Award.displayName = 'AwardIcon';
