import { Image, Tag } from '@chakra-ui/react';
import { selectColors } from '../select.config';

export const TagWithImage = ({ data, children, ...props }) => (
  <Tag
    {...props.sx}
    backgroundColor={selectColors.tagDefault}
    borderRadius="full"
    px={3}
  >
    <Image
      src={data.image}
      fallbackSrc="/images/placeholder.svg"
      w={8}
      h={8}
      borderRadius="lg"
      alt={`${data.label} logo`}
      mr={2}
    />
    {children}
  </Tag>
);
