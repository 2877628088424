import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PresentationChartBar: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.643 3.07C2.291 3.192 2 3.613 2 4c0 .396.291.808.66.934.107.036.227.066.267.066.07 0 .073.21.073 5.598 0 3.932.013 5.661.044 5.81.157.75.798 1.391 1.548 1.548.141.03 1.011.044 2.599.044h2.386l-1.223 1.23c-1.078 1.085-1.231 1.253-1.288 1.427-.13.39-.045.766.233 1.044s.655.363 1.044.234c.177-.059.376-.244 1.927-1.789L12 18.422l1.73 1.724c1.551 1.545 1.75 1.73 1.927 1.79.389.128.766.043 1.044-.235.278-.278.363-.655.233-1.044-.057-.174-.21-.342-1.288-1.427L14.423 18h2.386c1.588 0 2.458-.015 2.599-.044a2.062 2.062 0 001.548-1.548c.031-.149.044-1.878.044-5.81C21 5.21 21.003 5 21.073 5c.173 0 .471-.142.628-.299a.984.984 0 000-1.402c-.326-.327.567-.299-9.718-.296-8.192.003-9.182.01-9.34.066zM19 10.5V16H5V5h14v5.5zm-3.357-3.43a1.118 1.118 0 00-.343.228c-.309.31-.3.215-.3 3.202 0 2.986-.009 2.894.299 3.201a.984.984 0 001.402 0c.308-.307.299-.215.299-3.2 0-2.987.009-2.895-.299-3.202a.998.998 0 00-1.058-.23zm-4 2a1.066 1.066 0 00-.577.59c-.057.168-.066.413-.066 1.84 0 1.871.005 1.907.299 2.201.18.18.459.3.701.3.242 0 .521-.12.701-.3.294-.294.299-.33.299-2.2 0-1.872-.005-1.908-.299-2.202a.998.998 0 00-1.058-.23zm-4 2a1.066 1.066 0 00-.577.59c-.094.278-.094 1.402 0 1.68.126.37.538.66.934.66s.808-.29.934-.66c.094-.278.094-1.402 0-1.68-.173-.508-.762-.778-1.291-.59z"
      fill="currentColor"
    />
  </Icon>
);

PresentationChartBar.displayName = 'PresentationChartBarIcon';
