import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const DocumentReport: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62 2.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.327-.084 15.031 0 15.357.281 1.099 1.043 1.878 2.184 2.238.177.055.818.064 5.56.075 3.614.008 5.45-.001 5.633-.03a3.034 3.034 0 002.306-1.8c.233-.551.218-.175.231-5.755.008-3.423-.002-5.211-.029-5.36a2.74 2.74 0 00-.183-.525c-.138-.291-.226-.384-3.07-3.228-2.846-2.846-2.936-2.932-3.228-3.069a2.665 2.665 0 00-.515-.182c-.204-.039-6.155-.053-6.345-.016zm8.67 4.685L18 9.42v4.863c0 5.466.02 5.099-.299 5.418-.32.32.082.299-5.7.299-5.785 0-5.382.021-5.703-.3-.324-.324-.3.328-.288-7.782L6.02 4.7l.111-.189c.125-.213.318-.375.539-.454.12-.043.729-.054 3.03-.055L12.58 4l2.71 2.71zm-.647 3.359a1.118 1.118 0 00-.343.229c-.313.313-.3.157-.3 3.702 0 3.544-.013 3.39.3 3.701.18.18.458.299.7.299.242 0 .521-.119.701-.299.312-.311.3-.157.3-3.701s.012-3.39-.3-3.701a.998.998 0 00-1.058-.23zm-3 2a1.118 1.118 0 00-.343.229c-.303.303-.3.274-.3 2.702 0 2.427-.003 2.399.3 2.701a.984.984 0 001.401 0c.302-.302.3-.274.3-2.701s.002-2.399-.3-2.701a.998.998 0 00-1.058-.23zm-3 2a1.066 1.066 0 00-.577.591c-.098.288-.098 2.392 0 2.68.126.369.538.66.934.66s.808-.291.934-.66c.098-.288.098-2.392 0-2.68-.173-.508-.762-.778-1.29-.591z"
      fill="currentColor"
    />
  </Icon>
);

DocumentReport.displayName = 'DocumentReportIcon';
