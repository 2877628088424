import { Flex, Image, Text } from '@chakra-ui/react';
import { selectColors } from '../select.config';

export const OptionWithImage = ({
  label,
  data,
  isSelected,
  isFocused,
  ...props
}) => (
  <Flex
    flexDirection="row"
    alignItems="center"
    px={2}
    py={1}
    cursor="pointer"
    backgroundColor={
      isSelected
        ? selectColors.backgroundSelected
        : isFocused
          ? selectColors.backgroundHover
          : null
    }
    color={isSelected && selectColors.textSelected}
    _active={{
      backgroundColor: !isSelected && selectColors.backgroundActive,
      color: !isSelected && selectColors.textActive,
    }}
    _hover={{ backgroundColor: !isSelected && selectColors.backgroundHover }}
    _focus={{ backgroundColor: !isSelected && selectColors.backgroundHover }}
    type={props.type}
    value={props.value}
    {...props.innerProps}
  >
    <Image
      src={data.image}
      fallbackSrc="/images/placeholder.svg"
      w={8}
      h={8}
      borderRadius="lg"
      alt={`${data.label} logo`}
    />
    <Text ml={2} fontSize="lg" color="inherit">
      {label}
    </Text>
  </Flex>
);
