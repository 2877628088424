import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Building: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M8 3a1 1 0 0 0-1 1v17h10V4a1 1 0 0 0-1-1H8Zm11 5V4a3 3 0 0 0-3-3H8a3 3 0 0 0-3 3v7H4a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3h-1Zm0 2v11h1a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1h-1ZM5 13H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h1v-8Zm4-7a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z"
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Building.displayName = 'BuildingIcon';
