import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowUp: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.64 2.068c-.156.053-.66.541-3.811 3.687C5.2 8.379 4.174 9.429 4.113 9.556a.986.986 0 00.185 1.144c.279.279.655.364 1.045.235.179-.059.445-.312 2.927-2.789L11 5.421v7.863c0 8.826-.026 8.092.299 8.417a.984.984 0 001.402 0c.325-.325.299.409.299-8.417V5.421l2.73 2.725c2.482 2.477 2.748 2.73 2.927 2.789.39.129.766.044 1.045-.235a.986.986 0 00.185-1.144c-.061-.127-1.087-1.176-3.716-3.801-3.321-3.316-3.648-3.631-3.828-3.69a1.06 1.06 0 00-.703.003z"
      fill="currentColor"
    />
  </Icon>
);

ArrowUp.displayName = 'ArrowUpIcon';
