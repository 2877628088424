import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SwitchVertical: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.64 3.068c-.154.053-.488.369-2.311 2.187-1.516 1.511-2.156 2.176-2.217 2.301A.99.99 0 002.298 8.7c.279.28.656.364 1.045.234.174-.057.342-.21 1.427-1.288L6 6.423v4.861c0 5.465-.02 5.098.299 5.417a.984.984 0 001.402 0c.319-.319.299.048.299-5.417V6.423l1.23 1.223c1.085 1.078 1.253 1.231 1.427 1.288.389.13.766.046 1.045-.234a.99.99 0 00.186-1.144c-.061-.125-.701-.79-2.217-2.301-1.924-1.919-2.15-2.131-2.328-2.19a1.053 1.053 0 00-.703.003zm10.003 4.001a1.118 1.118 0 00-.343.229c-.32.32-.3-.048-.3 5.418v4.861l-1.23-1.223c-1.085-1.078-1.253-1.231-1.427-1.288-.389-.13-.766-.045-1.044.233s-.363.654-.234 1.044c.059.178.271.404 2.19 2.328 1.511 1.516 2.176 2.156 2.301 2.217.244.118.509.136.77.052.201-.065.342-.198 2.345-2.195 1.516-1.511 2.156-2.176 2.217-2.301a.99.99 0 00-.186-1.144c-.279-.28-.656-.364-1.045-.234-.174.057-.342.21-1.427 1.288L18 17.577v-4.861c0-5.465.02-5.098-.299-5.417a.998.998 0 00-1.058-.23z"
      fill="currentColor"
    />
  </Icon>
);

SwitchVertical.displayName = 'SwitchVerticalIcon';
