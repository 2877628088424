import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const TagAlt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M1.664 1.063 C 1.436 1.146,1.257 1.297,1.131 1.511 L 1.020 1.700 1.009 6.918 C 1.000 11.646,1.005 12.155,1.064 12.338 C 1.124 12.526,1.422 12.833,5.374 16.794 C 7.710 19.134,9.772 21.182,9.957 21.346 C 10.566 21.884,11.119 22.117,11.860 22.149 C 12.509 22.177,13.019 22.049,13.591 21.715 C 13.761 21.616,14.788 20.617,17.593 17.821 C 19.668 15.752,21.448 13.952,21.548 13.820 C 22.362 12.753,22.362 11.246,21.548 10.180 C 21.447 10.048,19.379 7.958,16.953 5.535 C 12.905 1.494,12.524 1.124,12.343 1.065 C 12.165 1.006,11.642 1.000,6.983 1.003 C 2.607 1.005,1.796 1.014,1.664 1.063 M15.788 7.210 C 19.068 10.490,20.015 11.460,20.078 11.600 C 20.183 11.836,20.183 12.164,20.078 12.400 C 19.956 12.671,12.705 19.927,12.420 20.064 C 12.166 20.185,11.851 20.190,11.600 20.077 C 11.460 20.014,10.487 19.064,7.210 15.789 L 3.000 11.581 3.000 7.290 L 3.000 3.000 7.290 3.000 L 11.580 3.000 15.788 7.210 M6.664 6.060 C 6.433 6.148,6.256 6.299,6.131 6.511 C 6.036 6.672,6.020 6.744,6.020 7.000 C 6.020 7.256,6.036 7.328,6.131 7.489 C 6.335 7.836,6.709 8.029,7.099 7.989 C 8.109 7.884,8.337 6.564,7.427 6.093 C 7.254 6.004,6.856 5.987,6.664 6.060 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

TagAlt.displayName = 'TagAltIcon';
