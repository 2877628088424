import { FC, useEffect, useRef, useState } from 'react';
import {
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  useDisclosure,
} from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';

export interface SearchControlProps {
  label?: string;
  value?: string;
  handleUpdate?: (value: string) => void;
  handleClear?: () => void;
  containerProps?: BoxProps;
  inputProps?: InputProps;
  buttonProps?: ButtonProps;
  defaultOpen?: boolean;
}

export const SearchControl: FC<SearchControlProps> = ({
  label,
  value = '',
  handleUpdate = () => null,
  handleClear,
  containerProps,
  inputProps,
  buttonProps,
  defaultOpen = false,
}) => {
  const inputRef = useRef(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [_search, _setSearch] = useState(value);

  const onChange = e => {
    const { value } = e.target;
    handleUpdate(value);
    _setSearch(value);
  };

  const onClear = () => {
    !!handleClear && handleClear();
    _setSearch('');
    onClose();
  };

  const handleOpen = () => {
    onOpen();
    inputRef.current?.focus();
    // FUTURE: this doesn't quite work...
  };

  useEffect(() => {
    if (hasLoaded) return;
    setHasLoaded(true);
    if (defaultOpen) {
      onOpen();
    }
  }, [hasLoaded, defaultOpen]);

  return (
    <Flex {...containerProps}>
      {isOpen ? (
        <Flex flexDirection="row" alignItems="center" width="100%" gap={2}>
          <InputGroup>
            <InputLeftElement>
              <Icon name={ICON_NAMES.search} h={4} w={4} />
            </InputLeftElement>
            <Input
              name="search"
              placeholder="Search"
              value={value}
              onChange={onChange}
              display="inline-flex"
              maxWidth={200}
              ref={inputRef}
              {...inputProps}
            />
          </InputGroup>
          {!!handleClear && _search?.length > 0 && (
            <Button
              size="xs"
              variant="transparent"
              onClick={onClear}
              fontWeight="medium"
              fontSize="xs"
              px={2}
            >
              {'Clear'}
            </Button>
          )}
        </Flex>
      ) : (
        <Button
          onClick={handleOpen}
          leftIcon={<Icon name={ICON_NAMES.search} h={5} w={5} />}
          size="sm"
          variant="transparent"
          sx={{ span: { my: 1 } }}
          {...buttonProps}
        >
          {label ? label : null}
        </Button>
      )}
    </Flex>
  );
};
