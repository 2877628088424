import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SocialLinkedin: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M3.853 3.074 C 3.600 3.162,3.393 3.316,3.236 3.532 C 2.984 3.880,2.998 3.349,3.010 11.842 L 3.020 19.580 3.114 19.769 C 3.245 20.033,3.487 20.269,3.754 20.394 L 3.980 20.500 11.598 20.511 C 16.564 20.518,19.291 20.507,19.432 20.481 C 19.884 20.398,20.193 20.155,20.395 19.725 L 20.500 19.500 20.500 11.760 L 20.500 4.020 20.410 3.798 C 20.304 3.535,20.058 3.273,19.780 3.126 L 19.580 3.020 11.820 3.011 C 4.450 3.003,4.050 3.006,3.853 3.074 M7.661 5.669 C 7.941 5.812,8.188 6.063,8.347 6.366 C 8.450 6.561,8.460 6.617,8.460 7.000 C 8.460 7.377,8.449 7.443,8.351 7.643 C 8.059 8.237,7.386 8.596,6.769 8.488 C 6.264 8.399,5.888 8.124,5.667 7.683 C 5.256 6.861,5.611 5.943,6.476 5.593 C 6.621 5.535,6.741 5.522,7.040 5.532 C 7.372 5.543,7.451 5.561,7.661 5.669 M15.481 9.481 C 16.648 9.622,17.369 10.178,17.722 11.210 C 17.970 11.933,17.973 11.985,17.990 15.130 L 18.005 18.000 16.726 18.000 L 15.447 18.000 15.429 15.550 C 15.414 13.483,15.401 13.057,15.346 12.825 C 15.180 12.115,14.847 11.795,14.216 11.736 C 13.528 11.672,12.985 11.953,12.733 12.504 C 12.534 12.939,12.520 13.144,12.520 15.664 L 12.520 18.000 11.220 18.000 L 9.920 18.000 9.920 13.820 L 9.920 9.640 11.159 9.640 L 12.398 9.640 12.409 10.229 L 12.420 10.818 12.553 10.610 C 12.627 10.496,12.791 10.307,12.917 10.191 C 13.579 9.584,14.416 9.352,15.481 9.481 M8.280 13.820 L 8.280 18.000 7.000 18.000 L 5.720 18.000 5.720 13.820 L 5.720 9.640 7.000 9.640 L 8.280 9.640 8.280 13.820 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

SocialLinkedin.displayName = 'SocialLinkedinIcon';
