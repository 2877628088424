import { FC, ReactNode } from 'react';
import { BoxProps, Flex, TextProps } from '@chakra-ui/react';

interface Tile {
  size?: 'md' | 'sm';
  containerProps?: BoxProps;
  wrapperProps?: BoxProps;
  textProps?: TextProps;
  labelContainerProps?: BoxProps;
  onClick?: () => void;
  children: ReactNode;
}

export const Tile: FC<Tile> = ({
  containerProps,
  wrapperProps,
  onClick,
  children,
}) => {
  return (
    <Flex
      direction="column"
      shadow="sm"
      borderRadius="lg"
      aria-roledescription="button"
      position="relative"
      cursor="pointer"
      transition="all 300ms ease-in-out"
      _after={{
        content: "' '",
        position: 'absolute',
        zIndex: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        shadow: 'lg',
        transition: 'opacity 300ms ease-in-out',
        borderRadius: 'lg',
      }}
      _hover={{ _after: { opacity: 1 } }}
      onClick={onClick}
      {...containerProps}
    >
      <Flex
        width="100%"
        height="100%"
        position="relative"
        zIndex={2}
        direction="column"
        borderRadius="lg"
        {...wrapperProps}
      >
        {children}
      </Flex>
    </Flex>
  );
};
