import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Speakerphone: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.645 2.068c-.12.043-.304.185-.594.46a7.04 7.04 0 01-.785.64c-1.127.741-2.652 1.286-4.446 1.589-1.012.17-1.625.212-3.6.243-1.574.026-1.974.043-2.22.099a5.13 5.13 0 00-3.003 1.912c-.285.377-.68 1.166-.797 1.589a5.103 5.103 0 00-.141 2.12c.088.575.2.932.46 1.465.458.939 1.006 1.523 2.056 2.195.036.023.488 1.265 1.064 2.92.551 1.584 1.078 3.033 1.171 3.219.312.625.898 1.113 1.623 1.353.254.084.387.102.767.105.558.003.784-.048 1.28-.289.657-.319 1.137-.884 1.395-1.641l.104-.307.013-2.23c.009-1.704.023-2.23.06-2.23.026 0 .318.062.648.137 1.117.253 1.969.551 2.82.985.653.334 1.029.596 1.522 1.061.451.427.648.537.958.537.242 0 .521-.119.701-.299.294-.294.299-.332.299-2.189 0-1.522.005-1.636.07-1.656 1.451-.454 2.461-1.505 2.821-2.936.116-.461.123-1.316.014-1.78A4.018 4.018 0 0019.3 6.221l-.28-.097L19 4.432c-.02-1.673-.021-1.694-.114-1.882-.22-.448-.752-.655-1.241-.482zM17 9.993v4.912l-.65-.325c-1.191-.596-2.602-1.038-4.2-1.317l-.15-.027V6.762l.09-.019.726-.143c1.378-.271 2.773-.754 3.804-1.318l.37-.2c.005-.001.01 2.209.01 4.911zm-7-.003v3.03l-1.63-.011c-1.275-.008-1.699-.024-1.948-.072a2.996 2.996 0 01-2.359-3.529c.228-1.113 1.04-1.981 2.163-2.313.198-.058.486-.073 1.874-.094.902-.013 1.699-.028 1.77-.033L10 6.96v3.03zm9.462-1.34a1.973 1.973 0 010 2.7c-.118.126-.27.263-.338.302l-.124.073v-3.45l.124.073c.068.039.22.176.338.302zm-9.708 6.376l.246.025v2.156c0 2.323.001 2.317-.212 2.548a.898.898 0 01-.539.244.813.813 0 01-.672-.406c-.031-.059-.4-1.094-.822-2.3l-.784-2.243c-.015-.039.253-.05 1.259-.05.703 0 1.389.012 1.524.026z"
      fill="currentColor"
    />
  </Icon>
);

Speakerphone.displayName = 'SpeakerphoneIcon';
