import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Trash4: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M9.503 1.041 C 8.846 1.155,8.167 1.542,7.722 2.057 C 7.191 2.672,7.000 3.281,7.000 4.358 L 7.000 5.000 4.925 5.000 C 2.676 5.000,2.663 5.001,2.374 5.222 C 2.301 5.277,2.192 5.408,2.131 5.511 C 2.036 5.672,2.020 5.744,2.020 6.000 C 2.020 6.256,2.036 6.328,2.131 6.489 C 2.371 6.898,2.631 7.000,3.425 7.000 L 4.000 7.000 4.000 13.642 C 4.000 20.927,3.988 20.549,4.238 21.156 C 4.392 21.528,4.582 21.809,4.886 22.114 C 5.321 22.548,5.815 22.814,6.421 22.939 C 6.858 23.029,17.152 23.030,17.580 22.940 C 18.607 22.723,19.408 22.050,19.793 21.080 C 20.006 20.544,19.999 20.820,19.999 13.650 L 20.000 7.000 20.573 7.000 C 21.259 7.000,21.454 6.948,21.701 6.701 C 22.092 6.310,22.092 5.690,21.701 5.299 C 21.400 4.998,21.419 5.000,19.073 5.000 L 17.000 5.000 17.000 4.358 C 17.000 3.641,16.945 3.288,16.765 2.851 C 16.485 2.172,15.975 1.638,15.296 1.312 C 14.674 1.013,14.813 1.026,12.120 1.016 C 10.789 1.011,9.611 1.023,9.503 1.041 M14.340 3.066 C 14.593 3.153,14.847 3.407,14.934 3.660 C 14.983 3.805,15.000 3.999,15.000 4.427 L 15.000 5.000 12.000 5.000 L 9.000 5.000 9.000 4.427 C 9.000 3.740,9.052 3.546,9.300 3.298 C 9.596 3.002,9.550 3.007,11.983 3.003 C 13.897 3.000,14.168 3.008,14.340 3.066 M18.000 13.573 C 18.000 20.954,18.024 20.378,17.701 20.701 C 17.381 21.021,17.783 21.000,12.000 21.000 C 6.217 21.000,6.619 21.021,6.299 20.701 C 5.976 20.378,6.000 20.954,6.000 13.573 L 6.000 7.000 12.000 7.000 L 18.000 7.000 18.000 13.573 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Trash4.displayName = 'Trash4Icon';
