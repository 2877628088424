import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SaveAs: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.643 2.069a1.118 1.118 0 00-.343.229c-.314.314-.3.13-.3 3.917v3.36l-.73-.721C11.496 8.088 11.364 8 11 8c-.563 0-1.044.523-.991 1.078.036.376.109.463 1.82 2.167 1.459 1.453 1.652 1.63 1.828 1.69a.987.987 0 00.686 0c.176-.06.369-.237 1.828-1.69 1.711-1.704 1.784-1.791 1.82-2.167C18.044 8.523 17.563 8 17 8c-.364 0-.496.088-1.27.854l-.73.72v-3.36c0-3.784.014-3.602-.299-3.915a.998.998 0 00-1.058-.23zM8.62 4.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.193.469-.217.616-.239 1.41l-.02.766-.766.02c-.503.014-.833.04-.965.08-1.125.333-1.901 1.125-2.184 2.229-.083.324-.083 8.033 0 8.357.281 1.099 1.043 1.878 2.184 2.238.177.055.818.064 5.56.075 3.614.008 5.449-.001 5.633-.03 1.171-.18 2.11-1.028 2.457-2.22.038-.13.066-.472.079-.97l.02-.77h.606c.333 0 .72-.02.861-.042 1.179-.191 2.146-1.092 2.448-2.278.083-.325.083-8.035 0-8.357-.307-1.197-1.264-2.09-2.448-2.281A8.497 8.497 0 0018.537 4c-.806 0-.981.042-1.238.299a.984.984 0 000 1.402c.254.254.435.299 1.201.299s.947.045 1.201.299c.314.314.299.097.299 4.2 0 4.105.015 3.888-.299 4.202-.32.32.082.299-5.701.299-5.783 0-5.381.02-5.701-.3-.315-.314-.299-.094-.297-4.217.001-2.955.012-3.692.055-3.813.079-.221.241-.414.454-.54.185-.108.205-.11.969-.13.714-.018.796-.028.97-.114.601-.296.73-1.108.251-1.587-.261-.261-.428-.3-1.278-.294-.398.002-.759.01-.803.02zM6.01 12.27c.013 1.92.025 2.3.078 2.47.351 1.12 1.053 1.824 2.172 2.174.174.055.687.065 3.97.076l3.77.013v.57c0 .686-.052.88-.299 1.128-.32.32.082.299-5.701.299-5.784 0-5.381.02-5.702-.3-.316-.316-.301-.091-.289-4.283L4.02 10.7l.111-.19a1.04 1.04 0 01.539-.453c.098-.035.352-.054.738-.055L5.996 10l.014 2.27z"
      fill="currentColor"
    />
  </Icon>
);

SaveAs.displayName = 'SaveAsIcon';
