import { FC } from 'react';
import {
  Flex,
  FlexProps,
  IconProps,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';
import { LocationRelations } from 'types';

const stylesByRelation = {
  [LocationRelations.APPROVED]: {
    backgroundColor: 'blue.100',
    iconColor: 'blue.500',
    icon: ICON_NAMES.checkalt,
    size: 3,
  },
  [LocationRelations.BLOCKED]: {
    backgroundColor: 'orange.100',
    iconColor: 'orange.500',
    icon: ICON_NAMES.x,
    size: 4,
  },
  [LocationRelations.FAVORITE]: {
    backgroundColor: 'pink.200',
    iconColor: 'pink.500',
    icon: ICON_NAMES.heart,
    size: 4,
  },
};

interface ReflexerRelationBubbleIconProps {
  relation: LocationRelations;
  containerProps?: FlexProps;
  iconProps?: IconProps;
  tooltipLabel?: string;
  tooltipProps?: TooltipProps;
}

export const ReflexerRelationBubbleIcon: FC<
  ReflexerRelationBubbleIconProps
> = ({ relation, containerProps, iconProps, tooltipLabel, tooltipProps }) => {
  const styles = stylesByRelation[relation];

  const Bubble = (
    <Flex
      h={6}
      w={6}
      borderRadius="full"
      backgroundColor={styles.backgroundColor}
      color={styles.iconColor}
      alignItems="center"
      justifyContent="center"
      {...containerProps}
    >
      <Icon name={styles.icon} h={styles.size} w={styles.size} {...iconProps} />
    </Flex>
  );

  if (tooltipLabel) {
    return (
      <Tooltip label={tooltipLabel} gutter={2} {...tooltipProps}>
        {Bubble}
      </Tooltip>
    );
  }

  return Bubble;
};
