import { FC } from 'react';
import { Button, ButtonProps, Flex, IconProps } from '@chakra-ui/react';
import { Icon } from 'icons';
import { MotionBox } from './MotionBox';

interface BetterButtonProps extends ButtonProps {
  isProcessing?: boolean;
  isDisabled?: boolean;
  isSuccess?: boolean;
  onClick?: (e) => void | Promise<void>;
  label: string;
  iconProps?: IconProps;
}

export const BetterButton: FC<BetterButtonProps> = ({
  isProcessing = false,
  isDisabled = false,
  isSuccess,
  onClick,
  label,
  iconProps,
  ...buttonProps
}) => {
  return (
    <Button
      isLoading={isProcessing}
      isDisabled={isDisabled}
      onClick={isSuccess ? () => null : e => onClick(e)}
      backgroundColor={isDisabled && !isSuccess && 'main.medium'}
      color={isDisabled && !isSuccess ? 'gray.400' : 'main.white'}
      _hover={{ backgroundColor: isDisabled && !isSuccess && 'main.medium' }}
      _active={{ backgroundColor: isDisabled && !isSuccess && 'main.medium' }}
      opacity={isDisabled && !isSuccess && 0.3}
      {...buttonProps}
    >
      {isSuccess && (
        <MotionBox
          initial={{ opacity: 0, marginTop: -12 }}
          animate={{ opacity: 1, marginTop: 0 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.25 }}
        >
          <Flex
            h={6}
            w={6}
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name="check" h={6} w={6} color="main.white" {...iconProps} />
          </Flex>
        </MotionBox>
      )}
      {isSuccess ? null : label}
    </Button>
  );
};
