import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const X: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.643 5.069C5.291 5.193 5 5.614 5 6c0 .08.029.234.065.343.059.179.312.445 2.789 2.927L10.579 12l-2.725 2.73c-2.477 2.482-2.73 2.748-2.789 2.927-.129.39-.044.766.234 1.044s.654.363 1.044.234c.179-.059.445-.312 2.927-2.789L12 13.421l2.73 2.725c2.482 2.477 2.748 2.73 2.927 2.789.39.129.766.044 1.044-.234s.363-.654.234-1.044c-.059-.179-.312-.445-2.789-2.927L13.421 12l2.725-2.73c2.477-2.482 2.73-2.748 2.789-2.927.129-.39.044-.766-.234-1.044s-.654-.363-1.044-.234c-.179.059-.445.312-2.927 2.789L12 10.579 9.27 7.854c-2.482-2.477-2.748-2.73-2.927-2.789a1.032 1.032 0 00-.7.004z"
      fill="currentColor"
    />
  </Icon>
);

X.displayName = 'XIcon';
