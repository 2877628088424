import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ReceiptTax: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62 2.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.247.602-.227-.147-.24 8.983-.013 9.366-.038 8.576.288 8.902.27.27.666.366 1.011.244.121-.043 1.736-.948 3.095-1.734.101-.058.14-.038 1.613.808.831.478 1.583.894 1.672.925a.923.923 0 00.62.001c.121-.043 1.736-.948 3.095-1.734.101-.058.14-.038 1.613.808.831.478 1.583.894 1.672.925a.977.977 0 001.013-.243c.326-.326.301.464.288-8.902-.013-9.088.005-8.375-.227-8.953-.367-.916-1.287-1.641-2.283-1.802-.246-.04-10.662-.057-10.86-.018zm10.72 2.041c.115.039.263.135.361.233.324.324.299-.33.299 7.765v7.21l-1.047-.597c-.576-.329-1.112-.615-1.19-.637a.94.94 0 00-.564.014c-.078.028-.828.443-1.668.922l-1.526.872-1.52-.868c-.836-.477-1.588-.892-1.672-.922a.962.962 0 00-.616-.003c-.117.042-1.573.854-2.067 1.153l-.131.079.011-7.293.01-7.294.111-.189c.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.703-.002 5.18.004 5.357.064zM9.12 7.057c-.4.112-.778.416-.954.767a1.498 1.498 0 00.301 1.742c.231.222.432.328.748.393 1.015.21 1.947-.71 1.747-1.724a1.36 1.36 0 00-.396-.768 1.476 1.476 0 00-1.446-.41zm5.52.011c-.156.053-.603.483-3.311 3.187-2.258 2.253-3.155 3.174-3.216 3.301-.417.858.473 1.748 1.331 1.331.127-.061 1.047-.958 3.301-3.216 2.85-2.855 3.131-3.148 3.19-3.328a1.004 1.004 0 00-1.295-1.275zm-.52 4.987c-.406.118-.779.419-.954.769a1.498 1.498 0 00.301 1.742c.358.344.811.485 1.283.398a1.36 1.36 0 00.783-.398c.929-.893.351-2.462-.937-2.545a1.51 1.51 0 00-.476.034z"
      fill="currentColor"
    />
  </Icon>
);

ReceiptTax.displayName = 'ReceiptTaxIcon';
