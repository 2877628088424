import { FC } from 'react';
import { BoxProps, Flex, HStack, Skeleton, VStack } from '@chakra-ui/react';

interface TableLoadingProps {
  headers?: number;
  rows?: number;
  containerProps?: BoxProps;
  rowProps?: BoxProps;
}

export const TableLoading: FC<TableLoadingProps> = ({
  headers = 3,
  rows = 3,
  containerProps,
  rowProps,
}) => {
  return (
    <Flex mt={4} px={{ base: 2, md: 8 }} {...containerProps}>
      <VStack width="100%" spacing={6}>
        <HStack width="100%">
          {Array.from(Array(headers)).map((header, index) => (
            <Flex width="100%" key={`header-${index}`}>
              <Skeleton
                px={3}
                w="100%"
                maxWidth="120px"
                h={4}
                startColor="blackAlpha.100"
                endColor="blackAlpha.300"
              />
            </Flex>
          ))}
        </HStack>
        {Array.from(Array(rows)).map((row, index) => (
          <Flex width="100%" key={`row-${index}`}>
            <Skeleton
              px={3}
              w="100%"
              h={8}
              startColor="blackAlpha.300"
              endColor="blackAlpha.100"
              {...rowProps}
            />
          </Flex>
        ))}
      </VStack>
    </Flex>
  );
};
