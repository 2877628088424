import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillInteriorDesign: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M12.39 4.969a.39.39 0 10.001.781.39.39 0 000-.781zM10.828 4.969H7.86a.39.39 0 100 .781h2.97a.39.39 0 100-.781zM16.922 4.969h-2.969a.39.39 0 000 .781h2.969a.39.39 0 000-.781z"
      fill="currentColor"
    />
    <path
      d="M2.39 19.813a.39.39 0 100 .78h19.22a.39.39 0 000-.78h-.391V7.703c0-.646-.526-1.172-1.172-1.172h-.39V4.578c0-.646-.526-1.172-1.173-1.172H6.297c-.646 0-1.172.526-1.172 1.172v1.953h-.39c-.647 0-1.173.526-1.173 1.172v1.47l-1.52 3.043A.396.396 0 002 12.39v.78c0 .617.294 1.196.781 1.563v5.079h-.39zm.977-5.626a1.176 1.176 0 01-.586-1.015v-.39h2.344v.39a1.173 1.173 0 01-1.758 1.015zm4.538-4.53L8.198 12h-2.24l.293-2.344h1.654zm12.533 3.905h-5.196c.026-.128.04-.258.04-.39v-.781a.394.394 0 00-.042-.175l-1.28-2.56h6.477v3.906zm-7.351-3.906L14.259 12H12.07l-.586-2.344h1.602zm-7.18 3.125H8.25v.39c0 .647-.526 1.173-1.172 1.173a1.176 1.176 0 01-1.172-1.172v-.39zm3.124 0h2.344v.39c0 .647-.526 1.173-1.172 1.173a1.173 1.173 0 01-1.172-1.172v-.39zm1.648-3.125L11.265 12h-2.28l-.292-2.344h1.986zm-4.773 5.075a1.944 1.944 0 002.735-.389 1.951 1.951 0 002.734.391v2.736H5.906V14.73zm6.25-1.95H14.5v.39a1.172 1.172 0 11-2.344 0v-.39zm6.094 7.031h-1.563v-3.125h1.563v3.125zm.781 0v-3.515a.39.39 0 00-.39-.39h-2.344a.39.39 0 00-.39.39v3.515H14.5v-5.078a1.96 1.96 0 00.39-.39h5.547v5.469h-1.406zM5.906 4.579c0-.215.175-.39.39-.39h12.188c.216 0 .391.175.391.39v1.953H5.906V4.578zM4.344 7.703c0-.215.175-.39.39-.39h15.313c.215 0 .39.175.39.39v1.172H4.345V7.703zm-.15 1.953h1.27L5.17 12H3.023l1.172-2.344zm-.631 5.43a1.943 1.943 0 001.563-.352v3.125c-.001.216.174.391.39.391h6.25a.39.39 0 00.39-.39v-3.127a1.943 1.943 0 001.563.352v4.727H3.563v-4.727z"
      fill="currentColor"
    />
  </Icon>
);

SkillInteriorDesign.displayName = 'SkillInteriorDesignIcon';
