import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AtSymbol: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.417 2.983 1.945 5.458 4.368 7.079 3.043 2.036 6.938 2.257 10.16.575.505-.263.68-.407.8-.655.105-.215.107-.616.005-.841-.098-.214-.4-.475-.624-.538-.315-.088-.508-.04-1.181.293-1.961.97-4.061 1.129-6.1.462-2.995-.981-5.146-3.672-5.46-6.829-.326-3.3 1.462-6.489 4.454-7.946a7.93 7.93 0 017.006-.004 7.977 7.977 0 014.462 6.464c.024.253.038.937.03 1.52l-.012 1.06-.112.24a1.698 1.698 0 01-.764.767c-.21.098-.293.113-.604.113s-.394-.015-.604-.113a1.698 1.698 0 01-.764-.767l-.112-.24-.023-1.26c-.02-.98-.04-1.331-.093-1.58-.223-1.024-.648-1.804-1.383-2.542-.738-.74-1.522-1.164-2.561-1.385-.471-.1-1.45-.1-1.92 0-1.034.22-1.824.647-2.562 1.385-.738.738-1.165 1.528-1.385 2.562-.1.471-.1 1.449 0 1.92.22 1.035.648 1.826 1.385 2.561.92.918 1.996 1.398 3.286 1.468 1.278.068 2.634-.414 3.569-1.272l.272-.248.065.095c.145.211.593.641.844.808.372.249.626.37 1.034.494.315.096.415.108.952.108.537 0 .637-.012.952-.108 1.193-.363 2.05-1.22 2.415-2.414.104-.342.108-.387.124-1.552.01-.722-.002-1.406-.03-1.72-.178-2.014-.904-3.846-2.14-5.4-1.622-2.04-3.891-3.323-6.541-3.7-.315-.044-1.745-.084-2.02-.056zm1.332 7.039c1.62.333 2.675 1.918 2.345 3.529-.333 1.621-1.918 2.675-3.53 2.345-2.086-.428-3.085-2.853-1.895-4.606a3.004 3.004 0 013.08-1.268z"
      fill="currentColor"
    />
  </Icon>
);

AtSymbol.displayName = 'AtSymbolIcon';
