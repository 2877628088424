import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Hashtag: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.664 3.063c-.21.077-.398.225-.505.399-.058.093-.282.915-.637 2.331L8.974 7.98 7.357 8c-1.595.02-1.62.021-1.807.114-.601.296-.73 1.108-.251 1.587.286.286.368.299 1.863.299H8.47l-.5 2-.5 2H5.662c-2.051 0-2.066.002-2.363.299a.984.984 0 000 1.402c.292.292.341.299 2.108.299.854 0 1.553.009 1.553.021 0 .011-.216.884-.48 1.939-.264 1.055-.48 1.979-.48 2.052 0 .516.479.988 1.002.988.318 0 .675-.197.839-.462.058-.093.282-.915.637-2.331l.548-2.187 1.967-.01c1.082-.006 1.967-.001 1.967.01 0 .012-.216.885-.48 1.94S12 19.939 12 20.012c0 .516.479.988 1.002.988.318 0 .675-.197.839-.462.058-.093.282-.915.637-2.331l.548-2.187 1.617-.02c1.595-.02 1.62-.021 1.807-.114.601-.296.73-1.108.251-1.587-.286-.286-.368-.299-1.863-.299H15.53l.5-2 .5-2h1.808c2.051 0 2.066-.002 2.363-.299a.984.984 0 000-1.402C20.409 8.007 20.36 8 18.593 8c-.854 0-1.553-.009-1.553-.021 0-.011.216-.884.48-1.939.264-1.055.48-1.979.48-2.052C18 3.472 17.521 3 16.998 3c-.318 0-.675.197-.839.462-.058.093-.282.915-.637 2.331l-.548 2.187-1.967.01c-1.082.006-1.967.001-1.967-.01 0-.012.216-.885.48-1.94S12 4.061 12 3.988c0-.662-.711-1.154-1.336-.925zM13.97 12l-.5 2H9.53l.5-2 .5-2h3.94l-.5 2z"
      fill="currentColor"
    />
  </Icon>
);

Hashtag.displayName = 'HashtagIcon';
