import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Briefcase: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.62 1.025c-.408.08-.533.114-.75.202-.706.287-1.342.91-1.62 1.588-.193.469-.217.616-.239 1.412l-.02.766-1.266.018c-.982.013-1.31.031-1.465.078-1.138.348-1.901 1.127-2.184 2.231-.083.326-.084 11.032 0 11.357.281 1.096 1.043 1.877 2.184 2.238.18.056.983.064 7.56.075 4.998.008 7.448-.001 7.633-.03 1.19-.183 2.167-1.084 2.471-2.28.083-.327.083-11.033 0-11.357-.307-1.197-1.264-2.09-2.444-2.28C19.337 5.02 18.723 5 18.115 5H17.01l-.02-.77c-.014-.507-.04-.838-.08-.97-.354-1.196-1.268-2.03-2.429-2.217-.244-.04-4.668-.056-4.86-.018zm4.72 2.041c.253.087.507.341.594.594.05.145.066.339.066.767V5H8.993l.014-.65c.013-.627.017-.657.124-.839.125-.213.318-.375.539-.454.118-.042.611-.054 2.313-.055 1.911-.002 2.185.006 2.357.064zm5 4c.257.088.51.342.595.599.058.176.065.458.055 2.556l-.01 2.359-.8.267c-3.115 1.039-6.547 1.382-9.9.989a23.037 23.037 0 01-4.46-.989l-.8-.267V7.7l.111-.189c.125-.213.318-.375.539-.454.123-.044 1.434-.054 7.313-.055 6.565-.002 7.18.004 7.357.064zm-7.697 4c-.355.131-.643.549-.643.934 0 .729.814 1.222 1.465.887.711-.365.708-1.41-.004-1.776-.21-.109-.588-.129-.818-.045zM5.9 15.243c1.299.326 2.634.547 4.14.685.828.075 3.3.063 4.16-.021 1.872-.183 3.737-.553 5.27-1.047l.53-.171v1.729c0 1.962-.003 1.987-.299 2.283-.324.324.322.299-7.7.299-8.024 0-7.378.025-7.702-.299C4.003 18.405 4 18.38 4 16.418V14.69l.55.176c.303.097.91.266 1.35.377z"
      fill="currentColor"
    />
  </Icon>
);

Briefcase.displayName = 'BriefcaseIcon';
