import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MessageCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.900 2.042 C 10.998 2.115,10.013 2.313,9.286 2.565 C 8.777 2.742,7.800 3.231,7.320 3.549 C 4.995 5.088,3.562 7.322,3.094 10.140 C 3.036 10.486,3.021 10.785,3.024 11.540 C 3.027 12.390,3.039 12.566,3.131 13.080 C 3.253 13.760,3.374 14.195,3.629 14.877 L 3.814 15.374 2.907 18.099 C 2.326 19.843,2.000 20.882,2.000 20.985 C 2.000 21.527,2.466 22.000,2.998 22.000 C 3.077 22.000,4.591 21.589,6.361 21.086 L 9.580 20.172 10.091 20.427 C 10.968 20.864,11.536 20.999,12.500 21.000 C 15.082 21.001,17.553 19.939,19.361 18.052 C 21.033 16.307,21.889 14.197,21.987 11.580 C 22.043 10.077,21.667 8.473,20.933 7.080 C 19.168 3.729,15.600 1.739,11.900 2.042 M13.792 4.097 C 15.961 4.488,17.774 5.724,18.931 7.601 C 19.645 8.759,20.047 10.264,19.983 11.540 C 19.912 12.951,19.708 13.791,19.178 14.860 C 18.867 15.489,18.540 15.978,18.097 16.480 C 16.703 18.059,14.602 19.002,12.488 18.999 C 11.784 18.998,11.575 18.940,10.708 18.501 C 10.014 18.149,9.939 18.120,9.723 18.120 C 9.541 18.120,8.947 18.274,7.035 18.817 C 5.685 19.200,4.576 19.509,4.571 19.504 C 4.566 19.499,4.841 18.659,5.182 17.637 C 5.934 15.389,5.939 15.370,5.860 15.066 C 5.827 14.941,5.748 14.735,5.684 14.607 C 5.196 13.641,4.931 12.151,5.032 10.950 C 5.123 9.872,5.347 9.075,5.828 8.120 C 6.555 6.673,7.634 5.587,9.080 4.844 C 10.032 4.355,10.845 4.142,12.200 4.024 C 12.536 3.995,13.459 4.037,13.792 4.097 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

MessageCircle.displayName = 'MessageCircleIcon';
