import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Activity: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M8.664 2.061 C 8.438 2.146,8.256 2.298,8.145 2.491 C 8.093 2.584,7.425 4.537,6.661 6.830 L 5.273 11.000 3.562 11.000 C 1.709 11.000,1.658 11.005,1.374 11.222 C 1.301 11.277,1.192 11.408,1.131 11.511 C 1.036 11.672,1.020 11.744,1.020 12.000 C 1.020 12.257,1.036 12.327,1.133 12.492 C 1.265 12.716,1.487 12.889,1.728 12.956 C 1.853 12.991,2.491 13.000,4.081 12.992 L 6.262 12.980 6.461 12.878 C 6.835 12.685,6.815 12.731,7.945 9.343 C 8.514 7.636,8.989 6.240,9.000 6.240 C 9.011 6.240,10.160 9.661,11.554 13.843 C 13.627 20.059,14.112 21.475,14.215 21.610 C 14.593 22.107,15.406 22.107,15.785 21.611 C 15.886 21.478,16.186 20.621,17.319 17.223 L 18.727 13.000 20.436 13.000 C 22.378 13.000,22.406 12.996,22.701 12.701 C 23.093 12.309,23.092 11.690,22.700 11.298 C 22.395 10.993,22.429 10.997,19.936 11.009 L 17.738 11.020 17.539 11.122 C 17.164 11.315,17.183 11.269,16.054 14.658 C 15.486 16.364,15.011 17.760,15.000 17.760 C 14.989 17.760,13.840 14.339,12.446 10.157 C 10.373 3.941,9.888 2.525,9.785 2.390 C 9.542 2.071,9.031 1.921,8.664 2.061 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Activity.displayName = 'ActivityIcon';
