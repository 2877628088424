import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChatAlt2: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 3.025c-.408.08-.533.114-.75.202-.881.358-1.549 1.137-1.794 2.093-.083.323-.083 7.034 0 7.357.282 1.102 1.063 1.9 2.184 2.232.132.039.463.066.97.08l.77.02v1.569c0 1.784.007 1.831.299 2.123.351.351.892.391 1.305.096l.119-.085.209.082c.463.182.449.181 2.588.195l2.06.015 1.94 1.933c1.746 1.739 1.96 1.939 2.137 1.998.389.129.766.044 1.044-.234.293-.292.299-.339.299-2.128V19h.61c.673-.001.995-.04 1.364-.168a3.069 3.069 0 001.95-2.152c.083-.324.083-7.036 0-7.357-.307-1.197-1.264-2.089-2.448-2.281A7.458 7.458 0 0018.615 7h-.606l-.02-.77c-.014-.507-.041-.838-.08-.97-.354-1.196-1.268-2.03-2.429-2.217-.246-.04-10.662-.057-10.86-.018zm10.72 2.041c.115.039.263.135.361.233.312.311.299.157.299 3.701s.013 3.39-.299 3.701c-.302.302-.274.299-2.701.299-1.881 0-2.17.008-2.343.066-.174.057-.342.21-1.427 1.288L8 15.577v-.861c0-1.002-.031-1.149-.299-1.417C7.421 13.018 7.316 13 6 13c-1.317 0-1.42-.018-1.702-.3-.314-.314-.301-.151-.289-3.783L4.02 5.7l.111-.189c.125-.213.318-.375.539-.454.122-.044 1.104-.054 5.313-.055 4.703-.002 5.18.004 5.357.064zm4 4c.115.039.263.135.361.233.312.311.299.157.299 3.701s.013 3.39-.299 3.701c-.28.281-.385.299-1.701.299s-1.421.018-1.701.299c-.268.268-.299.415-.299 1.417v.861l-1.23-1.223c-1.085-1.078-1.253-1.231-1.427-1.288-.171-.057-.441-.066-2.06-.066H9.421l.999-1 .999-1h1.901c1.113 0 2.005-.018 2.154-.042 1.168-.189 2.07-1.01 2.439-2.218.05-.163.064-.529.077-1.97L18.005 9h.57c.426 0 .62.017.765.066z"
      fill="currentColor"
    />
  </Icon>
);

ChatAlt2.displayName = 'ChatAlt2Icon';
