import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ShieldCheck: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.591 2.038a4.014 4.014 0 00-.5.368A11.008 11.008 0 015.48 4.901a16.82 16.82 0 01-1.44.093l-.98.026-.193.114c-.269.158-.401.384-.522.9-.206.872-.296 1.577-.33 2.566a12.99 12.99 0 002.85 8.54c.45.564 1.43 1.543 1.995 1.993a13.097 13.097 0 004.44 2.327c.624.19.78.189 1.44-.013a12.489 12.489 0 003.46-1.63c2.939-1.95 5.004-5.101 5.595-8.537.15-.873.184-1.291.184-2.28 0-1.197-.082-1.944-.323-2.966-.122-.516-.254-.742-.523-.9l-.193-.114-.96-.025a17.627 17.627 0 01-1.3-.072c-2.246-.309-4.087-1.116-5.798-2.539-.201-.167-.43-.331-.509-.364-.2-.084-.597-.075-.782.018zm.755 2.468c1.92 1.42 4.338 2.296 6.798 2.462l.688.047.073.522c.099.712.1 2.194.002 2.903a11.141 11.141 0 01-2.293 5.401c-.353.447-1.386 1.476-1.814 1.806a11.168 11.168 0 01-3.232 1.757c-.564.196-.602.194-1.308-.064-1.617-.589-2.959-1.464-4.181-2.725-1.633-1.686-2.656-3.803-2.986-6.175-.099-.715-.098-2.196.002-2.903l.073-.522.688-.047A12.795 12.795 0 009.86 5.6c.623-.315 1.372-.774 1.82-1.116.143-.109.283-.199.31-.201.028-.002.188.098.356.223zm2.294 4.562c-.153.053-.442.323-1.909 1.785l-1.73 1.724-.73-.723c-.619-.611-.761-.732-.928-.788-.388-.13-.765-.046-1.044.233-.279.278-.363.655-.233 1.044.057.173.203.336 1.189 1.328.771.775 1.179 1.158 1.3 1.216.246.119.51.137.771.053.201-.065.342-.198 2.345-2.195 1.516-1.511 2.156-2.176 2.217-2.301.396-.818-.38-1.674-1.248-1.376z"
      fill="currentColor"
    />
  </Icon>
);

ShieldCheck.displayName = 'ShieldCheckIcon';
