import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Adjustments: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.643 3.069a1.118 1.118 0 00-.343.229c-.32.32-.3-.024-.3 5.221v4.665l-.15.052c-.253.089-.698.385-.945.631-.604.599-.884 1.274-.884 2.133 0 .654.134 1.113.478 1.639.309.473.904.969 1.351 1.125l.15.052v.665c0 .786.044.965.299 1.22a.984.984 0 001.402 0c.256-.256.299-.433.299-1.228 0-.52.012-.673.051-.673.068 0 .474-.212.683-.356.545-.376 1.015-1.073 1.19-1.764.072-.285.072-1.075 0-1.357a3.164 3.164 0 00-.828-1.457c-.253-.25-.792-.606-.981-.647L7 13.194v-4.67c0-5.25.02-4.906-.299-5.225a.998.998 0 00-1.058-.23zm6 0a1.066 1.066 0 00-.577.591c-.051.15-.066.345-.066.859v.665l-.15.052c-.253.089-.698.385-.945.631-.604.599-.884 1.274-.884 2.133 0 .653.133 1.112.477 1.639.307.473.902.968 1.352 1.125l.15.052v4.665c0 5.244-.02 4.901.299 5.22a.984.984 0 001.402 0c.319-.319.299.025.299-5.228 0-3.766.01-4.673.051-4.673.068 0 .474-.212.683-.356.545-.376 1.015-1.073 1.19-1.764.072-.285.072-1.075 0-1.357a3.164 3.164 0 00-.828-1.457c-.253-.25-.792-.606-.981-.647L13 5.194v-.67c0-.792-.043-.969-.299-1.225a.998.998 0 00-1.058-.23zm6 0a1.118 1.118 0 00-.343.229c-.32.32-.3-.024-.3 5.221v4.665l-.15.052c-.253.089-.698.385-.945.631-.604.599-.884 1.274-.884 2.133 0 .654.134 1.113.478 1.639.309.473.904.969 1.351 1.125l.15.052v.665c0 .786.044.965.299 1.22a.984.984 0 001.402 0c.256-.256.299-.433.299-1.228 0-.52.012-.673.051-.673.068 0 .474-.212.683-.356.545-.376 1.015-1.073 1.19-1.764.072-.285.072-1.075 0-1.357a3.164 3.164 0 00-.828-1.457c-.253-.25-.792-.606-.981-.647L19 13.194v-4.67c0-5.25.02-4.906-.299-5.225a.998.998 0 00-1.058-.23zM12.34 7.066c.369.126.66.538.66.934 0 .527-.473 1-1 1a1.03 1.03 0 01-.94-.679.99.99 0 011.28-1.255zm-6 8c.369.126.66.538.66.934 0 .527-.473 1-1 1a1.03 1.03 0 01-.94-.679.99.99 0 011.28-1.255zm12 0c.369.126.66.538.66.934 0 .527-.473 1-1 1a1.03 1.03 0 01-.94-.679.99.99 0 011.28-1.255z"
      fill="currentColor"
    />
  </Icon>
);

Adjustments.displayName = 'AdjustmentsIcon';
