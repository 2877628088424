import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Layers: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.700 1.054 C 11.612 1.084,9.263 2.247,6.480 3.638 C 2.387 5.686,1.392 6.198,1.275 6.321 C 0.908 6.705,0.918 7.324,1.298 7.697 C 1.433 7.829,2.427 8.341,6.593 10.420 L 11.722 12.980 12.000 12.980 L 12.278 12.980 17.407 10.420 C 21.573 8.341,22.567 7.829,22.702 7.697 C 23.092 7.314,23.092 6.686,22.702 6.303 C 22.567 6.170,21.577 5.662,17.418 3.587 C 11.851 0.810,12.100 0.920,11.700 1.054 M15.860 5.050 C 17.983 6.111,19.720 6.989,19.720 7.000 C 19.720 7.011,17.983 7.889,15.860 8.950 L 12.000 10.880 8.140 8.950 C 6.017 7.889,4.280 7.011,4.280 7.000 C 4.280 6.985,11.936 3.134,11.990 3.122 C 11.995 3.121,13.737 3.988,15.860 5.050 M1.689 11.057 C 1.452 11.134,1.262 11.289,1.131 11.511 C 1.036 11.672,1.020 11.744,1.020 12.000 C 1.020 12.256,1.036 12.328,1.131 12.489 C 1.192 12.592,1.300 12.721,1.371 12.775 C 1.442 12.828,3.800 14.021,6.611 15.426 L 11.722 17.980 12.000 17.980 L 12.278 17.980 17.389 15.426 C 20.200 14.021,22.558 12.828,22.629 12.775 C 22.815 12.635,22.962 12.361,22.988 12.107 C 23.053 11.477,22.514 10.942,21.884 11.011 C 21.744 11.027,20.533 11.615,16.850 13.456 L 12.000 15.880 7.147 13.454 C 1.847 10.804,2.110 10.920,1.689 11.057 M1.689 16.057 C 1.452 16.134,1.262 16.289,1.131 16.511 C 1.036 16.672,1.020 16.744,1.020 17.000 C 1.020 17.256,1.036 17.328,1.131 17.489 C 1.192 17.592,1.300 17.721,1.371 17.775 C 1.442 17.828,3.800 19.021,6.611 20.426 L 11.722 22.980 12.000 22.980 L 12.278 22.980 17.389 20.426 C 20.200 19.021,22.558 17.828,22.629 17.775 C 22.815 17.635,22.962 17.361,22.988 17.107 C 23.053 16.477,22.514 15.942,21.884 16.011 C 21.744 16.027,20.533 16.615,16.850 18.456 L 12.000 20.880 7.147 18.454 C 1.847 15.804,2.110 15.920,1.689 16.057 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Layers.displayName = 'LayersIcon';
