import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const XCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.439.34-1.308 1.21-1.649 1.651-1.146 1.479-1.814 3.078-2.079 4.975-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.301.869 2.577 1.358 4.2 1.607.562.087 2.033.099 2.58.022 2.016-.283 3.753-1.046 5.24-2.3 4.113-3.47 4.719-9.628 1.362-13.848-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.993 7.993 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.689-9.3 7.491 7.491 0 011.46-2.073c1.693-1.742 4.142-2.639 6.516-2.386zM9.643 9.069C9.291 9.193 9 9.614 9 10c0 .364.088.496.854 1.27l.721.73-.721.73C9.088 13.504 9 13.636 9 14c0 .527.473 1 1 1 .364 0 .496-.088 1.27-.854l.73-.721.73.721c.774.766.906.854 1.27.854.527 0 1-.473 1-1 0-.364-.088-.496-.854-1.27l-.721-.73.721-.73c.766-.774.854-.906.854-1.27 0-.527-.473-1-1-1-.364 0-.496.088-1.27.854l-.73.721-.73-.721c-.618-.611-.76-.732-.927-.788a1.026 1.026 0 00-.7.003z"
      fill="currentColor"
    />
  </Icon>
);

XCircle.displayName = 'XCircleIcon';
