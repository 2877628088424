import { FC } from 'react';
import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { formatUserNameForDisplay } from 'helpers';
import { user } from 'types';

interface PersonNameProps extends user {
  containerProps?: FlexProps;
}

export const PersonName: FC<PersonNameProps> = ({
  containerProps,
  ...user
}) => {
  return (
    <Flex alignItems="center" gap={2} {...containerProps}>
      <Flex
        borderRadius="full"
        backgroundColor="navy.100"
        h={10}
        w={10}
        alignItems="center"
        justifyContent="center"
        fontWeight="semi"
        color="navy.500"
      >
        {user?.firstName?.charAt(0)}
      </Flex>
      <Text fontWeight="medium" fontSize="lg" color="blackAlpha.800">
        {formatUserNameForDisplay(user)}
      </Text>
    </Flex>
  );
};
