import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Archive: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.62 3.025c-.408.08-.533.114-.75.202-.881.358-1.551 1.139-1.793 2.093-.073.285-.073 1.074-.001 1.357a3.16 3.16 0 00.828 1.456c.248.246.694.543.945.63l.149.052.012 4.863c.011 4.32.02 4.884.076 5.062.361 1.13 1.052 1.822 2.174 2.175.177.055.818.064 5.56.075 3.614.008 5.449-.001 5.633-.03a3.034 3.034 0 002.306-1.8c.232-.55.217-.201.231-5.49.01-3.864.022-4.87.062-4.87.067 0 .474-.213.682-.356.545-.376 1.015-1.073 1.19-1.764.072-.285.072-1.075 0-1.357-.304-1.194-1.264-2.09-2.444-2.28-.246-.04-14.661-.057-14.86-.018zm14.72 2.041c.369.126.66.538.66.934 0 .242-.119.521-.299.701-.324.324.322.299-7.701.299-8.025 0-7.377.025-7.702-.3a.998.998 0 01.372-1.643c.123-.044 1.434-.054 7.313-.055 6.565-.002 7.179.004 7.357.064zM18 13.573c0 5.141.019 4.81-.299 5.128-.32.32.082.299-5.701.299-5.783 0-5.381.021-5.701-.299-.318-.318-.299.013-.299-5.128V9h12v4.573zm-8.357-2.504C9.291 11.193 9 11.614 9 12c0 .242.119.521.299.701.302.302.274.299 2.701.299s2.399.003 2.701-.299a.984.984 0 000-1.402c-.303-.303-.271-.299-2.718-.296-1.851.003-2.188.012-2.34.066z"
      fill="currentColor"
    />
  </Icon>
);

Archive.displayName = 'ArchiveIcon';
