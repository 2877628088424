import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowRightNarrow: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.643 7.069C16.291 7.193 16 7.614 16 8c0 .08.03.234.066.343.057.174.21.342 1.288 1.427L18.577 11h-7.861c-8.825 0-8.092-.026-8.417.299a.984.984 0 000 1.402c.325.325-.408.299 8.417.299h7.861l-1.223 1.23c-1.078 1.085-1.231 1.253-1.288 1.427-.13.389-.046.766.234 1.045a.99.99 0 001.144.186c.125-.061.79-.701 2.301-2.217 1.997-2.003 2.13-2.144 2.195-2.345a1.018 1.018 0 00-.052-.77c-.061-.125-.701-.79-2.217-2.301-1.924-1.919-2.15-2.131-2.328-2.19a1.032 1.032 0 00-.7.004z"
      fill="currentColor"
    />
  </Icon>
);

ArrowRightNarrow.displayName = 'ArrowRightNarrowIcon';
