import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const FolderPlus: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M3.503 2.042 C 2.852 2.151,2.167 2.541,1.722 3.057 C 1.495 3.321,1.371 3.522,1.235 3.851 C 0.988 4.452,1.000 4.020,1.000 12.000 C 1.000 19.991,0.987 19.546,1.238 20.156 C 1.392 20.528,1.582 20.809,1.886 21.114 C 2.321 21.548,2.815 21.814,3.421 21.939 C 3.860 22.030,20.150 22.030,20.580 21.940 C 21.789 21.685,22.685 20.789,22.940 19.580 C 23.030 19.152,23.029 7.859,22.939 7.421 C 22.744 6.478,22.159 5.726,21.296 5.312 C 20.649 5.001,21.059 5.024,16.035 5.010 L 11.531 4.998 10.636 3.653 C 10.136 2.903,9.683 2.266,9.612 2.211 C 9.336 2.001,9.321 2.000,6.404 2.005 C 4.917 2.007,3.611 2.024,3.503 2.042 M9.364 5.346 C 9.857 6.086,10.315 6.733,10.383 6.785 C 10.677 7.009,10.455 7.000,15.498 7.000 C 20.723 7.000,20.383 6.980,20.701 7.299 C 21.022 7.620,21.000 7.157,21.000 13.500 C 21.000 19.843,21.022 19.380,20.701 19.701 C 20.376 20.026,21.144 20.000,12.000 20.000 C 2.856 20.000,3.624 20.026,3.299 19.701 C 2.975 19.377,3.000 20.023,3.000 12.000 C 3.000 3.975,2.975 4.623,3.300 4.298 C 3.598 4.000,3.532 4.007,6.144 4.003 L 8.467 4.000 9.364 5.346 M11.664 10.063 C 11.436 10.146,11.257 10.297,11.131 10.511 L 11.020 10.700 11.008 11.850 L 10.995 13.000 9.923 13.000 C 8.764 13.000,8.642 13.018,8.374 13.222 C 8.301 13.277,8.192 13.408,8.131 13.511 C 8.036 13.672,8.020 13.744,8.020 14.000 C 8.020 14.256,8.036 14.328,8.131 14.489 C 8.192 14.592,8.301 14.723,8.374 14.778 C 8.642 14.983,8.764 15.000,9.925 15.000 L 11.000 15.000 11.000 16.070 C 11.000 16.684,11.018 17.204,11.042 17.289 C 11.103 17.509,11.315 17.762,11.531 17.874 C 11.932 18.080,12.390 18.012,12.700 17.702 C 12.979 17.423,13.000 17.309,13.000 16.073 L 13.000 15.000 14.073 15.000 C 15.308 15.000,15.423 14.979,15.701 14.701 C 16.092 14.310,16.092 13.690,15.701 13.299 C 15.423 13.021,15.308 13.000,14.073 13.000 L 13.000 13.000 13.000 11.927 C 13.000 10.692,12.979 10.577,12.701 10.299 C 12.429 10.027,12.018 9.933,11.664 10.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

FolderPlus.displayName = 'FolderPlusIcon';
