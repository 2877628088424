import { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { BlockText } from './BlockText';
import { elementPropsBySize } from './elements.config';
import { BlockType, ElementSizes } from './types';

interface BlockHeadingProps extends BlockType {
  id: string;
  size: ElementSizes;
  level: 'h1' | 'h2' | 'h3';
}

export const BlockHeading: FC<BlockHeadingProps> = ({
  rich_text,
  size,
  level,
  id,
}) => {
  return (
    <Text as={level} color="text.primary" {...elementPropsBySize[size][level]}>
      <BlockText text={rich_text} id={id} />
    </Text>
  );
};
