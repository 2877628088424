import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Tennisball: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.060 1.544 C 10.994 1.553,10.724 1.589,10.460 1.624 C 8.317 1.908,6.168 2.993,4.561 4.601 C 2.913 6.250,1.898 8.327,1.579 10.702 C 1.502 11.277,1.491 12.521,1.557 13.120 C 2.063 17.654,5.385 21.325,9.844 22.276 C 11.179 22.560,12.821 22.560,14.156 22.276 C 16.999 21.669,19.445 19.939,20.970 17.455 C 22.494 14.974,22.896 11.904,22.073 9.040 C 21.063 5.524,18.122 2.700,14.560 1.825 C 13.644 1.600,13.254 1.553,12.160 1.540 C 11.621 1.533,11.126 1.535,11.060 1.544 M13.320 3.104 C 15.199 3.375,16.998 4.281,18.359 5.641 C 19.937 7.219,20.844 9.261,20.984 11.550 L 21.011 12.000 20.755 12.000 C 20.615 12.000,20.271 11.973,19.992 11.941 C 16.915 11.581,14.260 9.703,12.920 6.940 C 12.367 5.800,12.096 4.751,12.016 3.444 L 11.988 2.988 12.444 3.016 C 12.695 3.031,13.089 3.071,13.320 3.104 M10.533 3.741 C 10.564 4.331,10.677 5.010,10.860 5.706 C 11.810 9.325,14.712 12.214,18.340 13.154 C 19.001 13.325,19.688 13.437,20.259 13.467 C 20.523 13.480,20.769 13.503,20.804 13.516 C 20.862 13.538,20.861 13.575,20.790 13.920 C 20.597 14.865,20.092 16.066,19.530 16.920 C 18.237 18.887,16.250 20.266,13.967 20.782 C 13.735 20.835,13.535 20.869,13.525 20.858 C 13.514 20.847,13.491 20.609,13.474 20.329 C 13.271 17.041,11.523 14.045,8.740 12.216 C 7.241 11.230,5.407 10.622,3.669 10.534 C 3.387 10.519,3.151 10.497,3.144 10.484 C 3.114 10.430,3.299 9.664,3.465 9.160 C 4.088 7.262,5.377 5.590,7.080 4.470 C 7.934 3.908,9.135 3.403,10.080 3.210 C 10.425 3.139,10.462 3.138,10.484 3.196 C 10.497 3.231,10.520 3.477,10.533 3.741 M4.320 12.104 C 5.242 12.237,6.052 12.489,6.940 12.920 C 8.746 13.795,10.191 15.240,11.077 17.056 C 11.630 18.188,11.904 19.245,11.984 20.550 L 12.011 21.000 11.755 21.000 C 11.615 21.000,11.271 20.973,10.992 20.941 C 7.915 20.581,5.260 18.703,3.920 15.940 C 3.367 14.800,3.096 13.751,3.016 12.444 L 2.988 11.988 3.444 12.016 C 3.695 12.031,4.089 12.071,4.320 12.104 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Tennisball.displayName = 'TennisballIcon';
