import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillSales: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M3.172 22h17.656c.646 0 1.172-.526 1.172-1.172V6.297a.39.39 0 00-.39-.39h-2.735A3.91 3.91 0 0014.969 2a3.904 3.904 0 00-3.906 3.906h-2.97a.39.39 0 00-.39.39v4.532H2.391a.39.39 0 00-.391.39v9.61C2 21.474 2.526 22 3.172 22zm-.39-1.172v-1.406h9.062v1.406c0 .216-.175.39-.39.39H3.171a.391.391 0 01-.39-.39zm18.046.39h-8.27c.043-.122.067-.253.067-.39v-1.406h8.594v1.406c0 .216-.175.39-.39.39zm-5.86-18.437a3.129 3.129 0 013.126 3.125h-6.25a3.123 3.123 0 013.125-3.125zM8.485 6.687h2.579v1.797h-.391a.39.39 0 100 .782h1.562a.39.39 0 000-.782h-.39V6.688h6.25v1.796h-.39a.39.39 0 000 .782h1.562a.39.39 0 100-.782h-.391V6.688h2.344V18.64h-8.594v-7.422a.39.39 0 00-.39-.39h-3.75V6.686zm3.36 4.922v7.032H2.78v-7.032h9.063z"
      fill="currentcolor"
    />
    <path
      d="M4.969 13.953v.781a2.343 2.343 0 104.687 0v-.78a.39.39 0 100-.782h-.781a.39.39 0 100 .781v.781a1.562 1.562 0 11-3.125 0v-.78a.39.39 0 100-.782h-.781a.39.39 0 100 .781zM20.047 15.516a.39.39 0 00.39-.391V12a.39.39 0 10-.78 0v3.125c0 .216.174.39.39.39z"
      fill="currentcolor"
    />
  </Icon>
);

SkillSales.displayName = 'SkillSalesIcon';
