import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Quote: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M9.900 5.045 C 7.858 5.238,5.946 6.178,4.473 7.712 C 3.225 9.013,2.435 10.607,2.111 12.480 C 2.038 12.899,2.024 13.142,2.022 13.980 C 2.021 14.660,2.037 15.076,2.075 15.280 C 2.252 16.255,2.701 17.103,3.393 17.768 C 4.292 18.633,5.383 19.048,6.597 18.988 C 8.660 18.887,10.364 17.340,10.701 15.261 C 10.968 13.614,10.296 11.950,8.959 10.949 C 8.591 10.674,7.921 10.348,7.505 10.241 C 7.081 10.133,6.446 10.077,6.050 10.113 C 5.869 10.129,5.720 10.135,5.720 10.126 C 5.720 10.084,6.180 9.555,6.428 9.313 C 7.029 8.724,7.931 8.165,8.732 7.884 C 9.196 7.722,9.787 7.604,10.314 7.568 L 10.740 7.540 10.751 6.270 L 10.761 5.000 10.511 5.005 C 10.373 5.008,10.098 5.026,9.900 5.045 M21.120 5.047 C 20.160 5.137,19.125 5.433,18.220 5.877 C 15.704 7.109,13.939 9.427,13.403 12.200 C 13.183 13.337,13.183 14.811,13.402 15.667 C 13.766 17.089,14.845 18.279,16.203 18.753 C 18.259 19.471,20.516 18.550,21.501 16.590 C 21.619 16.354,21.753 16.039,21.797 15.890 C 22.346 14.039,21.725 12.100,20.220 10.964 C 19.865 10.696,19.153 10.347,18.746 10.243 C 18.319 10.133,17.688 10.077,17.290 10.113 C 17.108 10.129,16.960 10.135,16.960 10.126 C 16.960 10.092,17.261 9.736,17.519 9.465 C 18.576 8.354,20.031 7.670,21.554 7.568 L 21.980 7.540 21.991 6.270 L 22.001 5.000 21.751 5.005 C 21.613 5.008,21.329 5.027,21.120 5.047 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Quote.displayName = 'QuoteIcon';
