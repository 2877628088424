import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Unlink: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M5.820 6.743 C 3.631 7.132,1.933 8.814,1.494 11.026 C 1.398 11.510,1.398 12.490,1.494 12.974 C 1.963 15.339,3.860 17.074,6.243 17.318 C 6.789 17.374,9.730 17.373,9.940 17.316 C 10.313 17.216,10.600 16.861,10.600 16.500 C 10.600 16.265,10.433 15.954,10.232 15.815 L 10.067 15.700 8.164 15.675 C 6.406 15.651,6.235 15.643,5.928 15.565 C 4.517 15.205,3.474 14.139,3.177 12.751 C 3.096 12.374,3.096 11.627,3.177 11.250 C 3.358 10.402,3.860 9.605,4.533 9.095 C 4.815 8.882,5.465 8.559,5.820 8.456 C 6.027 8.397,6.327 8.384,8.000 8.364 C 10.108 8.338,10.081 8.341,10.332 8.105 C 10.480 7.966,10.598 7.698,10.599 7.498 C 10.600 7.265,10.433 6.954,10.232 6.815 L 10.067 6.700 8.103 6.693 C 6.558 6.688,6.072 6.698,5.820 6.743 M13.900 6.736 C 13.621 6.860,13.402 7.196,13.402 7.500 C 13.402 7.698,13.520 7.966,13.668 8.105 C 13.919 8.341,13.892 8.338,16.000 8.364 C 17.673 8.384,17.973 8.397,18.180 8.456 C 18.535 8.559,19.185 8.882,19.467 9.095 C 20.141 9.605,20.644 10.406,20.823 11.250 C 20.902 11.626,20.902 12.375,20.822 12.751 C 20.530 14.135,19.483 15.205,18.072 15.565 C 17.765 15.643,17.594 15.651,15.836 15.675 L 13.933 15.700 13.768 15.815 C 13.567 15.954,13.400 16.265,13.400 16.500 C 13.400 16.861,13.687 17.216,14.060 17.316 C 14.270 17.373,17.211 17.374,17.757 17.318 C 19.025 17.188,20.141 16.656,21.041 15.753 C 21.784 15.008,22.295 14.039,22.506 12.974 C 22.602 12.490,22.602 11.510,22.506 11.026 C 22.065 8.804,20.355 7.118,18.163 6.744 C 17.655 6.658,14.093 6.651,13.900 6.736 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Unlink.displayName = 'UnlinkIcon';
