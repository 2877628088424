import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface useMobileAppReturn {
  isMobileApp: boolean;
  token?: string;
  closeWebview: () => void;
  logoutMobileApp: () => void;
  openLink: (url: string) => void;
}

export const useMobileApp = (): useMobileAppReturn => {
  const router = useRouter();
  const windowNotUndefined = typeof window !== 'undefined';
  const [isMobileApp, setIsMobileApp] = useState(false);
  const [token, setToken] = useState(undefined);

  const closeWebview = () => {
    const event = new Event('close-webview');
    window.dispatchEvent(event);
  };

  const logoutMobileApp = () => {
    const event = new Event('logout-mobile-app');
    window.dispatchEvent(event);
  };

  const openLink = (url: string) => {
    if (!url) return;
    const event = new CustomEvent('open-link', {
      detail: { url },
    });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    if (router) {
      const { query } = router;
      let { mobile_app } = query;
      const { fer } = query;
      const encodedToken = fer?.toString();
      const decodedToken = atob(encodedToken || '');
      const session_token = decodedToken.slice(0, decodedToken?.length / 2);

      if (windowNotUndefined) {
        if (mobile_app !== undefined) {
          window.localStorage.setItem('mobile_app', mobile_app as string);
        } else {
          mobile_app = window.localStorage.getItem('mobile_app');
        }

        if (session_token !== undefined) {
          setToken(session_token as string);
        } else {
          setToken('');
        }
      }

      setIsMobileApp(!!mobile_app);
    }
  }, [router, windowNotUndefined]);

  return {
    isMobileApp,
    token,
    closeWebview,
    logoutMobileApp,
    openLink,
  };
};
