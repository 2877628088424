import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LightningBolt: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.76 2.027c-.368.129-.24-.02-4.993 5.788-2.522 3.083-4.621 5.674-4.663 5.759a.99.99 0 00.194 1.126c.312.312.165.3 3.629.3H10v3.073c0 3.464-.012 3.317.3 3.629.306.306.752.376 1.157.179.17-.082.663-.67 4.776-5.696 2.522-3.083 4.621-5.674 4.663-5.759a.99.99 0 00-.194-1.126c-.312-.312-.165-.3-3.629-.3H14V5.927c0-3.463.012-3.317-.299-3.628-.237-.237-.677-.364-.941-.272zm-.647 8.422c.051.104.15.237.22.296.288.243.175.233 2.973.255l2.567.02-2.927 3.577-2.926 3.576-.02-2.216-.02-2.217-.093-.189a1.082 1.082 0 00-.22-.296c-.288-.243-.175-.233-2.973-.255l-2.567-.02 2.927-3.577 2.926-3.576.02 2.216.02 2.217.093.189z"
      fill="currentColor"
    />
  </Icon>
);

LightningBolt.displayName = 'LightningBoltIcon';
