import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillGiftWrapping: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M5.516 15.516a.39.39 0 100 .781.39.39 0 000-.781zM5.516 17.86a.39.39 0 100 .78.39.39 0 000-.78z"
      fill="currentColor"
    />
    <path
      d="M20.047 3.016H3.953A1.955 1.955 0 002 4.969v2.343c0 .216.175.391.39.391h.391v12.11c0 .646.526 1.171 1.172 1.171h16.094c.646 0 1.172-.525 1.172-1.172V7.704h.39a.39.39 0 00.391-.39V4.969a1.955 1.955 0 00-1.953-1.953zm-6.485 9.734l-1.318-1.055a.39.39 0 00-.488 0l-1.319 1.055V3.797h3.126v8.953zM2.783 4.969c0-.646.525-1.172 1.171-1.172h5.703v3.125H2.781V4.969zm17.655 14.843a.39.39 0 01-.39.391H3.953a.39.39 0 01-.39-.39V7.703h6.093v5.86a.39.39 0 00.635.304L12 12.5l1.71 1.367a.39.39 0 00.634-.305V7.704h6.094v12.11zm.782-12.89h-6.875V3.797h5.703c.646 0 1.172.526 1.172 1.172v1.953z"
      fill="currentColor"
    />
    <path
      d="M14.344 15.516a.39.39 0 00-.39.39v2.344c0 .216.174.39.39.39h4.14a.39.39 0 00.391-.39v-2.344a.39.39 0 00-.39-.39h-4.141zm3.75 2.343h-3.36v-1.562h3.36v1.562zM9.656 15.516H7.078a.39.39 0 100 .78h2.578a.39.39 0 000-.78zM9.656 17.86H7.078a.39.39 0 100 .78h2.578a.39.39 0 100-.78z"
      fill="currentColor"
    />
  </Icon>
);

SkillGiftWrapping.displayName = 'SkillGiftWrappingIcon';
