import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowUpCircle: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.417 2.983 1.946 5.461 4.368 7.079 1.3.869 2.577 1.358 4.2 1.607.562.087 2.033.099 2.58.022 2.016-.283 3.753-1.046 5.24-2.3 4.113-3.47 4.719-9.628 1.362-13.848-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.992 7.992 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.69-9.3 7.491 7.491 0 011.46-2.073c1.694-1.742 4.143-2.639 6.517-2.386zM11.64 7.068c-.152.053-.431.312-1.811 1.687-1.711 1.704-1.784 1.791-1.82 2.167-.053.555.428 1.078.99 1.078.365 0 .497-.088 1.27-.854l.73-.721v2.86c0 3.226-.01 3.107.3 3.416a.984.984 0 001.402 0c.309-.309.299-.19.299-3.416v-2.86l.73.721c.774.766.906.854 1.27.854.563 0 1.044-.523.99-1.078-.035-.376-.108-.463-1.82-2.167-1.458-1.453-1.651-1.631-1.827-1.69a1.053 1.053 0 00-.703.003z"
      fill="currentColor"
    />
  </Icon>
);

ArrowUpCircle.displayName = 'ArrowUpCircleIcon';
