import { useEffect, useMemo } from 'react';
import { ColumnInterface, useTable } from 'react-table';
import { BoxProps, Flex, Text } from '@chakra-ui/react';
import { FetchFunction, useDataQuery } from 'hooks';
import { DataKeys, PagingInfo } from 'types';
import {
  PaginationControls,
  PaginationControlsProps,
  PagingList,
  PagingListProps,
  RealSimpleLoading,
} from 'ui';

interface PagingLocationsList<ObjectType, QueryVariables> {
  containerProps?: BoxProps;
  listColumns?: () => ColumnInterface[];
  canFetch?: boolean;
  fetchFunction?: FetchFunction<QueryVariables>;
  query?: string;
  queryKey?: string;
  queryVariables?: QueryVariables;
  dataKey: DataKeys;
  returnKey?: string | string[];
  pagingKey?: string;
  contextOverride?: string;
  pagingListProps?: PagingListProps;
  paginationProps?: Partial<PaginationControlsProps>;
  onDataUpdate?: ({
    data,
    paging,
    status,
  }: {
    data?: ObjectType[];
    paging?: PagingInfo;
    status?: string;
  }) => void;
  // note: careful with above 🥴
}

export const HorizontalPagedList = <ObjectType, QueryVariables>({
  containerProps,
  listColumns,
  canFetch = false,
  fetchFunction,
  pagingListProps,
  paginationProps,
  query,
  queryKey,
  queryVariables,
  dataKey,
  returnKey,
  pagingKey,
  contextOverride,
  onDataUpdate,
}: PagingLocationsList<ObjectType, QueryVariables>) => {
  const { status, paging, ...results } = useDataQuery<
    ObjectType,
    QueryVariables
  >({
    queryVariables,
    canFetch,
    fetchFunction,
    query,
    queryKey,
    dataKey,
    returnKey,
    pagingKey,
    pagingParams: {
      page: paginationProps?.currentPage,
      pageSize: paginationProps?.pageSize,
    },
    contextOverride,
  });

  const dataResults = results?.[dataKey] || undefined;
  const data = useMemo(() => (dataResults ? dataResults : []), [dataResults]);

  const columns = useMemo(
    () => listColumns(),
    [listColumns, paging.total_count],
  ); // update columns when total_count changes. Otherwise there can be stale data and odd behavior

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const useTableReturn = useTable({ columns, data });

  useEffect(() => {
    if (!dataResults || !onDataUpdate) return;
    onDataUpdate({ data, paging, status });
  }, [dataResults]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!data?.length) return null;
  return (
    // "column" here controls the stacking of the list and pagination controls
    <Flex flexDirection="column" {...containerProps} width="100%">
      <PagingList
        {...useTableReturn}
        isLoading={status === 'loading'}
        emptyComponent={
          pagingListProps?.emptyComponent ? (
            pagingListProps?.emptyComponent
          ) : (
            <></>
          )
        }
        loadingComponent={
          pagingListProps?.loadingComponent ? (
            pagingListProps?.loadingComponent
          ) : (
            <></>
          )
        }
        containerProps={{
          maxWidth: '100vw',
          width: '100%',
          flexDirection: 'row',
          gap: 4,
          ...pagingListProps?.containerProps,
        }}
        rowProps={{
          position: 'relative',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          ...pagingListProps?.rowProps,
        }}
      />
      <PaginationControls
        total={paging?.total_count}
        allowPageSizeChange={false}
        containerProps={{ px: 6, mb: 4, width: '100%' }}
        pagingContainerProps={{ justify: 'flex-start' }}
        pagingLabelProps={{ ml: 0, flexGrow: 1, fontSize: 'md' }}
        isLoading={status === 'loading'}
        iconProps={{ w: 5, h: 5 }}
        {...paginationProps}
      />
    </Flex>
  );
};

export const commonHorizontalPagingListProps = (
  emptyText = 'No Results Found',
): PagingListProps => ({
  loadingComponent: <RealSimpleLoading />,
});
