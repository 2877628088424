import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Clock: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.26 2.024c-2.164.223-3.841.877-5.46 2.13-.437.339-1.307 1.209-1.646 1.646-1.147 1.482-1.817 3.084-2.082 4.98-.065.465-.065 1.975 0 2.44.223 1.596.73 2.976 1.558 4.24 1.419 2.169 3.642 3.721 6.13 4.281.832.187 1.288.236 2.22.237.894.001 1.252-.032 2.02-.183 2.57-.508 4.947-2.129 6.385-4.355.806-1.247 1.282-2.525 1.52-4.08.088-.562.1-2.033.023-2.58-.27-1.921-.96-3.558-2.106-5-1.623-2.04-3.892-3.323-6.542-3.7-.315-.044-1.745-.084-2.02-.056zm1.532 2.017a7.978 7.978 0 014.762 2.215 7.992 7.992 0 012.405 6.51 7.953 7.953 0 01-2.215 4.788 7.857 7.857 0 01-2.244 1.63A7.968 7.968 0 016.505 17.8a7.966 7.966 0 01-1.69-9.3 7.491 7.491 0 011.46-2.073c1.694-1.742 4.143-2.639 6.517-2.386zm-1.15 3.028a1.118 1.118 0 00-.342.229c-.303.303-.3.274-.3 2.702 0 1.878.008 2.17.065 2.343.059.176.237.369 1.69 1.828 1.14 1.145 1.677 1.657 1.8 1.717a.99.99 0 001.145-.186c.279-.279.364-.655.235-1.045-.06-.175-.225-.356-1.5-1.638L13 11.578V9.716c0-2.111-.001-2.119-.3-2.417a.998.998 0 00-1.057-.23z"
      fill="currentColor"
    />
  </Icon>
);

Clock.displayName = 'ClockIcon';
