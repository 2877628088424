import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Template: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.534 3.057a2.063 2.063 0 00-1.49 1.535c-.059.283-.059 2.533 0 2.816.157.75.798 1.39 1.548 1.548.3.062 14.516.062 14.816 0a2.062 2.062 0 001.548-1.548c.059-.283.059-2.533 0-2.816a2.062 2.062 0 00-1.548-1.548c-.323-.067-14.594-.055-14.874.013zM19 6v1H5V5h14v1zM4.534 11.057a2.063 2.063 0 00-1.49 1.535c-.061.295-.061 6.52 0 6.816.157.75.798 1.39 1.548 1.548.295.06 6.521.06 6.816 0a2.062 2.062 0 001.548-1.548c.061-.295.061-6.521 0-6.816a2.062 2.062 0 00-1.548-1.548c-.318-.066-6.596-.055-6.874.013zm12 0a2.063 2.063 0 00-1.49 1.535c-.061.295-.061 6.52 0 6.816.157.75.798 1.39 1.548 1.548.283.059 2.533.059 2.816 0a2.062 2.062 0 001.548-1.548c.03-.144.044-1.23.044-3.408 0-2.18-.014-3.264-.044-3.408a1.987 1.987 0 00-.552-.996 1.987 1.987 0 00-.996-.552c-.302-.063-2.604-.053-2.874.013zM11 16v3H5v-6h6v3zm8 0v3h-2v-6h2v3z"
      fill="currentColor"
    />
  </Icon>
);

Template.displayName = 'TemplateIcon';
