import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const RefreshCw: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.153 2.040 C 10.324 2.115,9.268 2.349,8.540 2.618 C 7.910 2.851,7.064 3.284,6.478 3.674 C 4.924 4.707,3.761 6.050,2.960 7.734 C 2.512 8.675,2.436 9.030,2.603 9.400 C 2.779 9.788,3.070 9.976,3.500 9.978 C 3.991 9.981,4.271 9.767,4.489 9.225 C 5.242 7.344,6.459 5.937,8.140 5.003 C 8.947 4.555,9.828 4.256,10.820 4.094 C 11.418 3.997,12.572 3.997,13.180 4.095 C 14.352 4.284,15.434 4.698,16.371 5.316 C 16.945 5.695,17.105 5.834,18.877 7.499 L 20.453 8.980 18.595 9.000 L 16.738 9.020 16.539 9.122 C 15.767 9.520,15.853 10.648,16.678 10.939 C 16.826 10.992,17.284 11.000,19.998 11.000 C 23.545 11.000,23.390 11.013,23.701 10.701 C 24.013 10.390,24.000 10.544,24.000 7.000 C 24.000 3.456,24.013 3.610,23.701 3.299 C 23.234 2.832,22.470 2.935,22.131 3.511 L 22.020 3.700 22.000 5.685 L 21.980 7.671 20.280 6.071 C 18.497 4.393,18.181 4.118,17.560 3.702 C 16.290 2.852,14.892 2.320,13.352 2.100 C 12.835 2.026,11.663 1.994,11.153 2.040 M0.664 13.063 C 0.436 13.146,0.257 13.297,0.131 13.511 L 0.020 13.700 0.009 16.912 C 0.002 19.065,0.012 20.176,0.040 20.281 C 0.101 20.506,0.312 20.761,0.531 20.874 C 1.036 21.134,1.643 20.944,1.886 20.450 C 1.980 20.260,1.980 20.255,2.000 18.295 L 2.020 16.329 3.720 17.929 C 5.563 19.663,5.825 19.890,6.520 20.353 C 7.760 21.177,9.154 21.693,10.780 21.928 C 11.242 21.995,12.829 21.994,13.260 21.926 C 14.967 21.659,16.256 21.174,17.540 20.316 C 19.072 19.291,20.232 17.953,21.023 16.300 C 21.509 15.284,21.574 14.960,21.370 14.557 C 21.179 14.181,20.792 13.966,20.390 14.014 C 19.965 14.064,19.707 14.288,19.511 14.775 C 18.833 16.458,17.795 17.759,16.394 18.681 C 13.841 20.361,10.533 20.430,7.897 18.857 C 7.265 18.480,6.851 18.129,5.149 16.527 L 3.548 15.020 5.404 15.000 C 7.250 14.980,7.261 14.979,7.450 14.886 C 8.051 14.590,8.180 13.778,7.701 13.299 C 7.389 12.987,7.547 13.000,3.983 13.003 C 1.342 13.005,0.794 13.015,0.664 13.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

RefreshCw.displayName = 'RefreshCwIcon';
