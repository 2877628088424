import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Wallet: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M16.680 2.269 C 16.344 2.298,3.902 4.436,3.680 4.502 C 2.760 4.776,2.001 5.519,1.668 6.470 C 1.547 6.816,1.546 6.832,1.516 7.920 C 1.470 9.602,1.474 17.880,1.521 18.290 C 1.665 19.550,2.457 20.502,3.681 20.886 L 3.980 20.980 12.000 20.980 L 20.020 20.980 20.319 20.886 C 21.543 20.502,22.335 19.550,22.479 18.290 C 22.533 17.819,22.533 9.181,22.479 8.710 C 22.336 7.461,21.527 6.479,20.360 6.137 L 20.060 6.049 20.034 5.475 C 20.005 4.808,19.935 4.475,19.738 4.048 C 19.201 2.886,17.947 2.156,16.680 2.269 M17.601 3.905 C 17.933 4.061,18.218 4.345,18.377 4.680 C 18.492 4.923,18.501 4.975,18.513 5.469 L 18.526 5.999 11.253 6.010 C 7.253 6.017,3.935 6.035,3.880 6.052 C 3.825 6.068,3.861 6.044,3.960 5.998 C 4.070 5.947,4.547 5.844,5.180 5.735 C 5.752 5.636,8.569 5.151,11.440 4.659 C 17.299 3.653,17.111 3.676,17.601 3.905 M20.020 7.603 C 20.317 7.714,20.548 7.897,20.729 8.162 C 21.012 8.577,21.000 8.334,21.000 13.500 C 21.000 18.611,21.009 18.412,20.755 18.808 C 20.527 19.163,20.172 19.397,19.741 19.476 C 19.409 19.538,4.591 19.538,4.259 19.476 C 3.828 19.397,3.473 19.163,3.245 18.808 C 2.991 18.412,3.000 18.611,3.000 13.500 C 3.000 8.334,2.988 8.575,3.271 8.163 C 3.457 7.892,3.641 7.743,3.944 7.619 L 4.180 7.522 11.988 7.521 L 19.797 7.520 20.020 7.603 M16.909 12.041 C 16.181 12.225,15.715 12.876,15.772 13.632 C 15.813 14.177,16.118 14.610,16.632 14.853 C 16.872 14.966,16.938 14.980,17.260 14.979 C 17.565 14.979,17.655 14.963,17.849 14.872 C 19.004 14.334,19.037 12.717,17.903 12.159 C 17.625 12.022,17.190 11.970,16.909 12.041 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Wallet.displayName = 'WalletIcon';
