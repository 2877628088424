import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Menu2: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M4.456 5.038 C 3.553 5.062,3.596 5.052,3.326 5.298 C 3.044 5.556,2.933 5.973,3.058 6.301 C 3.096 6.401,3.197 6.570,3.282 6.678 C 3.418 6.850,3.465 6.880,3.668 6.927 C 4.011 7.006,19.989 7.005,20.332 6.927 C 20.542 6.879,20.581 6.852,20.741 6.648 C 21.071 6.228,21.076 5.793,20.756 5.370 C 20.667 5.252,20.546 5.137,20.487 5.114 C 20.428 5.092,20.047 5.057,19.640 5.038 C 18.855 5.001,5.887 5.001,4.456 5.038 M4.116 11.037 C 3.587 11.061,3.584 11.062,3.326 11.298 C 3.019 11.579,2.924 12.006,3.088 12.365 C 3.215 12.643,3.420 12.857,3.620 12.924 C 3.823 12.990,11.476 13.027,12.041 12.964 C 12.473 12.916,12.590 12.859,12.775 12.607 C 13.060 12.219,13.060 11.781,12.775 11.393 C 12.575 11.121,12.472 11.079,11.905 11.038 C 11.384 11.000,4.951 10.999,4.116 11.037 M4.456 17.038 C 3.553 17.062,3.596 17.052,3.326 17.298 C 3.044 17.556,2.933 17.973,3.058 18.301 C 3.096 18.401,3.197 18.570,3.282 18.678 C 3.418 18.850,3.465 18.880,3.668 18.927 C 4.011 19.006,19.989 19.005,20.332 18.927 C 20.542 18.879,20.581 18.852,20.741 18.648 C 21.071 18.228,21.076 17.793,20.756 17.370 C 20.667 17.252,20.546 17.137,20.487 17.114 C 20.428 17.092,20.047 17.057,19.640 17.038 C 18.855 17.001,5.887 17.001,4.456 17.038 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Menu2.displayName = 'Menu2Icon';
