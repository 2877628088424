import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Moon2: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M7.620 1.716 C 6.552 2.226,5.625 2.891,4.717 3.798 C 3.473 5.041,2.649 6.373,2.080 8.060 C 1.480 9.843,1.343 11.848,1.697 13.694 C 2.108 15.834,3.224 17.892,4.805 19.422 C 7.523 22.052,11.309 23.075,15.020 22.180 C 16.319 21.867,17.495 21.354,18.593 20.621 C 19.795 19.818,20.964 18.614,21.741 17.380 C 22.002 16.965,22.461 16.037,22.493 15.860 C 22.551 15.532,22.298 15.136,21.967 15.037 C 21.756 14.974,21.605 15.004,20.980 15.232 C 20.029 15.577,19.030 15.732,17.720 15.735 C 16.489 15.739,15.739 15.622,14.623 15.251 C 11.751 14.297,9.468 11.907,8.622 8.969 C 8.070 7.050,8.154 4.626,8.835 2.823 C 9.014 2.348,9.033 2.196,8.938 1.970 C 8.814 1.673,8.570 1.518,8.232 1.523 C 8.065 1.524,7.935 1.565,7.620 1.716 M6.905 4.309 C 6.788 5.048,6.749 5.754,6.772 6.659 C 6.809 8.074,7.044 9.230,7.546 10.460 C 8.911 13.805,11.862 16.273,15.382 17.014 C 16.680 17.287,18.219 17.316,19.692 17.095 C 19.929 17.059,20.131 17.038,20.141 17.047 C 20.180 17.086,19.596 17.773,19.125 18.242 C 17.544 19.815,15.542 20.741,13.240 20.963 C 12.643 21.020,11.603 20.991,11.000 20.899 C 7.457 20.362,4.535 17.873,3.443 14.463 C 3.143 13.526,3.023 12.716,3.023 11.620 C 3.023 10.771,3.058 10.390,3.204 9.660 C 3.574 7.816,4.511 6.084,5.847 4.774 C 6.284 4.345,6.933 3.819,6.952 3.877 C 6.960 3.899,6.938 4.094,6.905 4.309 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Moon2.displayName = 'Moon2Icon';
