import { FC } from 'react';
import { BoxProps, Flex } from '@chakra-ui/react';

interface BlockSpacerProps {
  height: number;
}

export const BlockSpacer: FC<BlockSpacerProps> = (props: BoxProps) => {
  return <Flex aria-hidden="true" className="spacer" {...props} />;
};
