import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const BarChart: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M11.664 3.063 C 11.436 3.146,11.257 3.297,11.131 3.511 L 11.020 3.700 11.010 11.914 C 11.003 17.490,11.013 20.178,11.041 20.283 C 11.101 20.506,11.312 20.761,11.531 20.874 C 11.932 21.080,12.390 21.012,12.700 20.702 C 13.027 20.376,13.000 21.146,13.000 12.000 C 13.000 2.856,13.026 3.624,12.701 3.299 C 12.429 3.027,12.018 2.933,11.664 3.063 M17.664 9.063 C 17.436 9.146,17.257 9.297,17.131 9.511 L 17.020 9.700 17.009 14.914 C 17.002 18.436,17.012 20.177,17.041 20.282 C 17.101 20.506,17.312 20.761,17.531 20.874 C 17.932 21.080,18.390 21.012,18.700 20.702 C 19.021 20.381,19.000 20.784,19.000 15.000 C 19.000 9.217,19.021 9.619,18.701 9.299 C 18.429 9.027,18.018 8.933,17.664 9.063 M5.664 13.063 C 5.436 13.146,5.257 13.297,5.131 13.511 L 5.020 13.700 5.009 16.912 C 5.002 19.065,5.012 20.176,5.040 20.281 C 5.101 20.506,5.312 20.761,5.531 20.874 C 5.932 21.080,6.390 21.012,6.700 20.702 C 7.013 20.389,7.000 20.546,7.000 17.000 C 7.000 13.456,7.013 13.610,6.701 13.299 C 6.429 13.027,6.018 12.933,5.664 13.063 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

BarChart.displayName = 'BarChartIcon';
