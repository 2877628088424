import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SkillPunctual: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M18.4 8.664l.84-.84a1.173 1.173 0 00-1.657-1.657l-.898.897a8.205 8.205 0 00-3.122-1.32V4.577h.78a.39.39 0 00.391-.39V3.171c0-.646-.525-1.172-1.171-1.172h-3.126c-.646 0-1.171.526-1.171 1.172v1.015c0 .216.174.391.39.391h.781v1.165a8.203 8.203 0 00-3.122 1.32l-.898-.897a1.173 1.173 0 00-1.657 0 1.173 1.173 0 000 1.657l.84.841a8.195 8.195 0 00-1.803 5.133C3.797 18.32 7.477 22 12 22s8.203-3.68 8.203-8.203c0-1.873-.638-3.681-1.804-5.133zm-.264-1.946a.391.391 0 01.552.553l-.807.807a8.3 8.3 0 00-.571-.534l.826-.826zm-8.09-3.546a.39.39 0 01.392-.39h3.124a.39.39 0 01.391.39v.625h-3.906v-.625zm2.735 1.406v1.053a8.402 8.402 0 00-1.562 0V4.578h1.562zm-7.469 2.14a.391.391 0 01.552 0l.826.826c-.198.169-.389.347-.57.534l-.808-.807a.391.391 0 010-.553zM12 21.218c-4.092 0-7.422-3.329-7.422-7.421a7.413 7.413 0 012.932-5.91 7.416 7.416 0 018.98 0 7.413 7.413 0 012.932 5.91c0 4.092-3.33 7.422-7.422 7.422z"
      fill="currentColor"
    />
    <path
      d="M12 14.969a1.173 1.173 0 00.39-2.277V9.891a.39.39 0 10-.78 0v2.801A1.173 1.173 0 0012 14.97zm0-1.563a.391.391 0 110 .782.391.391 0 010-.782z"
      fill="currentColor"
    />
    <path
      d="M16.161 9.676l-.019-.021c-.007-.008-.015-.014-.023-.021a5.88 5.88 0 00-2.462-1.458.39.39 0 10-.221.75 5.09 5.09 0 011.866 1.017l-.264.263a.39.39 0 00.553.553l.264-.264a5.03 5.03 0 011.208 2.911h-.375a.39.39 0 000 .782h.375a5.03 5.03 0 01-1.208 2.911l-.264-.264a.39.39 0 00-.553.553l.264.264a5.031 5.031 0 01-2.911 1.208v-.375a.39.39 0 10-.782 0v.375a5.03 5.03 0 01-2.911-1.208l.264-.264a.39.39 0 10-.553-.553l-.264.264a5.03 5.03 0 01-1.208-2.911h.375a.39.39 0 100-.782h-.375a5.03 5.03 0 011.208-2.911l.264.264a.39.39 0 00.553 0 .39.39 0 000-.553l-.264-.263a5.089 5.089 0 011.866-1.018.39.39 0 00-.221-.749 5.879 5.879 0 00-2.485 1.479l-.019.02a5.82 5.82 0 00-1.698 4.122 5.82 5.82 0 001.698 4.121l.019.021.02.019A5.82 5.82 0 0012 19.656a5.82 5.82 0 004.121-1.698l.021-.019.019-.02a5.82 5.82 0 001.698-4.122 5.82 5.82 0 00-1.698-4.121z"
      fill="currentColor"
    />
    <path
      d="M12 8.719a.39.39 0 100-.782.39.39 0 000 .782z"
      fill="currentColor"
    />
  </Icon>
);

SkillPunctual.displayName = 'SkillPunctualIcon';
