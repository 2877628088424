import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Crop: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M5.828 0.055 C 5.560 0.155,5.383 0.301,5.260 0.524 L 5.140 0.740 5.112 2.895 C 5.096 4.080,5.072 5.061,5.058 5.075 C 5.045 5.089,4.067 5.109,2.887 5.120 L 0.740 5.140 0.530 5.256 C -0.220 5.672,-0.151 6.754,0.646 7.056 C 0.801 7.115,1.050 7.121,2.954 7.111 L 5.089 7.100 5.065 8.500 C 5.051 9.270,5.030 11.331,5.017 13.080 C 4.990 16.587,4.988 16.550,5.238 17.156 C 5.392 17.528,5.582 17.809,5.886 18.114 C 6.191 18.418,6.472 18.608,6.844 18.762 C 7.440 19.007,7.272 19.000,12.358 19.000 L 17.000 19.000 17.000 21.070 C 17.000 22.373,17.016 23.195,17.042 23.289 C 17.103 23.509,17.315 23.762,17.531 23.874 C 17.932 24.080,18.390 24.012,18.700 23.702 C 19.002 23.400,19.000 23.420,19.000 21.073 L 19.000 19.000 21.073 19.000 C 23.419 19.000,23.400 19.002,23.701 18.701 C 24.092 18.310,24.092 17.690,23.701 17.299 C 23.400 16.998,23.419 17.000,21.073 17.000 L 19.000 17.000 19.000 12.358 C 19.000 7.280,19.007 7.438,18.765 6.851 C 18.565 6.368,18.295 6.011,17.864 5.663 C 17.592 5.444,17.016 5.158,16.680 5.074 C 16.435 5.014,15.377 5.011,10.100 5.059 L 7.100 5.086 7.111 2.953 C 7.124 0.596,7.127 0.627,6.869 0.333 C 6.626 0.056,6.159 -0.068,5.828 0.055 M16.340 7.066 C 16.455 7.105,16.603 7.201,16.701 7.299 C 17.019 7.617,17.000 7.286,17.000 12.427 L 17.000 17.000 12.427 17.000 C 7.286 17.000,7.617 17.019,7.299 16.701 C 6.976 16.378,6.993 16.720,7.043 11.621 C 7.067 9.134,7.091 7.096,7.096 7.091 C 7.101 7.086,8.715 7.066,10.682 7.047 C 16.440 6.992,16.119 6.991,16.340 7.066 "
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

Crop.displayName = 'CropIcon';
